import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import Zoom from './Zoom';
import PageIncrement from './PageIncrement';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'repeat(3, 1fr)',
    backgroundColor: theme.palette.bottomDialogBackground,
    paddingRight: 12,
  },
  actionBarLeftContainer: {
    display: 'grid',
    alignContent: 'center',
    gridAutoFlow: 'column',
    marginLeft: '15%',
    overflow: 'hidden',
  },
  documentTitle: {
    lineHeight: '3',
    color: '#7b7b7b',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
});

const ActionBar = props => {
  const {
    actionButtons,
    classes,
    setPage,
    setZoom,
    setCurrentDocument,
    resetZoom,
    page,
    pageCount,
    prevDocId,
    nextDocId,
    className,
    withSidebar,
    documentTitle,
  } = props;
  const alignZoomEnd = withSidebar;
  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.actionBarLeftContainer}>
        {documentTitle && (
          <Tooltip
            title={documentTitle}
            enterDelay={500}
            classes={{ tooltip: classes.tooltip }}
          >
            <Typography variant="h6" className={classes.documentTitle}>
              {documentTitle}
            </Typography>
          </Tooltip>
        )}
        <Zoom
          setZoom={setZoom}
          resetZoom={resetZoom}
          alignZoomEnd={alignZoomEnd}
          withSidebar={withSidebar}
        />
      </div>
      <PageIncrement
        setPage={setPage}
        setCurrentDocument={setCurrentDocument}
        page={page}
        pageCount={pageCount}
        prevDocId={prevDocId}
        nextDocId={nextDocId}
        withSidebar={withSidebar}
      />
      {actionButtons}
    </div>
  );
};

export default withStyles(styles)(ActionBar);
