import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

const DeleteConfirmationDialog = props => {
  const { isOpen, onClose, deleteMessage, handleDeleteConfirmation } = props;
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      data-test-id="DeleteConfirmationDialog-root"
    >
      <DialogTitle data-test-id="DeleteConfirmationDialog-title">
        Delete Confirmation
      </DialogTitle>
      <DialogContent data-test-id="DeleteConfirmationDialog-content">
        <DialogContentText data-test-id="DeleteConfirmationDialog-message">
          {deleteMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          data-test-id="DeleteConfirmationDialog-cancelButton"
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleDeleteConfirmation()}
          color="primary"
          variant="contained"
          data-test-id="DeleteConfirmationDialog-deleteButton"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
