import React, { useState } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withStyles } from '@material-ui/core/styles';
import {
  ChevronLeft,
  ChevronRight,
  PersonPin as PersonPinIcon,
} from '@material-ui/icons';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { logout } from 'common/store/actions/authenticate';
import withApi from 'common/hoc/withApi';
import { withCookies } from 'react-cookie';
import {
  MYCASTANDCREW_URL,
  PLUSTRAINING_URL,
  UNIVERSITYLINK,
  CUSTOMER_SUPPORT_URL,
  REMEMBER_SIGNATURE_COOKIE,
} from 'mobile/constants';

const styles = {
  root: {
    width: '100%',
    paddingBottom: '50px',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    padding: 0,
  },
  list: {
    width: '70vw',
    padding: '15px',
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
    width: '60%',
  },
  drawerPaper: {
    backgroundImage: 'linear-gradient(180deg, #2eaba4 0%, #3c9dd7 100%)',
  },
  backIcon: {
    width: 40,
    height: 40,
    color: 'white',
    marginLeft: '5px',
  },
  backTitle: {
    width: 30,
    height: 17,
    color: 'white',
    fontSize: '12px',
    fontWeight: 400,
    bottom: '16px',
    position: 'relative',
  },
  backIconArea: {
    position: 'relative',
    right: '12px',
  },
  profile: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    marginBottom: '20px',
  },
  personIcon: {
    width: 50,
    height: 50,
    color: 'white',
  },
  profileName: {
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
  },
  profileEmail: {
    color: 'white',
    fontSize: 12,
    fontWeight: 300,
  },
  logout: {
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
    margin: '20px',
  },
  link: {
    width: '90%',
  },
  menuItem: {
    color: 'white',
    fontSize: 14,
    fontWeight: 400,
    padding: '20px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    '& a': {
      textDecoration: 'none',
    },
  },
  menuIcon: {
    width: '25px',
    height: '25px',
    float: 'right',
  },
  menuList: {
    '& li:last-child': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  childrenContainer: {
    flexGrow: 1,
  },
};

const HamburgerMenu = ({
  children,
  classes,
  cookies,
  logout,
  me: meQuery = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: me = {}, status: meStatus } = meQuery || {};
  const { first_name: firstName = '', last_name: lastName = '', email } =
    me || {};

  const isProfileLoading = meStatus === 'loading';

  const handleLogout = () => {
    logout();
    cookies.remove(REMEMBER_SIGNATURE_COOKIE);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h3" color="inherit" noWrap>
            Start+
          </Typography>
          <div className={classes.childrenContainer}>{children}</div>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            data-test-id="hamburger-menu-icon"
            onClick={() => setIsOpen(true)}
            aria-label="Open drawer"
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        className={classes.drawer}
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        classes={{ paper: classes.drawerPaper }}
        data-test-id="hamburger-menu-drawer"
      >
        <div className={classes.list}>
          <div className={classes.backIconArea}>
            <ChevronLeft
              data-test-id="hamburger-menu-backicon"
              onClick={() => setIsOpen(false)}
              className={classes.backIcon}
            />
            <span className={classes.backTitle}>Back</span>
          </div>
          {!isProfileLoading && (
            <div className={classes.profile}>
              <PersonPinIcon className={classes.personIcon} />
              <div>
                <p className={classes.profileName}>
                  {`${firstName}  ${lastName}`}
                </p>
                <p className={classes.profileEmail}>{email}</p>
              </div>
            </div>
          )}
        </div>
        <div>
          <ul className={classes.menuList}>
            <li className={classes.menuItem}>
              <a href={MYCASTANDCREW_URL} className={classes.link}>
                MyCast&Crew
              </a>
              <ChevronRight className={classes.menuIcon} />
            </li>
            <li className={classes.menuItem}>
              <a href={PLUSTRAINING_URL} className={classes.link}>
                Help Center
              </a>
              <ChevronRight className={classes.menuIcon} />
            </li>
            <li className={classes.menuItem}>
              <a href={UNIVERSITYLINK} className={classes.link}>
                C&C University
              </a>
              <ChevronRight className={classes.menuIcon} />
            </li>
            <li className={classes.menuItem}>
              <a href={CUSTOMER_SUPPORT_URL} className={classes.link}>
                Contact Support Link
              </a>
              <ChevronRight className={classes.menuIcon} />
            </li>
          </ul>
          <p className={classes.logout} onClick={() => handleLogout()}>
            Logout
          </p>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

HamburgerMenu.queries = {
  me: {
    info() {
      return {
        id: '/me',
      };
    },
  },
  profile: {
    info: function() {
      return {
        id: '/account/profile',
      };
    },
  },
};

const mapDispatchToProps = {
  logout,
};
export default compose(
  withApi,
  withCookies,
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(HamburgerMenu);
