import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
// Component
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
  root: {},
  error: {
    color: '#f44336',
  },
});

const RadioInput = props => {
  const { fieldName, fieldOptions, error, onChange, classes, value } =
    props || {};

  return (
    <FormControl
      component="fieldset"
      fullWidth
      classes={{ root: classes.root }}
      error={error}
    >
      <FormLabel component="legend">{fieldName}</FormLabel>
      <RadioGroup
        aria-label={fieldName}
        name={fieldName}
        onChange={({ target: { value } = {} } = {}) => onChange(`${value}`)}
        className={classes.group}
        value={value}
      >
        {fieldOptions.map(option => (
          <FormControlLabel
            value={option}
            control={<Radio data-test-id={`RadioInput-${option}`} />}
            label={option}
          />
        ))}
      </RadioGroup>
      {error && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(RadioInput);
