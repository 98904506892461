import React, { useState } from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Components
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// Utilities
import classNames from 'class-names';

const styles = theme => ({
  inputContainer: {
    height: '38px',
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  charCountLabel: {
    marginTop: 5,
  },
  input: {
    height: '38px',
  },
  select: {},
  error: {},
});

const JobDescriptionInput = props => {
  const {
    onChange: upstreamOnChange,
    formData: { jobDescription = '' } = {},
    classes = {},
    disabled,
  } = props;

  const [jobDescriptionValue, setJobDescriptionInput] = useState(
    jobDescription,
  );

  const onChange = ({ target: { value } = {} }) => {
    setJobDescriptionInput(value);
  };

  /**
   * Workaround to prevent typing lag
   */
  const onBlur = ({ target: { value } = {} }) => {
    if (value.length >= 101) return;
    upstreamOnChange({
      jobDescription: value,
    });
  };

  const jobDescriptionLength = jobDescriptionValue
    ? jobDescriptionValue.length
    : 0;
  const isAtMaxCharCount = jobDescriptionLength === 100;

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography className={classes.label} variant="body1">
          Job Description
        </Typography>
        <Typography
          className={classes.charCountLabel}
          variant="body1"
          color={isAtMaxCharCount ? 'error' : 'default'}
          data-test-id="JobDescriptionInput-charCount"
        >
          {jobDescriptionLength} / 100
        </Typography>
      </div>
      <TextField
        onChange={onChange}
        onBlur={onBlur}
        value={jobDescriptionValue}
        className={classNames(classes.inputContainer, classes.select)}
        variant="outlined"
        InputProps={{
          className: classes.input,
          inputProps: {
            'data-test-id': 'JobDescriptionInput-input',
          },
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default withStyles(styles)(JobDescriptionInput);
