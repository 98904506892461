import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// MaterialUI Components
import {
  List,
  ListItem,
  TextField,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  AddCircle as AddCircleIcon,
} from '@material-ui/icons';

// Utilities
import * as FieldTypes from 'common/utilities/constants/fieldTypes';

const styles = theme => ({
  list: {
    paddingRight: theme.spacing.unit * 3,
  },
  dropdownText: {
    display: 'flex',
    fontWeight: 600,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const DropdownAssignments = props => {
  const {
    fieldValues,
    dropdownEditKey,
    editValue,
    classes,
    submitUpdatedOption,
    setOptionEditKey,
    handleDeleteOption,
    handleOptionValueChange,
    handleKeyPress,
    addNewOption,
    title,
  } = props;
  const { options, fieldType, systemField } = fieldValues || {};
  const duplicateOptionError = !!(
    options.find(option => option.toLowerCase() === editValue.toLowerCase()) &&
    editValue !== dropdownEditKey
  );
  const disabled = options.includes('');
  // Render the dropdown option rows
  const dropdownOptions = options.map(option => {
    const isOptionDisabled = dropdownEditKey !== option;
    const optionAction = isOptionDisabled
      ? setOptionEditKey
      : submitUpdatedOption;
    const actionIcon = isOptionDisabled ? <EditIcon /> : <CheckIcon />;
    return (
      <ListItem key={option}>
        <TextField
          value={isOptionDisabled ? option : editValue}
          onChange={handleOptionValueChange}
          onKeyPress={!duplicateOptionError ? handleKeyPress : undefined}
          error={duplicateOptionError && !isOptionDisabled}
          helperText={
            duplicateOptionError && !isOptionDisabled ? 'Duplicate option.' : ''
          }
          disabled={isOptionDisabled}
          inputProps={{
            'data-test-id': `DropdownAssignments-optionInput-${option}`,
          }}
          autoFocus
        />
        <ListItemSecondaryAction>
          <IconButton
            disabled={!!systemField.name || duplicateOptionError}
            onClick={() => optionAction(option)}
            data-test-id={`DropdownAssignments-actionButton-${option}`}
          >
            {actionIcon}
          </IconButton>
          <IconButton
            disabled={!!systemField.name}
            onClick={() => handleDeleteOption(option)}
            data-test-id={`DropdownAssignments-deleteButton-${option}`}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });
  return (
    <List className={classes.list} key="dropdown-list">
      <Typography variant="subtitle1" className={classes.dropdownText}>
        {title}
        <IconButton
          disabled={
            !!(disabled || systemField.name || fieldType !== FieldTypes.CMB)
          }
          onClick={addNewOption}
          data-test-id={`DropdownAssignments-addButton-${systemField.name}`}
        >
          <AddCircleIcon />
        </IconButton>
      </Typography>
      {dropdownOptions}
    </List>
  );
};

export default withStyles(styles)(DropdownAssignments);
