import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SuccessIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';

import Dialog from 'common/components/Dialog';
import ProjectSearch from 'common/components/ProjectSearch';
import Loader from 'common/components/Loader';

import useAddRelatedProject from '../hooks/useAddRelatedProject';

const styles = {
  paper: {
    width: 600,
    maxHeight: 600,
    padding: 16,
  },
  btnClose: {
    background: '#f4f6f8',
    color: 'black',
    '&:hover': {
      background: '#ccc',
    },
    paddingLeft: 32,
    paddingRight: 32,
  },
  btnPrimary: {
    background: '#45a7dc',
    paddingLeft: 32,
    paddingRight: 32,
    color: 'white',
    '&:hover': {
      background: '#3297ce',
    },
    '&:disabled': {
      background: '#a8d4ee',
      color: 'white',
    },
  },
  loaderContainer: {
    height: 100,
  },
  dialogContent: {
    textAlign: 'center',
  },
  iconSuccess: {
    width: '100%',
    height: 128,
    color: '#2EABA4',
  },
  titleH5: {
    fontWeight: 'bold',
    paddingBottom: 20,
  },
  projectName: {
    color: '#45a7dc',
    fontWeight: 'bolder',
  },
  error: {
    color: 'red',
  },
};

const SEARCH = 0;
const PROCESSING = 1;
const SUCCESS = 2;
const FAILURE = 3;

const RelatedDialog = props => {
  const {
    classes,
    projectId,
    open = false,
    onClose = () => {},
    onSubmit = () => {},
  } = props;

  const [step, setStep] = useState(SEARCH);
  const [selectedProject, setSelectedProject] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);
  const [addRelatedProject] = useAddRelatedProject();

  const body = () => {
    switch (step) {
      default:
        return (
          <ProjectSearch
            project={{ id: projectId }}
            onSelect={project => setSelectedProject(project)}
            nonRelated={true}
            showOnlyCA={true}
            noEnvironmentSelection
          />
        );

      case PROCESSING:
        return (
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        );

      case SUCCESS:
        return (
          <div
            data-test-id="RelatedProjects-AddSuccess"
            className={classes.dialogContent}
          >
            <SuccessIcon className={classes.iconSuccess} />
            <Typography variant="h5" className={classes.titleH5}>
              Success!
            </Typography>
            <Typography variant="subtitle1">
              Relation to{' '}
              <span className={classes.projectName}>
                {selectedProject.name}
              </span>{' '}
              has been created.
            </Typography>
          </div>
        );

      case FAILURE:
        return (
          <Typography data-test-id="RelatedAdd-error" className={classes.error}>
            Reason: {failureMessage}
          </Typography>
        );
    }
  };

  const actions = () => {
    switch (step) {
      default:
        return [{ text: 'Close', fn: close, className: classes.btnPrimary }];

      case SEARCH:
        return [
          { text: 'Close', fn: close, className: classes.btnClose },
          {
            text: 'Add',
            fn: submit,
            className: classes.btnPrimary,
            disabled: selectedProject ? false : true,
          },
        ];
    }
  };

  const close = () => {
    if (step === SUCCESS) onSubmit();

    onClose();
    setStep(SEARCH);
  };

  const submit = () => {
    setStep(PROCESSING);
    addRelatedProject({
      master: projectId,
      related: parseInt(selectedProject.id),
    })
      .then(() => setStep(SUCCESS))
      .catch(e => {
        const { graphQLErrors = [] } = e || {};
        const { message = '' } = graphQLErrors[0] || {};
        setFailureMessage(message);
        setStep(FAILURE);
      });
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      title="Add related project"
      message={body()}
      actions={actions()}
      handleClose={close}
    />
  );
};

export default withStyles(styles)(RelatedDialog);
