import React, { useState } from 'react';
import Box from 'common/oldJavascripts/components/Base/Box';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Header from 'common/oldJavascripts/components/Base/Header';
import DocumentManagement from 'admin/components/DocumentManagement';
import DocumentMetaDataWrapper from './DocumentMetaDataWrapper';
import ReplacementWrapper from './DocumentReplacementWrapper';
import DocumentViewWrapper from './DocumentViewWrapper';
import UploaderWrapper from './DocumentUploaderWrapper';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Loader from 'common/components/Loader';
import withApi from 'common/hoc/withApi';
import listParams from 'admin/components/DocumentManagement/listParams';
import ajax from 'common/utilities/ajax.js';
import history from 'common/constants/config/history';

// Here we can hack and cleanup, rename, etc the info coming down from BE
const transformTemplate = template => {
  const {
    expireOn,
    uploadedBy: { extra: uploadedBy = {} } = {},
    updatedBy: { extra: updatedBy = {} } = {},
  } = template;
  const { fullName: uploadedByName = null } = uploadedBy || {};
  const { fullName: updatedByName = null } = updatedBy || {};
  return {
    ...template,
    expireOn,
    uploadedBy: uploadedByName || '- Unknown Start+ Admin -',
    updatedBy: updatedByName || '- Unknown Start+ Admin -',
  };
};

const DocumentLibrary = props => {
  const {
    templates: { reload, status, data: { items = [], totalItems } = {} },
  } = props;
  const isLoading = status === 'loading';

  const [templateIdBeingEdited, setTemplateIdBeingEdited] = useState();
  const [templateIdBeingViewed, setTemplateIdBeingViewed] = useState();
  const [templateIdBeingReplaced, setTemplateIdBeingReplaced] = useState();

  const templateBeingEdited =
    templateIdBeingEdited &&
    items.find(({ id }) => id === templateIdBeingEdited);
  const templateBeingReplaced =
    templateIdBeingReplaced &&
    items.find(({ id }) => id === templateIdBeingReplaced);
  const updateTemplate = (id, patch) => {
    const url = `/workflow/templates/${id}/edit`;
    return ajax
      .put(url, { payload: patch }, { json: true })
      .then(() => reload());
  };

  const toggleTemplateVisibility = templateId => {
    const template = items.find(({ id }) => id === templateId);
    updateTemplate(templateId, { visible: !template.visible });
  };

  return (
    <React.Fragment>
      <LayoutContent>
        <Box>
          <BoxItem>
            <Header>
              <Header.Title>
                <span data-test-id="DocumentLibrary-title">
                  Global Document Library
                </span>
              </Header.Title>
              <Header.Nav>
                <UploaderWrapper updater={() => reload()} />
              </Header.Nav>
            </Header>
          </BoxItem>
          <BoxItem>
            {isLoading ? (
              <Loader />
            ) : (
              <DocumentManagement
                data={items.map(transformTemplate)}
                length={totalItems || 0}
                handleActiveFlag={toggleTemplateVisibility}
                handleEdit={setTemplateIdBeingEdited}
                handleReplace={setTemplateIdBeingReplaced}
                handleOpenDocViewer={setTemplateIdBeingViewed}
              />
            )}
          </BoxItem>
        </Box>
      </LayoutContent>
      <DocumentMetaDataWrapper
        isOpen={!!templateIdBeingEdited}
        name={templateBeingEdited ? templateBeingEdited.name : ''}
        expireOn={templateBeingEdited && templateBeingEdited.expireOn}
        id={templateBeingEdited && templateBeingEdited.id}
        tags={templateBeingEdited ? templateBeingEdited.categories : []}
        handleCancel={() => setTemplateIdBeingEdited(null)}
        handleSubmit={() => {
          setTemplateIdBeingEdited(null);
          reload();
        }}
      />
      {!!templateIdBeingViewed && (
        <DocumentViewWrapper
          onClose={() => setTemplateIdBeingViewed(null)}
          params={{ templateId: templateIdBeingViewed }}
        />
      )}
      {!!templateIdBeingReplaced && (
        <ReplacementWrapper
          open={!!templateIdBeingReplaced}
          oldDocument={templateBeingReplaced}
          closeHandler={() => {
            setTemplateIdBeingReplaced(null);
            reload();
          }}
          mappingHandler={() =>
            history.push({
              pathname: `/library/documents/${templateIdBeingReplaced}/replacement`,
            })
          }
        />
      )}
    </React.Fragment>
  );
};

DocumentLibrary.queries = {
  templates: {
    info(__, related) {
      const { '/router/query': query } = related;
      const cursor = listParams(query);
      return {
        id: '/workflow/templates',
        cursor,
      };
    },
  },
};

export default withApi(DocumentLibrary);
