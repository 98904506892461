import _ from 'lodash';

import React from 'react';
import domClasses from '../../utils/dom/classes.js';
import domCompatibility from '../../utils/dom/compatibility.js';
import T from '../../utils/i18n';
import Alert from './Alert';
import Button from './Button';
import DocumentIcon from 'common/icons/SingleDocIcon';
import validateFileType from 'common/utilities/validateFileType';

const getFileTypeErrorString = file_extensions => {
  var fileArr = convertAcceptTypeStrToArr(file_extensions);
  var arrLen = fileArr.length;
  var errorStr = 'We only accept ';

  _.map(fileArr, function(val, i) {
    if (i < arrLen - 1) {
      errorStr += _.upperCase(val) + ', ';
    } else {
      if (arrLen > 1) {
        errorStr += 'and ';
      }
      errorStr += _.upperCase(val);
    }
  });

  errorStr += ' file type';
  errorStr += arrLen > 1 ? 's' : '';
  errorStr += '. Please try again.';

  return errorStr;
};

const convertAcceptTypeStrToArr = accept => {
  var acceptFileArr = accept.split(',');
  _.map(acceptFileArr, function(val, i) {
    acceptFileArr[i] = val
      ? val
          .trim()
          .slice(1)
          .toLowerCase()
      : '';
  });

  return acceptFileArr;
};

class Uploader extends React.Component {
  state = {
    active: false,
    isValidFileType: this.props.isValidFileType,
  };

  componentDidMount() {
    if (this.props.enableSnapshots) {
      this.refs.snapshotInput.setAttribute('capture', 'camera');
    }
  }

  render() {
    var file = this.props.value;
    var acceptFileArr = [];
    var classes = null;
    var filename = null;
    var snapshotSeparator = null;
    var snapshotInput = null;
    var errorField = null;
    var snapshotButton = null;

    classes = domClasses.set({
      uploader: true,
      'uploader--compatible': !domCompatibility.file.fileReader(),
      'is-active': this.state.active,
    });

    acceptFileArr = convertAcceptTypeStrToArr(this.props.accept);
    if (file) {
      var errorMessage = (
        <div>
          <Alert type="error" inline={true}>
            {getFileTypeErrorString(this.props.accept)}
          </Alert>
        </div>
      );

      if (Array.isArray(file)) {
        if (
          !file.every(singleFile =>
            validateFileType(singleFile.name, acceptFileArr),
          )
        ) {
          errorField = errorMessage;
        }
      } else {
        filename = <div className="uploader-filename">{file.name}</div>;

        if (!validateFileType(file.name, acceptFileArr)) {
          errorField = errorMessage;
        }
      }
    } else {
      errorField = null;
    }

    if (this.props.enableSnapshots) {
      snapshotInput = (
        <input
          type="file"
          className="uploader-input"
          ref="snapshotInput"
          accept="image/*"
          onChange={this._inputChanged}
        />
      );

      snapshotSeparator = (
        <div className="uploader-separator">{T('components.uploader.or')}</div>
      );

      snapshotButton = (
        <Button
          secondary={true}
          onClick={this._browseSnapshotFile}
          disabled={this.props.disabled}
          inline={true}
        >
          {T('components.uploader.take_snapshot')}
        </Button>
      );
    }

    return (
      <div
        className={classes}
        onDragOver={this._dragStart}
        onDragLeave={this._dragStop}
        onDrop={this._drop}
      >
        {errorField}

        <input
          type="file"
          className="uploader-input"
          ref="input"
          accept={this.props.accept}
          onChange={this._inputChanged}
        />

        {snapshotInput}

        {filename}
        <div className="uploader-title">
          <DocumentIcon
            style={{
              height: '54px',
              display: 'block',
              margin: 'auto',
              paddingBottom: '10px',
            }}
          />
          {T('components.uploader.drag_file')}
        </div>
        <Button
          disabled={this.props.disabled}
          inline={true}
          onClick={this._browseFile}
          tertiary={true}
          wrap={true}
        >
          {T('components.uploader.browse_file')}
        </Button>
        {snapshotSeparator}
        {snapshotButton}
      </div>
    );
  }

  _dragStart = event => {
    event.preventDefault();

    this.setState({ active: true });
  };

  _dragStop = () => {
    this.setState({ active: false });
  };

  _drop = event => {
    var files = (event.dataTransfer || {}).files || [];

    event.preventDefault();

    if (files.length > 0 && !this.props.disabled) {
      this._updateFile(files[0]);
    }

    this.setState({ active: false });
  };

  _browseFile = event => {
    event.preventDefault();

    this.refs.input.click();
  };

  _browseSnapshotFile = event => {
    event.preventDefault();

    this.refs.snapshotInput.click();
  };

  _inputChanged = event => {
    this._updateFile(event.target.files ? event.target.files[0] : null);
  };

  _updateFile = file => {
    const fileName = file ? file.name : '';
    const acceptTypeArr = convertAcceptTypeStrToArr(this.props.accept);
    const validType = validateFileType(fileName, acceptTypeArr);

    if (this.props.accept) {
      this.setState({ isValidFileType: validType });
    }
    if (this.props.onChange) {
      this.props.onChange(file);
    }
    if (typeof this.props.onFileTypeChange === 'function') {
      this.props.onFileTypeChange(validType);
    }
  };
}

export default Uploader;
