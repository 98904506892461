import React from 'react';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowDropUpOutlined from '@material-ui/icons/ArrowDropUpOutlined';

const styles = makeStyles(theme => ({
  accountFormatCode: {
    color: '#3c9dd7',
    fontSize: '18px',
    fontWeight: '400',
    height: 17,
    cursor: 'pointer',
    position: 'relative',
    top: -8,
  },
  subheader: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#606060',
  },
  symbol: {
    color: '#939393',
    fontSize: '14px',
    fontWeight: '400',
  },
  symbols: {
    backgroundColor: '#f3f3f3',
    width: '50px',
    textAlign: 'center',
  },
  legendGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: '10px',
    justifyItems: 'start',
  },
  legendTitle: {
    color: '#646464',
    fontSize: '20px',
    fontWeight: 400,
    marginBottom: '15px',
  },
  usageDescription: {
    fontSize: '14px',
    color: '#939393',
    fontWeight: 400,
    marginBottom: 10,
  },
  arrow: {
    position: 'absolute',
    left: '45%',
    top: -15,
    height: 50,
    width: 50,
    color: '#f3f3f3',
  },
  tooltipContainer: {
    width: '100%',
  },
  legendCopy: {
    fontSize: '14px',
  },
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: '#606060',
    fontWeight: 400,
    padding: 20,
    border: '1px solid #dadde9',
    maxWidth: 'none',
  },
}))(Tooltip);

const AccountCodeMaskTooltip = ({ accountMask = null }) => {
  const classes = styles();

  return (
    <div className={classes.tooltipContainer}>
      <HtmlTooltip
        title={
          <React.Fragment>
            <ArrowDropUpOutlined className={classes.arrow} />
            <Typography className={classes.legendTitle}>
              Account Code Format Legend
            </Typography>
            <div className={classes.legendGrid}>
              <Typography className={classes.usageDescription}>
                Symbol
              </Typography>
              <Typography className={classes.usageDescription}>
                Usage
              </Typography>
              <Typography className={classes.symbols}>.</Typography>
              <Typography className={classes.legendCopy}>
                Digits, letters, period (.), or underscore (_)
              </Typography>
              <Typography className={classes.symbols}>#</Typography>
              <Typography className={classes.legendCopy}>
                Digits only
              </Typography>
              <Typography className={classes.symbols}>A</Typography>
              <Typography className={classes.legendCopy}>
                Letters only
              </Typography>
              <Typography className={classes.symbols}>X</Typography>
              <Typography className={classes.legendCopy}>
                Letters or digits
              </Typography>
              <Typography className={classes.symbols}>-</Typography>
              <Typography className={classes.legendCopy}>
                Mandatory dash
              </Typography>
            </div>
          </React.Fragment>
        }
        placement="bottom"
      >
        <Typography className={classes.accountFormatCode}>
          {accountMask}
        </Typography>
      </HtmlTooltip>
    </div>
  );
};

export default AccountCodeMaskTooltip;
