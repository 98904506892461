import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import classNames from 'classnames';

const styles = {
  root: {
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 8,
    display: 'grid',
    gridTemplateRows: '40px 5px auto auto',
    gridTemplateColumns: '85%  15%',
    gridTemplateAreas: `
      "title     subHeader"
      ".......   ........."
      "actions   actions"
      "content   content"
    `,
  },
  title: {
    height: '100%',
    gridArea: 'title',
    alignSelf: 'end',
    display: 'flex',
    alignItems: 'flex-end',
  },
  subHeader: {
    gridArea: 'subHeader',
    justifySelf: 'end',
    alignSelf: 'end',
  },
  actions: {
    gridArea: 'actions',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: 'repeat(3, 31%)',
    gap: '3%',
    gridGap: '3%',
  },
  content: {
    gridArea: 'content',
  },
};

const ListItem = props => {
  const {
    classes,
    dataTestIds = {},
    title,
    subHeader,
    titleIcon,
    buttons,
    children,
    className,
    id,
  } = props;
  return (
    <div
      className={classNames(classes.root, className)}
      data-test-id={dataTestIds.root}
      id={id}
    >
      <Typography
        variant="subtitle1"
        className={classes.title}
        data-test-id={dataTestIds.title}
      >
        {titleIcon}
        {title}
      </Typography>
      <Typography
        variant="caption"
        className={classes.subHeader}
        data-test-id={dataTestIds.subHeader}
      >
        {subHeader}
      </Typography>
      <div className={classes.actions}>
        {buttons.map(({ onClick, color, dataTestId, disabled, title }) => (
          <Button
            onClick={onClick}
            color={color}
            variant="contained"
            data-test-id={dataTestId}
            disabled={disabled}
            key={title}
          >
            {title}
          </Button>
        ))}
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default withStyles(styles)(ListItem);
