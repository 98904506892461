import React, { useState } from 'react';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import * as Mutations from 'common/apollo/mutations';
import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import Field from 'common/oldJavascripts/components/Base/Field';
import Header from 'common/oldJavascripts/components/Base/Header';
import T from 'common/oldJavascripts/utils/i18n';
import Link from 'common/oldJavascripts/components/Base/Link';
import Modal from 'common/oldJavascripts/components/Base/Modal';
import Text from 'common/oldJavascripts/components/Base/Text';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

import BoxParagraphItem from 'common/oldJavascripts/components/Base/Box/BoxParagraphItem';

const ERROR_MESSAGE =
  'There was an error archiving the offer. Try again after some time';

const ArchiveRejectedOffers = ({ onSubmit, onClose, params }) => {
  const [isSubmitDisabled, setSubmitState] = useState(true);
  const [isError, setError] = useState(false);
  const [comments, setComments] = useState('');

  const submit = () => {
    const id = params?.offerId;

    onSubmit({ id, comments })
      .then(({ data: { archiveRejectedOffer } = {} }) => {
        if (archiveRejectedOffer) {
          onClose({ refresh: true });
        }
      })
      .catch(e => {
        const { data = {}, message = '' } = e || {};
        const { message: graphQlErrorMessage = '' } = data || {};
        const errorMessage = `ERROR: ${
          message.length || graphQlErrorMessage.length
            ? `: ${message || graphQlErrorMessage}`
            : '.'
        }`;
        setError(true);
        console.error(errorMessage);
      });
  };

  const handleNoteChange = event => {
    const wordCount = event ? event.length : 0;
    let shouldSubmitBeDisbled = true;
    if (wordCount > 0) {
      shouldSubmitBeDisbled = false;
    }
    setComments(event);
    setSubmitState(shouldSubmitBeDisbled);
  };

  return (
    <Modal minimal={true}>
      <Box minimal={true} noShadow={true}>
        <BoxItem>
          <Header>
            <Header.Title compact={true} center={true}>
              {T('employee.offers.archive.modal.title')}
            </Header.Title>
          </Header>
        </BoxItem>
        <BoxItem minimal={true}>
          <Text paragraph={true}>
            {T('employee.offers.archive.modal.note')}
          </Text>
          {isError && (
            <Text paragraph={true} alert>
              {ERROR_MESSAGE}
            </Text>
          )}
        </BoxItem>
        <BoxItem>
          <Field
            type="textarea"
            numRows={6}
            label={T('employee.offers.modal.note_label')}
            value={comments}
            onChange={event => handleNoteChange(event)}
          />
        </BoxItem>
        <BoxParagraphItem align="center" minimal={true}>
          <Link onClick={onClose}>{T('employee.offers.modal.close')}</Link>
          <Button
            align="right"
            marginLeft={true}
            disabled={isSubmitDisabled}
            onClick={submit}
          >
            {T('employee.offers.archive.modal.submit')}
          </Button>
        </BoxParagraphItem>
      </Box>
    </Modal>
  );
};

const withArchivedOffers = graphql(Mutations.archiveRejectedOffer, {
  props: ({ mutate }) => ({
    onSubmit: ({ id, comments }) =>
      mutate({
        variables: {
          id,
          comments,
        },
      }),
  }),
});

export default compose(withArchivedOffers)(ArchiveRejectedOffers);
