import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  YoutubeSearchedFor as ZoomResetIcon,
} from '@material-ui/icons';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    alignSelf: 'center',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'repeat(3, minMax(48px, 64px)) 1fr',
    placeItems: 'center',
  },
  selfAlignStart: {
    justifySelf: 'start',
  },
  selfAlignEnd: {
    justifySelf: 'end',
  },
  iconButton: {
    padding: 0,
    color: 'white',
  },
  iconColor: {
    color: theme.palette.primary.contrastText,
  },
  withSidebar: {
    color: 'black',
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
});

const Zoom = props => {
  const {
    classes,
    setZoom,
    resetZoom,
    withSidebar,
    alignZoomEnd = false,
  } = props;
  const rootClass = classNames(classes.root, {
    [classes.selfAlignStart]: !alignZoomEnd,
    [classes.selfAlignEnd]: alignZoomEnd,
  });
  const iconClass = classNames(classes.iconButton, {
    [classes.withSidebar]: withSidebar,
  });
  return (
    <div className={rootClass}>
      <Tooltip
        title="Zoom In"
        enterDelay={500}
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          className={classes.iconButton}
          onClick={() => setZoom(0.25)}
          data-test-id="Zoom-zoomInButton"
        >
          <ZoomInIcon style={{ fontSize: 39 }} className={iconClass} />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Zoom Out"
        enterDelay={500}
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          className={classes.iconButton}
          onClick={() => setZoom(-0.25)}
          data-test-id="Zoom-zoomOutButton"
        >
          <ZoomOutIcon style={{ fontSize: 39 }} className={iconClass} />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Reset to Actual Size"
        enterDelay={500}
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          className={classes.iconButton}
          onClick={resetZoom}
          data-test-id="Zoom-zoomResetButton"
        >
          <ZoomResetIcon style={{ fontSize: 39 }} className={iconClass} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(Zoom);
