import React from 'react';
import { withStyles, Typography, Paper, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SuiteOverride from './SuiteOverride';
import classNames from 'classnames';
const styles = {
  root: {
    display: 'grid',
    gridTemplateRows: '25px 150px 50px',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
    "header"
    "addressCard"
    "suiteOverride"`,
    width: '100%',
    height: '100%',
    placeItems: 'center',
    overflow: 'hidden',
  },
  header: {
    gridArea: 'header',
  },
  addressCard: {
    gridAra: 'addressCard',
  },
  suiteOverride: {
    gridAra: 'suiteOverride',
  },
  address: {
    fontSize: '16px',
    fontWeight: '500',
    paddingLeft: 5,
  },
  addressLine1: {
    display: 'flex',
  },
  addressLine2: {
    display: 'flex',
  },
  selectedAddressPaper: {
    display: 'flex',
    width: 400,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeSelectedAddressButton: {
    marginTop: -95,
  },
  addressText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 80%',
    paddingLeft: 75,
  },
};

const SelectedAddress = props => {
  const {
    getStateCode,
    clearAddress,
    fullAddress,
    suiteOverrideOpen,
    updateSuiteOverride,
    handleSuiteOverrrideKeyPress,
    saveAddressSuite,
    openSuiteOverride,
    suiteOverrideValue,
    type,
    className,
    classes,
  } = props;
  const {
    city,
    state_id: stateId,
    suite,
    zip_code: zipCode,
    street,
  } = fullAddress;
  return (
    <div
      className={classNames(className, classes.root)}
      data-test-id={`SelectedAddress-root-${type}`}
    >
      <Typography
        type="title"
        className={classes.header}
        data-test-id={`SelectedAddress-header-${type}`}
      >
        Selected Address:{' '}
      </Typography>
      <Paper
        className={classNames(
          classes.addressCard,
          classes.selectedAddressPaper,
        )}
        data-test-id={`SelectedAddress-paper-${type}`}
      >
        <div
          className={classes.addressText}
          data-test-id={`SelectedAddress-addressContainer-${type}`}
        >
          <div
            className={classes.addressLine1}
            data-test-id={`SelectedAddress-addressLine1-${type}`}
          >
            <Typography
              className={classes.address}
              data-test-id={`SelectedAddress-street-${type}`}
            >
              {street}
            </Typography>
            <Typography
              className={classes.address}
              data-test-id={`SelectedAddress-suite-${type}`}
            >
              {suite}
            </Typography>
          </div>
          <div
            className={classes.addressLine2}
            data-test-id={`SelectedAddress-addressLine2-${type}`}
          >
            <Typography
              className={classes.address}
              data-test-id={`SelectedAddress-city-${type}`}
            >{`${city}, `}</Typography>
            <Typography
              className={classes.address}
              data-test-id={`SelectedAddress-state-${type}`}
            >
              {getStateCode(stateId)}
            </Typography>
            <Typography
              className={classes.address}
              data-test-id={`SelectedAddress-zipCode-${type}`}
            >
              {zipCode}
            </Typography>
          </div>
        </div>
        <IconButton
          className={classes.closeSelectedAddressButton}
          onClick={clearAddress}
          data-test-id={`SelectedAddress-clearAddress-${type}`}
        >
          <Close />
        </IconButton>
      </Paper>
      <SuiteOverride
        className={classes.suiteOverride}
        open={suiteOverrideOpen}
        onChange={updateSuiteOverride}
        onKeyDown={handleSuiteOverrrideKeyPress}
        value={suiteOverrideValue}
        saveAddressSuite={saveAddressSuite}
        openSuiteOverride={openSuiteOverride}
      />
    </div>
  );
};

export default withStyles(styles)(SelectedAddress);
