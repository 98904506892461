import React, { Component } from 'react';
import moment from 'moment';
import Button from 'common/oldJavascripts/components/Base/Button';
import Link from 'common/oldJavascripts/components/Base/Link';
import Field from 'common/oldJavascripts/components/Base/Field';
import Form from 'common/oldJavascripts/components/Base/Form';
import FormField from 'common/oldJavascripts/components/Base/FormField';
import DatePicker from 'common/oldJavascripts/components/Base/DatePickerField';
import PrivilegesPicker from 'common/oldJavascripts/components/Shared/Form/PrivilegesPicker';
import {
  validateName,
  cleanName,
} from 'common/oldJavascripts/utils/validateName';
import { isFormComplete } from 'common/oldJavascripts/utils/isFormComplete';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { pushNotification } from 'common/store/actions/snackbarNotifications';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

// HoC
import { connect } from 'react-redux';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import { withStyles } from '@material-ui/core/styles';

// Utilities
import phoneNumberInputOnChange from 'common/oldJavascripts/utils/phoneNumberInputOnChange';
import { AsYouType } from 'libphonenumber-js';
import history from 'common/constants/config/history';

const styles = theme => ({
  root: {
    height: 'calc(100% - 122px)',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'auto',
    placeItems: 'center',
    overflowY: 'auto',
  },
  paper: {
    width: 500,
    margin: '15px 0px',
  },
  content: {
    padding: '22px 30px',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '60px auto',
  },
  header: {
    height: '40px',
    fontWeight: 700,
    fontSize: '24px',
    color: 'rgb(67, 79, 89)',
  },
  footer: {
    padding: '22px 30px',
    display: 'grid',
    background: '#fbfbfb',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'end',
  },
  datePickerContainer: {
    display: 'grid',
    margin: 0,
  },
  datePickerLabel: {
    margin: 0,
    paddingLeft: 2,
  },
  actions: {
    textAlign: 'right',
  },
});

class NewInvitation extends Component {
  static mutators = {
    invitation: {
      info(_, related) {
        const params = related['/router/params'];

        return {
          id: `/projects/${params.projectId}/invitations`,
          create: true,
          append: true,
          initial: {
            send_date: moment().format('YYYY-MM-DD'),
          },
        };
      },

      stale(_, related) {
        const params = related['/router/params'];

        return [
          `/projects/${params.projectId}/non-employees`,
          `/projects/${params.projectId}/invitations`,
          `/projects/${params.projectId}/users`,
        ];
      },
    },
  };

  formatPhoneNumber = number => {
    // we do not start formatting the number until it's greater than 4 digits
    // this prevents a bug that prevents the user from deleting their area code
    if (!/(.?\d){4,}/.test(number)) return number;
    const asYouType = new AsYouType('US');
    // return the formatted phone number
    return asYouType.input(number);
  };

  updatePhoneNumber = (number, target) => {
    const { invitation: invitationMutator } = this.props;
    return phoneNumberInputOnChange(number, target, 'phone', invitationMutator);
  };

  render() {
    const { routerParams = {}, classes } = this.props;
    const { projectId } = routerParams || {};
    const invitationMutator = this.props.invitation;
    const invitation = invitationMutator.data || {};
    const errors = invitationMutator.errors || {};
    const sendInviteButtonStatus = this._checkSendInviteButtonStatus();
    const sendDateMoment = invitation.send_date
      ? moment(invitation.send_date, 'YYYY-MM-DD')
      : moment();

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Form
            action={`/projects/${projectId}/invitations`}
            method="post"
            onSubmit={this._submitForm}
          >
            <div className={classes.content}>
              <Typography className={classes.header}>
                <span data-test-id="New-title">Profile information</span>
              </Typography>
              <span data-test-id="New-firstName">
                <Field
                  label="First Name"
                  type="text"
                  name="first_name"
                  value={cleanName(invitation.first_name)}
                  errors={errors.first_name}
                  onChange={value =>
                    invitationMutator.update('first_name')(validateName(value))
                  }
                  required={true}
                  id="first_name"
                />
              </span>
              <span data-test-id="New-lastName">
                <Field
                  label="Last Name"
                  type="text"
                  name="last_name"
                  value={cleanName(invitation.last_name)}
                  errors={errors.last_name}
                  onChange={value =>
                    invitationMutator.update('last_name')(validateName(value))
                  }
                  required={true}
                  id="last_name"
                />
              </span>
              <span data-test-id="New-email">
                <Field
                  label="Email"
                  type="text"
                  name="email"
                  value={invitation.email}
                  errors={errors.email}
                  onChange={invitationMutator.update('email')}
                  required={true}
                  id="email"
                />
              </span>
              <span data-test-id="New-phone">
                <Field
                  label="Phone"
                  type="phone"
                  name="phone"
                  value={this.formatPhoneNumber(invitation.phone)}
                  errors={errors.phone}
                  onChange={(value, target) =>
                    this.updatePhoneNumber(value, target)
                  }
                  id="phone"
                />
              </span>
              <span data-test-id="New-jobTitle">
                <Field
                  label="Title"
                  type="text"
                  name="title"
                  value={invitation.title}
                  errors={errors.title}
                  onChange={invitationMutator.update('title')}
                  required={true}
                  id="title"
                />
              </span>
              <PrivilegesPicker mutator={invitationMutator} />
            </div>
            <div className={classes.footer}>
              <div data-test-id="New-sendDate">
                <FormField
                  className={classes.datePickerContainer}
                  label="Send on:"
                  required={true}
                  grouped={false}
                  classes={{ label: classes.datePickerLabel }}
                >
                  <DatePicker
                    name="send_date"
                    id="send_date"
                    isClearable={false}
                    selected={sendDateMoment}
                    minDate={moment()}
                    maxDate={moment().add(90, 'days')}
                    onChange={value =>
                      invitationMutator.update('send_date')(
                        value && value.format('YYYY-MM-DD'),
                      )
                    }
                  />
                </FormField>
              </div>
              <div className={classes.actions}>
                <Link
                  to={`/projects/${projectId}/users`}
                  data-test-id="New-cancel"
                >
                  cancel
                </Link>
                {' or '}
                <Button
                  disabled={sendInviteButtonStatus}
                  onClick={this._showSuccess}
                  data-test-id="New-invite"
                >
                  Send invite
                </Button>
              </div>
            </div>
          </Form>
        </Paper>
      </div>
    );
  }

  _checkSendInviteButtonStatus = () => {
    const invitationFormData = this.props.invitation.data;
    const requiredFields = ['first_name', 'last_name', 'email', 'title'];
    return !isFormComplete(invitationFormData, requiredFields);
  };

  _submitForm = () => {
    const {
      pushNotification,
      routerParams: { projectId } = {},
      invitation = {},
    } = this.props;

    invitation.save('new', {}, () => {
      pushNotification({
        message: 'Invitation sent.',
        variant: SnackbarVariants.SUCCESS,
      });
      history.push(`/projects/${projectId}/invitations`);
    });
  };
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
};

export default compose(
  withApi,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(NewInvitation);
