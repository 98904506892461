import * as FieldTypes from '../../utilities/constants/fieldTypes';

// These are the only field types we respect isRequired for
const REQUIRED_FIELD_TYPES = [
  FieldTypes.TXT,
  FieldTypes.RDO,
  FieldTypes.CMB,
  FieldTypes.CHK,
  FieldTypes.SIGNATURE,
  FieldTypes.INITIAL_SIGNATURE,
  FieldTypes.UNVALIDATED_SIGNATURE,
  FieldTypes.UNVALIDATED_INITIAL_SIGNATURE,
  FieldTypes.DATE,
  FieldTypes.DATE_SIGNED,
  FieldTypes.NUMBER,
];

const SIGNATURE_FIELD_TYPES = [
  FieldTypes.SIGNATURE,
  FieldTypes.INITIAL_SIGNATURE,
  FieldTypes.UNVALIDATED_SIGNATURE,
  FieldTypes.UNVALIDATED_INITIAL_SIGNATURE,
];

const INITIAL_SIGNATURE_FIELD_TYPES = [
  FieldTypes.INITIAL_SIGNATURE,
  FieldTypes.UNVALIDATED_INITIAL_SIGNATURE,
];

const VALIDATED_SIGNATURE_FIELD_TYPES = [
  FieldTypes.INITIAL_SIGNATURE,
  FieldTypes.SIGNATURE,
];

const frontendFieldIsRequired = field => {
  const { isRequired, isDisabled } = field;

  // Field cannot be required if the user can not interact
  if (isDisabled) return false;

  // Check if the field type sent is not present in our required field types
  // All other field types cannot be required
  const fieldType = getFrontendFieldType(field);
  if (!REQUIRED_FIELD_TYPES.includes(fieldType)) return false;

  // Lastly defer to the key isRequired
  return isRequired;
};

const getFrontendFieldType = ({ fieldType, textType, validateSignature }) => {
  const textTypeUpper = (textType || '').toUpperCase();

  if (textTypeUpper === 'STRING') return FieldTypes.TXT;
  if (textTypeUpper === 'DATE_SIGNED') return FieldTypes.DATE_SIGNED;

  if (textTypeUpper === 'SIGNATURE' || textTypeUpper === 'INITIAL_SIGNATURE') {
    const prefixedTextType =
      (validateSignature === false ? 'UNVALIDATED_' : '') + textTypeUpper;
    return FieldTypes[prefixedTextType];
  }

  if (textTypeUpper && FieldTypes[textTypeUpper])
    return FieldTypes[textTypeUpper];

  return FieldTypes[fieldType];
};

const getSignatureTypeInfo = field => {
  const fieldType = getFrontendFieldType(field);
  const isSignature = !!SIGNATURE_FIELD_TYPES.includes(fieldType);
  const isInitials = INITIAL_SIGNATURE_FIELD_TYPES.includes(fieldType);
  const isValidated = VALIDATED_SIGNATURE_FIELD_TYPES.includes(fieldType);
  return { isInitials, isSignature, isValidated };
};

const getSystemFieldValue = field => {
  const { _derived, externalName, value } = field;
  const { externalNameValue = null } = _derived || {};
  if (externalNameValue != null) return externalNameValue;
  return !!externalName && value;
};

// Adds initial `_derived` object to a field before rules are run on it.
const addFieldInfo = field => ({
  ...field,
  _derived: {
    // Add isInitials, isSignature, isValidated
    ...getSignatureTypeInfo(field),
    isDisabled: field.isDisabled,
    isRequired: frontendFieldIsRequired(field),
    fieldType: getFrontendFieldType(field),
    externalNameValue: getSystemFieldValue(field),
    readOnlyTooltip: '',
  },
});

export default addFieldInfo;
