import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import ArchiveOfferModal from './Offers/Modal/ArchiveModal';
import ArchiveRejectedOffersModal from './Offers/Modal/ArchiveRejectedOffersModal';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Box from 'common/oldJavascripts/components/Base/Box';

import Header from 'common/oldJavascripts/components/Base/Header';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import OfferPacketsTable from 'studio/oldJavascripts/components/Shared/OfferPacketsTable';
import OffersTableHeader from './Offers/TableHeader';
import OffersTableRow from './Offers/TableRow';
import OfferFreeFormFields from './Offers/OfferFreeFormFields';
import Pagination from 'common/oldJavascripts/components/Base/Pagination';
import Loader from 'common/components/Loader';
import RateScaleRow from 'common/oldJavascripts/components/Shared/RateScaleRow';
import PacketApprovalFlow from 'studio/oldJavascripts/components/Shared/Packets/PacketApprovalFlow';
import RescindOfferModal from './Offers/Modal/RescindModal';
import Search from 'common/oldJavascripts/components/Shared/Search';
import StatusRow from 'common/oldJavascripts/components/Shared/StatusRow';
import config from 'common/oldJavascripts/config.js';
import AdditionalDealTerms from 'common/oldJavascripts/components/Pages/Project/Offers/AdditionalDealTerms.js';
import TypographyLink from './TypographyLink.js';
import OfferFullHistory from './Offers/OfferFullHistory';
import QueryRefresher from 'common/components/QueryRefresher';
import moment from 'moment';

// HoC
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withPermissionProtection from 'common/hoc/withPermissionProtection';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const styles = () => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
  optOutText: {
    marginLeft: '20px',
  },
});

class ManageOffersTable extends Component {
  static queries = {
    offers: {
      info(_, related) {
        const query = related['/router/query'];
        const routerParams = related['/router/params'];
        const {
          page = 1,
          per_page = config.DEFAULT_PER_PAGE,
          sort,
          direction,
          q,
        } = query || {};
        return {
          id: `/projects/${routerParams.projectId}/offers`,

          cursor: {
            page: page,
            per_page: per_page || config.DEFAULT_PER_PAGE,
            sort: sort,
            direction: direction,
            q: q,
          },
        };
      },
    },
    project: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: '/projects/' + params.projectId,
        };
      },
    },
  };

  state = {
    expandedOffersDetails: [],
    expandedOffersStatus: [],
    expandedPacketFlow: {},
    showModal: false,
    modalType: 'archive',
    offerStatus: null,
    modalOfferId: 0,
    isFreeFormFieldsShown: null,
    whichFreeFormField: null,
  };

  _renderModal = () => {
    const { offerStatus, modalType, showModal, modalOfferId } = this.state;
    if (showModal) {
      const params = { offerId: modalOfferId };

      if (modalType === 'archive') {
        if (offerStatus === 'rejected' || offerStatus === 'rejected_employee') {
          // render new modal since the graphql mutation archiveRejectedOffers
          // only handles rejected/rejected_employee offers, so we need to keep both for the time being
          return (
            <ArchiveRejectedOffersModal
              params={params}
              onClose={this._modalClose}
            />
          );
        } else {
          return (
            <ArchiveOfferModal params={params} onClose={this._modalClose} />
          );
        }
      } else {
        return <RescindOfferModal params={params} onClose={this._modalClose} />;
      }
    }
  };

  _renderTable = ({ items: offers = [] } = {}) => {
    const { project: projectQuery = {}, classes } = this.props;
    const { data: projectData = {} } = projectQuery || {};
    const { allow_end_date: endDateAllowed } = projectData || {};

    const rows = offers.map(this._renderTableRow);

    return (
      <ExpandableTable className={classes.table}>
        <OffersTableHeader endDateAllowed={endDateAllowed} />
        {rows}
      </ExpandableTable>
    );
  };

  _renderTableRow = offer => {
    const endDateAllowed = this.props.project.data.allow_end_date;
    const expandedOffersDetails = this.state.expandedOffersDetails;
    const expandedOffersStatus = this.state.expandedOffersStatus;
    const expandedPacketFlow = this.state.expandedPacketFlow;
    const isDetailsExpanded = expandedOffersDetails.indexOf(offer.id) !== -1;
    const isStatusExpanded = expandedOffersStatus.indexOf(offer.id) !== -1;
    const packetFlowExpanded = expandedPacketFlow[offer.id];
    const projectBackDate = this.props.project.data.backdate_days_limit;
    const offerStartDate = offer.start_date;
    const isOfferExpired = moment()
      .subtract(projectBackDate, 'days')
      .isAfter(moment(offerStartDate));
    let offerDescription = [];
    offerDescription.push(
      <OffersTableRow
        key={offer.id}
        endDateAllowed={endDateAllowed}
        offer={offer}
        params={{
          offer_id: offer.id,
          offerExpired: isOfferExpired,
        }}
        detailsExpanded={isDetailsExpanded}
        statusExpanded={isStatusExpanded}
        onToggleDetails={this._toggleOfferDetails}
        onToggleStatus={this._toggleOfferStatus}
        onShowModal={this._showModal}
        data-test-id={`Offers-row-${offer.id}`}
      />,
    );
    //render all of the extra detail offers when the table row is expanded
    if (isDetailsExpanded) {
      offerDescription.push(
        this._renderJobDescription(offer),
        this._renderFreeFormFieldsLink(offer),
        this._renderRateScale(offer),
        this._renderAllowances(offer),
        this._renderPackets(offer, expandedPacketFlow),
        this._renderNotes(offer),
      );
      //render the packet approval flow component when the packets icon is clicked
      if (packetFlowExpanded) {
        offerDescription.push(
          this._renderPacketApprovalFlow(offer, packetFlowExpanded),
        );
      }
    }
    if (isStatusExpanded) {
      offerDescription.push(this._renderOfferFullHistory(offer));
    }
    return offerDescription;
  };

  _renderNotes = offer => {
    if (offer.notes) {
      return (
        <ExpandableTableRow rowExpanded={true}>
          <ExpandableTableCell colSpan={10} expanded={true}>
            <AdditionalDealTerms
              title="Additional Deal Terms"
              notes={offer.notes || ''}
              oldNotes={[...(offer.old_notes || [])].reverse()}
              headerStyles={{
                color: '#3c9dd7',
                marginLeft: '8px',
                textTransform: 'uppercase',
                fontSize: '0.9rem',
              }}
              currentDate={offer.sent_at}
            />
          </ExpandableTableCell>
        </ExpandableTableRow>
      );
    }
    return null;
  };

  _renderJobDescription = offer => {
    if (offer.custom_occupation_description) {
      return (
        <ExpandableTableRow
          key={`custom-occupation-description-${offer.id}`}
          rowExpanded={true}
        >
          <ExpandableTableCell colSpan={10} expanded={true}>
            <ExpandableTableRowHeader>Job Description</ExpandableTableRowHeader>
            <p style={{ marginLeft: 8 }}>
              {offer.custom_occupation_description}
            </p>
          </ExpandableTableCell>
        </ExpandableTableRow>
      );
    }
    return null;
  };

  _renderFreeFormFieldsLink = offer => {
    return (
      <ExpandableTableRow
        key={`custom-free-form-fields-${offer.id}`}
        rowExpanded
      >
        <ExpandableTableCell
          colSpan={10}
          expanded
          data-test-id={`Offers-AccountCodeLink-${offer.id}`}
        >
          <TypographyLink
            onClick={() =>
              this._showFreeFormFieldsByOffer({ offerId: offer.id })
            }
            title={'Set Account Codes'}
          />
        </ExpandableTableCell>
      </ExpandableTableRow>
    );
  };

  _renderPagination = offers => {
    return <Pagination totalPages={offers.total_pages} page={offers.page} />;
  };

  _renderRateScale = offer => {
    return (
      <RateScaleRow
        key={`rate-${offer.id}`}
        rowExpanded
        offer={offer}
        colSpan={10}
      />
    );
  };

  _renderAllowances = offer => {
    return (
      <AllowancesRow
        key={`allowance-${offer.id}`}
        rowExpanded={true}
        colSpan={10}
        offer={offer}
      />
    );
  };

  _renderPackets = (offer, expandedWorkflow) => {
    const { classes = {} } = this.props;
    const optedOut = offer.status === 'complete_on_paper';
    return (
      <ExpandableTableRow
        key={`packet-documents-${offer.id}`}
        rowExpanded={true}
      >
        <ExpandableTableCell colSpan={10} expanded={true}>
          <ExpandableTableRowHeader>Offer Documents</ExpandableTableRowHeader>
          <ExpandableTableRowInfo>
            {optedOut && (
              <Typography
                className={classes.optOutText}
                data-test-id="OfferDocuments-optOutText"
              >
                Employee has chosen to complete the offer on paper and cannot
                complete this offer in Start+.
              </Typography>
            )}
            {!optedOut && (
              <OfferPacketsTable
                hidePermanent={false}
                showHeader={false}
                rowExpanded={true}
                colSpan={10}
                width="100%"
                params={{
                  scope: `/v2/offers/${offer.id}`,
                  offerId: offer.id,
                  hasPackets: offer.has_packets,
                }}
                expandedWorkflow={expandedWorkflow}
                showAction={true}
                actionClickHandler={this._togglePacketFlow}
              />
            )}
          </ExpandableTableRowInfo>
        </ExpandableTableCell>
      </ExpandableTableRow>
    );
  };

  _renderPacketApprovalFlow = (offer, packetId) => {
    const { has_packets } = offer;
    if (has_packets) {
      return (
        <ExpandableTableRow
          key={`packet-flow-${offer.id}-${packetId}`}
          rowExpanded={true}
        >
          <ExpandableTableCell colSpan={10} expanded={true}>
            <ExpandableTableRowHeader>
              Packet Approval Flow
            </ExpandableTableRowHeader>
            <ExpandableTableRowInfo>
              <PacketApprovalFlow params={{ offerId: offer.id, packetId }} />
            </ExpandableTableRowInfo>
          </ExpandableTableCell>
        </ExpandableTableRow>
      );
    }
  };

  _renderOfferLog = offer => {
    return <StatusRow key={`status-${offer.id}`} offer={offer} colSpan={10} />;
  };

  _renderOfferFullHistory = offer => {
    return (
      <ExpandableTableRow>
        <ExpandableTableCell colSpan={10} expanded>
          <Header>
            <Header.Title secondary>Offer Full History</Header.Title>
          </Header>
          <OfferFullHistory offer={offer} />
        </ExpandableTableCell>
      </ExpandableTableRow>
    );
  };

  _toggleOfferDetails = id => {
    var expandedOffers = this.state.expandedOffersDetails.slice(0);
    const index = expandedOffers.indexOf(id);

    if (index !== -1) {
      expandedOffers.splice(index, 1);
    } else {
      expandedOffers.push(id);
    }

    var newState = this._resetState(id, 'expandedOffersStatus');
    newState['expandedOffersDetails'] = expandedOffers;
    this.setState(newState);
  };

  _toggleOfferStatus = id => {
    var expandedOffers = this.state.expandedOffersStatus.slice(0);
    const index = expandedOffers.indexOf(id);

    if (index !== -1) {
      expandedOffers.splice(index, 1);
    } else {
      expandedOffers.push(id);
    }

    var newState = this._resetState(id, 'expandedOffersDetails');
    newState['expandedOffersStatus'] = expandedOffers;
    this.setState(newState);
  };

  _togglePacketFlow = (offerId, packetId) => {
    if (packetId) {
      this.setState({
        expandedPacketFlow: {
          [offerId]: packetId,
        },
      });
      return;
    }
    this.setState({ expandedPacketFlow: {} });
  };

  _resetState = (id, type) => {
    const list = this.state[type].slice(0);
    const index = list.indexOf(id);
    var ret = { [type]: [] };

    if (index !== 1) {
      list.splice(index, 1);
      ret[type] = list;
    }

    return ret;
  };

  _showModal = (type, offerId, offerStatus) => {
    this.setState({
      showModal: true,
      modalOfferId: offerId,
      modalType: type,
      offerStatus,
    });
  };

  _modalClose = options => {
    this.setState({ showModal: false });
    if (options && options.refresh) {
      this.props.offers.reload();
    }
  };

  _showFreeFormFieldsByOffer = offer => {
    this.setState({ isFreeFormFieldsShown: offer.offerId });
  };

  _hideFreeFormFieldsByOffer = offerId => {
    this.setState({ isFreeFormFieldsShown: null });
  };

  render() {
    const {
      offers: offersQuery = {},
      project: projectQuery,
      classes = {},
    } = this.props;
    const { status: projectStatus, data: project = {} } = projectQuery || {};
    const { status: offersStatus, data: offers = {}, reload: reloadOffers } =
      offersQuery || {};
    const { items: offersItems = [] } = offers;
    const { id: projectId } = project;

    const { isFreeFormFieldsShown } = this.state;

    const isLoading = offersStatus === 'loading' || projectStatus === 'loading';
    const displayContent = !isLoading && offersItems.length > 0;
    const displayBlankSlate = !isLoading && offersItems.length === 0;
    const displayFreeFormFields = !isLoading && isFreeFormFieldsShown;

    return (
      <LayoutContent extraPadding>
        <Box>{this._renderModal()}</Box>
        <Box>
          <BoxItem>
            <Header>
              <Header.Title>Offers</Header.Title>
              <QueryRefresher reloadQuery={reloadOffers} />
              <Header.Nav>
                <Search />
              </Header.Nav>
            </Header>
          </BoxItem>
          {isLoading && <Loader className={classes.loader} />}
          {displayContent && this._renderTable(offers)}
          {displayBlankSlate && <Blankslate>No Offers</Blankslate>}
        </Box>
        <Box>
          {displayFreeFormFields && (
            <OfferFreeFormFields
              offer={offersItems.find(
                offer => offer.id === isFreeFormFieldsShown,
              )}
              offerId={isFreeFormFieldsShown}
              projectId={projectId}
              project={project}
              onSave={this._saveFreeFormFields}
              onClose={this._hideFreeFormFieldsByOffer}
            />
          )}
        </Box>
        {displayContent && this._renderPagination(offers)}
      </LayoutContent>
    );
  }
}

export default compose(
  withPermissionProtection(['can_create_offers', 'can_review_offers']),
  withApi,
  withStyles(styles),
)(ManageOffersTable);
