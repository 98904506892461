// React
import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
// Mui Components
import MuiSnackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
// Utilities
import classNames from 'classnames';
// Colors
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import amber from '@material-ui/core/colors/amber';

const variantIcon = variant => {
  const icons = {
    SUCCESS: { Icon: CheckCircleIcon, iconId: 'Success' },
    WARNING: { Icon: WarningIcon, iconId: 'Warning' },
    ERROR: { Icon: ErrorIcon, iconId: 'Error' },
    INFO: { Icon: InfoIcon, iconId: 'Info' },
  };
  return icons[variant] || icons.INFO;
};

const styles = theme => ({
  SUCCESS: {
    backgroundColor: green[600],
  },
  ERROR: {
    backgroundColor: theme.palette.error.dark,
  },
  INFO: {
    backgroundColor: blue[700],
  },
  WARNING: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    color: theme.palette.secondary.contrastText,
  },
});

const Snackbar = ({
  open,
  message,
  onExited,
  handleClose,
  variant,
  classes,
  duration = 3000,
  className,
}) => {
  const { Icon, iconId } = variantIcon(variant);
  return (
    <MuiSnackbar
      key={message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      onExited={onExited}
      data-test-id="Snackbar-root"
    >
      <SnackbarContent
        className={classNames(classes[variant], className, classes.message)}
        aria-describedby="message"
        message={
          <span
            id="message"
            className={classes.message}
            data-test-id="Snackbar-message"
          >
            <Icon
              className={classNames(classes.icon, classes.iconVariant)}
              data-test-id={`Snackbar-icon-${iconId}`}
            />
            {message}
          </span>
        }
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
            data-test-id="Snackbar-close"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        }
        data-test-id={`Snackbar-content-${variant}`}
      />
    </MuiSnackbar>
  );
};

export default withStyles(styles)(Snackbar);
