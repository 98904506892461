import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Cell from './Cell';
import { createOfferCurrency } from '../../../utils/format';
import { formatCurrency } from 'common/utilities/formatCurrency';

const DEFAULT_FRACTIONAL_DIGITS = { numeric: 2, currency: 4 };
const EMPTY_CONTENT = '—'; // em dash
const MAX_CURRENCY = 9999999.9999;
const MAX_NUMERIC = 99.99;

const styles = {
  root: {
    padding: '10px',
    border: '1px solid #d8d8d8',
  },
  active: {
    background: 'rgba(70, 159, 204, 0.2)',
    border: '1px double #469fcc !important',
    padding: '0',
  },
  editable: {
    border: '2px solid transparent',
    cursor: 'pointer',
  },
  warning: { color: '#ea5a5a' },
  disabled: {
    cursor: 'not-allowed',
    '& > div': {
      outline: 'none',
    },
  },
  cellInput: {
    background: 'transparent',
    border: 'none',
    outline: '0',
    padding: '10px',
    textAlign: 'center',
    width: '100%',
  },
};

class EditableCell extends Component {
  state = {
    editMode: false,
    value: '',
    warning: false,
  };

  render() {
    const { editMode, warning } = this.state;
    const {
      disabled,
      errors,
      classes,
      borderBottom,
      noBorder,
      width = 'auto',
      'data-test-id': dataTestId,
    } = this.props;
    const isInvalid = errors && errors.length;
    const rootClass = classNames(classes.root, {
      [classes.editable]: warning || isInvalid,
      [classes.warning]: warning || isInvalid,
      [classes.disabled]: disabled,
      [classes.active]: editMode,
    });
    return (
      <Cell
        align={this.props.align}
        className={rootClass}
        onClick={this._getFocusHandler()}
        colSpan={this.props.colSpan}
        borderBottom={borderBottom}
        noBorder={noBorder}
        width={width}
        data-test-id={dataTestId}
      >
        {this._renderCellContent()}
      </Cell>
    );
  }

  _getEditValue = () => {
    const value = this.props.value;
    if (!value || (Number(value) === 0 && this.props.ignoreZero)) {
      return '';
    }
    return value;
  };

  _getEndValue = value => {
    const format = this.props.format;
    const number = Number(value);

    if ((format === 'currency' || format === 'numeric') && number) {
      return this._precisionRound(value, this._getFractionalDigits());
    }

    return value;
  };

  _getFocusHandler = () => {
    return this.state.editMode && !this.props.disabled
      ? null
      : this._onEditStart;
  };

  _getFractionalDigits = () => {
    if (typeof this.props.fractionalDigits === 'number') {
      return this.props.fractionalDigits;
    }
    return DEFAULT_FRACTIONAL_DIGITS[this.props.format];
  };

  _getValueState = value => {
    const { format, isCurrency = false } = this.props;

    if (format === 'numeric' || format === 'currency') {
      value = value.replace(/[^\d.-]/g, '');
    }
    if (isCurrency) {
      value = formatCurrency(value);
    }
    return {
      value,
    };
  };

  _getRangedValue = value => {
    const format = this.props.format;

    const numericValue = Number(value);
    const onMinWarning = this.props.onMinWarning;
    if (onMinWarning) {
      const minValueWarning = Number(this.props.minValueWarning);
      if (numericValue < minValueWarning) {
        return {
          value:
            this.props.onMinWarning(numericValue, minValueWarning) || value,
          warning: true,
        };
      }
    }

    const onMaxWarning = this.props.onMaxWarning;
    if (onMaxWarning) {
      const maxValueWarning = Number(this.props.maxValueWarning);
      if (numericValue > maxValueWarning) {
        return {
          value: onMaxWarning(numericValue, maxValueWarning) || value,
          warning: true,
        };
      }
      if (format === 'currency' && numericValue > MAX_CURRENCY) {
        return {
          value: onMaxWarning(numericValue, MAX_CURRENCY) || value,
          warning: true,
        };
      }
      if (format === 'numeric' && numericValue > MAX_NUMERIC) {
        return {
          value: onMaxWarning(numericValue, MAX_NUMERIC) || value,
          warning: true,
        };
      }
    }
    return {
      value,
      warning: false,
    };
  };

  _onChange = event => {
    if (this.props.onChange) {
      const state = this._getValueState(event.target.value);
      this.setState(state);
      this.props.onChange(state.value);
    }
  };

  _onEditStart = () => {
    this.setState({
      editMode: !this.props.disabled,
      value: this._getEditValue(),
    });
  };

  _onEditStop = () => {
    const ranged = this._getRangedValue(this.state.value);
    const value = this._getEndValue(ranged.value);

    this.setState(
      Object.assign(ranged, {
        editMode: false,
      }),
    );

    if (this.props.onBlur) {
      this.props.onBlur(value);
    }
  };

  _renderCellContent = () => {
    if (this.state.editMode && !this.props.disabled) {
      return this._renderEditableCellContent();
    }
    const format = this.props.format;
    const value = this.props.value;
    const number = Number(value);
    if (format === 'currency' && number) {
      return this._renderCurrencyCellContent();
    }
    if (format === 'numeric' && number) {
      return this._renderNumericCellContent();
    }
    if (!value || (number === 0 && this.props.ignoreZero)) {
      return EMPTY_CONTENT;
    }
    return value;
  };

  _renderCurrencyCellContent = () => {
    const value = Number(this.props.value);
    return createOfferCurrency(
      this._precisionRound(value, this._getFractionalDigits()),
      this._getFractionalDigits(),
    );
  };

  _renderEditableCellContent = () => {
    const { classes, maxLength = null } = this.props;
    return (
      <input
        autoFocus={true}
        className={classes.cellInput}
        onBlur={this._onEditStop}
        onChange={this._onChange}
        type="text"
        disabled={this.props.disabled}
        value={this.state.value}
        maxLength={maxLength}
      />
    );
  };

  _precisionRound = (number, precision) => {
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  };

  _renderNumericCellContent = () => {
    const value = Number(this.props.value);
    if (isNaN(value)) {
      return EMPTY_CONTENT;
    }
    return this._precisionRound(value, this._getFractionalDigits());
  };
}

export default withStyles(styles)(EditableCell);
