import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Table,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import ProjectTemplatesFooter from './ProjectTemplatesFooter';
import ProjectTemplate from './ProjectTemplate';

// Import Constants
import * as ApproverRoles from 'common/utilities/constants/approverRoles';

const styles = theme => ({
  tableRoot: {
    // Stop the table automatically resizing to the width of the content
    tableLayout: 'fixed',
  },
  tableCell: {
    // Fix a bizarre bug where including the footer causes the document
    // td width to shrink to -1px
    width: '100%',
  },
  expansionPanel: {
    margin: theme.spacing.unit * 1.875,
  },
});

class ProjectTemplates extends PureComponent {
  state = {
    deleteId: '',
    deleteDialogOpen: false,
    documents: this.props.documents,
    classes: this.props.classes,
  };

  componentDidUpdate(prevProps) {
    const {
      templateLoadingStatus: prevLoadingStatus,
      documents: prevDocuments,
    } = prevProps;
    const {
      templateLoadingStatus: currLoadingStatus,
      documents: currDocuments,
    } = this.props;
    const prevDocIds = prevDocuments.map(({ id }) => id).join(' ');
    const currDocIds = currDocuments.map(({ id }) => id).join(' ');

    if (
      (prevLoadingStatus === 'loading' && currLoadingStatus === 'success') ||
      prevDocIds !== currDocIds
    ) {
      this.setState({ documents: currDocuments });
    }
  }

  updateDoc = ({ id, value, key, subKey = false, update = false }) => {
    const { handleUpdate } = this.props;
    this.setState(({ documents }) => {
      // Create new copy of documents array
      const newDocuments = [...documents];
      // Get an array of just the document id's
      const docIds = newDocuments.map(doc => doc.id);
      // Get the index of the document in the array
      const docIndex = docIds.indexOf(id);
      // Create a copy of that specific document
      const updateObject = {
        ...newDocuments[docIndex],
      };
      // If there is a sub key this data is stored as an object
      // And we need to replace a key inside of that object
      // For example we are getting new step data from a workflow
      if (subKey) {
        if (!updateObject[key]) updateObject[key] = {};
        updateObject[key][subKey] = value;
        // If not then we are replacing this whole object or value
        // For example we are replacing the auto assign rules, or making the document active
      } else {
        updateObject[key] = value;
      }
      newDocuments[docIndex] = updateObject;
      // There are specific updates we send an api update automatically
      // Like moving the rank or making it active
      if (update && !subKey) {
        if (key === 'active') {
          handleUpdate({ ...updateObject }, key);
        } else {
          handleUpdate({ ...updateObject });
        }
      }
      return { documents: newDocuments };
    });
  };

  deleteDoc = id => {
    const { handleDelete } = this.props;
    handleDelete({ id });
    this.setState({ deleteDialogOpen: false });
  };

  updateListAfterDrag = list =>
    list.map((item, index) => ({
      ...item,
      rank: index + 1,
    }));

  handleSubmit = id => {
    const { handleUpdate } = this.props;
    const { documents } = this.state;
    const doc = documents.find(d => d.id === id);
    handleUpdate(doc);
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  handleOpenDeleteDialog = id =>
    this.setState({ deleteId: id, deleteDialogOpen: true });

  handleCloseDeleteDialog = () =>
    this.setState({ deleteId: '', deleteDialogOpen: false });

  updateRank = (template, newRank) => {
    const { handleUpdate } = this.props;
    if (template.rank === newRank) return;
    handleUpdate({ ...template, rank: newRank }, 'rank');
  };

  getFilteredUsersByTag = doc => {
    const { users = [] } = this.props;
    const { workflow = {} } = doc;
    const { categories: tags = [] } = workflow; // Backend Calls them categories, front end calls them tags
    const isI9Doc =
      tags.find(
        ({ name = '' }) =>
          String(name).toLowerCase() === 'i9' ||
          String(name).toLowerCase() === 'i-9',
      ) !== undefined;
    return users.filter(({ roles = [] }) =>
      roles.includes(isI9Doc ? ApproverRoles.VERIFIER : ApproverRoles.APPROVER),
    );
  };

  renderDocuments = () => {
    const {
      classes,
      requestHandler,
      error = {},
      isUpdatingRank,
      isDeleting,
      projectCountryId,
    } = this.props;
    const { documents } = this.state;
    return documents.map(doc => {
      // We remove the rank prop from the document
      // Because as the drag and drop happens the rank is the only
      // Prop that would change, Causing a re render of Document
      // Removing it allows the react-shallow-compare to know not to update
      const docProps = { ...doc };
      let documentError = {};
      if (error.id === doc.id) {
        documentError = error;
      }
      return (
        <tr key={doc.id} data-test-id={`DocumentContainer-row-${doc.id}`}>
          <td className={classes.tableCell}>
            <ProjectTemplate
              error={documentError}
              {...docProps}
              users={this.getFilteredUsersByTag(doc)}
              updateRank={destination =>
                this.updateRank(doc, parseInt(destination))
              }
              requestHandler={requestHandler}
              handleSubmit={this.handleSubmit}
              updateDoc={this.updateDoc}
              deleteDoc={this.handleOpenDeleteDialog}
              className={classes.expansionPanel}
              isUpdatingRank={isUpdatingRank}
              isDeleting={isDeleting}
              templateRequired={doc.required}
              projectCountryId={projectCountryId}
            />
          </td>
        </tr>
      );
    });
  };

  handleKeyPress = (key = '', docId) => {
    if (key === 'Enter' && docId) {
      this.deleteDoc(docId);
    }
  };

  render() {
    const { totalItems } = this.props;
    const { deleteId, deleteDialogOpen, documents, classes } = this.state;
    const toBeRendered = this.renderDocuments();
    const documentToDelete = documents.find(doc => doc.id === deleteId) || {};
    return (
      <div>
        <Table className={classes.tableRoot}>
          <tbody>{toBeRendered}</tbody>
          <ProjectTemplatesFooter length={totalItems || documents.length} />
        </Table>
        <Dialog
          open={deleteDialogOpen}
          onClose={this.handleCloseDeleteDialog}
          onKeyPress={e => this.handleKeyPress(e.key, documentToDelete.id)}
        >
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete {documentToDelete.name}?{' '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDeleteDialog}
              className={classes.button}
              color="secondary"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.deleteDoc(documentToDelete.id)}
              className={classes.button}
              color="primary"
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ProjectTemplates);
