import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CrewFilterMenu from './CrewFilterMenu';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/SortByAlpha';

const CrewFilter = props => {
  const { filters, onChange, classes = {}, sortCrewMembers } = props || {};
  const { sort } = filters || {};
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = ({ currentTarget } = {}) => {
    if (menuOpen) return setMenuOpen(false);
    return setMenuOpen(currentTarget);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="subtitle1">Filter </Typography>
        <IconButton
          aria-label="Filter Menu"
          onClick={toggleMenu}
          classes={{ root: classes.button }}
          data-test-id="CrewFilter-filterButton"
        >
          <FilterListIcon />
        </IconButton>
      </div>
      <Tooltip title={`Sort by ${sort === 'asc' ? 'descending' : 'ascending'}`}>
        <IconButton
          onClick={() => sortCrewMembers()}
          classes={{ label: classes.sortIconLabel }}
          data-test-id="CrewFilter-sortButton"
        >
          <SortIcon />
        </IconButton>
      </Tooltip>
      <CrewFilterMenu
        open={menuOpen}
        onClose={toggleMenu}
        anchor={menuOpen}
        filters={filters}
        onChange={onChange}
      />
    </React.Fragment>
  );
};

export default CrewFilter;
