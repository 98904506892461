import ContactDetails from '../Base/ContactDetails';
import React from 'react';
import config from 'common/oldJavascripts/config.js';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

class ContactCustomerService extends React.Component {
  render() {
    return (
      <ContactDetails>
        <ContactDetails.Data key="email">
          <ContactDetails.DataLabel>
            <EmailIcon />
          </ContactDetails.DataLabel>
          <ContactDetails.DataValue>
            {config.CUSTOMER_SERVICE.EMAIL}
          </ContactDetails.DataValue>
        </ContactDetails.Data>
        <ContactDetails.Data key="phone">
          <ContactDetails.DataLabel>
            <PhoneIcon />
          </ContactDetails.DataLabel>
          <ContactDetails.DataValue>
            {config.CUSTOMER_SERVICE.PHONE}
          </ContactDetails.DataValue>
        </ContactDetails.Data>
      </ContactDetails>
    );
  }
}

export default ContactCustomerService;
