import React from 'react';

// Components
import Tooltip from '@material-ui/core/Tooltip';
import { HelpOutline as HelpIcon } from '@material-ui/icons';

// HoC
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkButton: {
    height: '45px',
    width: '45px',
  },
});

const IdlePayHelpIcon = ({ classes = {} } = {}) => (
  <Tooltip
    title={
      <p>
        If you would like to specify a different rate for idle days for distant
        pay please enter it here. This is calculated as Rate Per Hour.
      </p>
    }
    enterDelay={200}
    placement="right"
  >
    <div className={classes.root}>
      <HelpIcon />
    </div>
  </Tooltip>
);

export default withStyles(styles)(IdlePayHelpIcon);
