import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Components

import { stableSort, getSorting } from 'common/utilities/stableSort';

// MuiComponents
import {
  Paper,
  Typography,
  IconButton,
  Switch,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
} from '@material-ui/core';

// Icons
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

// Table Header Definitions
const rows = [
  { id: 'firstName', numeric: false, label: 'Name' },
  { id: 'email', numeric: false, label: 'Email' },
  { id: 'active', numeric: false, label: 'Active' },
];

const styles = {
  switchCell: {
    width: 100,
  },
  actionCell: {
    width: 150,
  },
};

class AdminUserList extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'firstName',
    page: 0,
    rowsPerPage: 10,
  };

  // The sort & pagination functions are refered from Material-UI tables demo
  createSortHandler = property => event => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  collectionToRender = () => {
    const { collection = [] } = this.props;
    const { order, orderBy, page, rowsPerPage } = this.state;

    return stableSort(collection, getSorting(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  };

  render() {
    const {
      classes,
      collection = [],
      onEdit,
      onDelete,
      onStatusChange,
    } = this.props;
    const { order, orderBy, page, rowsPerPage } = this.state;

    const processedCollection = this.collectionToRender();

    return (
      <Paper elevation={0}>
        <Table data-test-id="AdminUserList-root">
          <TableHead>
            <TableRow>
              {rows.map(
                row => (
                  <TableCell
                    key={row.id}
                    sortDirection={orderBy === row.id ? order : false}
                    data-test-id={`AdminUserList-header-${row.id}`}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                      data-test-id={`AdminUserList-sort-${row.id}`}
                    >
                      {row.label}
                    </TableSortLabel>
                  </TableCell>
                ),
                this,
              )}
              <TableCell
                align="center"
                data-test-id="AdminUserList-headerCell-actions"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processedCollection.map(user => (
              <TableRow
                key={user.id}
                data-test-id={`AdminUserList-tableRow-${user.id}`}
              >
                <TableCell data-test-id={`AdminUserList-name-${user.id}`}>
                  <Typography variant="subtitle1">
                    {`${user.lastName}, ${user.firstName}`}
                  </Typography>
                </TableCell>
                <TableCell data-test-id={`AdminUserList-email-${user.id}`}>
                  <Typography variant="subtitle1">{user.email}</Typography>
                </TableCell>
                <TableCell
                  className={classes.switchCell}
                  data-test-id={`AdminUserList-status-${user.id}`}
                >
                  <Switch
                    checked={user.active}
                    onChange={onStatusChange(user.id)}
                    inputProps={{
                      'data-test-id': `AdminUserList-active-${user.id}`,
                      'data-checked': user.active,
                    }}
                  />
                </TableCell>
                <TableCell className={classes.actionCell}>
                  <IconButton
                    onClick={onEdit(user.id)}
                    data-test-id={`AdminUserList-edit-${user.id}`}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={onDelete(user.id)}
                    data-test-id={`AdminUserList-delete-${user.id}`}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={collection.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            'data-test-id': 'AdminUserList-pagination-select',
          }}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
            'data-test-id': 'AdminUserList-pagination-previous',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
            'data-test-id': 'AdminUserList-pagination-next',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          data-test-id="AdminUserList-pagination-root"
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(AdminUserList);
