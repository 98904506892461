import React from 'react';

// Components
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';

// Constants
import { daysMask } from '../../helpers/textFieldNumberMasks';

const WeeklyRate = ({
  onChange: upstreamOnChange,
  formData: { numberOfDaysPerWorkWeek } = {},
  classes = {},
}) => {
  const onChange = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') {
      upstreamOnChange({
        numberOfDaysPerWorkWeek: null,
      });
      return;
    }
    upstreamOnChange({
      numberOfDaysPerWorkWeek: value,
      dailyRate: null,
    });
    return;
  };

  const onBlur = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') return;
    upstreamOnChange({
      numberOfDaysPerWorkWeek: value,
      dailyRate: null,
    });
    return;
  };

  return (
    <TableCell align="center" className={classes.root}>
      <MaskedInput
        mask={daysMask}
        value={numberOfDaysPerWorkWeek || ''}
        onChange={onChange}
        onBlur={onBlur}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': 'NumberOfDaysPerWorkWeek-input',
              },
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default WeeklyRate;
