import React from 'react';
import TOOLTIP_ICON from 'common/images/tooltip.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

const formatAmount = amount => {
  return !Number.isInteger(amount) ? currency(amount) : `$${amount}`;
};

const useStyles = makeStyles(theme => ({
  terms: {
    marginTop: '40px',
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: 25,
  },
  termsTableFooter: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
  },
  rowTitle: {
    color: '#5b5b5b',
    fontSize: '16px',
    fontWeight: 700,
    textTableRowansform: 'uppercase',
    backgroundColor: '#f5fcfb',
    padding: '10px',
  },
  panel: {
    backgroundColor: '#f5fcfb',
    padding: '10px',
  },
  amount: {
    color: '#2eaba4',
    fontSize: 30,
    fontWeight: 400,
    padding: 18,
    textAlign: 'center',
    border: '0.1px solid #d3f0ee',
    wordWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
      padding: 11,
    },
  },
  smallerAmount: {
    color: '#2eaba4',
    fontSize: 27,
    fontWeight: 400,
    padding: 18,
    textAlign: 'center',
    border: '0.1px solid #d3f0ee',
    wordWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
      padding: 11,
    },
  },
  grey: {
    color: '#939393',
  },
  scaleTip: {
    fontSize: 10,
    color: '#939393',
  },
  tooltip: {
    marginLeft: '2px',
    position: 'absolute',
  },
  idlePayRate: {
    color: '#939393',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
  },
  greenBox: {
    backgroundColor: '#f5fcfb',
    color: '#5b5b5b',
    fontSize: 14,
    fontWeight: 700,
    borderBottom: '0.1px solid #d3f0ee',
    textAlign: 'center',
    padding: '11px',
  },
  greenBoxNoContent: {
    borderBottom: 'none',
  },
  regularBox: {
    minHeight: 50,
    minWidth: 155,
    color: '#5b5b5b',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    borderBottom: '0 solid #d3f0ee',
  },
  distant: {
    color: '#5b5b5b',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    border: '0 solid #d3f0ee',
  },
  tableContainer: {
    marginTop: 50,
    marginBottom: 20,
  },
}));

const UnionWeeklyEmployeeTermsDesktop = ({ terms = {} }) => {
  const classes = useStyles();

  const {
    rate_per_hour_studio: rate,
    rate_per_hour_location: rateDistant,
    guaranteed_hours_studio: guaranteedHours,
    guaranteed_hours_location: guaranteedHoursDistant,
    pay_idle_days_distant: payIdleDaysDistant,
    pay_gold_at: payGoldAt,
    pay_gold_at_distant: payGoldAtDistant,
    pay_at_scale: payAtScale,
    pay_at_scale_distant: payAtScaleDistant,
    idle_at_scale_distant: idleAtScaleDistant,
    gold_at_scale: goldAtScale,
    gold_at_scale_distant: goldAtScaleDistant,
    rate_per_week_studio: weeklyRate,
    rate_per_week_location: weeklyRateDistant,
  } = terms;

  const isStudio = rate || weeklyRate || guaranteedHours;
  const isDistant = rateDistant || weeklyRateDistant || guaranteedHoursDistant;
  const isLargeAmount = amount => Number(amount) >= 1000;

  return (
    <div className={classes.terms}>
      <Typography
        className={classes.title}
        data-test-id="TermsOfEmploymentDesktop-title"
      >
        Terms of Employment
      </Typography>
      {isStudio && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={[classes.greenBoxNoContent, classes.greenBox].join(
                  ' ',
                )}
              />
              <TableCell
                data-test-id="TermsOfEmploymentDesktop-hourlyStudioRate-title"
                className={classes.greenBox}
              >
                Hourly Rate
              </TableCell>
              <TableCell
                className={classes.greenBox}
                data-test-id="TermsOfEmploymentDesktop-weeklyStudioRate-title"
              >
                Weekly Rate
              </TableCell>
              <TableCell
                className={classes.greenBox}
                data-test-id="TermsOfEmploymentDesktop-studioDaysHours-title"
              >
                Hours Per Week
              </TableCell>
              <TableCell
                className={classes.greenBox}
                data-test-id="TermsOfEmploymentDesktop-studioDaysHours-title"
              >
                Pay Gold at
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                data-test-id="TermsOfEmploymentDesktop-studio-title"
                className={[classes.greenBox, classes.distant].join(' ')}
              >
                STUDIO{' '}
                <Tooltip
                  title="Studio refers to a local distance within the studio zone."
                  placement="top-start"
                  className={classes.tooltip}
                >
                  <img src={TOOLTIP_ICON} alt="schedule  tooltip" />
                </Tooltip>
              </TableCell>
              <TableCell
                data-test-id="TermsOfEmploymentDesktop-hourlyStudioRate-amount"
                className={
                  isLargeAmount(rate) ? classes.smallerAmount : classes.amount
                }
              >
                {formatAmount(rate)}
                {payAtScale && (
                  <Typography className={classes.scaleTip}>
                    *Scale Rate
                  </Typography>
                )}
              </TableCell>
              <TableCell
                data-test-id="TermsOfEmploymentDesktop-weeklyStudioRate-amount"
                className={
                  isLargeAmount(weeklyRate)
                    ? classes.smallerAmount
                    : classes.amount
                }
              >
                {formatAmount(weeklyRate)}
                {payAtScale && (
                  <Typography className={classes.scaleTip}>
                    *Scale Rate
                  </Typography>
                )}
              </TableCell>
              {guaranteedHours && (
                <TableCell
                  data-test-id="TermsOfEmploymentDesktop-studioDaysHours"
                  className={classes.amount}
                >
                  {guaranteedHours}
                </TableCell>
              )}
              {payGoldAt && (
                <TableCell
                  data-test-id="TermsOfEmploymentDesktop-studioDaysGold-amount"
                  className={classes.amount}
                >
                  {formatAmount(payGoldAt)}
                  {goldAtScale && (
                    <Typography className={classes.scaleTip}>
                      *Scale Rate
                    </Typography>
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      )}
      {isDistant && (
        <Table className={classes.tableContainer}>
          <TableHead>
            <TableRow>
              <TableCell
                className={[classes.greenBoxNoContent, classes.greenBox].join(
                  ' ',
                )}
              />
              {rateDistant && (
                <TableCell
                  className={classes.greenBox}
                  data-test-id="TermsOfEmploymentDesktop-hourlyDistantRate-title"
                >
                  Hourly Rate
                </TableCell>
              )}
              {weeklyRateDistant && (
                <TableCell className={classes.greenBox}>Weekly Rate</TableCell>
              )}
              {guaranteedHoursDistant && (
                <TableCell className={classes.greenBox}>
                  Hours per Week
                </TableCell>
              )}
              {payGoldAtDistant && (
                <TableCell className={classes.greenBox}>Pay Gold at</TableCell>
              )}
              {payIdleDaysDistant && (
                <TableCell className={classes.greenBox}>
                  Idle Days pay
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className={[classes.greenBox, classes.distant].join(' ')}
              >
                DISTANT{' '}
                <Tooltip
                  title="Distant refers to the location outside the studio zone."
                  placement="top-start"
                  className={classes.tooltip}
                >
                  <img src={TOOLTIP_ICON} alt="schedule  tooltip" />
                </Tooltip>
              </TableCell>
              {rateDistant && (
                <TableCell
                  data-test-id="TermsOfEmploymentDesktop-hourlyDistantRate-amount"
                  className={
                    isLargeAmount(rateDistant)
                      ? classes.smallerAmount
                      : classes.amount
                  }
                >
                  {formatAmount(rateDistant)}
                  {payAtScaleDistant && (
                    <Typography className={classes.scaleTip}>
                      *Scale Rate
                    </Typography>
                  )}
                </TableCell>
              )}
              {weeklyRateDistant && (
                <TableCell
                  className={
                    isLargeAmount(weeklyRateDistant)
                      ? classes.smallerAmount
                      : classes.amount
                  }
                >
                  {formatAmount(weeklyRateDistant)}
                  {payAtScaleDistant && (
                    <Typography className={classes.scaleTip}>
                      *Scale Rate
                    </Typography>
                  )}
                </TableCell>
              )}
              {guaranteedHoursDistant && (
                <TableCell className={classes.amount}>
                  {guaranteedHoursDistant}
                </TableCell>
              )}
              {payGoldAtDistant && (
                <TableCell className={classes.amount}>
                  {formatAmount(payGoldAtDistant)}
                  {goldAtScaleDistant && (
                    <Typography className={classes.scaleTip}>
                      *Scale Rate
                    </Typography>
                  )}
                </TableCell>
              )}
              {payIdleDaysDistant && (
                <TableCell className={classes.amount}>
                  {formatAmount(payIdleDaysDistant)}
                  {idleAtScaleDistant && (
                    <Typography className={classes.scaleTip}>
                      *Scale Rate
                    </Typography>
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      )}
      <Typography className={classes.scaleTip}>
        *Scale rate shown is as of offer creation date. Scale rate is subject to
        change upon union negotiated increases.
      </Typography>
    </div>
  );
};

export default UnionWeeklyEmployeeTermsDesktop;
