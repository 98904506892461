import React, { Component } from 'react';
import Addresses from 'common/oldJavascripts/components/Pages/UserProfile/Addresses/Index';
import Emergency from 'common/oldJavascripts/components/Pages/UserProfile/Emergency';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import General from 'common/oldJavascripts/components/Pages/UserProfile/General';
import T from 'common/oldJavascripts/utils/i18n';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import NotificationsPicker from 'common/oldJavascripts/components/Shared/Form/NotificationsPicker';
import EditHeader from './EditHeader';
import classNames from 'classnames';

// Utilities
import history from 'common/constants/config/history';
// HoC
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import { withStyles } from '@material-ui/core/styles';
// Components
import { Typography, Paper, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Loader from 'common/components/Loader';

const styles = theme => ({
  paper: {
    gridArea: 'paper',
    maxHeight: '100%',
    display: 'grid',
    gridTemplateColumns: '10% 76% 10%',
    gridTemplateRows:
      '20px auto 20px auto 20px auto 20px auto 20px auto 20px auto 20px',
    gridTemplateAreas: `
    ". ............. ."
    ". header        ."
    ". ............. ."
    ". general       ."
    ". ............. ."
    ". addresses     ."
    ". ............. ."
    ". emergency     ."
    ". ............. ."
    ". notifications ."
    ". ............. ."
    ". actions       ."
    ". ............. ."`,
    background: theme.palette.common.white,
  },
  typography: {
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '1.23em',
    fontWeight: 'bold',
    lineHeight: '1.25em',
    margin: '15px 0',
  },
  header: {
    gridArea: 'header',
  },
  general: {
    gridArea: 'general',
  },
  addresses: {
    gridArea: 'addresses',
  },
  emergency: {
    gridArea: 'emergency',
  },
  notifications: {
    gridArea: 'notifications',
  },
  actions: {
    gridArea: 'actions',
    display: 'flex',
    justifyContent: 'center',
  },
  separator: {
    borderBottom: '1px solid #e6e7ea',
  },
  cancelButton: {
    marginLeft: '20px',
  },
});

class EditEmployee extends Component {
  static mutators = {
    employee: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/projects/${params.projectId}/users/${params.userId}`,
        };
      },
    },
    notifications: {
      info(_, related) {
        const routerParams = related['/router/params'];
        return {
          id: '/v2/notification-preferences',
          cursor: {
            profile_id: routerParams.userId,
          },
        };
      },

      stale() {
        return ['/v2/notification-preferences'];
      },
    },
  };

  updateEmployee = () => {
    const { routerParams: params, notifications, employee } = this.props;

    notifications.save('edit', {}, () => {
      employee.save('edit', {}, () => {
        history.push(`/projects/${params.projectId}/users`);
      });
    });
  };

  handleAddressChange = (address, type) => {
    const { employee } = this.props;
    employee.update(type)(address);
  };

  render() {
    const {
      classes,
      routerParams: params,
      employee: employeeMutator,
    } = this.props;
    if (employeeMutator.status === 'loading') {
      return <Loader size={24} />;
    }

    if (employeeMutator.status === 'failed' && !employeeMutator.errors) {
      return (
        <LayoutContent>
          <FailureAlert queryName="the profile data" />
        </LayoutContent>
      );
    }

    return (
      <LayoutContent>
        <Paper className={classes.paper}>
          <div className={classNames(classes.header, classes.separator)}>
            <EditHeader user={employeeMutator.data} />
          </div>
          <div className={classNames(classes.general, classes.separator)}>
            <General
              profileMutator={employeeMutator}
              data-test-id={`EditEmployee-FirstName-${employeeMutator.id}`}
            />
          </div>

          <div className={classNames(classes.addresses, classes.separator)}>
            <Addresses
              params={{
                projectId: params.projectId,
                employeeId: employeeMutator.id,
              }}
              profileMutator={employeeMutator}
              onChange={this.handleAddressChange}
              data-test-id={`EditEmployee-Addresses-${employeeMutator.id}`}
            />
          </div>

          <div className={classNames(classes.emergency, classes.separator)}>
            <Emergency
              profileMutator={employeeMutator}
              data-test-id={`EditEmployee-Emergency-${employeeMutator.id}`}
            />
          </div>

          <div className={classNames(classes.notifications, classes.separator)}>
            <span
              data-test-id={`EditEmployee-Notifications-${employeeMutator.id}`}
            >
              <Typography className={classes.typography}>
                Notification Preferences
              </Typography>
              <NotificationsPicker
                adminEdition={true}
                mutator={this.props.notifications}
              />
            </span>
          </div>

          <div className={classes.actions}>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.updateEmployee}
              data-test-id={'EditEmployee-saveChangesButton'}
            >
              {T('profile.submit')}
            </Button>
            <Button
              component={Link}
              to={`/projects/${params.projectId}/users`}
              data-test-id={'EditEmployee-cancelButton'}
              className={classes.cancelButton}
            >
              {T('form.actions.cancel')}
            </Button>
          </div>
        </Paper>
      </LayoutContent>
    );
  }
}

export default compose(withApi, withStyles(styles))(EditEmployee);
