import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { StepIcon } from '@material-ui/core';
import { Typography, Tooltip, IconButton as Button } from '@material-ui/core';
import {
  CheckCircle as CheckIcon,
  HighlightOff as ClearIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import {
  COMPLETE,
  INPROGRESS,
  REJECTED,
  WAITING,
} from 'common/utilities/constants/documentStatus';

export const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: -theme.spacing.unit * 4,
  },
  iconContainer: {
    width: theme.spacing.unit * 5,
    height: theme.spacing.unit * 5,
  },
  labelContainer: {
    width: '100%',
  },
  stepNameContainer: {
    height: theme.spacing.unit * 2,
  },
  iconButtonRoot: {
    width: 40,
    height: 40,
    padding: 0,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  success: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  pending: {
    color: theme.palette.grey[300],
  },
  leftPadding: {
    paddingLeft: theme.spacing.unit * 2,
  },
});

const ApprovalStepLabel = props => {
  const { children, classes, stepDetail } = props;
  const { status, requireAll, levelName } = stepDetail;

  const statusClass = classNames(
    {
      [classes.success]: status === COMPLETE,
      [classes.error]: status === REJECTED,
      [classes.pending]: status === INPROGRESS || status === WAITING,
    },
    [classes.capitalize],
    [classes.leftPadding],
  );
  const requireAllClass = classNames(
    [classes.capitalize],
    [classes.leftPadding],
  );
  const tooltipTitleProp = (
    <div>
      <Typography className={classes.capitalize} color="inherit">
        Status:
      </Typography>
      <Typography className={statusClass}>{status}</Typography>
      <Typography color="inherit">Require:</Typography>
      <Typography color="inherit" className={requireAllClass}>
        {requireAll ? 'All Members' : 'One Member'}
      </Typography>
    </div>
  );

  const stepIconProp = (
    <Tooltip title={tooltipTitleProp} placement="right">
      <Button
        disableRipple
        classes={{ root: classes.iconButtonRoot }}
        data-test-id={`ApprovalStepLabel-button`}
      >
        {status === COMPLETE && <CheckIcon className={classes.success} />}
        {status === REJECTED && <ClearIcon className={classes.error} />}
        {(status === INPROGRESS || status === WAITING) && <ClearIcon />}
      </Button>
    </Tooltip>
  );

  return (
    <span className={classes.root}>
      <span className={classes.stepNameContainer}>
        <Typography>{levelName}</Typography>
      </span>

      <span className={classes.iconContainer}>
        <StepIcon icon={stepIconProp} />
      </span>

      <span className={classes.labelContainer}>
        <Typography variant="body1" component="span">
          {children}
        </Typography>
      </span>
    </span>
  );
};

export default withStyles(styles)(ApprovalStepLabel);
