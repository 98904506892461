import React from 'react';
import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Field from 'common/oldJavascripts/components/Base/Field';
import T from 'common/oldJavascripts/utils/i18n';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Link from 'common/oldJavascripts/components/Base/Link';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import NotificationsPicker from 'common/oldJavascripts/components/Shared/Form/NotificationsPicker';
import Preloader from 'common/oldJavascripts/components/Shared/Preloader';
import PrivilegesPicker from 'common/oldJavascripts/components/Shared/Form/PrivilegesPicker';
import EditHeader from './EditHeader';
import status from 'common/oldJavascripts/utils/react/status.js';
import { isFormComplete } from 'common/oldJavascripts/utils/isFormComplete';
import {
  validateName,
  cleanName,
} from 'common/oldJavascripts/utils/validateName';

import { AsYouType } from 'libphonenumber-js';
// Utilities
import history from 'common/constants/config/history';
import classNames from 'classnames';
import phoneNumberInputOnChange from 'common/oldJavascripts/utils/phoneNumberInputOnChange';

// HoC
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import { withStyles } from '@material-ui/core/styles';
// Mui Components
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

const styles = theme => ({
  paper: {
    gridArea: 'paper',
    maxHeight: '100%',
    display: 'grid',
    gridTemplateColumns: 'calc(10% - 20px) 36% 18% 18% calc(10% - 20px)',
    gridTemplateRows:
      '20px auto 20px auto 20px auto 20px auto 20px auto 20px auto 20px',
    columnGap: '20px',
    gridTemplateAreas: `
    ". ..........  .............  .............   ."
    ". header      header         header          ."
    ". ..........  .............  .............   ."
    ". general     .............  .............   ."
    ". ..........  .............  .............   ."
    ". firstname   lastname       lastname        ."
    ". ..........  .............  .............   ."
    ". email       phone          title           ."
    ". ..........  .............  .............   ."
    ". privileges  notifications  notifications   ."
    ". ..........  .............  .............   ."
    ". ..........  .............  buttons         ."
    ". ..........  .............  .............   ."`,
    background: theme.palette.common.white,
  },
  typography: {
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '1.23em',
    fontWeight: 'bold',
    lineHeight: '1.25em',
    margin: '15px 0',
  },
  header: {
    gridArea: 'header',
  },
  general: {
    gridArea: 'general',
  },
  firstname: {
    gridArea: 'firstname',
  },
  lastname: {
    gridArea: 'lastname',
  },
  email: {
    gridArea: 'email',
  },
  title: {
    gridArea: 'title',
  },
  phone: {
    gridArea: 'phone',
  },
  privileges: {
    gridArea: 'privileges',
  },
  notifications: {
    gridArea: 'notifications',
  },
  buttons: {
    gridArea: 'buttons',
  },
  separator: {
    borderBottom: '1px solid #e6e7ea',
  },
});

class OldEditNonEmployee extends React.Component {
  static mutators = {
    user: {
      info(_, related) {
        const routerParams = related['/router/params'];
        return {
          id: `/projects/${routerParams.projectId}/users/${routerParams.userId}`,
        };
      },
    },
    notifications: {
      info(_, related) {
        const routerParams = related['/router/params'];
        return {
          id: '/v2/notification-preferences',
          cursor: {
            profile_id: routerParams.userId,
          },
        };
      },

      stale() {
        return ['/v2/notification-preferences'];
      },
    },
  };

  _getStatus() {
    return status([this.props.notifications, this.props.user]);
  }

  _checkSaveChangesButtonStatus() {
    const userFormData = this.props.user.data;
    const requiredFields = ['first_name', 'last_name', 'email', 'title'];
    return !isFormComplete(userFormData, requiredFields);
  }

  handleSubmit = () => {
    const { routerParams: params, notifications, user } = this.props;

    notifications.save('edit', {}, () => {
      user.save('edit', {}, () => {
        history.push(`/projects/${params.projectId}/users`);
      });
    });
  };

  updatePhoneNumber = (number, target) => {
    const { user: userMutator } = this.props;
    phoneNumberInputOnChange(number, target, 'phone', userMutator);
  };

  formatPhoneNumber = number => {
    // we do not start formatting the number until it's greater than 4 digits
    // this prevents a bug that prevents the user from deleting their area code
    if (!/(.?\d){4,}/.test(number)) return number;
    const asYouType = new AsYouType('US');
    // return the formatted phone number
    return asYouType.input(number);
  };

  render() {
    const { routerParams: params, user: userMutator, classes } = this.props;
    const { data: user, errors } = userMutator;
    const status = this._getStatus();
    const saveChangesButtonStatus = this._checkSaveChangesButtonStatus();

    if (status === 'loading') {
      return <Preloader />;
    }
    if (status === 'failed' && !errors) {
      return (
        <LayoutContent>
          <Box>
            <BoxItem>
              <FailureAlert />
            </BoxItem>
          </Box>
        </LayoutContent>
      );
    }

    return (
      <LayoutContent>
        <Paper className={classes.paper}>
          <div className={classNames(classes.header, classes.separator)}>
            <EditHeader user={user} />
          </div>

          <div className={classes.general}>
            <Typography className={classes.typography}>
              General Information
            </Typography>
          </div>

          <div className={classes.firstname}>
            <Field
              errors={errors.first_name}
              grouped={true}
              id="first_name"
              label={T('edit_non_employee.general.first_name')}
              name="first_name"
              onChange={value =>
                userMutator.update('first_name')(validateName(value))
              }
              type="text"
              value={cleanName(user.first_name)}
              required={true}
              data-test-id={`EditNonEmployee-FirstName-${user.id}`}
            />
          </div>

          <div className={classes.lastname}>
            <Field
              errors={errors.last_name}
              grouped={true}
              id="last_name"
              label={T('edit_non_employee.general.last_name')}
              name="last_name"
              onChange={value =>
                userMutator.update('last_name')(validateName(value))
              }
              type="text"
              value={cleanName(user.last_name)}
              required={true}
              data-test-id={`EditNonEmployee-LastName-${user.id}`}
            />
          </div>

          <div className={classes.email}>
            <Field
              errors={errors.email}
              grouped={true}
              id="email"
              label={T('edit_non_employee.general.email')}
              name="email"
              onChange={userMutator.update('email')}
              type="text"
              value={user.email}
              required={true}
              data-test-id={`EditNonEmployee-Email-${user.id}`}
            />
          </div>

          <div className={classes.title}>
            <Field
              errors={errors.title}
              grouped={true}
              id="title"
              label={T('edit_non_employee.general.title')}
              name="title"
              onChange={userMutator.update('title')}
              type="text"
              value={user.title}
              required={true}
              data-test-id={`EditNonEmployee-Title-${user.id}`}
            />
          </div>

          <div className={classes.phone}>
            <Field
              errors={errors.phone}
              grouped={true}
              id="phone"
              label={T('edit_non_employee.general.phone')}
              name="phone"
              onChange={(value, target) =>
                this.updatePhoneNumber(value, target)
              }
              type="phone"
              value={this.formatPhoneNumber(user.phone)}
              data-test-id={`EditNonEmployee-Phone-${user.id}`}
            />
          </div>

          <div className={classes.privileges}>
            <Typography className={classes.typography}>Privileges</Typography>
            <PrivilegesPicker
              mutator={userMutator}
              data-test-id={`EditNonEmployee-Privileges-${user.id}`}
            />
          </div>

          <div className={classes.notifications}>
            <Typography className={classes.typography}>
              Email Notifications
            </Typography>
            <NotificationsPicker
              adminEdition={true}
              mutator={this.props.notifications}
              data-test-id={`EditNonEmployee-notifications-${user.id}`}
            />
          </div>

          <div className={classes.buttons}>
            <NavBar>
              <NavBar.Item>
                <Button
                  disabled={saveChangesButtonStatus}
                  onClick={this.handleSubmit}
                  data-test-id="EditNonEmployee-submit"
                >
                  {T('edit_non_employee.submit')}
                </Button>
              </NavBar.Item>
              <NavBar.Item>
                <Link
                  to={`/projects/${params.projectId}/users`}
                  data-test-id="EditNonEmployee-cancel"
                >
                  {T('form.actions.cancel')}
                </Link>{' '}
                {T('form.actions.or')}
              </NavBar.Item>
            </NavBar>
          </div>
        </Paper>
      </LayoutContent>
    );
  }
}

export default compose(withApi, withStyles(styles))(OldEditNonEmployee);
