import React from 'react';
import T from '../../utils/i18n';
import Box from '../Base/Box';
import Button from '../Base/Button';
import Field from '../Base/Field';
import Header from '../Base/Header';
import Link from '../Base/Link';
import Modal from '../Base/Modal';
import Text from '../Base/Text';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

import BoxParagraphItem from 'common/oldJavascripts/components/Base/Box/BoxParagraphItem';

// MATERIAL
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '20px 1fr',
  },
  charCountLabel: {
    justifySelf: 'end',
    gridColumn: '2',
  },
});

class RejectModal extends React.Component {
  state = {
    rejectNote: '',
  };

  render() {
    const { rejectNote } = this.state;
    const maxLength = '500';
    const classes = this.props.classes;

    var content = this.props.content || {};
    var title = content.title;
    var note_paragraph = content.paragraph;
    var note_label = content.note_label;
    var note_placeholder = content.note_placeholder;
    var closeLink = content.closeLink;
    var submitBtn = content.submitBtn;

    if (!this.props.visible) {
      return null;
    }

    return (
      <Modal minimal={true} data-test-id={this.props['data-test-id']}>
        <Box minimal={true} noShadow={true}>
          <BoxItem>
            <Header>
              <Header.Title compact={true} center={true}>
                {T(title)}
              </Header.Title>
            </Header>
          </BoxItem>
          <BoxItem minimal={true}>
            <Text paragraph={true}>{T(note_paragraph)}</Text>
          </BoxItem>
          <BoxItem>
            <div className={classes.root}>
              <InputLabel>{T(note_label)}</InputLabel>
              <Typography className={classes.charCountLabel}>
                {rejectNote.length} / {maxLength}
              </Typography>
            </div>
            <Field
              name="OfferReject"
              type="textarea"
              numRows={6}
              maxLength={maxLength}
              placeholder={T(note_placeholder)}
              onChange={this._onNoteChange}
              data-test-id={`OfferRejectModal-textArea`}
            />
          </BoxItem>
          <BoxParagraphItem align="center" minimal={true}>
            <Link
              onClick={this.onClose}
              data-test-id={`OfferRejectModal-closeButton`}
            >
              {T(closeLink)}
            </Link>
            <Button
              align="right"
              marginLeft={true}
              disabled={!this.isValid()}
              onClick={this.onSubmit}
              data-test-id={`OfferRejectModal-submitButton`}
            >
              {T(submitBtn)}
            </Button>
          </BoxParagraphItem>
        </Box>
      </Modal>
    );
  }

  _onNoteChange = value => {
    this.setState({ rejectNote: value });
  };

  isValid = () => {
    const { rejectNote } = this.state;
    return Boolean(rejectNote);
  };

  onSubmit = () => {
    const { rejectNote } = this.state;

    if (this.props.onSubmit) {
      this.props.onSubmit(rejectNote);
      this.setState({
        rejectNote: '',
      });
    }
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
      this.setState({
        rejectNote: '',
      });
    }
  };
}

export default withStyles(styles)(RejectModal);
