import React from 'react';
import Moment from 'moment';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import collection from 'common/oldJavascripts/utils/collection.js';
import DatePicker from 'common/oldJavascripts/components/Base/DatePickerField';
import Layout from 'common/oldJavascripts/components/Base/Layout';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import Header from 'common/oldJavascripts/components/Base/Header';
import List from 'common/oldJavascripts/components/Base/List';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import Form from 'common/oldJavascripts/components/Base/Form';
import Field from 'common/oldJavascripts/components/Base/Field';
import FormField from 'common/oldJavascripts/components/Base/FormField';
import Preloader from 'common/oldJavascripts/components/Shared/Preloader';
import T from 'common/oldJavascripts/utils/i18n';
import CONFIG from 'common/oldJavascripts/config.js';
import history from 'common/constants/config/history';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import validateFileType from 'common/utilities/validateFileType';

import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

export default Relay.createClass({
  statics: {
    mutators: {
      archive: {
        info: function(params, related) {
          var routerParams = related['/router/params'];
          var currentDate = Moment().format(CONFIG.DATE_FORMAT.DB);

          return {
            id: '/projects/' + routerParams.projectId + '/archives',
            create: true,
            append: true,

            initial: {
              sent_date: currentDate,
              approved_date: currentDate,
            },
          };
        },
      },
    },
  },

  getInitialState: function() {
    return {
      isValidFileType: true,
      sentDate: null,
      approvedDate: null,
    };
  },

  componentDidMount: function() {
    var archiveMutator = this.props.archive;
    var archive = archiveMutator.data || {};
    this.setState({
      sentDate: Moment(archive.sent_date),
      approvedDate: Moment(archive.approved_date),
    });
  },

  render: function() {
    return (
      <Layout>
        <LayoutContent>
          <Box minimal={true}>
            <BoxBody>
              <BoxItem>
                <Header>
                  <Header.Title>{T('archives.new.title')}</Header.Title>
                </Header>
              </BoxItem>

              <BoxItem>{this._renderForm()}</BoxItem>
            </BoxBody>
          </Box>
        </LayoutContent>
      </Layout>
    );
  },

  _handleFileChange: function(value) {
    var files = this.props.archive.data.files || [];
    if (value) {
      files.push(value);
      this.props.archive.update('files')(files);
    }
  },

  _handleFileDelete: function(index) {
    var acceptFileArr = ['png', 'jpg', 'pdf'];

    return function(ev) {
      var files = this.props.archive.data.files || [];

      files = collection.deleteAt(files, index);
      this.props.archive.update('files')(files);

      ev.preventDefault();
      var allValid = files.every(singleFile =>
        validateFileType(singleFile, acceptFileArr),
      );
      this.setState({ isValidFileType: allValid });
    }.bind(this);
  },

  _updateFileType: function(validtype) {
    var allFilesValid = this.state.isValidFileType;
    this.setState({ isValidFileType: validtype && allFilesValid });
  },

  _changeSentDate: function(date) {
    this.setState({ sentDate: date });

    if (date) {
      if (date.isAfter(this.state.approvedDate)) {
        //reset Approved date if Sent date is set to a later date
        this.setState({ approvedDate: null });
      }
      date = date.format(CONFIG.DATE_FORMAT.DB);
    } else {
      this.setState({ approvedDate: date }); //Reset Approved date when reseting Sent date
    }

    this.props.archive.update('sent_date')(date);
  },

  _changeApprovedDate: function(date) {
    this.setState({ approvedDate: date });

    if (date) {
      date = date.format(CONFIG.DATE_FORMAT.DB);
    }

    this.props.archive.update('approved_date')(date);
  },

  _renderForm: function() {
    const { archive: archiveMutator = {}, routerParams = {} } = this.props;
    const { data: archive = {}, errors: archiveErrors = {} } = archiveMutator;
    const { files: filesError = [] } = archiveErrors || {};
    const { projectId } = routerParams || {};

    const filesErrorToDisplay = [...filesError];

    const fileTypeErrorString =
      'We only accept PNG, JPG, and PDF file types. Please try again.';
    const fileErrorToReplace = 'invalid_file_format';
    if (filesErrorToDisplay.includes(fileErrorToReplace)) {
      const index = filesErrorToDisplay.indexOf(fileErrorToReplace);
      filesErrorToDisplay.splice(index, 1, fileTypeErrorString);
    }

    if (archiveMutator.status === 'loading') {
      return <Preloader />;
    } else {
      return (
        <Form
          action={'/projects/' + projectId + '/archives/new'}
          method="post"
          multipart={true}
          onSubmit={this._submitForm}
        >
          <Field
            type="text"
            label={T('archives.new.first_name')}
            name="first_name"
            id="first_name"
            value={archive.first_name}
            errors={archiveErrors.first_name}
            required={true}
            onChange={archiveMutator.update('first_name')}
          />
          <Field
            type="text"
            label={T('archives.new.last_name')}
            name="last_name"
            id="last_name"
            value={archive.last_name}
            errors={archiveErrors.last_name}
            required={true}
            onChange={archiveMutator.update('last_name')}
          />
          <Field
            type="text"
            label={T('archives.new.position')}
            name="position"
            id="position"
            value={archive.position}
            errors={archiveErrors.position}
            required={true}
            onChange={archiveMutator.update('position')}
          />
          <FormField
            label={T('Sent date')}
            required={true}
            errors={archiveErrors.sent_date}
            grouped={false}
          >
            <div style={{ width: '100%' }}>
              <DatePicker
                name="sent_date"
                id="sent_date"
                selected={this.state.sentDate}
                onChange={this._changeSentDate}
              />
            </div>
          </FormField>
          <FormField
            label={T('Approved date')}
            required={true}
            errors={archiveErrors.approved_date}
            grouped={false}
          >
            <div style={{ width: '100%' }}>
              <DatePicker
                name="approved_date"
                id="approved_date"
                selected={this.state.approvedDate}
                minDate={this.state.sentDate}
                onChange={this._changeApprovedDate}
              />
            </div>
          </FormField>

          <Field
            type="file"
            label={T('archives.new.file')}
            name="file"
            id="file"
            value={archive.files}
            errors={filesErrorToDisplay}
            required={true}
            accept={'.png, .jpg, .pdf'}
            onChange={this._handleFileChange}
            onFileTypeChange={this._updateFileType}
          />

          {this._renderFileList()}

          <NavBar>
            <NavBar.Item>
              <Button disabled={!this.state.isValidFileType}>
                {T('archives.new.submit_button')}
              </Button>
            </NavBar.Item>
          </NavBar>
        </Form>
      );
    }
  },

  _renderFileList: function() {
    var files = this.props.archive.data.files || [];

    if (files.length > 0) {
      var entries = collection.map(
        files,
        function(file, index) {
          return (
            <List.Item key={index}>
              <List.ItemContent>
                <List.ItemContentBody>{file.name}</List.ItemContentBody>
                <List.ItemNav>
                  <IconButton onClick={this._handleFileDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                </List.ItemNav>
              </List.ItemContent>
            </List.Item>
          );
        }.bind(this),
      );

      return <div className="list">{entries}</div>;
    }
  },

  _submitForm: function() {
    const {
      archive: archiveMutator = {},
      routerParams = {},
      invalidateByPatterns,
    } = this.props;
    const { projectId } = routerParams || {};

    invalidateByPatterns([`/projects/${projectId}/archive`]);

    archiveMutator.save('new', { multipart: true }, function() {
      history.push(`/projects/${projectId}/archives`);
    });
  },
});
