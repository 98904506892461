import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SavedDealNote from './SavedDealNote';

const styles = theme => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '300px',
    overflowX: 'auto',
  },
});

const SavedDealNotes = ({ dealNotes = [], classes = {} } = {}) => (
  <div className={classes.root}>
    {dealNotes.map((dealNote, index) => (
      <SavedDealNote key={index} {...dealNote} index={index} />
    ))}
  </div>
);

export default withStyles(styles)(SavedDealNotes);
