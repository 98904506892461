import React from 'react';
import { compose } from 'redux';
import withRouteHelpers from 'common/hoc/withApi';
import withApi from 'common/hoc/withApi';
import Badge from 'common/oldJavascripts/components/Base/Badge';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import format from 'common/oldJavascripts/utils/format.js';

import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const ETableRow = ExpandableTableRow;
const ETableCell = ExpandableTableCell;

const UserRow = props => {
  const {
    userData: {
      id: userId,
      offerCount: userOfferCount,
      profile: { email, firstName, lastName, middleName },
      hasUserAccount,
    },
    isSupportAdmin = false,
    refetchCrewList,
    crewMember: crewMemberMutator = {},
  } = props;

  const renderAccountBadge = () => {
    const hasAccountText = hasUserAccount ? 'Yes' : 'No';

    return <Badge>{hasAccountText}</Badge>;
  };

  const renderStatusBadge = () => {
    const offerCount = userOfferCount || 0;
    let offerCountText = 'None';

    if (offerCount > 0) {
      offerCountText = `${offerCount} ${offerCount > 1 ? 'offers' : 'offer'}`;
    }

    return <Badge>{offerCountText}</Badge>;
  };

  const renderActionLink = () => {
    const offerCount = userOfferCount || 0;

    if (offerCount === 0 && !isSupportAdmin) {
      return (
        <IconButton aria-label="Delete">
          <DeleteIcon onClick={deleteCrewMember} />
        </IconButton>
      );
    }
  };

  const deleteCrewMember = event => {
    const message = 'Are you sure you want to delete this user from your crew?';
    event.preventDefault();

    if (window.confirm(message)) {
      crewMemberMutator.save(
        'delete',
        { excludeActionFromPath: true },
        refetchCrewList(),
      );
    }
  };

  return (
    <ETableRow key={userId}>
      <ETableCell rowHeader={true} data-test-id={`UserRow-name-${userId}`}>
        {format.fullName(firstName, middleName, lastName)}
      </ETableCell>
      <ETableCell>{email}</ETableCell>
      <ETableCell>{renderAccountBadge()}</ETableCell>
      <ETableCell>{renderStatusBadge()}</ETableCell>
      <ETableCell noPadding>{renderActionLink()}</ETableCell>
    </ETableRow>
  );
};

UserRow.mutators = {
  crewMember: {
    info: (params, related) => {
      const routerParams = related['/router/params'];
      return {
        create: true, // Don't make an initial call.
        id: `/v3/projects/${routerParams.projectId}/crew/${params.id}`,
      };
    },

    stale: (_, related) => {
      const routerParams = related['/router/params'];
      return [`/projects/${routerParams.projectId}/crew`];
    },
  },
};

export default compose(withRouteHelpers, withApi)(UserRow);
