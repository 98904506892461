import React from 'react';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import formatOptionLabel from './formatOptionLabel';
import useStates from 'common/hooks/useStates';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

const WorkStateSelect = props => {
  const {
    onChange: upstreamOnChange,
    error = '',
    formData: { workState } = {},
    selectStyles,
    classes = {},
    disabled,
    countryId,
  } = props;

  const { loading, data: states } = useStates(countryId);

  const options = (states || []).map(({ name, code }) => ({
    value: code,
    label: name,
  }));
  const selectedOption = options.find(({ value } = {}) => workState === value);

  const onChange = ({ value }) => {
    const { id: workStateId } = states.find(({ code }) => code === value) || {};
    upstreamOnChange({
      workState: value,
      workStateId,
      workCity: null,
      union: null,
      occupation: null,
      workSchedule: null,
    });
  };

  return (
    <div className={classes.root}>
      <Typography
        className={classes.label}
        data-test-id="WorkStateSelect-label"
        variant="body1"
      >
        Work <UsaOnly>State</UsaOnly>
        <CanadaOnly>Province</CanadaOnly>
      </Typography>
      <div data-test-id="WorkStateSelect-select">
        <Select
          styles={selectStyles}
          className={classes.select}
          onChange={onChange}
          value={selectedOption || null}
          options={options}
          isLoading={loading}
          formatOptionLabel={formatOptionLabel('WorkStateSelect')}
          isDisabled={disabled}
        />
      </div>
      {error && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="WorkStateSelect-error"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default WorkStateSelect;
