import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import classNames from 'class-names';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const styles = {
  tabsroot: {
    bottom: 0,
    position: 'absolute',
    right: 40,
  },
  block: {
    marginTop: 20,
    marginRight: 30,
    position: 'relative',
    right: 0,
    textAlign: 'right',
  },
  itemRoot: {
    borderRadius: '3px 3px 0 0',
    background: 'rgb(242, 242, 243)',
    color: 'rgb(67, 79, 89)',
    cursor: 'pointer',
    display: 'inline-block',
    fontWeight: '700',
    padding: 10,
    textDecoration: 'none',
    position: 'relative',
    top: 2,
    marginLeft: 4,
    ':first-of-type': {
      marginLeft: 0,
    },
  },
  itemActive: {
    border: '1px solid rgb(230, 231, 234)',
    background: 'rgb(255, 255, 255)',
    borderBottom: 'none',
    color: 'rgb(70, 159, 204)',
    top: 1,
  },
};

const OldTabs = props => {
  const { children, block, classes } = props;

  const rootClass = classNames(classes.tabsroot, {
    [classes.block]: block,
  });

  return <div className={rootClass}>{children}</div>;
};

const Item = props => {
  const {
    children,
    classes,
    onClick,
    to,
    params,
    index,
    location,
    isDefault,
    isActive: upstreamIsActive,
    'data-test-id': dataTestId,
  } = props;
  const { pathname, search } = location || {};
  const isTabActive =
    upstreamIsActive ||
    RegExp(to, 'g').test(`${pathname}${search}`) ||
    RegExp(to, 'g').test(`${pathname}/${search}`);

  const rootClass = classNames(classes.itemRoot, {
    [classes.itemActive]: isTabActive || (!!isDefault && !search),
  });

  const click = event => {
    if (onClick) {
      event.preventDefault();
      onClick(index);
    }
  };

  return onClick ? (
    <span className={rootClass} onClick={click} data-test-id={dataTestId}>
      {children}
    </span>
  ) : (
    <Link
      to={to}
      className={rootClass}
      params={params}
      data-test-id={dataTestId}
    >
      {children}
    </Link>
  );
};

OldTabs.Item = compose(withRouter, withStyles(styles))(Item);

export default withStyles(styles)(OldTabs);
