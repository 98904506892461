import classNames from 'classnames';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProjectTemplateAutoAssignmentRuleTypeSelect from './selects/ProjectTemplateAutoAssignmentRuleTypeSelect';
import ProjectTemplateAutoAssignmentRuleDepartmentSelect from './selects/ProjectTemplateAutoAssignmentRuleDepartmentSelect';
import ProjectTemplateAutoAssignmentRuleUnionSelect from './selects/ProjectTemplateAutoAssignmentRuleUnionSelect';
import ProjectTemplateAutoAssignmentRuleStateSelect from './selects/ProjectTemplateAutoAssignmentRuleStateSelect';
import ProjectTemplateAutoAssignmentRuleCitySelect from './selects/ProjectTemplateAutoAssignmentRuleCitySelect';
import ProjectTemplateAutoAssignmentRuleEmploymentTypeSelect from './selects/ProjectTemplateAutoAssignmentRuleEmploymentTypeSelect';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

const styles = theme => ({
  selectRow: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: `
      "ruleType employmentType  .......... ........"
      "ruleType department  .......... ........"
      "ruleType union       .......... ........"
      "ruleType hireState   workState  workCity"
    `,
    gridTemplateColumns: '24% 24% 24% 24%',
    gridTemplateRows: 'auto auto auto',
    gridColumnGap: '8px',
    columnGap: '8px',
  },
  multiSelectRow: {
    gridRowGap: '7px',
    rowGap: '7px',
  },
  ruleType: {
    gridArea: 'ruleType',
  },
  department: {
    gridArea: 'department',
  },
  union: {
    gridArea: 'union',
  },
  hireState: {
    gridArea: 'hireState',
  },
  workState: {
    gridArea: 'workState',
  },
  workCity: {
    gridArea: 'workCity',
  },
  employmentType: {
    gridArea: 'employmentType',
  },
});

const ProjectTemplateAutoAssignmentRuleEditor = props => {
  const { classes, rule, onChange, projectCountryId } = props;
  const {
    id,
    __typename,
    department,
    union,
    hireState,
    workState,
    workCity,
    employmentType,
  } = rule;
  const departmentId = department?.id;
  const unionCode = union?.code;
  const hireStateId = hireState?.id;
  const workStateId = workState?.id;
  const workCityId = workCity?.id;
  const employmentTypeId = employmentType?.id;
  const ruleType = __typename?.slice(0, -'AutoAssignmentRule'.length);
  const isCombo = ruleType === 'Combo';
  const showDepartment = isCombo || ruleType === 'Department';
  const showUnion = isCombo || ruleType === 'Union';
  const showLocation = isCombo || ruleType === 'Location';
  const showEmploymentType = isCombo || ruleType === 'EmploymentType';

  const selectRowClass = classNames(classes.selectRow, {
    [classes.multiSelectRow]: isCombo,
  });

  return (
    <div className={selectRowClass}>
      <ProjectTemplateAutoAssignmentRuleTypeSelect
        ruleType={ruleType}
        onChange={ruleType =>
          onChange({ id, __typename: `${ruleType}AutoAssignmentRule` })
        }
        data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-ruleType`}
        className={classes.ruleType}
      />

      {showDepartment && (
        <ProjectTemplateAutoAssignmentRuleDepartmentSelect
          departmentId={departmentId}
          onChange={department => onChange({ ...rule, department })}
          data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-department`}
          className={classes.department}
        />
      )}

      {showUnion && (
        <ProjectTemplateAutoAssignmentRuleUnionSelect
          unionCode={unionCode}
          onChange={union => onChange({ ...rule, union })}
          data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-union`}
          className={classes.union}
        />
      )}

      {showLocation && (
        <React.Fragment>
          <ProjectTemplateAutoAssignmentRuleStateSelect
            label={
              <>
                Hire <UsaOnly>State</UsaOnly>
                <CanadaOnly>Province</CanadaOnly>
              </>
            }
            stateId={hireStateId}
            onChange={hireState => onChange({ ...rule, hireState })}
            data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-hireState`}
            className={classes.hireState}
            projectCountryId={projectCountryId}
          />
          <ProjectTemplateAutoAssignmentRuleStateSelect
            label={
              <>
                Work <UsaOnly>State</UsaOnly>
                <CanadaOnly>Province</CanadaOnly>
              </>
            }
            stateId={workStateId}
            onChange={workState =>
              onChange({ ...rule, workState, workCity: null })
            }
            data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-workState`}
            className={classes.workState}
            projectCountryId={projectCountryId}
          />
          <UsaOnly>
            {!!workStateId && (
              <ProjectTemplateAutoAssignmentRuleCitySelect
                label="Work City"
                cityId={workCityId}
                stateId={workStateId}
                onChange={workCity => onChange({ ...rule, workCity })}
                data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-workCity`}
                className={classes.workCity}
              />
            )}
          </UsaOnly>
        </React.Fragment>
      )}

      {showEmploymentType && (
        <ProjectTemplateAutoAssignmentRuleEmploymentTypeSelect
          employmentTypeId={employmentTypeId}
          onChange={employmentType => onChange({ ...rule, employmentType })}
          data-test-id={`ProjectTemplateAutoAssignmentRuleEditor-employmentType`}
          className={classes.employmentType}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRuleEditor);
