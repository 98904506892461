import React from 'react';
import HireStateSelect from './HireStateSelect';
import HireCitySelect from './HireCitySelect';
import WorkStateSelect from './WorkStateSelect';
import WorkCitySelect from './WorkCitySelect';
import HiringStatusSelect from './HiringStatusSelect';
import UnionSelect from './UnionSelect';
import OccupationSelect from './OccupationSelect';
import WorkScheduleSelect from './WorkScheduleSelect';
import DepartmentSelect from './DepartmentSelect';
import SeasonSelect from './SeasonSelect';
import CurrencySelect from './CurrencySelect';
import JobDescriptionInput from './JobDescriptionInput';
import EmploymentClassifcation from './EmploymentClassifcation';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'class-names';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import useProjectCurrencies from '../hooks/useProjectCurrencies';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  header: {
    gridColumn: '1 / 3',
    gridRow: 1,
    marginBottom: 10,
  },
  hireState: {
    gridColumn: 1,
  },
  hireCity: {
    gridColumn: 1,
  },
  workState: {
    gridColumn: 1,
  },
  workCity: {
    gridColumn: 1,
  },
  employmentClassification: {
    gridColumn: 1,
    display: 'grid',
    width: '100%',
  },
  union: {
    gridColumn: 2,
    gridRow: 1,
  },
  rightItems: {
    width: '48%',
  },
  leftItems: {
    width: '48%',
  },
  itemSpacing: {
    marginTop: 10,
    marginBottom: 10,
  },
  workSchedule: {
    gridColumn: 2,
    gridRow: 3,
  },
  department: {
    gridColumn: 2,
    gridRow: 4,
  },
  season: {
    gridColumn: 1,
  },
  jobDescription: {
    gridColumn: 2,
    gridRow: 5,
  },
  hplusDept: {
    marginTop: 23,
  },
  selectContainer: {
    width: '100%',
  },
  error: {
    fontSize: '0.9rem',
    paddingLeft: '5px',
    display: 'inline-block',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  errorSelect: {
    borderColor: 'red',
  },
  selectLabel: {
    fontSize: '.9rem',
    padding: '5px',
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
  },
});

const TermsOfHire = props => {
  const {
    offerDefaults: { enforceLoanOut, countryId },
    onChange,
    projectId,
    toggleLoanOutRestrictionModal,
    formData = {},
    formErrors = {},
    classes = {},
    isReviewOffer,
    disabled,
  } = props;

  const selectProps = key => ({
    disabled,
    onChange,
    formData,
    isReviewOffer,
    error: formErrors[key],
    classes: {
      root: classNames(classes.selectContainer, classes.itemSpacing),
      label: classes.selectLabel,
      select: classes.select,
      error: classes.error,
    },

    selectStyles: {
      control: provided => ({
        ...provided,
        borderColor: formErrors[key] ? 'red' : 'hsl(0,0%,80%)',
      }),
    },
  });

  const { data: currencies } = useProjectCurrencies({ projectId });
  const { hiringStatus = null } = formData;

  return (
    <div>
      <Typography className={classes.header} variant="h5">
        Terms of Hire
        <Typography variant="subtitle1" className={classes.description}>
          {isReviewOffer
            ? 'Review crew member and Union type'
            : 'Define crew member and Union type'}
        </Typography>
      </Typography>
      <div className={classes.root}>
        <div className={classes.leftItems}>
          {' '}
          <SeasonSelect
            {...selectProps('season')}
            isReviewOffer={isReviewOffer}
          />
          <HireStateSelect
            {...selectProps('hireState')}
            countryId={countryId}
          />
          <UsaOnly>
            <HireCitySelect
              {...selectProps('hireCity')}
              countryId={countryId}
            />
          </UsaOnly>
          <WorkStateSelect
            {...selectProps('workState')}
            countryId={countryId}
          />
          <UsaOnly>
            <WorkCitySelect
              {...selectProps('workCity')}
              countryId={countryId}
            />
            {!!hiringStatus && (
              <HiringStatusSelect {...selectProps('hiringStatus')} />
            )}
          </UsaOnly>
          <CanadaOnly>
            {currencies?.length > 1 && (
              <CurrencySelect
                {...selectProps('currency')}
                currencies={currencies}
              />
            )}
          </CanadaOnly>
          <EmploymentClassifcation
            {...selectProps('employmentClassification')}
            toggleModal={toggleLoanOutRestrictionModal}
            enforceLoanOut={enforceLoanOut}
          />
        </div>
        <div className={classes.rightItems}>
          <UnionSelect {...selectProps('union')} countryId={countryId} />
          <OccupationSelect {...selectProps('occupation')} />
          <WorkScheduleSelect {...selectProps('workSchedule')} />
          <DepartmentSelect {...selectProps('department')} />
          <JobDescriptionInput {...selectProps('jobDescription')} />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(TermsOfHire);
