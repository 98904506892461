import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: `
      "ruleType department  .......... ........"
      "ruleType union       occupation schedule"
      "ruleType hireState   workState  workCity"
    `,
    gridTemplateColumns: '24% 24% 24% 24%',
    gridTemplateRows: 'auto auto auto',
    gridColumnGap: '8px',
    columnGap: '8px',
    fontSize: '1.2em',
    background: '#f7fcfc',
    paddingTop: 8,
    paddingLeft: 8,
  },
  ruleType: {
    gridArea: 'ruleType',
    paddingBottom: 8,
  },
  department: {
    gridArea: 'department',
    paddingBottom: 8,
  },
  union: {
    gridArea: 'union',
    paddingBottom: 8,
  },
  occupation: {
    gridArea: 'occupation',
    paddingBottom: 8,
  },
  schedule: {
    gridArea: 'schedule',
    paddingBottom: 8,
  },
  hireState: {
    gridArea: 'hireState',
    paddingBottom: 8,
  },
  workState: {
    gridArea: 'workState',
    paddingBottom: 8,
  },
  workCity: {
    gridArea: 'workCity',
    paddingBottom: 8,
  },
});

const ProjectTemplateAutoAssignmentRuleDisplay = props => {
  const { classes, rule } = props;

  const {
    ruleType: { description: ruleTypeName, code },
    department: { name: departmentName = '--' } = {},
    union: { description: unionName = '--' } = {},
    occupation: { description: occupationName = '--' } = {},
    schedule: { description: scheduleName = '--' } = {},
    hireState: { name: hireStateName = '--' } = {},
    workState: { name: workStateName = '--' } = {},
    workCity: { name: workCityName = '--' } = {},
  } = rule;

  const isCombo = code === 'combo';
  const showDepartment = isCombo || code === 'department';
  const showUnion = isCombo || code === 'union';
  const showLocation = isCombo || code === 'location';

  return (
    <div className={classes.root}>
      <div
        className={classes.ruleType}
        data-test-id="ProjectTemplateAutoAssignmentRuleDisplay-ruleType"
      >
        <Typography variant="caption">Rule Type</Typography>
        <div>{ruleTypeName}</div>
      </div>
      {showDepartment && (
        <div
          className={classes.department}
          data-test-id="ProjectTemplateAutoAssignmentRuleDisplay-department"
        >
          <Typography variant="caption">Department</Typography>
          <div>{departmentName}</div>
        </div>
      )}
      {showUnion && (
        <React.Fragment>
          <div
            className={classes.union}
            data-test-id="ProjectTemplateAutoAssignmentRuleDisplay-union"
          >
            <Typography variant="caption">Union</Typography>
            <div>{unionName}</div>
          </div>
          <div
            className={classes.occupation}
            data-test-id="ProjectTemplateAutoAssignmentRuleDisplay-occupation"
          >
            <Typography variant="caption">Occupation</Typography>
            <div>{occupationName}</div>
          </div>
          <div
            className={classes.schedule}
            data-test-id="ProjectTemplateAutoAssignmentRuleDisplay-schedule"
          >
            <Typography variant="caption">Schedule</Typography>
            <div>{scheduleName}</div>
          </div>
        </React.Fragment>
      )}
      {showLocation && (
        <React.Fragment>
          <div
            className={classes.hireState}
            data-test-id="ProjectTemplateAutoAssignmentRuleDisplay-hireState"
          >
            <Typography variant="caption">Hire State</Typography>
            <div>{hireStateName}</div>
          </div>
          <div
            className={classes.workState}
            data-test-id="ProjectTemplateAutoAssignmentRuleDisplay-workState"
          >
            <Typography variant="caption">Work State</Typography>
            <div>{workStateName}</div>
          </div>
          <div
            className={classes.workCity}
            data-test-id="ProjectTemplateAutoAssignmentRuleDisplay-workCity"
          >
            <Typography variant="caption">Work City</Typography>
            <div>{workCityName}</div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRuleDisplay);
