import React, { useState } from 'react';
import { Button, Typography, TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const addNotesStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#f7fcfc',
    padding: '20px 50px',
  },
  title: {
    color: '#2eaba4',
    fontSize: '18px',
    fontWeight: 400,
  },
  description: {
    color: '#646464',
    fontSize: 14,
    marginBottom: '20px',
    marginTop: '20px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  closeBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: '40px',
    background: 'transparent !important',
    color: '#2eaba4',
    border: '1px solid #2eaba4',
    textTransform: 'none !important',
    minWidth: '91px',
    borderRadius: 2,
  },
  submitBtn: {
    background: '#2eaba4',
    height: '40px',
    color: 'white',
    marginLeft: '20px',
    minWidth: '91px',
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 500,
  },
  textArea: {
    width: '100%',
    borderRadius: 2,
    border: '1 solid #b0bec5',
    backgroundColor: '#ffffff',
    padding: '10px',
    color: '#333',
    fontSize: 14,
  },
  errorMessage: {
    color: 'red',
    fontSize: 14,
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

const AddNote = props => {
  const { onClose, closeAddNote, rejectOfferHandler } = props;
  const classes = addNotesStyles();
  const [comment, setComment] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const sendNote = () => {
    if (comment.length > 0) {
      rejectOfferHandler(comment);
      closeAddNote();
    } else {
      setErrorMessage('Please enter an explanation');
    }
  };

  return (
    <div className={classes.root} data-test-id="RejectOffer-AddNote">
      <Typography className={classes.title}>Add Note</Typography>
      <Typography className={classes.description}>
        You have chosen to reject the selected offer. It is required that you
        give us a short explanation for this action.
      </Typography>
      <div>
        <TextareaAutosize
          className={classes.textArea}
          rows={9}
          onChange={ev => setComment(ev.target.value)}
          aria-label="note text area"
          placeholder='Provide an explanation for declining this offer. Example: "the allowance amount is over budget"'
          data-test-id="RejectOffer-TextAreaNote"
        />
      </div>
      <div className={classes.errorMessage}>{errorMessage}</div>
      <div className={classes.btnContainer}>
        <Button className={classes.closeBtn} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          className={classes.submitBtn}
          onClick={() => sendNote()}
          data-test-id="RejectOffer-submitBtn"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddNote;
