import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  CheckCircleOutline as SuccessIcon,
  Error as FailureIcon,
  Warning as ConfirmationIcon,
} from '@material-ui/icons';
import Dialog from 'common/components/Dialog';
import Loader from 'common/components/Loader';
import usePrevious from 'common/utilities/usePrevious';
import GoLiveDialogUserList from './GoLiveDialogUserList';

// Steps
const CONFIGURATION = 0;
const CONFIRMATION = 1;
const PROCESSING = 2;
const FAILURE = 3;
const SUCCESS = 4;

const styles = {
  paper: {
    width: 600,
    height: 600,
  },
  userListContainer: {
    marginTop: 20,
    height: 330,
    overflowY: 'scroll',
    padding: 1,
  },
  configurationFooter: {
    '& strong': { fontWeight: 'bold' },
    '& span': { color: '#45a7dc', marginLeft: 10 },
  },
  iconDialog: {
    textAlign: 'center',
    position: 'relative',
    top: 75,
    '& h5': { fontSize: '2em' },
    '& h6': { fontSize: '1.3em' },
    '& strong': { fontWeight: 'bold' },
  },
  confirmationIcon: {
    width: '100%',
    height: 200,
    color: '#f4b524',
  },
  processingIcon: {
    width: '100%',
    height: 200,
    color: '#45a7dc',
  },
  failureIcon: {
    width: '100%',
    height: 200,
    color: '#ce7d7d',
  },
  successIcon: {
    width: '100%',
    height: 200,
    color: '#00a44c',
  },
  closeBtn: {
    background: '#f4f6f8',
    color: 'black',
  },
  continueBtn: {
    background: '#45a7dc',
    color: 'white',
  },
  red: {
    color: 'red',
  },
};

const GoLiveDialog = props => {
  const {
    classes,
    onClose,
    onGoLive,
    open,
    project: { name: projectName },
    status,
    users,
  } = props;

  // State
  const allUserIds = users.map(u => u.id);
  const [step, setStep] = useState(CONFIGURATION);
  const [failureMessage, setFailureMessage] = useState(null);
  const [checkedUserIds, setCheckedUserIds] = useState(allUserIds);

  // Computed values
  const userCount = users.length;
  const selectedUserCount = checkedUserIds.length;
  const offerCount = users.reduce((acc, u) => acc + u.offers.length, 0);
  const selectedOfferCount = users.reduce(
    (acc, u) => acc + (checkedUserIds.includes(u.id) ? u.offers.length : 0),
    0,
  );

  const close = () => {
    if (step === SUCCESS) {
      window.location.reload();
    } else {
      onClose();
    }
  };

  // Stages
  const getSuccessBody = () => (
    <span className={classes.iconDialog} data-test-id="GoLiveDialog-success">
      <SuccessIcon className={classes.successIcon} />
      <Typography variant="h5">Success!</Typography>
      <Typography variant="subtitle1">
        All test users and associated offers have been removed from{' '}
        {projectName}.
      </Typography>
    </span>
  );

  const getFailureBody = () => (
    <span className={classes.iconDialog} data-test-id="GoLiveDialog-failure">
      <FailureIcon className={classes.failureIcon} />
      <Typography variant="h5">Something Went Wrong</Typography>
      <Typography variant="subtitle1">
        We were unable to set the project live.
      </Typography>
      {!!failureMessage && (
        <Typography className={classes.red}>
          Reason: {failureMessage}
        </Typography>
      )}
    </span>
  );

  const getProcessingBody = () => (
    <span className={classes.iconDialog} data-test-id="GoLiveDialog-processing">
      <div>
        <Loader size={200} />
      </div>
      <Typography variant="h5">Processing...</Typography>
      <Typography variant="subtitle1">
        This process may take a couple of minutes. If you close the window, we
        will notify you when the process is complete.
      </Typography>
    </span>
  );

  const getConfirmationBody = () => (
    <span
      className={classes.iconDialog}
      data-test-id="GoLiveDialog-confirmation"
    >
      <ConfirmationIcon className={classes.confirmationIcon} />
      <Typography variant="h5">Are You Sure?</Typography>
      {!!selectedUserCount && (
        <Typography variant="subtitle1">
          You have selected <strong>{selectedUserCount} users</strong> with{' '}
          <strong>{selectedOfferCount} offers</strong> for removal. Do you wish
          to go live?
        </Typography>
      )}
      {!selectedUserCount && (
        <Typography variant="subtitle1">
          Are you sure you want to go live without removing any test users?
        </Typography>
      )}
    </span>
  );

  const getConfigurationBody = () => {
    return (
      <div data-test-id="GoLiveDialog-configuration">
        <Typography variant="subtitle1">
          Transitioning the project to "Live" will remove all selected test
          users and associated offers, regardless of role. Uncheck any users
          that you wish to keep in the live project. Click the chevron for
          expanded view of related offers, if any.
        </Typography>
        <div className={classes.userListContainer}>
          <GoLiveDialogUserList
            users={users}
            checkedUserIds={checkedUserIds}
            onChangeCheckedUsers={setCheckedUserIds}
          />
        </div>
        <br />
        <Typography variant="h5" className={classes.configurationFooter}>
          <strong>Removing:</strong>
          <span>
            {selectedUserCount}/{userCount}
          </span>{' '}
          Users
          <span>
            {selectedOfferCount}/{offerCount}
          </span>{' '}
          Offers
        </Typography>
      </div>
    );
  };

  // Dialog body
  const body = (() => {
    switch (step) {
      case CONFIGURATION:
        return getConfigurationBody();
      case CONFIRMATION:
        return getConfirmationBody();
      case PROCESSING:
        return getProcessingBody();
      case FAILURE:
        return getFailureBody();
      case SUCCESS:
        return getSuccessBody();
      default:
        return null;
    }
  })();

  // Dialog actions
  const configure = () => setStep(CONFIRMATION);
  const confirm = () => {
    onGoLive(checkedUserIds).catch(e => {
      const { graphQLErrors = [] } = e || {};
      const { message = '' } = graphQLErrors[0] || {};
      setFailureMessage(message);
      setStep(FAILURE);
    });
    setStep(PROCESSING);
  };
  const actions = (() => {
    switch (step) {
      case CONFIGURATION:
        return [
          { text: 'Cancel', fn: close, className: classes.closeBtn },
          { text: 'Continue', fn: configure, className: classes.continueBtn },
        ];
      case CONFIRMATION:
        return [
          { text: 'Cancel', fn: close, className: classes.closeBtn },
          { text: 'Go Live', fn: confirm, className: classes.continueBtn },
        ];
      case PROCESSING:
      case FAILURE:
      case SUCCESS:
        return [{ text: 'Close', fn: close, className: classes.closeBtn }];
      default:
        throw new Error('Invalid step!');
    }
  })();

  // Handle transition from processing to failure or success, and handle wiping
  // state on open.
  const prevStatus = usePrevious(status);
  const prevOpen = usePrevious(open);
  useEffect(() => {
    if (prevStatus !== status) {
      if (status === 'live') setStep(SUCCESS);
      if (status === 'live_failed') setStep(FAILURE);
    }
    if (!prevOpen && open) {
      setStep(CONFIGURATION);
      setCheckedUserIds(allUserIds);
    }
  }, [prevStatus, status, prevOpen, open, allUserIds]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      title="Go Live"
      message={body}
      actions={actions}
      handleClose={close}
    />
  );
};

export default withStyles(styles)(GoLiveDialog);
