import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  cls1: {
    opacity: '1',
  },
  cls2: {
    fill: 'none',
    stroke: '#419dcd',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '1.78px',
  },
});

const MailBox = ({ classes, ...propsCatch }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42.78 42.78"
    {...propsCatch}
  >
    <title>mailing</title>
    <g className={classes.cls1}>
      <path
        className={classes.cls2}
        d="M580,5187h-10.7v-16a5.35,5.35,0,0,1,10.7,0v16Z"
        transform="translate(-568.44 -5155.78)"
      />
      <path
        className={classes.cls2}
        d="M574.68,5165.59H605a5.35,5.35,0,0,1,5.35,5.35v16H580"
        transform="translate(-568.44 -5155.78)"
      />
      <line
        className={classes.cls2}
        x1="24.07"
        y1="31.2"
        x2="24.07"
        y2="41.89"
      />
      <line
        className={classes.cls2}
        x1="4.46"
        y1="27.63"
        x2="8.02"
        y2="27.63"
      />
      <line className={classes.cls2} x1="8.02" y1="9.8" x2="8.02" y2="4.46" />
      <rect
        className={classes.cls2}
        x="8.02"
        y="0.89"
        width="5.35"
        height="3.57"
      />
    </g>
  </svg>
);

export default withStyles(styles)(MailBox);
