import React, { useEffect } from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Components
import List from '@material-ui/core/List';
import CrewMember from './CrewMember';

const styles = theme => ({
  root: {
    overflowY: 'auto',
  },
});

const CrewList = ({
  onChange,
  graphqlSearch,
  clearGraphqlSearch,
  isSelected,
  crew = [],
  classes = {},
} = {}) => {
  // We clear the search here after this component mounts to prevent
  // freezing due to firing off another graphql request
  useEffect(() => {
    if (graphqlSearch === '') return;
    clearGraphqlSearch();
  }, [graphqlSearch, clearGraphqlSearch]);

  return (
    <List className={classes.root}>
      {crew.map(({ node = {} } = {}) => (
        <CrewMember
          key={node.id}
          {...node.profile}
          onClick={() => onChange(node)}
          selected={isSelected(node.id)}
        />
      ))}
    </List>
  );
};

export default withStyles(styles)(CrewList);
