import React, { useState } from 'react';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Done as SelectedIcon,
  PersonAdd as UnselectedIcon,
} from '@material-ui/icons';
import * as Queries from 'common/apollo/queries';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import toggleInList from 'common/utilities/toggleInList';
import * as colors from '@castandcrew/cnc-ui/theme/colors';

const styles = {
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  userList: {
    height: 400,
    overflowY: 'scroll',
  },
  listItem: {
    cursor: 'pointer',
  },
  tick: {
    color: colors.GREEN,
  },
};

const ProjectTemplateWorkflowApproversSelector = props => {
  const {
    classes,
    loading,
    onClose,
    onSubmit,
    open,
    projectApprovers,
    defaultSelectedApproverIds = [],
  } = props;

  const [selectedApproverIds, setSelectedApproverIds] = useState(
    defaultSelectedApproverIds,
  );

  const isAllOptionsSelected =
    selectedApproverIds.length === projectApprovers.length;

  const approverOption = approver => {
    const {
      id,
      profile: { firstName, lastName },
    } = approver;
    const name = `${firstName} ${lastName}`;
    const isSelected = selectedApproverIds.includes(id);
    return { id, name, isSelected };
  };

  const toggleSelectAll = () =>
    setSelectedApproverIds(
      isAllOptionsSelected ? [] : projectApprovers.map(({ id }) => id),
    );

  const submit = () => {
    const selectedApprovers = selectedApproverIds.map(id =>
      projectApprovers.find(u => u.id === id),
    );
    onSubmit(selectedApprovers);
    onClose();
  };

  if (loading) return null;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle data-test-id="ProjectTemplateWorkflowApproversSelector-title">
        {defaultSelectedApproverIds.length ? 'Edit' : 'Add'} Approvers
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAllOptionsSelected}
              onChange={toggleSelectAll}
              data-test-id="ProjectTemplateWorkflowApproversSelector-selectAll"
            />
          }
          label="Select all"
        />
        <List className={classes.userList}>
          {projectApprovers
            .map(approverOption)
            .map(({ id, name, isSelected }) => (
              <ListItem
                onClick={() =>
                  setSelectedApproverIds(toggleInList(selectedApproverIds, id))
                }
                key={id}
                className={classes.listItem}
              >
                <ListItemIcon>
                  {isSelected ? (
                    <SelectedIcon
                      className={classes.tick}
                      data-test-id={`ProjectTemplateWorkflowApproversSelector-selectedUser-${id}`}
                    />
                  ) : (
                    <UnselectedIcon
                      data-test-id={`ProjectTemplateWorkflowApproversSelector-unselectedUser-${id}`}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!selectedApproverIds.length}
          onClick={submit}
          variant="contained"
          data-test-id="ProjectTemplateWorkflowApproversSelector-submit"
        >
          {defaultSelectedApproverIds.length ? 'Update' : 'Add'} Approvers
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const withProjectApprovers = graphql(Queries.projectUsers, {
  options: ({ isTemplateI9, projectId }) => ({
    variables: {
      id: projectId,
      userRole: isTemplateI9 ? 'i9_verifier' : 'approver',
    },
    // Always go to the network
    // TODO Once invitation page is graphql we can replace this with
    // refetchQueries on the mutator.
    fetchPolicy: 'cache-and-network',
  }),
  props: ({
    data: { loading, project: { users: projectApprovers = [] } = {} },
  }) => ({
    loading,
    projectApprovers,
  }),
});

export default compose(
  withRouteHelpers,
  withProjectApprovers,
  withStyles(styles),
)(ProjectTemplateWorkflowApproversSelector);
