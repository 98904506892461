import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import { connect } from 'react-redux';

// GQL
import { Queries, Mutations } from 'common/apollo';

// MuiComponents
import {
  Button,
  Paper,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';

// Icons
import { Search as SearchIcon } from '@material-ui/icons';

// Components

import Loader from 'common/components/Loader';
import {
  PermissionPresetForm,
  PermissionPresetList,
} from 'admin/components/PermissionPreset';

// constants
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

// actions
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import Header from 'common/oldJavascripts/components/Base/Header';

const styles = {
  search: {
    paddingRight: 16,
    width: 250,
  },
};

class PresetPage extends React.Component {
  state = {
    filter: [],
    isOpen: false,
    comfirmDelete: null,
    preset: {},
    permissionSelection: [],
    blockCopy: false,
  };

  handleFilter = event => {
    const {
      target: { value },
    } = event;

    this.setState({
      filter: value
        .replace(/,/gi, '')
        .trim()
        .toLowerCase()
        .split(' '),
    });
  };

  filterCallbak = item => {
    const { filter = [] } = this.state;
    const { name = '' } = item || {};
    if (filter.length === 0) return true;
    return filter.some(word => name.toLowerCase().includes(word));
  };

  confirmDelete = id => event => this.setState({ confirmDeleteId: id });

  cancelDelete = () => this.setState({ confirmDeleteId: null });

  handleDelete = () => {
    const { confirmDeleteId } = this.state;
    // Close Dialog
    this.setState({ confirmDeleteId: null });

    const {
      DeletePermissionPresetMutate: mutate,
      PermissionPresetQuery,
      pushNotification,
      popNotification,
    } = this.props;
    const loadingMessage = 'Deleting permission preset.';
    // Notifications
    popNotification({ popAll: true });
    pushNotification({
      message: loadingMessage,
      variant: SnackbarVariants.INFO,
    });

    // Delete Permission Preset
    mutate({ variables: { id: parseInt(confirmDeleteId, 10) } })
      .then(() => {
        popNotification({ message: loadingMessage, popAllMatching: true });
        pushNotification({
          message: 'Successfully deleted permission preset.',
          variant: SnackbarVariants.SUCCESS,
        });
        PermissionPresetQuery.refetch();
      })
      .catch(err => {
        const { message: errMessage = '' } = err || {};
        let displayErrorMessage =
          'Error deleting this permission preset, please try again';

        if (errMessage.startsWith('GraphQL error:'))
          displayErrorMessage = errMessage.substring(15);

        popNotification({ message: loadingMessage, popAllMatching: true });
        pushNotification({
          message: displayErrorMessage,
          variant: SnackbarVariants.ERROR,
        });
        PermissionPresetQuery.refetch();
      });
  };

  handleEdit = id => event => {
    const {
      PermissionPresetQuery: { permissionPresets = [] } = {},
    } = this.props;
    const preset = permissionPresets.find(p => p.id === id) || {};
    const { permissions = [] } = preset;

    if (preset) {
      this.setState({
        preset: { ...preset },
        permissionSelection: permissions.map(s => s.id),
        isOpen: true,
      });
    }
  };

  handleCopy = id => event => {
    const {
      PermissionPresetQuery,
      CreatePermissionPresetMutate,
      pushNotification,
      popNotification,
    } = this.props;
    // Block race conditions with copy name creation
    const { blockCopy } = this.state;
    if (blockCopy) return;
    this.setState({ blockCopy: true });

    const { permissionPresets = [] } = PermissionPresetQuery;
    const preset = permissionPresets.find(p => p.id === id) || {};
    const { permissions = [] } = preset;
    const loadingMessage = 'Copying permission preset.';
    // Notifications
    popNotification({ popAll: true });
    pushNotification({
      message: loadingMessage,
      variant: SnackbarVariants.INFO,
    });

    // Prepare Data
    const variables = {
      name: this.getNextAvailableName(preset.name),
      permissions: permissions.map(p => parseInt(p.id, 10)),
    };

    // Post
    CreatePermissionPresetMutate({ variables })
      .then(() => {
        popNotification({ message: loadingMessage, popAllMatching: true });
        pushNotification({
          message: 'Successfully copied permission preset.',
          variant: SnackbarVariants.SUCCESS,
        });

        PermissionPresetQuery.refetch().then(() =>
          this.setState({ blockCopy: false }),
        );
      })
      .catch(err => {
        const { message: errMessage = '' } = err || {};
        let displayErrorMessage =
          'There was an error copying the permission preset.  Please try again, or contact product support if the issue persists.';

        if (errMessage.startsWith('GraphQL error:'))
          displayErrorMessage = errMessage.substring(15);

        popNotification({ message: loadingMessage, popAllMatching: true });
        pushNotification({
          message: displayErrorMessage,
          variant: SnackbarVariants.ERROR,
        });
        this.setState({ blockCopy: false });
      });
  };

  handleSave = () => {
    const {
      PermissionPresetQuery,
      pushNotification,
      popNotification,
    } = this.props;

    // Validate Inputs
    const errors = this.validateInputs();
    this.setState({ errors });
    // Inputs invalid, do not proceed
    if (errors) return;
    const loadingMessage = 'Saving permission preset.';
    // Notifications
    popNotification({ popAll: true });
    pushNotification({
      message: loadingMessage,
      variant: SnackbarVariants.INFO,
    });

    const {
      preset: { name, id },
      permissionSelection = [],
    } = this.state;

    const isEdit = !!id;

    // Get graphql mutator
    const mutate = isEdit
      ? this.props.UpdatePermissionPresetMutate
      : this.props.CreatePermissionPresetMutate;

    // Data to post
    const permissions = permissionSelection.map(p => parseInt(p, 10));
    const variables = { permissions, name };
    if (isEdit) variables['id'] = parseInt(id, 10);

    // Create or Update Call
    mutate({ variables })
      .then(() => {
        this.setState({
          isOpen: false,
          permissionSelection: [],
          preset: {},
          errors: {},
        });
        popNotification({ message: loadingMessage, popAllMatching: true });
        pushNotification({
          message: 'Successfully saved permission preset.',
          variant: SnackbarVariants.SUCCESS,
        });
        PermissionPresetQuery.refetch();
      })
      .catch(err => {
        const { message: errMessage = '' } = err || {};
        let displayErrorMessage =
          'There was an error saving the permission preset.  Please try again, or contact product support if the issue persists.';

        if (errMessage.startsWith('GraphQL error:'))
          displayErrorMessage = errMessage.substring(15);

        popNotification({ message: loadingMessage, popAllMatching: true });
        pushNotification({
          message: displayErrorMessage,
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  handleCancel = () => {
    this.setState(() => {
      return {
        isOpen: false,
        permissionSelection: [],
        preset: {},
        errors: {},
      };
    });
  };

  handlePermissionChange = attr => newValue => {
    this.setState({ permissionSelection: newValue });
  };

  handlePresetChange = attr => newValue => {
    this.setState(() => {
      const { preset } = this.state;
      preset[attr] = newValue;

      return { preset };
    });
  };

  getNextAvailableName = name => {
    const {
      PermissionPresetQuery: { permissionPresets = [] } = {},
    } = this.props;

    // let prefix = name.trim(), max = 0, suffix = 0, next;
    let prefix = name.trim(),
      suffix,
      next = 1;

    // 1. Test if [name] ends with number, e.g. "Permission Set 22 "
    const matches = prefix.match(/\d+$/);
    if (matches) {
      // [name] does end with numbers,
      // update prefix, suffix, next
      // e.g. prefix = "Permission Set", suffix = 22, next = 23
      prefix = prefix.slice(0, matches.index).trim();
      suffix = parseInt(matches[0], 10);
      next = suffix + 1;
    }

    // 2. Caculate the next number in sequence
    // and append it to prefix
    for (let i = 0; i < permissionPresets.length; i++) {
      let name = permissionPresets[i].name.trim();

      if (name.startsWith(prefix)) {
        // Get the suffix and try turn it into a number
        suffix = parseInt(name.slice(prefix.length), 10);
        if (isNaN(suffix)) continue;

        if (suffix === next) {
          // [next] is already taken, increment [next]
          next = next + 1;
        }
      }
    }

    return `${prefix} ${next}`;
  };

  validateInputs = () => {
    // This is to validate required fields not empty
    const {
      preset: { name = '' },
      permissionSelection = [],
    } = this.state;
    let error = null;

    if (name.trim().length === 0)
      error = { ...error, name: 'Name is required' };

    if (name.length > 100)
      error = { ...error, name: 'Max length is 100 characters' };

    if (permissionSelection.length === 0)
      error = { ...error, permissions: 'Please select permissions' };

    return error;
  };

  render() {
    const {
      isOpen,
      preset,
      errors,
      filter,
      confirmDeleteId,
      blockCopy,
    } = this.state;
    const {
      classes,
      PermissionQuery = {},
      PermissionPresetQuery = {},
    } = this.props;
    const { permissions: allPermissions = [] } = PermissionQuery;
    const { permissionPresets = [] } = PermissionPresetQuery;

    const filteredPresets = permissionPresets.filter(this.filterCallbak);

    return (
      <Paper elevation={0}>
        <Header>
          <Header.Title>
            <span data-test-id="AdminUsers-title">Permission Presets</span>
          </Header.Title>
          <Header.Nav>
            <TextField
              className={classes.search}
              margin="dense"
              placeholder="Filter Permission Presets"
              onChange={this.handleFilter}
              InputProps={{
                inputProps: { 'data-test-id': 'Presets-search' },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                this.setState({ isOpen: true });
              }}
              data-test-id="Presets-add"
            >
              Add New Preset
            </Button>
          </Header.Nav>
        </Header>

        {PermissionPresetQuery.loading && <Loader size={36} />}
        <PermissionPresetList
          filter={filter}
          collection={filteredPresets}
          onEdit={this.handleEdit}
          onDelete={this.confirmDelete}
          onCopy={this.handleCopy}
          blockCopy={blockCopy}
        />

        <Dialog open={isOpen} maxWidth="md" scroll="body">
          <DialogContent>
            <PermissionPresetForm
              preset={preset}
              errors={errors}
              selections={this.state.permissionSelection}
              permissionGroups={allPermissions}
              onPermissionChange={this.handlePermissionChange}
              onPresetChange={this.handlePresetChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleCancel}
              data-test-id="Presets-dialog-cancel"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleSave}
              data-test-id="Presets-dialog-save"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={confirmDeleteId != null} maxWidth="sm">
          <DialogContent>
            <Typography variant="h6">
              Are you sure to delete this permission preset?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.cancelDelete}
              color="secondary"
              data-test-id="Presets-confirmDelete-cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleDelete}
              color="secondary"
              data-test-id="Presets-confirmDelete-OK"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

export default compose(
  graphql(Mutations.CreatePermissionPreset, {
    name: 'CreatePermissionPresetMutate',
  }),
  graphql(Mutations.UpdatePermissionPreset, {
    name: 'UpdatePermissionPresetMutate',
  }),
  graphql(Mutations.DeletePermissionPreset, {
    name: 'DeletePermissionPresetMutate',
  }),
  graphql(Queries.GetPermissions, {
    name: 'PermissionQuery',
    options: props => ({
      variables: {
        admin: true,
      },
    }),
  }),
  graphql(Queries.GetPermissionPresets, {
    name: 'PermissionPresetQuery',
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(PresetPage);
