import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import shallowCompare from 'react-addons-shallow-compare';
import isEmpty from 'common/utilities/isEmpty';
import uniqueId from 'common/utilities/uniqueId';
import palette from 'common/shared/oldDocumentSignerUI/palette';
import { Button, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import ProjectTemplateAutoAssignmentRule from './ProjectTemplateAutoAssignmentRule';
import ruleTypes from './ruleTypes';
import ruleConfig from './ruleConfig';

const styles = theme => ({
  rulesList: {
    marginTop: theme.spacing.unit,
    flexGrow: 1,
  },
  headingText: {
    padding: `${theme.spacing.unit}px 0px`,
  },
  rulesRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  button: {
    marginLeft: theme.spacing.unit,
    color: 'white',
    backgroundColor: palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
});

const ruleTypeAll = { description: 'All', code: 'all', id: 4 };

class ProjectTemplateAutoAssignmentRules extends Component {
  static defaultProps = {
    rules: [],
  };

  constructor(props) {
    super(props);
    const rules = this.prepareRules(props.rules);
    const setToAll = rules[0] && rules[0].ruleType.id === 4;
    const rulesLoadingStatus = rules.map(rule => {
      const fieldStatus = {};
      Object.keys(rule).forEach(key => (fieldStatus[key] = null));
      return fieldStatus;
    });
    this.state = {
      setToAll,
      rules,
      rulesLoadingStatus,
      options: {
        ruleType: ruleTypes,
        department: [],
        union: [],
        occupation: {},
        schedule: {},
        hireState: [],
        workState: [],
        workCity: {},
        ruleIndexBeingEdited: null,
      },
      requested: false,
    };
  }

  componentDidMount() {
    // HACK This component is terrible and needs to be violently refactored
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(newProps) {
    const { isExpanded, rules: newRules } = newProps;
    const { rules: oldRules } = this.props;
    const { requested } = this.state;
    if (isExpanded && !requested) {
      this.setState({ requested: true }, () => {
        this.requestOptions({ key: 'department' });
        this.requestOptions({ key: 'union' });
        this.requestOptions({
          key: 'workState',
          stateKeys: ['workState', 'hireState'],
        });
        this.requestOptions({ key: 'workCity' });
        this.updateStateFromProps(newProps);
        this.optionRequestCheck(newProps);
      });
    }
    if (oldRules === newRules) return;
    this.updateStateFromProps(newProps);
    this.optionRequestCheck(newProps);
  }

  shouldComponentUpdate = (nextProps, nextState) =>
    shallowCompare(this, nextProps, nextState);

  updateStateFromProps = ({ rules }) => {
    const setToAll = rules[0] && rules[0].ruleType.id === 4;
    this.setState({
      rules: [...rules],
      setToAll,
    });
  };

  generateRule = () => ({
    ruleType: {},
    department: {},
    union: {},
    occupation: {},
    schedule: {},
    hireState: {},
    workState: {},
    workCity: {},
  });

  prepareRules = rules =>
    rules.map(rule => ({
      ...this.generateRule(),
      ...rule,
    }));

  optionRequestCheck = props => {
    const { rules = [] } = props;
    const { options, rulesLoadingStatus } = this.state;
    const {
      ruleType,
      department,
      union,
      occupation,
      schedule,
      hireState,
      workCity,
      workState,
    } = ruleConfig;
    const { key: ruleTypeKey } = ruleType;
    const { key: departmentKey } = department;
    const { key: unionKey } = union;
    const { key: occupationKey } = occupation;
    const { key: scheduleKey } = schedule;
    const { key: hireStateKey } = hireState;
    const { key: workStateKey } = workState;
    const { key: workCityKey } = workCity;
    const alreadyRequestedKeys = [
      departmentKey,
      ruleTypeKey,
      unionKey,
      hireStateKey,
      workStateKey,
    ];
    const keyOrder = [
      departmentKey,
      unionKey,
      occupationKey,
      scheduleKey,
      workStateKey,
      workCityKey,
    ];
    rules.forEach((rule, index) => {
      const ruleKeys = Object.keys(rule);
      ruleKeys.forEach(key => {
        const ruleKeyStatus = rulesLoadingStatus[index];
        // We request all items that are not dependent on a previous selection
        // In component Did Mount.  Dont request these again
        if (alreadyRequestedKeys.indexOf(key) !== -1) return;
        // If The selector that this is dependent on is empty
        // Dont request options, because we dont have a param to pass
        const previousKey = keyOrder[keyOrder.indexOf(key) - 1];
        if (isEmpty(rule[previousKey] || {})) return;
        // If we already have option data for that selection
        // Dont request it again
        if (options[key].hasOwnProperty(rule[previousKey].code)) return;
        // If the request is already in flight do not request it again
        if (ruleKeyStatus[key] === 'loading') return;
        // Fall Through: We should now have a selection for the previous selector
        // And not have any option data for it
        this.requestOptions({
          key,
          param: rule[previousKey].code,
          rule,
          index,
        });
      });
    });
  };

  updateRuleSet = () => {
    const { updateRuleSet: upstreamUpdateRuleSet } = this.props;
    const { rules } = this.state;
    upstreamUpdateRuleSet(rules);
  };

  createRule = () => {
    this.setState(({ rules, rulesLoadingStatus }) => {
      const newRule = this.generateRule();
      const newRules = [...rules, newRule];
      const fieldStatus = {};
      Object.keys(newRule).forEach(key => (fieldStatus[key] = null));
      const updatedStatuses = [...rulesLoadingStatus, fieldStatus];
      return {
        rules: newRules,
        rulesLoadingStatus: updatedStatuses,
        ruleIndexBeingEdited: newRules.length - 1,
      };
    }, this.updateRuleSet);
  };

  deleteRule = index => {
    this.setState(({ rules, rulesLoadingStatus }) => {
      const updatedLoadingStatus = [...rulesLoadingStatus];
      updatedLoadingStatus.splice(index, 1);
      const newRules = [...rules];
      const removedRule = newRules.splice(index, 1)[0];
      const addDisabled = removedRule.ruleType.id === 4;
      if (addDisabled)
        return {
          rules: newRules,
          rulesLoadingStatus: updatedLoadingStatus,
          setToAll: false,
        };
      return { rules: newRules, rulesLoadingStatus: updatedLoadingStatus };
    }, this.updateRuleSet);
  };

  requestSuccessCallback = (payload, key, param, stateKeys = [], index) => {
    const keys = stateKeys.length > 0 ? [...stateKeys] : [key];
    this.setState(({ options, rulesLoadingStatus }) => {
      const newOptions = { ...options };
      const updatedLoadingStatus = [...rulesLoadingStatus];

      // If there is a param then it is an object and the payload
      // Should be stored under that as the key
      if (!param) {
        keys.forEach(k => {
          newOptions[k] = payload;
        });
      } else {
        keys.forEach(k => {
          if (newOptions[k] === undefined) {
            newOptions[k] = {};
          }
          newOptions[k][param] = payload;
        });
      }

      if (index !== null) {
        const updatedRuleStatus = updatedLoadingStatus[index];
        updatedRuleStatus[key] = 'success';
        updatedLoadingStatus.splice(index, 1, updatedRuleStatus);
      }

      return {
        options: newOptions,
        rulesLoadingStatus: updatedLoadingStatus,
      };
    });
  };

  requestsFailureCallback = (error, key, index) => {
    if (index === null) return;
    this.setState(({ rulesLoadingStatus }) => {
      const updatedLoadingStatus = [...rulesLoadingStatus];
      const updatedRuleStatus = updatedLoadingStatus[index];
      updatedRuleStatus[key] = null;
      updatedLoadingStatus.splice(index, 1, updatedRuleStatus);
      return {
        error, // eslint-disable-line react/no-unused-state
        rulesLoadingStatus: updatedLoadingStatus,
      };
    });
  };

  requestOptions = ({
    key,
    param = false,
    stateKeys = [],
    rule,
    index = null,
  }) => {
    const { requestHandler } = this.props;
    this.setState(
      ({ rulesLoadingStatus }) => {
        const updatedLoadingStatus = [...rulesLoadingStatus];
        if (index !== null) {
          const updatedRuleStatus = updatedLoadingStatus[index];
          updatedRuleStatus[key] = 'loading';
          updatedLoadingStatus.splice(index, 1, updatedRuleStatus);
          return {
            rulesLoadingStatus: updatedLoadingStatus,
          };
        }
        return {
          rulesLoadingStatus: updatedLoadingStatus,
        };
      },
      () => {
        requestHandler({
          key,
          param,
          rule,
          successCallback: payload =>
            this.requestSuccessCallback(payload, key, param, stateKeys, index),
          failureCallback: error =>
            this.requestsFailureCallback(error, key, index),
        });
      },
    );
  };

  updateRule = ({ index, rule, nextSelectorConfig, value }) => {
    this.setState(({ rules, setToAll }) => {
      let disabledFlag = setToAll;
      if (rule.ruleType.id === 4) {
        const allRule = this.generateRule();
        allRule.ruleType = ruleTypeAll;
        return { rules: [allRule], setToAll: true };
      }
      // Because setToAll can only be enabled when there is a single
      // `All` rule, as long as the rule changes, the flag can be disabled
      if (setToAll && rule.ruleType.id !== 4) {
        disabledFlag = false;
      }
      const updatedRules = [...rules];
      updatedRules[index] = rule;
      return { rules: updatedRules, setToAll: disabledFlag };
    }, this.updateRuleSet);
    if (nextSelectorConfig && nextSelectorConfig.requirePrevious) {
      this.requestOptions({
        key: nextSelectorConfig.key,
        param: value.code,
        rule,
        index,
      });
    }
  };

  render() {
    const { classes, id, templateRequired } = this.props;
    const {
      setToAll,
      rules,
      rulesLoadingStatus,
      options,
      ruleIndexBeingEdited,
    } = this.state;
    const rulesWhenRequired = templateRequired ? [] : rules;
    const ruleList = rulesWhenRequired.map((rule, index) => (
      <ProjectTemplateAutoAssignmentRule
        key={`rule-${uniqueId()}`}
        rule={rule}
        index={index}
        options={options}
        updateRule={this.updateRule}
        deleteRule={this.deleteRule}
        setAll={this.setAllHandler}
        loadingStatus={rulesLoadingStatus[index]}
        onToggleEdit={() =>
          this.setState(({ ruleIndexBeingEdited }) => ({
            ruleIndexBeingEdited: index === ruleIndexBeingEdited ? null : index,
          }))
        }
        isBeingEdited={ruleIndexBeingEdited === index}
      />
    ));
    return (
      <div className={classes.rulesList}>
        <div className={classes.headingText}>
          <Typography variant="h6" gutterBottom>
            Auto Assignments
          </Typography>
          <Typography variant="body1">
            Offers that match the rules below will have this document enabled by
            default.
          </Typography>
        </div>
        <div className={classes.rulesRow}>{ruleList}</div>
        <div className={classes.buttonRow}>
          <Button
            className={classes.button}
            onClick={this.createRule}
            disabled={setToAll || templateRequired}
            variant="contained"
            color="primary"
            size="small"
            data-test-id={`RuleList-add-${id}`}
          >
            <AddIcon />
            Add New Rule
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ProjectTemplateAutoAssignmentRules);
