import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import OffersListTable from './OffersListTable';
import useOffersByStatus from './useOffersByStatus';
import Loader from 'common/components/Loader';

const styles = {
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
};

const OffersList = ({ classes }) => {
  const { data: offers, loading } = useOffersByStatus();

  if (loading || !offers) return <Loader className={classes.loader} />;

  return (
    <LayoutContent>
      <OffersListTable
        columns={[
          'project.name',
          'termsOfHire.occupation.description',
          'sendDate',
        ]}
        offers={offers.filter(
          ({ status }) => status === 'sent' || status.startsWith('onboarding'),
        )}
        type="sent"
      />

      <OffersListTable
        columns={[
          'project.name',
          'termsOfHire.occupation.description',
          'sendDate',
        ]}
        offers={offers.filter(({ status }) => status === 'submitted')}
        type="submitted"
      />

      <OffersListTable
        columns={[
          'project.name',
          'termsOfHire.occupation.description',
          'sendDate',
          'approvedAt',
        ]}
        offers={offers.filter(({ status }) => status === 'approved')}
        type="approved"
      />

      <OffersListTable
        columns={[
          'project.name',
          'termsOfHire.occupation.description',
          'sendDate',
          'rejectedAt',
          'rejectionComments',
        ]}
        offers={offers.filter(({ status }) => status === 'rejected')}
        type="employeeRejected"
      />

      <OffersListTable
        columns={[
          'project.name',
          'termsOfHire.occupation.description',
          'sendDate',
        ]}
        offers={offers.filter(({ status }) => status === 'opted-out')}
        type="optedOut"
      />
    </LayoutContent>
  );
};

export default withStyles(styles)(OffersList);
