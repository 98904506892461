import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import DocumentUploaderModal from 'common/components/DocumentUploader/DocumentUploaderModal';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import MyFilesModal from 'common/components/DocumentUploader/MyFiles';
import UploadSuccessModal from 'common/components/DocumentUploader/UploadSuccessModal';

const INSTRUCTIONS_WITH_MY_FILES =
  'Please upload PDF, JPG or PNG files only, other types of files will not be accepted. Drag & Drop files below, choose files from file selector, or choose files from your My Files storage.';
const INSTRUCTIONS_WITHOUT_MY_FILES =
  'Please upload PDF, JPG or PNG files only, other types of files will not be accepted. Drag & Drop files below or choose files from file selector.';

const styles = theme => ({
  primaryButton: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    color: theme.palette.primary.contrastText,
    height: 15,
    width: 15,
  },
  documentBtn: {
    background: '#30aba3',
    textTransform: 'none',

    '&:hover': {
      background: '#30aba3',
      opacity: '0.6',
    },
  },
  buttonTitle: {
    marginLeft: '5px',
    color: 'white',
    fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
  },
  addIcon: {
    color: 'white',
    fontSize: '16px',
  },
});

const DocumentUploadButton = props => {
  const initialState = {
    open: false,
    isSuccessModalOpen: false,
    isMyFilesOpen: false,
    files: [],
    recentlySelectedMyFiles: [],
  };
  const [state, setState] = useState(initialState);
  const {
    classes,
    uploadHandler,
    editHandler,
    deleteHandler,
    viewHandler,
    deleteAllHandler,
    uploadedSupportDocuments,
    isMyFiles,
  } = props;
  const instructions = isMyFiles
    ? INSTRUCTIONS_WITH_MY_FILES
    : INSTRUCTIONS_WITHOUT_MY_FILES;

  const handleDocumentUploaderModal = (options = null) => {
    if (options === 'start') {
      clearFileState();
    }
    setState(state => ({ ...state, open: !state.open }));
  };

  const handleMyFilesClose = (recentlySelectedMyFiles = []) => {
    setState(state => ({
      ...state,
      recentlySelectedMyFiles,
    }));
    handleMyFilesModal();
    handleDocumentUploaderModal();
  };

  const handleSuccessModal = (files = []) => {
    setState(state => ({
      ...state,
      isSuccessModalOpen: !state.isSuccessModalOpen,
      files,
    }));
  };

  const handleMyFilesModal = () => {
    setState(state => ({
      ...state,
      isMyFilesOpen: !state.isMyFilesOpen,
    }));
  };

  const clearFileState = () => {
    setState(state => ({
      ...state,
      recentlySelectedMyFiles: [],
      files: [],
    }));
  };

  const {
    open,
    isMyFilesOpen,
    isSuccessModalOpen,
    files,
    recentlySelectedMyFiles,
  } = state;

  return (
    <div>
      <Button
        className={classes.documentBtn}
        onClick={() => handleDocumentUploaderModal('start')}
        data-test-id="DocumentUploadButton-AddDocument"
      >
        <AddCircleIcon className={classes.addIcon} />
        <span className={classes.buttonTitle}>Add Document</span>
      </Button>
      {open && (
        <DocumentUploaderModal
          open={open}
          uploadHandler={uploadHandler}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          deleteAllHandler={deleteAllHandler}
          viewHandler={viewHandler}
          closeModalHandler={handleDocumentUploaderModal}
          uploadedSupportDocuments={uploadedSupportDocuments}
          myFilesModalHandler={handleMyFilesModal}
          successModalHandler={handleSuccessModal}
          recentlySelectedMyFiles={recentlySelectedMyFiles}
          isMyFiles={isMyFiles}
          title="Upload Supporting Documents"
          instructions={instructions}
          fileTypes="image/jpeg, image/png, application/pdf"
        />
      )}
      {isMyFilesOpen && (
        <MyFilesModal
          isOpen={isMyFilesOpen}
          handleClose={handleMyFilesClose}
          uploadHandler={uploadHandler}
        />
      )}
      {isSuccessModalOpen && (
        <UploadSuccessModal
          isOpen={isSuccessModalOpen}
          handleClose={handleSuccessModal}
          files={files}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(DocumentUploadButton);
