import React from 'react';
import _ from 'lodash';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from '@material-ui/core';
import ajax from 'common/utilities/ajax.js';
import Preloader from 'common/oldJavascripts/components/Shared/Preloader';
import HistoryItem from './OfferFullHistoryItem';

const ERRMSG = 'No Old Versions Available';
class OfferFullHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      histories: null,
    };
  }

  componentDidMount() {
    const { offer } = this.props;
    let ordered = [];
    // [/v2/offers/${offer.id}/old-versions] returns offer histories
    try {
      ajax
        .get(`/v2/offers/${offer.id}/old-versions`)
        .then(res => {
          ordered = _.orderBy(res.items, ['version'], ['desc']);
        })
        .finally(() => {
          // Unshift current offer to the front of [histories]
          // as the most recent history
          if (_.get(offer, 'status') !== 'draft') {
            ordered.unshift(offer);
          }

          if (_.isEmpty(ordered)) {
            this.setState({ error: ERRMSG });
          } else {
            this.setState({ histories: ordered });
          }
        });
    } catch (e) {
      this.setState({ error: ERRMSG });
    }
  }

  render() {
    const { error, histories } = this.state;
    const { offer } = this.props;

    if (error) {
      return (
        <Table data-test-id={`OfferHistory-table-${offer.id}`}>
          <TableRow>
            <TableCell>
              <Typography>{ERRMSG}</Typography>
            </TableCell>
          </TableRow>
        </Table>
      );
    }

    if (!histories) {
      return (
        <Table data-test-id={`OfferHistory-table-${offer.id}`}>
          <TableRow>
            <TableCell>
              <Preloader />
            </TableCell>
          </TableRow>
        </Table>
      );
    }

    return (
      <Table data-test-id={`OfferHistory-table-${offer.id}`}>
        <TableBody>
          {histories &&
            histories.map(history => {
              const keyid = `${offer.id}-${history.version}`;
              return (
                <TableRow
                  key={`OfferHistory-${keyid}`}
                  data-test-id={`OfferHistory-row-${keyid}`}
                >
                  <TableCell colSpan={12}>
                    <HistoryItem
                      key={`OfferHistoryItem-${keyid}`}
                      history={history}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  }
}

export default OfferFullHistory;
