import React, { useState } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import history from 'common/constants/config/history';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import withApi from 'common/hoc/withApi';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser.js';
import withOnboardingStatus from 'onboarding/hoc/withOnboardingStatus';
import SupportingDocumentsListContainer from './SupportingDocumentsListContainer';

const styles = theme => ({
  container: {
    paddingTop: '30px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  red: {
    color: 'red',
  },
  nextButton: {
    float: 'right',
    marginTop: 34,
    width: '100%',
  },
  description: {
    marginBottom: 34,
  },
});

const SupportingDocuments = ({
  classes,
  routerParams: { offerId },
  offer: offerMutator,
  pushSnackbarNotification,
  nextOnboardingStep,
}) => {
  const [isNextEnabled, setIsNextEnabled] = useState(false);

  const handleUpdateError = err =>
    pushSnackbarNotification({
      message: parseErrorMessages(err),
      variant: SnackbarVariants.ERROR,
    });

  const continueToNextStep = () => {
    const { status, url } = nextOnboardingStep;

    offerMutator.updateAndSave(
      { status },
      'status',
      {},
      () => history.push(url),
      handleUpdateError,
    );
  };

  return (
    <div className={classes.container}>
      <Typography
        variant="h4"
        data-test-id="SupportingDocuments-title"
        gutterBottom
      >
        Supporting Document Request
      </Typography>
      <Typography
        variant="body1"
        data-test-id="SupportingDocuments-description"
        className={classes.description}
      >
        You have been requested to provide supporting documentation for your
        allowance(s) (e.g. Box Rental Inventory, Proof of Insurance, etc).
        <br />
        <br />
        If required (red asterisk <span className={classes.red}>*</span>) you
        must upload in order to proceed.
        <br />
        <br />
        For box rental, if you do not load box rental inventory, you may be
        required to bring a physical copy to Production Accounting.
        <br />
        <br />
        Please ensure you do not upload health-related documents and be sure to
        redact any unnecessary information (e.g., financial information) from
        any documents you upload.
      </Typography>
      <SupportingDocumentsListContainer
        onChangeIsNextEnabled={setIsNextEnabled}
      />
      <Button
        disabled={!isNextEnabled}
        onClick={ev => {
          ev.preventDefault();
          continueToNextStep();
        }}
        className={classes.nextButton}
        data-test-id="SupportingDocuments-next"
      >
        Next
      </Button>
    </div>
  );
};

SupportingDocuments.mutators = {
  offer: {
    info: function(_, related) {
      var params = related['/router/params'];
      return {
        id: '/v2/offers/' + params.offerId,
      };
    },
  },
};

export default compose(
  withSnackbarNotification,
  withStyles(styles),
  withOnboardingStatus,
  withApi,
)(SupportingDocuments);
