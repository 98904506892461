import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import Checkbox from 'common/components/Checkbox';

import LABELS from './labels.json';

const styles = {
  hint: {
    position: 'absolute',
    marginTop: 0,
    opacity: 0.5,
    fontSize: 11,
  },
};

const Item = props => {
  const { classes, name, onChange, checked, hint = null } = props;

  const label = LABELS[name] || name;

  return (
    <FormControlLabel
      label={
        <FormLabel>
          {label}
          {hint && (
            <FormHelperText className={classes.hint}>{hint}</FormHelperText>
          )}
        </FormLabel>
      }
      control={
        <Checkbox
          data-test-id={`ProjectClone-Selection-${name}`}
          name={name}
          onChange={({ target: { name, checked } = {} }) => {
            onChange(name, checked);
          }}
          checked={checked}
        />
      }
    />
  );
};

export default withStyles(styles)(Item);
