import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// MaterialUI Components
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InfoIcon from '@material-ui/icons/Info';

// Custom Components
import FieldTypeSelect from './FieldTypeSelect';
import RadioItem from 'admin/components/RoleMapper/FieldDrawer/RadioItem';

// Utilities
import classNames from 'classnames';
import * as FieldTypes from 'common/utilities/constants/fieldTypes';
import sortByCoords from 'common/utilities/sortByCoords';

const styles = theme => ({
  drawerInput: {
    width: '80%',
  },
  fieldDetails: {
    padding: theme.spacing.unit * 2,
    display: 'grid',
    gap: `${theme.spacing.unit * 2.5}px`,
    gridGap: `${theme.spacing.unit * 2.5}px`,
  },
  drawerText: {
    fontWeight: 600,
    display: 'flex',
  },
  warningText: {
    marginTop: '10px',
  },
  icon: {
    height: 20,
    width: 20,
    marginLeft: 5,
  },
  tooltip: {
    fontSize: '1rem !important',
  },
});

const FieldDetails = props => {
  const {
    classes = {},
    fieldValues = {},
    triggerRules = [],
    conditionalRules = [],
    fieldTypeSelectProps,
    updateField,
  } = props;
  const {
    displayName = '',
    defaultValue = '',
    isRequired,
    isDisabled = false,
    systemField = {},
    customProperties,
    fieldType,
    id,
  } = fieldValues || {};
  const { collectSystemField = false } = customProperties || {};
  // If the field is marked as "collect system field" the BE will
  // automatically print whatever value is collected for the system field to the form
  // Otherwise, the user can configure whether or not to require the system field
  const printToForm = systemField.name && !collectSystemField;
  const assignedConditionalRule = conditionalRules.find(
    ({ fieldGroups = [] }) =>
      fieldGroups.some(({ fieldIds = [] }) => fieldIds.includes(id)),
  );
  const isConditionalAssignedToTrigger =
    assignedConditionalRule &&
    triggerRules.some(({ conditionalRuleIds = [] }) =>
      conditionalRuleIds.includes(assignedConditionalRule.id),
    );
  const isAssignedToTriggerRule =
    triggerRules.some(({ fieldGroups = [] }) =>
      fieldGroups.some(({ fieldIds = [] }) => fieldIds.includes(id)),
    ) || isConditionalAssignedToTrigger;
  const isAssignedToRule = !!(
    isAssignedToTriggerRule || assignedConditionalRule
  );
  const getDefaultValueInput = (() => {
    switch (fieldType) {
      case FieldTypes.RDO:
        const { radioGroupItems = [] } = fieldValues || {};
        const radioMenuItems = sortByCoords(radioGroupItems).map(
          (radioButton, radioButtonIndex) => (
            <MenuItem
              value={isAssignedToRule ? radioButton.name : null}
              data-test-id={`FieldDetails-defaultValueMenuItem-${radioButton.name}`}
              key={radioButton.name}
            >
              <RadioItem
                name={radioButton.radioValue}
                index={radioButtonIndex}
              />
            </MenuItem>
          ),
        );
        return (
          <Select
            value={isAssignedToRule ? defaultValue || '' : ''}
            className={classes.drawerInput}
            onChange={e => updateField(e.target.value, 'defaultValue')}
            data-test-id="FieldDetails-defaultValueRadioSelect"
            disabled={!isAssignedToRule}
            inputProps={{
              'data-test-id': 'FieldDetails-defaultValueInput',
            }}
          >
            {radioMenuItems}
          </Select>
        );
      case FieldTypes.CMB:
        const { options = [] } = fieldValues || {};
        const dropdownMenuItems = options.map(option => (
          <MenuItem
            value={isAssignedToRule ? option : null}
            data-test-id={`FieldDetails-defaultValueMenuItem-${option}`}
            key={option}
          >
            {option}
          </MenuItem>
        ));
        return (
          <Select
            value={isAssignedToRule ? defaultValue : null}
            className={classes.drawerInput}
            onChange={e => updateField(e.target.value, 'defaultValue')}
            data-test-id="FieldDetails-defaultValueDropdownSelect"
            disabled={!isAssignedToRule}
            inputProps={{
              'data-test-id': 'FieldDetails-defaultValueInput',
            }}
          >
            {dropdownMenuItems}
          </Select>
        );
      case FieldTypes.CHK:
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={isAssignedToRule ? defaultValue : null}
                onChange={e => updateField(!defaultValue, 'defaultValue')}
                data-test-id="FieldDetails-defaultValueCheckBox"
                inputProps={{
                  'data-test-id': 'FieldDetails-defaultValueInput',
                }}
                disabled={!isAssignedToRule}
              />
            }
            label="Checked"
          />
        );
      default:
        return (
          <TextField
            value={isAssignedToRule ? defaultValue || '' : ''}
            className={classes.drawerInput}
            onChange={e => updateField(e.target.value, 'defaultValue')}
            disabled={!isAssignedToRule}
            inputProps={{
              'data-test-id': 'FieldDetails-defaultValueInput',
            }}
          />
        );
    }
  })();
  const getDefaultValueTooltipText = (() => {
    if (isAssignedToRule)
      return 'This field is mapped to a trigger rule or a required rule and can have a default value set.';
    return 'This field is not mapped to a trigger rule or a required rule and cannot have a default value set.';
  })();

  return (
    <div className={classes.fieldDetails}>
      <div>
        <Typography variant="subtitle1" className={classes.drawerText}>
          Display Name:
        </Typography>
        <TextField
          value={displayName}
          className={classes.drawerInput}
          onChange={e => updateField(e.target.value, 'displayName')}
          helperText={
            !(displayName || '').length ? 'Display name required.' : null
          }
          error={!(displayName || '').length}
          inputProps={{
            'data-test-id': `FieldDrawer-displayNameInput`,
          }}
        />
      </div>
      <FieldTypeSelect
        {...fieldTypeSelectProps}
        fieldValues={fieldValues}
        updateField={updateField}
      />
      <div>
        <Typography variant="subtitle1" className={classes.drawerText}>
          Default Value
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={getDefaultValueTooltipText}
          >
            <InfoIcon
              className={classes.icon}
              data-test-id="FieldDetails-defaultValueInfo"
            />
          </Tooltip>
          :
        </Typography>
        {getDefaultValueInput}
      </div>
      <div>
        <Typography variant="subtitle1" className={classes.drawerText}>
          Disabled by Default
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              isAssignedToTriggerRule
                ? 'This field is mapped to a trigger rule or a required rule that is linked to a trigger rule and can be disabled by default.'
                : 'This field is not mapped to a trigger rule or a required rule that is linked to a trigger rule and cannot be disabled by default.'
            }
          >
            <InfoIcon className={classes.icon} />
          </Tooltip>
          :
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAssignedToTriggerRule ? isDisabled : null}
              onChange={e => updateField(!isDisabled, 'isDisabled')}
              data-test-id="FieldDrawer-isDisabledCheckbox"
              disabled={!isAssignedToTriggerRule}
              inputProps={{
                'data-test-id': 'FieldDrawer-isDisabledCheckboxInput',
              }}
            />
          }
          label="Disabled"
        />
      </div>
      <div>
        <Typography variant="subtitle1" className={classes.drawerText}>
          Field is Required
          {isAssignedToRule && (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={
                'This field is mapped to a rule and cannot be set as required.'
              }
            >
              <InfoIcon className={classes.icon} />
            </Tooltip>
          )}
          :
        </Typography>
        {printToForm ? (
          <Typography
            className={classNames(classes.drawerText, classes.warningText)}
            variant="subtitle1"
            data-test-id="FieldDrawer-requiredWarning"
          >
            This field will always print to the form (empty value prints as
            blank).
          </Typography>
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                checked={isRequired}
                onChange={e => updateField(!isRequired, 'isRequired')}
                data-test-id="FieldDrawer-isRequiredCheckbox"
                inputProps={{
                  'data-test-id': 'FieldDrawer-isRequiredCheckboxInput',
                }}
                disabled={isAssignedToRule}
              />
            }
            label="Required"
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(FieldDetails);
