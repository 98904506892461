import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Cell from './Table/Cell';
import CellTitle from './Table/CellTitle';
import Header from './Table/Header';
import EditableCell from './Table/EditableCell';
import Row from './Table/Row';

const styles = {
  root: {
    background: '#ffffff',
    paddingBottom: '20px',
    width: '100%',
  },
  fixed: { tableLayout: 'fixed' },
  framed: { paddingBottom: '0', marginBottom: '20px' },
  mini: { marginBottom: '0' },
  overflowed: { overflow: 'visible' },
  noPadding: { padding: '0px !important' },
  tbody: {
    background: '#ffffff',
    borderCollapse: 'collapse',
    minWidth: '100%',
  },
  noWrap: { whiteSpace: 'noWrap' },
  nestedTable: {
    tableLayout: 'unset',
  },
};

const Table = props => {
  const {
    classes,
    className,
    framed,
    mini,
    fixed = true,
    overflowed,
    noPadding,
    children,
    nestedTable,
    'data-test-id': dataTestId,
  } = props;
  const tableClass = classNames(classes.root, className, {
    [classes.fixed]: fixed,
    [classes.framed]: framed,
    [classes.mini]: mini,
    [classes.overflowed]: overflowed,
    [classes.noPadding]: noPadding,
    [classes.nestedTable]: nestedTable,
  });
  const bodyClass = classNames(classes.tbody);
  return (
    <table className={tableClass} data-test-id={dataTestId}>
      <tbody className={bodyClass}>{children}</tbody>
    </table>
  );
};

//
// return domClasses.set({
//   'table-body': true,
//   'table-body--no-wrap': this.props.noWrap,
//   'table-body--grid': this.props.grid,
//   'table-body--mini': this.props.mini,
//   'table-body--compact': this.props.compact,
//   'table-body--secondary': this.props.secondary,
// });

Table.Cell = Cell;
Table.CellTitle = CellTitle;
Table.Header = Header;
Table.EditableCell = EditableCell;
Table.Row = Row;

export { Cell, Row, Header, EditableCell, CellTitle };

export default withStyles(styles)(Table);
