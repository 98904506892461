import React from 'react';

// Components
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const AccountCodeTableHead = props => {
  const { accountCodeConfigurations = [], classes = {} } = props || {};

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.headerCell} />
        {accountCodeConfigurations.map(({ label, required } = {}, index) => (
          <TableCell
            align="center"
            className={classes.headerCell}
            key={`${label}-${index}`}
          >
            <div className={classes.headerCellContainer}>
              {label}
              {required && (
                <Typography className={classes.requiredFlag} color="error">
                  *
                </Typography>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AccountCodeTableHead;
