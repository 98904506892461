import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem, Divider } from '@material-ui/core';
import { ListSubheader } from '@material-ui/core';
import {
  AddBox as AddBoxIcon,
  People as RolesIcon,
  List as RulesIcon,
  Keyboard as InputsIcon,
  SubdirectoryArrowRight as ShareIcon,
} from '@material-ui/icons';
import ToolbarButton from './ToolbarButton';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 2,
    background: '#f9f9f9',
    height: 60,
    borderRight: `1px solid #d7d7d7`,
    borderBottom: `1px solid #d7d7d7`,
  },
  text: {
    color: theme.palette.bottomDialogBackground,
    borderColor: theme.palette.primary.contrastText,
  },
  menuHeader: {
    outline: 'none',
    fontSize: '1rem',
    color: palette.black,
  },
  toolbarActions: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  shareIcon: {
    transform: 'rotate(-90deg)',
  },
});

const GroupToolbar = props => {
  const {
    classes,
    addNewRole,
    handleTabChange,
    tabValue,
    openMenu,
    menuAnchor,
    closeMenu,
    createConditionalRule,
    createTriggerRule,
    activeRuleGroupId,
    activeRoleId,
    createNewInput,
    createNewEmailNotification,
    isEditingRoleName,
    createNewWorkflowRule,
  } = props;
  const isDisabled = !!(activeRoleId || activeRuleGroupId);
  const iconAction = tabValue === 0 ? addNewRole : openMenu;

  const menuProps = (() => {
    switch (tabValue) {
      case 2:
        return {
          title: 'Create New Input',
          menuItems: [{ title: 'New Input', action: createNewInput }],
        };
      case 3:
        return {
          title: 'Create New Notification',
          menuItems: [
            {
              title: 'New Email Notification',
              action: createNewEmailNotification,
            },
          ],
        };
      default:
        return {
          title: 'Create New Rule',
          menuItems: [
            { title: 'Required Rule', action: createConditionalRule },
            { title: 'Trigger Rule', action: createTriggerRule },
            {
              title: 'Conditional Workflow Rule',
              action: createNewWorkflowRule,
            },
          ],
        };
    }
  })();

  const menu = (
    <Menu
      id="rule-menu"
      anchorEl={menuAnchor}
      open={!!menuAnchor}
      onClose={closeMenu}
    >
      <ListSubheader className={classes.menuHeader}>
        {menuProps.title}
      </ListSubheader>
      <Divider />
      {menuProps.menuItems.map(item => {
        if (item.title === 'Conditional Workflow Rule') {
          return (
            <MenuItem
              key={item.action}
              onClick={item.action}
              data-test-id={`GroupToolbar-ruleMenuItem-${item.title}`}
            >
              {item.title}
            </MenuItem>
          );
        }
        return (
          <MenuItem
            key={item.action}
            onClick={item.action}
            data-test-id={`GroupToolbar-ruleMenuItem-${item.title}`}
          >
            {item.title}
          </MenuItem>
        );
      })}
    </Menu>
  );

  const renderActionButton = (buttonProps, index) => (
    <ToolbarButton
      {...buttonProps}
      disabled={isDisabled}
      activeTab={tabValue}
      onClick={handleTabChange}
      index={index}
      key={buttonProps.title}
      isEditingRoleName={isEditingRoleName}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.toolbarActions}>
        {renderActionButton(
          {
            title: 'Roles',
            dataTestId: 'GroupToolbar-rolesPermissionsTab',
            icon: <RolesIcon />,
          },
          0,
        )}
        {renderActionButton(
          {
            title: 'Rules',
            dataTestId: 'GroupToolbar-rulesTab',
            icon: <RulesIcon />,
          },
          1,
        )}
        {renderActionButton(
          {
            title: 'Inputs',
            dataTestId: 'GroupToolbar-inputsTab',
            icon: <InputsIcon />,
          },
          2,
        )}
        {renderActionButton(
          {
            title: 'Share',
            dataTestId: 'GroupToolbar-shareTab',
            icon: <ShareIcon className={classes.shareIcon} />,
          },
          3,
        )}
      </div>
      <IconButton
        size="small"
        onClick={iconAction}
        data-test-id="GroupToolbar-addButton"
      >
        <AddBoxIcon className={classes.text} />
      </IconButton>
      {menu}
    </div>
  );
};

export default withStyles(styles)(GroupToolbar);
