import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const ratePerHourMask = createNumberMask({
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalLimit: 9,
  integerLimit: 7,
  prefix: '',
});

const guaranteedHoursMask = createNumberMask({
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 2,
  prefix: '',
});

const daysMask = createNumberMask({
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 1,
  prefix: '',
});

const allowancesAmountMask = createNumberMask({
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalLimit: 4,
  integerLimit: 7,
  prefix: '',
});

const allowancesDurationMask = createNumberMask({
  includeThousandsSeparator: false,
  allowDecimal: false,
  integerLimit: 3,
  prefix: '',
});

export {
  ratePerHourMask,
  guaranteedHoursMask,
  daysMask,
  allowancesAmountMask,
  allowancesDurationMask,
};
