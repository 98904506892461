import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// MaterialUI Components
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
  Check as CheckIcon,
  Close as CrossIcon,
  ErrorOutline as ErrorIcon,
} from '@material-ui/icons';

import * as palette from '../../shared/oldDocumentSignerUI/palette';
import isFilledIn from '../../utilities/isFilledIn';

const styles = theme => ({
  primaryText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 14,
  },
  secondaryText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 11,
  },

  complete: {
    marginRight: 0,
    color: palette.success2,
  },
  incomplete: {
    marginRight: 0,
    color: palette.grey,
  },
  error: {
    marginRight: 0,
    color: theme.palette.error.main,
  },
});

const DocumentSelectorField = ({ field = {}, goToField, classes }) => {
  const {
    id,
    page,
    value,
    name,
    displayName,
    _derived: { fieldType, error },
  } = field;
  const isAck = fieldType === 'ACK';
  const fieldName = isAck ? 'Acknowledgment' : displayName || name;
  const isValueFilledIn = isFilledIn(value);

  const statusText = (() => {
    if (error) return error.message || 'Error';
    if (isValueFilledIn) return isAck ? 'Confirmed' : 'Validation Satisfied';
    return 'Incomplete';
  })();
  const caption = (page ? `Page ${page} - ` : '') + statusText;

  const icon = (() => {
    if (error) return <ErrorIcon className={classes.error} />;
    if (isValueFilledIn) return <CheckIcon className={classes.complete} />;
    return <CrossIcon className={classes.incomplete} />;
  })();

  return (
    <ListItem
      button
      onClick={goToField}
      data-test-id={`DocumentSelectorField-root-${id}`}
    >
      <ListItemIcon
        data-test-id={`DocumentSelectorField-fieldStatusIcon-${id}`}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={fieldName}
        secondary={caption}
        classes={{
          primary: classes.primaryText,
          secondary: classes.secondaryText,
        }}
        data-test-id={`DocumentSelectorField-fieldText-${id}`}
      />
    </ListItem>
  );
};

export default withStyles(styles)(DocumentSelectorField);
