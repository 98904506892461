import React, { useState } from 'react';
import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactSelect from 'react-select';
import useReplaceUser from 'admin/oldJavascripts/components/Pages/Project/hooks/useReplaceUser';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { pushNotification as pushSnackbarNotification } from 'common/store/actions/snackbarNotifications';
import Loader from 'common/components/Loader';
import sortBy from 'lodash.sortby';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: '400px',
    minHeight: '400px',
  },
  submitBtn: {
    backgroundColor: '#2eaba4',
    color: '#FFF',
    '&:focus': {
      background: '#2eaba4 !important',
    },
    '&:hover': {
      background: '#2eaba4 !important',
    },
  },
  cancelBtn: {
    backgroundColor: 'transparent !important',
    marginRight: '15px',
    color: '#2eaba4',
    border: '1px solid #2eaba4',
  },
  center: {
    textAlign: 'center',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
}));

const RemoveUser = props => {
  const classes = useStyles();
  const {
    nonEmployeesQuery: { data: { items: nonEmployees = [] } = {} } = {},
    handleCloseModal,
    deletedNonEmployee: {
      project_user_id: deletedProjectUserId,
      privileges: deletedEmployeePriviledges = [],
    },
    projectId,
    invalidateByPatterns,
  } = props;

  const [selectedValue, setSelectedValue] = useState(null);
  const [replaceOldUser, { loading, error }] = useReplaceUser();
  const { message = '' } = error || {};
  const cleanedMesage = message.replace('GraphQL error:', '');
  const errorMessage =
    cleanedMesage ||
    'Sorry, an error occurred. Please try again or contact support.';

  const handleSubmit = async data => {
    await replaceOldUser(data);
    if (!loading && !error) {
      pushSnackbarNotification({
        message: 'User successfully replaced.',
        variant: SnackbarVariants.SUCCESS,
      });
      invalidateByPatterns([
        `/projects/${data.projectId}/users`,
        `/projects/${data.projectId}/non-employees`,
      ]);
      handleCloseModal();
      window.location.reload();
    }
  };

  const handleChange = selectedOption => {
    setSelectedValue(selectedOption);
  };
  const nonEmployeesWithSimilarPermissions = nonEmployees.filter(
    obj =>
      obj.project_user_id !== deletedProjectUserId &&
      deletedEmployeePriviledges.every(val => obj.privileges.includes(val)),
  );

  const nonEmployeesOptions = sortBy(nonEmployeesWithSimilarPermissions, o =>
    o.first_name.toLowerCase(),
  ).map(p => ({
    value: p.first_name,
    label: `${p.first_name}  ${p.last_name}`,
    projectUserId: p.project_user_id,
  }));
  const { projectUserId = null } = selectedValue || {};

  return (
    <Dialog
      open={true}
      data-test-id="User-NonEmployee-delete-dialog"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        Please select the replacement non-production employee
      </DialogTitle>
      <DialogContent>
        <ReactSelect
          value={selectedValue}
          onChange={handleChange}
          options={nonEmployeesOptions}
          placeholder="Search"
          isSearchable
          isClearable
          maxMenuHeight={150}
        />
        {loading && (
          <div className={classes.center}>
            <Loader size={36} />
          </div>
        )}
        {error && (
          <Typography className={classes.error}>{errorMessage}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelBtn}
          onClick={handleCloseModal}
          data-test-id="RemoveUser-NonEmployee-cancelBtn"
        >
          Cancel
        </Button>
        <Button
          className={classes.submitBtn}
          disabled={loading || !projectUserId}
          data-test-id="RemoveUser-NonEmployee-saveChangesBtn"
          onClick={() =>
            handleSubmit({
              projectId: parseInt(projectId),
              replacementUserId: projectUserId,
              deletedUserId: parseInt(deletedProjectUserId),
            })
          }
        >
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveUser;
