import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import config from 'common/oldJavascripts/config.js';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import Header from 'common/oldJavascripts/components/Base/Header';
import Table from 'common/oldJavascripts/components/Base/Table';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Pagination from 'common/oldJavascripts/components/Base/Pagination';
import Link from 'common/oldJavascripts/components/Base/Link';
import Field from 'common/oldJavascripts/components/Base/Field';
import Loader from 'common/components/Loader';
import Search from 'common/oldJavascripts/components/Shared/Search';
import collection from 'common/oldJavascripts/utils/collection.js';
import format from 'common/oldJavascripts/utils/format.js';
import UserPrivileges from 'common/oldJavascripts/utils/UserPrivileges.js';
import ajax from 'common/utilities/ajax';
import Sentry from '@sentry/browser';
import RemoveUser from 'admin/oldJavascripts/components/Pages/Project/RemoveUser';
import UsaOnly from 'common/components/UsaOnly';

// Actions
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import { IconButton } from '@material-ui/core';

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
} from '@material-ui/icons';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

import * as Mutations from 'common/apollo/mutations';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

const { Row, Cell, CellTitle } = Table;

const styles = {
  phoneIcon: {
    width: 15,
    height: 15,
  },
  phoneContainer: {
    display: 'flex',
  },
  row: {
    height: 68,
  },
  iconButton: {
    padding: 5,
  },
  icon: {
    width: '0.8em',
    height: '0.8em',
  },
  submitBtn: {
    backgroundColor: '#2eaba4',
    color: '#FFF',
  },
  cancelBtn: {
    backgroundColor: 'transparent !important',
    marginRight: '15px',
    color: '#2eaba4',
    border: '1px solid #2eaba4',
  },
};

const UserRowClass = Relay.createClass({
  statics: {
    mutators: {
      user: {
        info(params, related) {
          const routerParams = related['/router/params'];

          return {
            id: `/projects/${routerParams.projectId}/users/${params.id}`,
          };
        },
      },
    },
  },

  render() {
    const {
      routerParams: params,
      user: userMutator = {},
      invitations: invitationsQuery = {},
      classes,
    } = this.props;

    const { data: user = {} } = userMutator || {};
    const { data: invitationsData = {} } = invitationsQuery || {};
    const { items: invitations = [] } = invitationsData || {};
    const disablePrivileges = user.role !== 'non_employee';
    const privileges = new UserPrivileges(user.privileges);
    const editParams = {
      projectId: params.projectId,
      userId: user.id,
    };
    const isResend = invitations.some(
      inv => inv.project_user_id === user.project_user_id,
    );

    if (userMutator.status === 'loading') {
      return (
        <Row>
          <Loader />
        </Row>
      );
    }

    return (
      <Row key={user.id} classes={{ root: classes.row }}>
        {this._renderEmployeeCell(user)}
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Users-email-${user.id}`}>{user.email}</span>
        </Cell>
        <Cell
          align="center"
          noPadding={true}
          doubleBorderBottom={true}
          data-test-id={`Users-hirer-${user.id}`}
        >
          <Field
            label="Hiring Manager"
            type="checkbox"
            disableFieldClass={true}
            smallCheckbox={true}
            marginLeft={true}
            hideLabel={true}
            value={privileges.isHirer()}
            onChange={this._changePrivilege('can_create_offers')}
            disabled={disablePrivileges}
          />
        </Cell>
        <Cell
          align="center"
          noPadding={true}
          doubleBorderBottom={true}
          data-test-id={`Users-reviewer-${user.id}`}
        >
          <Field
            label="Reviewer"
            type="checkbox"
            disableFieldClass={true}
            smallCheckbox={true}
            marginLeft={true}
            hideLabel={true}
            value={privileges.isReviewer()}
            onChange={this._changePrivilege('can_be_reviewer')}
            disabled={disablePrivileges}
          />
        </Cell>
        <Cell
          align="center"
          noPadding={true}
          doubleBorderBottom={true}
          data-test-id={`Users-approver-${user.id}`}
        >
          <Field
            label="Approver"
            type="checkbox"
            disableFieldClass={true}
            smallCheckbox={true}
            marginLeft={true}
            hideLabel={true}
            value={privileges.isApprover()}
            onChange={this._changePrivilege('can_approve_offers')}
            disabled={disablePrivileges}
          />
        </Cell>
        <UsaOnly>
          <Cell
            align="center"
            noPadding={true}
            doubleBorderBottom={true}
            data-test-id={`Users-verifier-${user.id}`}
          >
            <Field
              label="I-9 Verifier"
              type="checkbox"
              disableFieldClass={true}
              smallCheckbox={true}
              marginLeft={true}
              hideLabel={true}
              value={privileges.isVerifier()}
              onChange={this._changePrivilege('can_be_i9_verifier')}
              disabled={disablePrivileges}
            />
          </Cell>
        </UsaOnly>
        <Cell
          align="center"
          noPadding={true}
          doubleBorderBottom={true}
          data-test-id={`Users-archives-${user.id}`}
        >
          <Field
            label="Archives"
            type="checkbox"
            disableFieldClass={true}
            smallCheckbox={true}
            marginLeft={true}
            hideLabel={true}
            value={privileges.isArchiver()}
            onChange={this._changePrivilege('can_access_archives')}
            disabled={disablePrivileges}
          />
        </Cell>
        <Cell
          align="center"
          noPadding={true}
          doubleBorderBottom={true}
          data-test-id={`Users-viewer-${user.id}`}
        >
          <Field
            label="Document Viewer"
            type="checkbox"
            disableFieldClass={true}
            smallCheckbox={true}
            marginLeft={true}
            hideLabel={true}
            value={privileges.isDocumentsViewer()}
            onChange={this._changePrivilege('can_view_documents')}
            disabled={disablePrivileges}
          />
        </Cell>
        <Cell
          align="center"
          noPadding={true}
          doubleBorderBottom={true}
          data-test-id={`Users-viewAccountCodes-${user.id}`}
        >
          <Field
            label="View Account Codes"
            type="checkbox"
            disableFieldClass={true}
            smallCheckbox={true}
            marginLeft={true}
            hideLabel={true}
            value={privileges.canViewAccountCodes()}
            onChange={this._changePrivilege('can_view_offer_account_codes')}
            disabled={disablePrivileges}
          />
        </Cell>
        <Cell
          align="center"
          noPadding={true}
          doubleBorderBottom={true}
          data-test-id={`Users-editAccountCodes-${user.id}`}
        >
          <Field
            label="Edit Account Codes"
            type="checkbox"
            disableFieldClass={true}
            smallCheckbox={true}
            marginLeft={true}
            hideLabel={true}
            value={privileges.canEditAccountCodes()}
            onChange={this._changePrivilege('can_edit_offer_account_codes')}
            disabled={disablePrivileges}
          />
        </Cell>
        <Cell doubleBorderBottom={true}>
          <span data-test-id={`Users-role-${user.id}`}>
            {this._userRoleText(user.role)}
          </span>
        </Cell>
        <Cell doubleBorderBottom={true}>
          <Link
            to={`/projects/${editParams.projectId}/users/${editParams.userId}/edit`}
            icon={true}
            data-test-id={`Users-edit-${user.id}`}
          >
            <IconButton
              aria-label="Edit"
              classes={{ root: classes.iconButton }}
            >
              <EditIcon fontSize="small" classes={{ root: classes.icon }} />
            </IconButton>
          </Link>
          <Link icon data-test-id={`Users-delete-${user.id}`}>
            <IconButton
              aria-label="Delete"
              classes={{ root: classes.iconButton }}
              onClick={this._delete}
            >
              <DeleteIcon fontSize="small" classes={{ root: classes.icon }} />
            </IconButton>
          </Link>
          {isResend ? (
            <Link icon data-test-id={`Users-resend-${user.id}`}>
              <IconButton
                aria-label="Resend"
                classes={{ root: classes.iconButton }}
                onClick={this._resend}
              >
                <EmailIcon fontSize="small" classes={{ root: classes.icon }} />
              </IconButton>
            </Link>
          ) : (
            ''
          )}
        </Cell>
      </Row>
    );
  },

  _renderEmployeeCell(user) {
    const { classes } = this.props;
    const fullName = format.fullName(
      user.first_name,
      user.middle_name,
      user.last_name,
    );

    if (user.phone) {
      return (
        <Cell doubleBorderBottom={true}>
          <CellTitle>
            <span data-test-id={`Users-name-${user.id}`}>{fullName}</span>
          </CellTitle>
          <div className={classes.phoneContainer}>
            <PhoneIcon className={classes.phoneIcon} />{' '}
            <span data-test-id={`Users-phone-${user.id}`}>{user.phone}</span>
          </div>
        </Cell>
      );
    } else {
      return (
        <Cell rowHeader={true} doubleBorderBottom={true}>
          <span data-test-id={`Users-name-${user.id}`}>{fullName}</span>
        </Cell>
      );
    }
  },

  _userRoleText(role) {
    return role === 'employee' ? 'Employee' : 'Non-employee';
  },

  _delete() {
    const {
      user: { data: user = {} } = {},
      routerParams,
      usersQuery,
      deleteCrewMember,
      pushSnackbarNotification,
      removeUserModalHandler,
    } = this.props;
    let message = null;
    let label = null;
    let department_names = null;
    const { departments = [], role = '', id: profileId } = user || {};
    const { projectId } = routerParams || {};

    const isCrewMember = role === 'employee';
    const isNonEmployee = role === 'non_employee';

    if (isNonEmployee) {
      return removeUserModalHandler();
    }

    if (departments.length > 0) {
      department_names = [];

      collection.each(departments, function(_, department) {
        department_names.push(department.name);
      });

      label = `the head of ${department_names.join(', ')}`;
    } else {
      label = this.props.user.data.email;
    }

    message = `Are you sure you want to delete ${label}`;

    if (window.confirm(message)) {
      if (isCrewMember) {
        deleteCrewMember({
          projectId: parseInt(projectId),
          profileId: parseInt(profileId),
        })
          .then(
            ({
              data: { deleteCrewMember: deleteCrewMemberResponse } = {},
              errors,
            }) => {
              let errorMessage;
              if (deleteCrewMemberResponse) {
                usersQuery.reload();
                pushSnackbarNotification({
                  variant: SnackbarVariants.SUCCESS,
                  message: 'User was deleted successfully',
                });
                return;
              }
              if (typeof errors === 'object') {
                const { message = '' } = errors || {};
                errorMessage = message;
              }
              if (Array.isArray(errors)) {
                const errorObject = errors[0] || {};
                const { message = '' } = errorObject;
                errorMessage = message;
              }
              throw new Error(errorMessage);
            },
          )
          .catch(e => {
            const { data = {}, message = '' } = e || {};
            const { message: graphQlErrorMessage = '' } = data || {};
            pushSnackbarNotification({
              variant: SnackbarVariants.ERROR,
              message: `There was an error while trying to delete the user${
                message.length || graphQlErrorMessage.length
                  ? `: ${message || graphQlErrorMessage}`
                  : '.'
              }`,
            });
          });
        return;
      }
      this.props.user.save(
        'delete',
        {},
        () =>
          pushSnackbarNotification({
            variant: SnackbarVariants.SUCCESS,
            message: 'User was deleted successfully',
          }),
        function() {
          alert('Deletion failed, this user has pending offers.');
        },
      );
    }
  },

  _resend() {
    const {
      user: { data: user = {} } = {},
      invitations,
      pushSnackbarNotification,
    } = this.props;

    const { data: { items = [] } = {} } = invitations || {};
    let userInvitation =
      items.find(invitation => {
        return invitation.project_user_id === user.project_user_id;
      }) || {};

    const path = `/invitations/${userInvitation.token}/resend`;

    ajax
      .post(path)
      .then(() => {
        popNotification();
        pushNotification({
          message: 'Resending Invitation To Non-employee now.',
          variant: SnackbarVariants.SUCCESS,
        });
        pushSnackbarNotification({
          variant: SnackbarVariants.SUCCESS,
          message: 'Resending Invitation To Non-employee now.',
        });
      })
      .catch(e => {
        popNotification();
        pushNotification({
          message:
            'There was an issue resending the invitation.' +
            'Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
        pushSnackbarNotification({
          variant: SnackbarVariants.ERROR,
          message:
            'There was an issue resending the invitation.' +
            'Please try again or contact customer support if the issue persists.',
        });
        Sentry.captureException(e);
      });
  },

  findChildPrivileges(privilege) {
    // These child privileges (contained in the arrays) should be turned
    // off when their parents (the keys) get turned off
    const privileges = {
      can_create_offers: ['can_hirer_view_all'],
      can_be_reviewer: ['can_review_offers'],
      can_be_i9_verifier: ['can_i9_verify_offers'],
      can_approve_offers: [],
      can_view_offer_account_codes: ['can_edit_offer_account_codes'],
      can_access_archives: [],
      can_view_documents: [],
    };
    const { [privilege]: subPrivileges = [] } = privileges;
    return subPrivileges || [];
  },

  _changePrivilege(privilege) {
    const { user: userMutator = {}, pushSnackbarNotification } = this.props;
    const { data: user = {} } = userMutator || {};
    const { privileges = [] } = user || {};
    return value => {
      const newPrivileges = [...privileges];
      if (value) {
        newPrivileges.push(privilege);
      } else {
        // If you remove a parent privilege we should be removing
        // the child privilege
        // along with it.  If you remove hiring manager privileges
        // you should also remove hirer_can_view_all_offers
        let privilegesToRemove = [
          privilege,
          ...this.findChildPrivileges(privilege),
        ];
        privilegesToRemove.forEach(privilegeToRemove => {
          if (newPrivileges.includes(privilegeToRemove)) {
            newPrivileges.splice(newPrivileges.indexOf(privilegeToRemove), 1);
          }
        });
      }

      if (!!newPrivileges && newPrivileges.length === 0) {
        pushSnackbarNotification({
          message:
            'You cannot have empty User Privileges. Instead use Delete option.',
          variant: SnackbarVariants.ERROR,
          duration: 5000,
        });
        return;
      }

      userMutator.updateAndSave({ privileges: newPrivileges }, 'privileges');
    };
  },
});

const deleteCrewMemberMutationConfig = {
  props: ({ mutate }) => ({
    deleteCrewMember: ({ projectId, profileId }) =>
      mutate({ variables: { projectId, profileId } }),
  }),
};

const UserRow = compose(
  withSnackbarNotification,
  graphql(Mutations.DeleteCrewMember, deleteCrewMemberMutationConfig),
  withStyles(styles),
)(UserRowClass);

export default Relay.createClass({
  statics: {
    queries: {
      users: {
        info(_, related) {
          const params = related['/router/params'];
          const query = related['/router/query'];

          return {
            id: `/projects/${params.projectId}/users`,

            cursor: {
              page: query.page,
              per_page: query.per_page || config.DEFAULT_PER_PAGE,
              sort: query.sort,
              direction: query.direction,
              q: query.q,
            },
          };
        },
      },
      invitations: {
        info(params, related) {
          const routerParams = related['/router/params'];

          return {
            id: `/projects/${routerParams.projectId}/invitations`,
          };
        },
      },
      projectUsers: {
        info(params, related) {
          const routerParams = related['/router/params'];

          return {
            id: `/projects/${routerParams.projectId}/non-employees`,
          };
        },
      },
    },
  },

  getInitialState() {
    return {
      isNonEmployeeModalOpen: false,
      deletedNonEmployee: null,
    };
  },

  handleNonEmployeeDeleteModal(deletedNonEmployee) {
    this.setState(prevState => ({
      isNonEmployeeModalOpen: !prevState.isNonEmployeeModalOpen,
      deletedNonEmployee,
    }));
  },

  render() {
    const {
      routerParams: params = {},
      users: usersQuery = {},
      invitations: invitationsQuery,
      projectUsers: nonEmployeesQuery,
      invalidateByPatterns,
    } = this.props;

    const { isNonEmployeeModalOpen, deletedNonEmployee } = this.state;
    const { projectId } = params || {};

    const {
      data: {
        items: users = [],
        total_items: totalUsers,
        total_pages: totalPages,
        page,
      } = {},
      status: usersStatus,
    } = usersQuery || {};
    let content = null;
    let pagination = null;
    let rows = null;
    let searchTitle = null;

    if (usersStatus === 'loading') return <Loader />;

    searchTitle = `(${totalUsers}) Users`;
    if (users.length > 0) {
      rows = users.map(user => (
        <UserRow
          params={{ id: user.id }}
          key={user.id}
          usersQuery={usersQuery}
          invitations={invitationsQuery}
          removeUserModalHandler={() => this.handleNonEmployeeDeleteModal(user)}
        />
      ));

      content = (
        <Table data-test-id="Users-table">
          <Table.Header>
            <Cell
              header={true}
              sort="name"
              width={170}
              data-test-id="Users-headerCell-employeeName"
            >
              Employee
            </Cell>
            <Cell
              header={true}
              sort="email"
              width={270}
              data-test-id="Users-headerCell-email"
            >
              Email
            </Cell>
            <Cell
              header={true}
              align="center"
              sort="hirer"
              defaultSort="desc"
              data-test-id="Users-headerCell-hiringManager"
            >
              Hiring Manager
            </Cell>
            <Cell
              header={true}
              align="center"
              sort="reviewer"
              defaultSort="desc"
              data-test-id="Users-headerCell-reviewer"
            >
              Reviewer
            </Cell>
            <Cell
              header={true}
              align="center"
              sort="approver"
              defaultSort="desc"
              data-test-id="Users-headerCell-approver"
            >
              Approver
            </Cell>
            <UsaOnly>
              <Cell
                header={true}
                align="center"
                sort="i9_verifier"
                defaultSort="desc"
                data-test-id="Users-headerCell-i9Verifier"
              >
                I-9 Verifier
              </Cell>
            </UsaOnly>
            <Cell
              header={true}
              align="center"
              sort="archiver"
              defaultSort="desc"
              data-test-id="Users-headerCell-archives"
            >
              Archives
            </Cell>
            <Cell
              header={true}
              align="center"
              sort="documents_viewer"
              defaultSort="desc"
              data-test-id="Users-headerCell-documentViewer"
            >
              Document Viewer
            </Cell>
            <Cell
              header={true}
              align="center"
              sort="can_view_offer_account_codes"
              defaultSort="desc"
              data-test-id="Users-headerCell-viewAccountCodes"
            >
              View Account Codes
            </Cell>
            <Cell
              header={true}
              align="center"
              sort="can_edit_offer_account_codes"
              defaultSort="desc"
              data-test-id="Users-headerCell-editAccountCodes"
            >
              Edit Account Codes
            </Cell>
            <Cell
              header={true}
              sort="role"
              width={100}
              data-test-id="Users-headerCell-role"
            >
              Role
            </Cell>
            <Cell header={true} data-test-id="Users-headerCell-actions">
              Actions
            </Cell>
          </Table.Header>
          {rows}
        </Table>
      );

      pagination = (
        <Pagination
          totalPages={totalPages}
          page={page}
          data-test-id="Users-pagination"
        />
      );
    } else {
      content = (
        <Blankslate data-test-id="Users-blank">No users found</Blankslate>
      );
    }

    return (
      <LayoutContent>
        <Box>
          <BoxItem>
            <Header>
              <Header.Title>
                <span data-test-id="Users-title">{searchTitle}</span>
              </Header.Title>
              <Header.Nav>
                <Search />
                <Button
                  to={`/projects/${params.projectId}/invitations/new`}
                  data-test-id="Users-inviteUsers"
                >
                  Invite/Add Non-production employee
                </Button>
              </Header.Nav>
            </Header>
          </BoxItem>
          {content}
        </Box>
        {pagination}
        {isNonEmployeeModalOpen && (
          <RemoveUser
            nonEmployeesQuery={nonEmployeesQuery}
            handleCloseModal={this.handleNonEmployeeDeleteModal}
            deletedNonEmployee={deletedNonEmployee}
            projectId={projectId}
            usersQuery={usersQuery}
            invalidateByPatterns={invalidateByPatterns}
          />
        )}
      </LayoutContent>
    );
  },
});
