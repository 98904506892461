import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = {
  root: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '0 0 0 4%',
    width: '48%',
  },
  first: { marginLeft: '0' },
  secondary: {
    float: 'right',
    padding: '10px 15px',
    margin: '-8px -13px -8px 0',
    border: '1px solid #e6e7ea',
  },
  big: { width: '60%' },
  extraBig: { width: '89%', margin: '0 0 0 2%' },
  small: { width: '36%' },
  tall: {
    minHeight: '172px',
    height: 'auto',
    '& >*': {
      minHeight: '172px',
      height: 'auto',
    },
  },
  indented: { marginLeft: '15px' },
};

const BoxSection = props => {
  const {
    classes,
    children,
    first,
    secondary,
    big,
    extrabig,
    small,
    tall,
    indented,
    className,
    'data-test-id': dataTestId,
  } = props;
  const rootClass = classNames(
    classes.root,
    {
      [classes.first]: first,
      [classes.secondary]: secondary,
      [classes.big]: big,
      [classes.extraBig]: extrabig,
      [classes.small]: small,
      [classes.tall]: tall,
      [classes.indented]: indented,
    },
    className,
  );
  return (
    <div className={rootClass} data-test-id={dataTestId}>
      {children}
    </div>
  );
};

export default withStyles(styles)(BoxSection);
