import React, { Fragment } from 'react';
import TOOLTIP_ICON from 'common/images/tooltip.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import UnionWeeklyEmployeeTermsMobile from './UnionWeeklyEmployeeTermsMobile';

const useStyles = makeStyles(theme => ({
  terms: {
    marginTop: '40px',
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: 20,
  },
  amount: {
    color: '#2eaba4',
    fontSize: 30,
    fontWeight: 400,
    padding: 18,
    textAlign: 'center',
    border: '0.1px solid #d3f0ee',
    [theme.breakpoints.up('md')]: {
      padding: 15,
    },
  },
  grey: {
    color: '#939393',
  },
  tooltip: {
    marginLeft: '5px',
  },
  mobileTermsRateHeader: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    gridTemplateRows: '30% 70%',
    gridAutoFlow: 'row',
  },
  greenBox: {
    backgroundColor: '#f5fcfb',
    fontSize: 18,
    fontWeight: 400,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  regularBox: {
    backgroundColor: '#f5fcfb',
    color: '#5b5b5b',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    padding: 15,
    border: 'none',
  },
  distant: {
    color: '#5b5b5b',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  distantContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const formatAmount = amount => {
  return !Number.isInteger(amount) ? currency(amount) : `$${amount}`;
};

const TermsOfEmploymentMobile = ({
  isNonUnion,
  offerData,
  isNonUnionScheduleA,
  isNonUnionScheduleC,
  isNonUnionScheduleD,
  isUnionWeeklySchedule,
  terms,
}) => {
  const classes = useStyles();
  const {
    hoursDaysTitle,
    hourlyLocationRate,
    hourlyStudioRate,
    dailyStudioRate,
    weeklyLocationRate,
    weeklyStudioRate,
    idlePayRate,
    sixthDayRate,
    seventhDayRate,
    studioDaysHours,
    locationHours,
    locationTitle,
    overtimeRate,
    daysPerWeekTitle,
    daysPerWeek,
  } = offerData;

  if (isUnionWeeklySchedule) {
    return <UnionWeeklyEmployeeTermsMobile terms={terms} />;
  }

  const isStudioRate = dailyStudioRate || weeklyStudioRate || hourlyStudioRate;
  const isDistant = hourlyLocationRate || weeklyLocationRate || locationHours;
  const is6th7thDay = sixthDayRate || seventhDayRate;

  const payDayTitle = day => {
    if (isNonUnionScheduleA) {
      return (
        <>
          Apply Hours per Day <br /> to {day}
          th Day
        </>
      );
    }
    if (isNonUnionScheduleC) {
      return `Pay ${day}th day at`;
    }
    return `${day}th day pay`;
  };

  const weekendPayRate = payRate => {
    if (isNonUnionScheduleA) return payRate > 0 ? 'Yes' : 'No';
    return formatAmount(payRate);
  };

  const showStudioDaysHours =
    !isNonUnionScheduleC && !isNonUnionScheduleD && !!studioDaysHours;

  return (
    <div className={classes.terms}>
      <Typography
        className={classes.title}
        data-test-id="TermsOfEmploymentMobile-title"
      >
        Terms of Employment
      </Typography>
      {isStudioRate && (
        <Fragment>
          <UsaOnly>
            <div>
              {!isNonUnion && (
                <div className={classes.greenBox}>
                  <Typography
                    className={classes.distant}
                    data-test-id="TermsOfEmploymentMobile-studioSection-title"
                  >
                    STUDIO{' '}
                    <Tooltip
                      title="Studio refers to a local distance within the studio zone"
                      placement="top-start"
                      className={classes.tooltip}
                    >
                      <img src={TOOLTIP_ICON} alt="schedule tooltip" />
                    </Tooltip>
                  </Typography>
                </div>
              )}
            </div>
          </UsaOnly>
          {(hourlyStudioRate || dailyStudioRate) && (
            <Table>
              <TableHead>
                <TableRow>
                  {hourlyStudioRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-hourlyStudioRate-title"
                    >
                      <UsaOnly>Hourly Rate</UsaOnly>
                      <CanadaOnly>Rate Per Hour</CanadaOnly>
                    </TableCell>
                  )}
                  {dailyStudioRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-dailyStudioRate-title"
                    >
                      Daily Rate
                    </TableCell>
                  )}
                  {overtimeRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-overtimeTitle"
                    >
                      Overtime Rate
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {hourlyStudioRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-hourlyStudioRate-amount"
                    >
                      {formatAmount(hourlyStudioRate)}
                    </TableCell>
                  )}
                  {dailyStudioRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-dailyStudioRate-amount"
                    >
                      {formatAmount(dailyStudioRate)}
                    </TableCell>
                  )}

                  {overtimeRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-overtime-amount"
                    >
                      {formatAmount(overtimeRate)}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          )}
          {(weeklyStudioRate || showStudioDaysHours) && (
            <Table className={classes.distantContainer}>
              <TableHead>
                <TableRow>
                  {weeklyStudioRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-weeklyStudioRate-title"
                    >
                      Weekly Rate
                    </TableCell>
                  )}
                  {showStudioDaysHours && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-hourDays-title"
                    >
                      <UsaOnly>
                        {isNonUnionScheduleA ? 'Hours Per Day' : hoursDaysTitle}
                      </UsaOnly>
                      <CanadaOnly>Hours Per Day</CanadaOnly>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {weeklyStudioRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-weeklyStudioRate-amount"
                    >
                      {formatAmount(weeklyStudioRate)}
                    </TableCell>
                  )}
                  {showStudioDaysHours && (
                    <TableCell
                      className={[classes.amount, classes.grey].join(' ')}
                      data-test-id="TermsOfEmploymentMobile-studioDaysHours-amount"
                    >
                      {studioDaysHours}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          )}
          {daysPerWeek && (
            <CanadaOnly>
              <Table className={classes.distantContainer}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-canada-daysPerWeek-title"
                    >
                      {daysPerWeekTitle}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {daysPerWeek && (
                      <TableCell
                        className={[classes.amount, classes.grey].join(' ')}
                        data-test-id="TermsOfEmploymentMobile-canada-daysPerWeek"
                      >
                        {daysPerWeek}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </CanadaOnly>
          )}
        </Fragment>
      )}
      <UsaOnly>
        {' '}
        {isDistant && (
          <div className={classes.distantContainer}>
            <div>
              <div className={classes.greenBox}>
                <Typography
                  className={classes.distant}
                  data-test-id="TermsOfEmploymentMobile-distantSection-title"
                >
                  DISTANT{' '}
                  <Tooltip
                    title="Distant refers to the location outside the studio zone."
                    placement="top-start"
                    className={classes.tooltip}
                  >
                    <img src={TOOLTIP_ICON} alt="schedule tooltip" />
                  </Tooltip>
                </Typography>
              </div>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  {hourlyLocationRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-hourlyRate-title"
                    >
                      Hourly Rate
                    </TableCell>
                  )}
                  {weeklyLocationRate && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-weeklyLocationRate-title"
                    >
                      Weekly Rate
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {hourlyLocationRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-hourlyLocationRate-amount"
                    >
                      {formatAmount(hourlyLocationRate)}
                    </TableCell>
                  )}
                  {weeklyLocationRate && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-weeklyLocationRate-amount"
                    >
                      {formatAmount(weeklyLocationRate)}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
            {!!locationHours && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-locationHours-title"
                    >
                      {locationTitle}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {locationHours && (
                      <TableCell
                        className={classes.amount}
                        data-test-id="TermsOfEmploymentMobile-locationHours-amount"
                      >
                        {locationHours}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </div>
        )}
        {is6th7thDay && (
          <Table>
            <TableHead>
              <TableRow>
                {sixthDayRate && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="TermsOfEmploymentMobile-6thDayRate-title"
                  >
                    {payDayTitle('6')}
                  </TableCell>
                )}
                {seventhDayRate && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="TermsOfEmploymentMobile-7thDayRate-title"
                  >
                    {payDayTitle('7')}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {sixthDayRate && (
                  <TableCell
                    className={`${classes.amount} ${classes.grey}`}
                    data-test-id="TermsOfEmploymentMobile-6thDayRate-amount"
                  >
                    {weekendPayRate(sixthDayRate)}
                  </TableCell>
                )}
                {seventhDayRate && (
                  <TableCell
                    className={`${classes.amount} ${classes.grey}`}
                    data-test-id="TermsOfEmploymentMobile-7thDayRate-amount"
                  >
                    {weekendPayRate(seventhDayRate)}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        )}
        {idlePayRate && (
          <Table className={classes.distantContainer}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.regularBox}
                  data-test-id="TermsOfEmploymentMobile-idleRate-title"
                >
                  Idle Days Pay
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.amount}
                  data-test-id="TermsOfEmploymentMobile-idleRate-amount"
                >
                  {formatAmount(idlePayRate)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </UsaOnly>
    </div>
  );
};

export default TermsOfEmploymentMobile;
