import React from 'react';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';

const DocumentRequired = ({ classes, templateRequired, updateRequired }) => (
  <Typography variant="h6" gutterBottom>
    Required Document
    <div>
      <FormControlLabel
        label="Required"
        control={
          <Checkbox
            checked={templateRequired}
            onClick={() => updateRequired(!templateRequired)}
            data-test-id="DocumentRequired-required"
          />
        }
      />
    </div>
  </Typography>
);

export default DocumentRequired;
