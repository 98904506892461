import React from 'react';
import Field from '../../Base/Field';
import T from '../../../utils/i18n';
import Typography from '@material-ui/core/Typography';
import Loader from 'common/components/Loader';

// Utilities
import phoneNumberInputOnChange from 'common/oldJavascripts/utils/phoneNumberInputOnChange';
import { AsYouType } from 'libphonenumber-js';

// HoC
import { withStyles } from '@material-ui/core/styles';

const styles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridColumnGap: '2%',
  },
  typography: {
    margin: '15px 0',
    fontSize: '1.23em',
    lineHeight: '1.25em',
    fontFamily: 'proxima-nova, sans-serif',
    fontWeight: 'bold',
  },
};

class EmergencyContact extends React.Component {
  changeName = newName => {
    const { profileMutator } = this.props;
    const n = newName.replace(/[^a-zA-Z.'\-\s]/g, '');
    profileMutator.update('emergency_name')(n);
  };

  formatPhoneNumber = number => {
    // we do not start formatting the number until it's greater than 4 digits
    // this prevents a bug that prevents the user from deleting their area code
    if (!/(.?\d){4,}/.test(number)) return number;
    const asYouType = new AsYouType('US');
    // return the formatted phone number
    return asYouType.input(number);
  };

  updatePhoneNumber = (number, target) => {
    const { profileMutator } = this.props;
    phoneNumberInputOnChange(number, target, 'emergency_phone', profileMutator);
  };

  render() {
    const { profileMutator, classes } = this.props;
    const { data: profile, errors } = profileMutator;
    var content = null;

    const phoneTypeOptions = [
      {
        label: T('profile.general_information.phone_type_options.mobile'),
        value: 'Mobile',
      },
      {
        label: T('profile.general_information.phone_type_options.work'),
        value: 'Work',
      },
      {
        label: T('profile.general_information.phone_type_options.home'),
        value: 'Home',
      },
    ];

    if (profileMutator.status === 'loading') {
      content = <Loader size={24} />;
    } else {
      content = (
        <div className={classes.wrapper}>
          <div data-test-id="Emergency-name">
            <Field
              label={T('profile.emergency_contact.name')}
              type="text"
              grouped={true}
              name="emergency_name"
              required={this.props.required}
              value={profile.emergency_name}
              errors={errors.emergency_name}
              onChange={this.changeName}
              id="emergency_name"
            />
          </div>
          <div data-test-id={`Emergency-relationship`}>
            <Field
              label={T('profile.emergency_contact.relationship')}
              type="text"
              grouped={true}
              name="emergency_relationship"
              required={this.props.required}
              value={profile.emergency_relationship}
              errors={errors.emergency_relationship}
              onChange={profileMutator.update('emergency_relationship')}
              id="emergency_relationship"
            />
          </div>
          <div data-test-id="Emergency-phoneType">
            <Field
              label={T('profile.emergency_contact.phone_type')}
              type="select"
              grouped={true}
              name="emergency_phone_type"
              required={this.props.required}
              value={profile.emergency_phone_type}
              errors={errors.emergency_phone_type}
              onChange={profileMutator.update('emergency_phone_type')}
              options={phoneTypeOptions}
              id="emergency_phone_type"
            />
          </div>
          <div data-test-id="Emergency-phone">
            <Field
              label={T('profile.emergency_contact.phone')}
              type="phone"
              grouped={true}
              name="emergency_phone"
              required={this.props.required}
              value={this.formatPhoneNumber(profile.emergency_phone)}
              errors={errors.emergency_phone}
              onChange={(value, target) =>
                this.updatePhoneNumber(value, target)
              }
              id="emergency_phone"
            />
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root} data-test-id="Emergency-root">
        <Typography className={classes.typography} variant="h4">
          {T('profile.emergency_contact.title')}
        </Typography>
        {content}
      </div>
    );
  }
}

export default withStyles(styles)(EmergencyContact);
