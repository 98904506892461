import React from 'react';

// Components
import TableCell from '@material-ui/core/TableCell';
import Select from 'react-select';

// Utilities
import classNames from 'class-names';

const PerField = ({
  onChange: upstreamOnChange,
  value = '',
  updateKey,
  classes = {},
}) => {
  const options = () => [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
  ];

  // We only store the code in our formData but we need to supply the formatted option
  // as the selected value
  const selectValue = options().find(({ value: val }) => value === val) || null;

  const onChange = ({ value }) =>
    upstreamOnChange({
      [updateKey]: value,
    });

  return (
    <TableCell
      align="center"
      className={classNames(classes.root, classes.selectContainer)}
    >
      <div data-test-id="PerField-select">
        <Select
          onChange={onChange}
          value={selectValue}
          options={options()}
          className={classes.select}
        />
      </div>
    </TableCell>
  );
};

export default PerField;
