import React from 'react';
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Icon from './Icon';
import Help from './Help';
import Select from './Select';
import Uploader from './Uploader';
import Datepicker from './Datepicker';
import List from './List';
import Text from './Text';
import Alert from './Alert';
import FormField from './FormField';
import NumericInput from './NumericInput';
import { currency } from '../../utils/format';
import domClasses from '../../utils/dom/classes.js';
import T from '../../utils/i18n';
import classNames from 'classnames';

// HoC
import { withStyles } from '@material-ui/core/styles';
// Mui Components
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Typography,
} from '@material-ui/core';

const SSN_MASK_LENGTH = 4;
const ENTER_KEY = 'Enter';
const DEFAULT_FRACTIONAL_DIGITS = { numeric: 2 };

const styles = theme => ({
  checkbox: {
    border: 'none',
    marginRight: 7,
    padding: 0,
    width: 'initial',
    '&>input': {
      display: 'none',
    },
  },
  checked: {
    color: '#469fcc',
  },
  typography: {
    fontSize: 13,
    fontFamily: 'proxima-nova, sans-serif',
  },
  smallLabel: {
    fontSize: 11,
  },
  lightLabel: {
    color: '#979ca5',
    marginTop: 2,
  },
  fontHeavy: {
    fontWeight: 600,
  },
  smallCheckbox: {
    height: 15,
    width: 15,
  },
  paddingLeft: {
    paddingLeft: 20,
  },
  smallRadio: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  marginLeft: {
    marginLeft: 25,
  },
  radioInline: {
    flexDirection: 'row !important',
  },
});

const CheckboxOld = withStyles(styles)(props => {
  const {
    checked,
    name,
    id,
    disabled,
    classes,
    label,
    hideLabel,
    smallLabel,
    lightLabel,
    fontHeavy,
    smallCheckbox,
    paddingLeft,
    marginLeft,
    className,
  } = props;
  const value = checked ? '1' : '';

  const onChangeHandler = event => {
    const { onChange, checked } = props;
    onChange(!checked);
  };

  const labelClass = classNames(classes.typography, {
    [classes.smallLabel]: smallLabel,
    [classes.lightLabel]: lightLabel,
    [classes.fontHeavy]: fontHeavy,
  });

  const labelProp = hideLabel ? null : (
    <Typography className={labelClass}>{label}</Typography>
  );

  const checkboxClass = classNames({
    [classes.smallCheckbox]: smallCheckbox,
    [classes.paddingLeft]: paddingLeft,
    [classes.marginLeft]: marginLeft,
  });

  const iconDimensions = smallCheckbox ? '15px' : '1em';
  const dataTestIdDynamicID = String(id || name || label).replace(/,\s/g, '');
  return (
    <FormControlLabel
      label={labelProp}
      className={classNames(classes.controlLabel, className)}
      control={
        <Checkbox
          name={name}
          id={id}
          value={value}
          checked={checked}
          onChange={onChangeHandler}
          disabled={disabled}
          inputProps={{
            'data-test-id': `Field-checkboxInput-${dataTestIdDynamicID}`,
            'data-checked': String(checked),
          }}
          icon={
            <CheckBoxOutlineBlankIcon
              style={{ height: iconDimensions, width: iconDimensions }}
            />
          }
          checkedIcon={
            <CheckBoxIcon
              style={{ height: iconDimensions, width: iconDimensions }}
            />
          }
          className={checkboxClass}
        />
      }
    />
  );
});

const RadioOld = withStyles(styles)(props => {
  const { classes, className, value, onChange, checked, disabled } = props;

  const onChangeHandler = event => {
    onChange(value);
  };

  const radioClass = classNames(classes.smallRadio, className);
  return (
    <Radio
      onChange={onChangeHandler}
      checked={checked}
      disabled={disabled && !checked}
      className={radioClass}
    />
  );
});

export class Errors extends React.Component {
  render() {
    var errors = null;
    var errorString = null;
    var errorKey = null;

    errors = _.map(this.props.errors, function(error, i) {
      errorString = T('errors.' + error);
      errorKey = 'error_' + i;

      if (Object.prototype.toString.call(error) === '[object Array]') {
        var errorHtmls = [];

        for (var index = 0; index < error.length; index++) {
          errorHtmls.push(
            <li className="field-error" key={`error_${index}`}>
              {error[index]}
            </li>,
          );
        }
        return errorHtmls;
      } else {
        if (errorString !== 'errors.' + error) {
          return (
            <li className="field-error" key={error}>
              {errorString}
            </li>
          );
        } else {
          return (
            <li className="field-error" key={errorKey}>
              {T(error)}
            </li>
          );
        }
      }
    });

    return (
      <div className="field-errors">
        <ul className="field-errorsContent">{errors}</ul>
      </div>
    );
  }
}

class SSNInput extends React.Component {
  state = {
    masked: true,
    edited: false,
    initialValue: this.props.value,

    // One warning for each ssn input
    warnings: [false, false, false],
  };

  render() {
    var maskCharacter = '•';
    var maskedLength = 0;
    var value = this.props.value || '';

    if (this.state.masked && value) {
      if (value.length > SSN_MASK_LENGTH) {
        maskedLength = value.length - SSN_MASK_LENGTH;
      }

      value = maskCharacter.repeat(maskedLength) + value.substr(maskedLength);

      value = [value.substr(0, 3), value.substr(4, 2), value.substr(7, 4)];
    } else {
      value = value.split('-');
    }

    return (
      <div>
        <input
          type="text"
          name={this.props.name}
          id={this.props.id}
          value={value[0]}
          className={this._classForInput(0)}
          ref="input-0"
          onChange={this._change(0)}
          onBlur={this._mask}
          onFocus={this._focus}
          disabled={this.props.disabled}
        />
        <input
          type="text"
          name={this.props.name}
          id={this.props.id}
          value={value[1]}
          className={this._classForInput(1)}
          ref="input-1"
          onChange={this._change(1)}
          onBlur={this._mask}
          onFocus={this._focus}
          disabled={this.props.disabled}
        />
        <input
          type="text"
          name={this.props.name}
          id={this.props.id}
          value={value[2]}
          className={this._classForInput(2)}
          ref="input-2"
          onChange={this._change(2)}
          onBlur={this._mask}
          onFocus={this._focus}
          disabled={this.props.disabled}
        />
      </div>
    );
  }

  _focus = () => {
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus();
    }
    this._unmask();
  };

  _mask = () => {
    this.setState({ masked: true });
  };

  _unmask = () => {
    this.setState({ masked: false });
  };

  _change = index => {
    var self = this;

    return function(event) {
      var currentValue = (self.props.value || '').split('-');
      var valLen = [3, 2, 4][index];
      var value = event.target.value.replace(/\D/g, '').substr(0, valLen);
      var nextFocus = null;

      if (!self.state.edited) {
        self.setState({ edited: true });
      }

      if (index !== 2 && value.length === valLen) {
        nextFocus = self.refs['input-' + (index + 1)];
      }

      currentValue[index] = value;

      if (self.state.edited || self._hasWarnings()) {
        self._validateFields(currentValue);
      }

      self.props.onChange(currentValue.join('-'));

      if (nextFocus) {
        nextFocus.focus();
      }
    };
  };

  _classForInput = index => {
    var className = this.props.className;
    var shouldWarn = this.state.warnings[index];

    return shouldWarn ? className + ' is-invalid' : className;
  };

  _validateFields = currentValue => {
    var warnings = this.state.warnings;
    var valLen = [3, 2, 4];

    _.forEach(currentValue, function(_val, i) {
      var len = valLen[i];
      var val = _val.substr(0, len);
      var pattern = new RegExp('\\d{' + len + '}');

      warnings[i] = !val || !pattern.test(val);
    });

    this.setState({ warnings: warnings });
  };

  _hasWarnings = () => {
    return _.some(this.state.warnings, Boolean);
  };
}

class Field extends React.Component {
  static defaultProps = {
    unmaskLength: 0,
    maskCharacter: '•',
    numRows: 1,
  };

  state = {
    maskPassword: true,
    masked: this.props.masked || this.props.type === 'ssn',
    didExceedLimit: false,
  };

  render() {
    const { inline } = this.props;
    var self = this;
    var isInvalid = !_.isEmpty(this.props.errors);
    var classes = null;
    var inputClasses = null;
    var labelClasses = null;
    var labelHelpText = null;
    var required = null;
    var errors = null;
    var inputs = null;
    var helpText = null;
    var errorAlert = null;
    var uploader = null;
    var label = null;
    var classSize = null;

    if (this.props.size) {
      classSize = 'field--size-' + this.props.size;
    }

    classes = domClasses.set(
      {
        field: !this.props.disableFieldClass && true,
        'field--inline': this.props.inline,
        'field--grouped': this.props.grouped === true,
        'field--grouped-bottom': this.props.grouped === 'bottom',
        'field--disabled': this.props.disabled,
        'field--compact': this.props.compact,
        'is-invalid': isInvalid,
      },
      classSize,
      this.props.className,
    );

    labelClasses = domClasses.set({
      'field-label': true,
      'field-label--hidden': this.props.hideLabel,
      'field-label--inline': this.props.inlineLabel,
    });

    inputClasses = domClasses.set(
      {
        'field-input': true,
        'field-input--alignCenter': this.props.align === 'center',
        'field-input--autocomplete': this.props.type === 'autocomplete',
        'field-input--code': this.props.type === 'code',
        'field-input--noBorder': this.props.border === 'none',
        'field-input--radio':
          this.props.type === 'radio' || this.props.type === 'radiogroup',
        'field-input--ssn': this.props.type === 'ssn',
        'field-input--search': this.props.type === 'search',
        'field-input--warning': this.state.didExceedLimit,
        'is-invalid': isInvalid,
        'text-mask-uppercase': this.props.textMaskUppercase,
      },
      this.props.className,
    );

    if (this.props.required) {
      required = <span className="field-required">*</span>;
    }

    if (this.props.labelHelpText) {
      labelHelpText = <Help text={this.props.labelHelpText} />;
    }

    if (isInvalid) {
      errors = <Errors errors={this.props.errors} />;
    }

    if (this.props.helpText) {
      helpText = (
        <Text paragraph={true} comment={true}>
          {this.props.helpText}
        </Text>
      );
    }

    const {
      smallLabel,
      lightLabel,
      fontHeavy,
      smallCheckbox,
      paddingLeft,
      marginLeft,
    } = this.props;

    if (this.props.type === 'checkbox') {
      return (
        <div className={classes} style={{ marginBottom: '5px' }}>
          <CheckboxOld
            id={this.props.id}
            name={this.props.name}
            onChange={this._changeValue}
            checked={!!this.props.value}
            className={this.props.className}
            disabled={this.props.disabled}
            label={this.props.label}
            hideLabel={this.props.hideLabel}
            smallLabel={smallLabel}
            lightLabel={lightLabel}
            fontHeavy={fontHeavy}
            smallCheckbox={smallCheckbox}
            paddingLeft={paddingLeft}
            marginLeft={marginLeft}
          />
          <span className={labelClasses}>
            {required} {labelHelpText}
          </span>
          {this.props.children}
          {errors}
        </div>
      );
    } else if (this.props.type === 'radio') {
      return (
        <div className={classes}>
          <label htmlFor={this.props.id} className="field-label">
            <RadioOld
              name={this.props.name}
              id={this.props.id}
              value={this.props.value}
              className={inputClasses}
              onChange={this._changeValue}
              checked={this.props.checked}
              label={this.props.label}
            />
            {this.props.label} {required} {labelHelpText}
          </label>
          {errors}
        </div>
      );
    } else if (this.props.type === 'radiogroup') {
      inputs = this.props.options.map(option => (
        <label
          key={option.value}
          data-test-id={`Field-radio-${this.props.name}/${option.value}`}
        >
          <RadioOld
            name={self.props.name}
            id={self.props.id}
            value={option.value}
            disabled={self.props.disabled}
            checked={self.props.value === option.value}
            onChange={self._changeValue}
            label={option.label}
          />
          {option.label}
          {option.help ? <Help text={option.help} /> : null}
        </label>
      ));

      const { classes } = this.props;

      const radioGroupClass = classNames(
        { [classes.radioInline]: inline },
        classes.radioGroup,
      );
      return (
        <React.Fragment>
          <FormControl
            component="fieldset"
            classes={{
              root: classes.fieldSet,
            }}
          >
            <RadioGroup name={this.props.name} className={radioGroupClass}>
              {inputs}
            </RadioGroup>
          </FormControl>
          {errors}
        </React.Fragment>
      );
    } else if (this.props.type === 'checkboxgroup') {
      return (
        <div className={classes}>
          <div className={labelClasses}>
            {this.props.label} {required} {labelHelpText}
          </div>
          {errors}
          <List>
            {_.map(this.props.options, function(option) {
              var checked = self.props.value.indexOf(option.value) !== -1;
              var optionClasses = null;
              var onChange = null;

              optionClasses = domClasses.set({
                'field-item': true,
                'field-item--inline': true,
                'field-item--grouped': option.grouped,
              });

              onChange = function() {
                self._changeValue(option.value);
              };

              return (
                <List.Item key={option.value}>
                  <List.ItemContent>
                    <List.ItemContentBody>
                      <label className={optionClasses} key={option.value}>
                        <Checkbox
                          checked={checked}
                          disabled={option.disabled}
                          name={self.props.name}
                          onChange={onChange}
                        />
                        {option.label || option.value}
                      </label>
                    </List.ItemContentBody>
                  </List.ItemContent>
                </List.Item>
              );
            })}
          </List>
        </div>
      );
    } else if (this.props.type === 'select') {
      return (
        <div className={classes}>
          <label htmlFor={this.props.id} className={labelClasses}>
            {this.props.label} {required} {labelHelpText}
          </label>
          <Select
            disabled={this.props.disabled}
            options={this.props.options || []}
            value={this.props.value}
            onChange={this._changeValue}
            isLoading={this.props.isLoading}
            searchable={this.props.searchable}
            clearable={this.props.clearable}
            simpleValue={true}
            id={this.props.id}
            name={this.props.name}
            multiple={this.props.multiple}
            isInvalid={isInvalid}
          />
          {helpText}
          {errors}
        </div>
      );
    } else if (this.props.type === 'autocomplete') {
      return (
        <div className={classes}>
          <label htmlFor={this.props.id} className={labelClasses}>
            {this.props.label} {required} {labelHelpText}
          </label>
          <Select
            options={this.props.options || []}
            value={this.props.value}
            onChange={this._changeValue}
            id={this.props.id}
            isInvalid={isInvalid}
            name={this.props.name}
            multiple={this.props.multiple}
            clearable={true}
            searchable={true}
          />
          {helpText}
          {errors}
        </div>
      );
    } else if (this.props.type === 'file') {
      if (errors) {
        errorAlert = (
          <Alert type="error" inline={true}>
            {errors}
          </Alert>
        );
      }

      if (!this.props.noUploader) {
        uploader = (
          <Uploader
            value={this.props.value}
            onChange={this._changeValue}
            className={inputClasses}
            id={this.props.id}
            name={this.props.name}
            enableSnapshots={this.props.enableSnapshots}
            accept={this.props.accept}
            onFileTypeChange={this._updateFileType}
          />
        );
      }

      return (
        <div className={classes}>
          <label htmlFor={this.props.id} className={labelClasses}>
            {this.props.label} {required} {labelHelpText}
          </label>
          {errorAlert}
          {uploader}
          {helpText}
        </div>
      );
    } else if (this.props.type === 'date') {
      console.warn(
        'You are using a `Field` component with `type=date and ' +
          ' which is deprecated in favor of using:\n <FormField>\n  ' +
          '<Datepicker />\n </FormField>',
      );

      if (this.props.hideLabel) {
        label = false;
      } else {
        label = this.props.label;
      }

      return (
        <FormField
          label={label}
          errors={this.props.errors}
          helpText={this.props.helpText}
          required={this.props.required}
          grouped={this.props.grouped}
          inlineLabel={this.props.inlineLabel}
          compact={this.props.compact}
          inline={this.props.inline}
        >
          <Datepicker
            value={this.props.value}
            id={this.props.id}
            name={this.props.name}
            onChange={this.props.onChange}
            isInvalid={isInvalid}
            yearsUp={this.props.yearsUp}
            yearsOrder={this.props.yearsOrder}
            limitBefore={this.props.limitDateBefore}
            limitAfter={this.props.limitDateAfter}
            disabled={this.props.disabled}
          />
        </FormField>
      );
    } else if (this.props.type === 'textarea') {
      return (
        <div className={classes}>
          <label htmlFor={this.props.id} className={labelClasses}>
            {this.props.label} {required} {labelHelpText}
          </label>
          {this.props.children}
          <textarea
            value={this.props.value}
            className={inputClasses}
            id={this.props.id}
            name={this.props.name}
            maxLength={this.props.maxLength}
            placeholder={this.props.placeholder}
            onChange={this._change}
            rows={this.props.numRows}
            data-test-id={`Field-textarea-${this.props.name}`}
          />
          {helpText}
          {errors}
        </div>
      );
    } else if (this.props.type === 'ssn') {
      return (
        <div className={classes}>
          <label htmlFor={this.props.id} className={labelClasses}>
            {this.props.label} {required} {labelHelpText}
          </label>
          {this.props.children}
          <SSNInput
            name={this.props.name}
            id={this.props.id}
            value={this.props.value}
            className={inputClasses}
            onChange={this._changeValue}
            onFocus={this.props.onFocus}
            disabled={this.props.disabled}
          />
          {helpText}
          {errors}
        </div>
      );
    } else if (this.props.type === 'search') {
      return (
        <div className={classes}>
          <label htmlFor={this.props.id} className={labelClasses}>
            {this.props.label} {required} {labelHelpText}
          </label>
          {this.props.children}
          <Icon name="magnifier" className="field-inputIcon" />
          <input
            type={this.props.type}
            name={this.props.name}
            id={this.props.id}
            className={inputClasses}
            autoFocus={this.props.autoFocus}
            placeholder={this.props.placeholder}
            onChange={this._change}
            onBlur={this._submitSearch}
            onFocus={this.props.onFocus}
            onKeyDown={this._searchKeyPressed}
            value={this.props.value}
          />
          {helpText}
          {errors}
        </div>
      );
    } else if (
      this.props.type === 'number' &&
      this.props.format === 'numeric'
    ) {
      console.warn(
        'You are using a `Field` component with `type=number and ' +
          ' format=numeric` which is' +
          ' deprecated in favor of using:\n <FormField>\n  <NumericInput />' +
          '\n</FormField>',
      );

      if (this.props.hideLabel) {
        label = false;
      } else {
        label = this.props.label;
      }

      return (
        <FormField
          label={label}
          errors={this.props.errors}
          helpText={this.props.helpText}
          required={this.props.required}
          grouped={this.props.grouped}
          inlineLabel={this.props.inlineLabel}
          compact={this.props.compact}
          inline={this.props.inline}
        >
          <NumericInput
            name={this.props.name}
            id={this.props.id}
            value={this.props.value}
            min={this.props.min}
            max={this.props.max}
            onChange={this.props.onChange}
            autoFocus={this.props.autoFocus}
            placeholder={this.props.placeholder}
            onBlur={this.onBlur}
            onFocus={this.props.onFocus}
            disabled={this.props.disabled}
            readOnly={this.props.readonly}
            allowZeros={this.props.allowZeros}
            scale={this.props.scale}
          />
        </FormField>
      );
    } else if (this.props.type === 'textmask') {
      const {
        children,
        id,
        label,
        mask,
        name,
        placeholder,
        value,
        disabled,
        placeholderChar,
      } = this.props;
      const valueToDisplay = value ? `${value}`.toUpperCase() : value;
      return (
        <div className={classes}>
          <label htmlFor={id} className={labelClasses}>
            {label} {required} {labelHelpText}
          </label>
          {children}
          <MaskedInput
            disabled={disabled}
            placeholderChar={placeholderChar}
            placeholder={placeholder}
            mask={mask}
            name={name}
            id={id}
            value={valueToDisplay}
            className={inputClasses}
            onChange={this._change}
            autoComplete="off"
          />
          {helpText}
          {errors}
        </div>
      );
    } else if (this.props.type === 'address') {
      const {
        children,
        disabled,
        id,
        label,
        placeholder,
        value,
        autoFocus,
      } = this.props;
      // Type must be set to text instead of address to help bypass chrome autofill
      return (
        <div className={classes}>
          <label htmlFor={id} className={labelClasses}>
            {label} {required} {labelHelpText}
          </label>
          {children}
          <input
            type={'text'}
            name={'red'}
            id={'red'}
            value={this.state.masked ? this._maskedValue() : value}
            className={inputClasses}
            onChange={this._change}
            onKeyUp={this._keyUp}
            onFocus={() => {
              this._maskFocus();
              if (this.props.onFocus) {
                this.props.onFocus();
              }
            }}
            autoFocus={autoFocus}
            placeholder={placeholder}
            onBlur={this._onBlur}
            disabled={disabled}
            autoComplete="false"
          />
          {helpText}
          {errors}
        </div>
      );
    } else {
      const passwordIcons = [];
      if (this.props.type === 'password') {
        passwordIcons.push(
          <Icon
            name={this.state.maskPassword ? 'fa-eye' : 'fa-low-vision'}
            label="Show/Hide"
            className="password-show"
            onClick={() =>
              this.setState({ maskPassword: !this.state.maskPassword })
            }
          />,
        );
        if (this.props.passwordTick) {
          passwordIcons.push(
            <Icon
              name="fa-check"
              label="Valid Password!"
              className="password-tick"
            />,
          );
        }
      }
      const type =
        this.props.type === 'password' && !this.state.maskPassword
          ? 'text'
          : this.props.type;
      const { value = '' } = this.props;
      return (
        <div className={classes}>
          <label htmlFor={this.props.id} className={labelClasses}>
            {this.props.label} {required} {labelHelpText}
          </label>
          {this.props.children}
          <input
            type={type}
            name={this.props.name}
            id={this.props.id}
            value={this.state.masked ? this._maskedValue() : value || ''}
            min={this.props.min}
            max={this.props.max}
            maxLength={this.props.maxLength}
            className={inputClasses}
            onChange={this._change}
            onKeyUp={this._keyUp}
            onFocus={() => {
              this._maskFocus();
              if (this.props.onFocus) {
                this.props.onFocus();
              }
            }}
            autoFocus={this.props.autoFocus}
            placeholder={this.props.placeholder}
            onBlur={this._onBlur}
            disabled={this.props.disabled}
            step={this.props.step}
            defaultValue={this.props.defaultValue}
            data-test-id={`Field-Input-${this.props.label}`}
          />
          {passwordIcons}
          {helpText}
          {errors}
        </div>
      );
    }
  }

  _updateFileType = validtype => {
    if (this.props.onFileTypeChange) {
      this.props.onFileTypeChange(validtype);
    }
  };

  _updateState = (key, value, callback) => {
    const state = {};
    state[key] = value;
    this.setState(state, callback);
  };

  _keyUp = event => {
    if (this.props.onKeyUp) {
      event.stopPropagation();
      this.props.onKeyUp(event);
    }
  };

  _change = event => {
    if (this.props.readonly) {
      return;
    }
    this._changeValue(event.target.value, event.target);
  };

  _changeValue = (value, target) => {
    const format = this.props.format;

    if (format === 'numeric' || format === 'currency') {
      value = Number(value.replace(/[^\d.-]/g, ''));

      if (value > Number.MAX_SAFE_INTEGER || value < Number.MIN_SAFE_INTEGER) {
        return;
      }

      const min = Number(this.props.min);
      const max = Number(this.props.max);
      const didExceedLimit = value < min || value > max;
      this.setState({ didExceedLimit });
    }

    if (this.props.onChange) {
      if (this.props.type === 'phone') {
        value = this._cleanPhoneNumber(value);
      } else if (this.props.type === 'checkboxgroup') {
        const newValue = this.props.value.slice();
        const index = this.props.value.indexOf(value);

        if (index >= 0) {
          _.pullAt(newValue, [index]);
        } else {
          newValue.push(value);
        }
        value = newValue;
      }
      this.props.onChange(value, target);
    }
  };

  _onBlur = event => {
    const format = this.props.format;

    let fractionalDigits = this.props.scale;
    if (
      format in DEFAULT_FRACTIONAL_DIGITS &&
      typeof fractionalDigits !== 'number'
    ) {
      fractionalDigits = DEFAULT_FRACTIONAL_DIGITS[format];
    }

    let allowZeros = this.props.allowZeros;
    allowZeros = allowZeros === undefined ? true : allowZeros;

    let value = event.target.value;
    switch (format) {
      case 'currency':
        value = currency(value, fractionalDigits, true);
        break;
      case 'numeric':
        value = Number(value);
        value =
          !allowZeros && value === 0 ? null : value.toFixed(fractionalDigits);
        break;
      default:
    }

    event.target.value = value;

    if (this.props.onBlur) {
      this.props.onBlur(value);
    }
  };

  _maskedValue = () => {
    var maskCharacter = this.props.maskCharacter;
    var maskedLength = null;
    var value = this.props.value;

    if (value) {
      maskedLength = value.length - this.props.unmaskLength;

      if (maskedLength < 0) {
        maskedLength = 0;
      }

      value = maskCharacter.repeat(maskedLength) + value.substr(maskedLength);
    }

    return value;
  };

  _maskFocus = () => {
    this._updateState('masked', false);
  };

  _cleanPhoneNumber = value => {
    var newValue = '';
    var allowedParens = 2;
    var allowedPrefix = 1;
    var allowedDigits = 15;
    var parenCounter = 0;
    var prefixCounter = 0;
    var digitsCounter = 0;

    _.forEach(value, function(c, i, value) {
      if (c === '(' || c === ')') {
        if (parenCounter < allowedParens) {
          newValue += c;
        }
        parenCounter++;
      } else if (c === '+') {
        if (prefixCounter < allowedPrefix) {
          newValue += c;
        }
        prefixCounter++;
      } else if (c >= '0' && c <= '9') {
        if (digitsCounter < allowedDigits) {
          newValue += c;
        }
        digitsCounter++;
      } else if (c === '-' && value[i - 1] !== '-') {
        newValue += c;
      }
    });

    return newValue;
  };

  _searchKeyPressed = event => {
    if (event.key === ENTER_KEY) {
      this._submitSearch();
    }
  };

  _submitSearch = () => {
    this.props.onSubmitSearch();
  };
}

export class Side extends React.Component {
  render() {
    return <div className="field-side">{this.props.children}</div>;
  }
}

Field.Errors = Errors;
Field.Side = Side;

export default withStyles(styles)(Field);
