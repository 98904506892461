import React, { useState } from 'react';
import CrewMembers from './CrewMembers';
import OfferForm from './OfferForm';
import Loader from 'common/components/Loader';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import withApi from 'common/hoc/withApi';
import moment from 'moment';
import getNormalizedMutationPayload from './helpers/getNormalizedMutationPayload';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import history from 'common/constants/config/history';
import { Mutations } from 'common/apollo';
import useOfferDefaults from './hooks/useOfferDefaults';
import useScaleRates from 'studio/hooks/useScaleRates';
import useAccountCodeConfigurations from './hooks/useAccountCodeConfigurations';
import useSeasons from 'studio/hooks/useSeasons';
import useProjectCurrencies from './hooks/useProjectCurrencies';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import isUnionWeeklyScheduleCode from 'common/utilities/isUnionWeeklySchedule';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(300px, 400px) 1fr',
    gridTemplateRows: '100%',
    gridTemplateAreas: `
        "crewMembers content"`,
    height: '100%',
    overflowY: 'auto',
  },
  crewMembers: {
    gridArea: 'crewMembers',
    margin: '20px',
    padding: 10,
    marginRight: 0,
    boxSizing: 'content-box',
    position: 'sticky',
    top: 20,
  },
});

const CreateOffer = props => {
  const {
    classes,
    invalidateByPatterns,
    projectId,
    mutate,
    popSnackbarNotification,
    pushSnackbarNotification,
  } = props;

  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const [hasOfferBeenValidated, setHasOfferBeenValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [crew, setCrew] = useState({});
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { offerDate = {}, termsOfHire = {} } = formData;
  const { startDateObject } = offerDate || {};
  const {
    hireState,
    hireCity,
    union,
    occupation,
    workCity,
    workSchedule,
    workState,
    season,
    currency,
  } = termsOfHire || {};
  const { value: unionValue, isNonUnion = false } = union || {};
  const { value: occupationValue } = occupation || {};
  const { value: workScheduleValue } = workSchedule || {};
  const flags = useFeatureFlags();
  const isUnionScheduleAFlagActive = flags.includes('UnionScheduleA');
  const isUnionWeeklyScheduleFlagActive = flags.includes('UnionWeeklySchedule');
  const isUnionScheduleAV2 =
    !isNonUnion && workScheduleValue === 'A' && isUnionScheduleAFlagActive;
  const isUnionWeeklyScheduleV2 =
    !isNonUnion &&
    isUnionWeeklyScheduleCode(workScheduleValue) &&
    isUnionWeeklyScheduleFlagActive;
  const { data: offerDefaults } = useOfferDefaults(projectId);
  const { data: scaleRates } = useScaleRates({
    projectId,
    countryCode: offerDefaults?.countryCode,
    startDate: startDateObject && startDateObject.format('YYYY-MM-DD'),
    workState,
    hireState,
    workCity,
    hireCity,
    union: unionValue,
    isNonUnion,
    occupation: occupationValue,
    workSchedule: workScheduleValue,
    season,
    currency,
    isUnionScheduleAV2,
    isUnionWeeklyScheduleV2,
  });

  const { data: accountCodeConfigurations } = useAccountCodeConfigurations(
    projectId,
  );
  const { data: seasons } = useSeasons(projectId);
  const { data: currencies } = useProjectCurrencies({ projectId });

  if (!offerDefaults || !accountCodeConfigurations || !seasons || !currencies)
    return <Loader />;

  const getPrePopulatedAccountCodes = () => {
    const accountCodeLineItemKeys = [
      'studio',
      'location',
      'hourly',
      'daily',
      'weekly',
      'perDiem',
      'box',
      'car',
      'computer',
      'mobile',
      'housing',
    ];
    let accountCodes = [];
    accountCodeConfigurations.forEach(({ id, defaultValue, code }) => {
      if (!defaultValue && code !== 'detail/sub') return;
      accountCodeLineItemKeys.forEach(lineItemKey => {
        accountCodes = [
          ...accountCodes,
          {
            accountCodeId: id,
            lineItemKey,
            value: defaultValue || '',
          },
        ];
      });
    });
    return accountCodes;
  };

  const onChangeCrewMember = node =>
    setCrew(crew => ({
      ...crew,
      [node.id]: crew[node.id] === undefined ? node : undefined,
    }));

  const confirmOffer = () => {
    setSubmitInProgress(true);
    const isUnionScheduleA =
      workScheduleValue === 'A' && isUnionScheduleAFlagActive && !isNonUnion;

    const loadingMessage = 'Submitting Offer Now...';
    let modifiedFormData = {
      ...formData,
      termsOfEmployment: {
        ...formData?.termsOfEmployment,
        isTermsOfEmploymentV2:
          isUnionWeeklyScheduleV2 ||
          formData?.termsOfEmployment?.isTermsOfEmploymentV2,
      },
    };
    const variables = getNormalizedMutationPayload(modifiedFormData);
    // Additional processing of mutation payload
    variables.projectId = projectId;
    const toe = variables?.termsOfEmployment || {};
    const isCanada = offerDefaults?.countryCode === 'CA';
    if (scaleRates && !isUnionScheduleA && !isUnionWeeklyScheduleV2) {
      toe.scaleRates = isCanada ? {} : scaleRates;
      delete toe.scaleRates.__typename;
      delete toe.scaleRates.scaleRateHash;
    } else {
      toe.scaleRates = {};
    }

    if (isUnionScheduleA || isUnionWeeklyScheduleV2) {
      delete toe.scaleRates;
      delete toe.__typename;
      delete toe.tableErrors;
    }

    // Remove undefined values in hash.
    Object.keys(crew).forEach(
      key => crew[key] === undefined && delete crew[key],
    );
    variables.crew = Object.values(crew)
      .filter(({ id }) => id)
      .map(({ id }) => parseInt(id, 10));

    popSnackbarNotification();
    pushSnackbarNotification({
      message: loadingMessage,
      variant: SnackbarVariants.INFO,
    });
    mutate({
      variables,
    })
      .then(({ data, message = '' }) => {
        setSubmitInProgress(false);
        popSnackbarNotification({
          message: loadingMessage,
          popAllMatching: true,
        });
        if (message.length) {
          pushSnackbarNotification({
            message: `There was an error submitting the offer${
              !message ? '.' : `: ${message}.`
            }`,
            variant: SnackbarVariants.ERROR,
          });
          return;
        }
        pushSnackbarNotification({
          message: 'Offer successfully submitted',
          variant: SnackbarVariants.SUCCESS,
        });
        invalidateByPatterns([
          `projects/${projectId}/offers`,
          `projects/${projectId}/reviews`,
        ]);
        history.push(`/projects/${projectId}/offers`);
      })
      .catch(err => {
        setSubmitInProgress(false);
        const { graphQLErrors, message } = err;
        graphQLErrors.forEach(console.warn);
        popSnackbarNotification({
          message: loadingMessage,
          popAllMatching: true,
        });
        pushSnackbarNotification({
          message: `There was an error submitting the offer${
            !message ? '.' : `: ${message}.`
          }`,
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  if (!configurationLoaded) {
    setConfigurationLoaded(true);

    const {
      boxRentalAllowance,
      carAllowance,
      computerRentalAllowance,
      hireState = {},
      housingAllowance,
      mobilePhoneAllowance,
      perDiemAllowance,
      sendDateConfiguration,
      workState = {},
      hiringStatus,
    } = offerDefaults;

    setFormData({
      offerDate: {
        startDateObject: moment(),
        startDate: moment().format('LL'),
        sendDateObject: moment(),
        sendDate: moment().format('LL'),
        isLinkActive: sendDateConfiguration === 'start_date',
      },
      termsOfHire: {
        hireState: hireState && hireState.code,
        hireStateId: hireState && hireState.id,
        workState: workState && workState.code,
        workStateId: workState && workState.id,
        employmentClassification: 'w2',
        season: seasons.length === 1 ? seasons[0].id : null,
        hiringStatus: hiringStatus,
        currency: currencies.length === 1 ? currencies[0] : null,
      },
      allowances: {
        boxRentalAllowance,
        carAllowance,
        computerRentalAllowance,
        housingAllowance,
        mobilePhoneAllowance,
        perDiemAllowance,
      },
      accountCodes: getPrePopulatedAccountCodes(),
    });
  }

  return (
    <div className={classes.root}>
      <CrewMembers
        onChange={onChangeCrewMember}
        formData={crew}
        hasOfferBeenValidated={hasOfferBeenValidated}
        classes={{ root: classes.crewMembers }}
      />
      <OfferForm
        crew={crew}
        onSubmit={confirmOffer}
        submitInProgress={submitInProgress}
        performAdditionalValidation={() => {
          // Flag that the offer has been validated so we should show errors.
          setHasOfferBeenValidated(true);
          // Additional validation passes if crew members have been selected.
          return Object.keys(crew).length > 0;
        }}
        formData={formData}
        workScheduleCode={workScheduleValue}
        setFormData={setFormData}
      />
    </div>
  );
};

export default compose(
  withSnackbarNotification,
  withApi,
  graphql(Mutations.SubmitOffer),
  withStyles(styles),
)(CreateOffer);
