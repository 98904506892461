import React, { Fragment } from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { QueryBuilder, Alarm, Warning, CheckCircle } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = {
  blue: {
    color: '#0091FF',
  },
  orange: {
    color: '#FF7800',
  },
  warning: {
    color: '#D92B2E',
  },
  success: {
    color: '#2ECC71',
  },
  icon: {
    display: 'block',
    margin: '0 auto',
  },
  label: {
    margin: '0 auto',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
  },
};

const getINineStatusInfo = props => {
  const {
    props: {
      start_date: startDate = null,
      status,
      i9_document: i9Document = true,
      i9_verified: i9Verified = false,
    },
    classes,
  } = props;
  const { success, blue, orange, warning } = classes || {};

  const isStatusVisible =
    status === 'sent' ||
    status === 'submitted' ||
    status.includes('onboarding');
  if (!isStatusVisible) return null;

  if (!i9Document) {
    return {
      label: 'No I9',
      style: success,
    };
  }

  //Difference in number of days
  const days = moment()
    .startOf('day')
    .diff(startDate, 'days', false);

  if (i9Verified) {
    return {
      icon: CheckCircle,
      label: 'VERIFIED',
      style: success,
    };
  } else if (days < -1) {
    return {
      icon: QueryBuilder,
      label: 'WAITING',
      style: blue,
    };
  } else if (days === -1) {
    return {
      icon: Alarm,
      label: '1 DAY BEFORE START',
      style: orange,
    };
  } else if (days >= 0 && days < 3) {
    let dayCount = 3 - days;
    return {
      icon: Alarm,
      label: `${dayCount}-DAY${dayCount > 1 ? 'S' : ''} LEFT`,
      style: orange,
    };
  } else if (days === 3) {
    return {
      icon: Warning,
      label: 'DUE TODAY',
      style: warning,
    };
  } else if (days >= 4) {
    let daysPast = days - 3;
    return {
      label: `${daysPast}-DAY${daysPast === 1 ? '' : 'S'} PAST DUE`,
      icon: Warning,
      style: warning,
    };
  } else {
    return null;
  }
};

const OfferI9Status = props => {
  const {
    props: { id },
    classes,
  } = props;
  const { label, style, icon: Icon } = getINineStatusInfo(props) || {};

  if (!label) return null;

  return (
    <Fragment>
      {Icon && (
        <Icon
          data-test-id="Shared-StatusIcon-icon"
          className={classNames(style, classes.icon)}
        />
      )}
      <Typography
        className={[classes.label, style]}
        variant="caption"
        data-test-id={`Shared-INineReviewStatus-label-${id}`}
      >
        {label}
      </Typography>
    </Fragment>
  );
};

export default withStyles(styles)(OfferI9Status);
