import React from 'react';

import {
  TableFooter as MuiTableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import { DEFAULT_PER_PAGE } from 'common/oldJavascripts/config.js';

const ProjectTemplatesFooter = props => {
  const { amendRouterQuery, length, getRouterQueryParam } = props;

  const rowsPerPage = getRouterQueryParam('perPage', DEFAULT_PER_PAGE);
  const page = getRouterQueryParam('page', 1) - 1;

  return (
    <MuiTableFooter>
      <TableRow>
        <TablePagination
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(__, page) => amendRouterQuery({ page: page + 1 })}
          onChangeRowsPerPage={ev =>
            amendRouterQuery({ perPage: ev.target.value, page: 1 })
          }
          data-input="table-pagination"
          rowsPerPageOptions={[5, 10, 20]}
          backIconButtonProps={{ 'data-test-id': 'TableFooter-prev' }}
          nextIconButtonProps={{ 'data-test-id': 'TableFooter-next' }}
        />
      </TableRow>
    </MuiTableFooter>
  );
};

export default withRouteHelpers(ProjectTemplatesFooter);
