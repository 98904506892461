import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Layout
import ProjectLayout from 'common/oldJavascripts/components/Pages/Layouts/Project';

// Studio Admins
import AddCrewMember from 'studio/components/AddCrewMember';
import CrewList from 'studio/components/ManageCrew';
import ImportCrew from 'studio/oldJavascripts/components/Pages/Project/Crew/Import';

export default props => {
  return (
    <ProjectLayout>
      <Switch>
        <Route
          exact
          path="/projects/:projectId/crew/new"
          component={AddCrewMember}
        />
        <Route
          exact
          path="/projects/:projectId/crew/import"
          component={ImportCrew}
        />
        <Route component={CrewList} />
      </Switch>
    </ProjectLayout>
  );
};
