import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Components
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';

import Loader from 'common/components/Loader';

// Icons
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
  root: {},
  checkIcon: {
    color: theme.palette.secondary.main,
  },
});

const CrewSearch = ({ onChange, isLoading, value = '', classes = {} }) => {
  const endAdornment = () => {
    if (value === '') return null;
    if (isLoading)
      return (
        <InputAdornment position="end">
          <Loader size={15} />
        </InputAdornment>
      );
    return (
      <InputAdornment position="end">
        <CheckIcon className={classes.checkIcon} />
      </InputAdornment>
    );
  };
  return (
    <FormControl
      fullWidth
      className={classes.root}
      data-test-id="CrewSearch-root"
    >
      <InputLabel htmlFor="input-with-icon-textfield">{'Search'}</InputLabel>
      <Input
        id="input-with-icon-textfield"
        value={value}
        onChange={onChange}
        autoComplete="off"
        inputProps={{
          'data-test-id': 'CrewSearch-input',
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        endAdornment={endAdornment()}
      />
    </FormControl>
  );
};

export default withStyles(styles)(CrewSearch);
