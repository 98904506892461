import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: { height: '61px', maxHeight: '61px', minHeight: '61px', width: '100%' },
};

const LayoutHeader = props => {
  const { classes, children } = props;
  return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(LayoutHeader);
