import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Utilities
import colorFromInt from 'common/utilities/colorFromInt';
import classNames from 'classnames';
import sortByCoords from 'common/utilities/sortByCoords';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  button: {
    position: 'absolute',
    textAlign: 'left',
    justifyContent: 'flex-start',
    padding: `${theme.spacing.unit * 0.8}px`,
  },
  radioButtonContainer: {
    position: 'absolute',
    width: 0,
    height: 0,
  },
  requiredField: {
    border: `2px solid ${palette.primary}`,
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
});

const RadioButtonFields = props => {
  const {
    radioItems,
    displayIcon,
    displayColor,
    tooltipMessage,
    disabled,
    buttonAction,
    classes,
    isRequired,
    displayName,
    id,
    drawerFieldId,
    drawerOpen,
    contColorIdx = 0,
  } = props;
  const isFieldInFieldDrawer = id === drawerFieldId && drawerOpen;
  const buttonClasses = classNames(
    {
      [classes.requiredField]: isRequired,
    },
    classes.button,
  );
  const radioButtons = sortByCoords(radioItems, 'asc').map(
    (radioButton, idx) => {
      const nextContColorIdx = contColorIdx + idx;
      const backgroundColor = isFieldInFieldDrawer
        ? { background: colorFromInt(nextContColorIdx) }
        : displayColor;
      const {
        y: radioY,
        x: radioX,
        height: radioHeight,
        width: radioWidth,
      } = radioButton;
      const radioDynamicStyle = {
        top: radioY + radioHeight / 1.5,
        left: radioX,
        height: radioHeight,
        width: radioWidth,
        minWidth: radioWidth,
        minHeight: radioHeight,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '50%',
        ...backgroundColor,
      };

      const tooltipTitle = disabled
        ? tooltipMessage
        : displayName || 'Not Available';

      return (
        <Tooltip
          title={tooltipTitle}
          placement="top"
          key={`FieldIconButton-radioButtonTooltip-${id}-${radioX}-${radioY}`}
          data-test-id={`FieldIconButton-radioButtonTooltip-${nextContColorIdx}-${radioX}/${radioY}`}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            disableRipple
            className={buttonClasses}
            style={radioDynamicStyle}
            onClick={() => buttonAction(id)}
            key={`FieldIconButton-radioButton-${id}-${radioY}-${radioX}`}
            data-test-id={`FieldIconButton-radioButton-${nextContColorIdx}-${radioY}/${radioX}`}
            data-tooltip-title={tooltipTitle}
          >
            {displayIcon}
          </Button>
        </Tooltip>
      );
    },
  );
  return <div className={classes.radioButtonContainer}>{radioButtons}</div>;
};

export default withStyles(styles)(RadioButtonFields);
