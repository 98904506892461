import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import T from 'common/oldJavascripts/utils/i18n';
import Loader from 'common/components/Loader';
import RemoveUser from 'admin/oldJavascripts/components/Pages/Project/RemoveUser';

import { Link } from 'react-router-dom';

// Mui Components
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

export default Relay.createClass({
  statics: {
    mutators: {
      nonEmployee: {
        info: function(params, related) {
          var routerParams = related['/router/params'];

          return {
            id: [
              '/projects',
              routerParams.projectId,
              'non-employees',
              params.id,
            ].join('/'),
          };
        },
      },
      projectUsers: {
        info(params, related) {
          const routerParams = related['/router/params'];

          return {
            id: `/projects/${routerParams.projectId}/non-employees`,
          };
        },
      },
    },
  },

  getInitialState: function() {
    return {
      isNonEmployeeModalOpen: false,
    };
  },

  render: function() {
    const {
      nonEmployee: nonEmployeeQuery = {},
      queryParams = {},
      routerParams = {},
      invalidateByPatterns,
      projectUsers,
    } = this.props;
    const { status: nonEmployeeStatus, data: nonEmployee } = nonEmployeeQuery;

    if (nonEmployeeStatus === 'loading') {
      return (
        <ListItem>
          <Loader />
        </ListItem>
      );
    }

    const editParams = { ...routerParams, userId: queryParams.id };
    const { isNonEmployeeModalOpen } = this.state;

    return (
      <React.Fragment>
        {this._renderSuccess(nonEmployee, editParams, T)}
        {isNonEmployeeModalOpen && (
          <RemoveUser
            nonEmployeesQuery={projectUsers}
            handleCloseModal={this.handleNonEmployeeDeleteModal}
            deletedNonEmployee={nonEmployee}
            projectId={routerParams.projectId}
            invalidateByPatterns={invalidateByPatterns}
          />
        )}
      </React.Fragment>
    );
  },

  handleNonEmployeeDeleteModal(nonEmployee) {
    this.setState(prevState => ({
      isNonEmployeeModalOpen: !prevState.isNonEmployeeModalOpen,
    }));
  },

  _renderSuccess(nonEmployee, editParams, T) {
    const { id, first_name, last_name } = nonEmployee;
    return (
      <ListItem key={id} divider>
        <ListItemText primary={`${first_name + ' ' + last_name}`} />
        <ListItemSecondaryAction>
          <Link
            to={`/projects/${editParams.projectId}/users/${editParams.userId}/edit`}
          >
            <IconButton
              aria-label="Edit"
              data-test-id={`NonEmployeeListItem-edit-${id}`}
            >
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton
            aria-label="Delete"
            data-test-id={`NonEmployeeListItem-delete-${id}`}
          >
            <DeleteIcon onClick={this.handleNonEmployeeDeleteModal} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  },
});
