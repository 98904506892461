import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Layouts
import ProjectLayout from 'common/oldJavascripts/components/Pages/Layouts/Project';

// Components
// Implementation Admins
import UserList from 'admin/oldJavascripts/components/Pages/Project/Users';
import UserEdit from 'admin/oldJavascripts/components/Pages/Project/User/Edit';
import Invitations from 'admin/oldJavascripts/components/Pages/Project/Invitations';
import NewInvitation from 'admin/oldJavascripts/components/Pages/Project/Invitations/New';

// Studio Admins
import Offers from 'studio/oldJavascripts/components/Pages/Project/Offers';
import Approvals from 'studio/oldJavascripts/components/Pages/Project/Approvals';
import Reviews from 'studio/oldJavascripts/components/Pages/Project/Reviews';
import DeleteReview from 'studio/oldJavascripts/components/Pages/Project/Reviews/Delete';
import I9Verifications from 'studio/oldJavascripts/components/Pages/Project/I9Verifications';
import OfferDocuments from 'studio/oldJavascripts/components/Pages/Project/OffersDocuments';
import OffersDocumentsDetails from 'studio/oldJavascripts/components/Pages/Project/OffersDocuments/Details';
import DownloadAllDocuments from 'studio/components/DownloadAllDocuments';

export default props => {
  return (
    <ProjectLayout>
      <Switch>
        <Route
          exact
          path="/projects/:projectId/files/:fileId"
          component={DownloadAllDocuments}
        />
        <Route
          exact
          path="/projects/:projectId/invitations"
          component={Invitations}
        />
        <Route
          exact
          path="/projects/:projectId/invitations/new"
          component={NewInvitation}
        />
        <Route exact path="/projects/:projectId/users" component={UserList} />
        <Route
          exact
          path="/projects/:projectId/users/:userId/edit"
          component={UserEdit}
        />
        <Route exact path="/projects/:projectId/offers" component={Offers} />
        <Route
          exact
          path="/projects/:projectId/approvals"
          component={Approvals}
        />
        <Route exact path="/projects/:projectId/reviews" component={Reviews} />
        <Route
          exact
          path="/projects/:projectId/reviews/:reviewId/delete"
          component={DeleteReview}
        />
        <Route
          exact
          path="/projects/:projectId/i9-verifications"
          component={I9Verifications}
        />
        <Route
          exact
          path="/projects/:projectId/offer-documents"
          component={OfferDocuments}
        />
        <Route
          exact
          path="/projects/:projectId/offer-documents/:offerId/details"
          component={OffersDocumentsDetails}
        />
      </Switch>
    </ProjectLayout>
  );
};
