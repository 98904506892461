import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import collection from 'common/oldJavascripts/utils/collection.js';
import T from 'common/oldJavascripts/utils/i18n';
import Form from 'common/oldJavascripts/components/Shared/Form';
import ReimbursementsTable from 'common/oldJavascripts/components/Shared/ReimbursementsTable';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import Link from 'common/oldJavascripts/components/Base/Link';
import I9Details from './Details/I9';
import AdditionalDetails from 'admin/components/ProjectSettings/AdditionalDetails';

export default Relay.createClass({
  render() {
    const { project, states, onSaveFailure } = this.props;

    let projectErrors = project.errors;
    if (!collection.isArray(projectErrors)) {
      projectErrors = [];
    }

    return (
      <Form mutator={project} onError={onSaveFailure}>
        <AdditionalDetails project={project} states={states} />
        <I9Details project={project} states={states} />
        <ReimbursementsTable
          data={project.data}
          errors={projectErrors}
          onChange={this.props.onChangeReimbursements}
          onSaveFailure={onSaveFailure}
        />
        <BoxItem>
          <NavBar>
            <NavBar.Item>
              <Form.Button mutator={project} data-test-id="Details-saveButton">
                {T('form.actions.save')}
              </Form.Button>
            </NavBar.Item>
            <NavBar.Item>
              <Link onClick={this.props.onCancel}>
                {T('form.actions.cancel')}
              </Link>{' '}
              {T('form.actions.or')}
            </NavBar.Item>
          </NavBar>
        </BoxItem>
      </Form>
    );
  },
});
