import orderBy from 'lodash.orderby';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import classNames from 'classnames';
import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';

const styles = {
  root: {
    width: '100%',
  },
  header: {
    background: '#e9eaed',
    height: 40,
    paddingLeft: 24,
    paddingRight: 56,
    display: 'grid',
    gridTemplateColumns: '45px 1fr 1.5fr',
  },
  headerCell: {
    alignSelf: 'center',
  },
  summaryContent: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '45px 1fr 1.5fr',
  },
  summaryCell: {
    alignSelf: 'center',
  },
  noOffers: {
    cursor: 'auto !important',
  },
  checkbox: {
    padding: 0,
  },
  columnHeader: {
    cursor: 'pointer',
  },
  statusBadge: {
    width: 120,
  },
};

const GoLiveDialogUserList = props => {
  const { checkedUserIds, classes, onChangeCheckedUsers, users } = props;
  const isAllUsersChecked = checkedUserIds.length === users.length;

  const [userOrder, setUserOrder] = useState({
    field: 'name',
    direction: 'asc',
  });
  const derivedUsers = users.map(user => {
    const {
      profile: { email, firstName, middleName, lastName },
    } = user;
    const name = [firstName, middleName, lastName].join(' ');
    return { ...user, email, name };
  });
  const sortedUsers = orderBy(
    derivedUsers,
    userOrder.field,
    userOrder.direction,
  );

  const toggleUser = id => {
    const newCheckedUserIds = checkedUserIds.includes(id)
      ? checkedUserIds.filter(x => x !== id)
      : checkedUserIds.concat(id);
    onChangeCheckedUsers(newCheckedUserIds);
  };

  const selectAllUsers = isSelected => {
    const newCheckedUserIds = isSelected ? users.map(({ id }) => id) : [];
    onChangeCheckedUsers(newCheckedUserIds);
  };

  const toggleUserOrder = field => {
    let direction = 'asc';
    if (userOrder.field === field) {
      direction = userOrder.direction === 'asc' ? 'desc' : 'asc';
    }
    setUserOrder({ field, direction });
  };

  const getUser = user => {
    const { email, id, name, offers } = user;
    const hasOffers = !!offers.length;
    const expansionPanelProps = hasOffers ? {} : { expanded: false };
    const summaryClass = classNames({ [classes.noOffers]: !hasOffers });
    return (
      <ExpansionPanel {...expansionPanelProps} key={id}>
        <ExpansionPanelSummary
          className={summaryClass}
          expandIcon={
            hasOffers && (
              <ExpandMoreIcon
                data-test-id={`GoLiveDialogUserList-expand-${id}`}
              />
            )
          }
        >
          <div className={classes.summaryContent}>
            <div className={classes.summaryCell}>
              <Checkbox
                className={classes.checkbox}
                checked={checkedUserIds.includes(id)}
                onClick={ev => {
                  toggleUser(id);
                  ev.preventDefault();
                  ev.stopPropagation();
                }}
                data-test-id={`GoLiveDialogUserList-checkbox-${id}`}
              />
            </div>
            <div className={classes.summaryCell}>
              <Typography data-test-id={`GoLiveDialogUserList-name-${id}`}>
                {name}
              </Typography>
            </div>
            <div className={classes.summaryCell}>
              <Typography data-test-id={`GoLiveDialogUserList-email-${id}`}>
                {email}
              </Typography>
            </div>
          </div>
        </ExpansionPanelSummary>
        {hasOffers && (
          <ExpansionPanelDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Job Title</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offers.map(({ id, jobTitle, status }) => (
                  <TableRow key={id}>
                    <TableCell
                      data-test-id={`GoLiveDialogUserList-offerJobTitle-${id}`}
                    >
                      {jobTitle}
                    </TableCell>
                    <TableCell
                      data-test-id={`GoLiveDialogUserList-offerStatus-${id}`}
                    >
                      <OfferStatus
                        status={status}
                        className={classes.statusBadge}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>
    );
  };

  const sortArrow = field => {
    if (userOrder.field !== field) return '';
    return userOrder.direction === 'asc' ? ' ↑' : ' ↓';
  };

  const getHeader = () => (
    <div className={classes.header}>
      <div className={classes.headerCell}>
        <Checkbox
          className={classes.checkbox}
          checked={isAllUsersChecked}
          onClick={ev => selectAllUsers(ev.target.checked)}
        />
      </div>
      <div className={classes.headerCell}>
        <span
          className={classes.columnHeader}
          onClick={() => toggleUserOrder('name')}
        >
          Name
          {sortArrow('name')}
        </span>
      </div>
      <div className={classes.headerCell}>
        <span
          className={classes.columnHeader}
          onClick={() => toggleUserOrder('email')}
        >
          Email
          {sortArrow('email')}
        </span>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {getHeader()}
      {sortedUsers.map(getUser)}
    </div>
  );
};

export default withStyles(styles)(GoLiveDialogUserList);
