import React, { useState } from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { withRouter } from 'react-router';

// Components;
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import MergeAccounts from 'admin/components/MergeAccounts';
import UserAccessPage from 'admin/components/UserAccessReport/UserAccess';
import UserProjectsList from 'admin/components/UserProjectList';
import Box from 'common/oldJavascripts/components/Base/Box';

// MuiComponents
import { Paper, Tabs, Tab } from '@material-ui/core';

const styles = {
  topMargin: {
    marginTop: 16,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#30aba3',
  },
  container: {
    padding: 16,
  },
};

const ToolsTab = classes => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, value) => setActiveTab(value);

  return (
    <LayoutContent>
      <Box>
        <Paper elevation={0}>
          <Tabs
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleTabChange}
            value={activeTab}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Merge Accounts"
              data-test-id="ToolsTab-mergeAccountsTab"
            />
            <Tab
              label="User Access Report"
              data-test-id="ToolsTab-userAccessReportTab"
            />
            <Tab
              label="Projects by User"
              data-test-id="ToolsTab-userProjectsList"
            />
          </Tabs>
        </Paper>

        <Paper elevation={0} className={classes.container}>
          {activeTab === 0 && <MergeAccounts />}
          {activeTab === 1 && <UserAccessPage />}
          {activeTab === 2 && <UserProjectsList />}
        </Paper>
      </Box>
    </LayoutContent>
  );
};

export default compose(withRouter, withStyles(styles))(ToolsTab);
