import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';

const unionEmployeeInfoStyles = makeStyles(theme => ({
  title: {
    color: '#999999',
    fontSize: 12,
    fontWeight: 400,
  },
  sectionTitle: {
    color: '#646464',
    fontSize: 16,
    paddingTop: 10,
    lineHeight: '16px',
  },
  name: {
    fontSize: 24,
    fontWeight: 400,
    color: '#646464',
  },
  date: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 400,
  },
  email: {
    fontSize: 10,
    color: '#0091ff',
    fontWeight: 400,
    marginTop: '3px',
    letterSpacing: '0.3px',
  },
  spacing: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  amount: {
    color: '#2eaba4',
    fontSize: 24,
    fontWeight: 400,
    marginRight: '10px',
  },
  description: {
    wordBreak: 'break-word',
  },
  flag: {
    display: 'block',
    margin: 'auto',
    width: 24,
    height: 24,
  },
  caption: {
    color: '#aaaaaa',
    fontSize: 10,
    fontWeight: 400,
    padding: 0,
    textAlign: 'center',
  },
  scaleCaption: {
    fontSize: 10,
    color: '#AAAAAA',
    position: 'relative',
    top: -14,
  },
  topSpacing: {
    marginTop: 20,
  },
  scaleCopy: {
    color: '#8D8D8D',
    fontSize: 10,
  },
  divider: {
    position: 'absolute',
    bottom: 162,
    width: '10%',
    marginLeft: 41,
  },
}));

const UnionEmployeeTerms = ({ offer }) => {
  const classes = unionEmployeeInfoStyles();
  const { terms_of_employment = {} } = offer;
  const {
    rate_per_hour_studio: rate,
    rate_per_hour_location: rateDistant,
    guaranteed_hours_studio: guaranteedHours,
    guaranteed_hours_location: guaranteedHoursDistant,
    pay_6th_day_rate: guaranteedHours6thDay,
    pay_7th_day_rate: guaranteedHours7thDay,
    pay_6th_day_rate_location: guaranteedHours6thDayDistant,
    pay_7th_day_rate_location: guaranteedHours7thDayDistant,
    pay_idle_days_distant: payIdleDaysDistant,
    pay_gold_at: payGoldAt,
    pay_gold_at_distant: payGoldAtDistant,
    pay_at_scale: payAtScale,
    pay_at_scale_distant: payAtScaleDistant,
    idle_at_scale_distant: idleAtScaleDistant,
    gold_at_scale: goldAtScale,
    gold_at_scale_distant: goldAtScaleDistant,
  } = terms_of_employment;

  return (
    <>
      <Typography
        className={[classes.topSpacing, classes.sectionTitle].join(' ')}
        data-test-id={`UnionEmployeeInfo-studioTitle-${offer?.id}`}
      >
        Studio
      </Typography>
      <Grid container className={classes.spacing}>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`UnionEmployeeInfo-hourlyRateTitle-${offer?.id}`}
          >
            Hourly Rate
          </Typography>
          <Typography
            className={classes.amount}
            data-test-id={`UnionEmployeeInfo-hourlyRate-${offer?.id}`}
          >
            {currency(rate)} <br />{' '}
            {payAtScale && (
              <span className={classes.scaleCaption}>*Scale Rate</span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`UnionEmployeeInfo-hoursPerDayTitle-${offer?.id}`}
          >
            Hours Per Day
          </Typography>
          <Typography
            className={classes.amount}
            data-test-id={`UnionEmployeeInfo-hoursPerDay-${offer?.id}`}
          >
            {guaranteedHours}
          </Typography>
        </Grid>
        {guaranteedHours6thDay && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`UnionEmployeeInfo-sixthDayPayTitle-${offer?.id}`}
            >
              Hours Apply to 6th
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`UnionEmployeeInfo-sixthDayPayValue-${offer?.id}`}
            >
              {guaranteedHours6thDay ? 'Yes' : 'No'}
            </Typography>
          </Grid>
        )}
        {guaranteedHours7thDay && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`UnionEmployeeInfo-seventhDayPay-${offer?.id}`}
            >
              Hours Apply to 7th
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`UnionEmployeeInfo-seventhDayPayValue`}
            >
              {guaranteedHours7thDay ? 'Yes' : 'No'}
            </Typography>
          </Grid>
        )}
        {payGoldAt && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`UnionEmployeeInfo-payGoldAtTitle-${offer?.id}`}
            >
              Gold Pay
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`UnionEmployeeInfo-payGoldAt-${offer?.id}`}
            >
              {currency(payGoldAt)}
              <br />{' '}
              {goldAtScale && (
                <span className={classes.scaleCaption}>*Scale Rate</span>
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Typography
        className={[classes.topSpacing, classes.sectionTitle].join(' ')}
        data-test-id={`UnionEmployeeInfo-DistantTitle-${offer?.id}`}
      >
        Distant
      </Typography>
      <Grid container className={classes.spacing}>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`UnionEmployeeInfo-hourlyRateDistantTitle-${offer?.id}`}
          >
            Hourly Rate
          </Typography>
          <Typography
            className={classes.amount}
            data-test-id={`UnionEmployeeInfo-hourlyRateDistant-${offer?.id}`}
          >
            {currency(rateDistant)} <br />{' '}
            {payAtScaleDistant && (
              <span className={classes.scaleCaption}>*Scale Rate</span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`UnionEmployeeInfo-hourlyPerDayDistantTitle-${offer?.id}`}
          >
            Hours Per Day
          </Typography>
          <Typography
            className={classes.amount}
            data-test-id={`UnionEmployeeInfo-guaranteedHoursDistant-${offer?.id}`}
          >
            {guaranteedHoursDistant}
          </Typography>
        </Grid>
        {guaranteedHours6thDayDistant && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`UnionEmployeeInfo-sixthDayPayTitle-${offer?.id}`}
            >
              Hours Apply to 6th
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`UnionEmployeeInfo-sixthDayPayValue-${offer?.id}`}
            >
              {guaranteedHours6thDayDistant ? 'Yes' : 'No'}
            </Typography>
          </Grid>
        )}
        {guaranteedHours7thDayDistant && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`UnionEmployeeInfo-seventhDayPayTitle-${offer?.id}`}
            >
              Hours Apply to 7th
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`UnionEmployeeInfo-seventhDayPayValue-${offer?.id}`}
            >
              {guaranteedHours7thDayDistant ? 'Yes' : 'No'}
            </Typography>
          </Grid>
        )}
        {payIdleDaysDistant && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`UnionEmployeeInfo-payIdleDaysDistantTitle-${offer?.id}`}
            >
              Idle Days Pay
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`UnionEmployeeInfo-idleDaysDistant-${offer?.id}`}
            >
              {currency(payIdleDaysDistant)}
              <br />{' '}
              {idleAtScaleDistant && (
                <span className={classes.scaleCaption}>*Scale Rate</span>
              )}
            </Typography>
          </Grid>
        )}
        {payGoldAtDistant && (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              className={classes.title}
              data-test-id={`UnionEmployeeInfo-goldPayTitle-${offer?.id}`}
            >
              Gold Pay
            </Typography>
            <Typography
              className={classes.amount}
              data-test-id={`UnionEmployeeInfo-goldPayDistant-${offer?.id}`}
            >
              {currency(payGoldAtDistant)} <br />{' '}
              {goldAtScaleDistant && (
                <span className={classes.scaleCaption}>*Scale Rate</span>
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Typography className={classes.scaleCopy}>
        *Scale rate shown is as of offer creation date. Scale rate is subject to
        change upon union negotiated increases.
      </Typography>
    </>
  );
};
export default UnionEmployeeTerms;
