import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  backIcon: {
    color: '#2eaba4',
    backgroundColor: '#ffffff',
    height: '24px',
    width: '24px',
    borderRadius: 50,
  },
  backTitle: {
    fontSize: 14,
    fontWeight: 300,
    textDecoration: 'none',
    color: '#646464',
  },
}));

const BackButton = ({ title = 'Back', url = `/`, isExternal = false }) => {
  const classes = useStyles();
  const BackIcon = () => (
    <IconButton>
      <ChevronLeft className={classes.backIcon} />{' '}
    </IconButton>
  );
  if (isExternal) {
    return (
      <a href={url} className={classes.backTitle}>
        <BackIcon />
        {title}
      </a>
    );
  }
  return (
    <Link to={url} className={classes.backTitle}>
      <BackIcon />
      {title}
    </Link>
  );
};

export default BackButton;
