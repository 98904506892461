import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '3rem',
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: 18,
  },
  wrapper: {
    '& > div:not(:last-child)': {
      borderBottom: '0.1px solid #2eaba4',
    },
  },
  commentContainer: {
    backgroundColor: '#f5fcfb',
    padding: 20,
  },
  comment: {
    color: '#6d7278',
    fontSize: 16,
    fontWeight: 400,
    marginTop: 10,
  },
  date: {
    color: '#2eaba4',
    fontFamily: 'Helvetica Neue',
    fontSize: 15,
    fontWeight: 500,
    marginRight: 10,
  },
  jobTitle: {
    color: '#ff7800',
    fontFamily: 'Helvetica Neue',
    fontSize: 15,
    fontWeight: 500,
  },
  commentRowTitle: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  noNotes: {
    fontSize: '18px',
    color: '#999999',
    textAlign: 'center',
  },
}));

const AdditionalDealTerms = props => {
  const {
    notes = null,
    old_notes: oldNotes = [],
    sent_at: noteCreatedAt,
  } = props?.offerData;
  const { first_name: hirerFirstName, last_name: hirerLastName } = props?.hirer;
  const previousNotes = [...oldNotes].reverse();
  const classes = useStyles();
  const isEmptyNotes = !notes && oldNotes.length === 0;
  const hirerName = `${hirerFirstName} ${hirerLastName}`;
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        Additional Deal Terms / Comments
      </Typography>
      <div className={classes.wrapper}>
        {isEmptyNotes && (
          <Typography className={classes.noNotes}>
            No Additional Notes
          </Typography>
        )}
        {!isEmptyNotes && (
          <div className={classes.commentContainer}>
            <div className={classes.commentRowTitle}>
              <Typography className={classes.date}>
                {moment(noteCreatedAt).format('M/D/YYYY')} - {hirerName}
              </Typography>
              <Typography className={classes.jobTitle}>
                Hiring Manager
              </Typography>
            </div>
            <Typography className={classes.comment}>{notes}</Typography>
          </div>
        )}

        {!isEmptyNotes &&
          previousNotes.map(note => (
            <div className={classes.commentContainer}>
              <div className={classes.commentRowTitle}>
                <Typography className={classes.date}>
                  {moment(note.notes_updated_at).format('M/D/YYYY')}-{' '}
                  {hirerName}
                </Typography>
                <Typography className={classes.jobTitle}>
                  Hiring Manager
                </Typography>
              </div>
              <Typography className={classes.comment}>{note.notes}</Typography>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AdditionalDealTerms;
