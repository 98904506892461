import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { FormControl, Input, Menu } from '@material-ui/core';
import { ListItem, ListSubheader } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

const styles = theme => ({
  menuHeader: {
    justifyContent: 'center',
    padding: `0 0 ${theme.spacing.unit / 2}px 0`,
    outline: 'none',
  },
  filterControl: {
    padding: 0,
    width: 300,
    height: 'auto',
    outline: 'none',
  },
  formControlFilter: {
    width: '100%',
    margin: theme.spacing.unit,
    outline: 'none',
  },
  searchBar: {
    position: 'absolute',
    top: 0,
    outline: 'none',
  },
  optionMenu: {
    overflow: 'hidden',
    paddingBottom: 20,
  },
  menuSection: {
    overflowX: 'scroll',
    marginTop: 50,
    height: 400,
    width: 300,
  },
  header: {
    outline: 'none',
  },
});

const MultiLevelMenu = props => {
  const {
    anchor,
    classes,
    closeMenu,
    filterOptions,
    filteredOptions = [],
    selectSystemField,
  } = props;
  const buildMenuSection = menuObject => {
    const { label, key, items } = menuObject;
    const categories = items.map(item => (
      <MenuItem
        key={`system-field-${item.id || item.name || item.label}`}
        onClick={() => selectSystemField(item)}
        data-test-id={`MultiLevelMenu-menuItem-${item.id}`}
      >
        {item.label}
      </MenuItem>
    ));
    return (
      <div key={`system-field-section-${key || label}`}>
        <ListSubheader
          disableSticky
          className={classes.header}
          data-test-id={`MultiLevelMenu-ListSubHeader-${label}`}
        >
          {label}
        </ListSubheader>
        {categories}
      </div>
    );
  };
  const menuSection = filteredOptions.map(category =>
    buildMenuSection(category),
  );
  return (
    <Menu
      id="system-fields-selection-menu"
      anchorEl={anchor}
      open={!!anchor}
      onClose={closeMenu}
      data-test-id="system-fields-selection-menu"
      classes={{ paper: classes.optionMenu }}
    >
      <div className={classes.searchBar}>
        <ListItem
          className={classes.filterControl}
          button={false}
          divider
          disableGutters
        >
          <FormControl fullWidth className={classes.formControlFilter}>
            <Input
              id="filter"
              onChange={filterOptions}
              autoFocus={true}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              placeholder="Filter"
              inputProps={{
                'data-test-id': 'MultiLevelMenu-searchInput',
              }}
            />
          </FormControl>
        </ListItem>
      </div>
      <div className={classes.menuSection}>{menuSection}</div>
    </Menu>
  );
};

export default withStyles(styles)(MultiLevelMenu);
