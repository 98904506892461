import React from 'react';
import StartDate from './StartDate';
import SendDateLink from './SendDateLink';
import SendDate from './SendDate';
import SendDateLinkHelp from './SendDateLinkHelp';
import EndDate from './EndDate';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'class-names';

const styles = theme => ({
  root: {
    display: 'grid',
    gridAutoColumns: 'max-content',
    gap: '0px 10px',
    gridTemplateRows: 'max-content 10px 1fr',
    width: '100%',
  },
  header: {
    gridColumn: 1,
    gridRow: 1,
  },
  startDate: {
    gridColumn: 1,
    gridRow: 3,
  },
  sendDateLink: {
    gridColumn: 2,
    gridRow: 3,
    placeSelf: 'end',
  },
  sendDateLinkHelp: {
    gridColumn: 4,
    gridRow: 3,
  },
  sendDate: {
    gridColumn: 3,
    gridRow: 3,
  },
  endDate: {
    gridColumn: 4,
    gridRow: 3,
  },

  fieldContainer: {
    width: '100%',
  },
  fieldLabel: {
    fontSize: '.9rem',
    padding: '5px',
  },

  field: {
    backgroundColor: 'hsl(0,0%,100%)',
    borderColor: 'hsl(0,0%,80%)',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    minHeight: '38px',
    minWidth: '250px',
    fontSize: '13px',
    padding: '0px 8px',
    '&:disabled': {
      background: 'hsl(0,0%,95%)',
      color: 'rgb(153, 153, 153)',
    },
  },
  error: {
    color: 'red',
    fontSize: '0.9rem',
    width: 250,
  },
});

const OfferDate = props => {
  const {
    onChange,
    formData = {},
    classes = {},
    formErrors,
    offerDefaults: { allowEndDate, backdateLimit, sendDateConfiguration },
  } = props || {};
  const isSendOnStartDefault = sendDateConfiguration === 'start_date';

  const selectProps = className => ({
    onChange,
    formData,
    classes: {
      root: classNames(classes.fieldContainer, className),
      label: classes.fieldLabel,
      field: classes.field,
      error: classes.error,
    },
    formErrors,
    sendDateConfiguration,
  });

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant="h5">
        Offer Date
      </Typography>
      <StartDate
        {...selectProps(classes.startDate)}
        backdateLimit={backdateLimit}
      />
      {isSendOnStartDefault && (
        <SendDateLink {...selectProps(classes.sendDateLink)} />
      )}
      <SendDate
        {...selectProps(classes.sendDate)}
        helpIcon={isSendOnStartDefault && <SendDateLinkHelp />}
      />
      {allowEndDate && <EndDate {...selectProps(classes.endDate)} />}
    </div>
  );
};

export default withStyles(styles)(OfferDate);
