import React from 'react';

// HoC
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

// Components
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import AccountCodeTableHead from './AccountCodeTableHead';
import AccountCodeTableBody from './AccountCodeTableBody';

const styles = theme => ({
  root: {
    display: 'grid',
  },
  table: {
    gridRow: 2,
    gridColumn: 1,
    padding: 25,
    tableLayout: 'fixed',
  },
  icon: {
    height: '14px',
    width: 'auto',
  },
  disabledCell: {
    background: 'hsl(0,0%,95%)',
    color: 'rgb(153, 153, 153)',
  },
  headerCell: {
    fontSize: '1.1125rem',
    padding: '5px !important',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerCellContainer: {
    display: 'grid',
    height: ' 100%',
    placeContent: 'center',
    gridAutoFlow: 'column',
  },
  requiredFlag: {
    paddingLeft: '5px',
  },
  labelCell: {
    fontSize: '1.0125rem',
    padding: '5px !important',
  },
  errorCell: {
    borderLeft: `2px solid ${theme.palette.error.main}`,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  errorLabel: {
    paddingLeft: 8,
    fontSize: '1rem',
  },
  border: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  cellWithTextField: {
    padding: '5px !important',
  },
  textField: {
    width: '100%',
  },
  textFieldInputContainer: {
    '&:before': {
      border: 'none',
    },
    '&:after': {
      borderBottomColor: '#27a9ae',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `2px solid #27a9ae !important`,
    },
  },
  textFieldInput: {
    textAlign: 'center',
  },
});

const AccountCodeTable = props => {
  const {
    formData = {},
    formErrors = [],
    onChange,
    classes = {},
    accountCodeConfigurations = [],
    accountCodes = [],
    onClose,
    enforceMask,
    canEditAccountCodes,
  } = props || {};

  const {
    // Terms Of Employment
    // Union Table
    studioRatePerWeek,
    studioRatePerHour,
    locationRatePerWeek,
    locationRatePerHour,
    idleLocationSixthDayRatePerHour,
    idleLocationSeventhDayRatePerHour,
    // Exempt Table
    dailyRate,
    weeklyRate,
    // Default Table
    ratePerHour,
    rate,
    rateDistant,
    // Allowances
    boxRentalAllowance = {},
    carAllowance = {},
    computerRentalAllowance = {},
    housingAllowance = {},
    mobilePhoneAllowance = {},
    perDiemAllowance = {},
  } = formData || {};

  const rowKeys = [
    {
      lineItemKey: 'studio',
      label: 'Studio',
      display: () => studioRatePerWeek > 0 || studioRatePerHour > 0 || rate > 0,
    },
    {
      lineItemKey: 'location',
      label: 'Location',
      display: () =>
        locationRatePerWeek > 0 ||
        locationRatePerHour > 0 ||
        idleLocationSixthDayRatePerHour > 0 ||
        idleLocationSeventhDayRatePerHour > 0 ||
        rateDistant > 0,
    },
    {
      lineItemKey: 'hourly',
      label: 'Hourly Rate',
      display: () => ratePerHour > 0,
    },
    { lineItemKey: 'daily', label: 'Daily Rate', display: () => dailyRate > 0 },
    {
      lineItemKey: 'weekly',
      label: 'Weekly Rate',
      display: () => weeklyRate > 0,
    },
    {
      lineItemKey: 'perDiem',
      label: 'Per Diem',
      display: () => perDiemAllowance.amount,
    },
    {
      lineItemKey: 'box',
      label: 'Box Rental',
      display: () => boxRentalAllowance.amount,
    },
    {
      lineItemKey: 'car',
      label: 'Car Allowance',
      display: () => carAllowance.amount,
    },
    {
      lineItemKey: 'computer',
      label: 'Computer Rental',
      display: () => computerRentalAllowance.amount,
    },
    {
      lineItemKey: 'mobile',
      label: 'Mobile Phone Allowance',
      display: () => mobilePhoneAllowance.amount,
    },
    {
      lineItemKey: 'housing',
      label: 'Housing Allowance',
      display: () => housingAllowance.amount,
    },
  ];

  const rowKeysToDisplay = rowKeys.filter(({ display }) => display());

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        Account Codes
        <IconButton
          onClick={onClose}
          data-test-id="AccountCodeTable-closeButton"
        >
          <CloseIcon />
        </IconButton>
      </Typography>
      {rowKeysToDisplay.length === 0 && (
        <Typography className={classes.root} variant="h4">
          The account code table will be available after entering a value for
          any monetary line items for this offer.
        </Typography>
      )}
      {rowKeysToDisplay.length > 0 && (
        <Table className={classes.table}>
          <AccountCodeTableHead
            accountCodeConfigurations={accountCodeConfigurations}
            classes={classes}
          />
          <AccountCodeTableBody
            formData={formData}
            formErrors={formErrors}
            onChange={onChange}
            classes={classes}
            accountCodeConfigurations={accountCodeConfigurations}
            accountCodes={accountCodes}
            enforceMask={enforceMask}
            canEditAccountCodes={canEditAccountCodes}
          />
        </Table>
      )}
    </div>
  );
};

export default compose(withRouter, withStyles(styles))(AccountCodeTable);
