import React from 'react';
import { Switch, Route } from 'react-router-dom';
import OfferLayout from 'onboarding/oldJavascripts/components/Pages/Layouts/Offer';
import OffersList from 'onboarding/components/OffersList';
import OfferHelp from 'onboarding/oldJavascripts/components/Pages/Offer/Help';
import ProfileIntro from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/Intro/Profile';
import Profile from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/Profile';
import Details from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/Details';
import SupportingDocuments from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/SupportingDocuments';
import I9 from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/I9';
import Sign from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/Documents';
import OnboardingComplete from 'onboarding/components/Onboarding/Complete';

const getIsOnboarding = pathname =>
  ['/submitted', '/opted-out', '/help'].every(s => !pathname.endsWith(s));

export default props => {
  return (
    <Switch>
      <Route
        path="/onboarding/offers/:offerId"
        render={({ location: { pathname } }) => (
          <OfferLayout isOnboarding={getIsOnboarding(pathname)}>
            <Switch>
              <Route
                exact
                path="/onboarding/offers/:offerId/intro/profile"
                component={ProfileIntro}
              />
              <Route
                exact
                path="/onboarding/offers/:offerId/profile"
                component={Profile}
              />
              <Route
                exact
                path="/onboarding/offers/:offerId/details"
                component={Details}
              />
              <Route
                exact
                path="/onboarding/offers/:offerId/supporting-documents"
                component={SupportingDocuments}
              />
              <Route
                exact
                path="/onboarding/offers/:offerId/i9"
                component={I9}
              />
              <Route
                exact
                path="/onboarding/offers/:offerId/documents"
                component={Sign}
              />
              <Route
                exact
                path="/onboarding/offers/:offerId/complete"
                component={OnboardingComplete}
              />
              <Route
                exact
                path="/onboarding/offers/:offerId/submitted"
                component={Details}
              />
              <Route
                exact
                path="/onboarding/offers/:offerId/opted-out"
                component={Details}
              />
              <Route
                path="/onboarding/offers/:offerId/help"
                component={OfferHelp}
              />
            </Switch>
          </OfferLayout>
        )}
      />
      <OfferLayout>
        <Route component={OffersList} />
      </OfferLayout>
    </Switch>
  );
};
