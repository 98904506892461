import React from 'react';

// MaterialUI Components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

// Custom Components
import ProgressSpinner from 'common/components/ProgressSpinner';

const DiscardChangesDialog = props => {
  const { onClose, isOpen, discardChanges, isDiscardingChanges } = props;
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>Are you sure you want to discard changes?</DialogTitle>
      <DialogContent>{`Any unsaved changes will be discarded and the document will be reverted to the previously saved configuration.`}</DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          data-test-id={'DiscardChangesDialog-cancelButton'}
        >
          Cancel
        </Button>
        <Button
          onClick={discardChanges}
          variant="contained"
          disabled={isDiscardingChanges}
          data-test-id={'DiscardChangesDialog-discardButton'}
        >
          Discard Changes
          {isDiscardingChanges && (
            <ProgressSpinner size={24} isDiscardingChanges />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardChangesDialog;
