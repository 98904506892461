import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import * as palette from '../shared/oldDocumentSignerUI/palette';
import DialogButton from '../DialogButton';

const styles = theme => ({
  root: {
    placeItems: 'center',
    display: 'grid',
    width: '100%',
    gridGap: '2%',
    gap: '2%',
    gridTemplateColumns: 'repeat(3, 32%)',
  },
  buttonBase: {
    flex: 1,
    marginLeft: 5,
    width: '100%',
  },
  label: {
    color: theme.palette.primary.contrastText,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '.73rem',
  },
  inverseLabel: {
    color: palette.black,
  },
  save: {
    backgroundColor: 'white',
    gridColumn: '1',
  },
  accept: {
    gridColumn: '2 ',
  },
  cancel: {
    gridColumn: '3',
    marginRight: 5,
  },
  documents: {
    display: 'inline',
    fontSize: '0.8em',
    fontWeight: 'bold',
    '& li': {
      float: 'left',
      display: 'inline',
      paddingRight: '10px',
    },
    '& li:before': {
      content: '"• "',
    },
  },
  confirmationCancelText: {
    textTransform: 'none',
    fontStyle: 'italic',
    textDecoration: 'underline',
  },
  confirmationConfirmButton: {
    background: '#536881',
    color: theme.palette.primary.contrastText,
  },
  confirmationConfirmText: {
    textTransform: 'none',
  },
});

const ActionButtons = props => {
  const {
    actions,
    actionButtonClick,
    classes,
    groupedAcknowledgmentDocumentNames,
    isOfferComplete,
    templatesAcknowledgeNotes,
    templatesAcknowledgeTitle,
  } = props;

  return (
    <div className={classes.root}>
      {actions.save && (
        <DialogButton
          color="tertiary"
          variant="contained"
          className={classNames(classes.buttonBase, classes.save)}
          buttonClasses={{
            label: classNames(classes.label, classes.inverseLabel),
          }}
          onClick={() => actionButtonClick('save')}
          dialogType="alert"
          dialogTitle="Your documents have been saved"
          dialogBody="Please note: Your offer is not complete and will not be submitted for approval until you complete and sign all offer documents. You can login and resume the process."
          data-test-id="ActionButtons-saveButton"
        >
          {actions.save.text}
        </DialogButton>
      )}
      {actions.submit && (
        <DialogButton
          color="primary"
          variant="contained"
          className={classNames(classes.buttonBase, classes.accept)}
          buttonClasses={{ label: classes.label }}
          disabled={!isOfferComplete}
          onClick={() => actionButtonClick('submit')}
          dialogType={
            groupedAcknowledgmentDocumentNames.length && 'confirmation'
          }
          dialogTitle={templatesAcknowledgeTitle}
          dialogBody={
            <span>
              <Typography>{templatesAcknowledgeNotes}</Typography>
              <ul className={classes.documents}>
                {groupedAcknowledgmentDocumentNames.map(name => (
                  <li>{name}</li>
                ))}
              </ul>
            </span>
          }
          dialogCancelButton={
            <Button fullWidth>
              <span className={classes.confirmationCancelText}>
                On second thought, let me review those again...
              </span>
            </Button>
          }
          dialogConfirmButton={
            <Button
              fullWidth
              color="primary"
              variant="contained"
              className={classes.confirmationConfirmButton}
            >
              <span className={classes.confirmationConfirmText}>
                I acknowledge receipt of the aforementioned documents
              </span>
            </Button>
          }
          data-test-id="ActionButtons-submitButton"
        >
          {actions.submit.text}
        </DialogButton>
      )}
      {actions.cancel && (
        <Button
          color="secondary"
          variant="contained"
          className={classNames(classes.buttonBase, classes.cancel)}
          classes={{ label: classes.label }}
          onClick={() => actionButtonClick('cancel')}
          data-test-id="ActionButtons-cancelButton"
        >
          {actions.cancel.text}
        </Button>
      )}
    </div>
  );
};

export default withStyles(styles)(ActionButtons);
