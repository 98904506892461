import React from 'react';

// CONSTANTS
import { FETCH_MORE_NETWORK_STATUS } from 'common/constants/graphqlNetworkStatus/networkStatus';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreButton: {
    padding: '11px 16px',
    margin: '0 0 20px 0',
    background: '#FFFFFF',
    border: '1px solid #30ABA3',
    color: '#30ABA3',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
  },
  loadingIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    color: '#30ABA3',
  },
}));

const FetchMoreCrewMembers = props => {
  const { crewPageInfo, searchTerm, fetchMore, networkStatus } = props;

  const classes = useStyles();

  const loadMoreCrewMemebers = () => {
    const { endCursor } = crewPageInfo;
    fetchMore({
      variables: {
        after: endCursor,
        search: searchTerm,
      },
      updateQuery: (prevResults, { fetchMoreResult }) => {
        const { project: fetchMoreProject = {} } = fetchMoreResult || {};
        const {
          crew: {
            edges: fetchMoreEdges = [],
            pageInfo: fetchMorePageInfo = {},
          } = {},
        } = fetchMoreProject || {};
        if (!fetchMoreEdges.length) return prevResults;
        return {
          ...prevResults,
          project: {
            ...prevResults.project,
            crew: {
              ...prevResults.project.crew,
              edges: [
                ...prevResults.project.crew.edges,
                ...fetchMoreEdges.filter(
                  ({ node: { id: crewId } }) =>
                    !prevResults.project.crew.edges.some(
                      ({ node: { id: prevId } }) => prevId === crewId,
                    ),
                ),
              ],
              pageInfo: { ...fetchMorePageInfo },
            },
          },
        };
      },
    });
  };

  return (
    <div className={classes.buttonContainer}>
      <Button
        className={classes.loadMoreButton}
        data-test-id={`Projects-load-more-button`}
        onClick={loadMoreCrewMemebers}
      >
        Load More
        {networkStatus === FETCH_MORE_NETWORK_STATUS && (
          <CircularProgress size="sm" className={classes.loadingIcon} />
        )}
      </Button>
    </div>
  );
};

export default FetchMoreCrewMembers;
