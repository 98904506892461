import { useQuery } from '@apollo/react-hooks';
import { Queries } from 'common/apollo';

const useDepartments = (
  { projectId, occupation, union },
  { skip = false } = {},
) => {
  const { loading, data } = useQuery(Queries.GetDepartments, {
    skip,
    variables: {
      // NOTE Project.id is listed in the schema as an ID! type, but the project
      // query takes a projectId of type Int! Obviously, this is wrong.
      projectId: parseInt(projectId),
      occupation,
      union,
    },

    // NOTE: I don't know why this is needed. Without it, when skip goes from
    // true to false, the query gets stuck on loading forever. It seems like
    // this might be a bug in react-apollo, see this thread for more info:
    // https://github.com/apollographql/react-apollo/issues/3270#issuecomment-522244995
    notifyOnNetworkStatusChange: true,
  });
  return { loading, data: data && data.departments };
};

export default useDepartments;
