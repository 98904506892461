import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import classNames from 'class-names';

const styles = {
  extraMargin: {
    marginRight: 8,
  },
  sendImmediatelyLabel: {
    color: '#828385',
  },
  root: {},
  label: {},
  field: {},
  datePicker: {
    display: 'flex',
    alignItems: 'center',
  },
};

const SendDate = props => {
  const {
    classes,
    onChange: upstreamOnChange,
    formData: { isLinkActive, sendDateObject } = {},
    helpIcon,
    formErrors: { sendDate: sendDateError } = {},
    sendDateConfiguration,
  } = props;
  const isSendImmediately = sendDateConfiguration === 'immediately';
  const isSendWhenever = sendDateConfiguration === null;
  const isSendOnStartAlways = sendDateConfiguration === 'always_start_date';

  const onChange = date =>
    upstreamOnChange({
      sendDate: (date && date.format('LL')) || null,
      sendDateObject: date,
    });

  return (
    <div
      className={classNames(classes.root, {
        [classes.extraMargin]: isSendImmediately || isSendWhenever,
      })}
    >
      <Typography className={classes.label} variant="body1">
        Send Date
      </Typography>
      <div data-test-id="SendDate-root">
        {isSendImmediately ? (
          <div>
            <DatePicker
              value="Immediately"
              className={classes.field}
              disabled={true}
            />
            <Typography
              variant="body1"
              data-test-id="SendDate-immediatelyLabel"
            >
              Send date pre-configured
            </Typography>
          </div>
        ) : (
          <div className={classes.datePicker}>
            <DatePicker
              todayButton={'Today'}
              selected={sendDateObject}
              onChange={onChange}
              className={classes.field}
              disabled={isSendOnStartAlways || isLinkActive}
              minDate={moment()}
            />
            {helpIcon}
          </div>
        )}
        {sendDateError && (
          <Typography
            className={classes.error}
            variant="caption"
            data-test-id="SendDate-error"
          >
            {sendDateError}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(SendDate);
