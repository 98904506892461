import * as Sentry from '@sentry/browser';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { REQUEST } from 'common/store/actions/actionTypes';

import {
  getSessionCheckSuccess,
  AUTHENTICATION_STATUS,
  logout as requestLogout,
  LOGOUT,
  REDIRECT_SSO,
} from 'common/store/actions/authenticate';

import { SESSION_STATUS } from 'common/store/actions/session';

function* checkSession(auth) {
  try {
    const session = yield call(auth.checkSession);
    if (session) {
      yield put(getSessionCheckSuccess());
    } else {
      yield put(requestLogout({ fromURI: 'href' }));
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function* redirectToSSO(auth) {
  try {
    yield call(auth.redirectToSSO);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function* logout(auth, { withFromURI, fromURI } = {}) {
  try {
    yield call(auth.oktaLogout);
    yield call(auth.oktaLogoutMyCnC);
  } catch (e) {
    // Suppress Errors.  Errors here mean that okta session was already killed
  }
  try {
    yield call(auth.cncLogout, { withFromURI, fromURI });
  } catch (e) {
    Sentry.captureException(e);
  }
}

export default function* authenticationFlow({ auth, history }) {
  yield all([
    takeEvery(REQUEST(AUTHENTICATION_STATUS), checkSession, auth),
    takeEvery(REQUEST(SESSION_STATUS), checkSession, auth),
    takeEvery(REQUEST(LOGOUT), logout, auth),
    takeEvery(REQUEST(REDIRECT_SSO), redirectToSSO, auth),
  ]);
}
