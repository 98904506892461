import React from 'react';
import moment from 'moment';
import Select from './Select';
import collection from '../../utils/collection.js';
var today = new Date();
var todayYear = today.getFullYear();
var YEAR_RANGE_UP = 20;
var YEAR_RANGE_DOWN = 100;
var NULL_VALUE = '';
var parseValue = null;
var formatSelectValue = null;
var getSelectRanges = null;
var Datepicker = null;

formatSelectValue = function(value) {
  if (!value) {
    return NULL_VALUE;
  }
  return value < 10 ? '0' + value : value.toString();
};

parseValue = function(value) {
  var day = null;
  var month = null;
  var year = null;
  var date = null;
  var splittedDate = null;

  if (!value && value !== '') {
    return null;
  }

  if (moment.isDate(value)) {
    date = moment(value);
    day = date.date();
    month = date.month() + 1;
    year = date.year();
  } else {
    splittedDate = value.split('-');
    day = Number(splittedDate[2]) || 0;
    month = Number(splittedDate[1]) || 0;
    year = Number(splittedDate[0]) || 0;
  }

  return {
    day: day,
    month: month,
    year: year,
    date: day && month && year && new Date(year, month, day),
  };
};

getSelectRanges = function(valueNow, options) {
  var valueLimitBefore = parseValue(options.limitBefore);
  var valueLimitAfter = parseValue(options.limitAfter);
  var days = [];
  var months = [];
  var years = [];
  var yearFrom = options.yearFrom;
  var yearTo = options.yearTo;
  var monthFrom = 1;
  var monthTo = 12;
  var dayFrom = 1;
  var dayTo = new Date(valueNow.year, valueNow.month, 0).getDate();

  if (valueLimitBefore) {
    if (yearTo > valueLimitBefore.year) {
      yearTo = valueLimitBefore.year;
    }

    if (valueNow.year === valueLimitBefore.year) {
      monthTo = valueLimitBefore.month;

      if (valueNow.month === valueLimitBefore.month) {
        dayTo = valueLimitBefore.day;
      }
    }
  }

  if (valueLimitAfter) {
    if (yearFrom < valueLimitAfter.year) {
      yearFrom = valueLimitAfter.year;
    }

    if (valueNow.year === valueLimitAfter.year) {
      monthFrom = valueLimitAfter.month;

      if (valueNow.month === valueLimitAfter.month) {
        dayFrom = valueLimitAfter.day;
      }
    }
  }

  // Bound check to default (non-value)
  if (valueNow.day > dayTo || valueNow.day < dayFrom) {
    valueNow.day = 0;
  }
  if (valueNow.month > monthTo || valueNow.month < monthFrom) {
    valueNow.month = 0;
  }
  if (valueNow.year > yearTo || valueNow.year < yearFrom) {
    valueNow.year = 0;
  }

  collection.eachInRange(dayFrom, dayTo + 1, function(day) {
    var value = formatSelectValue(day);
    days.push({ label: value, value: value });
  });

  collection.eachInRange(monthFrom, monthTo + 1, function(month) {
    var value = formatSelectValue(month);
    months.push({ label: value, value: value });
  });

  // default behavior is to reverse list years
  yearFrom = [yearTo, (yearTo = yearFrom)][0];
  // yearTo - 1 (unlike others) because we reverse list years
  collection.eachInRange(yearFrom, yearTo - 1, function(year) {
    var value = formatSelectValue(year);
    years.push({ label: value, value: value });
  });

  if (options.yearOrder === 'asc') {
    years.reverse();
  }

  return {
    days: days,
    months: months,
    years: years,
  };
};

Datepicker = class extends React.Component {
  static defaultProps = {
    yearsUp: YEAR_RANGE_UP,
    yearsOrder: 'desc',
  };

  render() {
    var value = parseValue(this.props.value || '');
    var selectRanges = null;

    selectRanges = getSelectRanges(value, {
      yearFrom: todayYear - YEAR_RANGE_DOWN,
      yearTo: todayYear + this.props.yearsUp,
      limitBefore: this.props.limitBefore,
      limitAfter: this.props.limitAfter,
      yearOrder: this.props.yearsOrder,
    });

    return (
      <div className="datepicker">
        <Select
          options={selectRanges.months}
          value={formatSelectValue(value.month)}
          onChange={this._changeMonth}
          className="datepicker-month"
          placeholder="MM"
          searchable={true}
          noResultsText={''}
          isInvalid={this.props.isInvalid}
          disabled={this.props.disabled}
        />
        <span className="datepicker-separator">—</span>
        <Select
          id={this.props.id}
          options={selectRanges.days}
          value={formatSelectValue(value.day)}
          onChange={this._changeDay}
          className="datepicker-day"
          placeholder="DD"
          searchable={true}
          noResultsText={''}
          isInvalid={this.props.isInvalid}
          disabled={this.props.disabled}
        />
        <span className="datepicker-separator">—</span>
        <Select
          options={selectRanges.years}
          value={formatSelectValue(value.year)}
          onChange={this._changeYear}
          className="datepicker-year"
          placeholder="YYYY"
          searchable={true}
          noResultsText={''}
          isInvalid={this.props.isInvalid}
          disabled={this.props.disabled}
        />
      </div>
    );
  }

  _changeYear = year => {
    this._changeSlice(0, year);
  };

  _changeMonth = month => {
    this._changeSlice(1, month);
  };

  _changeDay = day => {
    this._changeSlice(2, day);
  };

  _changeSlice = (index, slice) => {
    var slices = (this.props.value || '').split('-');

    if (this.props.onChange) {
      slices[index] = slice;
      this.props.onChange(slices.join('-'));
    }
  };
};

export default Datepicker;
