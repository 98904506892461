import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay';
import ajax from 'common/utilities/ajax.js';
import DocumentMetaData from 'admin/components/DocumentMetaData';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser';

const CLOSE_AFTER_SAVE_INTERVAL = 1500;

const DocumentMetaDataWrapper = Relay.createClass({
  getInitialState() {
    return {
      error: [],
      isSaving: false,
      saveSuccess: false,
    };
  },

  updateDocument(
    id,
    name,
    expireOn,
    newTags = [],
    existingTags = [],
    deletedTags = [],
  ) {
    const formattedDeletedTags = deletedTags.map(tag => ({
      id: tag.id,
      name: tag.name,
      delete: true,
    }));
    const documentTags = [...existingTags, ...newTags, ...formattedDeletedTags];
    const url = `/workflow/templates/${id}/edit`;
    const payload = { payload: { name, expireOn, categories: documentTags } };
    this.setState({ isSaving: true });
    ajax
      .put(url, payload, { json: true })
      .then(this.handleSuccess)
      .catch(this.handleError);
  },

  handleSuccess(response) {
    this.setState({ error: [], isSaving: false, saveSuccess: true });
    const { handleSubmit: upstreamHandleSubmit } = this.props;
    upstreamHandleSubmit(response);
    setTimeout(this.handleCancel, CLOSE_AFTER_SAVE_INTERVAL);
  },

  handleError(error) {
    const parsedError = parseErrorMessages(error);
    this.setState({ error: parsedError, isSaving: false, saveSuccess: false });
  },

  handleCancel() {
    const { handleCancel: upstreamHandleCancel } = this.props;
    this.setState(this.getInitialState(), upstreamHandleCancel);
  },

  render() {
    const { isOpen, name, expireOn, id, tags } = this.props;
    const { error, isSaving, saveSuccess } = this.state;
    const formattedTags = tags.map(tag => ({ id: tag.id, name: tag.name }));
    return (
      <div>
        {isOpen ? (
          <DocumentMetaData
            isOpen={isOpen}
            name={name}
            expireOn={expireOn}
            tags={formattedTags}
            id={id}
            handleCancel={this.handleCancel}
            handleSubmit={this.updateDocument}
            error={error}
            isSaving={isSaving}
            saveSuccess={saveSuccess}
          />
        ) : (
          <span />
        )}
      </div>
    );
  },
});

export default DocumentMetaDataWrapper;
