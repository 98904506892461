import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'class-names';

const styles = {
  root: {
    textDecoration: 'none',
  },
  icon: {
    color: '#7FCC2A',
    fontSize: '21px',
    verticalAlign: 'sub',
    marginLeft: '-5px',
    marginRight: '5px',
  },
};

const ContactLink = props => {
  const { classes, className, phone } = props;
  return (
    <a href={'tel:' + phone} className={classNames(classes.root, className)}>
      <PhoneIcon className={classes.icon} />
      {phone}
    </a>
  );
};

export default withStyles(styles)(ContactLink);
