import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  dropzoneContainer: {
    border: '2px dashed',
    borderRadius: '3px',
    borderColor: palette.lightGrey,
    backgroundColor: theme.palette.common.white,
    textAlign: 'center',
    height: theme.spacing.unit * 9,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    color: 'rgba(0, 0, 0, 0)',
  },
  largeIcon: {
    height: theme.spacing.unit * 4,
  },
  primaryButton: {
    marginLeft: theme.spacing.unit,
  },
  overlayStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.divider,
    textAlign: 'center',
    color: 'inherit',
  },
  error: {
    color: theme.palette.error.main,
  },
});

class DocumentDropzone extends Component {
  static defaultProps = {
    multiple: true,
    accept: 'application/pdf',
  };

  state = {
    dropzoneActive: false,
    dropzoneRef: null,
  };

  onDragEnter = () => {
    this.setState({
      dropzoneActive: true,
    });
  };

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false,
    });
  };

  onDrop = files => {
    const { uploadHandler, multiple } = this.props;
    this.setState({
      dropzoneActive: false,
    });
    if (files.length > 0) {
      uploadHandler(multiple ? files : files[0]);
    }
  };

  render() {
    const { classes, multiple, accept } = this.props;
    const { dropzoneActive } = this.state;
    let { dropzoneRef } = this.state;
    return (
      <Dropzone
        className={classes.dropzoneContainer}
        accept={accept}
        multiple={multiple}
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        disableClick
        ref={node => {
          dropzoneRef = node;
        }}
        rejectClassName={classes.error}
      >
        <div>
          {dropzoneActive && (
            <Typography className={classes.overlayStyle}>
              Please drop PDF files only
            </Typography>
          )}

          <Typography color="textSecondary">
            <span>Drag & Drop File Here or</span>
            <Button
              size="small"
              className={classes.primaryButton}
              color="primary"
              variant="contained"
              data-test-id="doc-dropzone-open-file-selector"
              onClick={() => {
                dropzoneRef.open();
              }}
            >
              + Add File From Computer
            </Button>
          </Typography>
        </div>
      </Dropzone>
    );
  }
}

export default withStyles(styles)(DocumentDropzone);
