import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'class-names';
import domClasses from '../../utils/dom/classes.js';
import domIsDescendant from '../../utils/dom/isDescendant.js';
import collection from '../../utils/collection.js';
import Icon from './Icon';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class List extends React.Component {
  state = {
    placeholderIndex: null,
  };

  render() {
    var self = this;
    var children = this.props.children;
    var classes = null;
    var placeholder = null;

    if (this.props.sortable) {
      children = collection.map(children, function(child, index) {
        return (
          <div
            draggable="true"
            key={child.key}
            onDragEnd={self._dragEnd}
            data-index={index}
            onMouseDown={self._mouseDown}
            onDragStart={self._dragStart}
            onDragOver={self._dragOver}
          >
            {child}
          </div>
        );
      });

      if (this.state.placeholderIndex !== null) {
        placeholder = (
          <List.Item
            highlighted={true}
            ref="placeholder"
            key={'placeholder-' + this.state.placeholderIndex}
          >
            <List.ItemContent>...</List.ItemContent>
          </List.Item>
        );
        children.splice(this.state.placeholderIndex, 0, placeholder);
      }
    } else {
      children = (
        <ReactCSSTransitionGroup
          component="div"
          transitionName="anim_rowExpand"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {children}
        </ReactCSSTransitionGroup>
      );
    }

    classes = domClasses.set({
      list: true,
      'list--secondary': this.props.secondary,
      'list--noBorder': this.props.noBorder,
    });

    return (
      <div className={classes} ref="container">
        {children}
      </div>
    );
  }

  _mouseDown = event => {
    this.correctClick = domIsDescendant(event.target, function(node) {
      return domClasses.contains(node, 'list-itemHandle');
    });
  };

  _dragEnd = event => {
    var oldPosition = this.draggedIndex;
    var newPosition = this.state.placeholderIndex;

    event.stopPropagation();

    if (newPosition > oldPosition) {
      newPosition -= 1;
    }

    this.props.onUpdate(oldPosition, newPosition);

    this.setState({ placeholderIndex: null });
    this.draggedIndex = undefined;
    this.correctClick = undefined;
  };

  _dragStart = event => {
    if (!this.correctClick) {
      event.preventDefault();
      return;
    }

    event.stopPropagation();

    this.draggedIndex = Number(event.currentTarget.dataset.index);

    event.dataTransfer.effectAllowed = 'move';

    // Firefox requires calling dataTransfer.setData
    // for the drag to properly work
    event.dataTransfer.setData('text/html', null);
  };

  _dragOver = event => {
    var over = event.currentTarget;
    var toIndex = Number(over.dataset.index);

    event.preventDefault();

    if (!isFinite(this.draggedIndex)) {
      return;
    }

    this.setState({ placeholderIndex: toIndex });
  };
}

const itemStyles = {
  root: {
    zoom: '1',
    borderBottom: '1px solid #e6e7ea',
    fontWeight: '700',
    position: 'relative',
    overflow: 'inherit',
    '&:before': { content: '" "', display: 'table' },
    '&:after': { content: '" "', display: 'table', clear: 'both' },
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': { background: '#ffffff' },
  },
  highlighted: {
    fontSize: '0.76923077em',
    background: '#fafafa',
    color: '#979ca5',
    textTransform: 'uppercase',
  },
  success: { background: '#ecf9dc' },
  warning: { background: '#fff9f2' },
  primary: {
    lineHeight: '1.92307692',
    background: '#e6e7ea',
    borderBottom: '1px solid #c8c8c8',
  },
  secondary: {
    lineHeight: '1.92307692',
  },
  noBoarder: {
    border: ['none !important', 'none'],
  },
  expanded: {
    overflow: 'hidden',
  },
};

export const Item = withStyles(itemStyles)(props => {
  const {
    classes,
    onClick,
    highlighted,
    success,
    warning,
    primary,
    secondary,
    noBorder,
    expanded,
    children,
  } = props;
  const rootClass = classNames(classes.root, {
    [classes.clickable]: !!onClick,
    [classes.highlighted]: highlighted,
    [classes.success]: success,
    [classes.primary]: primary,
    [classes.secondary]: secondary,
    [classes.noBorder]: noBorder,
    [classes.expanded]: expanded,
    [classes.warning]: warning,
  });
  return (
    <div className={rootClass} onClick={() => !!onClick && onClick()}>
      {children}
    </div>
  );
});

const itemContentStyles = {
  root: {
    WebkitTransition: 'margin-right 0.5s ease-in-out 0s',
    MozTransition: 'margin-right 0.5s ease-in-out 0s',
    msTransition: 'margin-right 0.5s ease-in-out 0s',
    transition: 'margin-right 0.5s ease-in-out 0s',
    float: 'left',
    padding: '12px 15px',
    width: '100%',
  },
  clickable: {
    cursor: 'pointer',
  },
  minimal: { padding: '6px 12px 6px 16px' },
  noIndent: { paddingLeft: '0' },
  weak: { fontWeight: 'normal' },
  shortHeight: { height: 40 },
  alignCenter: { display: 'flex', alignItems: 'center' },
  minPadding: { padding: 10 },
};

export const ItemContent = withStyles(itemContentStyles)(props => {
  const {
    classes,
    onClick,
    minimal,
    noIndent,
    weak,
    children,
    shortHeight,
    alignCenter,
    minPadding,
    className,
    'data-test-id': dataTestId,
  } = props;
  const rootClass = classNames(
    classes.root,
    {
      [classes.clickable]: !!onClick,
      [classes.minimal]: minimal,
      [classes.noIndent]: noIndent,
      [classes.weak]: weak,
      [classes.shortHeight]: shortHeight,
      [classes.alignCenter]: alignCenter,
      [classes.minPadding]: minPadding,
    },
    className,
  );
  return (
    <div
      className={rootClass}
      onClick={() => !!onClick && onClick()}
      data-test-id={dataTestId}
    >
      {children}
    </div>
  );
});

const itemContentBodyStyles = {
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    float: 'left',
    width: '70%',
  },
  full: { width: '100%' },
};

export const ItemContentBody = withStyles(itemContentBodyStyles)(props => {
  const { full, title, children, className, classes } = props;
  const rootClass = classNames(
    classes.root,
    {
      [classes.full]: full,
    },
    className,
  );
  return (
    <div className={rootClass} title={title}>
      {children}
    </div>
  );
});

const itemNavStyles = {
  root: { float: 'left', textAlign: 'right', width: '30%' },
  nowrap: { whiteSpace: 'nowrap' },
  highlighted: { color: '#469fcc' },
};

export const ItemNav = withStyles(itemNavStyles)(props => {
  const { classes, children, nowrap, highlighted } = props;
  const rootClass = classNames(classes.root, {
    [classes.nowrap]: nowrap,
    [classes.highlighted]: highlighted,
  });
  return <div className={rootClass}>{children}</div>;
});

const itemButtonsStyles = {
  root: {
    WebkitTransition: 'width 0.5s ease-in-out 0s',
    MozTransition: 'width 0.5s ease-in-out 0s',
    msTransition: 'width 0.5s ease-in-out 0s',
    transition: 'width 0.5s ease-in-out 0s',
    float: 'left',
    overflow: 'hidden',
    width: '0',
  },
};

export const ItemButtons = withStyles(itemButtonsStyles)(props => {
  const { children, classes } = props;
  return <div className={classes.root}>{children}</div>;
});

const itemButtonStyles = {
  root: {
    WebkitTransition: 'width 0.5s ease-in-out 0s',
    MozTransition: 'width 0.5s ease-in-out 0s',
    msTransition: 'width 0.5s ease-in-out 0s',
    transition: 'width 0.5s ease-in-out 0s',
    float: 'left',
    overflow: 'hidden',
    width: '0',
  },
  destructive: { background: '#ea5a5a' },
};

export const ItemButton = withStyles(itemButtonStyles)(props => {
  const { classes, destructive, onClick, children } = props;
  const rootClass = classNames(classes.root, {
    [classes.destructive]: destructive,
  });

  return (
    <div className={rootClass} onClick={() => !!onClick && onClick()}>
      {children}
    </div>
  );
});

const itemHandleStyles = {
  root: {
    cursor: 'pointer',
    fontSize: '15px',
    left: '0',
    position: 'absolute',
    top: '8px',
  },
};

export const ItemHandle = withStyles(itemHandleStyles)(props => {
  const { classes } = props;

  return (
    <span className={classes.root}>
      <Icon name="drag-handler" />
    </span>
  );
});

const itemSectionStyles = {
  root: {
    borderBottom: '1px solid #e6e7ea',
    marginBottom: '10px',
    paddingBottom: '10px',
  },
};

export const ItemSection = withStyles(itemSectionStyles)(props => {
  const { children, classes } = props;
  return <div className={classes.root}>{children}</div>;
});

List.Item = Item;
List.ItemContent = ItemContent;
List.ItemContentBody = ItemContentBody;
List.ItemNav = ItemNav;
List.ItemButtons = ItemButtons;
List.ItemButton = ItemButton;
List.ItemHandle = ItemHandle;
List.ItemSection = ItemSection;
export default List;
