import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Material UI
import Typography from '@material-ui/core/Typography';

// Utilities
import classNames from 'class-names';

const styles = {
  icon: {
    color: '#eaeaea',
    height: 140,
    width: 140,
  },
  root: {
    placeSelf: 'center',
    height: '100%',
    width: '100%',
    border: '2px dashed #e3e3e3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  padding: {
    paddingBottom: 32,
    paddingTop: 12,
  },
  text: {
    padding: '8px 48px',
    backgroundColor: '#eaeaea',
    borderRadius: 4,
    color: '#7c8085',
  },
};

const EmptyMessage = ({ classes = {}, icon: MessageIcon, text }) => (
  <Typography
    className={classNames(classes.root, {
      [classes.padding]: !!MessageIcon,
    })}
    variant="h6"
  >
    {MessageIcon && <MessageIcon className={classes.icon} />}
    <span className={classes.text}>{text}</span>
  </Typography>
);

export default withStyles(styles)(EmptyMessage);
