import React, { useEffect, useState } from 'react';
import RateTableInputField from './RateTableInputField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import OVERTIME_MULTIPLIER from '../OVERTIME_MULTIPLIER';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';

const useStyles = makeStyles(theme => ({
  tableRoot: {
    marginTop: 10,
    width: '100%',
  },
  tableTitle: {
    marginTop: 30,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 16,
    color: '#6d7278',
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      input: {
        fontSize: 16,
        fontWeight: 'normal',
        textAlign: 'center',
        width: 196,
        top: 3,
        color: '#525252',
      },
      underline: {
        width: 196,
        color: '#696969',
        '&:before': {
          borderBottom: `1px solid #8D8D8D`,
        },
        '&$focused:not($disabled):before': {
          borderBottom: `2px solid #30ABA3`,
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          borderBottom: `2px solid #30ABA3`,
        },
        '&$error:not($disabled):before': {
          borderBottom: `1px solid #F45656`,
        },
      },
      disabled: {
        '&:before': {
          borderBottom: `1px solid #C6C6C6`,
        },
      },
    },
  },
});

const HOURS_WARNING_MESSAGE =
  'Hours can either be guaranteed per day or per week, not both';
const RateTable = (props = {}) => {
  const classes = useStyles();
  const { formData, workScheduleCode, onChange: upstreamOnChange } = props;
  const {
    overtimeRatePerHour,
    ratePerHour,
    dailyRate,
    weeklyRate,
    guaranteedHours,
    guaranteedHoursFrequency,
  } = formData || {};
  useEffect(() => {
    upstreamOnChange({
      isPrcanRateTable: true,
    });
  }, [workScheduleCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const [overtimeRate, setOvertimeRate] = useState(overtimeRatePerHour);
  const [hoursType, setHoursType] = useState(guaranteedHoursFrequency);
  const [isRatePerWeekWarning, setRatePerWeekWarning] = useState(false);
  const [isRatePerDayWarning, setRatePerDayWarning] = useState(false);
  const [guaranteedValue, setGuaranteedValue] = useState();

  // Updates hoursType because on load, guaranteedHoursFrequency comes in as undefined
  // since state is derived from props
  if (guaranteedHoursFrequency !== hoursType)
    setHoursType(guaranteedHoursFrequency);

  const handleOvertimeRate = typedValue => {
    const rate = typedValue
      ? formatCurrency(typedValue * OVERTIME_MULTIPLIER)
      : null;

    setOvertimeRate(rate);
    upstreamOnChange({
      overtimeRatePerHour: rate,
    });
  };

  const handleHoursType = type => {
    setHoursType(type);
    const isPreviousWeekly = type === 'daily' && guaranteedHours > 0;
    const isPreviousDaily = type === 'weekly' && guaranteedHours > 0;
    upstreamOnChange({
      guaranteedHours: null,
      guaranteedHoursFrequency: type,
    });
    let newValue;
    if (isPreviousWeekly) {
      newValue = isPreviousWeekly ? 'weekly' : null;
    }
    if (isPreviousDaily) {
      newValue = isPreviousDaily ? 'daily' : null;
    }
    if (!newValue) return;
    setGuaranteedValue(newValue);
  };

  const isPerHourAndDay = dailyRate > 0 && ratePerHour > 0;
  const isPerHourAndWeek = weeklyRate > 0 && ratePerHour > 0;

  const handleRateType = type => {
    const isWeeklyRate = type === '/week' && dailyRate > 0;
    const isDailyRate = type === '/day' && weeklyRate > 0;
    setRatePerWeekWarning(isWeeklyRate);
    setRatePerDayWarning(isDailyRate);
  };

  const isHoursPerDay =
    guaranteedHours > 0 && guaranteedHoursFrequency === 'daily';
  const isOnlyDailyRateEntered =
    dailyRate > 0 && !weeklyRate && !ratePerHour && !isHoursPerDay;

  const isHoursPerDayRequiredWarning =
    (isPerHourAndDay && !isHoursPerDay) || isOnlyDailyRateEntered;
  const hoursDailyWarningMessage = isHoursPerDayRequiredWarning
    ? 'Hours per Day is required'
    : null;

  const isHoursPerWeek =
    guaranteedHours > 0 && guaranteedHoursFrequency === 'weekly';
  const isOnlyWeeklyRateEntered =
    weeklyRate > 0 && !dailyRate && !ratePerHour && !isHoursPerWeek;
  const isHoursPerWeekRequiredWarning =
    (isPerHourAndWeek && !isHoursPerWeek) || isOnlyWeeklyRateEntered;
  const hoursWeeklyWarningMessage = isHoursPerWeekRequiredWarning
    ? 'Hours per Week is required'
    : null;

  const rowItems = [
    {
      rowTitle: 'Rate Per Hour',
      rowPaymentType: '/hour',
      currencySymbol: true,
      isTableHeader: true,
      tableTitle: 'Rates',
      rateKey: 'ratePerHour',
      rowRate: ratePerHour,
    },
    {
      rowTitle: 'Rate Per Day',
      rowPaymentType: '/day',
      currencySymbol: true,
      rateKey: 'dailyRate',
      rowRate: dailyRate,
      isWarning: isRatePerDayWarning,
      warningMessage: 'Cannot specify both Rate per Day and Rate per Week',
    },
    {
      rowTitle: 'Rate Per Week',
      rowPaymentType: '/week',
      currencySymbol: true,
      rateKey: 'weeklyRate',
      rowRate: weeklyRate,
      isWarning: isRatePerWeekWarning,
      warningMessage: 'Cannot specify both Rate per Day and Rate per Week',
    },
    {
      rowTitle: 'Hours Per Day',
      rowPaymentType: 'hours/day',
      currencySymbol: false,
      isTableHeader: true,
      tableTitle: 'Guarantees',
      rateKey: 'guaranteedHours',
      errorRateKey: 'hoursPerDay',
      rowRate: hoursType === 'daily' ? guaranteedHours : null,
      isWarning: isHoursPerDayRequiredWarning || guaranteedValue === 'weekly',
      warningMessage: hoursDailyWarningMessage || HOURS_WARNING_MESSAGE,
    },
    {
      rowTitle: 'Hours Per Week',
      rowPaymentType: 'hours/week',
      currencySymbol: false,
      rateKey: 'guaranteedHours',
      errorRateKey: 'hoursPerWeek',
      rowRate: hoursType === 'weekly' ? guaranteedHours : null,
      isWarning: isHoursPerWeekRequiredWarning || guaranteedValue === 'daily',
      warningMessage: hoursWeeklyWarningMessage || HOURS_WARNING_MESSAGE,
    },
    {
      rowTitle: 'Overtime Rate',
      currencySymbol: true,
      isTableHeader: true,
      tableTitle: 'Overtime',
      rateKey: 'overtimeRatePerHour',
      rowRate: overtimeRate || overtimeRatePerHour,
    },
  ];

  return (
    <div className={classes.tableRoot}>
      <MuiThemeProvider theme={theme}>
        {rowItems.map(item => {
          return (
            <>
              {item?.tableTitle && (
                <Typography className={classes.tableTitle}>
                  {item?.tableTitle}
                </Typography>
              )}
              <RateTableInputField
                {...props}
                {...item}
                handleOvertimeRate={handleOvertimeRate}
                hoursType={hoursType}
                handleHoursType={handleHoursType}
                handleRateType={handleRateType}
              />
            </>
          );
        })}
      </MuiThemeProvider>
    </div>
  );
};

export default RateTable;
