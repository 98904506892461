import React from 'react';

// Components
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';

// HoC
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { compose } from 'redux';

// GQL
import { Queries } from 'common/apollo';

// Utilities
import variableTest from '../helpers/variableTest';
import formatOptionLabel from './formatOptionLabel';

const OccupationSelect = props => {
  const {
    onChange: upstreamOnChange,
    formData = {},
    data: {
      occupations = [],
      loading,
      variables: graphqlQueryVariables = {},
    } = {},
    error = '',
    selectStyles,
    classes = {},
    disabled,
  } = props;
  const { occupation } = formData || {};
  const { value: occupationValue } = occupation || {};

  const options = () => {
    // Ensure that the current data is using the most up to data query variables
    if (!variableTest(graphqlQueryVariables, formData)) return [];
    // Format options for react-select
    return occupations.map(({ code, description }) => ({
      value: code,
      label: `${code} - ${description}`,
    }));
  };
  // We only store the code in our formData but we need to supply the formatted option
  // as the selected value
  const value =
    options().find(({ value }) => occupationValue === value) || null;

  const onChange = occupation =>
    upstreamOnChange({
      occupation,
      workSchedule: null,
    });

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Occupation
      </Typography>
      <div data-test-id="OccupationSelect-select">
        <Select
          styles={selectStyles}
          onChange={onChange}
          value={value}
          options={loading ? [] : options()}
          className={classes.select}
          isLoading={loading}
          formatOptionLabel={formatOptionLabel('OccupationSelect')}
          isDisabled={disabled}
        />
      </div>
      {error && (
        <Typography
          color="error"
          variant="caption"
          className={classes.error}
          data-test-id="OccupationSelect-error"
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

const getOccupationConfig = {
  skip: ({
    formData: { workState, hireState, union, startDate, currency } = {},
  }) =>
    workState === null ||
    hireState === null ||
    union === null ||
    !currency ||
    !startDate,
  options: ({
    formData: {
      startDateObject,
      workState,
      hireState,
      workCity,
      hireCity,
      currency,
      union = {},
      occupation,
      season,
    } = {},
    match: { params: { projectId } = {} } = {},
  }) => {
    const { value: occupationCode } = union || {};
    return {
      variables: {
        projectId: parseInt(projectId, 10),
        startDate:
          (startDateObject && startDateObject.format('YYYY-MM-DD')) || '',
        workState,
        hireState,
        workCity,
        hireCity,
        currency,
        union: occupationCode,
        season,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
};

export default compose(
  withRouter,
  graphql(Queries.GetOccupations, getOccupationConfig),
)(OccupationSelect);
