import React, { Component } from 'react';
import Viewer from '@castandcrew/react-viewer';

class DocumentViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 0,
      zoomedIn: false,
    };
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].style = {};
  }

  componentDidUpdate(prevProps, prevState) {
    const props = this.props;
    if (props.onPageChange) {
      const pageIndex = this.state.pageIndex;
      const pages = props.pages;
      if (pageIndex !== prevState.pageIndex || pages !== prevProps.pages) {
        props.onPageChange(pageIndex, pages.length);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({ pageIndex: 0 });
    }
  }

  render() {
    const { pages, onClose } = this.props;
    const interactiveViewPages = pages.map(page => ({
      src: page.url || page,
      alt: `${page.url}` || `${page}`,
    }));
    return (
      <Viewer
        visible={true}
        images={interactiveViewPages}
        onClose={() => onClose()}
        rotatable={false}
        attribute={false}
        scalable={false}
      />
    );
  }
}

export default DocumentViewer;
