import React from 'react';
import withSizes from 'react-sizes';
import { MAX_MOBILE_WIDTH } from 'mobile/constants';
import TermsOfEmploymentMobile from './TermsOfEmploymentMobile';
import TermsOfEmploymentDesktop from './TermsOfEmploymentDesktop';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import isNonUnionWorkScheduleA from 'common/utilities/isNonUnionWorkScheduleA';
import useOfferCountry from 'common/components/SpecifiedCountryOnly/useOfferCountry';
import isUnionWeeklyScheduleCode from 'common/utilities/isUnionWeeklySchedule';

const TermsOfEmployment = props => {
  const { offerData, isMobile } = props;

  const terms = offerData?.terms_of_employment || {};
  const isNonUnion = offerData?.union_is_non_union;
  const workScheduleCode = offerData?.work_schedule_code;
  const flags = useFeatureFlags();
  const { data: offerCountry } = useOfferCountry({ offerId: offerData?.id });
  const isCanada = offerCountry?.code === 'CA';
  const isNonUnionScheduleAFlagActive = flags.includes('nonUnionSchedules');
  const isNonUnionScheduleCFlagActive = flags.includes('nonUnionScheduleC');
  const isNonUnionScheduleA =
    isNonUnion &&
    isNonUnionScheduleAFlagActive &&
    isNonUnionWorkScheduleA(workScheduleCode);
  const isNonUnionScheduleC =
    isNonUnion &&
    isNonUnionScheduleCFlagActive &&
    ['C', 'E', 'CN'].includes(workScheduleCode);
  const isNonUnionScheduleD =
    isNonUnion && isNonUnionScheduleCFlagActive && workScheduleCode === 'D';
  const isUnionScheduleA = !isNonUnion && workScheduleCode === 'A';
  const isUnionWeeklyScheduleFlagActive = flags.includes('UnionWeeklySchedule');
  const isUnionWeeklySchedule =
    isUnionWeeklyScheduleCode(workScheduleCode) &&
    !isNonUnion &&
    isUnionWeeklyScheduleFlagActive;

  let employmentOfferData = {};

  // NON UNION
  if (isNonUnion) {
    employmentOfferData.hourlyStudioRate = terms?.rate_per_hour_studio;
    employmentOfferData.dailyStudioRate = terms?.rate_per_day_studio;
    employmentOfferData.weeklyStudioRate = terms?.rate_per_week_studio;
    // Non Union does not have location / distant rate
    employmentOfferData.studioDaysHours =
      terms?.guaranteed_days_studio || terms?.guaranteed_hours_studio;
    employmentOfferData.hoursDaysTitle = terms?.guaranteed_days_studio
      ? 'Days Per Week'
      : 'Hours per Week';
  } else {
    // union
    employmentOfferData.hoursDaysTitle = terms?.guaranteed_days_studio
      ? 'Days Per Week'
      : 'Guaranteed Hours';
    employmentOfferData.studioDaysHours =
      terms?.guaranteed_days_studio || terms?.guaranteed_hours_studio;
    employmentOfferData.locationTitle = 'Guaranteed Hours';
    employmentOfferData.locationHours = terms?.guaranteed_hours_location;
    employmentOfferData.hourlyStudioRate = terms?.rate_per_hour_studio;
    employmentOfferData.dailyStudioRate = terms?.rate_per_day_studio;
    employmentOfferData.hourlyLocationRate = terms?.rate_per_hour_location;
    employmentOfferData.weeklyStudioRate = terms?.rate_per_week_studio;
    employmentOfferData.weeklyLocationRate = terms?.rate_per_week_location;
    employmentOfferData.sixthDayLocationRate = terms?.pay_6th_day_rate_location;
    employmentOfferData.seventhDayLocationRate =
      terms?.pay_7th_day_rate_location;
  }
  employmentOfferData.sixthDayRate = terms?.pay_6th_day_rate;
  employmentOfferData.seventhDayRate = terms?.pay_7th_day_rate;
  employmentOfferData.idlePayRate = false;
  employmentOfferData.overtimeRate = terms?.overtime_rate;
  employmentOfferData.work_schedule_code = terms?.work_schedule_code;

  if (isCanada) {
    employmentOfferData.daysPerWeekTitle = terms?.guaranteed_days_studio
      ? 'Days Per Week'
      : null;
    employmentOfferData.daysPerWeek = terms?.guaranteed_days_studio;
    employmentOfferData.hoursDaysTitle =
      terms?.guaranteed_hours_frequency === 'weekly'
        ? 'Hours Per Week'
        : 'Hours Per Day';
    employmentOfferData.studioDaysHours = terms?.guaranteed_hours_studio;
  }

  return isMobile ? (
    <TermsOfEmploymentMobile
      offerData={employmentOfferData}
      isNonUnion={isNonUnion}
      isNonUnionScheduleA={isNonUnionScheduleA}
      isNonUnionScheduleC={isNonUnionScheduleC}
      isNonUnionScheduleD={isNonUnionScheduleD}
      isUnionScheduleA={isUnionScheduleA}
      isUnionWeeklySchedule={isUnionWeeklySchedule}
      terms={terms}
    />
  ) : (
    <TermsOfEmploymentDesktop
      offerData={employmentOfferData}
      isNonUnion={isNonUnion}
      isNonUnionScheduleA={isNonUnionScheduleA}
      isNonUnionScheduleC={isNonUnionScheduleC}
      isNonUnionScheduleD={isNonUnionScheduleD}
      isUnionScheduleA={isUnionScheduleA}
      isUnionWeeklySchedule={isUnionWeeklySchedule}
      terms={terms}
    />
  );
};

export default withSizes(({ width }) => ({
  isMobile: width < MAX_MOBILE_WIDTH,
}))(TermsOfEmployment);
