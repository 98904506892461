import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
} from '@material-ui/core';
import LensIcon from '@material-ui/icons/Lens';
import withOnboardingStatus from 'onboarding/hoc/withOnboardingStatus';

const styles = {
  stepTitle: {
    textAlign: 'center',
  },
  root: {
    background: 'transparent',
    padding: 0,
    marginTop: 4,
  },
  lineRoot: {
    top: '6px',
    left: '-50%',
    right: 0,
    width: '100%',
    zIndex: 1,
    position: 'absolute',
  },
  connectorLine: {
    transition: 'none',
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: '#79c2d5',
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: '#79c2d5',
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: 'white',
    },
  },
  iconRoot: {
    color: 'white',
    fontSize: '12px',
    zIndex: 2,
  },
  alternativeLabel: {
    marginTop: '0 !important',
    color: '#79c2d5',
    fontWeight: 700,
  },
  completedLabel: {
    color: 'white !important',
    fontWeight: '700 !important',
  },
  activeLabel: {
    color: '#79c2d5 !important',
    fontWeight: '700 !important',
  },
  iconDisabled: {
    color: '#79c2d5',
    fontSize: '12px',
    zIndex: 2,
  },
};

const OnboardingStepper = ({
  classes,
  currentOnboardingStepIndex,
  onboardingSteps,
}) => {
  if (onboardingSteps.length === 0 || currentOnboardingStepIndex === -1) {
    return null;
  }
  return (
    <div data-test-id="OnboardingStepper-root">
      <Typography variant="subtitle2" className={classes.stepTitle}>
        {onboardingSteps[currentOnboardingStepIndex].label}
      </Typography>
      <Stepper
        className={classes.root}
        activeStep={currentOnboardingStepIndex + 1}
        alternativeLabel
        connector={
          <StepConnector
            classes={{
              root: classes.lineRoot,
              active: classes.connectorActive,
              completed: classes.connectorCompleted,
              disabled: classes.connectorDisabled,
              line: classes.connectorLine,
            }}
          />
        }
      >
        {onboardingSteps.map((step, index) => (
          <Step key={step.name}>
            <StepLabel
              classes={{
                alternativeLabel: classes.alternativeLabel,
                active: classes.activeLabel,
                completed: classes.completedLabel,
              }}
              icon={
                <LensIcon
                  classes={{
                    root:
                      index <= currentOnboardingStepIndex
                        ? classes.iconRoot
                        : classes.iconDisabled,
                  }}
                />
              }
            />
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default withStyles(styles)(withOnboardingStatus(OnboardingStepper));
