import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  navArrowsContainer: {
    backgroundColor: 'transparent',
    position: 'relative',
    top: '-22rem',
    bottom: 0,
    width: '30%',
    height: 0,
  },
  leftArrow: {
    color: '#2eaba4',
  },
  rightArrow: {
    color: '#2eaba4',
  },
  iconButtonRight: {
    backgroundColor: '#f7fcfc',
    zIndex: 10,
    padding: '4px !important',
    left: '84vw',
  },

  iconButtonLeft: {
    backgroundColor: '#f7fcfc',
    zIndex: 10,
    padding: '4px !important',
    left: '-0.1rem',
  },
}));

const MobileDocumentPageNavigation = props => {
  const classes = useStyles();

  const { page, pageCount, setPage } = props;

  return (
    <div className={classes.navArrowsContainer}>
      <IconButton
        disabled={page <= 1}
        className={classes.iconButtonLeft}
        onClick={() => setPage(-1)}
      >
        <ChevronLeft className={classes.leftArrow} />
      </IconButton>
      <IconButton
        disabled={page >= pageCount}
        className={classes.iconButtonRight}
        onClick={() => setPage(1)}
      >
        <ChevronRight className={classes.rightArrow} />
      </IconButton>
    </div>
  );
};

export default MobileDocumentPageNavigation;
