import DocumentModal from 'common/oldJavascripts/components/Shared/DocumentModal';

import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';

export default Relay.createClass({
  statics: {
    queries: {
      documentImages: {
        info(params) {
          const {
            documentId,
            isMainDocument = true,
            offerId,
            supportingDocumentId,
          } = params;
          // If it's not flagged as a parent document make a request for the supporting document images
          if (!isMainDocument) {
            return {
              id: `/v2/offers/${offerId}/documents/${documentId}/supporting-documents/${supportingDocumentId}/images`,
            };
          }
          return {
            id: `/v2/offers/${offerId}/documents/${documentId}/images`,
          };
        },
      },
    },
  },

  render() {
    const { items: pages = [] } = this.props.documentImages.data;
    return <DocumentModal onClose={this.props.onClose} pages={pages} />;
  },
});
