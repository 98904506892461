import DocumentModal from './DocumentModal';

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Relay from '../../utils/react/Relay.js';
import FailureAlert from '../Shared/FailureAlert';
import Modal from '../Base/Modal';

const styles = {
  cancelButton: {
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    textDecoration: 'underline',
    fontSize: '1rem',
  },
};

export default withStyles(styles)(
  Relay.createClass({
    statics: {
      queries: {
        images: {
          info(params, related) {
            const { projectId } = related['/router/params'];
            const { templateId, adminTemplate, path } = params;
            if (adminTemplate) {
              return {
                id: path,
              };
            }
            return {
              id: `/v2/projects/${projectId}/active-templates/${templateId}/images`,
            };
          },
        },
      },
    },

    _isEmptyContent(images) {
      try {
        const { items } = images.data;
        if (items.length > 0) {
          return false;
        }
        return true;
      } catch (err) {
        return true;
      }
    },

    render() {
      const { images, onClose, classes } = this.props;
      const { items } = images.data;
      if (images.status === 'success' && this._isEmptyContent(images)) {
        return (
          <Modal>
            <FailureAlert />
            <button className={classes.cancelButton} onClick={onClose}>
              Close
            </button>
          </Modal>
        );
      }

      return <DocumentModal onClose={onClose} pages={items} />;
    },
  }),
);
