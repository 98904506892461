import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Header from 'common/oldJavascripts/components/Base/Header';
import Table from 'common/oldJavascripts/components/Base/Table';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Preloader from 'common/oldJavascripts/components/Shared/Preloader';
import T from 'common/oldJavascripts/utils/i18n';
import collection from 'common/oldJavascripts/utils/collection.js';

import moment from 'moment';

const Row = Table.Row;
const Cell = Table.Cell;

const AuditLogRow = Relay.createClass({
  statics: {
    mutators: {
      auditLog: {
        info: function(params, related) {
          var routerParams = related['/router/params'];

          return {
            id:
              '/projects/' +
              routerParams.projectId +
              '/audit-logs/' +
              params.id,
          };
        },
      },
    },
  },

  render: function() {
    const { auditLog: { data: auditLog } = {} } = this.props;
    const {
      activity_type: activityType,
      activity_data: activityData,
      created_at: createdAt,
    } = auditLog || {};

    const message = T('audit_logs.messages.' + activityType, activityData);

    const [createdAtStripped] = createdAt.split('+') || [];

    const formattedCreatedAt = moment(
      createdAtStripped,
      'YYYY-MM-DD HH:mm:ss',
    ).format('MMM Do, YYYY, h:mmA');

    return (
      <Row key={auditLog.id}>
        <Cell>
          <span data-test-id={`AuditLogs-row-${auditLog.id}`}>
            {formattedCreatedAt}
            {' - '}
            {auditLog.user_name} {message}
          </span>
        </Cell>
      </Row>
    );
  },
});

export default Relay.createClass({
  statics: {
    queries: {
      auditLogs: {
        info: function(_, related) {
          var params = related['/router/params'];
          return {
            id: '/projects/' + params.projectId + '/audit-logs',
          };
        },
      },
    },
  },

  render: function() {
    var auditLogsQuery = this.props.auditLogs;
    var auditLogs = auditLogsQuery.data;
    var content = null;
    var rows = null;

    if (auditLogsQuery.status === 'loading') {
      return <Preloader />;
    }

    if (auditLogsQuery.status === 'failed') {
      return <Blankslate>{T('audit_logs.not_found')}</Blankslate>;
    }

    if (auditLogs.items.length > 0) {
      rows = collection.map(auditLogs.items, function(auditLog) {
        return <AuditLogRow params={{ id: auditLog.id }} key={auditLog.id} />;
      });

      content = (
        <Table>
          <Table.Header>
            <Cell header={true}>{T('audit_logs.columns.activity')}</Cell>
          </Table.Header>
          {rows}
        </Table>
      );
    } else {
      content = <Blankslate>{T('audit_logs.not_found')}</Blankslate>;
    }

    return (
      <BoxBody>
        <BoxItem>
          <Header>
            <Header.Title>
              <span data-test-id="AuditLogs-title">
                {T('audit_logs.title')}
              </span>
            </Header.Title>
          </Header>
        </BoxItem>
        {content}
      </BoxBody>
    );
  },
});
