import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import _ from 'lodash';
import T from 'common/oldJavascripts/utils/i18n';

import Text from 'common/oldJavascripts/components/Base/Text';
import { createOfferCurrency as formatCurrency } from 'common/oldJavascripts/utils/format.js';

import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

var ExpandableCell = ExpandableTableCell;
var ETableCellTitle = ExpandableTableCellTitle;

export default Relay.createClass({
  render: function() {
    var emptyText = T('shared.empty_text');
    var offer = this.props.offer;
    var terms = offer.terms_of_employment || offer;
    var mainContent = null;
    var secondaryContent = null;
    var rate = null;
    var scale = null;

    rate =
      terms.rate_per_hour_studio ||
      terms.rate_per_hour_location ||
      terms.rate_per_day_studio ||
      terms.rate_per_week_studio ||
      terms.rate_per_week_location;
    scale =
      offer.scale_rate_per_hour_studio ||
      offer.scale_rate_per_hour_location ||
      offer.scale_rate_per_week_studio ||
      offer.scale_rate_per_week_location;

    rate = _.isString(rate) ? _.toNumber(rate) : rate ? rate : scale;

    if (rate && scale && rate !== scale) {
      mainContent = <Text alert={true}>{formatCurrency(rate, 9)}</Text>;
      if (!this.props.noShowScale) {
        secondaryContent = formatCurrency(scale, 9);
      }
    } else {
      mainContent = formatCurrency(rate, 9) || emptyText;
    }

    return (
      <ExpandableCell
        expanded={this.props.expanded}
        onClick={this.props.onClick}
        data-test-id={this.props['data-test-id']}
      >
        <span data-test-id={`RateCell-content-${offer.id}`}>
          <ETableCellTitle>{mainContent}</ETableCellTitle>
          {secondaryContent}
        </span>
      </ExpandableCell>
    );
  },
});
