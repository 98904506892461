import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import classNames from 'classnames';

const styles = () => ({
  root: {
    textDecoration: 'underline',
    textTransform: 'capitalize',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: 4,
    height: 15,
    width: 15,
  },
  marginBottom: {
    marginBottom: 20,
  },
  bold: {
    fontWeight: 600,
  },
});

const TypographyLink = props => {
  const { classes, title, onClick, marginBottom, bold } = props;
  const rootClass = classNames(classes.root, {
    [classes.marginBottom]: marginBottom,
    [classes.bold]: bold,
  });
  return (
    <Typography
      onClick={onClick}
      className={rootClass}
      data-test-id="TypographyLink-title"
    >
      {title} <OpenInNew className={classes.icon} />
    </Typography>
  );
};

export default withStyles(styles)(TypographyLink);
