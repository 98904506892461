import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import CustomDateInput from './CustomDateInput';
import classNames from 'classnames';
import sharedCss from '../../shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';

const styles = theme => ({
  ...sharedCss.fields,
  root: {
    position: 'absolute',
  },
  input: {
    color: 'black',
  },
});

const getValidMoment = (value, defaultValue, format) => {
  if (!value) return null;
  if (value === defaultValue) return value;
  const m = moment(value, format);
  if (!m.isValid()) return null;
  return m;
};

const getMomentValue = (value, format) => {
  return value && value.isValid && value.isValid()
    ? value.format(format)
    : value;
};

const DatePicker = props => {
  const {
    id,
    format = 'MM/DD/YYYY',
    x,
    y,
    height,
    width,
    handleChange,
    classes,
    value,
    setFieldRef,
    defaultValue,
    _derived: { isRequired, isDisabled },
  } = props;
  const fieldDynamicStyle = {
    top: y,
    left: x,
    height,
    width,
  };

  const filteredProps = { ...props, fieldId: id }; // DataPicker overwrites our id prop
  delete filteredProps.classes;

  const dateFormat = format ? format.toUpperCase() : 'MM/DD/YYYY';
  const validMoment = getValidMoment(value, defaultValue, dateFormat);

  const onChange = v => {
    const m = getValidMoment(v, '', dateFormat);
    const value = (m && m.format && m.format(dateFormat)) || m;
    handleChange(value);
  };

  return (
    <div className={classes.root} style={fieldDynamicStyle}>
      <ReactDatePicker
        ref={e => setFieldRef(e, y)}
        customInput={<CustomDateInput {...filteredProps} />}
        onChange={onChange}
        placeholderText={isRequired ? 'Required' : 'Click to select a date'}
        selected={(typeof validMoment === 'object' && validMoment) || null}
        value={getMomentValue(validMoment, dateFormat)}
        disabled={isDisabled}
        className={classNames(classes.input, fieldStatusClass(props))}
        dateFormat={dateFormat}
        popperModifiers={{
          preventOverflow: {
            boundariesElement: 'scrollParent',
          },
        }}
        data-test-id={`DatePicker-picker-${id}`}
      />
    </div>
  );
};

export default withStyles(styles)(DatePicker);
