import React, { Component } from 'react';
import withApi from 'common/hoc/withApi';

import status from 'common/oldJavascripts/utils/react/status';
import Button from 'common/oldJavascripts/components/Base/Button';
import DepartmentForm from '../Form';
import Link from 'common/oldJavascripts/components/Base/Link';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import Loader from 'common/components/Loader';

class ItemForm extends Component {
  static queries = {
    project: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/projects/${params.projectId}`,
        };
      },
    },
    departmentRules: {
      info(params, related) {
        const routerParams = related['/router/params'];

        return {
          id: `/v2/projects/${routerParams.projectId}/departments/${params.id}/department-rules`,
        };
      },
      stale(params, related) {
        const routerParams = related['/router/params'];

        return [
          `/v2/projects/${routerParams.projectId}/departments/${params.id}`,
        ];
      },
    },
  };

  render() {
    const {
      detailSubDataType,
      enforceMask,
      accountMask,
      departmentRules = {},
      department = {},
    } = this.props;
    const {
      data: {
        department_rules: departmentRulesProp = [],
        department_loan_out_rules: loanoutRulesProp = [],
      },
    } = departmentRules;
    if (this._getStatus() === 'loading') {
      return <Loader />;
    }
    return (
      <DepartmentForm
        action={department.data._id + '/edit'}
        method="put"
        onSubmit={this._onSubmit}
        department={department}
        departmentRules={departmentRulesProp}
        loanoutRules={loanoutRulesProp}
        detailSubDataType={detailSubDataType}
        enforceMask={enforceMask}
        accountMask={accountMask}
      >
        <NavBar>
          <NavBar.Item>
            <Button data-test-id="ItemForm-updateButton">Update</Button>
          </NavBar.Item>
          <NavBar.Item>
            <span data-test-id="ItemForm-cancelButton">
              <Link onClick={this._onCancel}>Cancel</Link>
              {' or'}
            </span>
          </NavBar.Item>
        </NavBar>
      </DepartmentForm>
    );
  }

  _getStatus = () => {
    const props = this.props;
    return status([props.departmentRules]);
  };

  _onCancel = () => {
    this.props.onCancel();
  };

  _onSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };
}

export default withApi(ItemForm);
