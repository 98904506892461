import { createMuiTheme } from '@material-ui/core/styles';
// Custom Theme
import palette from './palette';
import typography from './typography';

const theme = createMuiTheme({
  palette,
  typography,
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '16px',
      },
    },
  },
});

export default theme;
