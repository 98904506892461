import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Typography, Input } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import {
  Edit as EditIcon,
  Done as ConfirmIcon,
  Refresh as RetryIcon,
  // Delete as DeleteIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import UploadedDocumentTags from 'common/components/UploadedDocument/UploadedDocumentTags';
import uploadedDocumentActionEnum from 'common/components/UploadedDocument/UploadedDocumentActionEnum';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  container: {
    border: '1px solid #333',
    padding: '10px',
    backgroundColor: theme.palette.common.white,
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing.unit,
    },
  },
  boldFont: {
    fontWeight: 'bold',
  },
  preview: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderRadius: '3px',
    borderColor: palette.grey,
    marginBottom: theme.spacing.unit * 1.5,
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  fullWidth: {
    width: '100%',
  },
  toolbar: {
    padding: 0,
    minHeight: 20,
  },
  iconButton: {
    padding: 0,
    width: 30,
    height: 30,
    marginLeft: theme.spacing.unit,
    backgroundColor: theme.palette.grey['300'],
  },
  noPaddingInput: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  docContainer: {
    width: '90%',
  },
  smallFont: {
    flex: 1,
    lineHeight: '24px',
    paddingRight: theme.spacing.unit,
    fontSize: '12px',
    fontFamily: 'Helvetica Neue',
    color: '#6d7278',
  },
  clickable: {
    height: theme.spacing.unit * 1.5,
    width: theme.spacing.unit * 1.5,
  },
  green: {
    backgroundColor: palette.green,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tagsWidth: {
    flex: 6,
  },
  hidden: {
    display: 'none',
  },
  errorContainer: {
    paddingLeft: '8.3%',
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  barRoot: {
    flexGrow: 1,
    marginTop: '10px',
    marginLeft: '10px',
    backgroundColor: '#6dd400 !important',
    opacity: 0.42,
  },
  progressContainer: {
    display: 'flex',
  },
  scanning: {
    color: '#6dd400',
    fontFamily: 'Helvetica Neue',
    fontSize: 12,
  },
  bar2: {
    backgroundColor: '#6dd400 !important',
  },
  iconsContainer: {
    display: 'flex',
    marginRight: '25px',
    justifyContent: 'space-evenly',
  },
  viewCopy: {
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
    fontSize: '10px',
    color: '#9e9e9e',
  },
  errorMessage: {
    color: 'red',
    fontSize: '12px',
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
});

class UploadedDocument extends Component {
  state = {
    isUploading: true,
    isUploaded: false,
    isCancelled: false,
    editMode: false,
    docName: this.props.document.file.name,
    errorMessage: null,
    tags: [],
    lastAction: null,
    actionQueue: [],
  };

  componentDidMount() {
    this.dispatch(uploadedDocumentActionEnum.UPLOAD);
  }

  onEdit = () => {
    this.setState({ editMode: true });
  };

  onStop = () => {
    this.setState({
      isUploading: false,
      isCancelled: true,
      actionQueue: [...this.state.actionQueue, 'CANCEL'],
    });
  };

  errHandler = ({ message }) => {
    if (Array.isArray(message)) message = message.join(' ');
    this.setState({
      errorMessage:
        message || 'Start+ encountered an issue while uploading the file.',
      isUploading: false,
    });
  };

  uploadFile = (successHandler, errHandler) => {
    this.setState({ isUploading: true });

    const {
      uploadHandler,
      document: { file },
    } = this.props;
    const body = new FormData();
    body.append('file', file);

    uploadHandler(body)
      .then(successHandler)
      .catch(errHandler);
  };

  uploadSuccessCallback = response => {
    // check the message queue, and see CANCEL
    const [msg] = this.state.actionQueue;
    if (msg === 'CANCEL') {
      this.dispatch(uploadedDocumentActionEnum.DELETE);
      this.setState({ actionQueue: [] });
    }

    // Update state with the data sent by the server
    // If the data is wrapped in key `data`, its response
    // for createTemplate, if not, its for replaceTemplate
    const data = response.data ? response.data[0] : response;
    const tags = data.categories || [];
    const docName = data.name;
    const newState = {
      isUploaded: true,
      isUploading: false,
      fileId: data.id,
      tags,
      docName,
    };
    this.setState(newState);

    // pass back fileId to update parent components inside the lib
    const {
      onSuccessfulUpload,
      document: { file },
    } = this.props;
    if (onSuccessfulUpload) {
      onSuccessfulUpload(data.id, file, data);
    }
  };

  updateFile = (successHandler, errHandler) => {
    this.setState({ editMode: false });
    const update = this.prepareDataForUpdate();
    const { editHandler } = this.props;

    editHandler(update)
      .then(successHandler)
      .catch(errHandler);
  };

  updateSuccessCallback = data => {
    // Make a copy of the tags, for calculating delta
    const tags = data.categories || [];
    const docName = data.name;
    this.setState({ tags, docName });
    return data;
  };

  prepareDataForUpdate = () => {
    const { fileId, docName, tags } = this.state;
    return {
      fileId,
      tags,
      name: docName,
    };
  };

  handleFieldChange = field => event => {
    this.setState({
      [field]: event.target.value,
    });
  };

  deleteFile = () => {
    const { deleteHandler } = this.props;
    const { fileId } = this.state;
    deleteHandler && deleteHandler({ fileId });
  };

  viewFile = () => {
    const { viewHandler } = this.props;
    const { fileId } = this.state;
    viewHandler(fileId);
  };

  clearError = () => {
    this.setState({ errorMessage: null });
  };

  dispatch = action => {
    if (action !== uploadedDocumentActionEnum.RETRY) {
      this.setState({ lastAction: action });
    }
    this.clearError();
    switch (action) {
      case uploadedDocumentActionEnum.UPLOAD:
        this.uploadFile(this.uploadSuccessCallback, this.errHandler);
        break;
      case uploadedDocumentActionEnum.EDIT:
        this.onEdit();
        break;
      case uploadedDocumentActionEnum.SAVE:
        this.updateFile(this.updateSuccessCallback, this.errHandler);
        break;
      case uploadedDocumentActionEnum.DELETE:
        this.deleteFile();
        break;
      case uploadedDocumentActionEnum.RETRY: {
        const { lastAction } = this.state;
        this.dispatch(lastAction);
        break;
      }
      case uploadedDocumentActionEnum.VIEW: {
        this.viewFile();
        break;
      }
      default:
        break;
    }
  };

  calcFileSize = () => {
    const unit = ['B', 'kB', 'MB', 'GB', 'TB'];
    const { size } = this.props.document.file;
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / 1024 ** i).toFixed(2) * 1} ${unit[i]}`;
  };

  addTag = value => {
    this.setState(({ tags }) => {
      const newTag = { name: value.toLowerCase() };
      for (let i = 0; i < tags.length; i += 1) {
        if (tags[i].name.toLowerCase() === newTag.name) return null;
      }
      return { tags: [...tags, newTag] };
    });
  };

  deleteTag = tag => {
    this.setState(({ tags }) => {
      const newTags = [...tags];
      newTags.splice(tags.indexOf(tag), 1);
      return { tags: newTags };
    });
  };

  viewDocument = () => {
    const { closeModalHandler } = this.props;
    closeModalHandler && closeModalHandler();
    this.dispatch(uploadedDocumentActionEnum.VIEW);
  };

  renderDocumentName = () => {
    const { classes } = this.props;
    const { editMode, docName, isUploaded } = this.state;
    const filesize = this.calcFileSize();
    const classNameProp = classNames(
      classes.fullWidth,
      classes.boldFont,
      classes.ellipsis,
    );
    let name;
    if (editMode) {
      name = (
        <Input
          placeholder={docName}
          value={docName}
          autoFocus
          onChange={this.handleFieldChange('docName')}
          className={classes.fullWidth}
          data-test-id="UploadedDocument-templateNameEdit"
          classes={{
            input: classes.noPaddingInput,
          }}
        />
      );
    } else {
      name = (
        <div>
          <Typography
            color="textSecondary"
            className={classNameProp}
            variant="subtitle1"
            data-test-id={`UploadedDocument-templateName-${docName}`}
          >
            {docName}
          </Typography>
          {isUploaded && (
            <Typography className={classes.smallFont}>
              {filesize} Scan Complete
            </Typography>
          )}
        </div>
      );
    }
    return name;
  };

  renderEditButton = () => {
    const { classes } = this.props;
    const { editMode } = this.state;
    const buttonClass = classNames({
      [classes.green]: editMode,
    });
    const Icon = editMode ? ConfirmIcon : EditIcon;
    const editAction = editMode
      ? uploadedDocumentActionEnum.SAVE
      : uploadedDocumentActionEnum.EDIT;

    return (
      <IconButton
        className={buttonClass}
        onClick={() => this.dispatch(editAction)}
        data-test-id={editMode ? 'Document-edit' : 'Document-save'}
      >
        <Icon />
      </IconButton>
    );
  };

  renderViewButton = () => (
    <IconButton
      onClick={() => this.viewDocument()}
      data-test-id="Document-view"
    >
      <RemoveRedEyeIcon />
    </IconButton>
  );

  render() {
    const {
      classes,
      editable,
      processing,
      // deleteHandler,
      viewHandler,
    } = this.props;
    const {
      isUploading,
      isUploaded,
      isCancelled,
      tags,
      editMode,
      errorMessage,
      // docName,
    } = this.state;

    const allowAction = !isCancelled && isUploaded;
    const documentNameField = this.renderDocumentName();

    return (
      <div className={classes.container}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={9}>
            <div className={classes.rowContainer}>
              <div className={classes.docContainer}>
                {documentNameField}
                {isUploading && (
                  <div className={classes.progressContainer}>
                    <span className={classes.scanning}>
                      Scanning for viruses
                    </span>
                    <LinearProgress
                      classes={{
                        root: classes.barRoot,
                        bar2Indeterminate: classes.bar2,
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                className={classNames({
                  [classes.hidden]: !allowAction,
                })}
              />
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.docContainer}>
                <UploadedDocumentTags
                  form
                  tags={tags}
                  addHandler={this.addTag}
                  deleteHandler={this.deleteTag}
                  classes={{ root: classes.tagsWidth }}
                  editable={editMode}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.iconsContainer}>
              {allowAction &&
                viewHandler &&
                !processing &&
                this.renderViewButton()}
              {allowAction && editable && this.renderEditButton()}
              {/*allowAction && deleteHandler && (
                <IconButton
                  onClick={() =>
                    this.dispatch(uploadedDocumentActionEnum.DELETE)
                  }
                  data-test-id={`UploadedDocument-delete-${docName}`}
                >
                  <DeleteIcon />
                </IconButton>
              )*/}
            </div>
          </Grid>
        </Grid>

        <div
          className={classNames(classes.errorContainer, {
            [classes.hidden]: !errorMessage,
          })}
        >
          <Typography color="error" className={classes.smallFont}>
            <span className={classes.errorMessage}>{errorMessage}.</span>
            <IconButton
              className={classes.iconButton}
              onClick={() => this.dispatch(uploadedDocumentActionEnum.RETRY)}
            >
              <RetryIcon className={classes.clickable} />
            </IconButton>
          </Typography>
        </div>
      </div>
    );
  }
}

Document.defaultProps = {
  editable: false,
};

export default withStyles(styles)(UploadedDocument);
