import React from 'react';

// Components
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaskedInput from 'react-text-mask';

// Constants
import { ratePerHourMask } from '../../helpers/textFieldNumberMasks';
import OVERTIME_MULTIPLIER from '../OVERTIME_MULTIPLIER';

// Utilities
import formatCurrency from '../../helpers/formatCurrency';
import enforceMaxDigits from '../../helpers/enforceMaxDigits';

const RatePerHour = ({
  onChange: upstreamOnChange,
  formData: { ratePerHour } = {},
  classes = {},
}) => {
  const onChange = ({ target: { value: rawValue } = {} } = {}) => {
    let value;
    let overtime;

    if (value === '' || value === '0') {
      value = null;
      overtime = null;
    } else {
      value = rawValue;
      overtime = enforceMaxDigits(value * OVERTIME_MULTIPLIER);
    }

    upstreamOnChange({
      ratePerHour: value,
      overtimeRatePerHour: overtime,
    });
    return;
  };

  const onBlur = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') return;

    const formattedValue = formatCurrency(value);
    const overtimeValue = formattedValue * OVERTIME_MULTIPLIER;
    const formattedOvertimeValue = formatCurrency(overtimeValue);

    upstreamOnChange({
      ratePerHour: formattedValue,
      overtimeRatePerHour: formattedOvertimeValue,
    });
    return;
  };

  return (
    <TableCell align="center" className={classes.root}>
      <MaskedInput
        mask={ratePerHourMask}
        value={ratePerHour || ''}
        onChange={onChange}
        onBlur={onBlur}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': 'RatePerHour-input',
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default RatePerHour;
