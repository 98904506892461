import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Loader from 'common/components/Loader';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Done as CheckIcon, Clear as ErrorIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { compose } from 'redux';
import { withRouter } from 'react-router';
import ajax from 'common/utilities/ajax';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as Sentry from '@sentry/browser';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { connect } from 'react-redux';

const styles = {
  title: {
    textAlign: 'center',
  },
  returnText: {
    marginTop: '10px',
  },
  root: {
    width: '80%',
    margin: '0 auto',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  success: {
    position: 'relative',
    top: '0.225em',
    color: palette.success2,
  },
  error: {
    position: 'relative',
    top: '0.225em',
    color: palette.error2,
  },
  loader: {
    height: '75px',
  },
};

class DownloadAllDocuments extends Component {
  state = {
    fileIsLoading: true,
    downloadError: false,
    errorMessage: null,
  };

  componentDidMount() {
    const {
      match: { params = {} } = {},
      pushNotification,
      popNotification,
    } = this.props;
    const { projectId, fileId } = params || {};
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(`/projects/${projectId}/files/${fileId}`)
      .then(response => {
        Sentry.addBreadcrumb({ response });
        const { path = '' } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification();
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
        this.setState({ fileIsLoading: false });
      })
      .catch(err => {
        let message =
          'There was an error with your download. Please try again. If you need help, contact support: plus.support@castandcrew.com or 818.860.7770.';
        const { status_code = 200 } = err;
        if (status_code === 422) {
          const { resource: { errors = {} } = {} } = err;
          const { token: [errMessage] = [] } = errors || {};
          if (errMessage === 'downloaded') {
            message = 'The document you requested has already been downloaded.';
          } else if (errMessage === 'expired') {
            message =
              'The document you requested has already expired download time limit.';
          }
        }
        popNotification();
        Sentry.captureException(err);
        this.setState({
          downloadError: true,
          fileIsLoading: false,
          errorMessage: message,
        });
      });
  }

  render() {
    const { classes = {}, match: { params = {} } = {} } = this.props;
    const { fileIsLoading, downloadError, errorMessage } = this.state;
    const { projectId } = params || {};
    return (
      <LayoutContent>
        <Paper classes={{ root: classes.root }}>
          {fileIsLoading && !downloadError && (
            <Loader className={classes.loader} />
          )}
          {!downloadError && (
            <Typography className={classes.title} variant="h5">
              {fileIsLoading ? (
                'Your files will be downloaded shortly.'
              ) : (
                <React.Fragment>
                  <CheckIcon className={classes.success} /> Your download has
                  started.
                </React.Fragment>
              )}
            </Typography>
          )}
          {downloadError && (
            <Typography className={classes.title} variant="h5">
              <ErrorIcon className={classes.error} />
              {errorMessage}
            </Typography>
          )}
          {!fileIsLoading && (
            <Typography variant="h5" className={classes.returnText}>
              Click{' '}
              <Link to={`/projects/${projectId}/offer-documents`}>here</Link> to
              return to Offers Documents.
            </Typography>
          )}
        </Paper>
      </LayoutContent>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(DownloadAllDocuments);
