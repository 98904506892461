import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';

// GQL
import { Queries } from 'common/apollo';

import { Paper, Typography, FormControl, TextField } from '@material-ui/core';
import ReactSelect, { components } from 'react-select';

import PermissionExpansionPanel from 'admin/components/PermissionExpansionPanel';

// Utilities
import validateName from 'common/utilities/validateName';
import { stableSort, getSorting } from 'common/utilities/stableSort';

const styles = {
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '20px',
  },
  header: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#30aba3',
    },
  },
  cssFocused: {},
  cssShrinked: {
    color: '#30aba3',
  },
  permissionContainer: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
};

const DropdownIndicator = props => {
  return (
    <span data-test-id="AdminUserForm-select-opener">
      <components.DropdownIndicator {...props} />
    </span>
  );
};

const ClearIndicator = props => {
  return (
    <span data-test-id="AdminUserForm-select-clear">
      <components.ClearIndicator {...props} />
    </span>
  );
};

const formatOptionLabel = ({ label }, { context }) =>
  context === 'value' ? (
    <span data-test-id="AdminUserForm-select-value">{label}</span>
  ) : (
    <span data-test-id={`AdminUserForm-select-option-${label}`}>{label}</span>
  );

class AdminUserForm extends React.Component {
  handleChange = attr => newValue => {
    const { onChange: parentHandler } = this.props;

    parentHandler(attr)(newValue);
  };

  render() {
    const {
      classes,
      user = {},
      errors,
      permissionGroups = [],
      selections = [],
      onPermissionChange,
      onChange,
      PresetQuery,
    } = this.props;

    const { permissionPresets: presets = [] } = PresetQuery;

    const {
      id,
      firstName = '',
      lastName = '',
      email = '',
      presetId = '',
    } = user;

    const {
      firstName: firstNameError = '',
      lastName: lastNameError = '',
      email: emailError = '',
      root: rootError = '',
    } = errors || {};

    const options = presets.map(p => ({ value: p.id, label: p.name }));
    const orderedOptions = stableSort(options, getSorting('asc', 'label'));
    return (
      <Paper className={classes.form} elevation={0}>
        <Typography
          className={classes.header}
          variant="h5"
          data-test-id="AdminUserForm-header"
        >
          {id ? 'Edit Admin User' : 'Create Admin User'}
        </Typography>

        {rootError && (
          <Typography
            className={classes.header}
            variant="body1"
            color="error"
            data-test-id="AdminUserForm-error"
          >
            {rootError}
          </Typography>
        )}

        <TextField
          required
          error={!!firstNameError}
          helperText={firstNameError}
          value={firstName}
          label="First Name"
          onChange={({ target: { value } = {} }) =>
            this.handleChange('firstName')(validateName(value))
          }
          margin="normal"
          className={classes.input}
          inputProps={{
            'data-test-id': 'AdminUserForm-firstName',
          }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
              shrink: classes.cssShrinked,
            },
          }}
          FormHelperTextProps={{
            'data-test-id': 'AdminUserForm-error-firstName',
          }}
        />

        <TextField
          required
          error={!!lastNameError}
          helperText={lastNameError}
          value={lastName}
          label="Last Name"
          onChange={({ target: { value } = {} }) =>
            this.handleChange('lastName')(validateName(value))
          }
          margin="normal"
          className={classes.input}
          inputProps={{
            'data-test-id': 'AdminUserForm-lastName',
          }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
              shrink: classes.cssShrinked,
            },
          }}
          FormHelperTextProps={{
            'data-test-id': 'AdminUserForm-error-lastName',
          }}
        />

        <TextField
          required
          error={!!emailError}
          helperText={emailError}
          value={email}
          label="Email Address"
          onChange={({ target: { value } = {} }) =>
            this.handleChange('email')(value)
          }
          margin="normal"
          className={classes.input}
          inputProps={{
            'data-test-id': 'AdminUserForm-email',
          }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
              shrink: classes.cssShrinked,
            },
          }}
          FormHelperTextProps={{
            'data-test-id': 'AdminUserForm-error-email',
          }}
        />

        <FormControl margin="normal">
          <ReactSelect
            value={options.filter(opt => opt.value === presetId)}
            onChange={onChange('presetId')}
            options={orderedOptions}
            placeholder="Apply Permission Preset"
            isSearchable
            isClearable
            maxMenuHeight={150}
            formatOptionLabel={formatOptionLabel}
            components={{ DropdownIndicator, ClearIndicator }}
          />
        </FormControl>

        <Typography
          gutterBottom
          variant="subtitle1"
          className={classes.topMargin}
        >
          Permissions
        </Typography>
        <Paper className={classes.permissionContainer}>
          {permissionGroups.map(item => {
            return (
              <PermissionExpansionPanel
                key={item.id}
                permissionGroup={item}
                selections={selections}
                onChange={onPermissionChange(item.id)}
              />
            );
          })}
        </Paper>
      </Paper>
    );
  }
}

export default compose(
  graphql(Queries.GetPermissionPresets, {
    name: 'PresetQuery',
  }),
  graphql(Queries.GetAdminUsers, {
    name: 'AdminQuery',
  }),
  withStyles(styles),
)(AdminUserForm);
