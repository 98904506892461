import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import UsaOnly from 'common/components/UsaOnly';

const styles = () => ({
  employeeNameHeader: {
    width: '15%',
  },
  departmentHeader: {
    width: '10%',
  },
  jobTitleHeader: {
    width: '10%',
  },
  unionHeader: {
    width: '9%',
  },
  startDateHeader: {
    width: '10%',
  },
  rateHeader: {
    width: '7%',
  },
  allowancesHeader: {
    width: '12%',
  },
  statusHeader: {
    width: '12%',
  },
  iNineHeader: {
    width: '10%',
    textAlign: 'center',
  },
  actionsHeader: {
    width: '10%',
  },
});

const TableHeader = ({ classes, endDateAllowed }) => (
  <ExpandableTableRow>
    <ExpandableTableCell
      className={classes.employeeNameHeader}
      header
      sort="last_name,first_name,middle_name"
    >
      Employee
    </ExpandableTableCell>
    <ExpandableTableCell
      className={classes.departmentHeader}
      header
      sort="department"
    >
      Department
    </ExpandableTableCell>
    <ExpandableTableCell
      className={classes.jobTitleHeader}
      header
      sort="occupation"
    >
      Job Title/
      <br />
      Acct Code
    </ExpandableTableCell>
    <ExpandableTableCell className={classes.unionHeader} header sort="union">
      Union
    </ExpandableTableCell>
    <ExpandableTableCell
      className={classes.startDateHeader}
      header
      sort="start_date"
    >
      Start Date
    </ExpandableTableCell>
    {endDateAllowed && (
      <ExpandableTableCell
        className={classes.startDateHeader}
        header={true}
        sort="end_date"
      >
        End Date
      </ExpandableTableCell>
    )}
    <ExpandableTableCell
      className={classes.rateHeader}
      header
      sort="rate_per_hour_studio,rate_per_hour_location,rate_per_day_studio,rate_per_week_studio,rate_per_week_location"
    >
      Rate/
      <br />
      Scale
    </ExpandableTableCell>
    <ExpandableTableCell className={classes.allowancesHeader} header>
      Allowances/
      <br />
      Reimbursements
    </ExpandableTableCell>
    <UsaOnly>
      <ExpandableTableCell className={classes.iNineHeader} header>
        I-9 Status
      </ExpandableTableCell>
    </UsaOnly>
    <ExpandableTableCell className={classes.statusHeader} header sort="status">
      Offer Status
    </ExpandableTableCell>
    <ExpandableTableCell className={classes.actionsHeader} header>
      Actions
    </ExpandableTableCell>
  </ExpandableTableRow>
);

export default withStyles(styles)(TableHeader);
