import React from 'react';
import createReactClass from 'create-react-class';
import Button from 'common/oldJavascripts/components/Base/Button';
import T from 'common/oldJavascripts/utils/i18n';
import Link from 'common/oldJavascripts/components/Base/Link';
import BoxFooter from 'common/oldJavascripts/components/Base/Box/BoxFooter';
import BoxParagraphItem from 'common/oldJavascripts/components/Base/Box/BoxParagraphItem';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  error: {
    color: 'red',
    fontSize: '1rem',
    marginRight: 25,
  },
});

const ErrorMessage = ({ error, classes }) => (
  <span className={classes.error}>{`${error
    .charAt(0)
    .toUpperCase()}${error.slice(1)}`}</span>
);
const StyledErrorMessage = withStyles(styles)(ErrorMessage);

export default createReactClass({
  defaultProps: {
    bordered: true,
  },

  render: function() {
    const { isProcessing = false, errors = [] } = this.props;
    const errorDisplay = errors.map(err => <StyledErrorMessage error={err} />);
    return (
      <BoxFooter bordered={this.props.bordered}>
        <BoxItem>
          <BoxParagraphItem align="right" minimal={true}>
            <span data-test-id="Footer-error">{errorDisplay}</span>
            {isProcessing && 'Processing...'}
            {!isProcessing && [
              <Link onClick={this._onReject} data-test-id="Footer-reject">
                {T('employee.onboarding.details.reject_offer_label')}
              </Link>,
              <Button
                disabled={this.props.disabled}
                onClick={this._onAccept}
                marginLeft={true}
                data-test-id="Footer-accept"
              >
                {T('employee.onboarding.details.accept_offer_label')}
              </Button>,
            ]}
          </BoxParagraphItem>
        </BoxItem>
      </BoxFooter>
    );
  },

  _onAccept: function(event) {
    this.props.onAccept(event);
  },

  _onReject: function(event) {
    this.props.onReject(event);
  },
});
