import { format as formatDate } from 'date-fns';
import React, { Component } from 'react';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import LayoutMain from 'common/oldJavascripts/components/Base/Layout/LayoutMain';
import Loader from 'common/components/Loader';
import RejectModal from './RejectModal';
import status from 'common/oldJavascripts/utils/react/status.js';
import ajax from 'common/utilities/ajax.js';
import processDocumentRequests from 'common/utilities/processDocumentRequests';
import DocumentSigner from 'common/oldJavascripts/components/Shared/DocumentSigner/DocumentSigner';
import DocumentSignerContainer from 'onboarding/components/DocumentSigner/DocumentSignerContainer';
import history from 'common/constants/config/history';
import withApi from 'common/hoc/withApi';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import Paper from '@material-ui/core/Paper';
import Feature from 'common/components/Feature';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: ' 100%',
    gridTemplateRows: ' 100%',
    padding: ' 25px',
    boxSizing: ' border-box',
    width: ' 100%',
    height: ' calc(100% - 130px)',
  },
});

class Documents extends Component {
  static mutators = {
    offer: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/v2/offers/${params.offerId}`,
        };
      },

      stale(_, related) {
        const params = related['/router/params'];
        return [`/v2/offers/${params.offerId}/packets`, `/offers`];
      },
    },
  };
  static queries = {
    documents: {
      info: function(_, related) {
        const { offerId } = related['/router/params'];
        return {
          id: `/v2/offers/${offerId}/documents-details`,
        };
      },
    },

    profile: {
      info() {
        return {
          id: '/account/profile',
        };
      },
    },
  };
  state = {
    documentsSuccessfullySubmitted: [],
  };

  render() {
    const status = this._getStatus();
    if (status === 'loading') {
      return this._renderLoading();
    }
    if (status === 'failed') {
      return this._renderFailure();
    }
    return this._renderSuccess();
  }

  _getStatus = () => {
    const { documents = {}, offer = {}, profile = {} } = this.props;
    return status([documents, offer, profile]);
  };

  getFieldValues = fields =>
    Object.values(fields).map(({ id, value }) => {
      if (value instanceof Date) {
        value = formatDate(value, 'MM/DD/YYYY');
      }
      return {
        id,
        value,
      };
    });

  submitDocs = docs => {
    const { documentsSuccessfullySubmitted } = this.state;
    const { documents: documentsQuery = {}, routerParams = {} } = this.props;
    const { offerId } = routerParams;
    const { data = {} } = documentsQuery;
    const { items: documents = [] } = data;

    // Filter out any documents that have already been submitted or are
    // otherwise unready for submission.
    const filteredSubmittedDocKeys = Object.keys(docs).filter(d => {
      const fullDocObject = documents.find(doc => doc.id === d);
      return (
        fullDocObject.signed === false &&
        !documentsSuccessfullySubmitted.includes(d) &&
        fullDocObject.ready === true
      );
    });

    // Submit documents and if all submit successfully, redirect to complete.
    const documentRequests = filteredSubmittedDocKeys.map(d => {
      const fields = this.getFieldValues(docs[d].fields);
      const { crew_acknowledged = false } = docs[d] || {};
      const postUrl = `/v2/offers/${offerId}/documents/${d}`;
      const promise = ajax
        .post(postUrl, { fields, crew_acknowledged }, { json: true })
        // If POST was successful, record success.
        .then(() =>
          this.setState(({ documentsSuccessfullySubmitted }) => ({
            documentsSuccessfullySubmitted: documentsSuccessfullySubmitted.concat(
              d,
            ),
          })),
        );
      return { promise, documentId: d };
    });
    return processDocumentRequests(documentRequests).then(() => {
      this.props.invalidateByPatterns([
        '/v2/offers/\\d+/documents',
        `/v2/offers/${offerId}`,
      ]);
      history.push(`/onboarding/offers/${offerId}/complete`);
    });
  };

  _saveDocs = (docs, isAutosave) => {
    const { documentsSuccessfullySubmitted } = this.state;
    const { documents: documentsQuery = {}, routerParams = {} } = this.props;
    const { offerId } = routerParams;
    const { data = {} } = documentsQuery;
    const { items: origDocs = [] } = data;
    let newDocs = origDocs;

    const documentRequests = docs
      // Filter out any documents that have already been submitted or are
      // otherwise unready for submission.
      .filter(({ id }) => {
        const { signed, ready } = origDocs.find(d => d.id === id) || {};
        return (
          signed === false &&
          ready === true &&
          !documentsSuccessfullySubmitted.includes(id)
        );
      })
      // Save documents one by one, returning successes & failures.
      .map(doc => {
        const fields = this.getFieldValues(doc.fields);
        const { crew_acknowledged, id } = doc;
        const postUrl = `/v2/offers/${offerId}/documents/${id}/save-draft`;

        // NOTE
        // The following code used to merge the data returned from the save-draft
        // endpoint into the cache. However there are errors in that data - it
        // changes read-only fields to read/write. And anyway, I don't think we
        // should be overwriting our data because of a save operation.
        const promise = ajax.post(
          postUrl,
          { fields, crew_acknowledged },
          { json: true },
        );

        return { promise, documentId: id };
      });

    // If this is an autosave don't reject errors - we don't want to pop up
    // mysterious error messages while the user is typing.
    return processDocumentRequests(documentRequests, !isAutosave).then(() =>
      documentsQuery.replace({ ...documentsQuery.data, items: newDocs }),
    );
  };

  _onModalClose = () => {
    this.setState({ showRejectModal: false });
  };

  _onReject = () => {
    this.setState({ showRejectModal: true });
  };

  _renderFailure = () => {
    return (
      <LayoutMain>
        <LayoutContent>
          <FailureAlert queryName="the additional project information" />
        </LayoutContent>
      </LayoutMain>
    );
  };

  _renderLoading = () => {
    return (
      <LayoutMain>
        <LayoutContent>
          <Loader />
        </LayoutContent>
      </LayoutMain>
    );
  };

  _renderRejectModal = () => {
    if (this.state.showRejectModal) {
      return <RejectModal onClose={this._onModalClose} />;
    }
  };

  _renderSuccess = () => {
    const { offer: offerMutator = {}, classes } = this.props;
    const { data: offer = {} } = offerMutator || {};

    const params = { type: 'crew' };

    return (
      <React.Fragment>
        <Feature
          name="mobileOnboarding"
          fallback={
            <div className={classes.root}>
              <Paper>
                <DocumentSigner
                  params={params}
                  handleOfferAccept={this.submitDocs}
                  handleOfferReject={this._onReject}
                  handleSave={this._saveDocs}
                  offer={offer}
                />
              </Paper>
            </div>
          }
        >
          <DocumentSignerContainer
            params={params}
            handleOfferAccept={this.submitDocs}
            handleOfferReject={this._onReject}
            handleSave={this._saveDocs}
            offer={offer}
          />
        </Feature>
        {this._renderRejectModal()}
      </React.Fragment>
    );
  };
}

export default compose(withApi, withStyles(styles))(Documents);
