import React, { useState } from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';

// GQL
import { Mutations } from 'common/apollo';

// MuiComponents
import { Button } from '@material-ui/core';

const styles = {
  button: {
    display: 'inline-flex',
    backgroundColor: '#2dcc70',
    color: '#ffffff',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    '&:hover, &:focus': {
      outline: 'none',
      backgroundColor: '#009a43',
    },
  },
};

const UserAccessButton = props => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { classes, producer, getLink, role, userStatus } = props;
  const getDownloadLink = () => {
    setIsDisabled(true);
    getLink({ producer, role, userStatus }).then(resp => {
      window.location.href =
        resp && resp.data && resp.data.downloadUserAccessReport;
    });
    setIsDisabled(false);
  };

  return (
    <Button
      onClick={getDownloadLink}
      className={classes.button}
      variant="contained"
      disabled={isDisabled}
      data-test-id="UserAccessButton-download"
    >
      Export to Excel
    </Button>
  );
};

const withUserAccessButton = graphql(Mutations.DownloadUserAccessReport, {
  props: ({ mutate }) => ({
    getLink: ({ producer, role, userStatus }) =>
      mutate({
        variables: {
          producerId: parseInt(producer),
          employeeRole: role.label,
          userStatus: userStatus.label,
        },
      }),
  }),
});

export default compose(
  withStyles(styles),
  withUserAccessButton,
)(UserAccessButton);
