import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Settings as ConfigIcon } from '@material-ui/icons';
import DocumentNotificationDialog from './DocumentNotificationDialog';

const styles = theme => ({
  card: {
    width: 300,
    marginRight: 20,
    marginBottom: 20,
  },
  header: {
    background: '#eee',
    paddingTop: 10,
    paddingBottom: 10,
  },
});

const DocumentNotification = ({
  classes,
  notification,
  onSaveNotification,
}) => {
  const { distributionLists, explainer, id, name } = notification;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onSave = distributionLists => onSaveNotification(id, distributionLists);

  return (
    <React.Fragment>
      <Card className={classes.card} onClick={() => setIsDialogOpen(true)}>
        <CardHeader
          title={name}
          action={
            <IconButton data-test-id={`DocumentNotification-configure-${id}`}>
              <ConfigIcon />
            </IconButton>
          }
          className={classes.header}
          data-test-id={`DocumentNotification-title-${id}`}
        />
        <CardContent>
          <Typography
            variant="subtitle1"
            data-test-id={`DocumentNotification-explainer-${id}`}
          >
            {explainer}
          </Typography>
        </CardContent>
      </Card>
      <DocumentNotificationDialog
        onClose={() => setIsDialogOpen(false)}
        onSave={onSave}
        open={isDialogOpen}
        distributionLists={distributionLists}
        notificationName={name}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(DocumentNotification);
