import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Close as CloseIcon } from '@material-ui/icons';
import classNames from 'class-names';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import useDepartments from 'common/hooks/useDepartments';

const styles = {
  warning: {
    textAlign: 'center',
    marginTop: 16,
  },
  borderRight: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  borderTop: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  tableDescription: {
    padding: 8,
  },
  closeIcon: {
    fontSize: '20px',
    color: 'grey',
    marginRight: 20,
    cursor: 'pointer',
  },
  iconContainer: {
    textAlign: 'right',
    padding: 10,
  },
};

const LoanOutRestrictionTable = props => {
  const { classes, handleClose, projectId } = props;
  const { data: departments = [] } = useDepartments({ projectId });

  const tableBodyRows = departments
    .filter(({ loanOutOccupations = [] }) => loanOutOccupations.length)
    .map(({ id, name, code, loanOutOccupations = [] }) => (
      <React.Fragment key={id}>
        <TableRow>
          <TableCell
            rowSpan={loanOutOccupations.length}
            className={classNames(classes.borderRight, classes.labelCell)}
          >
            <Tooltip title={`Department Code: ${code}`} enterDelay={500}>
              <Typography>{name}</Typography>
            </Tooltip>
          </TableCell>
          <TableCell className={classNames(classes.border, classes.labelCell)}>
            <Tooltip
              title={`Occupation Code: ${loanOutOccupations[0].code}`}
              enterDelay={500}
            >
              <Typography>{`${loanOutOccupations[0].code} - ${loanOutOccupations[0].description}`}</Typography>
            </Tooltip>
          </TableCell>
        </TableRow>
        {[...loanOutOccupations].slice(1).map(({ description, code }) => (
          <TableRow>
            <TableCell
              className={classNames(classes.border, classes.labelCell)}
            >
              <Tooltip title={`Occupation Code: ${code}`} enterDelay={500}>
                <Typography>{`${code} - ${description}`}</Typography>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </React.Fragment>
    ));

  return (
    <div>
      <div className={classes.iconContainer}>
        <CloseIcon onClick={handleClose} className={classes.closeIcon} />
      </div>
      <Typography variant="h6" className={classes.tableDescription}>
        Production management for your show restricts what jobs can be
        loan-outs. ONLY the following Departments/Occupations are authorized:
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={classNames(classes.borderTop, classes.borderRight)}
            >
              <Typography variant="h6">Department</Typography>
            </TableCell>
            <TableCell align="center" className={classes.borderTop}>
              <Typography variant="h6">Occupation</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableBodyRows}</TableBody>
      </Table>
      {!tableBodyRows.length && (
        <Typography variant="h6" className={classes.warning}>
          There are not any restricted loan out occupations configured for this
          project.
        </Typography>
      )}
    </div>
  );
};

export default compose(
  withRouteHelpers,
  withStyles(styles),
)(LoanOutRestrictionTable);
