import React from 'react';

import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  CheckCircle as CheckIcon,
  HighlightOff as ClearIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import SupportingDocument from './SupportingDocument';
import {
  COMPLETE,
  INPROGRESS,
  REJECTED,
  WAITING,
} from 'common/utilities/constants/documentStatus';

const styles = theme => {
  return {
    root: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
    },
    parentDocument: {
      display: 'flex',
      alignItems: 'center',
    },
    doc: {
      display: 'flex',
      alignItems: 'center',
      margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme
        .spacing.unit / 2}px ${theme.spacing.unit * 2}px`,
      cursor: 'pointer',
      background: 'none',
      outline: 'none',
      border: 'none',
      padding: 0,
    },
    documentName: {
      whiteSpace: 'nowrap',
    },
    statusIcon: {
      cursor: 'pointer',
      marginRight: theme.spacing.unit,
      marginLeft: theme.spacing.unit * 0.75,
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
    documentIcon: {
      height: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 0.5,
    },
    previewIcon: {
      marginTop: theme.spacing.unit * 1.5,
      height: 20,
      cursor: 'pointer',
    },
    green: {
      color: theme.palette.primary.main,
    },
    red: {
      color: theme.palette.error.main,
    },
    grey: {
      color: theme.palette.grey[300],
    },
  };
};

const OfferDocumentsStatus = (props, context) => {
  const { documents, handleDocumentSelect, classes, previewDocument } = props;
  const documentList = documents.map(doc => {
    const statusIconClasses = classnames(
      {
        [classes.green]: doc.status === COMPLETE,
        [classes.red]: doc.status === REJECTED,
        [classes.grey]: doc.status === INPROGRESS || doc.status === WAITING,
      },
      [classes.statusIcon],
    );
    const { supportingDocuments: supportDocs = [] } = doc;
    const supportingDocuments = supportDocs.map(supportDoc => (
      <SupportingDocument
        key={supportDoc.id}
        name={supportDoc.name}
        id={supportDoc.id}
        parentDocId={doc.id}
        isViewable={supportDoc.isViewable}
        previewDocument={previewDocument}
      />
    ));
    return (
      <div key={doc.id}>
        <div className={classes.parentDocument}>
          <button
            className={classes.doc}
            onClick={() => handleDocumentSelect(doc.id)}
            data-test-id={`offer-document-status-${doc.id}`}
          >
            {(doc.status === COMPLETE ||
              doc.status === INPROGRESS ||
              doc.status === WAITING) && (
              <CheckIcon className={statusIconClasses} />
            )}
            {doc.status === REJECTED && (
              <ClearIcon className={statusIconClasses} />
            )}

            <Typography type="body" className={classes.documentName}>
              {doc.name}
            </Typography>
          </button>
          {doc.isViewable && (
            <VisibilityIcon
              onClick={() => previewDocument(true, doc.id)}
              className={classes.previewIcon}
              data-test-id={`offer-document-preview-${doc.id}`}
            />
          )}
        </div>
        {supportingDocuments}
      </div>
    );
  });
  return <div className={classes.root}>{documentList}</div>;
};

export default withStyles(styles)(OfferDocumentsStatus);
