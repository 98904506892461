import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query projects($keyword: String, $after: String) {
    projects(first: 20, keyword: $keyword, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
      nodes {
        id
        name
        privileges
        canContinue
        country {
          id
          name
          code
        }
        productionCompany {
          id
          name
          producer {
            id
            name
          }
        }
      }
    }
  }
`;

const useProjects = variables => {
  const { error, loading, data, fetchMore, networkStatus } = useQuery(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  return {
    error,
    networkStatus,
    loading,
    fetchMore,
    data: data?.projects,
  };
};

export default useProjects;
