import React, { Component } from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';

// Components
import { Redirect } from 'react-router';
import Loader from 'common/components/Loader';

// Utilities
import * as Sentry from '@sentry/browser';

const styles = theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#e3e5eb',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    gridTemplateAreas: `
    "content"
    `,
  },
  appBar: {
    gridArea: 'header',
  },
  content: {
    gridArea: 'content',
    placeSelf: 'center',
  },
});

class Home extends Component {
  static mutators = {
    me: {
      info: () => ({
        id: `/me`,
      }),
    },
  };

  redirect = () => {
    const { me: meQuery = {} } = this.props;
    const { data: meData } = meQuery || {};
    const {
      can_access_offers: canAccessOffers,
      can_access_projects: canAccessProjects,
    } = meData || {};
    if (canAccessProjects) return <Redirect push exact to="/projects" />;
    if (canAccessOffers) return <Redirect push exact to="/offers" />;
    Sentry.addBreadcrumb({ meData });
    Sentry.captureException(
      new Error('Could not determine where to redirect user'),
    );
  };
  render() {
    const { classes, me: meQuery = {} } = this.props;
    const { status: meStatus } = meQuery || {};

    return (
      <div className={classes.root}>
        <Loader className={classes.content} fullScreen />
        {meStatus === 'success' && this.redirect()}
      </div>
    );
  }
}

export default compose(withApi, withStyles(styles))(Home);
