import * as palette from './palette';

export default {
  fields: {
    required: {
      backgroundColor: palette.transparentYellow,
    },
    optional: {
      backgroundColor: palette.transparentBlue,
    },
    success: {
      backgroundColor: palette.transparentGreen,
    },
    error: {
      backgroundColor: palette.transparentRed,
    },
    readOnly: {
      color: palette.black,
    },
  },
};
