import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// HoC
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';

// GQL
import { Queries } from 'common/apollo';

// MuiComponents
import {
  Paper,
  Typography,
  TextField,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button } from '@material-ui/core';

// Components

import { UserAccessReport, UserAccessButton } from './index';

const styles = {
  formControl: {
    minWidth: 240,
    marginLeft: 22,
  },
  search: {
    paddingRight: 16,
    width: 200,
  },
  title: {
    padding: 18,
  },
  userAccessHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    paddingBottom: 16,
  },
  button: {
    display: 'inline-flex',
    backgroundColor: '#2dcc70',
    color: '#ffffff',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    '&:hover, &:focus': {
      outline: 'none',
      backgroundColor: '#009a43',
    },
  },
  producerInput: {
    width: 240,
  },
  headerColumn: {
    display: 'inline-flex',
  },
  producerRow: {
    cursor: 'pointer',
  },
  labelProducers: {
    marginTop: -24,
  },
  labelProducerName: {
    marginTop: 25,
  },
  dropdownLabel: {
    fontSize: '16px',
  },
};

const UserAccess = props => {
  const {
    classes,
    producersQuery: { producers = [] },
  } = props;
  const [producer, setProducer] = useState(1);
  const [role, setRole] = useState();
  const [userStatus, setUserStatus] = useState();
  const [reportObject, setReportObject] = useState(null);
  const [page, setPage] = useState(0);
  const ROLES = [
    { value: 1, label: 'all', showLabel: 'All' },
    { value: 2, label: 'non_employee', showLabel: 'Non Employee' },
    { value: 3, label: 'employee', showLabel: 'Employee' },
  ];
  const USER_STATUSES = [
    { value: 1, label: 'all', showLabel: 'All' },
    { value: 2, label: 'active', showLabel: 'Active' },
    { value: 3, label: 'inactive', showLabel: 'Inactive' },
  ];
  const handleRoleChange = event => setRole(event.target.value);
  const handleUserStatusChange = event => setUserStatus(event.target.value);
  const roleObject = ROLES.find(role_obj => role_obj.value === role);
  const userStatusObject = USER_STATUSES.find(
    us_obj => us_obj.value === userStatus,
  );
  const createReportObject = () => ({
    producer: producer,
    role: roleObject,
    userStatus: userStatusObject,
  });
  const producerName = producers.find(prod => prod.id === producer)?.name;

  return (
    <Paper elevation={0}>
      <Typography variant="h4" className={classes.title}>
        User Access Report
      </Typography>
      <div className={classes.userAccessHeader}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel
              htmlFor="autocomplete-producers"
              className={classes.labelProducers}
            >
              Producer
            </InputLabel>
            <Autocomplete
              id="autocomplete-producers"
              className={classes.producerInput}
              options={producers}
              getOptionLabel={option => option.name}
              value={producerName}
              onChange={(event, newProducer) => setProducer(newProducer?.id)}
              data-test-id="UserAccess-producerSelect"
              autoComplete
              renderInput={params => (
                <TextField
                  {...params}
                  data-test-id="UserAccess-producerItem"
                  margin="normal"
                  fullWidth
                />
              )}
            />
            <InputLabel
              htmlFor="autocomplete-producers"
              className={classes.labelProducerName}
            >
              {producerName}
            </InputLabel>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.dropdownLabel}>Role</InputLabel>
            <Select
              id="UserAccessReport-Role-Select"
              data-test-id="UserAccess-RoleSelect"
              value={role}
              name={roleObject?.label}
              onChange={handleRoleChange}
            >
              {ROLES.map(userRole => (
                <MenuItem
                  value={userRole.value}
                  data-test-id="UserAccess-RoleSelectOption"
                >
                  {userRole.showLabel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.dropdownLabel}>
              User Status
            </InputLabel>
            <Select
              id="UserAccessReport-UserStatus-Select"
              data-test-id="UserAccess-UserStatusSelect"
              value={userStatus}
              name={userStatusObject?.label}
              onChange={handleUserStatusChange}
            >
              {USER_STATUSES.map(userStatus => (
                <MenuItem
                  value={userStatus.value}
                  data-test-id="UserAccess-UserStatusSelectOption"
                >
                  {userStatus.showLabel}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <Button
            onClick={() => {
              setReportObject(createReportObject);
              setPage(0);
            }}
            className={classes.button}
            variant="contained"
            data-test-id="UserAccessButton-run"
          >
            Run
          </Button>
        </div>
        <div>
          {producer && role && userStatus && (
            <UserAccessButton
              producer={producer}
              role={roleObject}
              userStatus={userStatusObject}
            />
          )}
        </div>
      </div>
      {producer && role && userStatus && reportObject && (
        <UserAccessReport
          reportObject={reportObject}
          updatePage={setPage}
          page={page}
        />
      )}
    </Paper>
  );
};

export default compose(
  graphql(Queries.GetProducers, { name: 'producersQuery' }),
  withStyles(styles),
)(UserAccess);
