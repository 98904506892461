import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import withApi from 'common/hoc/withApi';

import collection from 'common/oldJavascripts/utils/collection.js';
import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Loader from 'common/components/Loader';
import history from 'common/constants/config/history';
import ValidationAlert from 'common/oldJavascripts/components/Base/ValidationAlert';
import ProjectStatus from 'common/components/ProjectStatus';
import ProjectClone from 'common/components/ProjectClone';
import Feature from 'common/components/Feature';

import Fields from 'admin/oldJavascripts/components/Pages/Project/Setup/Documents/Fields';
import UsersAndDepartments from 'admin/oldJavascripts/components/Pages/Project/Setup/Documents/UsersAndDepartments';
import Details from 'admin/oldJavascripts/components/Pages/Project/Setup/Documents/Details';

// hooks
import useStates from 'common/hooks/useStates';

const VALIDATION_FIELD_NAME_OVERRIDES = {
  box_rental_per: 'Box Rental per day/week',
  computer_rental_per: 'Computer Rental per day/week',
  car_allowance_per: 'Car Allowance per day/week',
  mobile_phone_per: 'Mobile Phone Allowance per day/week',
  per_diem_allowance_per: 'Per Diem Allowance per day/week',
  housing_allowance_per: 'Housing Allowance per day/week',
  weekly_rate_days: 'Weekly Rate and Days per day/week',
};

const updateEnforceMask = (value, props) => {
  const {
    projectEnforceMask: enforceMaskMutator,
    project: projectMutator,
  } = props;

  enforceMaskMutator.updateAndSave(
    { enforce_mask: value },
    '',
    { json: true },
    () => projectMutator.reload(),
  );
};

const scrollUpToError = errorBox => {
  const errorBoxDomNode = ReactDOM.findDOMNode(errorBox);
  if (errorBoxDomNode) errorBoxDomNode.scrollIntoView();
};

const cancel = props => {
  const {
    projectDetails: projectDetailsMutator = {},
    routerParams: { projectId } = {},
  } = props;

  projectDetailsMutator.revert('hiring_status');
  projectDetailsMutator.revert('allow_end_date');
  projectDetailsMutator.revert('allow_hirers_to_send_offers');
  projectDetailsMutator.revert('send_final_docs_to_studio_plus');
  projectDetailsMutator.revert('send_provisional_deal_memo');
  projectDetailsMutator.revert('custom_email_text');

  history.push(`/projects/${projectId}/settings`);
};

const changeReimbursements = (key, value, props) => {
  const { projectDetails: projectDetailsMutator = {} } = props;
  projectDetailsMutator.update(key)(value);
};

const ProjectSettings = props => {
  let {
    routerParams: { projectId } = {},
    customFields = {},
    nonEmployees = {},
    projectDetails = {},
    project = {},
  } = props;
  const { errors: projectDetailsErrors = {} } = projectDetails || {};
  let errorBox = null;

  const [detailSubDataType, setDetailSubDataType] = useState(
    project?.data?.data_type,
  );
  const { data: statesData, loading: loadingStates } = useStates(
    project?.data?.country_id,
  );

  // Top level object that will contain all errors for project settings
  const errorsToDisplay = { ...projectDetailsErrors };

  if (
    project.status === 'loading' ||
    nonEmployees.status === 'loading' ||
    customFields.status === 'loading' ||
    loadingStates
  ) {
    return <Loader />;
  }

  nonEmployees = nonEmployees.data;
  customFields = customFields.data;

  const {
    data: { enforce_loan_out = false, enforce_mask = false, account_mask } = {},
  } = project;

  const states = collection.map(statesData, function(state) {
    return { label: state.name, value: state.id };
  });

  return (
    <BoxBody>
      {Object.keys(errorsToDisplay).length ? (
        <BoxItem ref={ref => (errorBox = ref)}>
          <ValidationAlert
            errors={errorsToDisplay}
            overrides={VALIDATION_FIELD_NAME_OVERRIDES}
          />
        </BoxItem>
      ) : null}
      <BoxItem divider={true}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <ProjectStatus project={project.data} />
          </Grid>
          <Feature name="projectClone">
            <Grid item xs={6} align="right">
              <ProjectClone project={project.data} />
            </Grid>
          </Feature>
        </Grid>
      </BoxItem>
      <Fields
        updateDetailSubDataType={setDetailSubDataType}
        accountMask={account_mask}
        enforceMask={enforce_mask}
        updateEnforceMask={value => updateEnforceMask(value, props)}
      />
      <UsersAndDepartments
        nonEmployees={nonEmployees}
        detailSubDataType={detailSubDataType}
        projectId={projectId}
        enforceLoanOut={enforce_loan_out}
        enforceMask={enforce_mask}
        accountMask={account_mask}
      />
      <Details
        project={projectDetails}
        states={states}
        onCancel={() => cancel(props)}
        onChangeReimbursements={(key, val) =>
          changeReimbursements(key, val, props)
        }
        onSaveFailure={() => scrollUpToError(errorBox)}
      />
    </BoxBody>
  );
};

ProjectSettings.mutators = {
  projectDetails: {
    info: function(_, related) {
      var routerParams = related['/router/params'];

      return {
        id: '/projects/' + routerParams.projectId + '/details',
      };
    },
  },

  projectEnforceMask: {
    info: function(_, related) {
      const routerParams = related['/router/params'];

      return {
        id: `/projects/${routerParams.projectId}/enforce-mask`,
        create: true,
      };
    },
  },
};

ProjectSettings.queries = {
  nonEmployees: {
    info: function(_, related) {
      var routerParams = related['/router/params'];

      return {
        id: '/projects/' + routerParams.projectId + '/non-employees',
      };
    },
  },

  customFields: {
    info: function(_, related) {
      var params = related['/router/params'];

      return {
        id: '/projects/' + params.projectId + '/custom-fields',
      };
    },
  },

  project: {
    info: function(_, related) {
      var params = related['/router/params'];

      return {
        id: '/projects/' + params.projectId,

        cursor: {
          with_privileges: true,
        },
      };
    },
  },
};

const mapStateToProps = () => ({});

export default compose(connect(mapStateToProps), withApi)(ProjectSettings);
