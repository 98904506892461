import withApi from 'common/hoc/withApi';
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import DesktopDocumentSigner from './DesktopDocumentSigner';
import MobileDocumentSigner from './MobileDocumentSigner';
import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import ajax from 'common/utilities/ajax.js';
import useIsMobile from 'common/hooks/useIsMobile';

const styles = {
  loader: {
    gridArea: 'content',
  },
};

const DocumentSignerContainer = props => {
  const {
    classes = {},
    documents: documentsQuery = {},
    handleOfferAccept: upstreamHandleOfferAccept,
    handleOfferReject,
    handleSave,
    me,
    offer: { project_id: projectId } = {},
    params: { offerId: paramsOfferId } = {},
    profile = {},
    routerParams: { offerId: routerOfferId } = {},
  } = props;

  const [imagesFailed, setImagesFailed] = useState(false);
  const [templatesAcknowledgeTitle, setTemplatesAcknowledgeTitle] = useState(
    '',
  );
  const [templatesAcknowledgeNotes, setTemplatesAcknowledgeNotes] = useState(
    '',
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!projectId) return;
    ajax
      .get(`/projects/${projectId}`)
      .then(({ templates_acknowledge_notes, templates_acknowledge_title }) => {
        setTemplatesAcknowledgeNotes(templates_acknowledge_notes);
        setTemplatesAcknowledgeTitle(templates_acknowledge_title);
      });
  }, [projectId]);

  const handleOfferAccept = docs => {
    const { data = {} } = documentsQuery;
    const { items: documents = [] } = data;
    const newDocs = {};
    docs
      .filter(doc => doc.ready && !doc.signed)
      .forEach(doc => (newDocs[doc.id] = doc));
    return upstreamHandleOfferAccept(newDocs, paramsOfferId, documents);
  };

  const getDocumentImageUrls = ({ documentId, page }) => {
    // Crew Member: offerId exists in routerParams
    // Approver, I9 Verifier: offerId exists in params
    const offerId = routerOfferId || paramsOfferId;

    const ajaxQuery = page ? `?page=${page}` : '';
    const ajaxUrl = `/v2/offers/${offerId}/documents/${documentId}/images${ajaxQuery}`;
    return ajax
      .get(ajaxUrl)
      .then(({ items }) => {
        return items.map(({ url }) => url);
      })
      .catch(throwFatalError);
  };

  const throwFatalError = () => setImagesFailed(true);

  if (documentsQuery.status === 'loading' || profile.status === 'loading') {
    return <Loader className={classes.loader} />;
  }

  if (imagesFailed || documentsQuery.status === 'failed')
    return <FailureAlert queryName="the Offer Document Details" />;

  const { items: documentData = [] } = documentsQuery.data;
  const { first_name, last_name, middle_name } = profile.data;

  if (documentData.length === 0) return <div />;

  // We have to update the document data because of discrepencies
  // between what the documentation outlined and what the backend sends
  const updatedDocs = documentData.map(doc => ({
    ...doc,
    // source: images[doc.id],
    fieldMappings: doc.fields,
    name: doc.templateName,
    rank: 1,
  }));
  const name = [first_name, middle_name, last_name].filter(s => s).join(' ');
  const initials = [first_name, middle_name, last_name]
    .filter(s => s)
    .map(s => String(s)[0])
    .join('');
  const actor = { name, initials };

  const actions = {
    submit: {
      text: 'Accept Offer',
      action: handleOfferAccept,
    },
    cancel: {
      text: 'Reject Offer',
      action: handleOfferReject,
    },
  };
  if (handleSave) {
    actions.save = {
      text: 'Save Offer',
      action: handleSave,
    };
  }

  return isMobile ? (
    <MobileDocumentSigner
      documents={updatedDocs}
      actor={actor}
      getDocumentImageUrls={getDocumentImageUrls}
      throwFatalError={throwFatalError}
      actions={actions}
      templatesAcknowledgeNotes={templatesAcknowledgeNotes}
      templatesAcknowledgeTitle={templatesAcknowledgeTitle}
      me={me}
    />
  ) : (
    <DesktopDocumentSigner
      documents={updatedDocs}
      actor={actor}
      getDocumentImageUrls={getDocumentImageUrls}
      throwFatalError={throwFatalError}
      actions={actions}
      templatesAcknowledgeNotes={templatesAcknowledgeNotes}
      templatesAcknowledgeTitle={templatesAcknowledgeTitle}
      me={me}
    />
  );
};

DocumentSignerContainer.queries = {
  documents: {
    info: function(params, related) {
      const { offerId: routerOfferId } = related['/router/params'];
      const { offerId: paramsOfferId } = params;
      const offerId = routerOfferId || paramsOfferId;
      return {
        id: `/v2/offers/${offerId}/documents-details`,
      };
    },
  },
  profile: {
    info: function() {
      return {
        id: `/account/profile`,
      };
    },
  },
  me: {
    info() {
      return { id: '/me' };
    },
  },
};

export default compose(withApi, withStyles(styles))(DocumentSignerContainer);
