import React from 'react';
import {
  withStyles,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';

const styles = {
  paper: {
    width: '500px',
    maxHeight: '400px',
    overflow: 'hidden',
  },
  addressSuggestionsContainer: {
    width: '500px',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
};
const AddressSuggestions = props => {
  const {
    selectSuggestion,
    closeAction,
    classes,
    addresses,
    className,
  } = props;
  if (addresses.length === 0) {
    return <div />;
  }
  const suggestions = addresses.map(address => {
    const { Id, Text, Description } = address;
    return (
      <ListItem
        key={Id}
        button
        onClick={() => selectSuggestion({ ...address, use_primary: false })}
      >
        <ListItemText primary={Text} secondary={Description} />
      </ListItem>
    );
  });
  try {
    return (
      <Paper className={classNames(className, classes.paper)} square>
        <div className={classes.addressSuggestionsContainer}>
          <ListItem key={`address-header`}>
            <ListItemText
              primary="Addresses"
              secondary="Please Select your address"
            />
            <IconButton onClick={closeAction}>
              <Close />
            </IconButton>
          </ListItem>
          <Divider />
          {suggestions}
        </div>
      </Paper>
    );
  } catch (e) {
    return <div />;
  }
};

export default withStyles(styles)(AddressSuggestions);
