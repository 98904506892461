import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    padding: '22px 30px',
    borderBottom: '1px solid #e6e7ea',
    background: '#ffffff',
  },
};

const BoxHeader = props => {
  const { classes, children } = props;
  return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(BoxHeader);
