import global from './utils/global.js';

export const CUSTOMER_SERVICE = {
  EMAIL: 'plus.support@castandcrew.com',
  PHONE: '+1 818 860-7770',
};
export const DATE_FORMAT = {
  DB: 'YYYY-MM-DD HH:mm:ss Z',
  UI: 'MM-DD-YYYY',
  UI_DATETIME: 'MM-DD-YYYY h:mm A',
};
export const DEFAULT_PER_PAGE = 20;
export const ENVIRONMENT = global && global.ENVIRONMENT;
export const CASTANDCREW_LOGO = '/images/castandcrew-logo-white.png';
export const STARTPLUS_LOGO = '/images/startplus-logo-white.png';
export const KEEP_ALIVE_FREQUENCY = 30000;
export const LOGINPLUS_APPS_URL = global && global.LOGINPLUS_APPS_URL;

export default {
  CUSTOMER_SERVICE,
  DATE_FORMAT,
  DEFAULT_PER_PAGE,
  ENVIRONMENT,
  CASTANDCREW_LOGO,
  STARTPLUS_LOGO,
  KEEP_ALIVE_FREQUENCY,
  LOGINPLUS_APPS_URL,
};
