import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import sharedCss from '../../shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';
import truncate from '../../utilities/truncate';

const styles = theme => ({
  ...sharedCss.fields,
  textField: {
    position: 'absolute',
  },
  input: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
});

const TextField = props => {
  const {
    name,
    x,
    y,
    height,
    width,
    isPassword,
    isMultiline,
    handleChange,
    classes,
    value,
    setFieldRef,
    max,
    id,
    _derived: { isRequired, isDisabled },
  } = props;

  const fieldDynamicStyle = {
    top: y,
    left: x,
    height,
    width,
  };

  const inputClass = classNames(classes.input, fieldStatusClass(props));

  return (
    <MuiTextField
      id={name}
      inputRef={e => setFieldRef(e, y)}
      className={classes.textField}
      placeholder={isRequired ? 'Required' : ''}
      value={value === null || value === undefined ? '' : value}
      style={fieldDynamicStyle}
      multiline={isMultiline}
      type={isPassword ? 'password' : 'text'}
      onChange={e => handleChange(truncate(e.target.value, max))}
      margin="none"
      disabled={isDisabled}
      data-test-id={`TextField-root-${id}`}
      InputProps={{
        disableUnderline: isDisabled,
        classes: {
          input: inputClass,
        },
        inputProps: {
          'data-test-id': `TextField-input-${id}`,
        },
      }}
    />
  );
};

TextField.defaultProps = {
  isPassword: false,
  isMultiline: false,
};

export default withStyles(styles)(TextField);
