import React from 'react';
import moment from 'moment';
import { TableCell, TableRow as MuiTableRow } from '@material-ui/core';
import { Tooltip, Typography, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import {
  MoreVert as MoreVertIcon,
  Lens as LensIcon,
  Schedule as ExpireIcon,
} from '@material-ui/icons';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  icon: {
    height: 10,
    width: 10,
    marginRight: '1px',
  },
  expireIcon: {
    verticalAlign: 'middle',
    marginLeft: '8px',
    height: '18px',
  },
  red: {
    color: theme.palette.error.main,
  },
  green: {
    color: theme.palette.primary.main,
  },
  tagCell: {
    maxWidth: 200,
  },
  tagContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  categories: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  toolTip: {
    color: palette.lightGrey,
  },
  mappedIndicator: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  inline: {
    display: 'inline !important',
  },
});

const renderTags = (id, categories, classes) => {
  const MAX_CHARS = 24;
  const MAX_TAGS = 3;
  let splitPoint = 2;
  let accumulatedChars = 0;
  categories.forEach((cat, index) => {
    if (accumulatedChars < MAX_CHARS) accumulatedChars += cat.name.length;
    if (accumulatedChars >= MAX_CHARS && index < splitPoint) splitPoint = index;
  });
  if (categories.length > MAX_TAGS || accumulatedChars > MAX_CHARS) {
    const overflowTags = [...categories]
      .splice(splitPoint, categories.length)
      .map(t => t.name)
      .join(', ');
    const displayChips = [...categories]
      .splice(0, splitPoint)
      .map(({ name }) => <Chip label={name} key={`chip-${id}-${name}`} />);
    return (
      <div className={classes.tagContainer}>
        {displayChips}
        <Tooltip
          title={
            <Typography className={classes.toolTip}>{overflowTags}</Typography>
          }
          key={`tooltip-${id}`}
          data-test-id={`table-categories-tooltip-${id}`}
        >
          <Chip label="..." />
        </Tooltip>
      </div>
    );
  }
  const chips = categories.map(({ name }) => (
    <Chip label={name} key={`chip-${id}-${name}`} />
  ));
  return <div className={classes.tagContainer}>{chips}</div>;
};

const TableRow = props => {
  const { classes, handleOpenMenu, item } = props;
  const {
    categories,
    expired,
    expireOn,
    fieldsMapped,
    id,
    name,
    updatedBy,
    uploadedBy,
  } = item;
  const statusIconClass = classnames({
    [classes.red]: !fieldsMapped,
    [classes.green]: fieldsMapped,
    [classes.icon]: true,
  });
  const expireIconClass = classnames({
    [classes.red]: expired,
    [classes.expireIcon]: true,
  });
  const expireTooltipText = moment(expireOn).format('MM/DD/YYYY');

  const tooltipTitle = (
    <React.Fragment>
      <Typography color="inherit" className={classes.inline}>
        Expires On:
      </Typography>
      <Typography
        color="inherit"
        className={classnames(classes.bold, classes.inline)}
      >
        {` ${expireTooltipText}`}
      </Typography>
    </React.Fragment>
  );

  const friendlyCategories = renderTags(id, categories, classes);

  return (
    <MuiTableRow key={id}>
      <TableCell className={classes.tableCell}>
        <Typography
          variant="subtitle1"
          className={classes.inline}
          data-test-id={`TableBody-name-${id}`}
        >
          {name}
        </Typography>
        {expireOn && (
          <Tooltip
            title={tooltipTitle}
            placement="right"
            PopperProps={{
              'data-test-id': `TableBody-tooltip-popper-${id}`,
            }}
          >
            <ExpireIcon
              className={expireIconClass}
              data-test-id={`TableBody-expireIcon-${id}`}
            />
          </Tooltip>
        )}
      </TableCell>

      <TableCell className={classes.tableCell}>
        <Typography variant="subtitle1">{uploadedBy}</Typography>
      </TableCell>

      <TableCell className={classes.tableCell}>
        <Typography variant="subtitle1">{updatedBy}</Typography>
      </TableCell>

      <TableCell className={classnames(classes.tableCell, classes.tagCell)}>
        <Typography variant="subtitle1">{friendlyCategories}</Typography>
      </TableCell>

      <TableCell className={classes.tableCell}>
        <Typography className={classes.mappedIndicator}>
          <LensIcon className={statusIconClass} />
          {fieldsMapped ? ' Mapped' : ' Unmapped'}
        </Typography>
      </TableCell>

      <TableCell>
        <IconButton
          onClick={e => handleOpenMenu(e, item)}
          data-test-id={`document-menu-${item.id}`}
        >
          <MoreVertIcon />
        </IconButton>
      </TableCell>
    </MuiTableRow>
  );
};

export default withStyles(styles)(TableRow);
