import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// MaterialUI Components
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

// Custom Components
import MultiLevelMenu from 'common/components/MultiLevelMenu';

const styles = theme => ({
  systemContainer: {
    position: 'relative',
    width: '90%',
    height: 45,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  },
  icon: {
    cursor: 'pointer !important',
    position: 'absolute !important',
    right: `${theme.spacing.unit}px !important`,
  },
  label: {
    width: '85%',
  },
});

const SystemFieldSelect = props => {
  const {
    classes = {},
    onClick,
    disabled,
    menuIcon,
    multiLevelMenuProps = {},
    systemField,
  } = props;
  const { filteredOptions = [] } = multiLevelMenuProps || {};
  const { label = '', id: systemFieldId } = systemField || {};
  const systemFieldParent = filteredOptions.find(({ items = [] }) =>
    items.find(({ id }) => id && id === systemFieldId),
  );
  const { label: systemFieldParentLabel } = systemFieldParent || {};
  const parentLabel = systemFieldParentLabel
    ? `- ${systemFieldParentLabel}`
    : '';
  return (
    <div className={classes.systemContainer}>
      <Typography
        data-test-id="SystemFieldSelect-systemFieldLabel"
        className={classes.label}
      >
        {`${label} ${parentLabel}`}
      </Typography>
      <IconButton
        className={classes.icon}
        onClick={onClick}
        disabled={disabled}
        data-test-id="SystemFieldSelect-prePopulateMenuButton"
      >
        {menuIcon}
      </IconButton>
      <MultiLevelMenu {...multiLevelMenuProps} />
    </div>
  );
};

export default withStyles(styles)(SystemFieldSelect);
