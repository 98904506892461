import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Collapse, IconButton } from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import ListItem from 'admin/components/RoleMapper/ListItem';

const styles = theme => ({
  groupActions: {
    gridArea: 'actions',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: 'repeat(2, 49%)',
    gap: '2%',
    gridGap: '2%',
  },
  fieldGroupsContainer: {
    marginTop: 10,
    gridArea: 'fieldGroups',
    width: '100%',
  },
  subItem: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gap: '8px',
    gridGap: '8px',
  },
  subItemContent: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '85% 15%',
    gridTemplateAreas: `
    "fieldGroupName      fieldCount"
    "..............      .........."
    "actions             actions"
    `,
  },
  fieldGroupName: {
    gridArea: 'fieldGroupName',
  },
  fieldCount: {
    gridArea: 'fieldCount',
    justifySelf: 'end',
  },
  iconButton: {
    width: 24,
    height: 24,
    padding: 0,
  },
  bold: {
    fontWeight: 'bold',
  },
  ruleTitle: {
    display: 'flex',
    alignItems: 'center',
  },
});

const ConditionalRuleListItem = props => {
  const {
    classes,
    addFieldGroupToConditionalRule,
    rule,
    deleteRule,
    openDeleteDialog,
    openConfigureDialog,
    activeRuleGroupId,
    toggleRuleMapping,
    deleteGroupFromRule,
  } = props;
  const { fieldGroups } = rule;
  const [groupListOpen, setGroupListOpen] = useState(true);

  const renderFieldGroupList = () =>
    fieldGroups.map(fieldGroup => {
      const fieldGroupDeleteMessage = (
        <span>
          There are <b className={classes.bold}>{fieldGroup.fieldIds.length}</b>{' '}
          fields mapped to this group, are you sure you want to delete{' '}
          <b className={classes.bold}>{fieldGroup.name}</b>?
        </span>
      );
      const fieldGroupDeleteCallback = () =>
        deleteGroupFromRule(rule.id, fieldGroup.id);
      // Disable the map and delete buttons if the user is currently mapping a different field group to fields
      const isDisabled =
        activeRuleGroupId !== fieldGroup.id && activeRuleGroupId !== null;
      return (
        <div
          key={fieldGroup.id}
          className={classes.subItemContent}
          id={`ConditionalRuleListItem-${fieldGroup.id}`}
        >
          <Typography
            className={classes.fieldGroupName}
            data-test-id={`ConditionalRuleListItem-fieldGroupName-${fieldGroup.id}`}
          >
            {fieldGroup.name}
          </Typography>
          <Typography
            variant="caption"
            className={classes.fieldCount}
            data-test-id={`ConditionalRuleListItem-fieldCount-${fieldGroup.id}`}
          >
            {`${fieldGroup.fieldIds.length} fields`}
          </Typography>
          <div className={classes.groupActions}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => toggleRuleMapping(rule.id, fieldGroup.id)}
              disabled={isDisabled}
              data-test-id={`ConditionalRuleListItem-mapFieldGroupButton-${fieldGroup.id}`}
            >
              {activeRuleGroupId === fieldGroup.id ? 'Done' : 'Map'}
            </Button>
            <Button
              variant="contained"
              disabled={isDisabled || !!activeRuleGroupId}
              onClick={() =>
                openDeleteDialog(
                  fieldGroupDeleteMessage,
                  fieldGroupDeleteCallback,
                )
              }
              data-test-id={`ConditionalRuleListItem-deleteFieldGroupButton-${fieldGroup.id}`}
            >
              Delete
            </Button>
          </div>
        </div>
      );
    });

  const ruleDeleteMessage = (
    <span>
      There are <b className={classes.bold}>{fieldGroups.length}</b> groups
      assigned to this rule, are you sure you want to delete{' '}
      <b className={classes.bold}>{rule.name}</b>?
    </span>
  );
  const ruleDeleteCallback = () => deleteRule(rule.id);
  const ruleTitle = (
    <div className={classes.ruleTitle}>
      {fieldGroups.length ? (
        <IconButton
          onClick={
            groupListOpen
              ? () => setGroupListOpen(false)
              : () => setGroupListOpen(true)
          }
          classes={{ root: classes.iconButton }}
          data-test-id={`ConditionalRuleListItem-expandIconButton-${rule.id}`}
        >
          {groupListOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      ) : null}
      {rule.name}
    </div>
  );

  const listProps = {
    title: ruleTitle,
    subHeader: `${fieldGroups.length} groups`,
    buttons: [
      {
        disabled: !!activeRuleGroupId,
        title: 'Add Group',
        color: 'primary',
        onClick: () => addFieldGroupToConditionalRule(rule.id),
        dataTestId: `ConditionalRuleListItem-addGroupButton-${rule.id}`,
      },
      {
        disabled: !!activeRuleGroupId,
        title: 'Configure',
        color: 'secondary',
        onClick: () => openConfigureDialog(rule.id),
        dataTestId: `ConditionalRuleListItem-configureButton-${rule.id}`,
      },
      {
        disabled: !!activeRuleGroupId,
        title: 'Delete',
        onClick: () => openDeleteDialog(ruleDeleteMessage, ruleDeleteCallback),
        dataTestId: `ConditionalRuleListItem-deleteButton-${rule.id}`,
      },
    ],
    dataTestIds: {
      title: `ConditionalRuleListItem-ruleName-${rule.id}`,
      root: `ConditionalRuleListItem-root-${rule.id}`,
      subHeader: `ConditionalRuleListItem-groupCount-${rule.id}`,
    },
    key: `ConditionalRuleListItem-${rule.id}`,
  };

  return (
    <ListItem {...listProps}>
      <Collapse
        in={groupListOpen}
        unmountOnExit
        classes={{
          container: classes.fieldGroupsContainer,
          wrapperInner: classes.subItem,
        }}
        data-test-id={`ConditionalRuleListItem-collapseRoot-${rule.id}`}
      >
        {renderFieldGroupList()}
      </Collapse>
    </ListItem>
  );
};

export default withStyles(styles)(ConditionalRuleListItem);
