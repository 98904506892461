import { Typography, withStyles } from '@material-ui/core';
import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';

import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';
import RateCell from '../Offers/RateCell';
import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import format from 'common/oldJavascripts/utils/format.js';
import ToolTipBox from 'common/oldJavascripts/components/Shared/ToolTipBox';
import Badges from 'common/components/Badges';

import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const styles = () => ({
  levelName: {
    textAlign: 'center',
    fontSize: '1em',
    fontWeight: 600,
    marginTop: 8,
  },
  offerStatusCell: {
    overflow: 'unset',
  },
  badgesContainer: {
    marginTop: 6,
  },
});

const PendingLevel = ({ classes, levelName = '' }) => (
  <Typography className={classes.levelName}>{levelName}</Typography>
);
const StyledPendingLevel = withStyles(styles)(PendingLevel);

export default withStyles(styles)(
  Relay.createClass({
    render() {
      const {
        approval: offer = {},
        classes = {},
        'data-test-id': dataTestId,
      } = this.props;
      const {
        first_name = '',
        middle_name = '',
        last_name = '',
        id,
        email,
        account_code,
        start_date,
        department_name,
        occupation_description,
        union_description,
        status,
        send_date,
        pending_levels: pendingLevels = [],
        badges = [],
      } = offer;
      const { name: pendingLevelName = '' } = pendingLevels[0] || {};
      let pendingDocumentNames = [];
      if (pendingLevels.length) {
        const pendingLevelMinPosition = pendingLevels.reduce((prev, curr) =>
          prev.position < curr.position ? prev : curr,
        ).position;
        pendingDocumentNames = pendingLevels
          .filter(level => level.position === pendingLevelMinPosition)
          .map(lvl => lvl.document_name);
      }
      const documentNameTooltipContents = pendingDocumentNames.map(name => (
        <Typography>{name}</Typography>
      ));

      const fullName = format.fullName(first_name, middle_name, last_name);

      return (
        <ExpandableTableRow
          key={id}
          onClick={this._toggleDetails}
          data-test-id={dataTestId}
        >
          <ExpandableTableCell>
            <ExpandableTableCellTitle>{fullName}</ExpandableTableCellTitle>
            {email}
            <div
              className={classes.badgesContainer}
              data-test-id={`ProcessedRow-badges-${offer.id}`}
            >
              <Badges badges={badges} id={offer.id} />
            </div>
          </ExpandableTableCell>
          <ExpandableTableCell>
            <ExpandableTableCellTitle>
              {department_name}
            </ExpandableTableCellTitle>
          </ExpandableTableCell>
          <ExpandableTableCell>
            {occupation_description}
            <br />
            {account_code}
          </ExpandableTableCell>
          <ExpandableTableCell>{union_description}</ExpandableTableCell>
          <ExpandableTableCell>{format.date(start_date)}</ExpandableTableCell>
          {this._renderEndDateCell()}
          <RateCell offer={offer} />
          <ExpandableTableCell expanded={this.props.expanded} align="center">
            <AllowancesRow.Icon offer={offer} />
          </ExpandableTableCell>
          <ExpandableTableCell
            align="center"
            className={classes.offerStatusCell}
          >
            <OfferStatus
              status={status}
              startDate={start_date}
              sendDate={send_date}
            />
            <ToolTipBox
              align="top"
              tooltipNode={documentNameTooltipContents}
              bold={true}
            >
              <StyledPendingLevel levelName={pendingLevelName} />
            </ToolTipBox>
          </ExpandableTableCell>
        </ExpandableTableRow>
      );
    },

    _renderEndDateCell() {
      const { approval: offer = {} } = this.props;
      const { end_date } = offer;
      if (this.props.endDateAllowed) {
        return (
          <ExpandableTableCell>{format.date(end_date)}</ExpandableTableCell>
        );
      }
    },

    _toggleDetails() {
      this.props.onToggle(this.props.params.id);
    },
  }),
);
