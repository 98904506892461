import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';

import classNames from 'classnames';
import uniqueId from 'common/utilities/uniqueId';

const styles = theme => ({
  formControl: {
    flex: 1,
    marginRight: theme.spacing.unit * 0.5,
    marginLeft: theme.spacing.unit * 0.5,
    maxWidth: '100%',
  },
});

const ProjectTemplateAutoAssignmentRuleSelect = props => {
  const {
    classes,
    value,
    disabled,
    index,
    isLoading,
    type,
    options = [],
    handleUpdate,
    label,
    className,
  } = props;
  const isDepartmentSelect = type === 'department';
  let formattedOptions = [];
  if (isDepartmentSelect) {
    formattedOptions = options.map((e = {}) => ({
      label: e.description || e.name || '',
      value: e.id || '',
    }));
  } else {
    formattedOptions = options.map((e = {}) => ({
      label: e.description || e.name || '',
      value: e.code || '',
    }));
  }
  const getValueObject = code => {
    const valueObject = options.find(option =>
      isDepartmentSelect ? option.id === code : option.code === code,
    );
    return valueObject;
  };
  const rootClass = classNames(className, classes.formControl);
  const ruleSelectId = uniqueId();
  return (
    <FormControl
      className={rootClass}
      data-test-id={`ProjectTemplateAutoAssignmentRuleSelect-root-${`${index}_${type}`}`}
    >
      <InputLabel
        htmlFor={`select-rule-${ruleSelectId}-${type}`}
        data-test-id={`ProjectTemplateAutoAssignmentRuleSelect-label-${type}`}
      >
        {label} {isLoading && <CircularProgress size={12} />}
      </InputLabel>
      <Select
        disabled={disabled || isLoading}
        value={value || ''}
        inputProps={{
          name: `select-rule-${ruleSelectId}-${type}`,
          id: `select-rule-${ruleSelectId}-${type}`,
        }}
        onChange={e => handleUpdate(type, getValueObject(e.target.value))}
        MenuProps={{
          disableRestoreFocus: true,
        }}
        SelectDisplayProps={{
          'data-test-id': `ProjectTemplateAutoAssignmentRuleSelect-select-${`${index}_${type}`}`,
        }}
      >
        {formattedOptions.map(option => {
          const menuId = uniqueId();
          return (
            <MenuItem
              key={`RuleSelect-menuItem-${type}-${menuId}`}
              data-test-id={`ProjectTemplateAutoAssignmentRuleSelect-menuItem-${type}-${menuId}`}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRuleSelect);
