import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';

const styles = {
  actions: {
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
  },
  name: {
    width: '49%',
    marginRight: '2%',
  },
  initials: {
    width: '49%',
  },
  saveButton: {
    color: 'white',
    background: '#536881',
    marginBottom: 10,
    '&:hover': {
      background: '#435871',
    },
  },
  dontSaveButton: {
    color: 'white',
    background: '#ff0000',
    marginBottom: 10,
    '&:hover': {
      background: '#df0000',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
};

class SaveSignature extends Component {
  state = {
    enteredInitials: '',
    enteredName: '',
    isInitialsError: false,
    isNameError: false,
    isSignatureSaved: false,
  };

  componentDidMount() {
    const { onSaveSignature } = this.props;
    const isSignatureSaved = this.getIsSignatureSaved();
    this.setState({ isSignatureSaved }, () =>
      onSaveSignature(isSignatureSaved),
    );
  }

  // Returns true if signature is saved.
  // Returns false if signature is not saved.
  // Returns null if user must be asked whether or not to save signature.
  getIsSignatureSaved = () => {
    const {
      me: { data: { signature_session: token } = {} },
    } = this.props;

    // If no token then API call has not yet resolved so don't ask user yet.
    if (!token) return false;

    // Get the isSignature saved token/value pair. If not present, ask user.
    const savedTokenAndValue = window.sessionStorage.getItem(
      'isSignatureSaved',
    );
    if (!savedTokenAndValue) return null;

    // Check the token is for the right session. If it is, return saved value.
    const [savedToken, savedValue] = savedTokenAndValue.split('-');
    if (savedToken === token) return savedValue === 'true';

    // Token is for wrong session, return null.
    return null;
  };

  setIsSignatureSaved = isSignatureSaved => {
    const {
      me: { data: { signature_session: token } = {} },
      onSaveSignature,
    } = this.props;

    const tokenAndValue = `${token}-${!!isSignatureSaved}`;
    window.sessionStorage.setItem('isSignatureSaved', tokenAndValue);

    this.setState({ isSignatureSaved }, () =>
      onSaveSignature(isSignatureSaved),
    );
  };

  updateName = ev => {
    const {
      target: { value: enteredName },
    } = ev;
    this.setState({ enteredName, isNameError: false });
  };

  updateInitials = ev => {
    const {
      target: { value: enteredInitials },
    } = ev;
    this.setState({ enteredInitials, isInitialsError: false });
  };

  saveNoSignature = () => {
    this.setIsSignatureSaved(false);
  };

  saveSignature = () => {
    const {
      actor: { name: recordedName, initials: recordedInitials } = {},
    } = this.props;
    let isError = false;
    this.setState(
      ({ enteredName, enteredInitials }) => {
        const isNameError =
          enteredName.toLowerCase().trim() !==
          recordedName.toLowerCase().trim();
        const isInitialsError =
          enteredInitials.toLowerCase().trim() !==
          recordedInitials.toLowerCase().trim();
        isError = isNameError || isInitialsError;
        return { isInitialsError, isNameError };
      },
      () => {
        if (!isError) this.setIsSignatureSaved(true);
      },
    );
  };

  render() {
    const { actor, classes, documents, showI9Note } = this.props;
    const {
      enteredInitials,
      enteredName,
      isInitialsError,
      isNameError,
      isSignatureSaved,
    } = this.state;
    const offerRequiresValidatedSignature = documents.some(({ fields }) =>
      fields.some(({ _derived: { isValidated } }) => isValidated),
    );
    const isDialogOpen =
      isSignatureSaved == null && offerRequiresValidatedSignature;
    const isDisabled = !(enteredInitials.trim() && enteredName.trim());
    return (
      <Dialog
        open={isDialogOpen}
        aria-labelledby="save-signature-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle
          id="save-signature-dialog-title"
          data-test-id="SaveSignature-title"
        >
          Save Signature & Initials
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We can save your signature for the duration of our online session.
            If you choose to save your signature you will not be asked to type
            your signature again until you log out or close your browser.
            {showI9Note && (
              <>
                <br />
                <span className={classes.bold}>PLEASE NOTE</span>{' '}
                <span className={classes.italic}>
                  the exception will be that employees and I-9 verifiers must
                  type their signatures directly on the form to comply with
                  federal I-9 regulations.
                </span>
              </>
            )}
          </DialogContentText>
          <TextField
            className={classes.name}
            autoFocus
            margin="dense"
            label="Full Legal Name"
            type="text"
            value={enteredName}
            onChange={this.updateName}
            error={isNameError}
            inputProps={{ 'data-test-id': 'SaveSignature-name' }}
            helperText={
              isNameError && (
                <span data-test-id="SaveSignature-nameError">
                  Please enter your full legal name as recorded in Start+:
                  <br />
                  {actor.name}
                </span>
              )
            }
          />
          <TextField
            className={classes.initials}
            margin="dense"
            label="Initials"
            type="text"
            value={enteredInitials}
            onChange={this.updateInitials}
            error={isInitialsError}
            inputProps={{ 'data-test-id': 'SaveSignature-initials' }}
            helperText={
              isInitialsError && (
                <span data-test-id="SaveSignature-initialsError">
                  Please enter your initials as recorded in Start+:
                  <br />
                  {actor.initials}
                </span>
              )
            }
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={this.saveSignature}
            className={classes.saveButton}
            variant="contained"
            disabled={isDisabled}
            fullWidth
            data-test-id="SaveSignature-save"
          >
            Yes, save for the duration of my session
          </Button>
          <Button
            onClick={this.saveNoSignature}
            className={classes.dontSaveButton}
            variant="contained"
            fullWidth
            data-test-id="SaveSignature-dontSave"
          >
            No, don't save anything and don't ask me again this session
          </Button>
          <Typography variant="caption">
            Only the person associated with these records is permitted to use
            this system through this account. Completion and submission of the
            online forms available through this system will create legally
            binding obligations associated with each form. Please read each form
            carefully. Execution of each form will be accomplished through
            clicking on the “Sign Here” button, where required on the form. By
            clicking “Sign Here”, you are acknowledging that you have read the
            applicable form, understand the requirements for submission of the
            information, agree that the information you are submitting is
            complete and accurate information, and that the form can be
            submitted and signed by you through this system.
          </Typography>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SaveSignature);
