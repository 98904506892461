import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Person as PersonIcon } from '@material-ui/icons';

const styles = {
  root: { position: 'relative' },
  avatar: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '10px',
    '& img': { cssFloat: 'left', width: '55px', height: '55px' },
  },
  details: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  person: { '& img': { borderRadius: '100px' } },
  secondary: { '& img': { width: '35px', height: '35px' } },
  dots: {
    position: 'absolute',
    width: '4px',
    left: '26px',
    top: '66px',
  },
  dot: {
    width: '4px',
    height: '4px',
    marginTop: '2px',
    background: 'black',
    borderRadius: '10px',
  },
};

class AvatarDetails extends React.Component {
  render() {
    const { classes, person, secondary } = this.props;
    const rootClasses = classNames(classes.root, {
      [classes.person]: person,
      [classes.secondary]: secondary,
    });
    return (
      <div className={rootClasses}>
        <div className={classes.avatar}>
          <PersonIcon fontSize="large" />
          {this._renderDottedLine()}
        </div>
        <div className={classes.details}>{this.props.children}</div>
      </div>
    );
  }

  _renderDottedLine() {
    const { classes, linked } = this.props;
    if (linked) {
      return (
        <div className={classes.dots}>
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
        </div>
      );
    }
  }
}

export default withStyles(styles)(AvatarDetails);
