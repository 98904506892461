import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Components
import Dialog from '@material-ui/core/Dialog';
import OfferConfirmationModal from './OfferConfirmationModal';

const styles = theme => ({
  root: {
    minHeight: '350px',
    height: 'max-content',
    maxHeight: '700px',
    width: '600px',
    overflowY: 'auto',
  },
});

const OfferConfirmation = ({
  open,
  formData,
  onClose,
  onSubmit,
  submitInProgress = false,
  classes,
  crew,
} = {}) => (
  <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
    <OfferConfirmationModal
      formData={formData}
      onSubmit={onSubmit}
      submitInProgress={submitInProgress}
      onClose={onClose}
      crew={crew}
    />
  </Dialog>
);

export default withStyles(styles)(OfferConfirmation);
