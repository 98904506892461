import React from 'react';

// Components
import Typography from '@material-ui/core/Typography';

const OfferHeader = ({
  classes = {},
  title = 'Create Offer',
  children = null,
} = {}) => {
  return (
    <Typography variant="h3" className={classes.root} key={title}>
      {title}
      {children}
    </Typography>
  );
};

export default OfferHeader;
