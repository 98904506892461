import serializer from './serializer';
import getApiServer from 'common/constants/config/apiServer';
import { Promise } from 'q';
import { pushNotification as pushSnackbarNotification } from 'common/store/actions/snackbarNotifications';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import oktaAuthMyCnC from 'common/utilities/oktaAuthMyCnC';

const CALLBACKS_PREFIX = 'app_';

const doRequest = async function(method, url, params, options) {
  // Pull out dispatch so we can dispatch snackbars on 404
  // Pull out access token so we can use it in our Auth Header
  const oktaAuthClient = oktaAuthMyCnC();
  const store = await import('common/store');
  const { dispatch, getState } = store.default;
  const { tokens: { accessToken = '' } = {} } = getState();
  const myCnCToken = await oktaAuthClient.tokenManager.get('accessToken');

  return new Promise(function(resolve, reject, progress) {
    var request = new XMLHttpRequest();
    var data = null;

    options = options || {};
    params = params || {};
    let fullyQualifiedUrl;
    if (url.startsWith('http')) {
      fullyQualifiedUrl = url;
    } else {
      // Only send our credentials to our server
      fullyQualifiedUrl = `${getApiServer()}${url}`;
      request.withCredentials = true;
    }
    request.open(method, fullyQualifiedUrl, true);

    request.onload = async function() {
      var data = null;
      var contentType = request.getResponseHeader('Content-Type') || '';

      if (contentType.match('json')) {
        data = JSON.parse(request.responseText);
      }

      if (request.status >= 200 && request.status < 400) {
        resolve(data);
      } else {
        if (request.status === 404) {
          // User does not have permission to perform the requested action
          dispatch(
            pushSnackbarNotification({
              message:
                'Sorry, you do not have the permission to perform that action.',
              variant: SnackbarVariants.ERROR,
              duration: 7000,
            }),
          );
        }
        reject({ resource: data, status_code: request.status });
      }
    };

    request.onerror = function() {
      reject();
    };

    request.upload.onprogress = function(event) {
      if (event.lengthComputable) {
        progress(event.loaded / event.total);
      }
    };

    request.upload.onloadend = function() {
      progress(1);
    };

    request.setRequestHeader('Accept', 'application/json');
    request.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    if (myCnCToken && myCnCToken.accessToken) {
      request.setRequestHeader(
        'AuthorizationMyCnC',
        `Bearer ${myCnCToken.accessToken}`,
      );
    }

    if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
      if (options.multipart) {
        data = serializer.reduceParams(params, new global.FormData(), function(
          key,
          val,
          acc,
          unserialized,
        ) {
          acc.append(key, unserialized);

          return acc;
        });
        data.append('multipart', 'true');
      } else if (options.json) {
        data = JSON.stringify(params);
        request.setRequestHeader(
          'Content-Type',
          'application/json; charset=UTF-8',
        );
      } else {
        data = serializer.generateQuery(params || {});
        request.setRequestHeader(
          'Content-Type',
          'application/x-www-form-urlencoded; charset=UTF-8',
        );
      }
      request.send(data);
    } else {
      request.send();
    }
  });
};

export default {
  get: function(url, params) {
    url = serializer.generateURL(url, params || {});

    return doRequest('GET', url);
  },

  post: function(url, params, options) {
    return doRequest('POST', url, params, options);
  },

  put: function(url, params, options) {
    return doRequest('PUT', url, params, options);
  },

  patch: function(url, params, options) {
    return doRequest('PATCH', url, params, options);
  },

  delete: function(url) {
    return doRequest('DELETE', url);
  },

  getCrossOrigin: function(url, params, options) {
    return new Promise(function(resolve, reject) {
      var scriptEl = document.createElement('script');
      var randomSuffix = Math.floor(Math.random() * 100000000);
      var callbackName = null;

      options = options || {};
      callbackName = options.callbackName || CALLBACKS_PREFIX + randomSuffix;

      params = params || {};
      params.callback = callbackName;

      url = serializer.generateURL(url, params);

      window[callbackName] = function(data) {
        if (options.globalName) {
          resolve(window[options.globalName]);
        } else {
          resolve(data);
        }
      };

      scriptEl.async = true;
      scriptEl.src = url;
      scriptEl.addEventListener('load', function() {
        scriptEl.remove();
      });
      scriptEl.addEventListener('error', function() {
        scriptEl.remove();
        reject();
      });

      document.body.appendChild(scriptEl);
    });
  },
};
