import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offersByStatus($status: [String!]) {
    offersByStatus(status: $status) {
      id
      approvedAt
      badges
      project {
        name
      }
      rejectedAt
      rejectionComments
      sendDate
      status
      termsOfHire {
        occupation {
          description
        }
      }
    }
  }
`;

const useOffersByStatus = ({ statuses } = {}) => {
  const { error, loading, data } = useQuery(QUERY, {
    variables: { status: statuses },
    fetchPolicy: 'cache-and-network',
  });
  return { error, loading, data: data?.offersByStatus };
};

export default useOffersByStatus;
