import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';

const allowancesStyles = makeStyles(theme => ({
  documentTitle: {
    color: '#646464',
    fontSize: 24,
    fontWeight: 400,
    marginBottom: '30px',
    marginTop: '10px',
  },
  allowanceTitle: {
    marginBottom: '10px',
  },
  allowanceItemTitle: {
    fontWeight: 500,
    color: '#999999',
    fontSize: 14,
  },
  allowanceAmount: {
    marginTop: '10px',
    color: '#2eaba4',
    fontSize: '24px',
  },
  perDay: {
    color: '#999999',
    fontSize: 14,
    fontWeight: 300,
    position: 'relative',
    bottom: '5px',
  },
  duration: {
    marginTop: '10px',
    color: '#999999',
    fontSize: 12,
    fontWeight: 300,
  },
  durationInfo: {
    fontWeight: 500,
  },
  noDocs: {
    fontSize: '18px',
    color: '#999999',
    textAlign: 'center',
    paddingTop: '90px',
  },
  itemContainer: {
    '& div:nth-child(-n+2)': {
      borderBottom: '1px solid darkgrey',
    },
  },
  allowanceItemContainer: {
    paddingBottom: '15px',
    paddingTop: '10px',
    marginRight: '25px',
  },
}));

const isDecimalAmount = amount => {
  return !Number.isInteger(amount);
};

const Allowances = props => {
  const classes = allowancesStyles();
  const { terms = {}, allowances = [] } = props;
  const isEmptyAllowance = allowances.every(
    ({ key }) => !terms[`${key}_amount`],
  );

  return (
    <div className={classes.documentsContainer}>
      <Typography
        variant="h2"
        className={[classes.documentTitle, classes.allowanceTitle].join(' ')}
      >
        Allowances
      </Typography>
      {isEmptyAllowance && (
        <Typography className={classes.noDocs}>No Allowances</Typography>
      )}
      {terms && (
        <Grid container className={classes.itemContainer}>
          {allowances.map(({ key, title }) => {
            const amount = terms[key + '_amount'];
            const perDay = terms[key + '_per'];
            const duration = terms[key + '_duration'] || 'Run of Show';
            const cap = terms[key + '_cap'];
            if (!amount) return null;
            const allowanceAmount = isDecimalAmount(amount)
              ? currency(amount)
              : `$${amount}`;
            return (
              <Grid
                key={key}
                item
                xs={5}
                className={classes.allowanceItemContainer}
              >
                <Typography className={classes.allowanceItemTitle}>
                  {title}
                </Typography>
                <p className={classes.allowanceAmount}>
                  {allowanceAmount}
                  <span className={classes.perDay}> per {perDay}</span>
                </p>
                <Typography className={classes.duration}>
                  Duration:
                  <span className={classes.durationInfo}> {duration}</span>
                </Typography>
                <Typography className={classes.duration}>
                  Cap:{' '}
                  {cap && <span className={classes.durationInfo}> ${cap}</span>}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default Allowances;
