import React from 'react';

// Components
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaskedInput from 'react-text-mask';

// Constants
import { ratePerHourMask } from '../../helpers/textFieldNumberMasks';

// Utilities
import formatCurrency from '../../helpers/formatCurrency';

const OvertimeRatePerHour = ({
  onChange: upstreamOnChange,
  formData: { overtimeRatePerHour } = {},
  classes = {},
}) => {
  const onChange = ({ target: { value: rawValue } = {} } = {}) => {
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';

    upstreamOnChange({
      overtimeRatePerHour: value,
    });
  };

  const onBlur = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') return;

    const formattedValue = formatCurrency(value);

    upstreamOnChange({
      overtimeRatePerHour: formattedValue,
    });
    return;
  };

  return (
    <TableCell align="center" className={classes.root} colSpan={2}>
      <MaskedInput
        mask={ratePerHourMask}
        value={overtimeRatePerHour || ''}
        onChange={onChange}
        onBlur={onBlur}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': 'OvertimeRatePerHour-input',
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default OvertimeRatePerHour;
