import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Loader from 'common/components/Loader';
import Box from 'common/oldJavascripts/components/Base/Box';
import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Table from 'common/oldJavascripts/components/Base/Table';
import Button from 'common/oldJavascripts/components/Base/Button';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import T from 'common/oldJavascripts/utils/i18n';
import Header from 'common/oldJavascripts/components/Base/Header';
import Link from 'common/oldJavascripts/components/Base/Link';

import useManualArchives from 'studio/hooks/useManualArchives';
import { compose } from 'redux';

const styles = {
  error: {
    color: 'red',
  },
};

const Archives = props => {
  const { classes, projectId } = props;
  const {
    loading,
    error: { message: errorManualArchives } = {},
    data: archives = [],
  } = useManualArchives(projectId);

  const hasArchives = archives.length > 0;

  const archiveTableRow = archiveOffer => {
    const {
      id,
      firstName,
      lastName,
      position,
      sentDate,
      approvedDate,
    } = archiveOffer;

    return (
      <Table.Row>
        <Table.Cell data-test-id={`Archives-name-${id}`}>
          {firstName.concat(' ', lastName)}
        </Table.Cell>
        <Table.Cell data-test-id={`Archives-position-${id}`}>
          {position}
        </Table.Cell>
        <Table.Cell data-test-id={`Archives-sentDate-${id}`}>
          {sentDate}
        </Table.Cell>
        <Table.Cell data-test-id={`Archives-approvedDate-${id}`}>
          {approvedDate}
        </Table.Cell>
        <Table.Cell>
          <Link to={`archives/${id}`} data-test-id={`Archives-viewLink-${id}`}>
            {T('archives.actions.view_packets')}
          </Link>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Box>
      <BoxBody>
        <BoxItem>
          <Header>
            <Header.Title>{T('archives.manual.title')}</Header.Title>
            <Header.Nav>
              <Button to={`archives/new`} data-test-id="Archives-addManual">
                {T('archives.new.action')}
              </Button>
            </Header.Nav>
          </Header>
        </BoxItem>
        {loading && (
          <BoxItem>
            <Loader />
          </BoxItem>
        )}
        {!loading && errorManualArchives && (
          <Typography className={classes.error}>
            Failed loading manual archives: {errorManualArchives}
          </Typography>
        )}
        {!loading && !errorManualArchives && !hasArchives && (
          <BoxItem>
            <Blankslate>{T('archives.not_found')}</Blankslate>
          </BoxItem>
        )}
        {!loading && !errorManualArchives && hasArchives && (
          <Table data-test-id="RelatedProjects-table">
            <Table.Header>
              <Table.Cell header>Name</Table.Cell>
              <Table.Cell header>Position</Table.Cell>
              <Table.Cell header>Sent Date</Table.Cell>
              <Table.Cell header>Approved Date</Table.Cell>
              <Table.Cell header>Actions</Table.Cell>
            </Table.Header>
            {archives.map(archiveTableRow)}
          </Table>
        )}
      </BoxBody>
    </Box>
  );
};

export default compose(withStyles(styles))(Archives);
