import React, { Component } from 'react';

import DepartmentItemForm from './ItemForm';
import Link from 'common/oldJavascripts/components/Base/Link';
import Loader from 'common/components/Loader';
import {
  Item,
  ItemNav,
  ItemContent,
  ItemContentBody,
} from 'common/oldJavascripts/components/Base/List';
import { Button, Paper, Slide } from '@material-ui/core';
import * as Queries from 'common/apollo/queries';
import status from 'common/oldJavascripts/utils/react/status';
import { pushNotification } from 'common/store/actions/snackbarNotifications';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { graphql } from 'react-apollo';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  contentContainer: {
    display: 'flex',
  },
  slide: {
    display: 'flex',
  },
  listItemText: {
    paddingLeft: 8,
    placeSelf: 'center',
  },
});

class DepartmentListItem extends Component {
  static mutators = {
    department: {
      info(params, related) {
        const routerParams = related['/router/params'];

        return {
          id: `/v2/projects/${routerParams.projectId}/departments/${params.id}`,
        };
      },

      stale(params, related) {
        const { id } = params;
        const { projectId } = related['/router/params'];
        return [
          `/v2/projects/${projectId}/departments`,
          `/v2/projects/${projectId}/departments/${id}/department-rules`,
        ];
      },
    },
  };

  state = {
    expanded: false,
    confirmDeletion: false,
  };

  render() {
    const { department: { data: { id } = {} } = {}, classes } = this.props;
    if (this._getStatus() === 'loading' && this.state.confirmDeletion) {
      return <Loader />;
    }
    const { confirmDeletion } = this.state;

    return (
      <Item expanded={confirmDeletion}>
        <ItemContent className={classes.contentContainer}>
          <Slide
            direction="right"
            in={confirmDeletion}
            mountOnEnter
            unmountOnExit
            className={classes.slide}
          >
            <Paper elevation={0}>
              <Button
                color="secondary"
                variant="contained"
                onClick={this._delete}
                data-test-id={`Item-deleteConfirm-${id}`}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                onClick={this._collapseConfirmDelete}
                style={{ marginLeft: '8px' }}
                data-test-id={`Item-deleteCancel-${id}`}
              >
                Cancel
              </Button>
            </Paper>
          </Slide>
          {this._renderContent()}
          {!confirmDeletion && this._renderNav()}
        </ItemContent>
      </Item>
    );
  }
  _renderContent = () => {
    return this.state.expanded
      ? this._renderDepartmentForm()
      : this._renderDepartmentHead();
  };

  _renderNav = () => {
    const { department: { data: { id } = {} } = {} } = this.props;
    if (!this.state.expanded) {
      return (
        <ItemNav>
          <Link onClick={this._expandForm} data-test-id={`Item-editLink-${id}`}>
            Edit
          </Link>{' '}
          <Link
            onClick={this._expandConfirmDelete}
            data-test-id={`Item-deleteLink-${id}`}
          >
            Delete
          </Link>
        </ItemNav>
      );
    }
  };

  _renderDepartmentForm = () => {
    const department = this.props.department;
    const { detailSubDataType, enforceMask, accountMask } = this.props;

    return (
      <DepartmentItemForm
        params={{ id: department.data.id }}
        department={department}
        onCancel={this._collapseForm}
        onSubmit={this._saveDepartment}
        detailSubDataType={detailSubDataType}
        enforceMask={enforceMask}
        accountMask={accountMask}
      />
    );
  };

  _renderDepartmentHead = () => {
    const {
      department: { data: department = {} },
      data: { ecsMasterDepartments = [] },
      classes,
    } = this.props;
    const {
      name,
      code = '',
      head_first_name: firstName,
      head_last_name: lastName,
      master_department_id: masterDepartmentId,
    } = department || {};

    const masterDepartment =
      ecsMasterDepartments.find(
        item => item.id === String(masterDepartmentId),
      ) || {};

    const masterDepartmentName = masterDepartmentId
      ? `| ${masterDepartment.name}`
      : '';

    return (
      <ItemContentBody
        className={classes.listItemText}
      >{`${name} ${masterDepartmentName} | ${firstName} ${lastName} | ${code.toUpperCase()}`}</ItemContentBody>
    );
  };

  _delete = () => {
    const { department: departmentMutator = {}, pushNotification } = this.props;
    departmentMutator.save(
      'delete',
      { excludeActionFromPath: true },
      () => {
        pushNotification({
          message: 'Department has been successfully deleted.',
          variant: SnackbarVariants.SUCCESS,
        });
        this.setState({ confirmDeletion: false });
      },
      () => {
        pushNotification({
          message:
            'This department cannot be deleted because there are offers or notifications associated with it.',
          variant: SnackbarVariants.ERROR,
        });
        this.setState({ confirmDeletion: false });
      },
    );
  };

  _collapseConfirmDelete = () => this.setState({ confirmDeletion: false });

  _expandConfirmDelete = () => this.setState({ confirmDeletion: true });

  _expandForm = () => this.setState({ expanded: true });

  _collapseForm = () => this.setState({ expanded: false });

  _saveDepartment = () => {
    const { department = {} } = this.props;
    department.save(
      'edit',
      { excludeActionFromPath: true },
      this._collapseForm,
    );
  };

  _getStatus = () => {
    const { department = {} } = this.props;
    return status([department]);
  };
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
};

const projectConfig = {
  options: ({ routerParams: { projectId } = {} }) => ({
    variables: { projectId: parseInt(projectId, 10) },
  }),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApi,
  graphql(Queries.GetMasterDepartments, projectConfig),
  withStyles(styles),
)(DepartmentListItem);
