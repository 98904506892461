import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import T from '../../utils/i18n';
import humanize from '../../utils/humanize';

const styles = {
  root: {
    fontSize: '0.92307692em',
    lineHeight: '1.53846154',
    color: '#ea5a5a',
    height: 'auto',
  },
  error: { display: 'block' },
};

// requires props to be passed down to apply conditional styles
class FormErrors extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <ul>{this._renderErrors()}</ul>
      </div>
    );
  }

  _getErrorMessage = code => {
    const key = `errors.${code}`;
    const errorString = T(key);
    if (errorString !== key) {
      return errorString;
    }
    return humanize(code);
  };

  _renderErrors = () => {
    const { classes } = this.props;
    return this.props.errors.map(errorCode => (
      <li className={classes.error} key={errorCode}>
        {this._getErrorMessage(errorCode)}
      </li>
    ));
  };
}

export default withStyles(styles)(FormErrors);
