import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MixedEmployeeTerms from 'mobile/components/Projects/MixedEmployeeTerms';
import UnionEmployeeTerms from 'mobile/components/Projects/UnionEmployeeTerms';
import CanadaOnly from 'common/components/CanadaOnly';
import useOfferCountry from 'common/components/SpecifiedCountryOnly/useOfferCountry';
import CurrencyIndicator from 'onboarding/components/CurrencyIndicator';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import moment from 'moment';

const employeeInfoStyles = makeStyles(theme => ({
  title: {
    color: '#999999',
    fontSize: 12,
    fontWeight: 400,
  },
  name: {
    fontSize: 24,
    fontWeight: 400,
    color: '#646464',
  },
  date: {
    fontSize: 14,
    color: '#646464',
    fontWeight: 400,
  },
  email: {
    fontSize: 10,
    color: '#0091ff',
    fontWeight: 400,
    marginTop: '3px',
    letterSpacing: '0.3px',
  },
  spacing: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  amount: {
    color: '#2eaba4',
    fontSize: 24,
    fontWeight: 400,
    marginRight: '10px',
  },
  description: {
    wordBreak: 'break-word',
  },
  flag: {
    display: 'block',
    margin: 'auto',
    width: 24,
    height: 24,
  },
  caption: {
    color: '#aaaaaa',
    fontSize: 10,
    fontWeight: 400,
    padding: 0,
    textAlign: 'center',
  },
  currencyIndicator: {
    left: 50,
    position: 'relative',
  },
}));

const EmployeeInfo = ({ offer }) => {
  const classes = employeeInfoStyles();
  const workScheduleCode = offer?.work_schedule_code;
  const { data: offerCountry } = useOfferCountry({ offerId: offer?.id });
  const flags = useFeatureFlags();
  const isNonUnion = offer?.union_is_non_union;
  const isUnionScheduleAFlagActive = flags.includes('UnionScheduleA');
  const isUnionScheduleA =
    workScheduleCode === 'A' && !isNonUnion && isUnionScheduleAFlagActive;
  const { id: offerId = null } = offer;

  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`EmployeeInfo-title-${offerId}`}
          >
            Employee
          </Typography>
          <Typography
            variant="h2"
            className={classes.name}
            data-test-id={`EmployeeInfo-fullName-${offerId}`}
          >
            {`${offer.first_name}  ${offer.last_name}`}
          </Typography>
          <Typography
            variant="h6"
            className={classes.email}
            data-test-id={`EmployeeInfo-email-${offerId}`}
          >
            {offer.email}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CanadaOnly>
            <CurrencyIndicator
              className={classes.currencyIndicator}
              countryCode={offerCountry?.code}
              currencyClasses={{ flag: classes.flag, caption: classes.caption }}
            />
          </CanadaOnly>
        </Grid>
      </Grid>
      <Grid container className={classes.spacing}>
        <Grid item xs={8}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`EmployeeInfo-position-${offerId}`}
          >
            Position/Title (Occupation Code)
          </Typography>
          <Typography
            className={classes.description}
            data-test-id={`EmployeeInfo-occupationDescription-${offerId}`}
          >
            {offer.occupation_description}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            className={classes.title}
            data-test-id={`EmployeeInfo-offerStartDateTitle-${offerId}`}
          >
            Start Date
          </Typography>
          <Typography
            className={classes.date}
            data-test-id={`EmployeeInfo-offerStartDate-${offerId}`}
          >
            {moment(offer.start_date).format('MM/DD/YYYY')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.spacing}>
        <Grid item xs={8}>
          <Typography variant="h6" className={classes.title}>
            Union
          </Typography>
          <Typography
            className={classes.description}
            data-test-id={`EmployeeInfo-unionDescription-${offerId}`}
          >
            {offer.union_description}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" className={classes.title}>
            Department
          </Typography>
          <Typography
            className={classes.description}
            data-test-id={`EmployeeInfo-departmentName-${offerId}`}
          >
            {offer.department_name}
          </Typography>
        </Grid>
        {!isUnionScheduleA && <MixedEmployeeTerms offer={offer} />}
        {isUnionScheduleA && <UnionEmployeeTerms offer={offer} />}
      </Grid>
    </div>
  );
};

export default EmployeeInfo;
