import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import classNames from 'classnames';
import Loader from 'common/components/Loader';

const styles = {
  nonEmployeeContent: {
    gridArea: 'content',
    display: 'grid',
    gridTemplateAreas: `
      "profilePicture"
      "   fullName   "
      "     email    "
      "   password   "
    `,
    gridTemplateRows: '90px 14% 14% 14%',
  },
  actionButton: {
    marginLeft: 'auto',
    backgroundColor: '#3c9dd7',
    color: 'white',
    width: '140px',
    '&:hover': {
      backgroundColor: '#59acdd',
    },
  },
  resetPasswordButton: {
    margin: 0,
  },
  profilePicture: {
    gridArea: 'profilePicture',
    position: 'relative',
  },
  row: {
    borderBottom: '1px solid #d2d2d2',
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 10,
  },
  fullName: {
    gridArea: 'fullName',
  },
  email: {
    gridArea: 'email',
  },
  password: {
    gridArea: 'password',
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 10,
  },
  input: {
    width: 250,
  },
  lastNameInput: {
    marginLeft: 25,
  },
  middleNameInput: {
    marginLeft: 25,
  },
  avatar: {
    width: 90,
    height: 90,
    borderRadius: '50%',
    background: 'orange',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #d2d2d2',
    fontSize: '3rem',
    color: 'white',
  },
  loader: {
    height: '18px',
  },
};

const NonCrewProfile = props => {
  const {
    isEditing_Name,
    firstName,
    middleName,
    lastName,
    email,
    resetPassword,
    saveProfile,
    updateInput,
    toggleEdit,
    classes = {},
    upstreamFirstName = '',
    upstreamLastName = '',
    profileIsLoading = false,
    passwordIsLoading = false,
  } = props;
  return (
    <div className={classes.nonEmployeeContent}>
      <div className={classes.profilePicture}>
        <div
          className={classes.avatar}
          data-test-id="NonCrewProfile-avatar"
        >{`${upstreamFirstName.charAt(0)}${upstreamLastName.charAt(0)}`}</div>
      </div>
      <div className={classNames(classes.row, classes.fullName)}>
        <TextField
          label="First Name"
          className={classes.input}
          disabled={!isEditing_Name}
          value={firstName}
          onChange={updateInput('firstName')}
          inputProps={{
            'data-test-id': 'NonCrewProfile-firstNameInput',
          }}
        />
        <TextField
          label="Middle Name"
          className={classNames(classes.middleNameInput, classes.input)}
          disabled={!isEditing_Name}
          value={middleName}
          onChange={updateInput('middleName')}
          inputProps={{
            'data-test-id': 'NonCrewProfile-middleNameInput',
          }}
        />
        <TextField
          label="Last Name"
          className={classNames(classes.input, classes.lastNameInput)}
          disabled={!isEditing_Name}
          value={lastName}
          onChange={updateInput('lastName')}
          inputProps={{
            'data-test-id': 'NonCrewProfile-lastNameInput',
          }}
        />
        <Button
          className={classes.actionButton}
          onClick={
            isEditing_Name ? () => saveProfile() : () => toggleEdit('Name')
          }
          data-test-id="NonCrewProfile-update"
        >
          {!profileIsLoading && (isEditing_Name ? 'Save Name' : 'Update Name')}
          {profileIsLoading && (
            <Loader color="white" size={18} className={classes.loader} />
          )}
        </Button>
      </div>
      <div className={classNames(classes.row, classes.email)}>
        <TextField
          label="Email"
          className={classes.input}
          disabled={true}
          value={email}
          inputProps={{
            'data-test-id': 'NonCrewProfile-emailInput',
          }}
        />
      </div>
      <div className={classes.password}>
        <Button
          className={classNames(
            classes.resetPasswordButton,
            classes.actionButton,
          )}
          onClick={resetPassword}
          data-test-id="NonCrewProfile-resetPassword"
        >
          {!passwordIsLoading && 'Reset Password'}
          {passwordIsLoading && (
            <Loader color="white" size={18} className={classes.loader} />
          )}
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(NonCrewProfile);
