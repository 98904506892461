import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';

import withApi from 'common/hoc/withApi';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { pushNotification } from 'common/store/actions/snackbarNotifications';

import T from 'common/oldJavascripts/utils/i18n';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Form from 'common/oldJavascripts/components/Shared/Form';

import SendOfferDefault from 'common/components/SendOfferDefault';
import Loader from 'common/components/Loader';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

import StudioPlusFolderSelector from 'admin/components/StudioPlusFolderSelector';

// hooks
import useHasHiringStatus from './hooks/useHasHiringStatus';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const updateSendToStudioPlus = (value, props) => {
  const { project, settings: settingsQuery = {}, pushNotification } = props;
  if (value) {
    const { data: settings = {} } = settingsQuery || {};
    const { production_company: productionCompany = {} } = settings || {};
    const { name: productionCompanyName } = productionCompany || {};
    const message = `Please ensure the production company${
      productionCompanyName ? `: ${productionCompanyName}` : ` name`
    } is configured in Studio+.`;
    pushNotification({
      message,
      variant: SnackbarVariants.WARNING,
    });
  }
  project.update('send_final_docs_to_studio_plus')(value);
};

const updateSendProvisionalDealMemo = (project, value) => {
  project.update('send_provisional_deal_memo')(value);
};

const updateEVerifyEmails = (project, value) => {
  project.update('enable_e_verify_email')(value);
};

const AdditionalDetails = props => {
  const { project, states, routerParams: { projectId } = {} } = props;
  const { data: projectData = {} } = project;
  const {
    offer_invitation_send: offerInvitationSend = 'null',
    employee_type: employeeType = null,
  } = projectData;
  const hiringStatusOptions = [
    { value: 'full_time', label: 'Full Time' },
    { value: 'variable', label: 'Variable' },
  ];
  if (!!employeeType) {
    hiringStatusOptions.push({
      value: 'opt_out_var',
      label: 'Opt-out Variable',
    });
    hiringStatusOptions.push({ value: 'part_time', label: 'Part Time' });
    hiringStatusOptions.push({ value: 'seasonal', label: 'Seasonal' });
    hiringStatusOptions.push({
      value: 'variable_ec',
      label: 'Variable (exclude Corps)',
    });
  }

  const activeFlags = useFeatureFlags();

  const {
    data: hasHiringStatus,
    loading: loadingHasHiringStatus,
  } = useHasHiringStatus(projectId);

  if (loadingHasHiringStatus) return <Loader />;

  const isCanadianProjectSupport = activeFlags.includes(
    'CanadianProjectSupport',
  );

  const showHiringStatus =
    (isCanadianProjectSupport && hasHiringStatus) || !isCanadianProjectSupport;

  return (
    <BoxItem divider>
      <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
        <span data-test-id="AdditionalDetails-title">
          {T('project.documents.additional_details.title')}
        </span>
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper elevation={0}>
            {showHiringStatus && (
              <div data-test-id="AdditionalDetails-hiring_status">
                <Form.Field
                  type="select"
                  mutator={project}
                  name="hiring_status"
                  grouped={true}
                  options={hiringStatusOptions}
                  label={T(
                    'project.documents.additional_details.hiring_status',
                  )}
                  disabled={!!employeeType}
                  clearable={false}
                />
              </div>
            )}
            <Form.Field
              mutator={project}
              label={T('project.documents.additional_details.allow_end_date')}
              type="checkbox"
              name="allow_end_date"
            />
            <Form.Field
              mutator={project}
              label={T(
                'project.documents.additional_details.allow_hirers_to_send_offers',
              )}
              type="checkbox"
              name="allow_hirers_to_send_offers"
            />
            <Form.Field
              mutator={project}
              label="Send Final Offer Documents to Studio+"
              type="checkbox"
              name="send_final_docs_to_studio_plus"
              onChange={value => updateSendToStudioPlus(value, props)}
            />
            {project?.data?.send_final_docs_to_studio_plus && (
              <StudioPlusFolderSelector
                directoryId={project?.data?.studio_plus_directory_id}
                onChangeDirectoryId={project.update('studio_plus_directory_id')}
              />
            )}
            <Form.Field
              mutator={project}
              label="Send deal memo on first approval"
              type="checkbox"
              name="send_provisional_deal_memo"
              onChange={value => updateSendProvisionalDealMemo(project, value)}
            />
            <UsaOnly>
              <span data-test-id="AdditionalDetails-everify">
                <Form.Field
                  mutator={project}
                  label="Enable E-verify emails"
                  type="checkbox"
                  name="enable_e_verify_email"
                  onChange={value => updateEVerifyEmails(project, value)}
                />
              </span>
            </UsaOnly>
            <Form.Field
              mutator={project}
              label={T(
                'project.documents.additional_details.start_backdate_limit',
              )}
              type="text"
              required={true}
              name="backdate_days_limit"
            />
            <Form.Field
              mutator={project}
              label="Grouped acknowledgment dialog title"
              type="text"
              name="templates_acknowledge_title"
            />
            <Form.Field
              mutator={project}
              label="Grouped acknowledgment dialog body"
              type="textarea"
              name="templates_acknowledge_notes"
              numRows={3}
            />
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper elevation={0}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              {T('project.documents.additional_details.custom_email_text')}
            </Typography>
            <Form.Field
              mutator={project}
              label={T(
                'project.documents.additional_details.custom_email_text',
              )}
              type="textarea"
              hideLabel={true}
              name="custom_email_text"
              numRows={15}
            />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <UsaOnly>
                  <Form.Field
                    type="select"
                    mutator={project}
                    label={T('Default Hire State')}
                    name="hire_state_id"
                    grouped={true}
                    options={states}
                    clearable={true}
                  />
                </UsaOnly>
                <CanadaOnly>
                  <Form.Field
                    type="select"
                    mutator={project}
                    label={T('Default Hire Province')}
                    name="hire_state_id"
                    grouped={true}
                    options={states}
                    clearable={true}
                  />
                </CanadaOnly>
              </Grid>
              <Grid item xs={6}>
                <UsaOnly>
                  <Form.Field
                    type="select"
                    mutator={project}
                    label={T('Default Work State')}
                    name="work_state_id"
                    grouped={true}
                    options={states}
                    clearable={true}
                  />
                </UsaOnly>
                <CanadaOnly>
                  <Form.Field
                    type="select"
                    mutator={project}
                    label={T('Default Work Province')}
                    name="work_state_id"
                    grouped={true}
                    options={states}
                    clearable={true}
                  />
                </CanadaOnly>
              </Grid>
            </Grid>
          </Paper>
          <SendOfferDefault
            value={offerInvitationSend}
            onChange={value => project.update('offer_invitation_send')(value)}
          />
        </Grid>
      </Grid>
    </BoxItem>
  );
};

AdditionalDetails.queries = {
  settings: {
    related: function() {
      return ['/router/params'];
    },
    info: function(_, related) {
      const params = related['/router/params'];
      return {
        id: `/v2/projects/${params.projectId}/settings`,
      };
    },
  },
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApi,
)(AdditionalDetails);
