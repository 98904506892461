import React, { Component } from 'react';

// Components
import ContactDetails from 'common/oldJavascripts/components/Base/ContactDetails';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import HelpIcon from '@material-ui/icons/Help';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Paper from '@material-ui/core/Paper';
import Loader from 'common/components/Loader';

import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import Separator from 'common/oldJavascripts/components/Base/Separator';
import MainHeader from 'common/oldJavascripts/components/Base/MainHeader';
import Typography from '@material-ui/core/Typography';

// HoC
import { withStyles } from '@material-ui/core/styles';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';

// Utilities
import classNames from 'classnames';
import T from 'common/oldJavascripts/utils/i18n';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'auto auto',
    gridGap: '25px',
    gap: '25px',
    placeItems: 'center',
  },
  header: {
    height: '40px',
    fontWeight: 700,
    fontSize: '24px',
    color: 'rgb(67, 79, 89)',
  },
  paper: {
    minHeight: 172,
    width: '500px',
  },
  contactGroupContainer: {
    padding: '8px 15px',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: 'auto auto',
    gap: '5px',
    gridGap: '5px',
  },
  contactDetailContainer: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
    fontSize: '0.92307692em',
    lineHeight: '1.07692308',
    fontWeight: '400',
  },
  icons: {
    height: '18px',
    width: 'auto',
  },
  helpIcon: {
    marginBottom: '-5px',
  },
});

class Help extends Component {
  static queries = {
    offer: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId,
        };
      },
    },

    hirer: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId + '/hirer',
        };
      },
    },
  };

  render() {
    const { offer: offerQuery = {}, hirer: hirerQuery = {} } = this.props;
    const { data: offer = {} } = offerQuery || {};
    const { data: hirer = {} } = hirerQuery || {};

    const { title = '', first_name = '', last_name = '', phone } = hirer || {};

    const { classes } = this.props;

    if (offerQuery.status === 'loading' || hirerQuery.status === 'loading') {
      return <Loader />;
    }

    return (
      <>
        <LayoutHeader>
          <MainHeader secondary={true}>
            <MainHeader.Title>
              <HelpIcon className={classes.helpIcon} /> {T('shared.help')}
            </MainHeader.Title>
          </MainHeader>
        </LayoutHeader>

        <LayoutContent>
          <div className={classes.root}>
            <Paper className={classNames(classes.crew, classes.paper)}>
              <BoxItem>
                <Typography className={classes.header}>
                  Hiring Managers For This Project
                </Typography>
              </BoxItem>

              <Separator />
              <BoxItem>
                <ContactDetails>
                  <ContactDetails.Title>
                    {first_name + ' ' + last_name}
                  </ContactDetails.Title>
                  <ContactDetails.Data secondary={true}>
                    <ContactDetails.DataValue>
                      {offer.project_name}
                      {title ? ` - ${title}` : ''}
                    </ContactDetails.DataValue>
                  </ContactDetails.Data>
                </ContactDetails>
                <div className={classes.contactGroupContainer}>
                  <div className={classes.contactDetailContainer}>
                    <EmailIcon className={classes.icons} />
                    <div>{hirer.email}</div>
                  </div>
                  {phone && (
                    <div className={classes.contactDetailContainer}>
                      <PhoneIcon className={classes.icons} />
                      <div>{phone}</div>
                    </div>
                  )}
                </div>
              </BoxItem>
            </Paper>

            <Paper className={classNames(classes.crew, classes.paper)}>
              <BoxItem>
                <Typography className={classes.header}>
                  {T('help.contact_information_title')}
                </Typography>
              </BoxItem>

              <Separator />
              <BoxItem>
                <ContactDetails>
                  <ContactDetails.Title>Customer Service</ContactDetails.Title>
                  <ContactDetails.Data secondary={true}>
                    <ContactDetails.DataValue>
                      Cast&Crew
                    </ContactDetails.DataValue>
                  </ContactDetails.Data>
                </ContactDetails>
                <div className={classes.contactGroupContainer}>
                  <div className={classes.contactDetailContainer}>
                    <EmailIcon className={classes.icons} />
                    <div>{T('help.support_email')}</div>
                  </div>
                  <div className={classes.contactDetailContainer}>
                    <PhoneIcon className={classes.icons} />
                    <div>{T('help.support_phone')}</div>
                  </div>
                </div>
              </BoxItem>
            </Paper>
          </div>
        </LayoutContent>
      </>
    );
  }
}

export default compose(withApi, withStyles(styles))(Help);
