import gql from 'graphql-tag';

export default gql`
  mutation updateProjectTemplate(
    $projectTemplateId: ID!
    $studioPlusDirectoryId: String!
    $studioPlusIntegrationEnabled: Boolean!
  ) {
    updateProjectTemplate(
      projectTemplateId: $projectTemplateId
      studioPlusDirectoryId: $studioPlusDirectoryId
      studioPlusIntegrationEnabled: $studioPlusIntegrationEnabled
    )
  }
`;
