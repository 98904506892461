import React, { Component } from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import _ from 'lodash';
import T from 'common/oldJavascripts/utils/i18n';
import reimbursementTypes from 'common/oldJavascripts/utils/reimbursementTypes';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Header from 'common/oldJavascripts/components/Base/Header';
import Table from 'common/oldJavascripts/components/Base/Table';
import Link from 'common/oldJavascripts/components/Base/Link';
import Badge from 'common/oldJavascripts/components/Base/Badge';
import DocumentTable from 'common/oldJavascripts/components/Base/DocumentTable';
import OfferContactDetails from './Offers/ContactDetails';
import OnboardingFooter from './Onboarding/Footer';
import RejectModal from './Onboarding/RejectModal';
import { createOfferCurrency as formatCurrency } from 'common/oldJavascripts/utils/format.js';
import AdditionalDealTerms from 'common/oldJavascripts/components/Pages/Project/Offers/AdditionalDealTerms.js';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser.js';
import ajax from 'common/utilities/ajax';
import history from 'common/constants/config/history';

import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import { withStyles } from '@material-ui/core/styles';
import Loader from 'common/components/Loader';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import classNames from 'classnames';

import BoxHeader from 'common/oldJavascripts/components/Base/Box/BoxHeader';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Modal from 'common/oldJavascripts/components/Base/Modal';
import Text from 'common/oldJavascripts/components/Base/Text';
import Button from 'common/oldJavascripts/components/Base/Button';
import getSSOUrl from 'common/constants/config/ssoUrl';

import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

var DocumentRow = null;
var DurationCell = null;
var EMPTY_VALUE = '--';

const DOCUMENTS_POLLING_INTERVAL = 10000;

const styles = theme => ({
  infoContainer: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: '100%',
    height: '400px',
  },
  leftGroup: {
    display: 'grid',
    paddingLeft: 30,
    gridTemplateColumns: '100%',
    gridTemplateRows: 'repeat(12, 8.33%)',
  },
  rightGroup: {
    display: 'grid',
    paddingLeft: 40,
    wordBreak: 'break-word',
  },
  formLabel: {
    color: 'rgba(105, 105, 105, 0.87)',
    fontSize: '1.1rem',
  },
  tableContainer: {
    display: 'grid',
    gridTemplateColumns: '47.5% 5% 47.5%',
    gridTemplateRows: '100%',
    gridTemplateAreas: `
    "terms ... reimbursements"`,
    marginBottom: '35px',
  },
  terms: {
    gridArea: 'terms',
    width: '100%',
  },
  reimbursements: {
    gridArea: 'reimbursements',
    width: '100%',
  },
  documentsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  downloadAllLink: {
    color: '#61a3ca',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  optedOutDownloadLink: {
    color: '#61a3ca',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '20px',
  },
  downloadIcon: {
    marginRight: '8px',
    marginBottom: '3px',
  },
  offerDetails: {
    fontWeight: 700,
  },
  documentDownloadIcon: {
    color: '#c7c7c7',
  },
  alignRight: {
    float: 'right',
  },
});

const CurrencyCell = ({ fractionalDigits, value }) => (
  <Table.Cell>
    {formatCurrency(Number(value), fractionalDigits) || EMPTY_VALUE}
  </Table.Cell>
);

DurationCell = Relay.createClass({
  render: function() {
    var value = Number(this.props.value);
    var fractionalDigits = this.props.fractionalDigits;

    fractionalDigits =
      typeof fractionalDigits === 'number' ? fractionalDigits : 2;
    value = value ? value.toFixed(fractionalDigits) : EMPTY_VALUE;

    return <Table.Cell>{value}</Table.Cell>;
  },
});

const TermsTable = withStyles(styles)(props => {
  const { offer = {} } = props;
  const renderUnion = () => {
    let { terms_of_employment: termsOfEmployment } = offer || {};
    if (!termsOfEmployment) termsOfEmployment = offer;

    return (
      <Table grid={true} compact={true}>
        <Table.Row header={true}>
          <Table.Cell width="200px">
            {T('employee.onboarding.details.terms_of_employment')}
          </Table.Cell>
          <Table.Cell>{T('employee.onboarding.details.studio')}</Table.Cell>
          <Table.Cell>{T('employee.onboarding.details.location')}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>
            {T('employee.onboarding.details.rate_per_hour')}
          </Table.Cell>
          <CurrencyCell value={termsOfEmployment.rate_per_hour_studio} />
          <CurrencyCell value={termsOfEmployment.rate_per_hour_location} />
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>
            {T('employee.onboarding.details.rate_per_week')}
          </Table.Cell>
          <CurrencyCell value={termsOfEmployment.rate_per_week_studio} />
          <CurrencyCell value={termsOfEmployment.rate_per_week_location} />
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>
            {T('employee.onboarding.details.guaranteed_hours')}
          </Table.Cell>
          <DurationCell value={termsOfEmployment.guaranteed_hours_studio} />
          <DurationCell value={termsOfEmployment.guaranteed_hours_location} />
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>
            {T('If rate does not include idle days(hours)')}
          </Table.Cell>
          <Table.Cell header={true} />
          <Table.Cell compact={true}>
            <Table grid={true} mini={true} secondary={true}>
              <Table.Row>
                <Table.Cell rowHeader={true} secondaryHeader={true}>
                  {T('employee.onboarding.details.pay_6th_day_at')}
                </Table.Cell>
                <CurrencyCell value={termsOfEmployment.pay_6th_day_rate} />
              </Table.Row>
              <Table.Row>
                <Table.Cell rowHeader={true} secondaryHeader={true}>
                  {T('employee.onboarding.details.pay_7th_day_at')}
                </Table.Cell>
                <CurrencyCell value={termsOfEmployment.pay_7th_day_rate} />
              </Table.Row>
            </Table>
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>{T('Over Time Rate')}</Table.Cell>
          <CurrencyCell value={termsOfEmployment.overtime_rate} />
        </Table.Row>
      </Table>
    );
  };

  const renderExempt = () => {
    let { terms_of_employment: termsOfEmployment } = offer || {};
    if (!termsOfEmployment) termsOfEmployment = offer;

    return (
      <Table grid={true} compact={true}>
        <Table.Row header={true}>
          <Table.Cell width="200px">
            {T('employee.onboarding.details.terms_of_employment')}
          </Table.Cell>
          <Table.Cell />
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>{T('Daily Rate')}</Table.Cell>
          <CurrencyCell value={termsOfEmployment.rate_per_day_studio} />
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>{T('Weekly Rate')}</Table.Cell>
          <CurrencyCell value={termsOfEmployment.rate_per_week_studio} />
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>{T('Weekly Rate Days')}</Table.Cell>
          <DurationCell
            fractionalDigits={0}
            value={termsOfEmployment.guaranteed_days_studio}
          />
        </Table.Row>
      </Table>
    );
  };

  const renderNonExempt = () => {
    // hack in order to support legacy and sp_service
    let { terms_of_employment: termsOfEmployment } = offer || {};
    if (!termsOfEmployment) termsOfEmployment = offer;

    return (
      <Table grid={true} compact={true}>
        <Table.Row header={true}>
          <Table.Cell width="200px">
            {T('employee.onboarding.details.terms_of_employment')}
          </Table.Cell>
          <Table.Cell />
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>{T('Hourly Rate')}</Table.Cell>
          <CurrencyCell value={termsOfEmployment.rate_per_hour_studio} />
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>{T('Overtime Rate')}</Table.Cell>
          <CurrencyCell value={termsOfEmployment.overtime_rate} />
        </Table.Row>

        <Table.Row>
          <Table.Cell rowHeader={true}>Guaranteed Hours</Table.Cell>
          <DurationCell
            fractionalDigits={1}
            value={termsOfEmployment.guaranteed_hours_studio}
          />
        </Table.Row>
      </Table>
    );
  };

  if (offer.union_is_non_union) {
    if (offer.work_schedule_code === 'C' || offer.work_schedule_code === 'D') {
      return renderExempt();
    } else {
      return renderNonExempt();
    }
  } else {
    return renderUnion();
  }
});

const ReimbursementTable = withStyles(styles)(props => {
  const { offer = {} } = props;
  let { terms_of_employment: termsOfEmployment } = offer || {};
  if (!termsOfEmployment) termsOfEmployment = offer;

  const rows = reimbursementTypes.map(type => (
    <Table.Row key={type}>
      <Table.Cell rowHeader={true}>
        {T('shared.reimbursements.' + type)}
      </Table.Cell>
      <CurrencyCell value={termsOfEmployment[type + '_amount']} />
      <Table.Cell>{termsOfEmployment[type + '_per'] || EMPTY_VALUE}</Table.Cell>
      <CurrencyCell value={termsOfEmployment[type + '_cap']} />
      <Table.Cell>
        {termsOfEmployment[type + '_duration'] || EMPTY_VALUE}
      </Table.Cell>
    </Table.Row>
  ));

  // filter rows with allowance set to 0
  const filteredRows = rows.filter(row => {
    const allowance = row.props.children[1].props.value;
    return allowance > 0;
  });

  return (
    // remove table when no allowances
    filteredRows.length > 0 && (
      <Table grid={true} compact={true}>
        <Table.Row header={true}>
          <Table.Cell width="200px">Allowance / Reimbursement</Table.Cell>
          <Table.Cell>{T('employee.onboarding.details.amount')}</Table.Cell>
          <Table.Cell>{T('employee.onboarding.details.per')}</Table.Cell>
          <Table.Cell>{T('employee.onboarding.details.cap')}</Table.Cell>
          <Table.Cell>{T('employee.onboarding.details.duration')}</Table.Cell>
        </Table.Row>
        {filteredRows}
      </Table>
    )
  );
});

DocumentRow = withStyles(styles)(
  Relay.createClass({
    render: function() {
      const { classes = {}, data: doc, offer } = this.props;
      const { templateName, name, status } = doc;
      const optedOut = offer.status === 'complete_on_paper';
      let isApproved;
      if (offer.has_packets) {
        let { approval_status } = doc;
        // anything like submitted_signed_X and submitted_i9_verifier should be
        // considered as "submitted"
        approval_status = /submitted_/.test(approval_status)
          ? 'submitted'
          : approval_status;
        isApproved = approval_status === 'approved';
      } else {
        isApproved = status === 'complete';
      }
      const isReady = this._isReady();
      const dlLinkTitle = isReady
        ? 'Download document'
        : 'Document is processing and will be available shortly';

      const offerStatusText = (docStatus, offerOptedOut) => {
        if (offerOptedOut) return 'Complete on Paper';
        if (docStatus === 'complete') return 'Approved';
        return 'Submitted for Approval';
      };
      return (
        <DocumentTable.Row key={doc.id}>
          <DocumentTable.Cell rowHeader={true}>
            {templateName || name}
          </DocumentTable.Cell>
          <DocumentTable.Cell
            data-test-id={`Details-offerStatusBadge-${doc.id}`}
          >
            <Badge success={isApproved || optedOut} warning={!isApproved}>
              {offer.has_packets
                ? T(`employee.offers.submitted.documents.${offer.status}`)
                : offerStatusText(status, optedOut)}
            </Badge>
          </DocumentTable.Cell>
          <DocumentTable.Cell>
            {optedOut ? (
              <Tooltip
                title="Unable to download individual documents for offers opted out of digital onboarding."
                data-test-id={`Details-downloadIconTooltip-${doc.id}`}
              >
                <DownloadIcon
                  className={classNames(
                    classes.documentDownloadIcon,
                    'list-itemNavIcon',
                  )}
                />
              </Tooltip>
            ) : (
              <Link
                icon={true}
                onClick={this._download}
                disabled={!isReady}
                title={dlLinkTitle}
                data-test-id={`Details-downloadLink-${doc.id}`}
              >
                {isReady && <DownloadIcon className="list-itemNavIcon" />}
                {!isReady && (
                  <span className="list-itemNavText">Processing...</span>
                )}
              </Link>
            )}
          </DocumentTable.Cell>
        </DocumentTable.Row>
      );
    },

    _isReady: function() {
      const {
        data: { lastStep },
        offer,
      } = this.props;
      if (offer.has_packets) return true;
      return lastStep === 'complete';
    },

    _download: function(e) {
      const { data: { id } = {}, onDownload: upstreamDownload } = this.props;
      const isReady = this._isReady();
      if (!isReady) return;
      upstreamDownload(id);
    },
  }),
);

class Details extends Component {
  static mutators = {
    offer: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId,
        };
      },
    },
  };
  static queries = {
    hirer: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId + '/hirer',
        };
      },
    },
    projectSettings: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId + '/project-settings',
        };
      },
    },
    documents: {
      info: function(_, related) {
        const { offerId } = related['/router/params'];
        return {
          id: `/v2/offers/${offerId}/documents-details`,
        };
      },
    },
    packets: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId + '/packets',
        };
      },
    },
  };

  state = {
    errors: [],
    showModal: false,
  };

  render() {
    const {
      offer: offerMutator = {},
      hirer: hirerQuery = {},
      documents: documentsQuery = {},
      packets: packetsQuery = {},
      projectSettings: projectSettingsQuery = {},
      routerPath = '',
      classes,
    } = this.props;
    const { data: { items: documentsData = [] } = {} } = documentsQuery || {};
    const { data: { items: packetsData = [] } = {} } = packetsQuery || {};
    const { data: projectSettings = {}, status: projectSettingsStatus } =
      projectSettingsQuery || {};
    const { data: hirer = {}, status: hirerStatus } = hirerQuery || {};
    const { data: offer = {}, status: offerStatus } = offerMutator || {};
    const {
      status,
      union = {},
      occupation = {},
      work_city = {},
      work_state = {},
      hire_state = {},
      work_schedule = {},
      allow_end_date,
      end_date,
      sent_at,
      has_packets,
    } = offer || {};

    const { errors, showModal } = this.state;
    const onboardingUrl = new RegExp('/onboarding/offers/\\d+/details');
    const submittedUrl = new RegExp('/onboarding/offers/\\d+/submitted');
    const isOnboarding = onboardingUrl.test(routerPath);
    const offerIsSubmitted = submittedUrl.test(routerPath);
    const offerIsApproved = status === 'approved';
    var documentsBox = null;
    var documentRows = [];
    var rejectModal = null;
    const optedOut = offer.status === 'complete_on_paper';
    const statusArray = [projectSettingsStatus, hirerStatus, offerStatus];
    if (statusArray.includes('loading')) return <Loader />;

    const isWaitingForDocuments = documentsData.find(
      doc => doc.lastStep !== 'complete',
    );
    if (isWaitingForDocuments && offerIsSubmitted) {
      setTimeout(() => documentsQuery.reload(), DOCUMENTS_POLLING_INTERVAL);
    }

    const formatDate = date =>
      moment(date, 'YYYY-MM-DD').format('MMM Do, YYYY');

    const endDateContent = () => {
      if (allow_end_date && end_date) {
        return [
          <Typography
            className={classes.formLabel}
            key="endDateTitle"
            variant="h6"
          >
            Anticipated End Date
          </Typography>,
          <Typography
            key="endDate"
            variant="subtitle1"
            className={classes.offerDetails}
          >
            {formatDate(end_date)}
          </Typography>,
        ];
      }
      return [
        <Typography
          className={classes.formLabel}
          key="sentDateTitle"
          variant="h6"
        >
          Sent Date
        </Typography>,
        <Typography
          key="sentDate"
          variant="subtitle1"
          className={classes.offerDetails}
          data-test-id="Details-sentDate"
        >
          {(sent_at && formatDate(sent_at)) || ''}
        </Typography>,
      ];
    };

    if (!work_schedule.description) {
      work_schedule.description = T(
        'work_schedules',
        work_schedule.code.toLowerCase(),
      );
    }

    if (this.state.showRejectModal) {
      rejectModal = (
        <RejectModal onClose={this.modalClose} onError={this.modalError} />
      );
    }

    const renderFooter = () => {
      const { documents: { status: documentQueryStatus } = {} } = this.props;
      if (!isOnboarding) return null;
      if (documentQueryStatus === 'loading') return <Loader />;
      return (
        <OnboardingFooter
          onAccept={this.onAccept}
          onReject={this.onReject}
          isProcessing={offerMutator.status === 'loading'}
          errors={errors}
        />
      );
    };

    if (!isOnboarding) {
      if (has_packets) {
        packetsData.forEach(packet => {
          const { documents = [] } = packet || {};
          documentRows = _.concat(
            documentRows,
            documents.map(doc => {
              return (
                <DocumentRow
                  params={{ id: doc.id }}
                  key={doc.id}
                  data={doc}
                  onDownload={this.downloadDocument}
                  offer={offer}
                />
              );
            }),
          );
        });
      } else {
        documentRows = documentsData.map(doc => {
          return (
            <DocumentRow
              params={{ id: doc.id }}
              key={doc.id}
              data={doc}
              onDownload={this.downloadDocument}
              offer={offer}
            />
          );
        });
      }
      documentsBox = (
        <Box>
          <BoxItem>
            <Header className={classes.documentsHeader}>
              <Header.Title>
                {offer.has_packets
                  ? 'Packets'
                  : T('employee.offers.submitted.documents.title')}
              </Header.Title>
              {offerIsApproved && !has_packets && (
                <div
                  className={classes.downloadAllLink}
                  onClick={() => this.downloadDocument(null, true)}
                  data-test-id="OfferDetails-finalEsignatureDownloadButton"
                >
                  <DownloadIcon className={classes.downloadIcon} /> Final
                  e-signature document
                </div>
              )}
              {optedOut && (
                <div
                  className={classes.optedOutDownloadLink}
                  onClick={this.downloadOptedOutDocuments}
                  data-test-id="OfferDetails-optedOutDownloadDocumentsButton"
                >
                  Download offer documents
                </div>
              )}
            </Header>
            <DocumentTable>
              <DocumentTable.Row header={true}>
                <DocumentTable.Cell header={true}>
                  {offer.has_packets
                    ? 'Packet Name'
                    : T(
                        'employee.offers.submitted.documents.document_name_label',
                      )}
                </DocumentTable.Cell>
                <DocumentTable.Cell header={true}>
                  {T('employee.offers.submitted.documents.status_label')}
                </DocumentTable.Cell>
                <DocumentTable.Cell header={true} />
              </DocumentTable.Row>
              {documentRows}
            </DocumentTable>
          </BoxItem>
        </Box>
      );
    }
    return (
      <LayoutContent>
        {rejectModal}
        <OfferContactDetails
          offer={offer}
          hirer={hirer}
          union={union.description}
        />

        <Box>
          <BoxHeader>
            <Header>
              <Header.Title>
                <span data-test-id="Details-projectName">
                  {offer.project_name}
                </span>
              </Header.Title>
              <Header.Title tertiary={true}>
                {projectSettings.production_type.description}
              </Header.Title>
            </Header>
          </BoxHeader>

          <BoxItem>
            <BoxItem minimal={true}>
              <Header>
                <Header.Title>
                  {T('employee.onboarding.details.project_information_title')}
                </Header.Title>
              </Header>
            </BoxItem>

            <div className={classes.infoContainer}>
              <div className={classes.leftGroup}>
                <Typography className={classes.formLabel} variant="h6">
                  {T('employee.onboarding.details.start_date_title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.offerDetails}
                  data-test-id="Details-startDate"
                >
                  {(offer.start_date && formatDate(offer.start_date)) || ''}
                </Typography>

                <Typography className={classes.formLabel} variant="h6">
                  {T('employee.onboarding.details.hire_state_title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.offerDetails}
                  data-test-id="Details-hireState"
                >
                  {hire_state.name}
                </Typography>
                <Typography className={classes.formLabel} variant="h6">
                  {T('employee.onboarding.details.work_state_title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.offerDetails}
                  data-test-id="Details-workState"
                >
                  {work_state.name}
                </Typography>

                <Typography className={classes.formLabel} variant="h6">
                  {T('employee.onboarding.details.work_city_title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.offerDetails}
                  data-test-id="Details-workCity"
                >
                  {work_city.name || '--'}
                </Typography>
                <Typography className={classes.formLabel} variant="h6">
                  {T('employee.onboarding.details.hiring_status_title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.offerDetails}
                  data-test-id="Details-hiringStatus"
                >
                  {T(
                    'employee.onboarding.details.hiring_status_' +
                      offer.hiring_status,
                  )}
                </Typography>
              </div>

              <div className={classes.rightGroup}>
                {endDateContent()}
                <Typography className={classes.formLabel} variant="h6">
                  {T('employee.onboarding.details.union_title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.offerDetails}
                  data-test-id="Details-union"
                >
                  {union.description}
                </Typography>

                <Typography className={classes.formLabel} variant="h6">
                  {T('employee.onboarding.details.occupation_code_title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.offerDetails}
                  data-test-id="Details-jobCodeTitle"
                >
                  {occupation.description}
                </Typography>

                {offer.custom_occupation_description && (
                  <div>
                    <Typography className={classes.formLabel} variant="h6">
                      {'Job Description'}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.offerDetails}
                      data-test-id="Details-jobDescription"
                    >
                      {offer.custom_occupation_description}
                    </Typography>
                  </div>
                )}
                <Typography className={classes.formLabel} variant="h6">
                  {T('employee.onboarding.details.schedule_title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.offerDetails}
                  data-test-id="Details-scheduleType"
                >
                  {work_schedule.description}
                </Typography>

                <Typography className={classes.formLabel} variant="h6">
                  {T('employee.onboarding.details.department_title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.offerDetails}
                  data-test-id="Details-department"
                >
                  {offer.department_name}
                </Typography>
              </div>
            </div>

            <div className={classes.tableContainer}>
              <div className={classes.terms}>
                <TermsTable offer={offer} />
              </div>

              <div className={classes.reimbursements}>
                <ReimbursementTable offer={offer} />
              </div>
            </div>
            <AdditionalDealTerms
              title="Additional Deal Terms / Comments (Optional)"
              notes={offer.notes || ''}
              oldNotes={[...(offer.old_notes || [])].reverse()}
              headerStyles={{ color: 'black', fontSize: '0.9em' }}
              currentDate={offer.sent_at}
            />
          </BoxItem>
          {isOnboarding && renderFooter()}
        </Box>
        {documentsBox}
        {showModal && this.incompleteRegistrationModal()}
      </LayoutContent>
    );
  }

  onAccept = event => {
    const {
      routerParams: params = {},
      documents: documentsQuery = {},
      offer: offerMutator = {},
    } = this.props;
    const { offerId } = params;
    event.preventDefault();
    let nextTransitionRoute = 'documents';
    let offer_status = 'onboarding_documents';
    const { items: documentsData = [] } = documentsQuery.data;
    // const packetsQuery = this.props.packets;
    // const packetsDocuments = packetsQuery.data.items;

    documentsData.forEach(doc => {
      const { categories = [] } = doc;
      const catNames = categories.map(cat => {
        const { name = '' } = cat;
        return name;
      });
      if (
        catNames.includes('i9') ||
        catNames.includes('I9') ||
        catNames.includes('I-9') ||
        catNames.includes('i-9')
      ) {
        offer_status = 'onboarding_i9';
        nextTransitionRoute = 'i9';
      }
    });

    documentsData.forEach(doc => {
      const { supportingDocumentTitles = [] } = doc;
      if (supportingDocumentTitles.length > 0) {
        offer_status = 'onboarding_supporting-documents';
        nextTransitionRoute = 'supporting-documents';
      }
    });
    offerMutator.updateAndSave(
      { status: offer_status },
      'status',
      {},
      function() {
        history.push(`/onboarding/offers/${offerId}/${nextTransitionRoute}`);
      },
      err => {
        this.onAcceptError(err);
      },
    );
  };

  onAcceptError = err => {
    this.checkRegistration();
    const { pushNotification } = this.props;
    const { showModal } = this.state;
    if (showModal) return;

    pushNotification({
      message: parseErrorMessages(err),
      variant: SnackbarVariants.ERROR,
    });
  };

  checkRegistration = () => {
    ajax.get('/registration').then(response => {
      const { registration_complete } = response;
      this.setState({ showModal: !registration_complete });
    });
  };

  onReject = event => {
    event.preventDefault();
    this.setState({ showRejectModal: true });
  };

  modalClose = () => {
    this.setState({ showRejectModal: false });
  };

  modalError = err => {
    const { pushNotification } = this.props;

    pushNotification({
      message: parseErrorMessages(err),
      variant: SnackbarVariants.ERROR,
    });

    this.setState({
      showRejectModal: false,
    });
  };

  downloadOptedOutDocuments = () => {
    const {
      routerParams: { offerId } = {},
      pushNotification,
      popNotification,
    } = this.props;
    const downloadUrl = `/v2/offers/${offerId}/opt-out`;
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .post(downloadUrl)
      .then(response => {
        Sentry.addBreadcrumb({ response });
        const { path } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification();
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
      })
      .catch(err => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
        Sentry.captureException(err);
      });
  };

  downloadDocument = (documentId, isFinalDocument = false) => {
    const {
      routerParams: { offerId } = {},
      pushNotification,
      popNotification,
    } = this.props;
    const documentDownloadUrl = isFinalDocument
      ? `/v2/offers/${offerId}/full-final-document`
      : `/v2/offers/${offerId}/download-documents/${documentId}`;
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(documentDownloadUrl)
      .then(response => {
        Sentry.addBreadcrumb({ response });
        const { path } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification();
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
      })
      .catch(err => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
        Sentry.captureException(err);
      });
  };

  incompleteRegistrationModal = () => {
    const { classes } = this.props;
    return (
      <Modal minimal={true}>
        <Header>
          <Header.Title quaternary={true}>
            InComplete Registration!
          </Header.Title>
        </Header>
        <BoxItem nopadding={true}>
          <Text paragraph={true} nowrap={false}>
            Sorry, but it looks like you haven't completed your registration.
            Please go to MyCast&Crew to finish registering before you accept
            your offer.
          </Text>
          <Text paragraph={true}>
            If you need help, contact support:{' '}
            <a href="mailTo:plus.support@castandcrew.com">
              plus.support@castandcrew.com
            </a>{' '}
            or <a href="tel:818.860.7770">818.860.7770</a>.
          </Text>
          <UsaOnly>
            <a
              href={`${getSSOUrl()}/startplusoffer`}
              className={classes.alignRight}
            >
              <Button variant="contained">Click Here</Button>
            </a>
          </UsaOnly>
          <CanadaOnly>
            <a
              href={`${getSSOUrl()}/startpluscanoffer`}
              className={classes.alignRight}
            >
              <Button variant="contained">Click Here</Button>
            </a>
          </CanadaOnly>
        </BoxItem>
      </Modal>
    );
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

export default compose(
  withApi,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Details);
