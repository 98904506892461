import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '@material-ui/icons/Check';

const styles = {
  dialogContent: {
    textAlign: 'center',
  },
  iconSuccess: {
    width: '100%',
    height: 128,
    color: '#2EABA4',
  },
  titleH5: {
    paddingBottom: 20,
  },
  projectName: {
    color: '#45a7dc',
    fontWeight: 'bolder',
  },
};

const Success = props => {
  const { classes, project: { name } = {} } = props;

  return (
    <div data-test-id="CloneDialog-success" className={classes.dialogContent}>
      <SuccessIcon className={classes.iconSuccess} />
      <Typography variant="h3" className={classes.titleH5}>
        Success!
      </Typography>
      <Typography>
        Project <span className={classes.projectName}>{name}</span> has been
        cloned!
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Success);
