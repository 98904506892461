import enforceMaxDigits from './enforceMaxDigits';

const removeTrailingZeros = rawValue => {
  let value = rawValue;
  if (typeof rawValue === 'number') value = String(rawValue);

  // Do not strip Zeros if there is no decimal
  if (!value.includes('.')) return value;

  let trailingZeroCount = 0;
  for (let i = value.length - 1; i >= 0; i -= 1) {
    const currentDigit = value[i];
    if (currentDigit === '0') {
      trailingZeroCount += 1;
      continue;
    } else {
      break;
    }
  }
  return value.substring(0, value.length - trailingZeroCount);
};

const addMinimumDecimalDigits = (rawValue = '', minimumDecimalDigits = 2) => {
  let value = rawValue;
  if (typeof rawValue === 'number') value = String(rawValue);
  // If the value is empty then return the value back
  if (value.length === 0) return value;
  // If there is no decimal add one with the minimum decimal digits
  if (!value.includes('.')) {
    const formattedString = `${value}.${'0'.repeat(minimumDecimalDigits)}`;
    return formattedString;
  }
  // Test how many digits are past the deicmal
  // If they are less than the minimum decimal digits fill it with 0's
  const splitValue = value.split('.');
  if (splitValue[1].length < minimumDecimalDigits) {
    const missingDecimalDigits = minimumDecimalDigits - splitValue[1].length;
    splitValue[1] = `${splitValue[1]}${'0'.repeat(missingDecimalDigits)}`;
    const formattedString = splitValue.join('.');
    return formattedString;
  }

  // The number met the specifications
  return value;
};

const formatCurrency = value => {
  const maxDigitFormatted = enforceMaxDigits(value);

  const trailingZerosFormatted = removeTrailingZeros(maxDigitFormatted);

  const minimumDecimalDigitsFormatted = addMinimumDecimalDigits(
    trailingZerosFormatted,
  );
  return minimumDecimalDigitsFormatted;
};

export default formatCurrency;
