// New Palette
export default {
  primary: {
    main: '#2dcc70',
    dark: '#009a43',
    light: '#6eff9f',
    contrastText: '#eeeeee',
  },
  secondary: {
    main: '#f3ac21',
    dark: '#bb7d00',
    light: '#ffde58',
    contrastText: '#000000',
  },
  tertiary: {
    main: '#37a8c0',
    light: '#72daf3',
    dark: '#007990',
    contrastText: '#eeeeee',
  },
  bottomDialogBackground: '#36a4bc',
};

// Colors from brand palette
export const bronze = '#a7774e';
export const grey = '#616264';
export const lightGrey = '#e3e5eb';
export const blue = '#536881';
export const navy = '#384455';
export const green = '#2dcc70';
export const orange = '#f28020';
export const yellow = '#f3ac21';
export const black = '#000000';
export const white = '#ffffff';

// Other colors - non sanctioned
// Provide reason we are using color to the right of the color
export const darkGrey = '#333'; // Found this on the C&C website as button hover
export const veryLightGrey = '#f4f8f7'; // Found on document signer mockups https://castandcrew.atlassian.net/browse/STAR-425

export const primary = bronze;
export const secondary = blue;
export const tertiary = yellow;

export const hover = darkGrey;
export const successHover = darkGrey; // We dont have multiple greens, but i envision that we will in the future
export const failureHover = darkGrey; // We dont have multiple reds/oranges, but i envision that we will in the future
export const disabled = grey;

export const text = '#eee'; // Use with dark backgrounds or button backgrounds
export const error = orange; // Brand guidelines do not provide a red.  We use orange.

export const success = green;
export const failure = orange; // Brand guidelines do not provide a red.  We use orange.

export const fieldHighlight = '#5f9e6375'; // Transparent version of corp's green.

export const roleMapperHighlight = 'rgba(227,229,235, 0.7)'; // Transparent lightGrey

// New colors!

export const red2 = '#ea595a';
export const green2 = '#2ecc71';
export const blue2 = '#3c9dd7';

export const transparentYellow = '#eeff0c4d';
export const transparentRed = '#ff0c0c26';
export const transparentGreen = '#53ff0c4d';
export const transparentBlue = '#0cdbff4d';
export const yellowHighlight = '#fae047';
export const error2 = red2;
export const success2 = green2;
export const crosshatch =
  "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc1JyBoZWlnaHQ9JzUnPgogIDxyZWN0IHdpZHRoPSc1JyBoZWlnaHQ9JzUnIGZpbGw9J3doaXRlJy8+CiAgPHBhdGggZD0nTTAgNUw1IDBaTTYgNEw0IDZaTS0xIDFMMSAtMVonIHN0cm9rZT0nIzg4OCcgc3Ryb2tlLXdpZHRoPScxJy8+Cjwvc3ZnPg==')";
