import React from 'react';
// Components
import Loader from 'common/components/Loader';
import { Redirect } from 'react-router';

// Utilities
import * as Sentry from '@sentry/browser';

// Actions
import { getResource } from 'common/store/actions/resources';

// HoC
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as permissionChecks from './permissionChecks';

/*
withPermissionProtection is a higher order component that is used to protect components 
to ensure that only users with the appropriate permissions gain access to the protected component.

We do not have a robust permission system on the backend so the front end has to do a bit more work
to accomplish this.  We have abstracted away the logic of checking the permission into a file called permissionChecks
which handles the low level work of going out and getting the data and returning: [true, false, 'loading'] responses

True: The user has the permission
False: the user does not have the permission
Loading: We do not yet know but the request has been made to find out if the user has the permission

This component can be passed a set of options. 

Options:
--------------
There are two ways of passing in the options.  The first is the full object notation:

permissions: <Array[String, Array[String]]>: an array of strings that the user must have to access the
            component.  If an array is passed inside of the array we verify to see that the user has atleast
            one of the permissions declared within.  
  Examples:  ['can_review_offers', 'is_admin']  - We check that the user has both permissions listed.
             ['is_admin', ['can_review_offers', 'can_create_offers']] - We check that the user has 'is_admin', and
                  atleast one of the permissions inside the nested array.

fallback: <React Node>: a component to display in the event that the user fails the permission check

fallbackRoute: <String>: if the user fails the permission check we push the user to this location
-------------------
Shorthand Notation:
-------------------
You can call this hoC by passing in strings instead of the options object and we treat it like the permissions array.
Example Implementation:
withPermissionProtection('is_admin', ['can_review_offers', 'can_create_offers'])(ProtectedComponent) - We check that the user has 'is_admin', and
                        atleast one of the permissions inside the nested array.
withPermissionProtection('can_review_offers', 'is_admin')(ProtectedComponent)  - We check that the user has both permissions listed.

You can only pass in permissions with the shorthand notation, if you need to use any other object from the options object
you must pass in the full object.
 */

const withPermissionProtection = (...args) => Component => {
  // Fallback is being capitalized because custom jsx elements must
  // start with a capital
  let [{ permissions = [], fallback: Fallback, fallbackRoute } = {}] =
    args || [];
  const C = props => {
    // validatePermission goes out and runs the permission check from our 'permissionChecks' file
    const validatePermission = permission => {
      const { [permission]: permissionCheck } = permissionChecks;
      if (typeof permissionCheck === 'function') return permissionCheck(props);
      // There is no permission check so we log a sentry issue and we fail the check
      Sentry.captureException(
        new Error(`No Permission Check Found for: ${permission}`),
        {
          extra: {
            permissionsRequested: permissions,
            permissionCheckKeys: Object.keys(permissionChecks),
            component: Component.displayName || Component.name,
          },
        },
      );
      return false;
    };

    // Dont fail if component sent in a single permission instead of array
    if (typeof permissions === 'string') permissions = [permissions];
    // Shorthand notation - We were passed in strings, or arrays
    if (args.every(arg => typeof arg === 'string' || Array.isArray(arg))) {
      permissions = [...args];
    }
    // Take the list of permissions and then run them agains the permission checks
    // We deny permission in the event that there is no permission check for that permission
    const persmissionCheckResults = permissions.map(permission => {
      // If we are passed in an array inside our permission array
      // We treat it as needing atleast one of the permissions in the array
      // This can only be used if the hoc is implemented with the full options object notation
      // not the shorthand notation
      if (Array.isArray(permission)) {
        return permission.some(validatePermission);
      }
      return validatePermission(permission);
    });
    const persmissionCheckPassed = persmissionCheckResults.every(
      result => result === true,
    );
    const persmissionCheckLoading = persmissionCheckResults.some(
      result => result === 'loading',
    );
    const persmissionCheckFailed = persmissionCheckResults.some(
      result => result === false,
    );
    if (persmissionCheckFailed) {
      // One or more of the permission checks came back false
      // We want to put this before Loading because if one has failed and
      // the other are loading we will still not be able to proceed
      //
      // Return the fallback component
      if (Fallback !== undefined && React.isValidElement(<Fallback />))
        return <Fallback />;
      // Push to the fallback route
      if (typeof fallbackRoute === 'string')
        return <Redirect push exact to={fallbackRoute} />;
      // Push to home, If user is logged out they will then be pushed to login
      return <Redirect push exact to="/" />;
    }
    // All feature flags needed are on
    if (persmissionCheckPassed) return <Component {...props} />;
    // One ore more Permission Checks are still loading
    if (persmissionCheckLoading) return <Loader />;
    // In the event that we get here log an error
    Sentry.captureException(
      new Error(`Permission fall through for: ${permissions}`),
    );
    return null;
  };

  C.displayName = `withPermissionProtection(${Component.displayName ||
    Component.name})`;

  const mapStateToProps = ({ resources }) => ({
    resources,
  });
  const mapDispatchToProps = {
    getResource,
  };

  return compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(C);
};

export default withPermissionProtection;
