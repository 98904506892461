import React, { Component } from 'react';

// Components
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import MainHeader from '../../Base/MainHeader';
import BaseLayout from './Base';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import SpecifiedCountryOnly from 'common/components/SpecifiedCountryOnly';

// Icons
import LensIcon from '@material-ui/icons/Lens';

// HoC
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import withApi from 'common/hoc/withApi';
import { withStyles } from '@material-ui/core/styles';
// Graphql
import * as Queries from 'common/apollo/queries';
// Utilities
import classNames from 'class-names';
// Constants
import * as palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  titleContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(2, max-content)',
    gap: '30px',
    height: '100%',
    overflow: 'hidden',
    width: 'max-content',
  },
  projectTitle: {
    marginLeft: '0',
    paddingLeft: '20px',
    fontSize: '1.53846154em',
    lineHeight: '3',
    color: '#ffffff',
    fontWeight: '700',
  },
  projectStatus: {
    alignSelf: 'center',
    fontSize: '1.33846154em',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  projectStatusLive: {
    color: '#ffffff',
  },
  projectStatusTest: {
    color: '#ffffff57',
  },
  statusContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gridTemplateColumns: '20px 1fr',
  },
  statusIcon: {
    height: '12px',
    width: '12px',
  },
  liveIcon: {
    color: palette.green,
  },
  testIcon: {
    color: '#3434348f',
  },
});

class ProjectLayout extends Component {
  static queries = {
    project: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: '/projects/' + params.projectId,

          cursor: {
            with_privileges: true,
          },
        };
      },
    },

    me: {
      info: function() {
        return { id: '/me' };
      },
    },
    documentSetup: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: `/v2/projects/${params.projectId}/document-setup`,
        };
      },
    },
  };

  renderHeaderItems = () => {
    const {
      location = {},
      me: meQuery = {},
      project: projectQuery = {},
      documentSetup: documentSetupQuery = {},
    } = this.props;
    const { data: me = {}, status: meStatus } = meQuery || {};
    const { data: documentSetup = {}, status: documentSetupStatus } =
      documentSetupQuery || {};

    const { data: project = {}, status: projectStatus } = projectQuery || {};
    const { privileges = [], id: projectId } = project || {};
    const { pathname } = location || {};

    const isLoading = status => status === 'loading';
    const statuses = [meStatus, projectStatus, documentSetupStatus];
    if (statuses.some(isLoading)) return null;

    const headerConfig = [
      {
        to: `settings`,
        label: 'Project Setup',
        permission: () => me.is_admin,
      },
      {
        to: `users`,
        label: 'Users',
        permission: () => me.is_admin,
      },
      {
        to: `invitations`,
        label: 'Invitations',
        permission: () => me.is_admin,
      },
      {
        to: `crew`,
        label: 'Manage Crew',
        permission: () =>
          privileges.includes('can_create_offers') || me.is_support_admin,
      },
      {
        to: `offers/new`,
        label: 'Create Offer',
        permission: () =>
          privileges.includes('can_create_offers') &&
          !me.is_support_admin &&
          documentSetup.result,
      },
      {
        to: `offers`,
        label: 'Manage Offers',
        permission: () =>
          privileges.includes('can_create_offers') ||
          privileges.includes('can_review_offers') ||
          me.is_support_admin,
      },
      {
        to: `approvals`,
        label: 'Approvals',
        permission: () =>
          privileges.includes('can_approve_offers') || me.is_support_admin,
      },
      {
        to: `reviews`,
        label: 'Reviews',
        permission: () => privileges.includes('can_review_offers'),
      },
      {
        to: `i9-verifications`,
        label: 'I-9 Reviews',
        permission: () =>
          privileges.includes('can_be_i9_verifier') || me.is_support_admin,
        countryCode: 'US',
      },
      {
        to: `archives`,
        label: 'Archives',
        permission: () => privileges.includes('can_access_archives'),
      },
      {
        to: `offer-documents`,
        label: 'Offers Documents',
        permission: () => privileges.includes('can_view_documents'),
      },
    ];

    return headerConfig
      .filter(({ permission }) => permission())
      .map(({ to, label, countryCode }) => (
        <SpecifiedCountryOnly countryCode={countryCode} key={to}>
          <MainHeader.Item
            to={`projects/${project.id}/${to}`}
            params={{ projectId: projectId }}
            isActive={pathname === `/projects/${projectId}/${to}`}
            data-test-id={`Project-headerItem-${to}`}
          >
            {label}
          </MainHeader.Item>
        </SpecifiedCountryOnly>
      ));
  };

  render() {
    const {
      me: { data: meData } = {},
      hasSidebar = false,
      children,
      data: { project = {}, stopPolling } = {},
      routerParams: { projectId } = {},
      routerPath,
      classes = {},
    } = this.props;

    const { is_admin, is_super_admin } = meData || {};
    const { status, name } = project || {};

    // If the status is live then there wont be any future changes
    if (status === 'live') stopPolling();

    const statusClass = classNames(classes.projectStatus, {
      [classes.projectStatusLive]: status === 'live',
      [classes.projectStatusTest]: status === 'test',
    });

    const iconClass = classNames(classes.statusIcon, {
      [classes.liveIcon]: status === 'live',
      [classes.testIcon]: status === 'test',
    });

    const statusLinkUrl = `/projects/${projectId}/documents`;
    const statusClickable =
      status === 'test' &&
      (is_admin || is_super_admin) &&
      routerPath !== statusLinkUrl;

    const statusLoaded = name && status;

    return (
      <BaseLayout hasSidebar={hasSidebar}>
        <LayoutHeader>
          <MainHeader secondary={true}>
            {statusLoaded && (
              <div className={classes.titleContainer}>
                <Typography className={classes.projectTitle}>{name}</Typography>{' '}
                <div className={classes.statusContainer}>
                  <LensIcon className={iconClass} />
                  {statusClickable && (
                    <Link href={statusLinkUrl} className={statusClass}>
                      {status}
                    </Link>
                  )}
                  {!statusClickable && (
                    <Typography className={statusClass}>{status}</Typography>
                  )}
                </div>
              </div>
            )}
            <MainHeader.Nav secondary={true}>
              {this.renderHeaderItems()}
            </MainHeader.Nav>
          </MainHeader>
        </LayoutHeader>
        {children}
      </BaseLayout>
    );
  }
}

const projectStatusConfig = {
  options: ({ routerParams: { projectId } = {} }) => ({
    variables: { id: parseInt(projectId, 10) },
  }),
};

export default compose(
  withStyles(styles),
  withApi,
  graphql(Queries.GetProjectNameStatus, projectStatusConfig),
)(ProjectLayout);
