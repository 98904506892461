import React from 'react';
import FeedbackButton, {
  DEFAULT_ERROR_MESSAGE,
} from '@castandcrew/cnc-ui/FeedbackButton';

const GraphQlActionButton = props => {
  const getErrorMessage = err => {
    const { graphQLErrors = [] } = err;
    if (graphQLErrors.length > 0) return graphQLErrors[0].message;
    return DEFAULT_ERROR_MESSAGE;
  };

  return <FeedbackButton errorMessage={getErrorMessage} {...props} />;
};

export default GraphQlActionButton;
