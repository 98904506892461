/*
 * Component to create a colorful round icon with initials or text
 */

import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const AVATAR_COLORS = [
  'orange',
  'lightBlue',
  'green',
  'lightPink',
  'lightPurple',
  'red',
];

const avatarColor = text => {
  return AVATAR_COLORS[(text[0] || '0').charCodeAt(0) % AVATAR_COLORS.length];
};

const styles = {
  root: {
    position: 'relative',
  },
  avatar: {
    borderRadius: '50%',
    lineHeight: '2.07692308',
    color: '#ffffff',
    display: 'inline-block',
    textAlign: 'center',
    textTransform: 'uppercase',
    height: '27px',
    width: '27px',
  },
  avatarMarginTop: {
    marginTop: '4px',
  },
  orange: {
    backgroundColor: '#f8a81d',
  },
  lightBlue: {
    backgroundColor: '#569ecb',
  },
  green: {
    backgroundColor: '#9bcf4c',
  },
  lightPink: {
    backgroundColor: '#f67b60',
  },
  lightPurple: {
    backgroundColor: '#b774ff',
  },
  red: {
    backgroundColor: '#c11515',
  },
  small: {
    borderRadius: '50%',
    lineHeight: '1.61538462',
    fontFamily: 'proxima-nova, sans-serif',
    color: '#ffffff',
    display: 'inline-block',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '700',
    height: '19px',
    width: '19px',
    marginRight: '3px',
  },
  smallMarginTop: {
    marginTop: '7px',
  },
  review: {
    backgroundColor: '#55a7d0',
  },
  sign: {
    backgroundColor: '#f15e6d',
  },
};

const CircleIcon = props => {
  const {
    classes,
    isAvatar,
    iconText,
    marginTop,
    iconSize,
    iconType,
    tooltip,
  } = props;

  const rootClass = classNames({
    [classes.avatar]: isAvatar,
    [classes.root]: !isAvatar,
  });
  const avatarIconClass = classNames(
    classes.avatar,
    classes[avatarColor(iconText)],
    {
      [classes.avatarMarginTop]: marginTop,
    },
  );
  const nonAvatarIconClass = classNames(classes[iconSize], classes[iconType], {
    [classes.smallMarginTop]: marginTop && iconSize === 'small',
  });
  const iconClass = classNames({
    [avatarIconClass]: isAvatar,
    [nonAvatarIconClass]: !isAvatar,
  });

  return (
    <span className={rootClass} title={tooltip || ''}>
      <span className={iconClass}>{iconText}</span>
    </span>
  );
};

export default withStyles(styles)(CircleIcon);
