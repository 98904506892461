import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox as MUICheckbox } from '@material-ui/core';
import { CheckBox as IconCheckBox } from '@material-ui/icons';

const styles = {
  checkbox: {
    color: '#45a7dc',
  },
};

const Checkbox = props => {
  const { classes } = props;

  const checkIcon = <IconCheckBox className={classes.checkbox} />;

  return <MUICheckbox {...props} checkedIcon={checkIcon} />;
};

export default withStyles(styles)(Checkbox);
