import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import FILE_ICON from 'common/images/file.svg';
import DOWNLOAD_ICON from 'common/images/download.svg';
import ArrowTooltip from './ArrowTooltip';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import * as Sentry from '@sentry/browser';
import ajax from 'common/utilities/ajax';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import { connect } from 'react-redux';
import useIsMobile from 'common/hooks/useIsMobile';
import DownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 50,
  },
  tableContainer: {
    backgroundColor: '#f5fcfb',
    padding: '0 20px',
    marginTop: 30,
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: 8,
  },
  headerTitle: {
    color: '#606060',
    fontSize: '14px',
    fontWeight: 400,
  },
  documentName: {
    color: '#606060',
    fontSize: 14,
    fontWeight: 400,
    width: '50%',
  },
  headerRow: {
    borderBottom: '2px solid #2eaba4',
  },
  itemStatus: {
    fontSize: 14,
    fontWeight: 400,
  },
  fileIcon: {
    position: 'relative',
    top: 3,
    marginRight: 8,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    },
  },
  downloadIcon: {
    position: 'relative',
    top: 3,
    marginRight: 8,
    cursor: 'pointer',
  },
  tableBody: {
    '& > tr:nth-child(even)': {
      backgroundColor: '#e7f6f4',
    },
    '& > tr > td': {
      padding: '10px!important',
    },
  },
  downloadAllLink: {
    color: '#61a3ca',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  optedOutDownloadLink: {
    color: '#61a3ca',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '20px',
  },
  finalDocIcon: {
    marginRight: 5,
  },
}));

const documentStatusColors = {
  approved: '#2ecc71',
  submitted: '#FEB300',
  complete: '#FEB300',
  completed_on_paper: '#939393',
  'in-progress': '#FEB300',
};

const DocumentsPanel = props => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { offer, documents = [] } = props;
  const { id: offerId, status: offerStatus, has_packets: hasPackets } = offer;
  const isCompleteOnpaper = offerStatus === 'complete_on_paper';
  const offerIsApproved = offerStatus === 'approved';

  const downloadOptedOutDocuments = () => {
    const { pushNotification, popNotification } = props;

    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });

    ajax
      .post(`/v2/offers/${offerId}/opt-out`)
      .then(response => {
        Sentry.addBreadcrumb({ response });
        const { path } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification();
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
      })
      .catch(err => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
        Sentry.captureException(err);
      });
  };

  const downloadDocument = (documentId, isFinalDocument = false) => {
    const { pushNotification, popNotification } = props;
    let documentDownloadUrl = isFinalDocument
      ? `/v2/offers/${offerId}/full-final-document`
      : `/v2/offers/${offerId}/download-documents/${documentId}`;

    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });

    ajax
      .get(documentDownloadUrl)
      .then(response => {
        Sentry.addBreadcrumb({ response });
        const { path } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification();
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
      })
      .catch(err => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
        Sentry.captureException(err);
      });
  };

  const getFileStatus = ({ status, signed: isSigned }, optedOut) => {
    if (status === 'complete_on_paper' || optedOut)
      return isMobile ? 'On Paper' : 'Complete On Paper';
    if (status === 'approved' || (status === 'complete' && isSigned))
      return 'Approved';
    if (status === 'in-progress' && isSigned)
      return isMobile ? 'Submitted' : 'Submitted for Approval';
    return 'Incomplete';
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Documents</Typography>
      {offerIsApproved && !hasPackets && (
        <div
          className={classes.downloadAllLink}
          onClick={() => downloadDocument(null, true)}
        >
          <DownloadIcon className={classes.finalDocIcon} /> Final e-signature
          document
        </div>
      )}
      {isCompleteOnpaper && (
        <div
          className={classes.optedOutDownloadLink}
          onClick={() => downloadOptedOutDocuments()}
        >
          <DownloadIcon name="download" className={classes.finalDocIcon} />
          Download offer documents
        </div>
      )}
      <div className={classes.tableContainer}>
        <Table>
          <TableHead className={classes.headerRow}>
            <TableRow>
              <TableCell className={classes.headerTitle}>
                Document Name
              </TableCell>
              <TableCell className={classes.headerTitle}>Status</TableCell>
              <TableCell className={classes.headerTitle} />
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {documents.map(item => (
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.documentName}>
                  <img
                    src={FILE_ICON}
                    alt="file icon"
                    className={classes.fileIcon}
                  />
                  {item.name}
                </TableCell>
                <TableCell
                  style={{ color: documentStatusColors[item.status] }}
                  className={classes.itemStatus}
                >
                  {getFileStatus(item, isCompleteOnpaper)}
                </TableCell>
                <TableCell>
                  <ArrowTooltip title="Download" placement="top">
                    <img
                      src={DOWNLOAD_ICON}
                      alt="Download icon"
                      className={classes.downloadIcon}
                      onClick={() => downloadDocument(item.id)}
                    />
                  </ArrowTooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  pushNotification,
  popNotification,
};
export default compose(
  withApi,
  withSnackbarNotification,
  connect(null, mapDispatchToProps),
)(DocumentsPanel);
