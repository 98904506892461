import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'class-names';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  compact: {
    maxWidth: '400px',
    padding: '100px 0',
  },
  hasHeader: {
    padding: '20px 0',
  },
  hasMainHeader: {},
  hasPageHeader: {},
  hasSidebar: {
    alignSelf: 'stretch',
    position: 'absolute',
    width: '100%',
  },
});

const Layout = props => {
  const {
    compact,
    compactNoLanding,
    hasHeader,
    hasMainHeader,
    hasPageHeader,
    hasSidebar,
    children,
    classes,
    className,
  } = props;
  const rootClass = classNames(
    {
      [classes.compact]: compact || compactNoLanding,
      [classes.hasHeader]: hasHeader,
      [classes.hasMainHeader]: hasMainHeader,
      [classes.hasPageHeader]: hasPageHeader,
      [classes.hasSidebar]: hasSidebar,
    },
    classes.root,
    className,
  );
  return <div className={rootClass}>{children}</div>;
};

export default withStyles(styles)(Layout);
