import red from '@material-ui/core/colors/red';

// Commenting out this palette for now
// Until Product gives us clear direction on how to use our
// extremely minimal brand palette Im going to put in material ui flat colors

// // Colors from brand palette
// const bronze = '#a7774e';
// const grey = '#616264';
// const lightGrey = '#e3e5eb';
// const blue = '#536881';
// const navy = '#384455';
// const green = '#5f9e63';
// const orange = '#f28020';
// const yellow = '#f3ac21';

// export default {
//   primary: {
//     main: bronze,
//   },
//   secondary: {
//     main: blue,
//   },
//   tertiary: {
//     main: yellow,
//   },
//   error: {
//     main: orange,
//   }
//   text: {
//     primary: lightGrey,
//     secondary: grey,
//   }
//   background: {
//     primary: lightGrey,
//     secondary:  grey,
//   },

// }

export default {
  primary: {
    main: '#eaeded',
    light: '#eee',
    dark: '#b8bbbb',
    darkContrastText: '#4a4a4a',
    contrastText: '#888888',
  },
  secondary: {
    main: '#30aba3',
    light: '#6effff',
    dark: '#007b74',
    contrastText: '#ffffff',
  },
  error: red,
};
