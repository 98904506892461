import React from 'react';

// HoC
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

// Actions
import { logout } from 'common/store/actions/authenticate';

// Utitities
import history from 'common/constants/config/history';
import getSSOUrl from 'common/constants/config/ssoUrl';

// Components
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import UsaOnly from 'common/components/UsaOnly';

// Icons
import IdentificationDocumentsIcon from 'common/icons/IdentificationDocuments';

const ACCEPTABLE_DOCS_URL =
  'https://www.uscis.gov/i-9-central/acceptable-documents/list-documents/form-i-9-acceptable-documents';

const MYCNC_W4S_URL = `${getSSOUrl()}/w4s`;

const styles = {
  root: {
    height: 'fit-content',
    width: 500,
    textAlign: 'center',
    margin: 'auto',
    padding: '20px 20px 40px 20px',
  },
  headerText: {
    color: '#5a6062',
  },
  image: {
    height: 'auto',
    width: 200,
  },
  footerText: {
    color: '#5a6062',
  },
  myCnCButton: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  i9Link: {
    color: '#4eaffc',
    fontWeight: 'bold',
  },
  buttons: {
    marginTop: 20,
    '& > :not(:last-child)': {
      marginRight: 24,
    },
  },
};

const OnboardingComplete = props => {
  const { match = {}, logout, classes } = props;
  const { params = {} } = match || {};
  const { offerId } = params;

  const goToSubmittedOffer = () =>
    history.push(`/onboarding/offers/${offerId}/submitted`);
  return (
    <Paper className={classes.root}>
      <Typography variant="h6" align="center" className={classes.headerText}>
        Your Start Packet has been Submitted for Approval!
      </Typography>
      <IdentificationDocumentsIcon className={classes.image} />
      <UsaOnly>
        <Typography variant="h6" align="center" className={classes.footerText}>
          Don’t forget your I-9 needs to be verified, so bring{' '}
          <Link
            href={ACCEPTABLE_DOCS_URL}
            target="_blank"
            rel="noopener"
            className={classes.i9Link}
            data-test-id="Complete-i9AcceptableDocsLink"
          >
            acceptable documents
          </Link>{' '}
          within 3 days of when you report to work. Go to MyCast&Crew to fill
          out your Federal and State W-4s.
        </Typography>
      </UsaOnly>
      <div className={classes.buttons}>
        <Button variant="contained" onClick={goToSubmittedOffer}>
          Offer Status
        </Button>
        <UsaOnly>
          <Link href={MYCNC_W4S_URL} className={classes.myCnCButton}>
            <Button variant="contained">Go to My W-4s</Button>
          </Link>
        </UsaOnly>
        <Button variant="contained" onClick={logout}>
          Logout
        </Button>
      </div>
    </Paper>
  );
};
const mapStateToProps = () => {};
const mapDispatchToProps = {
  logout,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(OnboardingComplete);
