import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  TableFooter as MuiTableFooter,
  TablePagination,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { CircularProgress, Typography } from '@material-ui/core';
import queryParam from 'common/utilities/queryParam';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

const styles = theme => ({
  footerRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  paginationIndicators: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 0,
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
});

const TableFooter = props => {
  const {
    classes,
    length,
    error,
    isRequesting,
    amendRouterQuery,
    routerQuery: q,
  } = props;
  const page = queryParam(q.page, 1) - 1;
  const perPage = queryParam(q.perPage, 10);

  const errorMessage = (
    <Typography color="error" variant="caption">
      {error || 'Error loading the page'}
    </Typography>
  );
  const loadingIndicator = <CircularProgress size={24} />;
  const indicators = (
    <TableCell className={classes.paginationIndicators}>
      {error && errorMessage}
      {isRequesting && loadingIndicator}{' '}
    </TableCell>
  );
  return (
    <MuiTableFooter>
      <TableRow className={error || isRequesting ? classes.footerRow : ''}>
        {(error || isRequesting) && indicators}
        <TablePagination
          count={length}
          rowsPerPage={perPage}
          page={page}
          onChangePage={(__, page) => amendRouterQuery({ page: page + 1 })}
          onChangeRowsPerPage={ev =>
            amendRouterQuery({ perPage: ev.target.value, page: 1 })
          }
          data-input="table-pagination"
          rowsPerPageOptions={[5, 10, 20]}
          backIconButtonProps={{ 'data-test-id': 'TableFooter-prev' }}
          nextIconButtonProps={{ 'data-test-id': 'TableFooter-next' }}
        />
      </TableRow>
    </MuiTableFooter>
  );
};

export default withStyles(styles)(withRouteHelpers(TableFooter));
