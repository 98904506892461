import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40,
  },
  terms: {
    marginTop: 10,
  },
  innerCell: {
    borderTop: 'none',
  },
  cellTitle: {
    backgroundColor: '#f9fdff',
    color: '#30ABA3',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #D6EEED',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  radioContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  radioGroupCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottom: 'none',
    paddingTop: 5,
    paddingBottom: 2,
  },
  dollar: {
    width: '10%',
    fontSize: 14,
    marginTop: 7,
    color: '#696969',
    paddingLeft: 14,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  tableSpacing: {
    marginTop: 16,
    marginBottom: 20,
    border: '1px solid #30ABA3',
  },
  topSpacing: {
    marginTop: 20,
  },
  hoursCopy: {
    marginTop: 7,
    paddingRight: 12,
    color: '#525252',
    fontSize: 16,
    fontWeight: 'normal',
  },
  errorIcon: {
    color: 'red',
    position: 'relative',
    top: 4,
    right: 8,
  },
  errorLabel: {
    color: 'red',
    fontSize: 14,
    textAlign: 'center',
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '65%',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hoursPerDay: {
    backgroundColor: '#f9fdff',
    color: '#30ABA3',
    fontSize: 14,
    fontWeight: 'bold',
  },
  rightCellArea: {
    width: '30%',
    backgroundColor: '#FFFFFF',
    color: '#30ABA3',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #D6EEED',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
    justifyContent: 'center',
  },
  fieldArea: {
    width: '40%',
    backgroundColor: '#FFFFFF!important',
  },
  noTop: {
    borderTop: 'none',
  },
  inputSpacing: {
    marginLeft: '5%',
    marginRight: '10%',
  },
  radio: {
    '&$checked': {
      color: '#30ABA3',
    },
  },
  checked: {
    color: '#30ABA3',
    '&, & + $label': {
      color: '#30ABA3 !important',
      fontSize: 16,
    },
  },
  label: {
    color: '#262626',
    fontSize: 16,
  },
  greyedOutArea: {
    width: '30%',
    backgroundColor: '#F4F4F4',
    border: '2px solid #D6EEED',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
}));

const RadioRow = props => {
  const {
    rowTitle,
    scaleKeyStudio,
    scaleKeyDistant,
    applySixthSeventhDayStudio,
    applySixthSeventhDayDistant,
    onChange: upstreamOnChange,
    isReadOnly,
  } = props;
  const classes = useStyles();
  const CustomRadio = (
    <Radio
      classes={{
        root: classes.radio,
        checked: classes.checked,
      }}
    />
  );

  const handleScaleChange = (ev = {}, radioKey) => {
    const { target: { value: rawValue } = {} } = ev;
    const scaleTitle = radioKey === 'studio' ? scaleKeyStudio : scaleKeyDistant;
    upstreamOnChange({
      [scaleTitle]: rawValue === 'yes',
    });
  };
  return (
    <>
      <TableRow>
        <TableCell className={classes.cellTitle}>
          <div className={classes.errorContainer}>
            <Typography className={classes.hoursPerDay}>{rowTitle}</Typography>
          </div>
        </TableCell>
        <TableCell className={classes.rightCellArea}>
          <RadioGroup
            className={classes.radioBtns}
            disabled={isReadOnly}
            value={applySixthSeventhDayStudio ? 'yes' : 'no'}
            onChange={ev => handleScaleChange(ev, 'studio')}
          >
            <FormControlLabel
              classes={{ label: classes.label }}
              value="no"
              control={CustomRadio}
              label="No"
              data-test-id={`Union-${scaleKeyStudio}-studio-no`}
            />
            <FormControlLabel
              classes={{ label: classes.label }}
              value="yes"
              control={CustomRadio}
              label="Yes"
              data-test-id={`Union-${scaleKeyStudio}-studio-yes`}
            />
          </RadioGroup>
        </TableCell>
        <TableCell className={classes.rightCellArea}>
          <RadioGroup
            className={classes.radioBtns}
            disabled={isReadOnly}
            value={applySixthSeventhDayDistant ? 'yes' : 'no'}
            onChange={ev => handleScaleChange(ev, 'distant')}
          >
            <FormControlLabel
              classes={{ label: classes.label }}
              value="no"
              control={CustomRadio}
              label="No"
              data-test-id={`Union-${scaleKeyDistant}-studio-no`}
            />
            <FormControlLabel
              classes={{ label: classes.label }}
              value="yes"
              control={CustomRadio}
              label="Yes"
              data-test-id={`Unionradiorow-${scaleKeyDistant}-studio-yes`}
            />
          </RadioGroup>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RadioRow;
