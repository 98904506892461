import React, { useState } from 'react';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Button } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import StudioPlusFolderSelectorModal from './StudioPlusFolderSelectorModal';
import * as Queries from 'common/apollo/queries';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import withLoader from 'common/hoc/withLoader';

const styles = theme => ({
  destination: {
    fontFamily: 'monospace',
  },
});

// Given array of directories & a directoryId, returns the path to the directory
// or null if the directory is not found.
const getDirectoryPath = (directories, directoryId, path = '') => {
  // This is a bit clunky
  let p = null;
  directories.find(({ id, children, name }) => {
    const thisPath = `${path}/${name}`;
    if (id === directoryId) {
      p = thisPath;
      return true;
    }
    if (!(children && children.length)) return false;
    p = getDirectoryPath(children, directoryId, thisPath);
    return !!p;
  });
  return p;
};

const StudioPlusFolderSelector = props => {
  const { classes, directories, directoryId, onChangeDirectoryId } = props;
  const directoryPath =
    directoryId && getDirectoryPath(directories, directoryId);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <React.Fragment>
      <div>
        <span data-test-id="StudioPlusFolderSelector-path">
          {directoryId ? (
            <span className={classes.destination}>{directoryPath}</span>
          ) : (
            <span>No destination directory set</span>
          )}
        </span>
        <IconButton
          onClick={() => setIsDialogOpen(true)}
          data-test-id="StudioPlusFolderSelector-open"
        >
          <EditIcon fontSize="small" />
        </IconButton>
        {directoryId && (
          <Button
            variant="contained"
            onClick={() => onChangeDirectoryId(null)}
            data-test-id="StudioPlusFolderSelector-pathDeleteBtn"
          >
            Delete
          </Button>
        )}
      </div>
      <StudioPlusFolderSelectorModal
        directories={directories}
        onClose={() => setIsDialogOpen(false)}
        onSelect={onChangeDirectoryId}
        open={isDialogOpen}
      />
    </React.Fragment>
  );
};

const withProjectStudioPlusDirectories = graphql(
  Queries.projectStudioPlusDirectories,
  {
    options: ({ projectId }) => ({
      variables: { projectId },
    }),
    props: ({
      data: {
        projectStudioPlusDirectories: directories = [],
        loading: dirsLoading,
      } = {},
    }) => ({
      directories,
      dirsLoading,
    }),
  },
);

export default compose(
  withStyles(styles),
  withRouteHelpers,
  withProjectStudioPlusDirectories,
  withLoader(({ dirsLoading }) => dirsLoading),
)(StudioPlusFolderSelector);
