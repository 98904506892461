import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from 'common/components/Checkbox';

import Item from './Selection/Item';
import ItemSection from './Selection/ItemSection';

const styles = {
  list: {
    listStyle: 'none',
    '& ul': {
      paddingLeft: 24,
    },
  },
  selectAll: {
    marginLeft: 24,
  },
};

const Selection = props => {
  const {
    classes,
    selection = {},
    onChangeItem = () => {},
    onChangeSection = () => {},
    onSelectAll = () => {},
  } = props;

  const settings = ['account_codes', 'allowances'];
  const users_and_departments = [
    'additional_settings',
    'departments',
    'employer_info',
    'users',
  ];
  const templates = [
    'acknowledgement_of_receipt',
    'auto_assignment_rules',
    'notifications',
    'required',
    'supporting_documents',
    'workflows',
  ];

  const isSelectAll = Object.values(selection).every(checked => checked);

  return (
    <Grid container data-test-id="CloneDialog-SelectionContainer">
      <Grid item xs={12}>
        Which items would you like to clone?
        <FormControlLabel
          label="Select all"
          className={classes.selectAll}
          control={
            <Checkbox
              onChange={({ target: { checked } = {} }) => onSelectAll(checked)}
              checked={isSelectAll}
            />
          }
        />
      </Grid>
      <Grid item xs={6}>
        <ul className={classes.list}>
          <li>
            <ItemSection
              title="All Settings"
              items={settings}
              selection={selection}
              onChange={onChangeSection}
            />
            <ul className={classes.list}>
              <li>
                <Item
                  name="account_codes"
                  checked={selection.account_codes}
                  onChange={onChangeItem}
                />
              </li>
              <li>
                <Item
                  name="allowances"
                  checked={selection.allowances}
                  onChange={onChangeItem}
                />
              </li>
            </ul>
          </li>
          <li>
            <ItemSection
              title="All Users and Departments"
              items={users_and_departments}
              selection={selection}
              onChange={onChangeSection}
            />
            <ul className={classes.list}>
              <li>
                <Item
                  name="additional_settings"
                  checked={selection.additional_settings}
                  onChange={onChangeItem}
                />
              </li>
              <li>
                <Item
                  name="departments"
                  checked={selection.departments}
                  onChange={onChangeItem}
                />
              </li>
              <li>
                <Item
                  name="employer_info"
                  checked={selection.employer_info}
                  onChange={onChangeItem}
                />
              </li>
              <li>
                <Item
                  name="users"
                  checked={selection.users}
                  onChange={onChangeItem}
                />
              </li>
            </ul>
          </li>
        </ul>
      </Grid>
      <Grid item xs={6}>
        <ul className={classes.list}>
          <li>
            <ItemSection
              title="All Templates"
              items={templates}
              selection={selection}
              onChange={onChangeSection}
            />
            <ul className={classes.list}>
              <li>
                <Item
                  name="acknowledgement_of_receipt"
                  checked={selection.acknowledgement_of_receipt}
                  onChange={onChangeItem}
                />
              </li>
              <li>
                <Item
                  name="auto_assignment_rules"
                  checked={selection.auto_assignment_rules}
                  onChange={onChangeItem}
                />
              </li>
              <li>
                <Item
                  name="notifications"
                  checked={selection.notifications}
                  onChange={onChangeItem}
                />
              </li>
              <li>
                <Item
                  name="required"
                  checked={selection.required}
                  onChange={onChangeItem}
                />
              </li>
              <li>
                <Item
                  name="supporting_documents"
                  checked={selection.supporting_documents}
                  onChange={onChangeItem}
                />
              </li>
              <li>
                <Item
                  name="workflows"
                  checked={selection.workflows}
                  onChange={onChangeItem}
                  hint="Copy Workflows require Copy Users"
                />
              </li>
            </ul>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Selection);
