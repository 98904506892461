import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// MaterialUI Components
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Utilities
import * as FieldTypes from 'common/utilities/constants/fieldTypes';

const restrictedFieldOptions = {
  CHK: `${FieldTypes.CHK}`,
  RDO: `${FieldTypes.RDO}`,
  CMB: `${FieldTypes.CMB}`,
};

const fieldTranslation = {
  CHK: 'Checkbox',
  RDO: 'Radio Button',
  CMB: 'Dropdown',
};

const styles = {
  fieldTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  drawerText: {
    fontWeight: 600,
  },
  drawerInput: {
    width: '80%',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
};

const FieldTypeSelect = props => {
  const {
    classes = {},
    textFieldOptions,
    fieldValues = {},
    renderDisplayIcon,
    updateField,
  } = props;
  const { textType = '', fieldType = '', validateSignature = true } =
    fieldValues || {};
  // Include the default TXT option since the BE does not provide it
  const textFieldOptionsWithDefault = [
    ...textFieldOptions,
    { name: 'Text', value: 'TXT' },
  ];
  // Display TXT for the field select when the textType is the default value
  const textTypeDisplay =
    textType === 'string' || textType === '' || textType === null
      ? fieldType
      : textType;
  let fieldOptions;
  // Check if the field type is one that cannot be changed
  if (restrictedFieldOptions[fieldType]) {
    // Render one menu item that is disabled
    fieldOptions = (
      <MenuItem
        value={fieldType}
        data-test-id={`FieldDrawer-fieldType-${fieldType}`}
      >
        {renderDisplayIcon(restrictedFieldOptions[fieldType])}
        {fieldTranslation[fieldType]}
      </MenuItem>
    );
  } else {
    // Render the selectable field options
    fieldOptions = textFieldOptionsWithDefault.map(option => (
      <MenuItem
        value={option.value}
        key={option.value}
        data-test-id={`FieldDrawer-fieldType-${option.value}`}
      >
        {renderDisplayIcon(option.value)}
        {option.name}
      </MenuItem>
    ));
  }
  // Check if the selected textType is a signature in order to display the validate signature checkbox
  const displaySignatureValidation =
    textType === FieldTypes.SIGNATURE.toLowerCase() ||
    textType === FieldTypes.INITIAL_SIGNATURE.toLowerCase();
  return (
    <div className={classes.fieldTypeContainer}>
      <Typography variant="subtitle1" className={classes.drawerText}>
        Field Type:
      </Typography>
      <Select
        value={textTypeDisplay || ''}
        onChange={e => updateField(e.target.value, 'textType')}
        className={classes.drawerInput}
        disabled={!!restrictedFieldOptions[fieldType]}
        classes={{ select: classes.select }}
        data-test-id="FieldDrawer-fieldType"
      >
        {fieldOptions}
      </Select>
      {displaySignatureValidation && (
        <FormControlLabel
          control={
            <Checkbox
              checked={validateSignature}
              onChange={e =>
                updateField(!validateSignature, 'validateSignature')
              }
              data-test-id="FieldDrawer-validateSignatureCheckbox"
            />
          }
          label="Require signature validation"
        />
      )}
    </div>
  );
};

export default withStyles(styles)(FieldTypeSelect);
