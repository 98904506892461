import merge from 'lodash.merge';
import defaultsDeep from 'lodash.defaultsdeep';
import get from 'lodash.get';
import { compose } from 'redux';
import React, { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import withApi from 'common/hoc/withApi';
import Loader from 'common/components/Loader';
import ScrollDestination from 'common/components/ScrollDestination';
import offerValidationChecks from './helpers/offerValidationChecks';
import OfferHeader from './OfferHeader';
import OfferDate from './OfferDate';
import TermsOfHire from './TermsOfHire';
import TermsOfEmployment from './TermsOfEmployment';
import Allowances from './Allowances';
import AccountCodeModalButton from './AccountCodeModalButton';
import DealNotes from './DealNotes';
import OfferDocuments from './OfferDocuments';
import SubmitOffer from './SubmitOffer';
import LoanOutRestriction from './LoanOutRestriction';
import AccountCodeModal from './AccountCodeModal';
import OfferConfirmation from './OfferConfirmation';
import useCities from 'common/hooks/useCities';
import useOfferDefaults from './hooks/useOfferDefaults';
import toggleInList from 'common/utilities/toggleInList';
import useScaleRates from 'studio/hooks/useScaleRates';
import useContracts from 'studio/hooks/useContracts';
import useDepartments from 'common/hooks/useDepartments';
import useAccountCodeConfigurations from './hooks/useAccountCodeConfigurations';
import useSeasons from 'studio/hooks/useSeasons';
import useProjectTemplates from 'studio/hooks/useProjectTemplates';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import isUnionWeeklyScheduleCode from 'common/utilities/isUnionWeeklySchedule';

const OMITTED_SCHEDULEA_UNIONS = ['399CAS', '817MPI'];
const styles = theme => ({
  root: {
    gridArea: 'content',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'auto auto auto auto auto auto auto auto auto auto',
    height: 'max-content',
    margin: '20px',
    padding: 30,
    paddingBottom: 50,
    boxSizing: 'content-box',
    gap: '45px',
    overflow: 'auto',
  },
  offerHeader: {
    gridRow: 1,
  },
  offerDate: {
    gridRow: 2,
  },
  termsOfHire: {
    gridRow: 3,
  },
  termsOfEmployment: {
    gridRow: 4,
  },
  allowances: {
    gridRow: 5,
  },
  accountCodeModalButton: {
    gridRow: 6,
  },
  dealNotes: {
    gridRow: 7,
  },
  documents: {
    gridRow: 8,
  },
  documentFields: {
    gridRow: 9,
  },
  submitOffer: {
    gridRow: 10,
  },
});

const OfferForm = props => {
  // Props processing

  const {
    classes,
    crew,
    formData,
    headerContent,
    headerTitle,
    isReviewOffer,
    isTermsOfHireDisabled,
    onSubmit,
    performAdditionalValidation,
    projectId,
    projectWithPrivilegesQuery,
    setFormData,
    submitInProgress = false,
  } = props;
  const {
    status: privilegesStatus,
    data: { privileges } = {},
  } = projectWithPrivilegesQuery;
  const {
    offerDate: offerDateFormData = {},
    termsOfHire: termsOfHireFormData = {},
    termsOfEmployment: termsOfEmploymentFormData = {},
    termsOfEmploymentV2: termsOfEmploymentV2FormData = {},
    allowances: allowancesFormData = {},
    dealNotes: dealNotesFormData = [],
    documents: documentsFormData = [],
    documentFields: documentFieldsFormData = [],
    accountCodes: accountCodeFormData = [],
  } = formData;
  const isTermsOfEmploymentEmpty =
    Object.keys(termsOfEmploymentFormData).length === 0;
  const { startDateObject } = offerDateFormData;
  const {
    workState,
    hireState,
    workCity,
    hireCity,
    currency,
    union,
    workSchedule,
    occupation,
    season,
    department,
    employmentClassification,
  } = termsOfHireFormData;
  const { value: unionCode = null, isNonUnion = false } = union || {};
  const { value: workScheduleCode = null } = workSchedule || {};
  const { value: occupationCode } = occupation || {};
  const projectTemplatesQueryVariables = {
    projectId,
    workState,
    hireState,
    workCity,
    hireCity,
    union: unionCode,
    occupation: occupationCode,
    workSchedule: workScheduleCode,
    department,
    employmentClassification,
  };

  // Hooks
  const [
    isLoanOutRestrictionModalOpen,
    setIsLoanOutRestrictionModalOpen,
  ] = useState(false);
  const [isAccountCodeModalOpen, setIsAccountCodeModalOpen] = useState(false);
  const [
    isOfferConfirmationModalOpen,
    setIsOfferConfirmationModalOpen,
  ] = useState(false);
  const flags = useFeatureFlags();
  const isUnionScheduleAFlagActive = flags.includes('UnionScheduleA');
  const isUnionScheduleAV2 =
    !isNonUnion && workScheduleCode === 'A' && isUnionScheduleAFlagActive;
  const isUnionWeeklyScheduleFlagActive = flags.includes('UnionWeeklySchedule');
  const isUnionWeeklyScheduleV2 =
    !isNonUnion &&
    isUnionWeeklyScheduleCode(workScheduleCode) &&
    isUnionWeeklyScheduleFlagActive;

  const [scrollDestinationKey, setScrollDestinationKey] = useState();
  const [isEditingDealNotes, setIsEditingDealNotes] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const { data: offerDefaults } = useOfferDefaults(projectId);
  const { countryId } = offerDefaults;
  const { workStateId, hireStateId } = termsOfHireFormData;
  const { data: workStateCities = [] } = useCities({
    countryId,
    stateId: workStateId,
  });
  const { data: hireStateCities = [] } = useCities({
    countryId,
    stateId: hireStateId,
  });

  const scaleRateQueryVariables = {
    projectId,
    countryCode: offerDefaults?.countryCode,
    startDate: startDateObject && startDateObject.format('YYYY-MM-DD'),
    workState,
    hireState,
    workCity,
    hireCity,
    currency,
    union: unionCode,
    isNonUnion,
    occupation: occupationCode,
    workSchedule: workScheduleCode,
    season,
    isUnionScheduleAV2,
    isUnionWeeklyScheduleV2,
  };
  const { loading: isScaleRatesLoading, data: scaleRates = {} } = useScaleRates(
    scaleRateQueryVariables,
  );

  const { loading: isContractsLoading, data: contracts } = useContracts(
    scaleRateQueryVariables,
  );
  const { loading: isTemplatesLoading, data: templates } = useProjectTemplates(
    projectTemplatesQueryVariables,
  );
  const { loading: isDepartmentsLoading, data: departments } = useDepartments({
    projectId,
    occupation: occupationCode,
    union: unionCode,
  });
  const { data: accountCodeConfigurations } = useAccountCodeConfigurations(
    projectId,
  );
  const { data: seasons = [] } = useSeasons(projectId);
  const isCanada = offerDefaults?.countryCode === 'CA';
  const isOmittedUnions = OMITTED_SCHEDULEA_UNIONS.includes(unionCode);
  const isNoScaleUnions =
    (isOmittedUnions && isUnionScheduleAV2) ||
    (isOmittedUnions && isUnionWeeklyScheduleV2);

  // When new scale rates are returned from server, if the rates section is
  // empty populates it with the returned scale rates.
  useEffect(() => {
    if (!isScaleRatesLoading && !isCanada && !isNoScaleUnions) {
      const { newScaleRates = {} } = formData;
      // Set new Scale Rates in form data.
      if (scaleRates) {
        if (Object.keys(newScaleRates).length === 0) {
          setFormData(formData => ({ ...formData, newScaleRates: scaleRates }));
        }
        // Set New Scale rates to termsOfEmployment if there are none.
        if (isTermsOfEmploymentEmpty) {
          const items = {
            payIdleDaysDistant: scaleRates?.rateDistant,
            payGoldAt: scaleRates?.rate,
            payGoldAtDistant: scaleRates?.rateDistant,
            payAtScale: scaleRates?.rate > 0,
            payAtScaleDistant: scaleRates?.rateDistant > 0,
            idleAtScaleDistant: scaleRates?.rateDistant > 0,
            goldAtScale: scaleRates?.rate > 0,
            goldAtScaleDistant: scaleRates?.rateDistant > 0,
          };
          const scalesUnionScheduleA = isUnionScheduleAV2
            ? {
                ...items,
              }
            : {};
          const scalesUnionWeeklySchedule = isUnionWeeklyScheduleV2
            ? {
                ...items,
              }
            : {};
          setFormData(formData => ({
            ...formData,
            termsOfEmployment: {
              ...scaleRates,
              ...scalesUnionScheduleA,
              ...scalesUnionWeeklySchedule,
            },
          }));
        }
      } else if (Object.keys(newScaleRates).length > 0) {
        // Clear Scale Rates.
        setFormData(formData => ({ ...formData, newScaleRates: {} }));
      }
    }
  });

  const memoizedCallback = useCallback(() => {
    setFormData(formData => ({
      ...formData,
      newScaleRates: { ...scaleRates },
      termsOfEmployment: { ...scaleRates, isPrcanRateTable: true },
    }));
  }, [scaleRates, setFormData]);

  // When new scale rates are returned from server, if the rates section is
  // empty populates it with the returned scale rates.
  useEffect(() => {
    if (!isScaleRatesLoading && isCanada) {
      const { newScaleRates = {} } = formData;
      // Set new Scale Rates in form data.
      if (Object.keys(scaleRates).length > 0) {
        if (Object.keys(newScaleRates).length === 0 && !isReviewOffer) {
          memoizedCallback();
        }
      } else if (Object.keys(newScaleRates).length > 0) {
        // Clear Scale Rates.
        setFormData(formData => ({
          ...formData,
          newScaleRates: {},
        }));
      }
    }
  });

  // When there is a pre-selected department for the current union & occupation,
  // make sure it is selected in terms of hire.
  useEffect(() => {
    if (isDepartmentsLoading) return;
    const preSelectedDepartmentId = departments?.find(d => d.preSelected)?.id;
    if (
      preSelectedDepartmentId &&
      preSelectedDepartmentId !== formData.termsOfHire.department
    ) {
      updateDeep({ termsOfHire: { department: preSelectedDepartmentId } });
    }
  });

  // When templates are loaded & document selection has not been made, select
  // the recommended & required documents.
  useEffect(() => {
    if (isTemplatesLoading || !templates || documentsFormData) return;
    const documents = templates
      .filter(({ required, preSelected }) => required || preSelected)
      .map(({ id }) => id);
    update({ documents });
  });

  // Hooks processing

  const {
    offerDate: offerDateFormErrors = {},
    termsOfHire: termsOfHireFormErrors = {},
    termsOfEmployment: termsOfEmploymentFormErrors = {},
    allowances: allowancesFormErrors = {},
    accountCodes: accountCodeFormErrors = [],
    dealNotes: dealNotesFormError = '',
    documents: documentsFormErrors = '',
    documentFields: documentFieldsFormErrors = [],
  } = formErrors || {};
  // Methods

  const performStandardValidation = () => {
    let results = offerValidationChecks.map(test =>
      test(formData, offerDefaults, {
        departments,
        seasons,
        privileges,
        workStateCities,
        hireStateCities,
        accountCodeConfigurations,
        templates,
        // Pass in isEditingOffer as true to skip
        // the crew member validation check
        isEditingOffer: isReviewOffer,
        contracts,
      }),
    );
    if (isEditingDealNotes)
      results = [
        ...results,
        { dealNotes: 'Please save or remove additional deal notes' },
      ];
    if (results.every(result => result === true)) return true;

    const newFormErrors = merge(
      {},
      ...results.filter(result => result !== true),
    );
    setFormErrors(newFormErrors);

    const firstError =
      results.find(
        result =>
          typeof result !== 'boolean' && Object.keys(result)[0] !== 'crew',
      ) || {};
    const sectionKey = Object.keys(firstError)[0];
    if (sectionKey) setScrollDestinationKey(sectionKey);
  };

  const onClickSubmit = () => {
    const isStandardValidationOk = performStandardValidation();
    const isAdditionalValidationOk = performAdditionalValidation
      ? performAdditionalValidation()
      : true;
    if (!(isStandardValidationOk && isAdditionalValidationOk)) return;
    setIsOfferConfirmationModalOpen(true);
  };

  const getAccountCodesWithUpdatedDetailSub = (accountCodes, departmentId) => {
    const { id: detailSubId } =
      accountCodeConfigurations.find(({ code }) => code === 'detail/sub') || {};
    if (!detailSubId) return accountCodes;
    const departmentCode = departments.find(({ id }) => id === departmentId)
      .code;
    const newAccountCodes = accountCodes.map(accountCode =>
      accountCode.accountCodeId === detailSubId
        ? { ...accountCode, value: departmentCode }
        : accountCode,
    );
    return newAccountCodes;
  };

  // Takes a `patch` object which is merged into the current formData state. The
  // merge can be shallow or deep depending on the `deep` argument.
  const update = (patch, deep = false) => {
    setFormData(formData => {
      const patchData = typeof patch === 'function' ? patch(formData) : patch;
      const newFormData = deep
        ? defaultsDeep({}, patchData, formData)
        : { ...formData, ...patchData };

      // If start date has changed, clear union, occupation & schedule
      // TODO: Generalise this so changing any ToH field clears all the fields
      // after it. At the moment this logic is scattered about.
      const hasStartDateChanged =
        formData.offerDate.startDate !== newFormData.offerDate.startDate;
      if (hasStartDateChanged) {
        newFormData.termsOfHire.union = null;
        newFormData.termsOfHire.occupation = null;
        newFormData.termsOfHire.workSchedule = null;
      }

      const getHaveTermsOfHireFieldsChanged = fields =>
        fields.some(field => {
          field = `termsOfHire.${field}`;
          return get(formData, field) !== get(newFormData, field);
        });

      // If scale rate parameters have changed, clear terms of employment.
      const haveScaleRateParamsChanged = getHaveTermsOfHireFieldsChanged([
        'season',
        'hireState',
        'hireCity',
        'workState',
        'workCity',
        'currency',
        'union.code',
        'occupation.value',
        'workSchedule.value',
      ]);
      if (haveScaleRateParamsChanged) newFormData.termsOfEmployment = {};

      // If document auto-assignment params have changed, clear selected documents.
      const haveDocumentParamsChanged = getHaveTermsOfHireFieldsChanged([
        'hireState',
        'hireCity',
        'workState',
        'workCity',
        'union.code',
        'occupation.value',
        'workSchedule.value',
        'department',
        'employmentClassification',
      ]);
      if (haveDocumentParamsChanged) newFormData.documents = null;

      // If department has changed, update account code detail/sub to dept code
      const hasDepartmentChanged = getHaveTermsOfHireFieldsChanged([
        'department',
      ]);
      if (hasDepartmentChanged) {
        newFormData.accountCodes = getAccountCodesWithUpdatedDetailSub(
          newFormData.accountCodes,
          newFormData.termsOfHire.department,
        );
      }

      // TODO
      // If an allowance is filled in, we should ideally set the corresponding
      // account codes to their default values.

      return newFormData;
    });
  };

  const updateDeep = patch => update(patch, true);

  const toggleDocumentSelection = id =>
    update(formData => ({
      documents: toggleInList(formData.documents ?? [], id),
    }));

  // Render

  if (
    privilegesStatus === 'loading' ||
    privilegesStatus === 'error' ||
    !offerDefaults
  )
    return <Loader />;

  return (
    <Paper className={classes.root}>
      <OfferHeader classes={{ root: classes.offerHeader }} title={headerTitle}>
        {headerContent}
      </OfferHeader>
      <ScrollDestination
        isActive={scrollDestinationKey === 'offerDate'}
        behavior="smooth"
        block="center"
      >
        <OfferDate
          classes={{ root: classes.offerDate }}
          offerDefaults={offerDefaults}
          onChange={patch => updateDeep({ offerDate: patch })}
          formData={offerDateFormData}
          formErrors={offerDateFormErrors}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'termsOfHire'}
        behavior="smooth"
        block="center"
      >
        <TermsOfHire
          classes={{ root: classes.termsOfHire }}
          offerDefaults={offerDefaults}
          onChange={patch => updateDeep({ termsOfHire: patch })}
          projectId={projectId}
          formData={{
            ...offerDateFormData,
            ...termsOfHireFormData,
          }}
          formErrors={termsOfHireFormErrors}
          toggleAccountCodeModal={() => setIsAccountCodeModalOpen(true)}
          toggleLoanOutRestrictionModal={() =>
            setIsLoanOutRestrictionModalOpen(true)
          }
          isReviewOffer={isReviewOffer}
          disabled={isTermsOfHireDisabled}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'termsOfEmployment'}
        behavior="smooth"
        block="center"
      >
        <TermsOfEmployment
          classes={{ root: classes.termsOfEmployment }}
          onChange={patch => updateDeep({ termsOfEmployment: patch })}
          formData={{
            ...offerDateFormData,
            ...termsOfHireFormData,
            ...termsOfEmploymentFormData,
            ...termsOfEmploymentV2FormData,
          }}
          formErrors={termsOfEmploymentFormErrors}
          isReviewOffer={isReviewOffer}
          isScaleRatesLoading={isScaleRatesLoading}
          isContractsLoading={isContractsLoading}
          scaleRates={isNoScaleUnions ? {} : scaleRates}
          contracts={contracts}
          isCanada={isCanada}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'allowances'}
        behavior="smooth"
        block="center"
      >
        <Allowances
          classes={{ root: classes.allowances }}
          onChange={subSection => patch =>
            updateDeep({ allowances: { [subSection]: patch } })}
          formData={allowancesFormData}
          formErrors={allowancesFormErrors}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'accountCodes'}
        behavior="smooth"
        block="center"
      >
        <AccountCodeModalButton
          formErrors={accountCodeFormErrors}
          classes={{ root: classes.accountCodeModalButton }}
          onClick={() => setIsAccountCodeModalOpen(true)}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'dealNotes'}
        behavior="smooth"
        block="center"
      >
        <DealNotes
          classes={{ root: classes.dealNotes }}
          onChange={dealNotes => update({ dealNotes })}
          dealNotesFormData={formData}
          formData={dealNotesFormData}
          isEditingDealNotes={isEditingDealNotes}
          updateEditingDealNoteStatus={setIsEditingDealNotes}
          formError={dealNotesFormError}
        />
      </ScrollDestination>
      <ScrollDestination
        isActive={scrollDestinationKey === 'documents'}
        behavior="smooth"
        block="center"
      >
        <OfferDocuments
          classes={{
            documentFields: classes.documentFields,
            documents: classes.documents,
          }}
          formData={{
            ...offerDateFormData,
            ...termsOfHireFormData,
            documentFields: documentFieldsFormData,
            documents: documentsFormData || [],
          }}
          formErrors={{
            documentFieldsFormErrors,
            documentsFormErrors,
          }}
          onChangeDocument={toggleDocumentSelection}
          onChangeDocumentFields={documentFields => update({ documentFields })}
          scrollToDocumentFields={scrollDestinationKey === 'documentFields'}
          templates={templates}
        />
      </ScrollDestination>
      <SubmitOffer
        classes={{ root: classes.submitOffer }}
        onClick={onClickSubmit}
      />
      <LoanOutRestriction
        open={isLoanOutRestrictionModalOpen}
        onClose={() => setIsLoanOutRestrictionModalOpen(false)}
      />
      {isAccountCodeModalOpen && (
        <AccountCodeModal
          formData={{
            ...termsOfEmploymentFormData,
            ...allowancesFormData,
            accountCodes: accountCodeFormData,
            workScheduleCode,
          }}
          formErrors={accountCodeFormErrors}
          offerDefaults={offerDefaults}
          open={isAccountCodeModalOpen}
          accountCodeConfigurations={accountCodeConfigurations}
          onClose={() => setIsAccountCodeModalOpen(false)}
          onSave={accountCodes => update({ accountCodes })}
          privileges={privileges}
        />
      )}
      <OfferConfirmation
        formData={formData}
        crew={crew}
        open={isOfferConfirmationModalOpen}
        onClose={() => setIsOfferConfirmationModalOpen(false)}
        onSubmit={onSubmit}
        submitInProgress={submitInProgress}
      />
    </Paper>
  );
};

OfferForm.queries = {
  projectWithPrivilegesQuery: {
    info: (__, related) => {
      const routerParams = related['/router/params'];
      return {
        id: `/projects/${routerParams.projectId}?with_privileges=true`,
      };
    },
  },
};

export default compose(withApi, withStyles(styles))(OfferForm);
