import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

const styles = theme => ({
  container: {
    width: '100%',
    paddingTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  formContainer: {
    width: '100%',
  },
  row: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
  },
  selectContainer: {
    flex: 0.3,
    marginRight: theme.spacing.unit * 0.5,
    marginLeft: theme.spacing.unit * 0.5,
  },
  acknowledgmentTitle: {
    flex: 0.7,
    marginRight: theme.spacing.unit * 0.5,
    marginLeft: theme.spacing.unit * 0.5,
  },
  acknowledgmentDescription: {
    flex: 1,
    marginRight: theme.spacing.unit * 0.5,
    marginLeft: theme.spacing.unit * 0.5,
  },
});

const AcknowledgmentSetup = props => {
  const {
    classes,
    updateHandler,
    disabled,
    acknowledgeNotes,
    acknowledgeOptions,
    acknowledgeTitle,
    acknowledgeType,
  } = props;
  const showDetails = acknowledgeType === 'C';

  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom>
        Acknowledgment of Receipt
      </Typography>
      <Typography variant="body1">
        You can require that a user acknowledges receipt of a document in this
        section.
      </Typography>
      <div className={classes.formContainer}>
        <div className={classes.row}>
          <FormControl className={classes.selectContainer}>
            <InputLabel htmlFor="acknowledgment-select" shrink>
              Require Acknowledgement of Receipt
            </InputLabel>
            <Select
              displayEmpty
              inputProps={{ id: 'acknowledgment-select' }}
              value={acknowledgeType || ''}
              onChange={e => updateHandler('acknowledgeType', e.target.value)}
              disabled={disabled}
              data-test-id="AcknowledgmentSetup-type"
            >
              <MenuItem value="">None</MenuItem>
              {acknowledgeOptions.includes('C') && (
                <MenuItem value="C">Click-through</MenuItem>
              )}
              {acknowledgeOptions.includes('G') && (
                <MenuItem value="G">Grouped</MenuItem>
              )}
            </Select>
          </FormControl>
          {showDetails && (
            <TextField
              className={classes.acknowledgmentTitle}
              value={acknowledgeTitle}
              onChange={e => updateHandler('acknowledgeTitle', e.target.value)}
              label="Title"
              inputProps={{ 'data-test-id': 'AcknowledgmentSetup-title' }}
            />
          )}
        </div>
        {showDetails && (
          <div className={classes.row}>
            <TextField
              className={classes.acknowledgmentDescription}
              value={acknowledgeNotes}
              onChange={e => updateHandler('acknowledgeNotes', e.target.value)}
              label="Description"
              inputProps={{ 'data-test-id': 'AcknowledgmentSetup-description' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(AcknowledgmentSetup);
