import React, { useState, useEffect } from 'react';
import mapValues from 'lodash/mapValues';
import { withStyles } from '@material-ui/core/styles';
import { ChevronLeft as IconPrev } from '@material-ui/icons';

import Dialog from 'common/components/Dialog';
import Loader from 'common/components/Loader';
import usePrevious from 'common/utilities/usePrevious';

import SearchProject from './Dialog/SearchProject';
import Selection from './Dialog/Selection';
import Confirmation from './Dialog/Confirmation';
import Failure from './Dialog/Failure';
import Success from './Dialog/Success';

import useCloneProject from './hooks/useCloneProject';

const SEARCH_PROJECT = 0;
const SELECTION = 1;
const CONFIRMATION = 2;
const PROCESSING = 3;
const SUCCESS = 4;
const FAILURE = 5;

const SELECTION_ALL = {
  account_codes: true,
  allowances: true,
  additional_settings: true,
  departments: true,
  employer_info: true,
  users: true,
  acknowledgement_of_receipt: true,
  auto_assignment_rules: true,
  notifications: true,
  required: true,
  supporting_documents: true,
  workflows: true,
};

const styles = {
  loaderContainer: {
    height: 100,
  },
  btnClose: {
    background: '#f4f6f8',
    color: 'black',
    '&:hover': {
      background: '#ccc',
    },
    paddingLeft: 32,
    paddingRight: 32,
  },
  btnPrimary: {
    background: '#45a7dc',
    paddingLeft: 32,
    paddingRight: 32,
    color: 'white',
    '&:hover': {
      background: '#3297ce',
    },
    '&:disabled': {
      background: '#a8d4ee',
      color: 'white',
    },
  },
  btnDefault: {
    background: '#f4f6f8',
    paddingLeft: 32,
    paddingRight: 32,
    color: 'black',
    '&:hover': {
      background: '#ccc',
    },
  },
  btnBack: {
    border: '1px solid #45a7dc',
    background: '#ffffff',
    color: '#45a7dc',
    paddingLeft: 32,
    paddingRight: 32,
    '&:hover': {
      background: '#fefefe',
    },
  },
  paper: {
    width: 600,
    maxHeight: 600,
    padding: 16,
  },
};

const Form = props => {
  const { classes, open, onClose, project, cloned, cloneEnvironments } = props;

  const [selection, setSelection] = useState(SELECTION_ALL);
  const [step, setStep] = useState(SEARCH_PROJECT);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedEnv, setSelectedEnv] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);
  const [keyword] = useState('');
  const [cloneProject] = useCloneProject();

  const isSelectionEmpty = Object.values(selection).every(checked => !checked);

  const close = () => {
    if (step === SUCCESS) return window.location.reload();

    onClose();
  };
  const reset = () => {
    setSelectedProject(null);
    setStep(SEARCH_PROJECT);
  };
  const select = () => {
    setStep(SELECTION);
  };
  const configure = () => setStep(CONFIRMATION);
  const confirm = () => {
    setStep(PROCESSING);
    cloneProject({
      from: parseInt(selectedProject.id, 10),
      to: project.id,
      selection: {
        settings: {
          accountCodes: selection.account_codes,
          allowances: selection.allowances,
          usersAndDepartments: {
            additionalSettings: selection.additional_settings,
            departments: selection.departments,
            employerInfo: selection.employer_info,
          },
        },
        templates: {
          acknowledgementOfReceipt: selection.acknowledgement_of_receipt,
          autoAssignmentRules: selection.auto_assignment_rules,
          notifications: selection.notifications,
          required: selection.required,
          supportingDocuments: selection.supporting_documents,
          workflows: selection.workflows,
        },
        users: selection.users,
      },
      env: selectedEnv,
    })
      .then(() => setStep(SUCCESS))
      .catch(e => {
        const { graphQLErrors = [] } = e || {};
        const { message = '' } = graphQLErrors[0] || {};
        setFailureMessage(message);
        setStep(FAILURE);
      });
  };

  const selectAll = () => setSelection(SELECTION_ALL);
  const selectNone = () => setSelection(mapValues(selection, () => false));

  const iconPrev = <IconPrev />;
  const btnClose = { text: 'Close', fn: close, className: classes.btnClose };
  const btnNext = {
    text: 'Next',
    className: classes.btnPrimary,
  };
  const btnBack = {
    text: 'Back',
    fn: reset,
    className: classes.btnBack,
    startIcon: iconPrev,
  };

  const body = (() => {
    switch (step) {
      case SEARCH_PROJECT:
        return (
          <SearchProject
            project={project}
            keyword={keyword}
            environments={cloneEnvironments}
            onSelect={(project, env) => {
              setSelectedProject(project);
              setSelectedEnv(env);
            }}
          />
        );

      case SELECTION:
        return (
          <Selection
            selection={selection}
            onChangeItem={(name, checked) => {
              setSelection({ ...selection, [name]: checked });
            }}
            onChangeSection={(names, checked) => {
              const sel = { ...selection };
              names.forEach(name => (sel[name] = checked));
              setSelection(sel);
            }}
            onSelectAll={checked => (checked ? selectAll() : selectNone())}
          />
        );

      case CONFIRMATION:
        return (
          <Confirmation
            project={selectedProject}
            environment={selectedEnv}
            selection={selection}
          />
        );

      case PROCESSING:
        return (
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        );

      case SUCCESS:
        return <Success project={selectedProject} />;

      case FAILURE:
        return <Failure project={selectedProject} message={failureMessage} />;

      default:
        return null;
    }
  })();

  const actions = (() => {
    switch (step) {
      case SEARCH_PROJECT:
        return [
          btnClose,
          { ...btnNext, fn: select, disabled: selectedProject ? false : true },
        ];

      case SELECTION:
        return [
          { ...btnBack, fn: reset },
          { ...btnNext, fn: configure, disabled: isSelectionEmpty },
        ];

      case CONFIRMATION:
        return [
          { ...btnBack, fn: select, text: 'No, Take me Back' },
          { ...btnNext, fn: confirm, text: 'Yes, Clone Project' },
        ];

      case PROCESSING:
        return [{ ...btnClose, fn: close }];

      case SUCCESS:
        return [
          { text: 'Close Window', fn: close, className: classes.btnPrimary },
        ];

      case FAILURE:
        return [
          { text: 'Close', fn: close, className: classes.btnClose },
          { text: 'Start again', fn: reset, className: classes.btnDefault },
          { text: 'Retry', fn: confirm, className: classes.btnPrimary },
        ];

      default:
        throw new Error('invalid step');
    }
  })();

  const prevCloned = usePrevious(cloned);
  const prevOpen = usePrevious(open);
  useEffect(() => {
    if (prevCloned !== cloned) {
      if (cloned) setStep(SUCCESS);
      if (!cloned) setStep(FAILURE);
    }
    if (!prevOpen && open) {
      setStep(SEARCH_PROJECT);
      setSelectedProject(null);
    }
  }, [prevCloned, cloned, prevOpen, open, selectedProject]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      title="Project Clone"
      message={body}
      actions={actions}
      handleClose={close}
    />
  );
};

export default withStyles(styles)(Form);
