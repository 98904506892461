import React from 'react';
import { Route } from 'react-router-dom';

// Layouts
import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';

// Components
import ChangeApp from 'common/oldJavascripts/components/Pages/Projects/ChangeApp';

export default props => {
  return (
    <BaseLayout>
      <Route exact component={ChangeApp} />
    </BaseLayout>
  );
};
