import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Warning from '@material-ui/icons/Warning';

import LABELS from './Selection/labels.json';

const styles = {
  dialogContent: {
    textAlign: 'center',
  },
  iconConfirm: {
    width: '100%',
    height: 128,
    color: '#f4b524',
  },
  titleH5: {
    fontWeight: 600,
    paddingBottom: 20,
  },
  section: {
    marginBottom: 12,
  },
  selectedItems: {
    paddingLeft: 36,
    paddingRight: 36,
    color: '#45a7dc',
    fontWeight: 600,
    marginBottom: 12,
  },
  highlight: {
    color: '#45a7dc',
    fontWeight: 600,
  },
};

const Confirmation = props => {
  const { classes, project, selection = [], environment } = props;

  const entries = Object.entries(selection);
  const sel = entries.filter(e => e[1]).map(i => LABELS[i[0]] || i[0]);
  const env = environment.toUpperCase();

  return (
    <div className={classes.dialogContent} data-test-id="CloneDialog-confirm">
      <Warning className={classes.iconConfirm} />
      <Typography variant="h5" className={classes.titleH5}>
        Cloning a project can NOT be undone.
        <br />
        Do you want to continue?
      </Typography>
      <Typography align="left" className={classes.section}>
        Project: <span className={classes.highlight}>{project.name}</span>,{' '}
        Environment: <span className={classes.highlight}>{env}</span>
      </Typography>
      <Typography align="left">
        You have selected these project settings to clone:
      </Typography>
      <Typography align="left" className={classes.selectedItems}>
        {sel.join(', ')}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Confirmation);
