import React, { Component } from 'react';

import _ from 'lodash';
import ajax from 'common/utilities/ajax';
import status from 'common/oldJavascripts/utils/react/status';
import FormField from 'common/oldJavascripts/components/Base/FormField';
import Select from 'common/oldJavascripts/components/Base/Select';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import UnionOccupationSelect from './UnionOccupationSelect';
import Typography from '@material-ui/core/Typography';

// Hoc
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';

const styles = () => ({
  typography: {
    fontSize: '1.05em',
    color: '#9B9CA5',
  },
});

class Rule extends Component {
  static queries = {
    unions: {
      info(params, related) {
        const routerParams = related['/router/params'];

        return {
          id: `/payroll/projects/${routerParams.projectId}/unions`,
        };
      },
    },
  };

  state = {
    selectedUnionCodes: [],
    previousUnionCodes: [],
    occupations: [],
    isRequestingOccupations: false,
    occupationsError: false,
  };

  componentWillReceiveProps(nextProps) {
    const { unions = {} } = nextProps.department;
    const unionCodes = Object.keys(unions).sort();
    const selectedUnionCodesSorted = this.state.selectedUnionCodes.sort();
    if (!_.isEqual(unionCodes, selectedUnionCodesSorted)) {
      const newUnionCodes = unionCodes.filter(
        code => !selectedUnionCodesSorted.includes(code),
      );
      if (newUnionCodes.length) {
        this.requestOccupations(newUnionCodes);
      }
      this.setState({
        previousUnionCodes: selectedUnionCodesSorted,
        selectedUnionCodes: unionCodes,
      });
    }
  }

  requestOccupations(unionCodes) {
    const {
      routerParams: { projectId },
    } = this.props;
    this.setState({ isRequestingOccupations: true }, () => {
      const occupationPromises = unionCodes.map(code =>
        ajax.get(`/payroll/projects/${projectId}/occupations/${code}`),
      );
      Promise.all(occupationPromises)
        .then(values => {
          this.setState(() => {
            const occupations = values
              .map(response => [...response.items])
              .flat();
            return {
              occupations,
              isRequestingOccupations: false,
            };
          });
        })
        .catch(err =>
          this.setState({
            isRequestingOccupations: false,
            occupationsError: true,
          }),
        );
    });
  }

  render() {
    const { classes } = this.props;
    if (this._getStatus() === 'failed') {
      return <FailureAlert queryName="Unions" />;
    }

    return (
      <div>
        <Typography variant="h6" className={classes.typography}>
          Occupation Assignment Rules
        </Typography>

        {this._renderUnionField()}
        {this._renderOccupationFields()}
      </div>
    );
  }

  _renderUnionField = () => {
    const { unions = {} } = this.props.department;
    return (
      <FormField label="Union" data-test-id="Rule-unionSelect">
        <Select
          id={this.props.id}
          isInvalid={false}
          isLoading={this._getStatus() === 'loading'}
          multiple={true}
          onChange={this._changeUnions}
          options={this._getUnions()}
          value={Object.keys(unions)}
          closeMenuOnSelect={false}
        />
      </FormField>
    );
  };

  _renderOccupationFields = () => {
    const { loanoutOnChange, department } = this.props;
    const { unions = {} } = department;
    const {
      occupations,
      occupationsError,
      isRequestingOccupations,
    } = this.state;
    const occupationSelectConfig = [
      {
        selectedOccupations: department.occupations || {},
        label: 'Occupation',
        id: 'occupation_select',
        onChange: this._changeOccupations,
      },
      {
        selectedOccupations: department.loan_out_occupations || {},
        label: 'Occupations Eligible for Loan-out Hire',
        id: 'loan_out_occupation_select',
        onChange: loanoutOnChange,
      },
    ];
    if (Object.keys(unions).length > 0) {
      return occupationSelectConfig.map(selectConfig => (
        <span data-test-id={`Rule-${selectConfig.id}`} key={selectConfig.id}>
          <UnionOccupationSelect
            selectedOccupations={selectConfig.selectedOccupations}
            occupations={occupations}
            selectedUnions={unions}
            department={department}
            onChange={selectConfig.onChange}
            hasFailed={occupationsError}
            isLoading={isRequestingOccupations}
            label={selectConfig.label}
            id={selectConfig.id}
          />
        </span>
      ));
    }
  };

  _getStatus = () => {
    return status([this.props.unions]);
  };

  _formatSelectedUnions = () => {
    const { unions = {} } = this.props.department;
    const unionIds = Object.keys(unions);
    const unionsQuery = this.props.unions;
    let selectedUnions = {};

    if (
      unionsQuery.status === 'success' &&
      Array.isArray(unionsQuery.data.items)
    ) {
      const unions = this.props.unions.data.items;
      unions
        .filter(union => unionIds.includes(union.code))
        .forEach(union => {
          selectedUnions = {
            ...selectedUnions,
            [union.code]: union,
          };
        });
    }
    return selectedUnions;
  };

  _getUnionCodes = () => {
    const { unions = {} } = this.props.department;
    if (this._getStatus() === 'loading') {
      return [];
    }
    return Object.keys(unions);
  };

  _getUnions = () => {
    const unionsQuery = this.props.unions;
    const unions = unionsQuery.data.items;
    if (unionsQuery.status === 'success' && Array.isArray(unions)) {
      return unions.map(union => ({
        label: union.description,
        value: String(union.code),
      }));
    }

    return [];
  };

  _changeUnions = selectedUnionCodes => {
    // remove unions from mutator if all unions are removed from the select component
    if (selectedUnionCodes === []) {
      this.props.unionOnChange({});
      return;
    }

    const {
      routerParams: { projectId },
      unions: { data = {} },
    } = this.props;
    const { items: unions } = data || {};
    const { selectedUnionCodes: oldUnionCodes } = this.state;
    const previousUnionCodes = [...oldUnionCodes];
    // create object with unionCode and unionObject key/value pairs
    const selectedUnions = {};
    selectedUnionCodes.forEach(unionCode => {
      const unionObject = unions.find(union => union.code === unionCode);
      selectedUnions[unionCode] = unionObject;
    });
    // find the newly selected union code
    // newUnionCode will be undefined if a union is removed
    const newUnionCode = selectedUnionCodes.filter(
      code => !previousUnionCodes.includes(code),
    )[0];
    this.setState(
      {
        isRequestingOccupations: !!newUnionCode,
        selectedUnionCodes,
        previousUnionCodes,
      },
      () => {
        this.props.unionOnChange(selectedUnions);
        // do not request occupations if union code is undefined
        if (!newUnionCode) return;
        ajax
          .get(`/payroll/projects/${projectId}/occupations/${newUnionCode}`)
          .then(({ items }) => {
            this.setState(({ occupations, selectedUnionCodes }) => {
              const uniqueOccupations = [...occupations, ...items]
                .filter(occupation =>
                  selectedUnionCodes.includes(occupation.union_id),
                )
                .filter(
                  (occupation, idx, self) =>
                    self.findIndex(
                      occ =>
                        `${occ.code}-${occ.description}-${occ.union_id}` ===
                        `${occupation.code}-${occupation.description}-${occupation.union_id}`,
                    ) === idx,
                );
              return {
                occupations: uniqueOccupations,
                isRequestingOccupations: false,
              };
            });
          })
          .catch(err =>
            this.setState({
              occupationsError: true,
              isRequestingOccupations: false,
            }),
          );
      },
    );
  };

  _changeOccupations = occupations => {
    this.props.occOnChange(occupations);
  };
}

export default compose(withApi, withStyles(styles))(Rule);
