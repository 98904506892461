import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {
  TextFields as TextFieldsIcon,
  Check as CheckIcon,
  DateRange as DateIcon,
  ExpandMore as ExpandMoreIcon,
  CalendarToday as TodaysDateIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import {
  SignatureIcon_mapper as SignatureIcon,
  InitialsSignatureIcon_mapper as InitialsSignatureIcon,
  Number as NumberIcon,
  RadioButton as RadioButtonIcon,
  Dropdown as DropdownIcon,
} from 'common/icons';
import * as FieldTypes from 'common/utilities/constants/fieldTypes';
import includesDuplicates from 'common/utilities/includesDuplicates';
import isDocCollectSystemField from 'common/utilities/isDocCollectSystemField';
import {
  filterSystemFields,
  searchSystemFields,
} from 'common/utilities/filterSystemFields';
import { newBadge } from 'admin/components/RoleMapper/documentSetupHelpers/notifications';
import RadioButtonAssignments from './RadioButtonAssignments';
import DropdownAssignments from './DropdownAssignments';
import FieldFormat from './FieldFormat';
import PrepopulateSection from './PrepopulateSection';
import FieldDetails from './FieldDetails';
import RuleAssignment from './RuleAssignment';

const styles = theme => ({
  root: {
    height: 'calc(100% - 60px)',
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  buttonContainer: {
    backgroundColor: 'white',
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 60,
  },
  drawerText: {
    fontWeight: 600,
  },
  actionButton: {
    width: 150,
    marginRight: 16,
    height: 34,
  },
  expansionPanelDetails: {
    display: 'grid !important',
    gap: `${theme.spacing.unit * 2.5}px`,
    gridGap: `${theme.spacing.unit * 2.5}px`,
    listStyleType: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '15px',
    zIndex: 100,
  },
  tooltip: {
    fontSize: '1rem',
  },
  divider: {
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  expandedPanel: {
    marginBottom: '16px !important',
  },
});

const dateOptions = [
  {
    value: 'mm/dd/yyyy',
    label: 'MM/DD/YYYY',
  },
  {
    value: 'mm/dd/yy',
    label: 'MM/DD/YY',
  },
];

class FieldDrawer extends Component {
  constructor(props) {
    super(props);
    const { drawerValues = {}, systemFieldOptions = [], rules = [] } = props;
    this.state = {
      dropdownEditKey: '',
      editValue: '',
      error: '',
      removedBadgeIds: [],
      searchMenuAnchor: null,
      fieldValues: drawerValues,
      systemFieldOptions,
      rules,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { drawerValues, systemFieldOptions, rules } = props;
    const { id: upstreamFieldId } = drawerValues;
    const { fieldValues: { id: stateFieldId } = {} } = state;
    // Update the field values if the upstream field id has changed
    if (upstreamFieldId !== stateFieldId) {
      return {
        fieldValues: drawerValues,
        systemFieldOptions,
        rules,
      };
    }
    return null;
  }

  componentDidMount() {
    const { systemFieldOptions = [] } = this.props;
    const { fieldValues: { customProperties = {} } = {} } = this.state;
    const { systemFieldId = null } = customProperties || {};
    this.verifySystemFieldExists(systemFieldId, systemFieldOptions);
  }

  // Validate that the system field assigned to the field still exists in the system field options
  // eg. Custom inputs can be added and removed from system field options
  verifySystemFieldExists(systemFieldId, systemFieldOptions) {
    if (systemFieldId) {
      const isExistingSystemField = systemFieldOptions.filter(
        option => option.items.filter(item => item.id === systemFieldId).length,
      ).length;
      if (!isExistingSystemField) {
        this.selectSystemField({ label: 'None', name: '' });
      }
    }
  }

  // Keep a copy of the value being edited to update the correct option
  // when it is saved or cancelled by the user
  setOptionEditKey = option => {
    this.setState({ dropdownEditKey: option, editValue: option });
  };

  // Add a new dropdown option to existing array of options and
  // reset the edit key and edit value
  submitUpdatedOption = () => {
    this.setState(({ dropdownEditKey, editValue, fieldValues }) => {
      const { options = [] } = fieldValues;
      const updatedOptions = options.map(option =>
        option.toLowerCase() === dropdownEditKey.toLowerCase()
          ? editValue
          : option,
      );
      return {
        fieldValues: {
          ...fieldValues,
          options: updatedOptions,
        },
        dropdownEditKey: '',
        editValue: '',
      };
    });
  };

  handleOptionValueChange = ({ target: { value } }) =>
    this.setState({
      editValue: value,
    });

  // Submit the dropdown option to be saved when enter is pressed
  handleKeyPress = ({ key }) => {
    if (key === 'Enter') {
      this.submitUpdatedOption();
    }
  };

  // Remove the dropdown option from the options array and reset the edit key
  handleDeleteOption = option =>
    this.setState(({ fieldValues }) => {
      const { options = [] } = fieldValues;
      const updatedOptions = options.filter(
        opt => opt.toLowerCase() !== option.toLowerCase(),
      );
      return {
        fieldValues: {
          ...fieldValues,
          options: updatedOptions,
        },
        dropdownEditKey: '',
        editValue: '',
      };
    });

  clearRadioButtonAssignments = () => {
    const { fieldType } = this.state.fieldValues;
    if (fieldType === FieldTypes.RDO) {
      this.updateField({}, 'systemFieldMapping');
    }
    this.setError();
  };

  updateDropdownOptions = systemFieldOptions => {
    const { fieldType } = this.state.fieldValues;
    if (fieldType === FieldTypes.CMB) {
      const options = Object.values(systemFieldOptions);
      this.updateField(options, 'options');
      this.updateField(systemFieldOptions, 'systemFieldMapping');
    }
  };

  // Update the field with the selected system field
  selectSystemField = systemField => {
    const { options = {} } = systemField;
    this.handleCloseMenu();
    this.updateField(systemField, 'systemField');
    this.updateField(systemField.id, 'customProperties', 'systemFieldId');
    this.updateDropdownOptions(options);
    this.clearRadioButtonAssignments();
  };

  // Update the radio button value with the system field name
  updateRadioButtonAssignment = (radioOptionName, systemFieldValue) => {
    const {
      fieldValues: { systemFieldMapping = {} },
    } = this.state;
    const newMapping = {
      ...systemFieldMapping,
      [radioOptionName]: systemFieldValue,
    };
    this.updateField(newMapping, 'systemFieldMapping');
  };

  setError = (error = '') => this.setState({ error });

  validateDrawerValues = () => {
    const { fieldValues } = this.state;
    const {
      radioGroupItems = [],
      systemField = {},
      systemFieldMapping = {},
      displayName = '',
    } = fieldValues;
    // Validate radio buttons have been assigned to all system field options
    const validateSystemFieldMapping =
      radioGroupItems.length &&
      systemField.options &&
      Object.keys(systemField.options).length;

    if (validateSystemFieldMapping) {
      if (radioGroupItems.some(radio => !systemFieldMapping[radio.name])) {
        this.setError('All radio buttons must be mapped.');
        return false;
      }
      if (includesDuplicates(Object.values(systemFieldMapping))) {
        this.setError('All radio buttons must be mapped to unique values.');
        return false;
      }
    }

    // Validate the field name
    if ((displayName || '').length > 0 && (displayName || '').length < 3) {
      this.setError('Display Name must be at least 3 characters.');
      return false;
    }

    this.setError();
    return true;
  };

  renderDisplayIcon = inputType => {
    const formattedInput = inputType ? inputType.toUpperCase() : '';
    const style = { marginRight: 10 };
    switch (formattedInput) {
      case FieldTypes.TXT:
        return <TextFieldsIcon style={style} />;
      case FieldTypes.NUMBER:
        return <NumberIcon style={{ ...style, width: 24 }} />;
      case FieldTypes.CHK:
        return <CheckIcon style={style} />;
      case FieldTypes.RDO:
        return <RadioButtonIcon style={style} />;
      case FieldTypes.CMB:
        return <DropdownIcon style={style} />;
      case FieldTypes.SIGNATURE:
        return <SignatureIcon style={style} />;
      case FieldTypes.INITIAL_SIGNATURE:
        return <InitialsSignatureIcon style={style} />;
      case FieldTypes.DATE_SIGNED:
        return <TodaysDateIcon style={style} />;
      case FieldTypes.DATE:
        return <DateIcon style={style} />;
      default:
        return <TextFieldsIcon style={style} />;
    }
  };

  // Converts any value to a positive integer or null
  toPositiveInteger = val => {
    val = `${val}`.replace(/[^0-9]+/g, '');
    if (val === '') return null;
    return parseInt(val);
  };

  // Add a new blank dropdown option
  addNewOption = () =>
    this.setState(({ fieldValues }) => {
      const { options = [] } = fieldValues;
      const updatedOptions = [...options, ''];
      return {
        fieldValues: {
          ...fieldValues,
          options: updatedOptions,
        },
      };
    });

  // Open the system field menu and filter the options
  // based on the field type that is currently selected
  handleOpenMenu = event =>
    this.setState({
      searchMenuAnchor: event.currentTarget,
      systemFieldOptions: filterSystemFields(
        this.state.fieldValues.fieldType,
        this.state.fieldValues.textType,
        this.props.systemFieldOptions,
      ),
    });

  // Close the system field menu
  handleCloseMenu = () =>
    this.setState(
      {
        searchMenuAnchor: null,
      },
      () => {
        // Add a delay to prevent the UI from refreshing the filtered list before it closes
        setTimeout(
          () =>
            this.setState({
              systemFieldOptions: this.props.systemFieldOptions,
            }),
          200,
        );
      },
    );

  // Filter the system field menu based on user input
  handleFilterSystemFieldOptions = ({ target: { value } }) => {
    const { systemFieldOptions } = this.props;
    const {
      fieldValues: { fieldType, textType },
    } = this.state;

    const filteredOptions = filterSystemFields(
      fieldType,
      textType,
      systemFieldOptions,
    );
    const searchResults = searchSystemFields(value, filteredOptions);
    this.setState({
      systemFieldOptions: searchResults,
    });
  };

  // Update the field with the corresponding key and value
  // or update the nested object is a subkey is provided
  updateField = (value, key, subKey = null) => {
    if (subKey) {
      this.setState(({ fieldValues }) => {
        const { [key]: parentObject } = fieldValues;
        return {
          fieldValues: {
            ...fieldValues,
            [key]: {
              ...parentObject,
              [subKey]: value,
            },
          },
        };
      });
    } else {
      this.setState(({ fieldValues }) => ({
        fieldValues: {
          ...fieldValues,
          [key]: value,
        },
      }));
    }
    if (key === 'textType' && value !== 'number') {
      this.updateField(null, 'max');
    }
  };

  handleSaveField = () => {
    const { saveField, updateRules } = this.props;
    const { fieldValues, rules, removedBadgeIds } = this.state;
    const { options: optionsArray = [], fieldType, textType } = fieldValues;
    const options = {};
    // If saving a dropdown field, transform the options from an array back to an object
    if (fieldType === FieldTypes.CMB && optionsArray.length !== 0) {
      optionsArray.forEach(option => {
        options[option] = option;
      });
    }
    const updatedFieldValues = {
      ...fieldValues,
      options,
    };
    // Clear the system field if the field is a signature field,
    // because signature fields cannot be assigned a system field
    if (
      textType === FieldTypes.SIGNATURE.toLowerCase() ||
      textType === FieldTypes.INITIAL_SIGNATURE.toLowerCase()
    ) {
      updatedFieldValues.systemField = {};
    }
    saveField(updatedFieldValues);
    updateRules(rules, removedBadgeIds);
  };

  // Assign a trigger rule to a field
  // Update the trigger rule with the parent field id
  // and the field with the trigger rule id
  assignRule = ({
    triggerRuleId,
    originalTriggerRuleId,
    triggerActionType,
    triggerValue = '',
  }) => {
    this.setState(({ fieldValues = {}, rules = [] }) => {
      const { id: fieldId } = fieldValues;
      const isReplacement =
        triggerRuleId !== originalTriggerRuleId && !!originalTriggerRuleId;
      let updatedRules = rules.map(rule =>
        rule.id === triggerRuleId
          ? {
              ...rule,
              ...this.applyTriggerConfig(
                triggerActionType,
                triggerValue,
                fieldId,
              ),
            }
          : rule,
      );
      // When replacing an existing rule we need to unassign the previous trigger and replace with the new trigger rule
      if (isReplacement) {
        updatedRules = this.resetTriggerSource(
          updatedRules,
          originalTriggerRuleId,
        );
      }
      return {
        rules: updatedRules,
      };
    });
  };

  assignBadge = ({
    triggerRuleId,
    originalTriggerRuleId,
    triggerActionType,
    triggerValue = '',
    ruleType = '',
  }) => {
    this.setState(({ fieldValues = {}, rules = [], removedBadgeIds = [] }) => {
      const { id: fieldId } = fieldValues;
      const { badgeTypes = [] } = this.props;
      const badgeIds = badgeTypes.map(({ name }) => name);
      const updatedRemovedBadgeIds = [...removedBadgeIds];
      const isReplacement =
        triggerRuleId !== originalTriggerRuleId && !!originalTriggerRuleId;
      let updatedRules = [...rules];

      if (isReplacement) {
        updatedRules = this.resetTriggerSource(
          updatedRules,
          originalTriggerRuleId,
        );

        // Remove any unassigned badge rules
        updatedRules = updatedRules.filter(
          ({ ruleType, triggerFieldId, id }) => {
            if (badgeIds.includes(id) && ruleType === 'badge') {
              if (!triggerFieldId) {
                updatedRemovedBadgeIds.push(id);
              }
              return !!triggerFieldId;
            }
            return true;
          },
        );
      }
      const ruleIdExists = updatedRules.some(
        ({ id, ruleType }) => ruleType === 'badge' && id === triggerRuleId,
      );

      if (!ruleIdExists && !!triggerRuleId) {
        updatedRules = updatedRules.concat({
          ...newBadge(badgeTypes.find(({ name }) => name === triggerRuleId)),
          ...this.applyTriggerConfig(triggerActionType, triggerValue, fieldId),
        });
      }
      return {
        rules: updatedRules,
        removedBadgeIds: [...new Set(updatedRemovedBadgeIds)],
      };
    });
  };

  resetTriggerSource = (rules, originalTriggerRuleId) =>
    rules.map(rule =>
      rule.id === originalTriggerRuleId
        ? {
            ...rule,
            triggerAction: { triggerActionType: null, value: null },
            triggerFieldId: '',
          }
        : rule,
    );

  applyTriggerConfig = (actionType, value, fieldId) => ({
    triggerAction: {
      triggerActionType: actionType,
      value: typeof value === 'string' ? value.trim() : value,
    },
    triggerFieldId: fieldId,
  });

  removeRuleFromField = ruleId => {
    this.setState(({ fieldValues = {}, rules = [] }) => {
      const updatedRules = rules.map(rule =>
        rule.id === ruleId
          ? {
              ...rule,
              triggerAction: { triggerActionType: null, value: null },
              triggerFieldId: null,
            }
          : rule,
      );
      return {
        rules: updatedRules,
      };
    });
  };

  // Render the generic field details that are available to all fields independent of field type
  renderFieldDetails = () => {
    const { textFieldOptions } = this.props;
    const { fieldValues = {}, rules = [] } = this.state;
    const fieldTypeSelectProps = {
      textFieldOptions,
      renderDisplayIcon: this.renderDisplayIcon,
    };
    const fieldDetailsProps = {
      fieldValues,
      fieldTypeSelectProps,
      conditionalRules: rules.filter(({ ruleType }) => ruleType === 'group'),
      triggerRules: rules.filter(({ ruleType }) => ruleType === 'trigger'),
      updateField: this.updateField,
    };
    return <FieldDetails {...fieldDetailsProps} />;
  };

  renderFieldFormatInputs = () => {
    const { formatOptions = dateOptions } = this.props;
    const { fieldValues } = this.state;
    const fieldFormatProps = {
      formatOptions,
      fieldValues,
      updateField: this.updateField,
      toPositiveInteger: this.toPositiveInteger,
    };
    return <FieldFormat {...fieldFormatProps} />;
  };

  // Render the selected system field name if any and the system field menu
  renderPrepopulateContent = disabled => {
    const { tags = [] } = this.props;
    const {
      fieldValues = {},
      searchMenuAnchor,
      systemFieldOptions = [],
    } = this.state;
    const multiLevelMenuProps = {
      anchor: searchMenuAnchor,
      closeMenu: this.handleCloseMenu,
      filteredOptions: systemFieldOptions,
      filterOptions: this.handleFilterSystemFieldOptions,
      selectSystemField: this.selectSystemField,
    };
    const prepopulateProps = {
      tags,
      fieldValues,
      disabled,
      selectSystemField: this.selectSystemField,
      handleOpenMenu: this.handleOpenMenu,
      updateField: this.updateField,
      multiLevelMenuProps,
    };
    return <PrepopulateSection {...prepopulateProps} />;
  };

  // Render trigger assignment section
  renderTriggerSection = (disabled = false) => {
    const {
      fields = [],
      triggerActions,
      responseBehaviorOptions,
      toggleTriggerRuleHighlight,
      triggerRuleIdToHighlight,
      roles = [],
    } = this.props;
    const { fieldValues = {}, rules = [] } = this.state;
    return (
      <RuleAssignment
        fields={fields}
        triggerActions={triggerActions}
        responseBehaviorOptions={responseBehaviorOptions}
        assignRule={this.assignRule}
        assignBadge={this.assignBadge}
        field={fieldValues}
        toggleTriggerRuleHighlight={toggleTriggerRuleHighlight}
        triggerRuleIdToHighlight={triggerRuleIdToHighlight}
        removeRuleFromField={this.removeRuleFromField}
        disabled={disabled}
        rules={rules}
        assignmentType={'trigger'}
        roles={roles}
      />
    );
  };

  // render workflow assignment section
  renderWorkflowSection = (disabled = false) => {
    const {
      fields = [],
      triggerActions,
      responseBehaviorOptions,
      toggleTriggerRuleHighlight,
      triggerRuleIdToHighlight,
      roles = [],
    } = this.props;
    const { fieldValues = {}, rules = [] } = this.state;
    return (
      <RuleAssignment
        fields={fields}
        triggerActions={triggerActions}
        responseBehaviorOptions={responseBehaviorOptions}
        assignRule={this.assignRule}
        assignBadge={this.assignBadge}
        field={fieldValues}
        toggleTriggerRuleHighlight={toggleTriggerRuleHighlight}
        triggerRuleIdToHighlight={triggerRuleIdToHighlight}
        removeRuleFromField={this.removeRuleFromField}
        disabled={disabled}
        rules={rules}
        assignmentType={'workflow'}
        roles={roles}
      />
    );
  };

  // Render the dropdown or radio button content if the field type is either
  renderDropdownRadioOptions = () => {
    const { fieldValues, dropdownEditKey, editValue } = this.state;
    const {
      radioGroupItems,
      systemField,
      fieldType,
      systemFieldMapping = {},
    } = fieldValues;
    const showRadioAssignments =
      radioGroupItems &&
      radioGroupItems.length &&
      systemField.options &&
      Object.keys(systemField.options).length;
    const dropdownAssignmentsProps = {
      fieldValues,
      dropdownEditKey,
      editValue,
      handleOptionValueChange: this.handleOptionValueChange,
      handleKeyPress: this.handleKeyPress,
      submitUpdatedOption: this.submitUpdatedOption,
      setOptionEditKey: this.setOptionEditKey,
      handleDeleteOption: this.handleDeleteOption,
      addNewOption: this.addNewOption,
      title: 'Dropdown Options:',
    };
    const radioButtonAssignmentsProps = {
      radios: radioGroupItems || [],
      mapping: systemFieldMapping || {},
      options: systemField.options,
      onAssign: this.updateRadioButtonAssignment,
      disabled: !showRadioAssignments,
      title: 'Radio Button Values:',
    };
    return (
      <div>
        {fieldType === FieldTypes.CMB && (
          <DropdownAssignments {...dropdownAssignmentsProps} />
        )}
        {fieldType === FieldTypes.RDO && (
          <RadioButtonAssignments {...radioButtonAssignmentsProps} />
        )}
      </div>
    );
  };

  renderShareSection = (disabled = false) => {
    const {
      fields,
      triggerActions,
      toggleTriggerRuleHighlight,
      triggerRuleIdToHighlight,
      roles = [],
      badgeTypes = [],
    } = this.props;
    const { fieldValues = {}, rules = [] } = this.state;
    return (
      <RuleAssignment
        rules={rules.filter(
          ({ ruleType }) => ruleType === 'email' || ruleType === 'badge',
        )}
        triggerActions={triggerActions}
        field={fieldValues}
        toggleTriggerRuleHighlight={toggleTriggerRuleHighlight}
        triggerRuleIdToHighlight={triggerRuleIdToHighlight}
        removeRuleFromField={this.removeRuleFromField}
        assignRule={this.assignRule}
        assignBadge={this.assignBadge}
        disabled={disabled}
        fields={fields}
        assignmentType={'share'}
        roles={roles}
        badgeTypes={badgeTypes}
      />
    );
  };

  renderFieldPanel = field => {
    const { classes } = this.props;
    const tooltipMessage = (field.disabled && field.tooltipMessage) || '';
    return (
      !field.hidden && (
        <Tooltip
          title={tooltipMessage}
          classes={{ tooltip: classes.tooltip }}
          key={field.key}
        >
          <ExpansionPanel
            key={field.key}
            defaultExpanded={field.defaultExpanded && !field.disabled}
            data-test-id={field.dataTestId}
            disabled={field.disabled}
            classes={{ expanded: classes.expandedPanel }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.drawerText} variant="subtitle1">
                {field.title}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              classes={{ root: classes.expansionPanelDetails }}
            >
              {field.render(field.disabled)}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Tooltip>
      )
    );
  };

  // Render the different sections of the field drawer
  renderDrawerContent = () => {
    const { fieldValues, rules = [] } = this.state;
    const { customProperties = {}, roleId, id } = fieldValues || {};
    const { systemFieldId = null } = customProperties || {};
    const isRadioButton = fieldValues.fieldType === FieldTypes.RDO;
    const isDropdown = fieldValues.fieldType === FieldTypes.CMB;
    const assignedRule = rules.find(({ fieldGroups = [] }) =>
      fieldGroups.some(({ fieldIds = [] }) => fieldIds.includes(id)),
    );
    const { name: ruleName, ruleType } = assignedRule || {};
    const triggerRuleTooltip = (() => {
      if (ruleType)
        return `This field cannot be assigned as a trigger rule source, because it is mapped to the "${ruleName}" rule.`;
      if (!roleId)
        return 'This field cannot be assigned as a trigger rule source, because it is not mapped to a role.';
    })();

    return (
      <React.Fragment>
        {this.renderFieldPanel({
          title: 'Field Format:',
          render: this.renderFieldFormatInputs,
          key: 'fieldFormat',
          defaultExpanded:
            fieldValues.textType === FieldTypes.NUMBER.toLowerCase() ||
            fieldValues.textType === FieldTypes.DATE.toLowerCase(),
          dataTestId: 'FieldDrawer-fieldFormatPanel',
          hidden: false,
        })}
        {this.renderFieldPanel({
          title: `${
            fieldValues.fieldType === FieldTypes.CMB ? 'Dropdown' : 'Radio'
          } Inputs: `,
          render: this.renderDropdownRadioOptions,
          key: 'multiOption',
          defaultExpanded: isRadioButton || isDropdown,
          dataTestId: 'FieldDrawer-multiOptionPanel',
          hidden: !(isRadioButton || isDropdown),
        })}
        {this.renderFieldPanel({
          title: 'Pre-populate From:',
          render: this.renderPrepopulateContent,
          key: 'prePopulate',
          defaultExpanded: !!systemFieldId,
          dataTestId: 'FieldDrawer-prePopulatePanel',
          hidden: false,
        })}
        {this.renderFieldPanel({
          title: 'Triggers:',
          render: this.renderTriggerSection,
          key: 'triggers',
          defaultExpanded: !ruleType || !!roleId,
          dataTestId: 'FieldDrawer-triggers',
          hidden: false,
          disabled: !!ruleType || !roleId,
          tooltipMessage: triggerRuleTooltip,
        })}
        {this.renderFieldPanel({
          title: 'Conditional Workflows:',
          render: this.renderWorkflowSection,
          key: 'workflows',
          defaultExpanded: true,
          dataTestId: 'FieldDrawer-workflows',
          hidden: false,
          disabled: false,
        })}
        {this.renderFieldPanel({
          title: 'Share:',
          render: this.renderShareSection,
          key: 'share',
          defaultExpanded: false,
          dataTestId: 'FieldDrawer-share',
          hidden: false,
          disabled: false,
        })}
      </React.Fragment>
    );
  };

  render() {
    const { classes, tags, onClose } = this.props;
    const { fieldValues } = this.state;
    const {
      systemField = {},
      customProperties = {},
      error,
      displayName = '',
      isRequired,
      options = [],
    } = fieldValues;
    const { collectSystemField = false } = customProperties || {};

    /* If the document is tagged as w4 or w9 set collect system field to true and disable the "Required" checkbox to prevent the users from changing the required value */
    const isW4OrW9 = tags.some(tag => isDocCollectSystemField(tag.name));
    if (isW4OrW9 && !collectSystemField) {
      this.updateField(true, 'customProperties', 'collectSystemField');
    }
    // Set is required to false if a system field is selected and the isRequired value was previously true
    if (systemField.name && isRequired && !collectSystemField) {
      this.updateField(false, 'isRequired');
    }
    const disabled = options.includes('') || (displayName || '').length === 0;

    return (
      <React.Fragment>
        <div className={classes.root} data-test-id="FieldDrawer-root">
          {onClose && (
            <IconButton
              onClick={onClose}
              className={classes.closeButton}
              data-test-id="FieldDrawer-closeButton"
            >
              <CloseIcon />
            </IconButton>
          )}
          {this.renderFieldDetails()}
          {this.renderDrawerContent()}
          {error && (
            <div>
              <Typography color="error" className={classes.error}>
                {error}
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.buttonContainer}>
          <Divider className={classes.divider} />
          <Button
            variant="contained"
            onClick={onClose}
            className={classes.actionButton}
            data-test-id="FieldDrawer-cancelButton"
          >
            Cancel
          </Button>
          <Button
            className={classes.actionButton}
            color="primary"
            variant="contained"
            disabled={!!disabled}
            onClick={() =>
              this.validateDrawerValues() && this.handleSaveField()
            }
            data-test-id="FieldDrawer-saveButton"
          >
            Done
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FieldDrawer);
