import React, { Component } from 'react';
import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Box from 'common/oldJavascripts/components/Base/Box';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Button from 'common/oldJavascripts/components/Base/Button';

import CompletedReviewRow from './Reviews/CompletedRow';
import { DEFAULT_PER_PAGE } from 'common/oldJavascripts/config.js';

import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Field from 'common/oldJavascripts/components/Base/Field';
import Header from 'common/oldJavascripts/components/Base/Header';
import T from 'common/oldJavascripts/utils/i18n';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import LayoutMain from 'common/oldJavascripts/components/Base/Layout/LayoutMain';
import Pagination from 'common/oldJavascripts/components/Base/Pagination';
import Loader from 'common/components/Loader';
import ReviewRow from './Reviews/Row';
import Search from 'common/oldJavascripts/components/Shared/Search';
import object from 'common/oldJavascripts/utils/object.js';
import OfferFullHistory from './Offers/OfferFullHistory';
import history from 'common/constants/config/history';
import qs from 'qs';
import QueryRefresher from 'common/components/QueryRefresher';

// HoC
import { withStyles } from '@material-ui/core/styles';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import withPermissionProtection from 'common/hoc/withPermissionProtection';

import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const Row = ExpandableTableRow;
const Cell = ExpandableTableCell;

const setID = (dict, id, flag) => {
  if (!dict[id] !== !flag) {
    dict = object.clone(dict);
    if (flag) {
      dict[id] = true;
    } else {
      delete dict[id];
    }
  }
  return dict;
};

const toggleId = (dict, id) => {
  dict = object.clone(dict);
  if (dict[id]) {
    delete dict[id];
  } else {
    dict[id] = true;
  }
  return dict;
};

const styles = () => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  checkBoxHeader: {
    width: '4%',
  },
  employeeNameHeader: {
    width: '15%',
  },
  departmentHeader: {
    width: '10%',
  },
  jobTitleHeader: {
    width: '10%',
  },
  unionHeader: {
    width: '9%',
  },
  startDateHeader: {
    width: '10%',
  },
  rateHeader: {
    width: '10%',
  },
  allowancesHeader: {
    width: '11%',
  },
  statusHeader: {
    width: '7%',
  },
  deleteHeader: {
    width: '5%',
  },
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
});

class Reviews extends Component {
  static queries = {
    reviews: {
      info(params, related) {
        const routerParams = related['/router/params'];
        const query = related['/router/query'];

        return {
          cursor: {
            direction: query.direction,
            page: query.page,
            per_page: query.per_page || DEFAULT_PER_PAGE,
            q: query.q,
            sort: query.sort,
            status: query.status,
          },
          id: `/projects/${routerParams.projectId}/reviews`,
        };
      },
    },
    project: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/projects/${params.projectId}`,
        };
      },
    },
  };
  state = {
    allowanceExpanded: {},
    expandedReviews: {},
    selectedReviews: {},
    statusExpanded: {},
  };

  _areAllSelected = () => {
    const { selectedReviews = {} } = this.state;
    const { reviews: reviewsQuery = {} } = this.props;
    const { data: { items: reviews = [] } = {} } = reviewsQuery || {};
    return Object.keys(selectedReviews).length === reviews.length;
  };

  _handleSelectReview = (id, selected) =>
    this.setState(({ selectedReviews }) => ({
      selectedReviews: setID(selectedReviews, id, selected),
    }));

  _isCompleted = () => {
    const { queryParams = {} } = this.props;
    const { status } = queryParams || {};
    return status === 'completed';
  };

  _renderCompletedHeaderRow = () => {
    const { project: projectQuery = {}, classes } = this.props;
    const { data: { allow_end_date: allowEndDate } = {} } = projectQuery || {};
    return (
      <Row>
        <Cell
          className={classes.employeeNameHeader}
          header={true}
          sort="last_name,first_name,middle_name"
        >
          {T('project.reviews.columns.employee')}
        </Cell>
        <Cell
          className={classes.departmentHeader}
          header={true}
          sort="department"
        >
          {T('project.reviews.columns.department_account')}
        </Cell>
        <Cell className={classes.jobTitleHeader} header={true}>
          {T('project.reviews.columns.occupation')}
        </Cell>
        <Cell className={classes.unionHeader} header={true}>
          {T('project.reviews.columns.union')}
        </Cell>
        <Cell className={classes.startDateHeader} header={true}>
          {T('project.reviews.columns.start_date')}
        </Cell>
        {allowEndDate && (
          <Cell
            className={classes.startDateHeader}
            header={true}
            sort="end_date"
          >
            End Date
          </Cell>
        )}
        <Cell
          className={classes.rateHeader}
          header={true}
          sort="rate_per_hour_studio,rate_per_hour_location,rate_per_day_studio,rate_per_week_studio,rate_per_week_location"
        >
          {T('project.reviews.columns.rate')}
        </Cell>
        <Cell className={classes.allowancesHeader} header={true}>
          Allowances / Reimbursements
        </Cell>
        <Cell
          className={classes.statusHeader}
          header={true}
          sort="status_description"
        >
          {T('project.reviews.columns.status')}
        </Cell>
      </Row>
    );
  };

  _renderCompletedRows = review => {
    const id = review.id;
    const state = this.state;
    const endDateAllowed = this.props.project.data.allow_end_date;
    const isAllowanceExpanded = state.allowanceExpanded[id];
    const isStatusExpanded = state.statusExpanded[id];
    const { reviews: { reload: reloadQuery } = {} } = this.props;

    const rows = [
      <CompletedReviewRow
        allowanceExpanded={isAllowanceExpanded}
        key={id}
        onAllowanceToggle={this._toggleAllowance}
        onStatusToggle={this._toggleStatus}
        params={{ id: id }}
        statusExpanded={isStatusExpanded}
        endDateAllowed={endDateAllowed}
      />,
    ];

    if (isAllowanceExpanded) {
      rows.push(
        <AllowancesRow colSpan={10} key={`allowance-${id}`} offer={review} />,
      );
    }

    if (isStatusExpanded) {
      rows.push(
        <ExpandableTableRow>
          <ExpandableTableCell colSpan={11} expanded>
            <Header>
              <Header.Title secondary>Offer Full History</Header.Title>
              <QueryRefresher reloadQuery={reloadQuery} />
            </Header>
            <OfferFullHistory offer={review} />
          </ExpandableTableCell>
        </ExpandableTableRow>,
      );
    }

    return rows;
  };

  _renderHeader = () => {
    const { reviews: { reload: reloadQuery } = {} } = this.props;

    return (
      <Header>
        <Header.Title>
          {T(`project.reviews.${this._isCompleted() ? 'sent_title' : 'title'}`)}
        </Header.Title>
        <QueryRefresher reloadQuery={reloadQuery} />
        <Header.Nav>
          <Search />
          {this._renderStartReviewButton()}
        </Header.Nav>
      </Header>
    );
  };

  _renderIncompleteHeaderRow = () => {
    const { project: projectQuery = {}, classes } = this.props;
    const { data: { allow_end_date: allowEndDate } = {} } = projectQuery || {};
    return (
      <Row data-test-id={`Reviews-row-header`}>
        <Cell className={classes.checkBoxHeader} header={true}>
          <Field
            inline={true}
            label=""
            onChange={checked => this._selectAll(checked)}
            type="checkbox"
            value={this._areAllSelected()}
          />
        </Cell>
        <Cell
          className={classes.employeeNameHeader}
          header={true}
          sort="last_name,first_name,middle_name"
        >
          {T('project.reviews.columns.employee')}
        </Cell>
        <Cell
          className={classes.departmentHeader}
          header={true}
          sort="department"
        >
          {T('project.reviews.columns.department_account')}
        </Cell>
        <Cell className={classes.jobTitleHeader} header={true}>
          {T('project.reviews.columns.occupation')}
        </Cell>
        <Cell className={classes.unionHeader} header={true}>
          {T('project.reviews.columns.union')}
        </Cell>
        <Cell className={classes.startDateHeader} header={true}>
          {T('project.reviews.columns.start_date')}
        </Cell>
        {allowEndDate && (
          <Cell
            className={classes.startDateHeader}
            header={true}
            sort="end_date"
          >
            End Date
          </Cell>
        )}
        <Cell
          className={classes.rateHeader}
          header={true}
          sort="rate_per_hour_studio,rate_per_hour_location,rate_per_day_studio,rate_per_week_studio,rate_per_week_location"
        >
          {T('project.reviews.columns.rate')}
        </Cell>
        <Cell className={classes.allowancesHeader} header={true}>
          Allowances / Reimbursements
        </Cell>
        <Cell
          className={classes.statusHeader}
          header={true}
          sort="status_description"
        >
          {T('project.reviews.columns.status')}
        </Cell>
        <Cell className={classes.deleteHeader} header={true} />
      </Row>
    );
  };

  _renderIncompleteRows = review => {
    const id = review.id;
    const state = this.state;
    const endDateAllowed = this.props.project.data.allow_end_date;
    const isExpanded = state.expandedReviews[id];
    const isSelected = state.selectedReviews[id];

    const rows = [
      <ReviewRow
        checked={isSelected}
        expanded={isExpanded}
        key={id}
        onAllowanceToggle={this._toggleReview}
        onSelect={this._handleSelectReview}
        params={{ id: id }}
        endDateAllowed={endDateAllowed}
        data-test-id={`Reviews-row-${id}`}
      />,
    ];

    if (isExpanded) {
      rows.push(
        <AllowancesRow key={`allowance-${id}`} colSpan={11} offer={review} />,
      );
    }

    return rows;
  };

  _renderPagination = () => {
    const reviews = this.props.reviews.data;
    if (reviews && reviews.total_pages > 0) {
      return (
        <Pagination page={reviews.page} totalPages={reviews.total_pages} />
      );
    }
  };

  _renderStartReviewButton = () => {
    if (!this._isCompleted()) {
      return (
        <Button onClick={this._startReview}>
          {T('project.reviews.review_bulk')}
        </Button>
      );
    }
  };

  _selectAll = selected => {
    if (selected) {
      const selectedReviews = {};
      this.props.reviews.data.items.forEach(
        review => (selectedReviews[review.id] = true),
      );
      this.setState({ selectedReviews });
    } else {
      this.setState({ selectedReviews: {} });
    }
  };

  _startReview = () => {
    const { routerParams = {} } = this.props;
    const { selectedReviews = [] } = this.state;
    const { projectId } = routerParams || {};
    const reviewIds = Object.keys(selectedReviews);
    const [initialOffer] = reviewIds;

    if (reviewIds.length > 0) {
      history.push({
        pathname: `/projects/${projectId}/offers/${initialOffer}/edit`,
        search: qs.stringify({
          ids: reviewIds.join(','),
        }),
      });
    }
  };

  _toggleAllowance = id => {
    const allowanceExpanded = toggleId(this.state.allowanceExpanded, id);
    const statusExpanded = allowanceExpanded[id]
      ? setID(this.state.statusExpanded, id, false)
      : this.state.statusExpanded;
    this.setState({
      allowanceExpanded,
      statusExpanded,
    });
  };

  _toggleReview = id => {
    this.setState({
      expandedReviews: toggleId(this.state.expandedReviews, id),
    });
  };

  _toggleStatus = id => {
    const statusExpanded = toggleId(this.state.statusExpanded, id);
    const allowanceExpanded = statusExpanded[id]
      ? setID(this.state.allowanceExpanded, id, false)
      : this.state.allowanceExpanded;
    this.setState({
      allowanceExpanded,
      statusExpanded,
    });
  };

  renderContent = () => {
    const { reviews: reviewsQuery = {}, classes = {} } = this.props;
    const { status: reviewsStatus, data: { items: reviews = [] } = {} } =
      reviewsQuery || {};

    if (reviewsStatus === 'loading')
      return <Loader className={classes.loader} />;
    if (reviewsStatus === 'failed') return <FailureAlert />;
    if (reviews.length === 0)
      return <Blankslate>{T('project.reviews.not_found')}</Blankslate>;

    return (
      <ExpandableTable className={classes.table}>
        {this._renderIncompleteHeaderRow()}
        {reviews.map(this._renderIncompleteRows)}
      </ExpandableTable>
    );
  };

  render() {
    return (
      <LayoutMain>
        <LayoutContent>
          <Box full={true}>
            <BoxItem>{this._renderHeader()}</BoxItem>
            {this.renderContent()}
          </Box>
          {this._renderPagination()}
        </LayoutContent>
      </LayoutMain>
    );
  }
}

export default compose(
  withPermissionProtection('can_review_offers'),
  withApi,
  withStyles(styles),
)(Reviews);
