import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Material UI
import { List } from '@material-ui/core';

// Components
import DocumentSelectorPanel from './DocumentSelectorPanel';

// Utilities
import classNames from 'classnames';

const styles = theme => ({
  root: {
    height: '100%',
    width: '100%',
    background: 'white',
  },
  menu: {
    padding: 0,
    overflowY: 'auto',
    height: '100%',
  },
});

const DocumentSelector = props => {
  const { classes, documents, currentDocumentId, className, ...rest } = props;
  const menuItems = documents.map(doc => {
    const { id } = doc;
    const isExpanded = id === currentDocumentId;
    return (
      <DocumentSelectorPanel
        key={id}
        doc={doc}
        isExpanded={isExpanded}
        {...rest}
      />
    );
  });

  return (
    <div className={classNames(classes.root, className)}>
      <List component="nav" className={classes.menu}>
        {menuItems}
      </List>
    </div>
  );
};

export default withStyles(styles)(DocumentSelector);
