import React, { Component } from 'react';
import Blankslate from '../Base/Blankslate';
import Field from '../Base/Field';
import Header from '../Base/Header';
import T from '../../utils/i18n';

// HoC
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

// Components
import Typography from '@material-ui/core/Typography';
import Loader from 'common/components/Loader';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const styles = {
  root: {
    height: '100%',
  },
  fieldSet: {
    width: '100%',
    height: '100%',
  },
  radioGroup: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: 'repeat(max-content, minMax(36px, 62px))',
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '10%',
    gridRowGap: '0',
  },
  typography: {
    fontSize: 13,
    color: '#434f59',
    display: 'flex',
  },
  infoIcon: {
    width: 20,
    height: 20,
    marginLeft: 4,
  },
};

class OldEthnicGroups extends Component {
  static queries = {
    ethnicGroups: {
      info: function() {
        return { id: '/ethnic-groups' };
      },
    },
  };

  render() {
    const {
      profile: profileMutator = {},
      ethnicGroups: ethnicGroupsMutator = {},
      title,
      label,
      classes,
    } = this.props;
    const { data: profile = {}, errors = {} } = profileMutator;
    const { ethnic_code = null } = profile;
    const {
      data: { items: ethnicGroups = [] } = {},
      status: ethnicGroupsStatus,
    } = ethnicGroupsMutator;
    const showContent = ethnicGroups.length > 0;

    if (ethnicGroupsStatus === 'loading') return <Loader size={24} />;

    const radioOptions = ethnicGroups.map(({ code, title }) => ({
      label: title,
      value: code,
      grouped: true,
    }));

    return (
      <div className={classes.root}>
        {title && (
          <Header>
            <Header.Title subtitle>{T(title)}</Header.Title>
          </Header>
        )}
        {label && (
          <Typography variant="body1" className={classes.typography}>
            {label} <span className="field-required">*</span>{' '}
            <Tooltip title='Please choose from the following options. You may opt out by selecting "Choose not to disclose".'>
              <InfoIcon
                className={classes.infoIcon}
                data-test-id="EthnicGroups-infoIcon"
              />
            </Tooltip>
          </Typography>
        )}
        {showContent && (
          <Field
            errors={errors.ethnic_code}
            type="radiogroup"
            name="ethnic_code"
            value={ethnic_code}
            options={radioOptions}
            onChange={profileMutator.update('ethnic_code')}
            compact
            required={true}
            classes={{
              fieldSet: classes.fieldSet,
              radioGroup: classes.radioGroup,
            }}
          />
        )}
        {!showContent && (
          <Blankslate>{T('No ethnic groups loaded.')}</Blankslate>
        )}
      </div>
    );
  }
}

export default compose(withApi, withStyles(styles))(OldEthnicGroups);
