import React from 'react';
import format from '../../utils/format.js';
import T from '../../utils/i18n';
import collection from '../../utils/collection.js';
import Blankslate from '../Base/Blankslate';
import Header from '../Base/Header';
import Table from '../Base/Table';

import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

var ETableRow = ExpandableTableRow;
var formatTime = format.time;
var ETableCell = ExpandableTableCell;

export default class extends React.Component {
  render() {
    var offer = this.props.offer;
    var historicalRows = [];
    var historicalTable = null;
    var rejectStatus = null;

    if (offer.status !== 'draft' && offer.sent_at) {
      historicalRows.push({
        datetime: offer.sent_at,
        label: T('project_crew.offer_historical.sent'),
      });
    }

    if (offer.approved_at) {
      historicalRows.push({
        datetime: offer.approved_at,
        label: T('project_crew.offer_historical.approved'),
      });
    }

    if (offer.rejected_at) {
      rejectStatus =
        offer.status === 'rejected' ? 'Rejected' : 'Employee Rejected';
      rejectStatus += ' : ' + offer.rejection_comments;
      historicalRows.push({
        datetime: offer.rejected_at,
        label: T(rejectStatus),
      });
    }

    if (offer.rescinded_or_archived_at) {
      const rescinded_or_archived_status =
        offer.status === 'archived' ? 'Archived' : 'Rescinded';
      historicalRows.push({
        datetime: offer.rescinded_or_archived_at,
        label: T(
          rescinded_or_archived_status +
            ': ' +
            offer.rescinded_or_archived_comments,
        ),
      });
    }

    if (historicalRows.length > 0) {
      historicalTable = (
        <Table>
          {collection.map(historicalRows, function(row) {
            return (
              <Table.Row>
                <Table.Cell>
                  {formatTime(row.datetime)} - {row.label}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table>
      );
    } else {
      historicalTable = (
        <Blankslate>{T('project_crew.offer_historical.empty')}</Blankslate>
      );
    }

    return (
      <ETableRow>
        <ETableCell colSpan={11} expanded={true}>
          <Header>
            <Header.Title secondary={true}>
              {T('project_crew.offer_historical.title')}
            </Header.Title>
          </Header>
          {historicalTable}
        </ETableCell>
      </ETableRow>
    );
  }
}
