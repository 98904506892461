import config from 'common/oldJavascripts/config.js';
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'common/oldJavascripts/components/Base/DatePickerField';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Header from 'common/oldJavascripts/components/Base/Header';
import Table from 'common/oldJavascripts/components/Base/Table';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Pagination from 'common/oldJavascripts/components/Base/Pagination';
import Link from 'common/oldJavascripts/components/Base/Link';
import Select from 'common/oldJavascripts/components/Base/Select';
import Field from 'common/oldJavascripts/components/Base/Field';
import Button from 'common/oldJavascripts/components/Base/Button';
import FormField from 'common/oldJavascripts/components/Base/FormField';
import Loader from 'common/components/Loader';
import T from 'common/oldJavascripts/utils/i18n';
import format from 'common/oldJavascripts/utils/format.js';
import ajax from 'common/utilities/ajax';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as Sentry from '@sentry/browser';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import QueryRefresher from 'common/components/QueryRefresher';

// Hoc
import { connect } from 'react-redux';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import { withStyles } from '@material-ui/core/styles';
import withPermissionProtection from 'common/hoc/withPermissionProtection';
// Mui Components
import { Link as ReactLink } from 'react-router-dom';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

const Row = Table.Row;
const Cell = Table.Cell;

const offerRowStyles = {
  actionLink: {
    color: '#979ca5',
    transition: 'all 0.3s ease-in-out 0s',
    '&:hover': {
      color: '#646464',
    },
    '& + a': {
      paddingLeft: 12,
    },
  },
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

const OfferRow = compose(
  withApi,
  withStyles(offerRowStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(
  class OfferRow extends Component {
    downloadOfferFiles = () => {
      const {
        routerParams = {},
        params = {},
        pushNotification,
        popNotification,
      } = this.props;
      const { projectId } = routerParams || {};
      const { offerId } = params || {};
      // An email is sent to the user when the document is ready for download
      pushNotification({
        message: 'Hold on a moment while we prepare the document for download.',
        variant: SnackbarVariants.INFO,
      });
      ajax
        .post(
          `/projects/${projectId}/offers-documents/${offerId}/files/download`,
        )
        .then(() => {
          popNotification();
          pushNotification({
            message:
              "Your file is being created. You'll receive an email when it's done.",
            variant: SnackbarVariants.SUCCESS,
          });
        })
        .catch(err => {
          popNotification();
          pushNotification({
            message:
              'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
            variant: SnackbarVariants.ERROR,
          });
          Sentry.captureException(err);
        });
    };

    render() {
      const { offer, routerParams, classes } = this.props;
      const { projectId } = routerParams;
      const { id: offerId } = offer;

      return (
        <Row>
          <Cell doubleBorderBottom rowHeader={true}>
            {format.fullName(
              offer.first_name,
              offer.middle_name,
              offer.last_name,
            )}
          </Cell>
          <Cell doubleBorderBottom>{offer.department_name}</Cell>
          <Cell doubleBorderBottom>{offer.account_code}</Cell>
          <Cell doubleBorderBottom>{offer.occupation_description}</Cell>
          <Cell doubleBorderBottom>
            {moment(offer.start_date, 'YYYY-MM-DD').format('MM-DD-YYYY')}
          </Cell>
          <Cell doubleBorderBottom>
            {moment(offer.approved_at, 'YYYY-MM-DD HH:mm:ss').format(
              'MM-DD-YYYY',
            )}
          </Cell>
          <Cell doubleBorderBottom>
            <ReactLink
              className={classes.actionLink}
              to={`/projects/${projectId}/offer-documents/${offerId}/details`}
            >
              {T('View')}
            </ReactLink>
            <ReactLink
              className={classes.actionLink}
              onClick={this.downloadOfferFiles}
            >
              {T('Download all')}
            </ReactLink>
          </Cell>
        </Row>
      );
    }
  },
);

const offersDocumentsStyles = {
  navBar: {
    display: 'grid',
    gridTemplateColumns: '13% 13% repeat(3, 24%)',
    gridTemplateRows: '100%',
    gridColumnGap: '5px',
    gap: '5px',
    clear: 'both',
    alignItems: 'flex-start',
  },
  navBarActions: {
    display: 'grid',
    gridTemplateAreas: `
      '................... clearFilters'
      'downloadAsSinglePdf downloadAllButton'
    `,
    gridColumnGap: '5px',
    columnGap: '5px',
    gridRowGap: '10px',
    rowGap: '10px',
    justifyContent: 'flex-end',
  },
  downloadAsSinglePdf: {
    gridArea: 'downloadAsSinglePdf',
  },
  downloadAllButton: {
    gridArea: 'downloadAllButton',
  },
  clearFilters: {
    gridArea: 'clearFilters',
    justifySelf: 'end',
  },
  datePicker: {
    marginTop: '27px',
  },
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
  fullWidth: {
    width: '100%',
  },
};

class OffersDocuments extends Component {
  static mutators = {
    offerDocumentsDownload: {
      info: function(params, related) {
        var routerParams = related['/router/params'];

        return {
          id: '/projects/' + routerParams.projectId + '/offers-documents/files',
          create: true,

          initial: {
            from_date: null,
            to_date: null,
            employee_name: null,
            document_type: null,
            department: null,
          },
        };
      },
    },
  };
  static queries = {
    offers_documents: {
      info: function(_, related) {
        var params = related['/router/params'];
        var query = related['/router/query'];

        return {
          id: '/projects/' + params.projectId + '/offers-documents',

          cursor: {
            page: query.page,
            per_page: query.per_page || config.DEFAULT_PER_PAGE,
            sort: query.sort,
            status: query.status,
            direction: query.direction,
            from_date: query.from_date,
            to_date: query.to_date,
            search_text: query.employee_name,
            document_type: query.document_type,
            department: query.department,
          },
        };
      },
    },

    tags: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v3/projects/' + params.projectId + '/workflow-categories',
        };
      },
    },

    employees: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: '/projects/' + params.projectId + '/offers-documents/employees',
        };
      },
    },

    departments: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: '/v2/projects/' + params.projectId + '/departments',
        };
      },
    },
  };
  state = {
    fromDate: null,
    toDate: null,
    isDownloadCheckboxVisible: false,
  };

  render() {
    const {
      classes = {},
      offers_documents: offersDocumentsQuery = {},
      departments: departmentsQuery = {},
      employees: employeesQuery = {},
      tags: tagsQuery = {},
      offerDocumentsDownload = {},
    } = this.props;
    const { data: offersDocuments = {}, reload: reloadQuery } =
      offersDocumentsQuery || {};
    const { items: offersDocumentsItems = [] } = offersDocuments || {};
    const { data: { items: departmentItems = [] } = {} } =
      departmentsQuery || {};
    const { data: { items: employeeItems = [] } = {} } = employeesQuery || {};
    const { data: filters = {} } = offerDocumentsDownload || {};
    const { data: { items: tags = [] } = {} } = tagsQuery || {};
    var employeesOptions = [];
    var filterDepartments = null;

    const formattedTags = tags.map(tag => ({
      value: tag.id,
      label: tag.name,
    }));
    const departmentsLoading = departmentsQuery.status === 'loading';
    const loading =
      offersDocumentsQuery.status === 'loading' || departmentsLoading;

    filterDepartments = _.map(departmentItems, function(department) {
      return { value: '' + department.id, label: department.name };
    });

    if (employeesQuery.status !== 'loading') {
      employeesOptions = _.chain(employeeItems)
        .map(function(employee) {
          return { value: employee.name, label: employee.name };
        })
        .uniqBy('value')
        .value();
    }

    const renderContent = () => {
      if (loading) return <Loader className={classes.loader} />;
      if (offersDocumentsItems.length === 0)
        return <Blankslate>{T('offers_documents.not_found')}</Blankslate>;
      return (
        <Table>
          <Table.Header>
            <Cell header={true} sort="name">
              {T('offers_documents.columns.name')}
            </Cell>
            <Cell header={true} sort="department">
              {T('offers_documents.columns.department')}
            </Cell>
            <Cell header={true} sort="account_code">
              {T('offers_documents.columns.account_code')}
            </Cell>
            <Cell header sort="occupation">
              {T('offers_documents.columns.occupation')}
            </Cell>
            <Cell header={true} sort="start_at">
              {T('offers_documents.columns.start_date')}
            </Cell>
            <Cell header={true} sort="approved_at">
              {T('offers_documents.columns.approved_on')}
            </Cell>
            <Cell header={true}>{T('offers_documents.columns.actions')}</Cell>
          </Table.Header>
          {_.map(offersDocumentsItems, function(offer) {
            return (
              <OfferRow
                key={offer.id}
                params={{ offerId: offer.id }}
                offer={offer}
              />
            );
          })}
        </Table>
      );
    };

    const renderPagination = () => {
      if (loading) return null;
      if (offersDocumentsItems.length === 0) return null;
      return (
        <Pagination
          totalPages={offersDocuments.total_pages}
          page={offersDocuments.page}
        />
      );
    };

    const fromDate =
      (filters.from_date &&
        moment(filters.from_date, 'YYYY-MM-DD HH:mm:ssZ').format(
          'MM-DD-YYYY',
        )) ||
      filters.from_date;

    const toDate =
      (filters.to_date &&
        moment(filters.to_date, 'YYYY-MM-DD HH:mm:ssZ').format('MM-DD-YYYY')) ||
      filters.to_date;

    const { isDownloadCheckboxVisible } = this.state;
    return (
      <LayoutContent>
        <Box>
          <BoxItem>
            <Header>
              <Header.Title>{T('offers_documents.title')}</Header.Title>
              <QueryRefresher reloadQuery={reloadQuery} />
            </Header>
            <div className={classes.navBar}>
              <FormField
                label={T('From')}
                classes={{
                  label: classes.fullWidth,
                }}
              >
                <DatePicker
                  id="filter_from_date"
                  name="from_date"
                  value={fromDate}
                  selected={this.state.fromDate}
                  onChange={this._onChangeFromDate}
                  className={classes.fullWidth}
                />
              </FormField>
              <FormField
                label={T('To')}
                classes={{
                  label: classes.fullWidth,
                }}
              >
                <DatePicker
                  id="filter_to_date"
                  name="to_date"
                  value={toDate}
                  selected={this.state.toDate}
                  minDate={this.state.fromDate}
                  onChange={this._onChangeToDate}
                  className={classes.fullWidth}
                />
              </FormField>
              <div data-test-id="OffersDocuments-documentTag">
                <FormField label="Document Tag">
                  <Select
                    options={formattedTags}
                    multiple={true}
                    placeholder={T('Select...')}
                    value={filters.document_type || []}
                    onChange={this._onChangeFilter('document_type')}
                    searchable={true}
                  />
                </FormField>
              </div>
              <div>
                <FormField label={T('Department')}>
                  <Select
                    isLoading={departmentsLoading}
                    options={filterDepartments}
                    multiple={true}
                    placeholder={T('Select...')}
                    value={filters.department}
                    onChange={this._onChangeFilter('department')}
                    searchable={true}
                  />
                </FormField>
              </div>

              <div>
                <FormField label={T('Employee Name')}>
                  <Select
                    options={employeesOptions}
                    label={''}
                    onChange={this._onChangeFilter('employee_name')}
                    value={filters.employee_name}
                    clearable={true}
                    searchable={true}
                  />
                </FormField>
              </div>
            </div>
            <div className={classes.navBarActions}>
              <div
                className={classes.downloadAsSinglePdf}
                data-test-id="OffersDocuments-downloadSinglePDF"
              >
                {isDownloadCheckboxVisible && (
                  <Field
                    type="checkbox"
                    name="create_merged"
                    value={filters.create_merged}
                    onChange={this._onChangeFilter('create_merged')}
                    label={T('Download as single PDF')}
                  />
                )}
              </div>
              <div className={classes.downloadAllButton}>
                <Button onClick={this._downloadFilteredFiles} secondary>
                  {T('Download All Documents')}
                </Button>
              </div>
              <div className={classes.clearFilters}>
                <Link onClick={this._clearFilters}>Clear filters</Link>
              </div>
            </div>
          </BoxItem>
          {renderContent()}
        </Box>
        {renderPagination()}
      </LayoutContent>
    );
  }

  _setFilter = (query, name, value) => {
    if (value) {
      query[name] = value;
    } else {
      delete query[name];
    }
  };

  /// TODO: call this function to filter the list
  _applyFilters = () => {
    var router = this.context.router;
    var filters = this.props.offerDocumentsDownload.data;
    var query = _.clone(router.getCurrentQuery());

    this._setFilter(query, 'from_date', filters.from_date);
    this._setFilter(query, 'to_date', filters.to_date);
    this._setFilter(query, 'search_text', filters.employee_name);
    this._setFilter(query, 'department', filters.department);
    this._setFilter(query, 'document_type', filters.document_type);
    query.page = 1;

    router.transitionTo(
      router.getCurrentPathname(),
      router.getCurrentParams(),
      query,
    );
  };

  _clearFilters = () => {
    this.setState({
      fromDate: null,
      toDate: null,
    });

    this.props.offerDocumentsDownload.update('from_date')(null);
    this.props.offerDocumentsDownload.update('to_date')(null);
    this.props.offerDocumentsDownload.update('document_type')(null);
    this.props.offerDocumentsDownload.update('department')(null);
    this.props.offerDocumentsDownload.update('employee_name')(null);
  };

  _downloadFilteredFiles = () => {
    const {
      pushNotification,
      popNotification,
      offerDocumentsDownload,
    } = this.props;
    var self = this;
    pushNotification({
      message: 'Hold on a moment while we prepare the document for download.',
      variant: SnackbarVariants.INFO,
    });
    offerDocumentsDownload.save(
      'download',
      {},
      () => {
        self._resetDatepickers();
        popNotification();
        pushNotification({
          message:
            "Your file is being created. You'll receive an email when it's done.",
          variant: SnackbarVariants.SUCCESS,
        });
      },
      () => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
      },
    );
  };

  _onChangeFilter = key => value => {
    const { offerDocumentsDownload = {} } = this.props;

    // check "Document Tag", enable/disable "Download as single PDF"
    if (key === 'document_type' && Array.isArray(value)) {
      const isDownloadCheckboxVisible = value.length !== 0;
      this.setState({ isDownloadCheckboxVisible });

      const update = {
        [key]: value,
      };
      if (!isDownloadCheckboxVisible) {
        update.create_merged = false;
      }
      offerDocumentsDownload.bulkUpdate(update);
      return;
    }

    offerDocumentsDownload.update(key)(value);
  };

  _onChangeFromDate = date => {
    var toDate = this.state.toDate;
    var formatedDate = null;
    if (date) {
      formatedDate = date.format(config.DATE_FORMAT.DB);
    }

    if (date && !date.isAfter(toDate)) {
      this.setState({ fromDate: date });
    } else {
      this.setState({
        fromDate: date,
        toDate: null,
      });
      this.props.offerDocumentsDownload.update('to_date')(null);
    }

    this.props.offerDocumentsDownload.update('from_date')(formatedDate);
  };

  _onChangeToDate = date => {
    var formatedDate = null;
    if (date) {
      formatedDate = date.format(config.DATE_FORMAT.DB);
    }

    this.setState({ toDate: date });

    this.props.offerDocumentsDownload.update('to_date')(formatedDate);
  };

  _resetDatepickers = () => {
    this.setState({
      fromDate: null,
      toDate: null,
    });
  };
}

export default compose(
  withPermissionProtection('can_view_documents'),
  withApi,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(offersDocumentsStyles),
)(OffersDocuments);
