import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
const styles = {
  label: {
    gridArea: 'label',
    marginTop: 18,
    fontWeight: 700,
  },
  inputs: {
    gridArea: 'inputs',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '48% 48%',
    gridColumnGap: '4%',
    gap: '16px',
    gridGap: '16px',
  },
};

const ConditionalRuleConfiguration = props => {
  const {
    classes = {},
    fieldGroups = [],
    onChange,
    onFocus,
    errors = {},
  } = props;
  // Render the text fields for the field groups
  const fieldGroupTitles = fieldGroups.map(fieldGroup => (
    <TextField
      key={fieldGroup.id}
      label="Field Group Name"
      value={fieldGroup.name}
      onChange={e => onChange(fieldGroup.id, e)}
      onFocus={() => onFocus(fieldGroup.id, fieldGroup.name)}
      error={errors[fieldGroup.id]}
      helperText={
        errors[fieldGroup.id] &&
        'Duplicate or blank field group names not allowed.'
      }
      inputProps={{
        'data-test-id': `RuleConfigurationDialog-fieldGroupName-${fieldGroup.id}`,
      }}
    />
  ));
  return (
    <React.Fragment>
      <Typography className={classes.label}>Field Groups:</Typography>
      <div className={classes.inputs}>{fieldGroupTitles}</div>
    </React.Fragment>
  );
};

export default withStyles(styles)(ConditionalRuleConfiguration);
