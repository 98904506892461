import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
// Component
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

const styles = theme => ({
  root: {},
  error: {
    color: `2px solid ${theme.palette.error.main}`,
  },
});

const TextInput = props => {
  const { fieldId, fieldName, error, onChange, classes, value } = props || {};
  return (
    <FormControl fullWidth classes={{ root: classes.root }} error={error}>
      <InputLabel htmlFor={fieldId}>{fieldName}</InputLabel>
      <Input
        name={fieldName}
        id={fieldId}
        onChange={({ target: { value } = {} } = {}) => onChange(value)}
        data-test-id={`TextInput-${fieldName}`}
        inputProps={{
          'data-test-id': `TextInput-input-${fieldName}`,
        }}
        value={value}
      />
      {error && (
        <FormHelperText className={classes.error}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(TextInput);
