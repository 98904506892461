import React from 'react';
import ProjectTemplateAutoAssignmentRuleSelect from './ProjectTemplateAutoAssignmentRuleSelect';
import useCities from 'common/hooks/useCities';

const ProjectTemplateAutoAssignmentRuleCitySelect = props => {
  const {
    cityId,
    className,
    'data-test-id': dataTestId,
    label,
    onChange,
    stateId,
  } = props;
  const { data: cities, loading } = useCities({ stateId });
  const cityOptions = cities?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  return (
    <ProjectTemplateAutoAssignmentRuleSelect
      isLoading={loading}
      label={label}
      options={cityOptions || []}
      value={cityId}
      onChange={cityId => onChange(cities.find(({ id }) => id === cityId))}
      data-test-id={dataTestId}
      className={className}
    />
  );
};

export default ProjectTemplateAutoAssignmentRuleCitySelect;
