import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid } from '@material-ui/core';
import { Check as CheckIcon, Search as SearchIcon } from '@material-ui/icons';

import useProjectCloneSettings from './hooks/useProjectCloneSettings';

import Loader from 'common/components/Loader';
import CloneDialog from './Dialog';

const styles = {
  gridCell: {
    lineHeight: '38px',
  },
  titleH5: {
    display: 'inline',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
  btnPrimary: {
    background: '#45a7dc',
    color: 'white',
    '&:hover': {
      background: '#3297ce',
    },
    '&:disabled': {
      background: '#a8d4ee',
      color: 'white',
    },
  },
  iconCheck: {
    color: '#2E9A9C',
    marginTop: '4px',
  },
};

const ProjectClone = props => {
  const { classes, project } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    loading,
    data: { cloned, environments: cloneEnvironments = [] } = {},
  } = useProjectCloneSettings(project && project.id);

  if (cloned === undefined || !project || loading) return <Loader />;

  const colWidth = cloned ? 1 : 3;
  const validEnvironments = cloneEnvironments.length > 0;

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={6} className={classes.gridCell}>
          {validEnvironments && (
            <Typography
              className={classes.titleH5}
              variant="h5"
              gutterBottom
              data-test-id="ProjectClone-clonedTitle"
            >
              Project clone:
            </Typography>
          )}
        </Grid>
        <Grid item xs={colWidth} className={classes.gridCell} align="center">
          {!cloned && validEnvironments && (
            <Button
              data-test-id="ProjectClone-goClone"
              className={classes.btnPrimary}
              variant="contained"
              onClick={() => setIsDialogOpen(true)}
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          )}
          {cloned && (
            <CheckIcon
              data-test-id="ProjectClone-alreadyCloned"
              fontSize="large"
              className={classes.iconCheck}
            />
          )}
        </Grid>
      </Grid>
      <CloneDialog
        project={project}
        cloned={cloned}
        cloneEnvironments={cloneEnvironments}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(ProjectClone);
