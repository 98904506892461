import React from 'react';
import { Route } from 'react-router-dom';

// Components

import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';
import GlobalPermissionsIndex from 'admin/oldJavascripts/components/Pages/GlobalPermissions/index.js';

const GlobalPermissions = props => (
  <BaseLayout data-test-id="GlobalPermissions-GlobalPermissionsLayout">
    <Route
      exact
      path="/global-permissions"
      component={GlobalPermissionsIndex}
    />
  </BaseLayout>
);

export default GlobalPermissions;
