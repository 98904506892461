import React from 'react';
import { withStyles, Typography, Button } from '@material-ui/core';
import classNames from 'classnames';
import { KeyboardArrowLeft } from '@material-ui/icons';

const styles = theme => ({
  root: {
    background: '#dadada',
    display: 'grid',
    gridTemplateColumns: '25% 50% 10% 15%',
    gridTemplateRows: '100%',
    gridTemplateAreas: `"startDate recipientName .  goBack"`,
  },
  goBack: {
    gridArea: 'goBack',
  },
  recipientName: {
    fontSize: '1.1rem',
    placeSelf: 'center',
    gridArea: 'recipientName',
  },
  startDate: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    placeSelf: 'center',
    gridArea: 'startDate',
  },
});

const OfferInfoBar = props => {
  const { offer = {}, onGoBackClick, classes, className } = props;
  const { first_name, last_name, start_date } = offer;
  return (
    <div
      className={classNames(classes.root, className)}
      data-test-id="OfferInfoBar-root"
    >
      <Button
        className={classes.goBack}
        onClick={onGoBackClick}
        data-test-id="OfferInfoBar-goBack"
      >
        <KeyboardArrowLeft />
        Back to Approvals
      </Button>
      <Typography
        variant="h6"
        className={classes.recipientName}
        data-test-id="OfferInfoBar-recipientName"
      >{`Reviewing Offer for: ${first_name} ${last_name}`}</Typography>
      <Typography
        variant="h6"
        className={classes.startDate}
        data-test-id="OfferInfoBar-startDate"
      >{`Start Date: ${start_date}`}</Typography>
    </div>
  );
};

export default withStyles(styles)(OfferInfoBar);
