// Adapted from https://github.com/TeamWertarbyte/material-ui-chip-input/blob/v1.0.0-beta.15/stories/examples/react-autosuggest.js

import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ChipInput from 'material-ui-chip-input';

const MAX_SUGGESTIONS = 5;

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
    zIndex: 10000,
  },
  suggestion: {
    display: 'block',
    '& strong': {
      fontWeight: 600,
    },
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
});

const isNameMatch = (a, b) => {
  a = a.name || a || '';
  b = b.name || b || '';
  return a.trim().toLowerCase() === b.trim().toLowerCase();
};

const isAlreadySelected = (selectedItems, item) =>
  selectedItems.some(selection => isNameMatch(item, selection));

const getSuggestions = (
  suggestionMatcher,
  suggestionSource,
  selectedItems,
  text,
) => {
  text = text.trim().toLowerCase();
  // Filter out already selected suggestions
  const availableSuggestions = suggestionSource.filter(
    suggestion => !isAlreadySelected(selectedItems, suggestion),
  );

  // If no search, just show first n results
  if (!text) return availableSuggestions.slice(0, MAX_SUGGESTIONS);

  // If search, show matching n results
  let count = 0;
  return availableSuggestions.filter(suggestion => {
    const keep = count < MAX_SUGGESTIONS && suggestionMatcher(text, suggestion);
    if (keep) count += 1;
    return keep;
  });
};

const doesTextMatchSuggestion = (text, suggestion) =>
  suggestion.name.toLowerCase().slice(0, text.length) === text.toLowerCase();

// This control assumes all items are of the form { id, name }. Currently it
// only supports adding items listed in the suggestionSource.
const ChipInputWithAutosuggest = props => {
  const {
    classes,
    className,
    canCreateNew,
    suggestionSource,
    value = [],
    onChange,
    suggestionMatcher = doesTextMatchSuggestion,
  } = props;

  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionListOpen, setIsSuggestionListOpen] = useState(false);
  const [textFieldInput, setTextFieldInput] = useState('');

  const renderChipInput = ({
    chips,
    onAdd,
    onChange,
    onDelete,
    ref,
    ...rest
  }) => (
    <ChipInput
      {...rest}
      clearInputValueOnChange
      dataSourceConfig={{ text: 'name', value: 'id' }}
      onUpdateInput={onChange}
      onAdd={onAdd}
      onDelete={onDelete}
      value={chips}
      inputRef={ref}
    />
  );

  const renderSuggestionsContainer = ({ children, containerProps }) => (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);
    return (
      <MenuItem
        selected={isHighlighted}
        component="div"
        onMouseDown={e => e.preventDefault()} // prevent the click causing the input to be blurred
      >
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <strong key={String(index)}>{part.text}</strong>
            ) : (
              <span key={String(index)}>{part.text}</span>
            );
          })}
        </div>
      </MenuItem>
    );
  };

  const addChip = chip => {
    if (isAlreadySelected(value, chip)) return; // TODO snackbar error
    if (!canCreateNew) {
      const isExistingSuggestion = suggestionSource.some(suggestion =>
        isNameMatch(suggestion, chip),
      );
      if (!isExistingSuggestion) return; // TODO snackbar error
      chip = suggestionSource.find(s => isNameMatch(s, chip));
    }
    onChange(value.concat(chip));
    setTextFieldInput('');
  };

  const removeChip = (chip, index) => {
    onChange(value.filter((__, i) => i !== index));
    setIsSuggestionListOpen(false);
  };

  useEffect(() => {
    // If suggestion list is closed, empty list contents
    if (!isSuggestionListOpen) {
      setSuggestions([]);
      return;
    }

    // Otherwise, populate the list from the current text input
    setSuggestions(
      getSuggestions(
        suggestionMatcher,
        suggestionSource,
        value,
        textFieldInput,
      ),
    );
  }, [
    textFieldInput,
    isSuggestionListOpen,
    suggestionSource,
    suggestionMatcher,
    value,
  ]);

  return (
    <Autosuggest
      className={className}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderInputComponent={renderChipInput}
      suggestions={suggestions}
      onSuggestionsFetchRequested={() => setIsSuggestionListOpen(true)}
      onSuggestionsClearRequested={() => setIsSuggestionListOpen(false)}
      renderSuggestionsContainer={renderSuggestionsContainer}
      getSuggestionValue={suggestion => suggestion.name}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={(e, { suggestion }) => {
        addChip(suggestion);
        e.preventDefault();
      }}
      focusInputOnSuggestionClick={true}
      inputProps={{
        ...props,
        chips: value,
        onChange: (event, { newValue }) => setTextFieldInput(newValue),
        value: textFieldInput,
        onAdd: addChip,
        onDelete: removeChip,
        onClick: () => setIsSuggestionListOpen(true),
      }}
      alwaysRenderSuggestions={true}
    />
  );
};

export default withStyles(styles)(ChipInputWithAutosuggest);
