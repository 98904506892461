import React from 'react';

// Components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Custom Components
import AmountField from './AmountField';
import PerField from './PerField';
import CapField from './CapField';
import DurationField from './DurationField';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Utilities
import classNames from 'class-names';

const styles = theme => ({
  root: {
    display: 'grid',
    height: 'max-content',
    width: '100%',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'max-content 10px max-content',
  },
  header: {
    gridRow: 1,
    gridColumn: 1,
  },
  table: {
    gridRow: 3,
    gridColumn: 1,
    padding: 25,
    tableLayout: 'fixed',
  },
  icon: {
    height: '14px',
    width: 'auto',
  },

  disabledCell: {
    background: 'hsl(0,0%,95%)',
    color: 'rgb(153, 153, 153)',
  },
  headerCell: {
    fontSize: '1.1125rem',
    padding: '0px 24px',
  },
  labelCell: {
    fontSize: '1.0125rem',
  },
  errorLabelCell: {
    borderLeft: '2px solid #f44336 !important',
  },
  error: {
    fontSize: '0.9rem',
    placeSelf: 'center',
    display: 'block',
  },
  border: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  cellWithTextField: {
    padding: '2px 24px',
  },
  textField: {
    width: '100%',
  },
  textFieldInputContainer: {
    '&:before': {
      border: 'none',
    },
    '&:after': {
      borderBottomColor: '#27a9ae',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `2px solid #27a9ae !important`,
    },
  },
  textFieldInput: {
    textAlign: 'center',
  },
  selectContainer: {
    width: '100%',
    padding: '5px 15px',
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
  },
});

const Allowances = ({
  classes = {},
  onChange: upstreamOnChange,
  formData = {},
  formErrors = {},
} = {}) => {
  const fieldProps = (formKey, subKey) => {
    const { [formKey]: sectionData = {} } = formData || {};
    const { [subKey]: value } = sectionData || {};
    return {
      id: formKey,
      onChange: upstreamOnChange(formKey),
      value: value,
      updateKey: subKey,
      classes: {
        root: classNames(classes.border, classes.cellWithTextField),
        textField: classes.textField,
        textFieldInputContainer: classes.textFieldInputContainer,
        textFieldInput: classes.textFieldInput,
        selectContainer: classes.selectContainer,
      },
    };
  };

  const rowConfig = [
    { label: 'Box Rental', key: 'boxRentalAllowance' },
    { label: 'Computer Rental', key: 'computerRentalAllowance' },
    { label: 'Car Allowance', key: 'carAllowance' },
    { label: 'Mobile Phone Allowance', key: 'mobilePhoneAllowance' },
    { label: 'Per Diem', key: 'perDiemAllowance' },
    { label: 'Housing Allowance', key: 'housingAllowance' },
  ];

  const labelCellClass = key =>
    classNames(
      {
        [classes.errorLabelCell]: formErrors[key],
      },
      classes.labelCell,
      classes.border,
    );

  const tableBodyRows = rowConfig.map(({ label, key }) => (
    <TableRow key={`${label}-${key}`}>
      <TableCell className={labelCellClass(key)}>
        {label}
        {formErrors[key] && (
          <Typography
            data-test-id={`Allowances-errorMessage-${key}`}
            color="error"
            variant="caption"
            className={classes.error}
          >
            {formErrors[key]}
          </Typography>
        )}
      </TableCell>
      <AmountField {...fieldProps(key, 'amount')} />
      <PerField {...fieldProps(key, 'per')} />
      <CapField {...fieldProps(key, 'cap')} />
      <DurationField {...fieldProps(key, 'duration')} />
    </TableRow>
  ));

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.header}>
        Allowances
        <Typography variant="subtitle1" className={classes.description}>
          Reimbursable expenses allowed over and beyond a crew member's daily
          rate
        </Typography>
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center" className={classes.headerCell}>
              Amount
            </TableCell>
            <TableCell align="center" className={classes.headerCell}>
              Per
            </TableCell>
            <TableCell align="center" className={classes.headerCell}>
              Cap
            </TableCell>
            <TableCell align="center" className={classes.headerCell}>
              Duration
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableBodyRows}</TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(Allowances);
