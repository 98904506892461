import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import classNames from 'classnames';
import palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  container: {
    width: '100%',
    paddingTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  documentRow: {
    width: '100%',
    display: 'flex',
  },
  name: {
    flex: 1.5,
    marginRight: theme.spacing.unit * 0.5,
    marginLeft: theme.spacing.unit * 0.5,
    maxWidth: '37%',
  },
  checkbox: {
    flex: 0.5,
    marginRight: theme.spacing.unit * 0.5,
    marginLeft: theme.spacing.unit * 0.5,
    maxWidth: '12%',
  },
  checked: {
    color: `${palette.primary.main} !important`,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    marginRight: theme.spacing.unit * 0.5,
    marginTop: theme.spacing.unit * 0.5,
    textTransform: 'capitalize',
  },
  button: {
    color: 'white',
    textTransform: 'capitalize',
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
});

const SupportDocument = props => {
  const {
    addHandler,
    classes,
    deleteHandler,
    updateHandler,
    supportDocument,
  } = props;
  const { name, isRequired, isIncluded } = supportDocument || {};

  const addDocumentButton = () => (
    <div className={classes.buttonContainer}>
      <Button
        onClick={addHandler}
        className={classes.button}
        color="primary"
        variant="contained"
        data-test-id="SupportingDocumentSetup-add"
      >
        Add Supporting Document
      </Button>
    </div>
  );

  const documentDetails = () => (
    <div>
      <div className={classes.documentRow}>
        <TextField
          className={classes.name}
          value={name}
          onChange={e => updateHandler({ name: e.target.value })}
          label="Name"
          data-test-id="SupportingDocumentSetup-name"
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={isRequired}
              onChange={e => updateHandler({ isRequired: e.target.checked })}
              classes={{ checked: classes.checked }}
              data-test-id="SupportingDocumentSetup-required"
            />
          }
          label={<Typography variant="caption">Required</Typography>}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={isIncluded}
              onChange={e => updateHandler({ isIncluded: e.target.checked })}
              classes={{ checked: classes.checked }}
              data-test-id="SupportingDocumentSetup-included"
            />
          }
          label={<Typography variant="caption">Add to Final PDF</Typography>}
        />
      </div>
      <div className={classes.buttonRow}>
        <Button
          className={classNames(classes.button, classes.deleteButton)}
          color="secondary"
          variant="contained"
          size="small"
          onClick={deleteHandler}
          data-test-id="SupportingDocumentSetup-delete"
        >
          <DeleteIcon />
          Remove Support Document
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom>
        Supporting Documents
      </Typography>
      <Typography />
      {supportDocument && documentDetails()}
      {!supportDocument && addDocumentButton()}
    </div>
  );
};

export default withStyles(styles)(SupportDocument);
