import React from 'react';
import Typography from '@material-ui/core/Typography';
import UnionTable from './UnionTermsOfEmploymentTable';
import USUnionTable from './USTermsOfEmployment/RateTable';
import USUnionWeeklyTable from 'studio/components/OfferPage/TermsOfEmployment/USUnionWeeklyTermsOfEmployment/WeeklyRateTable';

import ExemptTable from './ExemptTermsOfEmploymentTable';
import DefaultTable from './DefaultTermsOfEmploymentTable';
import EmptyMessage from '../EmptyMessage';
import Loader from 'common/components/Loader';
import { withStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import TableChartIcon from '@material-ui/icons/TableChartOutlined';
import classNames from 'class-names';
import NonUnionRateTable from './NonUnionRateTable';
import CanadaRateTable from './CanadaTermsOfEmployment/RateTable';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import NegotiatedContractTerms from './NegotiatedContractTerms';
import isNonUnionWorkScheduleA from 'common/utilities/isNonUnionWorkScheduleA';
import Feature from 'common/components/Feature';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';
import isUnionWeeklyScheduleCode from 'common/utilities/isUnionWeeklySchedule';

const styles = theme => ({
  root: {
    gridTemplateColumns: '100%',
    gridTemplateRows: 'max-content 30px 1fr',
  },
  rootWithMessage: {
    minHeight: '300px',
  },
  header: {
    gridColumn: 1,
    gridRow: 1,
  },
  tableRoot: {
    gridColumn: 1,
    gridRow: 3,
  },
  loader: {
    gridColumn: 1,
    gridRow: 3,
  },
  disabledCell: {
    background: 'hsl(0,0%,95%)',
    color: 'rgb(153, 153, 153)',
  },
  headerCell: {
    fontSize: '1.1125rem',
    padding: '0px 24px',
  },
  labelCell: {
    fontSize: '1.0125rem',
  },
  border: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  errorCell: {
    borderLeft: `2px solid ${theme.palette.error.main}`,
  },
  errorLabel: {
    paddingLeft: 8,
    fontSize: '1rem',
  },
  cellWithTextField: {
    padding: '2px 24px',
  },
  textField: {
    width: '100%',
  },
  idlePayLabel: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr',
    gap: '10px',
    placeItems: 'center start',
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'normal',
  },
  warningIcon: {
    color: '#ff0000',
    marginRight: '5px',
  },
  description: {
    color: '#6D7278',
    fontSize: 12,
  },
});

// Scale rate warning messages
const NO_SCALE_RATE_MESSAGE =
  "There's no minimum scale rate for this job title.";
const MISSING_SCALE_RATE_MESSAGE =
  "Sorry, we're not finding the scale rate. Please check with your C&C Payroll Coordinator.";
const MISSING_SCALE_RATE_MESSAGE_WEEKLY =
  'No scale rate found for this occupation. Please enter the negotiated rate and hours to continue';

// TODO: consolidate this Functions to one for scale rate warnings
const getScaleRatesWarningUnion = (
  formData,
  scaleRates,
  isCanada,
  isUnionHourlyWeeklyV2,
) => {
  const hasScaleRates = scaleRates && Object.keys(scaleRates).length > 0;
  if (!hasScaleRates || !isUnionHourlyWeeklyV2) return;

  const isFormRatesMissing =
    formData?.rate === null || parseInt(formData.rate, 10) === 0;
  if (!isFormRatesMissing) return;

  // No scale rates (rates are zero)
  const isNoScaleRates = parseInt(scaleRates.rate, 10) === 0;
  if (isNoScaleRates) return NO_SCALE_RATE_MESSAGE;

  // Missing scale rates (rates are null)
  const isMissingScaleRates = scaleRates.rate == null && !isCanada;
  const { value: workScheduleCode = null } = formData?.workSchedule || {};
  const isWeeklySchedule = isUnionWeeklyScheduleCode(workScheduleCode);
  if (isMissingScaleRates)
    return isWeeklySchedule
      ? MISSING_SCALE_RATE_MESSAGE_WEEKLY
      : MISSING_SCALE_RATE_MESSAGE;
};

const getScaleRatesWarning = (
  formData,
  scaleRates,
  isCanada,
  isUnionHourlyV2,
) => {
  const hasScaleRates = scaleRates && Object.keys(scaleRates).length > 0;
  if (!hasScaleRates) return;

  const isFormRatesMissing =
    (formData.studioRatePerHour == null ||
      parseInt(formData.studioRatePerHour, 10) === 0) &&
    (formData.studioRatePerWeek == null ||
      parseInt(formData.studioRatePerWeek, 10) === 0);
  if (!isFormRatesMissing) return;

  // No scale rates (rates are zero)
  const isNoScaleRates =
    parseInt(scaleRates.studioRatePerHour, 10) === 0 &&
    parseInt(scaleRates.studioRatePerWeek, 10) === 0;
  if (isNoScaleRates) return NO_SCALE_RATE_MESSAGE;

  // Missing scale rates (rates are null)
  const isMissingScaleRates =
    scaleRates.studioRatePerHour == null &&
    scaleRates.studioRatePerWeek == null &&
    !isCanada & !isUnionHourlyV2;
  if (isMissingScaleRates) return MISSING_SCALE_RATE_MESSAGE;
};

const getScaleRatesWarningCanada = (
  formData,
  scaleRates,
  isCanada,
  isUnionHourlyV2,
) => {
  const hasScaleRates = scaleRates && Object.keys(scaleRates).length > 0;
  if (!hasScaleRates) return;

  const isFormRatesMissing =
    (formData.ratePerHour == null ||
      parseInt(formData.ratePerHour, 10) === 0) &&
    (formData.weeklyRate == null || parseInt(formData.weeklyRate, 10) === 0);
  if (!isFormRatesMissing) return;

  // No scale rates (rates are zero)
  const isNoScaleRates =
    parseInt(scaleRates.ratePerHour, 10) === 0 &&
    parseInt(scaleRates.weeklyRate, 10) === 0;
  if (isNoScaleRates) return NO_SCALE_RATE_MESSAGE;

  // Missing scale rates (rates are null)
  const isMissingScaleRates =
    scaleRates.ratePerHour === null &&
    scaleRates.weeklyRate === null &&
    isCanada &&
    !isUnionHourlyV2;
  if (isMissingScaleRates) return MISSING_SCALE_RATE_MESSAGE;
};

const TermsOfEmployment = props => {
  const {
    onChange: upstreamOnChange,
    isScaleRatesLoading,
    scaleRates,
    formData,
    formErrors,
    classes,
    isReviewOffer,
    isContractsLoading,
    contracts = [],
    isCanada,
  } = props;

  const {
    union,
    occupation,
    workSchedule,
    startDate,
    workState,
    hireState,
    negotiatedContractId,
  } = formData || {};

  const { value: unionCode = null, isNonUnion = false } = union || {};
  const { value: workScheduleCode = null } = workSchedule || {};
  const { value: occupationCode } = occupation || {};
  const isUnion = !isNonUnion;
  const isExempt = workScheduleCode === 'C' || workScheduleCode === 'D';
  const isTermsOfHireFilledIn = ![
    startDate,
    workState,
    hireState,
    unionCode,
    occupationCode,
    workScheduleCode,
  ].includes(null);
  const flags = useFeatureFlags();
  const isNonUnionScheduleFlagActiveA = flags.includes('nonUnionSchedules');
  const isNonUnionScheduleFlagActiveC = flags.includes('nonUnionScheduleC');
  const isNonUnionScheduleFlagActiveB = flags.includes('nonUnionScheduleB');
  const isUnionScheduleAFlagActive = flags.includes('UnionScheduleA');
  const isUnionWeeklyScheduleFlagActive = flags.includes('UnionWeeklySchedule');

  const showNonUnionTableA =
    isNonUnion &&
    isNonUnionWorkScheduleA(workScheduleCode) &&
    isNonUnionScheduleFlagActiveA;
  const showNonUnionTableC =
    isNonUnion &&
    ['C', 'E', 'N'].includes(workScheduleCode) &&
    isNonUnionScheduleFlagActiveC;
  const showNonUnionTableB =
    isNonUnion &&
    ['B'].includes(workScheduleCode) &&
    isNonUnionScheduleFlagActiveB;
  const showNonUnionTableD =
    isNonUnion &&
    ['D'].includes(workScheduleCode) &&
    isNonUnionScheduleFlagActiveB;

  const showUnionScheduleA =
    isUnion &&
    !isCanada &&
    workScheduleCode === 'A' &&
    isUnionScheduleAFlagActive;

  const showUnionWeeklySchedule =
    isUnion &&
    !isCanada &&
    isUnionWeeklyScheduleCode(workScheduleCode) &&
    isUnionWeeklyScheduleFlagActive;

  const isNonUnionActive =
    showNonUnionTableA ||
    showNonUnionTableC ||
    showNonUnionTableB ||
    showNonUnionTableD;

  const tableClasses = {
    root: classes.tableRoot,
    disabledCell: classes.disabledCell,
    headerCell: classes.headerCell,
    labelCell: classes.labelCell,
    errorCell: classes.errorCell,
    errorLabel: classes.errorLabel,
    border: classes.border,
    cellWithTextField: classes.cellWithTextField,
    textField: classes.textField,
    textFieldInput: classes.textFieldInput,
    idlePayLabel: classes.idlePayLabel,
    cellWithTextFieldClass: classNames(
      classes.border,
      classes.cellWithTextField,
    ),
    labelCellClass: classNames(classes.border, classes.labelCell),
  };

  const rootClass = classNames(classes.root, {
    [classes.rootWithMessage]: !isTermsOfHireFilledIn,
  });
  const isUnionHourlyWeeklyV2 =
    scaleRates?.__typename === 'TermsOfEmploymentUnionHourly';

  const isScaleRatesWarning =
    getScaleRatesWarning(
      formData,
      scaleRates,
      isCanada,
      isUnionHourlyWeeklyV2,
    ) ||
    getScaleRatesWarningUnion(
      formData,
      scaleRates,
      isCanada,
      isUnionHourlyWeeklyV2,
    ) ||
    getScaleRatesWarningCanada(
      formData,
      scaleRates,
      isCanada,
      isUnionHourlyWeeklyV2,
    );

  const showLoader = isScaleRatesLoading && isContractsLoading;
  const showEmptyMessage = !showLoader && !isTermsOfHireFilledIn;
  const scaleRatesWarning = !showEmptyMessage && isScaleRatesWarning;
  const showScaleRatesWarning = !!scaleRatesWarning;
  const showTable = !showEmptyMessage && !showLoader;

  const TableComponent =
    (showUnionScheduleA && USUnionTable) ||
    (showUnionWeeklySchedule && USUnionWeeklyTable) ||
    (isNonUnionActive && NonUnionRateTable) ||
    (!showUnionScheduleA &&
      !showUnionWeeklySchedule &&
      isUnion &&
      UnionTable) ||
    (isExempt && ExemptTable) ||
    DefaultTable;

  return (
    <div className={rootClass}>
      <Typography className={classes.header} variant="h5">
        Terms of Employment
        <Typography variant="subtitle1" className={classes.description}>
          {isReviewOffer
            ? 'Review the rates for your crew member'
            : 'Set the rates for your crew members'}
        </Typography>
      </Typography>
      {Array.isArray(contracts) && (
        <NegotiatedContractTerms
          onChange={upstreamOnChange}
          negotiatedContractId={negotiatedContractId}
          contracts={contracts}
          formErrors={formErrors}
        />
      )}
      {showLoader && <Loader className={classes.loader} />}
      {showEmptyMessage && (
        <EmptyMessage
          classes={tableClasses}
          icon={TableChartIcon}
          text="Note that this table populates after you complete the Terms of Hire"
        />
      )}
      {showScaleRatesWarning && (
        <Typography
          className={classes.errorMessage}
          variant="subtitle1"
          data-test-id="TermsOfEmployment-scaleRatesWarning"
        >
          <WarningIcon className={classes.warningIcon} />
          {scaleRatesWarning}
        </Typography>
      )}
      <UsaOnly>
        {showTable && (
          <TableComponent
            classes={tableClasses}
            formData={formData}
            formErrors={formErrors}
            onChange={upstreamOnChange}
            workScheduleCode={workScheduleCode}
            scaleRates={scaleRates}
            contracts={contracts}
            isContractsLoading={isContractsLoading}
          />
        )}
      </UsaOnly>
      {showTable && (
        <Feature name="prcanRateTable">
          <CanadaOnly>
            <CanadaRateTable
              classes={tableClasses}
              formData={formData}
              formErrors={formErrors}
              onChange={upstreamOnChange}
              workScheduleCode={workScheduleCode}
            />
          </CanadaOnly>
        </Feature>
      )}
    </div>
  );
};

export default withStyles(styles)(TermsOfEmployment);
