import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query projectTemplate($id: Int!) {
    projectTemplate(id: $id) {
      id
      autoAssignmentRules {
        id
        ... on DepartmentAutoAssignmentRule {
          departments {
            id
            name
          }
        }
        ... on EmploymentTypeAutoAssignmentRule {
          employmentType {
            id
            label
          }
        }
        ... on UnionAutoAssignmentRule {
          unions {
            code
            description
            isNonUnion
          }
        }
        ... on LocationAutoAssignmentRule {
          hireStates {
            id
            code
            name
          }
          workStates {
            id
            code
            name
          }
          workCity {
            id
            code
            name
          }
        }
        ... on ComboAutoAssignmentRule {
          employmentType {
            id
            label
          }
          departments {
            id
            name
          }
          unions {
            code
            description
            isNonUnion
          }
          hireStates {
            id
            code
            name
          }
          workStates {
            id
            code
            name
          }
          workCity {
            id
            code
            name
          }
        }
      }
    }
  }
`;

// Schema is currently in a state of flux, for now we just convert the data into
// the representation we expect.
const convertToInternalRepresentation = rules => {
  if (!rules) return rules;
  return rules.map(rule => {
    rule = { ...rule };
    if (rule.departments) {
      rule.department = rule.departments[0];
      delete rule.departments;
    }
    if (rule.unions) {
      rule.union = rule.unions[0];
      delete rule.unions;
    }
    if (rule.workStates) {
      rule.workState = rule.workStates[0];
      delete rule.workStates;
    }
    if (rule.hireStates) {
      rule.hireState = rule.hireStates[0];
      delete rule.hireStates;
    }
    return rule;
  });
};

const useAutoAssignmentRules = ({ projectTemplateId }, options = {}) => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: !projectTemplateId,
    // NOTE ProjectTemplate.id is listed in the schema as an ID! type, but the projectTemplate
    // query takes a projectId of type Int! Obviously, this is wrong.
    variables: { id: parseInt(projectTemplateId) },
    ...options,
  });
  return {
    error,
    loading,
    data: convertToInternalRepresentation(
      data?.projectTemplate.autoAssignmentRules,
    ),
  };
};

export default useAutoAssignmentRules;
