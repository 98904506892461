import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query projectTemplates(
    $projectId: Int!
    $workState: String
    $hireState: String
    $workCity: String
    $union: String
    $occupation: String
    $workSchedule: String
    $department: String
    $employmentClassification: String
  ) {
    project(id: $projectId) {
      id
      templates(
        workState: $workState
        hireState: $hireState
        workCity: $workCity
        union: $union
        occupation: $occupation
        workSchedule: $workSchedule
        department: $department
        employmentClassification: $employmentClassification
      ) {
        edges {
          node {
            id
            preSelected
            name
            images
            required
            inputFieldGroups {
              id
              name
              required
              fields {
                id
                name
                type
                options
              }
            }
          }
        }
      }
    }
  }
`;

const useProjectTemplates = (variables, options = {}) => {
  const {
    projectId,
    hireState,
    workState,
    union,
    occupation,
    workSchedule,
    department,
  } = variables;
  const skip =
    !projectId ||
    !hireState ||
    !workState ||
    !union ||
    !occupation ||
    !workSchedule ||
    !department;
  const { loading, data } = useQuery(QUERY, {
    skip,
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    ...options,
  });
  return {
    loading,
    data: !skip && data?.project?.templates?.edges?.map(({ node }) => node),
  };
};

export default useProjectTemplates;
