import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import HamburgerMenu from './HamburgerMenu';

const styles = {
  projectTitle: {
    textAlign: 'center',
    position: 'relative',
    top: 5,
  },
};

const ProjectHamburgerMenu = ({ classes, project: projectQuery }) => {
  const { name: projectName = '' } = projectQuery.data || {};
  return (
    <HamburgerMenu>
      <Typography
        data-test-id="HamburgerMenu-projectTitle"
        className={classes.projectTitle}
      >
        {projectName}
      </Typography>
    </HamburgerMenu>
  );
};

ProjectHamburgerMenu.queries = {
  project: {
    info: (_, related) => {
      const { projectId } = related['/router/params'];
      return {
        id: projectId ? '/projects/' + projectId : 'empty',
      };
    },
  },
};

export default compose(withApi, withStyles(styles))(ProjectHamburgerMenu);
