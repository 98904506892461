import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  ListItem,
  IconButton,
  Chip,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import DocumentUploadButton from './DocumentUploadButton';
import Button from 'common/oldJavascripts/components/Base/Button';
import SingleDocIcon from 'common/icons/SingleDocIcon';

const styles = theme => ({
  documentRow: {
    display: 'flex',
    flexDirection: 'row',
    '&:nth-child(even)': {
      background: '#F4F4F4',
    },
    '&:nth-child(odd)': {
      background: '#E5E5E5',
    },
    '&:first-child': {
      background: 'inherit',
    },
  },
  supportingDocumentName: {
    display: 'flex',
    flex: 2,
    textAlign: 'left',
  },
  documentName: {
    display: 'flex',
    flex: 2,
    textAlign: 'left',
  },
  noAttachment: {
    fontSize: '14px',
    marginRight: '30px',
    color: 'black',
    fontFamily:
      'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !important',
  },
  headers: {
    marginLeft: theme.spacing.unit / 2,
    fontWeight: 'bold',
  },
  uploadedSupportDocumentChipsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 5,
    textAlign: 'left',
  },
  supportDoc: {
    margin: theme.spacing.unit / 4,
    background: '#ecf7f6',
  },
  closeIcon: {
    color: '#333',
  },
  attachmentsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  isRequired: {
    color: '#f28020',
  },
  attachmentsSection: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  deleteAll: {
    background: 'transparent',
    color: '#2eaba3',
    textTransform: 'initial',
    border: '1px solid #2eaba3',
    margin: '0 10px',
    borderRadius: '3px',
    padding: '6px 14px',
    fontFamily:
      'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !important',
    fontWeight: 'bold',
    '&:hover': {
      background: 'transparent',
    },
  },
  btnSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  associatedDocument: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  viewIcon: {
    position: 'relative',
  },
});

const SupportDocumentRow = props => {
  const {
    doc,
    editHandler,
    uploadHandler,
    deleteHandler,
    showDocumentHandler,
    index,
    classes,
    isApproval,
  } = props;
  const {
    id: documentId,
    templateName: documentName,
    supportingDocumentTitles: supportingDocuments,
  } = doc;
  if (!supportingDocuments[index]) {
    console.warn(
      'Tried to show a Supporting Document Row with an out of bound index.',
    );
    return <div />;
  }
  const {
    id: supportingDocumentId,
    documentTitle: supportingDocumentName,
    supportingDocuments: uploadedSupportDocuments,
    required: isRequired,
  } = supportingDocuments[index];

  const emptyList = (
    <div className={classes.attachmentsSection}>
      <Typography variant="caption" className={classes.noAttachment}>
        No Attachment
      </Typography>
    </div>
  );

  const deleteAllDocs = () => {
    uploadedSupportDocuments.forEach(({ id, canDelete }) =>
      canDelete ? deleteHandler({ fileId: id }) : null,
    );
  };

  const uploadedSupportDocumentChips = () =>
    uploadedSupportDocuments.map(supDoc => {
      const { id, name, canDelete } = supDoc;
      const deleteDoc = canDelete ? () => deleteHandler({ fileId: id }) : null;
      const chip = (
        <div>
          <Chip
            key={`support-doc-${id}-for-${documentId}`}
            label={name}
            onDelete={deleteDoc}
            className={classes.supportDoc}
            icon={<SingleDocIcon height={20} width={20} />}
            deleteIcon={<CloseIcon classes={{ root: classes.closeIcon }} />}
          />
          <IconButton
            className={classes.viewIcon}
            onClick={() => showDocumentHandler(supDoc.id, true)}
          >
            <VisibilityIcon />
          </IconButton>
        </div>
      );
      if (!canDelete) {
        return (
          <Tooltip
            title="This was uploaded by another user. You are not allowed to delete this document."
            key={`support-doc-tooltip-${id}-for-${documentId}`}
          >
            {chip}
          </Tooltip>
        );
      }
      return chip;
    });

  return (
    <ListItem key={`${doc.id}-container`} className={classes.documentRow}>
      <Grid container>
        <Grid item xs={2}>
          <Typography
            data-test-id={`SupportDocumentRow-name-${supportingDocumentId}`}
          >
            {supportingDocumentName}
            {!isApproval && isRequired && (
              <span className={classes.isRequired}> &#42;</span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.associatedDocument}>
            <Typography
              data-test-id={`SupportDocumentRow-associated-${supportingDocumentId}`}
            >
              {documentName}
            </Typography>
            <IconButton
              className={classes.viewIcon}
              onClick={() => showDocumentHandler(doc.id, false)}
            >
              <VisibilityIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={7}>
          <div className={classes.attachmentsContainer}>
            <div>
              {uploadedSupportDocuments.length === 0
                ? emptyList
                : uploadedSupportDocumentChips()}
            </div>
            <div className={classes.btnSection}>
              {uploadedSupportDocuments.length > 1 && (
                <Button
                  className={classes.deleteAll}
                  onClick={() => deleteAllDocs()}
                >
                  Delete All{' '}
                </Button>
              )}
              <DocumentUploadButton
                showButton={false}
                editHandler={editHandler}
                uploadHandler={uploadHandler}
                deleteHandler={deleteHandler}
                viewHandler={showDocumentHandler}
                uploadedSupportDocuments={uploadedSupportDocuments}
                isMyFiles={!isApproval}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default withStyles(styles)(SupportDocumentRow);
