import get from 'lodash.get';
import React from 'react';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Box from 'common/oldJavascripts/components/Base/Box';
import Table from 'common/oldJavascripts/components/Base/Table';
import Header from 'common/oldJavascripts/components/Base/Header';
import T from 'common/oldJavascripts/utils/i18n';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Badges from 'common/components/Badges';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

const COLUMN_TITLES = {
  approvedAt: 'Approved Date',
  'project.name': 'Project Name',
  'termsOfHire.occupation.description': 'Position',
  sendDate: 'Sent Date',
  rejectedAt: 'Rejected Date',
  rejectionComments: 'Reason For Rejection',
};

const styles = () => ({
  badgesContainer: {
    marginLeft: 6,
  },
  projectName: {
    overflow: 'visible',
    wordWrap: 'break-word',
  },
});

const { Cell, Row } = Table;

const OffersListTable = ({ classes, columns, offers, type }) => {
  const customCellDisplays = {
    'project.name': ({ project: { name }, id, badges = [] }) => (
      <Cell
        doubleBorderBottom
        rowHeader={true}
        data-test-id={`OfferTable-cell-${id}/project.name`}
        className={classes.projectName}
      >
        {name}
        <span className={classes.badgesContainer}>
          <Badges badges={badges} id={id} />
        </span>
      </Cell>
    ),
    approvedAt: ({ approvedAt, id }) => (
      <Cell
        doubleBorderBottom
        data-test-id={`OfferTable-cell-${id}/approvedAt`}
      >
        {moment
          .utc(approvedAt, 'YYYY-MM-DD HH:mm:ss Z')
          .local()
          .format('MM-DD-YYYY h:mmA')}
      </Cell>
    ),
    rejectedAt: ({ rejectedAt, id }) => (
      <Cell
        doubleBorderBottom
        data-test-id={`OfferTable-cell-${id}/rejectedAt`}
      >
        {moment
          .utc(rejectedAt, 'YYYY-MM-DD HH:mm:ss Z')
          .local()
          .format('MM-DD-YYYY h:mmA')}
      </Cell>
    ),
    sendDate: ({ sendDate, id }) => (
      <Cell doubleBorderBottom data-test-id={`OfferTable-cell-${id}/sendDate`}>
        {moment
          .utc(sendDate, 'YYYY-MM-DD HH:mm:ss Z')
          .local()
          .format('MM-DD-YYYY h:mmA')}
      </Cell>
    ),
  };

  const to = offer => {
    if (type === 'employeeRejected') return false;
    if (offer.status === 'sent' || offer.status === 'onboarding')
      return `/onboarding/offers/${offer.id}/details`;
    if (/^onboarding_/.test(offer.status)) {
      const onboardingStep = offer.status
        .split('_')
        .slice(1)
        .join('-');
      return `/onboarding/offers/${offer.id}/${onboardingStep}`;
    }
    if (type === 'optedOut') return `/onboarding/offers/${offer.id}/opted-out`;
    return `/onboarding/offers/${offer.id}/submitted`;
  };

  const tableBody = () =>
    offers.map(offer => {
      const cells = columns.map(column => {
        if (customCellDisplays[column])
          return customCellDisplays[column](offer);
        return (
          <Cell
            doubleBorderBottom
            data-test-id={`OfferTable-cell-${offer.id}/${column}`}
            className={classes.projectName}
          >
            {get(offer, column)}
          </Cell>
        );
      });
      return (
        <Row
          key={offer.id}
          to={to(offer)}
          data-test-id={`OfferTable-row-${offer.id}`}
        >
          {cells}
          <div
            className="table-cell"
            style={{ textAlign: 'end' }}
            data-test-id={`OfferTable-cell-${offer.id}/rightArrow`}
          >
            <ArrowRightIcon />
          </div>
        </Row>
      );
    });

  const tableHeaders = () =>
    columns
      .map(column => (
        <Cell
          key={column}
          header
          data-test-id={`OfferTable-headerRow-${type}/${column}`}
        >
          {COLUMN_TITLES[column]}
        </Cell>
      ))
      .concat(<Cell header key="arrow" />);

  const renderContent = () => {
    if (offers.length === 0)
      return <Blankslate>{T(`employee.offers.${type}.empty_list`)}</Blankslate>;

    return (
      <Table>
        <Table.Header>{tableHeaders()}</Table.Header>
        {tableBody()}
      </Table>
    );
  };

  return (
    <div>
      <Box>
        <BoxItem>
          <Header>
            <Header.Title data-test-id={`Table-header-${type}`}>
              {T('employee.offers.' + type + '.label')}
            </Header.Title>
          </Header>
        </BoxItem>
        {renderContent()}
      </Box>
    </div>
  );
};

export default withStyles(styles)(OffersListTable);
