import React, { useEffect } from 'react';

// Components
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaskedInput from 'react-text-mask';

// Constants
import { allowancesAmountMask } from '../helpers/textFieldNumberMasks';

// Utilities
import formatCurrency from '../helpers/formatCurrency';

const CapField = ({
  onChange: upstreamOnChange,
  value,
  updateKey,
  classes = {},
}) => {
  const onChange = ({ target: { value: rawValue } = {} } = {}) => {
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = null;

    upstreamOnChange({
      [updateKey]: value,
    });
  };

  const onBlur = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0' || value === 0) return;

    upstreamOnChange({
      [updateKey]: formatCurrency(value),
    });
  };

  useEffect(() => {
    if (value === 0 || value === null || value === '' || value === undefined)
      return;
    if (formatCurrency(value) === value) return;
    upstreamOnChange({
      [updateKey]: formatCurrency(value),
    });
  }, [value, updateKey, upstreamOnChange]);

  return (
    <TableCell align="center" className={classes.root}>
      <MaskedInput
        mask={allowancesAmountMask}
        value={value < 1 ? null : value}
        onChange={onChange}
        onBlur={onBlur}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': 'CapField-input',
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default CapField;
