import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import getSSOUrl from 'common/constants/config/ssoUrl';
import formatPhoneNumber from 'common/utilities/formatPhoneNumber';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0 27px',
    },
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
  },
  topCopy: {
    color: '#939393',
    fontSize: 16,
    fontWeight: 400,
  },
  editProfile: {
    color: '#3c9dd7',
    fontSize: 16,
    fontWeight: 400,
  },
  itemTitle: {
    color: '#939393',
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 4,
    marginTop: 25,
  },
  itemName: {
    color: '#5b5b5b',
    fontSize: 16,
    fontWeight: 400,
  },
  profileLink: {
    textDecoration: 'none',
  },
}));

const HiringProfile = props => {
  const classes = useStyles();
  const crewData = props?.crewData;
  const profileUrl = `${getSSOUrl()}/profile`;
  const { first_name: firstName, last_name: lastName, email, phone } = crewData;
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Your Hiring Profile</Typography>
      <Typography className={classes.topCopy}>
        Update your profile if anything is incorrect.
      </Typography>
      <Typography className={classes.editProfile}>
        <a href={profileUrl} className={classes.profileLink}>
          Edit Profile
        </a>
      </Typography>
      <Typography className={classes.itemTitle}>Name</Typography>
      <Typography className={classes.itemName}>
        {firstName} {lastName}
      </Typography>
      {phone && <Typography className={classes.itemTitle}>Phone</Typography>}
      {phone && (
        <Typography className={classes.itemName}>
          {formatPhoneNumber(phone)}
        </Typography>
      )}
      {email && <Typography className={classes.itemTitle}>Email</Typography>}
      {email && <Typography className={classes.itemName}>{email}</Typography>}
    </div>
  );
};

export default HiringProfile;
