import React, { Component } from 'react';
import classnames from 'classnames';

import T from 'common/oldJavascripts/utils/i18n';
import Modal from 'common/oldJavascripts/components/Base/Modal';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Header from 'common/oldJavascripts/components/Base/Header';
import Button from 'common/oldJavascripts/components/Base/Button';
import NonEmployeeListItem from './NonEmployeeListItem';
import Departments from '../Departments/Index';

import ajax from 'common/utilities/ajax.js';

// Mui Components
import {
  Paper,
  Grid,
  Typography,
  List,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
// HoC
import { withStyles } from '@material-ui/core/styles';

const styles = {
  listRoot: {
    border: '1px solid #e6e7ea',
    padding: '0 !important',
    marginBottom: '20px !important',
  },
  bold: {
    fontWeight: 'bold',
  },
  inlineTitle: {
    display: 'inline-block',
  },
  controlLabel: {
    float: 'right',
  },
  checkbox: {
    padding: 0,
  },
};

class UsersAndDepartments extends Component {
  state = {
    isWarningOpen: false,
    isLoanOutRestricted: this.props.enforceLoanOut,
  };

  renderList = () => {
    const {
      classes,
      nonEmployees: { items: nonEmployees = [] } = {},
    } = this.props;
    return (
      <List className={classes.listRoot}>
        {nonEmployees.map(nonEmployee => (
          <NonEmployeeListItem
            key={nonEmployee.id}
            params={{ id: nonEmployee.id }}
          />
        ))}
      </List>
    );
  };

  changeLoanOut = event => {
    const { checked } = event.target;
    const { projectId } = this.props;
    this.setState({ isLoanOutRestricted: checked });

    ajax
      .post(
        `/projects/${projectId}/enforce-loan-out`,
        { payload: { enforce_loan_out: checked } },
        { json: true },
      )
      .then(
        () => {},
        () => {
          this.setState({ isLoanOutRestricted: !checked, isWarningOpen: true });
        },
      );
  };

  render() {
    const {
      classes,
      nonEmployees: { items: nonEmployees = [] } = {},
      projectId,
      detailSubDataType,
      enforceMask,
      accountMask,
    } = this.props;

    const { isLoanOutRestricted, isWarningOpen } = this.state;

    return (
      <div>
        <BoxItem divider={true}>
          <Header>
            <Header.Title>
              <span data-test-id="UsersAndDepartments-title">
                {T('project.documents.manage_users.title')}
              </span>
            </Header.Title>
          </Header>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper elevation={0}>
                <Typography variant="h6" gutterBottom className={classes.bold}>
                  {T('project.documents.manage_users.non_employees.title')}
                </Typography>
                {!!nonEmployees.length && this.renderList()}
                <Button to={`/projects/${projectId}/invitations/new`}>
                  {T('project.documents.manage_users.non_employees.add')}
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classnames(classes.bold, classes.inlineTitle)}
                >
                  {T('project.documents.manage_users.departments.title')}
                </Typography>
                <FormControlLabel
                  classes={{ root: classes.controlLabel }}
                  control={
                    <Checkbox
                      checked={isLoanOutRestricted}
                      onChange={this.changeLoanOut}
                      classes={{ root: classes.checkbox }}
                    />
                  }
                  label="Restrict selectable occupations for loan-out hires"
                  data-test-id={`Project-restrictLoanOut-${projectId}`}
                />
                <Departments
                  detailSubDataType={detailSubDataType}
                  enforceMask={enforceMask}
                  accountMask={accountMask}
                />
              </Paper>
            </Grid>
          </Grid>
        </BoxItem>

        {isWarningOpen && (
          <Modal>
            <Typography variant="h6" gutterBottom>
              This project does not yet have any loan-out eligible occupations
              defined.
            </Typography>
            <Button
              onClick={() => {
                this.setState({ isWarningOpen: false });
              }}
              data-test-id="Project-loanOutWarning"
            >
              Close
            </Button>
          </Modal>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(UsersAndDepartments);
