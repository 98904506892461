import React from 'react';
import Feature from 'common/components/Feature';
import OfferDetails from 'onboarding/components/OfferDetails';
import DetailsOld from 'onboarding/oldJavascripts/components/Pages/Offer/DetailsOld';

export default () => (
  <Feature name="mobileOnboarding" fallback={<DetailsOld />}>
    <OfferDetails isOnboarding={true} />
  </Feature>
);
