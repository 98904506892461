import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: { fontWeight: '700', marginBottom: '5px' },
};

const CellTitle = ({ classes, children }) => (
  <div className={classes.root}>{children}</div>
);

export default withStyles(styles)(CellTitle);
