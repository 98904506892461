import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offerDefaults($projectId: Int!) {
    project(id: $projectId) {
      id
      offerDefaults {
        boxRentalAllowance {
          amount
          cap
          duration
          per
        }
        computerRentalAllowance {
          amount
          cap
          duration
          per
        }
        carAllowance {
          amount
          cap
          duration
          per
        }
        mobilePhoneAllowance {
          amount
          cap
          duration
          per
        }
        perDiemAllowance {
          amount
          cap
          duration
          per
        }
        housingAllowance {
          amount
          cap
          duration
          per
        }
        workState {
          id
          name
          code
        }
        hireState {
          id
          name
          code
        }
        sendDateConfiguration
        allowancesUpdatedAt
        enforceLoanOut
        enforceMask
        backdateLimit
        allowEndDate
        hiringStatus
        countryId
        countryCode
      }
    }
  }
`;

const useOfferDefaults = projectId => {
  const { error, loading, data } = useQuery(QUERY, {
    skip: !projectId,
    // NOTE Project.id is listed in the schema as an ID! type, but the project
    // query takes a projectId of type Int! Obviously, this is wrong.
    variables: { projectId: parseInt(projectId) },
  });
  return { error, loading, data: data && data.project.offerDefaults };
};

export default useOfferDefaults;
