import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import history from 'common/constants/config/history';

const styles = {
  root: {
    textDecoration: 'none',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      background: '#fafafa',
    },
  },
  arrowIcon: {
    lineHeight: '1.23076923',
    borderBottom: '1px solid #d8d8d8',
    padding: '16px 20px',
    verticalAlign: 'middle',
    position: 'relative',
    textAlign: 'end',
    overflow: 'hidden',
    width: 'auto',
    textOverflow: 'ellipsis',
  },
  link: {
    textDecoration: 'none',
  },
};

const Row = props => {
  const {
    classes,
    to,
    params,
    query,
    children,
    onClick,
    'data-test-id': dataTestId,
    showArrow,
  } = props;
  const rootClass = classNames(classes.root, {
    [classes.clickable]: !!onClick || !!to,
  });
  const arrowIcon = showArrow && (
    <div className={classes.arrowIcon}>
      <ArrowRightIcon />
    </div>
  );
  const handleClick = () => {
    history.push({
      pathname: to,
      params,
      query,
    });
  };
  if (!!to) {
    return (
      <tr className={rootClass} onClick={handleClick} data-test-id={dataTestId}>
        {children}
        {arrowIcon}
      </tr>
    );
  }
  return (
    <tr
      className={rootClass}
      onClick={() => onClick && onClick()}
      data-test-id={dataTestId}
    >
      {children}
      {arrowIcon}
    </tr>
  );
};

// return domClasses.set({
//   'table-row': true,
//   'table-row--header': this.props.header,
//   'table-row--clickable': this.props.to || this.props.onClick,
//   'table-row--arrow-right': this.props.showArrow,
// });

export default withStyles(styles)(Row);
