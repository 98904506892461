import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { RULE_TYPES } from './constants/ruleTypes';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: `
      "ruleType employmentType  .......... ........"
      "ruleType department  .......... ........"
      "ruleType union       .......... ........"
      "ruleType hireState   workState  workCity"
    `,
    gridTemplateColumns: '24% 24% 24% 24%',
    gridTemplateRows: 'auto auto auto',
    gridColumnGap: '8px',
    columnGap: '8px',
    fontSize: '1.2em',
    background: '#f7fcfc',
    paddingTop: 8,
    paddingLeft: 8,
  },
  ruleType: {
    gridArea: 'ruleType',
    paddingBottom: 8,
  },
  department: {
    gridArea: 'department',
    paddingBottom: 8,
  },
  union: {
    gridArea: 'union',
    paddingBottom: 8,
  },
  hireState: {
    gridArea: 'hireState',
    paddingBottom: 8,
  },
  workState: {
    gridArea: 'workState',
    paddingBottom: 8,
  },
  workCity: {
    gridArea: 'workCity',
    paddingBottom: 8,
  },
  employmentType: {
    gridArea: 'employmentType',
    paddingBottom: 8,
  },
});

const ProjectTemplateAutoAssignmentRuleDisplay = props => {
  const { classes, rule } = props;
  const options = RULE_TYPES.map(({ typeName, description }) => ({
    label: description,
    value: typeName,
  }));
  const {
    id,
    __typename,
    department,
    union,
    hireState,
    workState,
    workCity,
    employmentType,
  } = rule;
  const departmentName = department?.name ?? '--';
  const unionName = union?.description ?? '--';
  const hireStateName = hireState?.name ?? '--';
  const workStateName = workState?.name ?? '--';
  const workCityName = workCity?.name ?? '--';
  const employmentTypeName = employmentType?.label ?? '--';
  const ruleType = __typename.slice(0, -'AutoAssignmentRule'.length);
  const ruleDescription = options.find(({ value }) => value === ruleType).label;
  const isCombo = ruleType === 'Combo';
  const showEmploymentType = isCombo || ruleType === 'EmploymentType';
  const showDepartment = isCombo || ruleType === 'Department';
  const showUnion = isCombo || ruleType === 'Union';
  const showLocation = isCombo || ruleType === 'Location';

  return (
    <div className={classes.root}>
      <div
        className={classes.ruleType}
        data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-ruleType-${id}`}
      >
        <Typography variant="caption">Rule Type</Typography>
        <div>{ruleDescription}</div>
      </div>
      {showEmploymentType && (
        <div
          className={classes.employmentType}
          data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-employmentType-${id}`}
        >
          <Typography variant="caption">Employment Type</Typography>
          <div>
            <UsaOnly>{employmentTypeName}</UsaOnly>
            <CanadaOnly>
              {employmentTypeName === 'W-2' || employmentTypeName === 'W2'
                ? 'Individual'
                : employmentTypeName}
            </CanadaOnly>
          </div>
        </div>
      )}
      {showDepartment && (
        <div
          className={classes.department}
          data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-department-${id}`}
        >
          <Typography variant="caption">Department</Typography>
          <div>{departmentName}</div>
        </div>
      )}
      {showUnion && (
        <div
          className={classes.union}
          data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-union-${id}`}
        >
          <Typography variant="caption">Union</Typography>
          <div>{unionName}</div>
        </div>
      )}
      {showLocation && (
        <React.Fragment>
          <div
            className={classes.hireState}
            data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-hireState-${id}`}
          >
            <Typography variant="caption">
              Hire <UsaOnly>State</UsaOnly>
              <CanadaOnly>Province</CanadaOnly>
            </Typography>
            <div>{hireStateName}</div>
          </div>
          <div
            className={classes.workState}
            data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-workState-${id}`}
          >
            <Typography variant="caption">
              Work <UsaOnly>State</UsaOnly>
              <CanadaOnly>Province</CanadaOnly>
            </Typography>
            <div>{workStateName}</div>
          </div>
          <UsaOnly>
            <div
              className={classes.workCity}
              data-test-id={`ProjectTemplateAutoAssignmentRuleDisplay-workCity-${id}`}
            >
              <Typography variant="caption">Work City</Typography>
              <div>{workCityName}</div>
            </div>
          </UsaOnly>
        </React.Fragment>
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRuleDisplay);
