import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = {
  root: {
    display: [
      '-webkit-box',
      '-moz-box',
      'box',
      '-webkit-flex',
      '-moz-flex',
      '-ms-flex',
      'flex',
    ],
    WebkitBoxOrient: 'horizontal',
    MozBoxOrient: 'horizontal',
    msBoxOrient: 'horizontal',
    boxOrient: 'horizontal',
    WebkitFlexDirection: 'row',
    MozFlexDirection: 'row',
    msFlexDirection: 'row',
    flexDirection: 'row',
  },
  wrap: {
    WebkitFlexFlow: 'row wrap',
    msFlexFlow: 'row wrap',
    flexFlow: 'row wrap',
  },
};

const RowInfo = props => {
  const { classes, children, wrap } = props;
  const rootClass = classNames(classes.root, {
    [classes.wrap]: wrap,
  });
  return <div className={rootClass}>{children}</div>;
};

export default withStyles(styles)(RowInfo);
