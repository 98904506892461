import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import getAPIServer from 'common/constants/config/apiServer';
import serialize from 'serialize-javascript';
import * as Sentry from '@sentry/browser';
import logout from 'common/utilities/logout';
import oktaAuthMyCnC from 'common/utilities/oktaAuthMyCnC';

const authLink = setContext(async (_, { headers }) => {
  const oktaAuthClient = oktaAuthMyCnC();
  const store = await import('common/store');
  const { getState } = store.default;
  const { tokens: { accessToken = '' } = {} } = getState();
  const myCnCToken = await oktaAuthClient.tokenManager.get('accessToken');

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
      authorizationmycnc: `Bearer ${myCnCToken && myCnCToken.accessToken}`,
    },
  };
});

const errorLink = onError(error => {
  const { graphQLErrors = [] } = error || {};
  // Check if users is not authenticated
  const isLoggedOut = ({ message } = {}) => message === 'login';
  if (graphQLErrors.some(isLoggedOut)) {
    logout();
    if (graphQLErrors.length > 1) Sentry.captureException(error);
    return;
  }
  const [{ message } = {}] = graphQLErrors;
  Sentry.captureMessage(`Error Received from graphql Request: ${message}`, {
    extra: {
      error,
      serializedError: serialize(error),
    },
  });
});

const httpLink = new HttpLink({
  uri: `${getAPIServer()}/graphql`,
  fetch: (uri, options) => {
    const { body } = options;
    try {
      const { operationName } = JSON.parse(body);
      if (operationName) {
        uri = `${uri}?${operationName}`;
      }
    } catch (error) {
      // can't find operationName, ignore
    }
    return fetch(uri, options);
  },
});

const link = authLink.concat(errorLink.concat(httpLink));

export default link;
