import React from 'react';

// Components
import DatePicker from 'react-datepicker';
import Typography from '@material-ui/core/Typography';

const EndDateSelect = props => {
  const {
    onChange: upstreamOnChange,
    formData: { startDateObject, endDateObject } = {},
    classes = {},
  } = props || {};

  const onChange = date =>
    upstreamOnChange({
      endDate: (date && date.format('LL')) || null,
      endDateObject: date,
    });

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Anticipated End Date
      </Typography>
      <div data-test-id="EndDate-root">
        <DatePicker
          todayButton={'Today'}
          selected={endDateObject}
          onChange={onChange}
          className={classes.field}
          minDate={startDateObject}
          placeholderText={'MM-DD-YYYY'}
        />
      </div>
    </div>
  );
};
export default EndDateSelect;
