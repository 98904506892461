import React, { Component } from 'react';

import classnames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  TextField,
  Typography,
  MuiThemeProvider,
  FormLabel,
} from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import DocumentTags from 'common/components/UploadedDocument/UploadedDocumentTags';
import ProgressSpinner from 'common/components/ProgressSpinner';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';
import DatePicker from 'common/oldJavascripts/components/Base/DatePickerField';

const styles = theme => ({
  dialogContent: {
    width: 400,
    maxHeight: 400,
    overflow: 'scroll',
    padding: theme.spacing.unit * 3,
    paddingBottom: 0,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  dialogActions: {
    paddingBottom: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  textField: {
    width: 350,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 5,
  },
  tagsWidth: {
    maxWidth: '100%',
  },
  errorText: {
    textTransform: 'capitalize',
  },
  error: {
    padding: '0px 50px',
    minHeight: '1.71429em',
  },
  saveButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: palette.success,
    '&:hover': {
      backgroundColor: palette.successHover,
    },
  },
  expirationDateLabel: {
    marginTop: 16,
    marginBottom: 10,
  },
});

const getValidMoment = value => {
  if (!value) return null;
  const m = moment(value);
  if (!m.isValid()) return null;
  return m;
};

class DocumentMetaData extends Component {
  static defaultProps = {
    isOpen: false,
  };

  state = {
    name: this.props.name,
    expireOn: this.props.expireOn ? moment(this.props.expireOn) : null,
    tags: this.props.tags,
  };

  handleSubmit = () => {
    const { name, expireOn, tags } = this.state;
    const { handleSubmit: handleUpstreamSubmit, id } = this.props;
    handleUpstreamSubmit(id, name, expireOn, tags);
  };

  handleCancel = () => {
    const { handleCancel: handleUpstreamCancel } = this.props;
    handleUpstreamCancel();
  };

  handleDateChange = v => {
    const m = getValidMoment(v);
    this.setState({ expireOn: m });
  };

  handleInputChange = value => {
    this.setState({
      name: value,
    });
  };

  handleAddTag = value => {
    this.setState(({ tags }) => {
      const newTag = { name: value };
      if (
        tags.filter(tag => tag.name.toLowerCase() === value.toLowerCase())
          .length > 0
      ) {
        return null;
      }
      return { tags: [...tags, newTag] };
    });
  };

  handleDeleteTag = tag => {
    this.setState(({ tags }) => {
      const newTags = [...tags];
      newTags.splice(tags.indexOf(tag), 1);
      return {
        tags: newTags,
      };
    });
  };

  isValid = () => this.isNameValid() && this.isTagsValid();

  isNameValid = (name = this.state.name) => {
    const trimmedName = name.trim();
    return trimmedName.length >= 3 && trimmedName.length <= 75;
  };

  isTagsValid = (tags = this.state.tags) =>
    tags.every(tag => {
      const t = tag.name.trim();
      return t.length >= 2 && t.length <= 50;
    });

  render() {
    const { name, tags, expireOn } = this.state;
    const {
      error = [],
      isSaving = false,
      saveSuccess = false,
      isOpen,
      classes,
    } = this.props;
    const isValid = this.isValid();

    return (
      <MuiThemeProvider theme={documentSignerTheme}>
        <Dialog onClose={this.handleCancel} open={isOpen}>
          <div className={classes.dialogContent}>
            <Typography variant="h4" gutterBottom>
              Edit Document
            </Typography>
            <TextField
              value={name}
              error={!this.isNameValid()}
              onChange={e => this.handleInputChange(e.target.value)}
              label={
                this.isNameValid()
                  ? 'Document Name'
                  : 'Document name must be between 3 and 75 characters'
              }
              className={classes.textField}
              inputProps={{
                'data-test-id': 'DocumentMetaData-nameInput',
              }}
            />
            <DocumentTags
              tags={tags}
              addHandler={this.handleAddTag}
              deleteHandler={this.handleDeleteTag}
              classes={{ root: classes.tagsWidth }}
              editable
              form
            />

            <FormLabel
              component="label"
              className={classes.expirationDateLabel}
            >
              Expiration Date
            </FormLabel>

            <div>
              <DatePicker
                name="DocumentMetaData-expirationDate"
                id="DocumentMetaData-expirationDate"
                selected={expireOn}
                minDate={moment()}
                showYearDropdown={true}
                isClearable={true}
                onChange={this.handleDateChange}
                popperClassName="DocumentMetaData-popper-root"
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: 'scrollParent',
                  },
                }}
              />
            </div>
          </div>
          <DialogActions className={classes.dialogActions}>
            <div className={classes.error}>
              <Typography
                variant="body1"
                color="error"
                className={classes.errorText}
              >
                {error.join('. ')}
              </Typography>
            </div>
            <Button
              onClick={this.handleCancel}
              className={classes.button}
              color="secondary"
              variant="contained"
            >
              Cancel
            </Button>
            <div className={classes.saveButtonContainer}>
              <Button
                className={classnames(
                  {
                    [classes.buttonSuccess]: saveSuccess,
                  },
                  classes.button,
                )}
                onClick={this.handleSubmit}
                disabled={isSaving || !isValid}
                color="primary"
                variant="contained"
                data-test-id="DocumentMetaData-saveButton"
              >
                Save
              </Button>
              {isSaving && <ProgressSpinner size={24} top={6} left={20} />}
            </div>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(DocumentMetaData);
