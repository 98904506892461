import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const typographyProps = { variant: 'h6' };

const RuleList = props => {
  const { onClick, id, title, isOpen, children } = props;
  return (
    <React.Fragment>
      <ListItem
        button
        onClick={onClick}
        data-test-id={`GroupContainer-${id}List`}
      >
        <ListItemText
          primary={title}
          primaryTypographyProps={typographyProps}
        />
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Divider />
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </React.Fragment>
  );
};

export default RuleList;
