import React from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import SingleDoc from 'common/icons/SingleDocIcon';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing.unit * 5,
  },
  documentName: {
    fontSize: '0.75em',
  },
  fileIcon: {
    marginRight: -theme.spacing.unit * 1.5,
    height: 16,
  },
  previewIcon: {
    marginLeft: theme.spacing.unit,
    height: 18,
    cursor: 'pointer',
  },
});

const SupportingDocument = props => {
  const { name, classes, parentDocId, previewDocument, id, isViewable } = props;
  return (
    <div className={classes.root}>
      <SingleDoc className={classes.fileIcon} />
      <Typography className={classes.documentName}>{name}</Typography>
      {isViewable && (
        <VisibilityIcon
          className={classes.previewIcon}
          onClick={() => previewDocument(false, parentDocId, id)}
          data-test-id={`supporting-document-preview-${id}`}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(SupportingDocument);
