import React from 'react';
import DocumentSigner from 'common/oldJavascripts/components/Shared/DocumentSigner/DocumentSigner';
import SupportingDocumentForm from 'common/oldJavascripts/components/Shared/SupportingDocumentForm/SupportingDocumentForm';
import { Button, withStyles } from '@material-ui/core';
import OfferInfoBar from './OfferInfoBar';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    height: 'calc(100vh - 130px)',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: '25px calc(100% - 50px) 25px',
  },
  rootNoNext: {
    gridTemplateRows: '25px 36px calc(100% - 86px) 25px',
    gridTemplateAreas: `
    ". ....... ."
    ". infoBar ."
    ". content ."
    ". ....... ."`,
  },
  rootWithNext: {
    gridTemplateRows: '25px 36px calc(100% - 134px) 48px 25px',
    gridTemplateAreas: `
    ". ....... ."
    ". infoBar ."
    ". content ."
    ".  next   ."
    ". ....... ."`,
  },
  close: {
    zIndex: 10,
    // gridArea: 'close',
  },
  nextContainer: {
    gridArea: 'next',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: 'repeat(3, 33.33%)',
    gridTemplateAreas: `
    ". . button"`,
  },
  nextButton: {
    gridArea: 'button',
    placeSelf: 'end',
    paddingRight: 15,
    color: '#eee',
    backgroundColor: '#3c9dd7',
    '&:hover': {
      backgroundColor: '#59acdd',
    },
  },
  content: {
    height: '100%',
    width: '100%',
    border: '1px solid #dadada',
    placeSelf: 'center',
    gridArea: 'content',
  },
  infoBar: {
    gridArea: 'infoBar',
  },
});

class DocumentInteractionController extends React.Component {
  constructor(props) {
    const {
      offerId,
      filter,
      handleOfferAccept,
      handleOfferReject,
      type,
      errors,
      buttonText,
      handleSave,
    } = props;
    super(props);
    this.state = {
      step: 0,
      enableNext: false,
      errors: errors,
    };
    this.steps = [
      {
        component: SupportingDocumentForm,
        signer: false,
        displayNext: true,
        props: {
          params: {
            offerId: offerId,
            type,
          },
          enableNext: this.enableNext.bind(this),
          skipIfEmpty: this.handleNextClick.bind(this), // If there are no supporting docs skip this step
        },
      },
      {
        component: DocumentSigner,
        signer: true,
        displayNext: false,
        props: {
          params: {
            offerId: offerId,
            type,
          },
          filter,
          handleOfferAccept,
          handleOfferReject,
          buttonText,
          handleSave,
        },
      },
    ];
  }

  componentWillReceiveProps({ errors }) {
    if (errors !== this.state.errors) this.setState({ errors });
  }

  enableNext(enableNext) {
    this.setState({ enableNext });
  }

  handleNextClick() {
    const { handleEndDocumentInteraction } = this.props;
    this.setState(({ step }) => {
      if (step + 1 > this.steps.length - 1) {
        handleEndDocumentInteraction();
        return {};
      }
      return {
        step: step + 1,
      };
    });
  }

  render() {
    const {
      handleEndDocumentInteraction,
      offer,
      className,
      classes,
    } = this.props;
    const { step, enableNext, errors } = this.state;
    const { component: Component, props, displayNext } = this.steps[step];
    const rootClass = classNames(
      {
        [classes.rootWithNext]: displayNext,
        [classes.rootNoNext]: !displayNext,
      },
      classes.root,
      className,
    );
    return (
      <div
        className={rootClass}
        data-test-id="DocumentInterationController-root"
      >
        <OfferInfoBar
          className={classes.infoBar}
          onGoBackClick={handleEndDocumentInteraction}
          offer={offer}
        />
        <Component errors={errors} {...props} className={classes.content} />
        {displayNext && (
          <div className={classes.nextContainer}>
            <Button
              className={classes.nextButton}
              disabled={!enableNext}
              onClick={this.handleNextClick.bind(this)}
              variant="contained"
              data-test-id="DocumentInterationController-nextButton"
            >
              Next
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DocumentInteractionController);
