import React, { Component } from 'react';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';
import Link from 'common/oldJavascripts/components/Base/Link';
import Header from 'common/oldJavascripts/components/Base/Header';
import List from 'common/oldJavascripts/components/Base/List';
import Preloader from 'common/oldJavascripts/components/Shared/Preloader';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import FileItem from './FileItem';
import T from 'common/oldJavascripts/utils/i18n';
import collection from 'common/oldJavascripts/utils/collection.js';
import format from 'common/oldJavascripts/utils/format.js';
import { Link as ReactLink } from 'react-router-dom';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ajax from 'common/utilities/ajax';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

import BoxHeader from 'common/oldJavascripts/components/Base/Box/BoxHeader';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

const styles = {
  finalDocContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  finalDocLink: {
    display: 'flex',
    alignItems: 'center',
  },
  rowRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  finalDocIcon: {
    marginRight: '5px',
  },
};

class Details extends Component {
  static queries = {
    offer: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: [
            '/projects',
            params.projectId,
            'offers-documents',
            params.offerId,
          ].join('/'),
        };
      },
    },

    offer_files: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: ['/v2/offers', params.offerId, 'files'].join('/'),

          expires: true,
        };
      },
    },
  };

  _download_final_document = () => {
    const { routerParams = {}, pushNotification, popNotification } = this.props;
    const { offerId } = routerParams || {};
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(`/v2/offers/${offerId}/full-final-document`)
      .then(response => {
        Sentry.addBreadcrumb({ response });
        const { path = '' } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification();
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
      })
      .catch(err => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
        Sentry.captureException(err);
      });
  };

  render() {
    const {
      classes = {},
      routerParams = {},
      offer: offerQuery,
      offer_files: offerFilesQuery,
    } = this.props;
    const { data: offer } = offerQuery;
    const { data: offerFiles } = offerFilesQuery;
    var final_doc = null;
    var can_download_final_document = false;

    if (
      offerQuery.status === 'loading' ||
      offerFilesQuery.status === 'loading'
    ) {
      return <Preloader />;
    }

    for (var i = 0; i < offerFiles.items.length; i++) {
      if (
        offerFiles.items[i].can_download === false ||
        offerFiles.items[i].has_packets
      ) {
        break;
      }
      if (i === offerFiles.items.length - 1) {
        can_download_final_document = true;
      }
    }

    if (can_download_final_document) {
      final_doc = (
        <div
          className={classNames(classes.finalDocContainer, 'text-align-right')}
        >
          <Link
            bold={true}
            onClick={this._download_final_document}
            className={classes.finalDocLink}
          >
            <DownloadIcon name="download" className={classes.finalDocIcon} />
            {' Final E-Signature Document '}
          </Link>
        </div>
      );
    }

    return (
      <LayoutContent>
        <Box>
          <BoxHeader>
            <Header>
              <Header.Title>
                {format.fullName(
                  offer.first_name,
                  offer.middle_name,
                  offer.last_name,
                )}
              </Header.Title>
            </Header>
          </BoxHeader>
          <BoxItem>
            <BoxItem minimal={true}>
              <Header>
                <Header.Title>
                  {T('offers_documents.detail.title')}
                </Header.Title>
              </Header>
              {final_doc}
              <List>
                {collection.map(offerFiles.items, function(file) {
                  return <FileItem params={{ id: file.id }} key={file.id} />;
                })}
              </List>
            </BoxItem>
            <NavBar>
              <NavBar.Item>
                <ReactLink
                  to={`/projects/${routerParams.projectId}/offer-documents`}
                >
                  {T('shared.actions.back')}
                </ReactLink>
              </NavBar.Item>
            </NavBar>
          </BoxItem>
        </Box>
      </LayoutContent>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

export default compose(
  withApi,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Details);
