import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withApi from 'common/hoc/withApi';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import history from 'common/constants/config/history';
import getSSOUrl from 'common/constants/config/ssoUrl';
import ajax from 'common/utilities/ajax';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser.js';
import moment from 'moment';
import PreLoader from 'mobile/components/PreLoader';
import TermsOfEmployment from './TermsOfEmployment';
import Allowances from './Allowances';
import AdditionalDealTerms from './AdditionalDealTerms';
import DocumentsPanel from './DocumentsPanel';
import TermsOfHire from './TermsOfHire';
import HiringProfile from './HiringProfile';
import NeedAssistance from './NeedAssistance';
import ReviewOffer from './ReviewOffer';
import CurrencyIndicator from './CurrencyIndicator';
import BackButton from 'mobile/components/BackButton';
import Dialog from 'common/components/Dialog';
import RejectModal from 'onboarding/oldJavascripts/components/Pages/Offer/Onboarding/RejectModal';
import ScrollDestination from 'common/components/ScrollDestination';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

const I9_NAMES = ['i9', 'I9', 'I-9', 'i-9'];

const useStyles = makeStyles(theme => ({
  section1: {
    position: 'relative', // So `position: absolute` works correctly inside
    gridArea: 'offersummary',
    width: '100%',
    minHeight: '1340px',
    borderRadius: '3px',
    backgroundColor: '#ffffff',
    padding: '40px 10px',
    [theme.breakpoints.up('md')]: {
      padding: '40px 25px',
      minWidth: 669,
      height: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 827,
      height: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 950,
      height: 'auto',
    },
  },
  section2: {
    gridArea: 'row1',
  },
  section3: {
    gridArea: 'row2',
  },
  section4: {
    gridArea: 'row3',
  },
  section5: {
    gridArea: 'row4',
  },
  gridContainer: {
    display: 'grid',
    margin: 0,
    gridTemplateAreas: `'row1''offersummary''row2''row3''row4''row5'`,
    gridGap: '20px',
    backgroundColor: '#f2f5f7',
    padding: '10px',
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `
      'offersummary row1'
      'offersummary row2'
      'offersummary row3'
      'offersummary row4'
      'offersummary row5'`,
      margin: '30px 16px',
    },
    '& section:not(:first-child)': {
      borderRadius: '2px',
      backgroundColor: '#ffffff',
      padding: '30px 0',
      minWidth: 355,
      height: 'auto',
      [theme.breakpoints.up('lg')]: {
        minWidth: 404,
        height: 'auto',
      },
      [theme.breakpoints.up('xl')]: {
        minWidth: 550,
        height: 'auto',
      },
    },
  },
  submittedGridContainer: {
    gridTemplateAreas: `'offersummary''row2''row3''row4''row5'`,
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `
      'offersummary row2'
      'offersummary row3'
      'offersummary row4'
      'offersummary row5'`,
      margin: '30px 16px',
    },
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
  },
  itemLabel: {
    color: '#aaaaaa',
    fontSize: 16,
    fontWeight: 400,
  },
  itemTitle: {
    color: '#5b5b5b',
    fontSize: 22,
    fontWeight: 400,
  },
  itemContainer: {
    display: 'grid',
    marginTop: '30px',
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      marginTop: '30px',
    },
    '& div': {
      marginBottom: '24px',
    },
  },
  panel: {
    backgroundColor: '#f5fcfb',
    padding: '10px',
  },
  amount: {
    color: '#2eaba4',
    fontSize: 30,
    fontWeight: 400,
    padding: '15px',
    textAlign: 'center',
    border: '2px solid #f5fcfb',
  },
  hide: {
    display: 'none',
  },
  backBtnContainer: {
    marginTop: 10,
    position: 'relative',
    top: '-35px',
    right: '10px',
  },
  currencyIndicator: {
    position: 'absolute',
    right: 25,
    top: 43,
  },
}));

// TODO Centralize onboarding navigation logic
const getNextStatus = documentsQuery => {
  const { items: documents = [] } = documentsQuery.data;

  const doesOfferIncludeSupportingDocuments = documents.some(
    doc => doc.supportingDocumentTitles?.length > 0,
  );
  if (doesOfferIncludeSupportingDocuments) {
    return 'onboarding_supporting-documents';
  }

  const doesOfferIncludeI9 = documents.some(doc =>
    doc.categories?.some(({ name = '' }) => I9_NAMES.includes(name)),
  );
  if (doesOfferIncludeI9) {
    return 'onboarding_i9';
  }

  return 'onboarding_documents';
};

const getNextUrl = (offerId, nextStatus) =>
  `/onboarding/offers/${offerId}/${nextStatus.split('_')[1]}`;

const getIsRegistrationComplete = () =>
  ajax.get('/registration').then(resp => !!resp?.registration_complete);

const OfferDetails = props => {
  const { documents, offer, hirer = {}, pushSnackbarNotification } = props;
  const offerData = offer?.data || {};
  const hirerData = hirer?.data;
  const crewProfileData = offerData;
  const isOfferSubmitted = offerData.status === 'submitted';
  const isOfferApproved = offerData.status === 'approved';
  const isOfferLoading =
    offer.status === 'loading' || documents.status === 'loading';
  const { items: documentsData = [] } = documents?.data;
  const hasDocuments = documentsData.length > 0;

  const classes = useStyles();
  const [
    isRegistrationIncompleteModalOpen,
    setIsRegistrationIncompleteModalOpen,
  ] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  if (isOfferLoading) {
    return (
      <div>
        <PreLoader />
      </div>
    );
  }

  const optedOut = offerData?.status === 'complete_on_paper';
  const isOldOffer = optedOut || isOfferSubmitted || isOfferApproved;
  const isReviewOffer = !optedOut && !isOfferSubmitted && !isOfferApproved;

  const gridContainerClass = isReviewOffer
    ? classes.gridContainer
    : [classes.gridContainer, classes.submittedGridContainer].join(' ');

  const continueToNextStep = () => {
    const { id: offerId } = offerData;
    const nextStatus = getNextStatus(documents);
    const nextUrl = getNextUrl(offerId, nextStatus);

    offer.updateAndSave(
      { status: nextStatus },
      'status',
      {},
      () => history.push(nextUrl),
      handleAcceptError,
    );
  };

  const handleAcceptError = async acceptError => {
    let registrationCheckError;
    try {
      const isRegistrationComplete = await getIsRegistrationComplete();
      if (!isRegistrationComplete) {
        setIsRegistrationIncompleteModalOpen(true);
        return;
      }
    } catch (err) {
      registrationCheckError = err;
    }

    pushSnackbarNotification({
      message: parseErrorMessages(registrationCheckError ?? acceptError),
      variant: SnackbarVariants.ERROR,
    });
  };

  const handleRejectError = err => {
    pushSnackbarNotification({
      message: parseErrorMessages(err),
      variant: SnackbarVariants.ERROR,
    });
    setIsRejectModalOpen(false);
  };

  const gotoRegistration = countryCode => {
    const offerDir =
      countryCode === 'CA' ? 'startpluscanoffer' : 'startplusoffer';
    const registrationUrl = `${getSSOUrl()}/${offerDir}`;
    window.location = registrationUrl;
  };

  const canShowDocuments = isOfferSubmitted || optedOut || isOfferApproved;

  const getDialog = countryCode => (
    <Dialog
      open={isRegistrationIncompleteModalOpen}
      title="Registration Incomplete"
      message={
        <React.Fragment>
          <Typography gutterBottom>
            Sorry, but it looks like you haven't completed your registration.
            Please go to MyCast&Crew to finish registering before you accept
            your offer.
          </Typography>
          <Typography gutterBottom>
            If you need help, contact support:{' '}
          </Typography>
          <Typography>
            <a href="mailto:plus.support@castandcrew.com">
              Plus.Support@castandcrew.com
            </a>{' '}
          </Typography>
          <Typography>
            <a href="tel:818.860.7770">818.860.7770</a> (United States)
          </Typography>
          <Typography>
            <a href="tel:416.324.9748">416.324.9748</a> (Canada)
          </Typography>
        </React.Fragment>
      }
      actions={[
        {
          text: 'Complete Registration',
          fn: () => gotoRegistration(countryCode),
        },
      ]}
    />
  );

  return (
    <ScrollDestination isActive={isOldOffer} behavior="auto" block="start">
      <div className={gridContainerClass}>
        <section className={classes.section1}>
          <div className={classes.backBtnContainer}>
            <BackButton
              title="Back to Offers"
              data-test-id="OfferDetails-backBtn"
              url={`/onboarding/offers`}
              size="small"
            />
          </div>
          <CanadaOnly>
            <CurrencyIndicator className={classes.currencyIndicator} />
          </CanadaOnly>
          <Typography className={classes.title}>Offer Summary</Typography>
          <div className={classes.itemContainer}>
            <div>
              <Typography className={classes.itemLabel}>
                Project Name
              </Typography>
              <Typography
                className={classes.itemTitle}
                data-test-id="OfferDetails-projectName"
              >
                {offerData.project_name}
              </Typography>
            </div>
            <div>
              <Typography className={classes.itemLabel}>
                Start Date on or About
              </Typography>
              <Typography className={classes.itemTitle}>
                {moment(offerData.start_date).format('MMMM Do, YYYY')}
              </Typography>
            </div>
            <div>
              <Typography className={classes.itemLabel}>Job Title</Typography>
              <Typography className={classes.itemTitle}>
                {offerData.occupation_description}
              </Typography>
            </div>
          </div>
          <TermsOfEmployment offerData={offerData} />
          <Allowances offerData={offerData} />
          <AdditionalDealTerms offerData={offerData} hirer={hirerData} />
          {hasDocuments && canShowDocuments && (
            <DocumentsPanel documents={documentsData} offer={offerData} />
          )}
        </section>
        {isReviewOffer && (
          <section className={classes.section2}>
            <ReviewOffer
              onContinueToNextStep={continueToNextStep}
              onReject={() => setIsRejectModalOpen(true)}
            />
          </section>
        )}
        <section className={classes.section3}>
          <TermsOfHire offerData={offerData} />
        </section>
        <section className={classes.section4}>
          <HiringProfile crewData={crewProfileData} />
        </section>
        <section className={classes.section5}>
          <NeedAssistance hirer={hirerData} />
        </section>
        <UsaOnly>{getDialog('US')}</UsaOnly>
        <CanadaOnly>{getDialog('CA')}</CanadaOnly>
      </div>
      {isRejectModalOpen && (
        <RejectModal
          onClose={() => setIsRejectModalOpen(false)}
          onError={handleRejectError}
        />
      )}
    </ScrollDestination>
  );
};

OfferDetails.queries = {
  hirer: {
    info: function(_, related) {
      var params = related['/router/params'];
      return {
        id: '/v2/offers/' + params.offerId + '/hirer',
      };
    },
  },
  documents: {
    info: (_, related) => {
      const { offerId } = related['/router/params'];
      return {
        id: `/v2/offers/${offerId}/documents-details`,
      };
    },
  },
};

OfferDetails.mutators = {
  offer: {
    info: function(_, related) {
      var params = related['/router/params'];
      return {
        id: '/v2/offers/' + params.offerId,
      };
    },
  },
};

export default withSnackbarNotification(withApi(OfferDetails));
