import React, { Component } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Typography,
  withStyles,
  Tooltip,
  Divider,
  InputLabel,
} from '@material-ui/core';

import _ from 'lodash';
import classnames from 'classnames';
import Field from 'common/oldJavascripts/components/Base/Field';
import createAPIMaskPlaceholder from 'common/oldJavascripts/utils/createAPIMaskPlaceholder';
import AccountCodeMaskedInput from 'common/components/AccountCodeMaskedInput';
import accountCodeRestrictions from 'common/oldJavascripts/utils/accountCodeRestrictions';
import { createOfferCurrency } from 'common/oldJavascripts/utils/format';
import CopyFromIcon from 'common/oldJavascripts/components/Shared/CopyFromIcon';
import CopyToIcon from 'common/oldJavascripts/components/Shared/CopyToIcon';
import Loader from 'common/components/Loader';

const styles = () => ({
  content: {
    width: 900,
    height: 700,
    paddingLeft: 12,
    paddingRight: 12,
  },
  copyFrom: {
    height: 36,
    width: 36,
    marginLeft: 'auto',
  },
  copyTo: {
    height: 36,
    width: 36,
    marginRight: 'auto',
  },
  copyFromLabel: {
    marginTop: '-4px',
  },
  dialogTitle: {
    fontSize: '1.2em',
  },
  highlight: {
    background: '#f3f3f3',
  },
  menuItem: {
    textTransform: 'capitalize',
  },
  menuTitle: {
    outline: 'none',
    color: 'black',
    fontSize: '1.4rem',
  },
  buttonContainer: {
    display: 'flex',
    gridColumnEnd: '-1',
  },
  labelContainer: {
    gridColumn: '1/-1',
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    columnGap: 12,
    gridTemplateRows: '100%',
  },
  saveButton: {
    background: '#3c9dd7',
    color: 'white',
  },
  title: {
    gridColumn: '1 / 6',
    textTransform: 'capitalize',
    fontWeight: 600,
    alignSelf: 'center',
  },
  row: {
    paddingLeft: 8,
    paddingRight: 8,
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    columnGap: 12,
    rowGap: '2px',
    gap: '2px',
  },
  tooltip: {
    fontSize: '1rem',
  },
  label: {
    alignSelf: 'end',
    paddingBottom: '5px',
  },
});

// Passed in to the accountCodeRestrictions, it just
// formats the value to alphnumerical only, no special characters

const DEFAULT_DATA_TYPE = 'ALL';

const roundNumber = (number, precision) => {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

const codeTranslation = code => {
  switch (code) {
    case 'hourly_rate':
    case 'rate_per_hour':
      return ['rate_per_hour_location', 'rate_per_hour_studio'];
    case 'weekly_rate':
    case 'rate_per_week':
      return ['rate_per_week_location', 'rate_per_week_studio'];
    case 'studio':
      return ['rate_per_week_studio', 'rate_per_hour_studio'];
    case 'location':
      return ['rate_per_hour_location', 'rate_per_week_location'];
    case 'daily_rate':
      return ['rate_per_day_studio'];
    case 'per_diem':
      return ['per_diem_allowance_amount'];
    default:
      return [`${code}_amount`];
  }
};

const unionCodeExclusions = ['S', 'L'];
const nonUnionCodeExclusions = ['HR', 'DR', 'WR'];

const fieldNameTranslation = fieldName => {
  switch (fieldName) {
    case 'insurance':
      return 'Ins';
    case 'location':
      return 'Loc';
    case 'free_field_1':
      return 'FF1';
    case 'free_field_2':
      return 'FF2';
    case 'free_field_3':
      return 'FF3';
    case 'free_field_4':
      return 'FF4';
    default:
      return `${fieldName[0].toUpperCase()}${fieldName.slice(1)}`;
  }
};

const termsOfEmploymentDecimalFormat = {
  HR: 9,
  DR: 9,
  WR: 9,
  BR: 4,
  CR: 4,
  CA: 4,
  MP: 4,
  PD: 4,
  HA: 4,
  L: 9,
  S: 9,
};

const processedStatuses = [
  'approved',
  'rejected',
  'rejected_employee',
  'archived',
  'rescinded',
];

class AccountCodeModal extends Component {
  state = {
    copyMenuAnchor: null,
    menuTitle: '',
    accountCodeValues: {},
    activeRow: null,
    errors: [],
    pristineCodeValues: {},
    detailSubCode: null,
    detailSubDataType: null,
  };

  componentWillReceiveProps(nextProps) {
    const {
      accountCodes = [],
      termsOfEmployment: newTermsOfEmployment,
      offerId: nextOfferId,
      offerAccountCode: newOfferAccountCode,
      isOpen: nextIsOpen,
      detailSubCode: newDetailSubCode,
      tableRow,
      offerCodes,
      codeTypes,
      detailSubDataType: newDetailSubDataType,
      isLoading,
    } = nextProps;
    const {
      termsOfEmployment: oldTermsOfEmployment,
      offerId,
      offerAccountCode,
      isOpen: previousIsOpen,
      accountCodes: oldAccountCodes = [],
      offerCodes: oldOfferCodes = [],
    } = this.props;
    const {
      accountCodeValues: oldAccountCodeValues,
      detailSubCode: oldDetailSubCode,
      detailSubDataType,
    } = this.state;

    /*
      Update the project detail/sub data type if we've received a new detail sub and didn't have a previous non-null value OR the new detail sub is different from the previous detail sub
    */
    if (
      (newDetailSubDataType && !detailSubDataType) ||
      newDetailSubDataType !== detailSubDataType
    ) {
      this.setState({ detailSubDataType: newDetailSubDataType });
    }
    let accountCodeValues = {};

    // Update the detail/sub code for all account codes if the department has been changed
    if (
      newDetailSubCode &&
      oldDetailSubCode !== null &&
      oldDetailSubCode !== newDetailSubCode &&
      accountCodes.length &&
      !isLoading
    ) {
      accountCodeValues = this.updateAccountCodeValues(
        accountCodes,
        offerCodes,
        codeTypes,
        newDetailSubCode,
        newDetailSubDataType,
        oldDetailSubCode,
        newTermsOfEmployment,
        oldTermsOfEmployment,
      );
      this.setState(
        {
          detailSubCode: newDetailSubCode,
          accountCodeValues,
        },
        () => {
          if (!tableRow) {
            this.updateOfferAccountCodes(newTermsOfEmployment, false);
          }
        },
      );
      return;
    }

    // Update the account code values if the account codes and offer account codes have loaded
    if (
      ((accountCodes.length && !oldAccountCodes.length) ||
        (offerCodes.length && !oldOfferCodes.length)) &&
      !isLoading
    ) {
      accountCodeValues = this.updateAccountCodeValues(
        accountCodes,
        offerCodes,
        codeTypes,
        newDetailSubCode,
        newDetailSubDataType,
        oldDetailSubCode,
        newTermsOfEmployment,
        oldTermsOfEmployment,
      );
      this.setState(
        { detailSubCode: newDetailSubCode, accountCodeValues },
        () => {
          if (!tableRow) {
            this.updateOfferAccountCodes(newTermsOfEmployment, false);
          }
        },
      );
      return;
    }

    const termsOfEmploymentUpdated = !_.isEqual(
      oldTermsOfEmployment,
      newTermsOfEmployment,
    );
    let offerAccountCodeChanged;

    // Keep a copy of the account codes before the modal is opened in
    // the event that the user cancels their changes
    if (!previousIsOpen && nextIsOpen) {
      this.setState({ pristineCodeValues: oldAccountCodeValues });
    }

    if (newOfferAccountCode && newOfferAccountCode !== offerAccountCode) {
      offerAccountCodeChanged = true;
    }
    // If terms of employment contains new values, update the offer mutator with default values
    if (termsOfEmploymentUpdated || offerAccountCodeChanged) {
      this.updateOfferAccountCodes(newTermsOfEmployment, false);
    }
    if (termsOfEmploymentUpdated && !isLoading) {
      accountCodeValues = this.updateAccountCodeValues(
        accountCodes,
        offerCodes,
        codeTypes,
        newDetailSubCode,
        newDetailSubDataType,
        oldDetailSubCode,
        newTermsOfEmployment,
        oldTermsOfEmployment,
      );
      this.setState({ accountCodeValues }, () =>
        this.updateOfferAccountCodes(newTermsOfEmployment, false),
      );
    }
    // Update the initial account codes when values are initially received
    if (
      !_.isEqual(accountCodeValues, oldAccountCodeValues) &&
      Object.keys(oldAccountCodeValues).length === 0
    ) {
      this.setState({ accountCodeValues });
      return;
    }
    // Update account code values when a reviewer is switching between offers
    if (nextOfferId !== offerId) {
      this.setState({ accountCodeValues });
      return;
    }
  }

  filterCodeTypesDependingOnUnion = codeType => {
    const { isNonUnion, country } = this.props;
    if (isNonUnion || country === 'Canada') {
      return !unionCodeExclusions.includes(codeType.code);
    }
    return !nonUnionCodeExclusions.includes(codeType.code);
  };

  // updates the account code values in the modal UI
  updateAccountCodeValues = (
    accountCodes,
    offerCodes,
    codeTypes,
    detailSubCode,
    detailSubDataType,
    oldDetailSubCode,
    newTermsOfEmployment = {},
    oldTermsOfEmployment = {},
  ) => {
    const { isExistingOffer, tableRow } = this.props;
    const { accountCodeValues: oldAccountCodeValues } = this.state;
    let accountCodeValues = {};
    // Format account codes with the offer values if reviewing or editing an offer
    if (isExistingOffer) {
      accountCodes.forEach(accountCode => {
        codeTypes.forEach(codeType => {
          const accountCodeRow = oldAccountCodeValues[codeType.code] || {};
          const codeStateValue = accountCodeRow[accountCode.name];
          const offerCode =
            offerCodes.find(
              oc => oc.code === codeType.code && oc.name === accountCode.name,
            ) || {};
          // Check the terms of employment for a value that corresponds the code type
          const hasUserValue = codeTranslation(codeType.description)
            .map(key => newTermsOfEmployment[key])
            .filter(value => !!value)[0];
          // Check if the terms of employment value is newly added by the user
          const isNewTermValue =
            !codeTranslation(codeType.description)
              .map(key => oldTermsOfEmployment[key])
              .filter(value => !!value)[0] && !!hasUserValue;
          // If there is a corresponding terms of employment value for the code type (eg. Box rental, housing allowance)
          // use the stored state value or the value saved on the offer
          let value = hasUserValue
            ? codeStateValue || offerCode.value || ''
            : '';
          // If the value is a brand new user input in the terms of employment set the value to the default account code value
          if (isNewTermValue) {
            value = accountCode.value || '';
          }
          // If we are viewing the account codes in a table row (ie. manage offers table, approvals table, etc)
          // set the value to the account code value stored in the offer
          if (tableRow) {
            value = offerCode.value || '';
          }
          /*
            When reviewing offers do not update the detail/sub if the offer has a detail/sub
            that differs from the department detail/sub.
            When resending/editing offers update the detail/sub if a new terms of employment value is present
            Conditions for setting detail/sub as the value:
              - The offer code is the detail/sub code and
                - the detail/sub code has changed ie. the department has changed and the user
                  is editing/reviewing the offer OR the user input a new value in the terms of employment
              - We are currently iterating on the detail/sub account code and the offer doesn't have an existing value for the account code and
                - The user inputs a new value in the terms of employment OR
                - The current value in the state is different from the updated detail/sub value
          */
          const isNewDetailSub =
            detailSubCode !== oldDetailSubCode && oldDetailSubCode != null;
          const isDetailSubOfferCode = offerCode.field_code === 'detail/sub';
          const isDetailSubAccountCode = accountCode.code === 'detail/sub';
          const isNotExistingOfferCode = !Object.keys(offerCode).length;
          const isUpdatedDetailSub = codeStateValue !== detailSubCode;
          if (
            (isDetailSubOfferCode &&
              ((isNewDetailSub && !tableRow) || isNewTermValue)) ||
            (isDetailSubAccountCode &&
              ((isNotExistingOfferCode && isNewTermValue) ||
                (isNotExistingOfferCode && isUpdatedDetailSub)) &&
              !tableRow)
          ) {
            value = detailSubCode;
          }
          // If the account code is the detail/sub, use the detail/sub data type,
          // otherwise use the account code data type to format the value

          let formattedValue;
          if (accountCode.code !== 'detail/sub') {
            formattedValue = `${value}`
              .replace(accountCodeRestrictions[DEFAULT_DATA_TYPE], '')
              .toUpperCase();
          } else {
            formattedValue = `${value}`
              .replace(accountCodeRestrictions[detailSubDataType], '')
              .toUpperCase();
          }
          accountCodeValues = {
            ...accountCodeValues,
            [codeType.code]: {
              ...accountCodeValues[codeType.code],
              [accountCode.name]: formattedValue,
            },
          };
        });
      });
      return accountCodeValues;
    }

    // New offer
    accountCodes.forEach(accountCode => {
      // Do not add a default value to state if there is no default value, the account code is not required, and
      // the account code is not the detail sub
      if (
        accountCode.default_value === null &&
        accountCode.is_required === false &&
        accountCode.code !== 'detail/sub'
      )
        return;
      codeTypes.forEach(codeType => {
        const accountCodeRow = oldAccountCodeValues[codeType.code] || {};
        const codeStateValue = accountCodeRow[accountCode.name];
        // Set value to user input if present and use the default value and empty string as fallback
        let value = codeStateValue || accountCode.default_value || '';
        // Update the detail/sub value when an updated detail/sub value is received upstream
        if (
          accountCode.code === 'detail/sub' &&
          (!codeStateValue || detailSubCode !== oldDetailSubCode)
        ) {
          value = detailSubCode || '';
        }
        // If the account code is the detail/sub, use the detail/sub data type,
        // otherwise use the account code data type to format the value
        let formattedValue;
        const dataType =
          accountCode.code === 'detail/sub'
            ? detailSubDataType
            : accountCode.data_type;

        const isDetailSubDataType = accountCode.code === 'detail/sub';

        if (isDetailSubDataType) {
          formattedValue = `${value}`
            .replace(accountCodeRestrictions[dataType], '')
            .toUpperCase();
        } else {
          formattedValue = `${value}`
            .replace(accountCodeRestrictions[DEFAULT_DATA_TYPE], '')
            .toUpperCase();
        }

        accountCodeValues = {
          ...accountCodeValues,
          [codeType.code]: {
            ...accountCodeValues[codeType.code],
            [accountCode.name]: formattedValue,
          },
        };
      });
    });
    return accountCodeValues;
  };

  openCopyMenu = (event, title, code) => {
    const { currentTarget } = event;
    this.setState({
      copyMenuAnchor: currentTarget,
      menuTitle: title,
      activeRow: code,
    });
  };

  closeCopyMenu = () => {
    this.setState({ copyMenuAnchor: null });
  };

  updateInput = (
    value,
    accountCodeName,
    code,
    accountCodeDataType,
    isDetailSub = false,
  ) => {
    let formattedValue;
    if (isDetailSub) {
      formattedValue = `${value}`
        .replace(accountCodeRestrictions[accountCodeDataType], '')
        .toUpperCase();
    } else {
      formattedValue = `${value}`
        .replace(accountCodeRestrictions[DEFAULT_DATA_TYPE], '')
        .toUpperCase();
    }

    const { accountMask } = this.props;
    if (isDetailSub && accountMask) {
      formattedValue = value;
    }

    this.setState(({ accountCodeValues }) => {
      return {
        accountCodeValues: {
          ...accountCodeValues,
          [code]: {
            ...accountCodeValues[code],
            [accountCodeName]: formattedValue,
          },
        },
      };
    });
  };

  formatAccountName = (accountName, code) => {
    const accountCodeArray = accountName.split('_');
    accountCodeArray.splice(accountCodeArray.length - 1, 0, code);
    return accountCodeArray.join('_');
  };

  copyValues = code => {
    const { menuTitle, activeRow } = this.state;
    this.setState(({ accountCodeValues }) => {
      let copyValues;
      const update = {
        ...accountCodeValues,
      };
      if (menuTitle.includes('To')) {
        copyValues = accountCodeValues[activeRow] || {};
        update[code] = copyValues;
      }
      if (menuTitle.includes('From')) {
        copyValues = accountCodeValues[code] || {};
        update[activeRow] = copyValues;
      }
      return {
        accountCodeValues: update,
        copyMenuAnchor: null,
      };
    });
  };

  handleCancelButtonClick = () => {
    const { onClose } = this.props;
    const { pristineCodeValues } = this.state;
    onClose();
    this.setState({ accountCodeValues: pristineCodeValues });
  };

  // updates the account code values in the offer mutator
  updateOfferAccountCodes = (termsOfEmployment, closeAfterUpdate = true) => {
    const { accountCodeValues } = this.state;
    const {
      handleAccountCodeSubmit,
      onClose,
      isOpen,
      codeTypes = [],
      accountCodes = [],
      detailSubCode,
    } = this.props;
    const accountCodeSubmission = {};
    const errors = [];
    const detailSubObject =
      accountCodes.find(
        ac => ac.code === 'detail/sub' || ac.field_code === 'detail/sub',
      ) || {};
    const { name: detailSubName } = detailSubObject;
    Object.keys(accountCodeValues).forEach(code => {
      const lineItem =
        codeTypes
          .filter(this.filterCodeTypesDependingOnUnion)
          .find(c => c.code === code) || {};
      const hasUserValue = codeTranslation(lineItem.description)
        .map(key => termsOfEmployment[key])
        .filter(value => parseInt(value) > 0).length;

      // If there is no corresponding value in the terms of employment table we do not submit the account code
      Object.keys(accountCodeValues[code]).forEach(name => {
        const formattedKey = this.formatAccountName(name, code);
        const accountCode = accountCodes.find(ac => ac.name === name) || {};
        const codeValue = !hasUserValue
          ? ''
          : accountCodeValues[code][name] || '';
        // Check for empty or incomplete values for required fields that have corresponding values in the terms of employment table
        if (
          !`${codeValue}`.replace(/-/g, '').trim().length &&
          accountCode.is_required &&
          hasUserValue
        ) {
          errors.push(formattedKey);
          return;
        }
        // If it's a detail sub input we want to use the user input or the department code otherwise we just use the code value
        if (name === detailSubName && hasUserValue) {
          accountCodeSubmission[formattedKey] = codeValue || detailSubCode;
        } else {
          accountCodeSubmission[formattedKey] = codeValue;
        }
      });
    });
    if (errors.length && isOpen) {
      this.setState({ errors });
      return;
    }
    this.setState({ errors: [] });
    handleAccountCodeSubmit(accountCodeSubmission);
    if (closeAfterUpdate) onClose();
  };

  renderAccountRows = () => {
    const {
      accountCodes = [],
      termsOfEmployment,
      privileges = [],
      codeTypes = [],
      classes,
      offerStatus,
      isEditingOffer,
    } = this.props;

    const { accountCodeValues, errors, detailSubDataType } = this.state;
    const canEditAccountCodes = privileges.includes(
      'can_edit_offer_account_codes',
    );
    const canViewAccountCodes = privileges.includes(
      'can_view_offer_account_codes',
    );
    const isProcessed =
      processedStatuses.includes(offerStatus) && !isEditingOffer;

    return codeTypes
      .filter(this.filterCodeTypesDependingOnUnion)
      .map((codeType, index) => {
        const { description = '' } = codeType;
        let codeInputsLabels = [];
        const decimalFormat = termsOfEmploymentDecimalFormat[codeType.code];
        const rowClass = classnames(classes.row, {
          [classes.highlight]: index % 2 === 0,
        });
        let codeAmountValue =
          codeTranslation(description)
            .map(key => termsOfEmployment[key])
            .filter(value => !!value)[0] || '';
        const isValidAmount =
          !!parseFloat(codeAmountValue) && parseFloat(codeAmountValue) > 0;
        codeAmountValue = codeAmountValue
          ? createOfferCurrency(
              roundNumber(codeAmountValue || 0, decimalFormat),
              decimalFormat,
            )
          : codeAmountValue;
        const codeInputs = accountCodes
          .filter((ac, index, self) => {
            return index === self.findIndex(code => code.name === ac.name);
          })
          .map(accountCode => {
            const codeObject = accountCodeValues[codeType.code] || {};
            let value = codeObject[accountCode.name] || '';
            const key = this.formatAccountName(accountCode.name, codeType.code);
            const error = errors.includes(key);
            const translatedLabel = fieldNameTranslation(accountCode.label);
            const isRequired =
              accountCode.is_required && isValidAmount && canEditAccountCodes;
            const { accountMask } = this.props;
            const dataType =
              accountCode.code === 'detail/sub'
                ? detailSubDataType
                : accountCode.data_type;
            const isDetailSub = accountCode.code === 'detail/sub';
            const isDisabled =
              !isValidAmount || !canEditAccountCodes || isProcessed;

            // insurance field = 2 characters max;  alphanumerical only
            // everything else but detail/sub = 4 characters max; alphanumerical only
            // checked with the back end team, this accountCode.code should always be lowercased from the api
            const isInsuranceField = accountCode?.code.includes('insurance');
            const maxLength = isInsuranceField ? 2 : 4;

            // Push the label to 'codeInputsLabels'
            codeInputsLabels.push(
              <InputLabel className={classes.label}>
                {!accountMask && isDetailSub
                  ? translatedLabel + ' Default Detail/Sub'
                  : translatedLabel}
                {isRequired && <span className="field-required">*</span>}
              </InputLabel>,
            );

            return (
              <span
                data-test-id={`AccountCodeModal-field-${`${description}_${translatedLabel}`}`}
              >
                {isDetailSub && (
                  <AccountCodeMaskedInput
                    id={index}
                    disabled={isDisabled}
                    value={!isValidAmount || !canViewAccountCodes ? '' : value}
                    accountMask={accountMask}
                    onChange={ev =>
                      this.updateInput(
                        ev.target.value,
                        accountCode.name,
                        codeType.code,
                        dataType,
                        isDetailSub,
                      )
                    }
                    isRequired={error && !isDisabled}
                  />
                )}
                {!isDetailSub && (
                  <Field
                    data-test-id={`AccountCodeModal-Field-input-${index}`}
                    placeholder={createAPIMaskPlaceholder(
                      accountCode.account_mask,
                    )}
                    onChange={value =>
                      this.updateInput(
                        value,
                        accountCode.name,
                        codeType.code,
                        dataType,
                        isDetailSub,
                      )
                    }
                    value={!isValidAmount || !canViewAccountCodes ? '' : value}
                    key={`textmask-${key}`}
                    maxLength={maxLength}
                    disabled={isDisabled}
                    errors={
                      error && !isDisabled ? ['Account code required.'] : []
                    }
                    textMaskUppercase={true}
                  />
                )}
              </span>
            );
          });

        return (
          <div
            className={rowClass}
            key={`account-row-${codeType.code}-${description}`}
            data-test-id={`AccountCodeModal-row-${index}`}
          >
            <Typography
              className={classes.title}
              variant="subtitle1"
              data-test-id={`AccountCodeModal-title-${description}`}
            >
              {description.split('_').join(' ')}{' '}
              {codeAmountValue ? ` - ${codeAmountValue}` : ''}
            </Typography>
            <div className={classes.buttonContainer}>
              <Tooltip
                title="Copy Account Codes to another row"
                enterDelay={250}
              >
                <IconButton
                  className={classes.copyTo}
                  onClick={e => this.openCopyMenu(e, 'Copy To:', codeType.code)}
                  disabled={!canEditAccountCodes || isProcessed}
                  data-test-id={`AccountCodeModal-copyToButton-${codeType.code}`}
                >
                  <CopyToIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Copy Account Codes from another row"
                enterDelay={250}
              >
                <IconButton
                  className={classes.copyFrom}
                  onClick={e =>
                    this.openCopyMenu(e, 'Copy From:', codeType.code)
                  }
                  disabled={!canEditAccountCodes || isProcessed}
                  data-test-id={`AccountCodeModal-copyFromButton-${codeType.code}`}
                  classes={{
                    label: classes.copyFromLabel,
                  }}
                >
                  <CopyFromIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.labelContainer}>{codeInputsLabels}</div>
            {codeInputs}
          </div>
        );
      });
  };

  renderCopyMenu = () => {
    const { copyMenuAnchor, menuTitle, activeRow } = this.state;
    const { codeTypes, classes } = this.props;
    return (
      <Menu
        anchorEl={copyMenuAnchor}
        open={!!copyMenuAnchor}
        onClose={this.closeCopyMenu}
      >
        <ListItem className={classes.menuTitle}>{menuTitle}</ListItem>
        <Divider />
        {codeTypes
          .filter(this.filterCodeTypesDependingOnUnion)
          .filter(ct => ct.code !== activeRow)
          .map(codeType => {
            const { code, description = '' } = codeType;
            return (
              <MenuItem
                className={classes.menuItem}
                value={code}
                onClick={() => this.copyValues(code)}
                key={`menu-item-${codeType}-${description}`}
              >
                {description.split('_').join(' ')}
              </MenuItem>
            );
          })}
      </Menu>
    );
  };

  render() {
    const {
      accountCodes = [],
      classes,
      isOpen,
      onClose,
      offerStatus,
      isLoading,
      privileges = [],
      isEditingOffer,
      termsOfEmployment,
    } = this.props;
    const isProcessed =
      processedStatuses.includes(offerStatus) && !isEditingOffer;
    const accountTypeRows = this.renderAccountRows();
    const copyMenu = this.renderCopyMenu();
    const canEditAccountCodes = privileges.includes(
      'can_edit_offer_account_codes',
    );
    const canViewAccountCodes = privileges.includes(
      'can_view_offer_account_codes',
    );
    const displayAccountCodes =
      !isLoading && accountCodes.length > 0 && canViewAccountCodes;
    const displayEmptyMessage =
      (!isLoading && accountCodes.length === 0) || !canViewAccountCodes;
    const emptyDisplay = (
      <Typography>
        Either your project is not configured to use account codes or you have
        not been granted permissions to view account codes.
      </Typography>
    );
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="lg"
        data-test-id="AccountCodeModal-root"
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle}>
            Set Account Codes
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          {copyMenu}
          {isLoading && <Loader />}
          {displayAccountCodes && accountTypeRows}
          {displayEmptyMessage && emptyDisplay}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.handleCancelButtonClick()}
            data-test-id={'AccountCodeModal-cancelButton'}
          >
            Cancel
          </Button>
          {isProcessed ? (
            <Tooltip
              title="This offer's account codes cannot be changed because it has already been processed."
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button className={classes.saveButton}>Save Changes</Button>
            </Tooltip>
          ) : (
            <Button
              className={classes.saveButton}
              onClick={() => this.updateOfferAccountCodes(termsOfEmployment)}
              disabled={!canEditAccountCodes}
              data-test-id={'AccountCodeModal-saveButton'}
            >
              Save Changes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}
export default withStyles(styles)(AccountCodeModal);
