import React from 'react';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import classNames from 'class-names';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    color: '#3c9dd7',
  },
});

const AllowancesIcon = ({ className, classes }) => (
  <MoneyIcon className={classNames(classes.root, className)} />
);

export default withStyles(styles)(AllowancesIcon);
