import React from 'react';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import useSyncedState from 'common/hooks/useSyncedState';
import * as Mutations from 'common/apollo/mutations';
import Loader from 'common/components/Loader';
import StudioPlusFolderSelector from 'admin/components/StudioPlusFolderSelector';
import useProjectTemplateStudioPlusDirectory from './useProjectTemplateStudioPlusDirectory';

const styles = theme => ({
  container: {
    width: '100%',
    paddingTop: theme.spacing.unit * 3,
  },
  pathContainer: {
    marginTop: 12,
  },
});

const DocumentDestination = props => {
  const { classes, onSave, onSetGqlCallback, projectTemplateId } = props;

  const {
    data: { studioPlusDirectoryId, studioPlusIntegrationEnabled } = {},
    loading,
    refetch,
  } = useProjectTemplateStudioPlusDirectory({ projectTemplateId });
  const [selectedDirectoryId, setSelectedDirectoryId] = useSyncedState(
    studioPlusDirectoryId,
    studioPlusDirectoryId,
  );
  const defaultIsEnabled = !!(
    studioPlusIntegrationEnabled && selectedDirectoryId
  );
  const [isEnabled, setIsEnabled] = useSyncedState(
    defaultIsEnabled,
    defaultIsEnabled,
  );

  // Whenever the data changes, update the callback to fire the required mutation.
  // This callback will be called by the grandparent when the Save button is clicked.
  // This is not a good design but the project template page is a mess.
  const registerSaveCallback = (directoryId, isEnabled) =>
    onSetGqlCallback(async () => {
      await onSave({ directoryId, isEnabled });
      refetch();
    });

  if (loading) return <Loader />;

  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom>
        Studio+ Destination
      </Typography>
      <Typography variant="body1">
        This document will be sent to the following destination in Studio+.
      </Typography>
      <div className={classes.pathContainer}>
        <StudioPlusFolderSelector
          directoryId={selectedDirectoryId}
          onChangeDirectoryId={newDirectoryId => {
            setSelectedDirectoryId(newDirectoryId);
            setIsEnabled(!!newDirectoryId);
            registerSaveCallback(newDirectoryId ?? '', !!newDirectoryId);
          }}
        />
      </div>
      <div>
        <FormControlLabel
          label="Enabled"
          control={
            <Checkbox
              checked={isEnabled}
              onClick={() => {
                setIsEnabled(!isEnabled);
                registerSaveCallback(selectedDirectoryId, !isEnabled);
              }}
              disabled={!selectedDirectoryId}
              data-test-id="DocumentDestination-enabled"
            />
          }
        />
      </div>
    </div>
  );
};

const withUpdateProjectTemplate = graphql(Mutations.updateProjectTemplate, {
  props: ({ mutate, ownProps: { projectTemplateId } }) => ({
    onSave: ({ directoryId, isEnabled }) =>
      mutate({
        variables: {
          projectTemplateId: parseInt(projectTemplateId),
          studioPlusDirectoryId: directoryId,
          studioPlusIntegrationEnabled: isEnabled,
        },
      }),
  }),
});

export default compose(
  withStyles(styles),
  withUpdateProjectTemplate,
)(DocumentDestination);
