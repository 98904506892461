import React from 'react';

// MATERIAL
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreButton: {
    padding: '11px 16px',
    margin: '0 0 20px 0',
    background: '#FFFFFF',
    border: '1px solid #30ABA3',
    color: '#30ABA3',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
  },
  loadMoreMobileButton: {
    padding: '11px 16px',
    margin: '20px 0',
    background: '#FFFFFF',
    border: '1px solid #30ABA3',
    color: '#30ABA3',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  loadingIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    color: '#30ABA3',
  },
});

const FetchMoreProjects = props => {
  const {
    loadMoreProjects,
    searchQuery,
    projectsPageInfo,
    classes,
    isMobile = false,
    networkStatus,
  } = props;
  console.log('networkStatus', networkStatus);
  return (
    <div className={classes.buttonContainer}>
      <Button
        className={
          isMobile ? classes.loadMoreMobileButton : classes.loadMoreButton
        }
        data-test-id={`Projects-load-more-button`}
        onClick={() => {
          loadMoreProjects({
            variables: {
              keyword: searchQuery,
              after: projectsPageInfo.endCursor,
            },
            updateQuery: (prevResults, { fetchMoreResult }) => {
              const { projects: fetchMoreProject = {} } = fetchMoreResult || {};
              const {
                nodes: fetchMoreNodes = [],
                pageInfo: fetchMorePageInfo = {},
              } = fetchMoreProject || {};

              if (!fetchMoreNodes.length) return prevResults;

              return {
                projects: {
                  __typename: prevResults.projects.__typename,
                  nodes: [
                    ...prevResults.projects.nodes,
                    ...fetchMoreNodes.filter(
                      fetchMoreProjectId =>
                        !prevResults.projects.nodes.some(
                          prevResultsProjectId =>
                            prevResultsProjectId === fetchMoreProjectId,
                        ),
                    ),
                  ],
                  pageInfo: { ...fetchMorePageInfo },
                },
              };
            },
          });
        }}
      >
        Load More{' '}
        {networkStatus === 3 && (
          <CircularProgress size="sm" className={classes.loadingIcon} />
        )}
      </Button>
    </div>
  );
};

export default withStyles(styles)(FetchMoreProjects);
