import React from 'react';
import classNames from 'class-names';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    margin: '0 auto',
    padding: '40px 0',
    textAlign: 'center',
  },
  inline: {
    background: 'none',
    padding: 0,
  },
  bouncer: {
    animation: 'sk-bouncedelay 1.4s infinite ease-in-out both',
    borderRadius: '50%',
    background: '#2eaba4',
    display: 'inline-block',
    height: '18px',
    margin: '0 2px',
    verticalAlign: 'middle',
    width: '18px',
  },
  first: {
    animationDelay: '-0.32s',
  },
  second: {
    animationDelay: '-0.16s',
  },
  inlineBouncer: {
    height: '10px',
    width: '10px',
  },
  smallBouncer: {
    margin: '0',
    width: '8px',
    height: '8px',
  },
};
const Loader = ({ inline, size, classes }) => {
  const rootClass = classNames(
    {
      [classes.inline]: inline,
      [classes.small]: size === 'small',
    },
    classes.root,
  );

  return (
    <div className={rootClass} data-test-id="Loader-root">
      <div className={classNames(classes.bouncer, classes.first)} />
      <div className={classNames(classes.bouncer, classes.second)} />
      <div className={classNames(classes.bouncer)} />
    </div>
  );
};

export default withStyles(styles)(Loader);
