import React from 'react';

// Components
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaskedInput from 'react-text-mask';

// Constants
import { guaranteedHoursMask } from '../../helpers/textFieldNumberMasks';

const GuaranteedHours = ({
  onChange: upstreamOnChange,
  value,
  formKey,
  classes = {},
}) => {
  const onChange = ({ target: { value: rawValue } = {} } = {}) => {
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';

    upstreamOnChange({
      [formKey]: value,
    });
  };

  return (
    <TableCell align="center" className={classes.root}>
      <MaskedInput
        mask={guaranteedHoursMask}
        value={value || ''}
        onChange={onChange}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': `GuaranteedHours-input-${formKey}`,
              },
              startAdornment: (
                <InputAdornment position="start"> </InputAdornment>
              ),
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default GuaranteedHours;
