import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const styles = () => ({
  employeeNameHeader: {
    width: '20%',
  },
  departmentHeader: {
    width: '10%',
  },
  jobTitleHeader: {
    width: '10%',
  },
  unionHeader: {
    width: '10%',
  },
  startDateHeader: {
    width: '10%',
  },
  rateHeader: {
    width: '10%',
  },
  allowancesHeader: {
    width: '11%',
  },
  statusHeader: {
    width: '8%',
  },
  employeNameNoEndDateFix: {
    width: '30%',
  },
  departmentHeaderNoEndDateFix: {
    width: '12%',
  },
});

const ArchiveOfferTableHeader = ({ classes, endDateAllowed }) => (
  <ExpandableTableRow>
    <ExpandableTableCell
      className={
        !!endDateAllowed
          ? classes.employeeNameHeader
          : classes.employeNameNoEndDateFix
      }
      header={true}
      sort="last_name,first_name,middle_name"
    >
      Employee
    </ExpandableTableCell>
    <ExpandableTableCell
      className={
        !!endDateAllowed
          ? classes.departmentHeader
          : classes.departmentHeaderNoEndDateFix
      }
      header={true}
      sort="department"
    >
      Department
    </ExpandableTableCell>
    <ExpandableTableCell
      className={classes.jobTitleHeader}
      header
      sort="occupation"
    >
      Job Title/
      <br />
      Acct Code
    </ExpandableTableCell>
    <ExpandableTableCell
      className={classes.unionHeader}
      header={true}
      sort="union"
    >
      Union
    </ExpandableTableCell>
    <ExpandableTableCell
      className={classes.startDateHeader}
      header={true}
      sort="start_date"
    >
      Start Date
    </ExpandableTableCell>
    {endDateAllowed && (
      <ExpandableTableCell
        className={classes.startDateHeader}
        header={true}
        sort="end_date"
      >
        End Date
      </ExpandableTableCell>
    )}
    <ExpandableTableCell
      className={classes.rateHeader}
      header={true}
      sort="rate_per_hour_studio,rate_per_hour_location,rate_per_day_studio,rate_per_week_studio,rate_per_week_location"
    >
      Rate/Scale
    </ExpandableTableCell>
    <ExpandableTableCell className={classes.allowancesHeader} header={true}>
      Allowances / Reimbursements
    </ExpandableTableCell>
    <ExpandableTableCell
      className={classes.statusHeader}
      header={true}
      sort="status_description"
    >
      Status
    </ExpandableTableCell>
  </ExpandableTableRow>
);

export default withStyles(styles)(ArchiveOfferTableHeader);
