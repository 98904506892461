import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0 27px',
    },
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
  },
  reviewOfferCopy: {
    color: '#939393',
    fontSize: 16,
    fontWeight: 400,
    marginTop: 20,
  },
  beginBtn: {
    width: '95%',
    height: 50,
    backgroundColor: '#30aba3',
    color: '#f5f5f5',
    fontSize: 16,
    fontWeight: 500,
    marginTop: '30px',
    textTransform: 'none',
  },
  rejectOffer: {
    color: '#3c9dd7',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.5,
    textAlign: 'center',
    marginTop: 30,
  },
}));

const ReviewOffer = props => {
  const { onContinueToNextStep, onReject } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Review Your Offer</Typography>
      <Typography className={classes.reviewOfferCopy}>
        Take a look at the details of your offer on this page and click the
        button below when you're ready to accept.
      </Typography>
      <Button
        onClick={onContinueToNextStep}
        className={classes.beginBtn}
        data-test-id="ReviewOffer-begin"
      >
        Begin Start Packet Forms
      </Button>
      <Typography
        className={classes.rejectOffer}
        onClick={onReject}
        data-test-id="ReviewOffer-reject"
      >
        Reject offer & provide reason
      </Typography>
    </div>
  );
};

export default ReviewOffer;
