import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  cls1: {
    fill: 'none',
    stroke: '#419dcd',
    strokeLinecap: 'round',
    strokeWidth: '1.94px',
    strokeMiterlimit: '10',
  },
  cls2: {
    fill: 'none',
    stroke: '#419dcd',
    strokeLinecap: 'round',
    strokeWidth: '1.94px',
    strokeLinejoin: 'round',
  },
});

const Home = ({ classes, ...propsCatch }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47.94 43.48"
    {...propsCatch}
  >
    <title>home</title>
    <polyline
      className={classes.cls2}
      points="6.97 23.63 6.97 42.51 18.97 42.51 18.97 29.29 28.97 29.29 28.97 42.51 40.97 42.51 40.97 24.57"
    />
    <polyline
      className={classes.cls2}
      points="0.97 22.69 23.97 0.97 46.97 22.69"
    />
    <polyline
      className={classes.cls1}
      points="31.97 2.86 38.97 2.86 38.97 9.47"
    />
  </svg>
);

export default withStyles(styles)(Home);
