import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { withRouter } from 'react-router';

// Components
import AdminPage from './admins.js';
import PresetPage from './presets.js';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Box from 'common/oldJavascripts/components/Base/Box';

// MuiComponents
import { Paper, Tabs, Tab } from '@material-ui/core';

const styles = {
  topMargin: {
    marginTop: 16,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#30aba3',
  },
  container: {
    padding: 16,
  },
};

class GlobalPermissions extends React.Component {
  state = {
    activeTab: 0,
    showAdminUserForm: false,
    showPermissionPresetForm: false,
    permissionSelection: [],
  };

  handleTabChange = (event, value) => this.setState({ activeTab: value });

  componentDidMount() {
    const { match: { path } = {} } = this.props;

    if (path === '/global-permissions/permissions') {
      this.setState({ activeTab: 1 });
    }
    if (path === '/global-permissions/amins') {
      this.setState({ activeTab: 0 });
    }
  }

  render() {
    const { classes } = this.props;
    const { activeTab } = this.state;

    return (
      <LayoutContent>
        <Box>
          <Paper elevation={0}>
            <Tabs
              indicatorColor="secondary"
              textColor="secondary"
              onChange={this.handleTabChange}
              value={activeTab}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="Admin Users"
                data-test-id="GlobalPermissions-adminTab"
              />
              <Tab
                label="Permission Presets"
                data-test-id="GlobalPermissions-presetTab"
              />
            </Tabs>
          </Paper>

          <Paper elevation={0} className={classes.container}>
            {activeTab === 0 && <AdminPage />}
            {activeTab === 1 && <PresetPage />}
          </Paper>
        </Box>
      </LayoutContent>
    );
  }
}

export default compose(withRouter, withStyles(styles))(GlobalPermissions);
