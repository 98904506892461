import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { guaranteedHoursMask } from '../../helpers/textFieldNumberMasks';
import MaskedInput from 'react-text-mask';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 40,
    marginBottom: 40,
  },
  terms: {
    marginTop: 10,
  },
  innerCell: {
    borderTop: 'none',
  },
  cellTitle: {
    backgroundColor: '#f9fdff',
    color: '#30ABA3',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #D6EEED',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  radioContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  radioGroupCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottom: 'none',
  },
  dollar: {
    width: '10%',
    fontSize: 14,
    marginTop: 7,
    color: '#696969',
    paddingLeft: 14,
    fontFamily: 'Roboto, Helvetica, Helvetica Neue, san-serif',
  },
  tableSpacing: {
    marginTop: 16,
    marginBottom: 20,
    border: '1px solid #30ABA3',
  },
  topSpacing: {
    marginTop: 20,
  },
  hoursCopy: {
    marginTop: 7,
    paddingRight: 12,
    color: '#525252',
    fontSize: 16,
    fontWeight: 'normal',
  },
  errorIcon: {
    color: 'red',
    position: 'relative',
    top: 4,
    right: 8,
  },
  errorLabel: {
    color: 'red',
    fontSize: 14,
    textAlign: 'center',
  },
  warningIcon: {
    color: '#FFA800',
    position: 'relative',
    top: 4,
    right: 8,
  },
  warningLabel: {
    color: '#FFA800',
    fontSize: 14,
    textAlign: 'center',
    maxWidth: '65%',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hoursPerDay: {
    backgroundColor: '#f9fdff',
    color: '#30ABA3',
    fontSize: 14,
    fontWeight: 'bold',
  },
  rightCellArea: {
    width: '30%',
    backgroundColor: '#FFFFFF',
    color: '#30ABA3',
    fontSize: 14,
    fontWeight: 'bold',
    border: '2px solid #D6EEED',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
  },
  fieldArea: {
    width: '40%',
    backgroundColor: '#FFFFFF!important',
  },
  noTop: {
    borderTop: 'none',
  },
  inputSpacing: {
    marginLeft: '5%',
    marginRight: '10%',
  },
  label: {
    color: '#262626',
    fontSize: 16,
  },
}));
const STUDIO_DISTANT_BELOW_SCALE_ERROR_MSG = `Lower than contractual guarantee and/
or above 16 hours. Can’t create offer.`;
const BELOW_SCALE_ERROR_MSG =
  'Lower than contractual guarantee. Can’t create offer.';
const ISABOVE_SIXTEENHOURS_WARNINGMSG = 'You entered more than 16 hours';
const BELOW_SCALE_WARNING_MSG = `Lower than contractual guarantee
and/or above 16 hours.`;

const getHoursWarningMessage = (scalesInfo, isCNCSignatory, isReadOnly) => {
  if (isReadOnly) return;
  let warningMessage;
  if (scalesInfo.isAboveSixteenHours) {
    warningMessage = ISABOVE_SIXTEENHOURS_WARNINGMSG;
  } else if (
    (scalesInfo.isBelowScale && !isCNCSignatory) ||
    (scalesInfo.isAboveSixteenHours && !isCNCSignatory)
  ) {
    warningMessage = BELOW_SCALE_WARNING_MSG;
  }
  return warningMessage;
};

const getErrorMessage = (formErrors = {}, scaleStatesInfo, isCNCSignatory) => {
  let errorMsg;
  if (formErrors?.guaranteedHours) {
    errorMsg = formErrors?.guaranteedHours;
  } else if (scaleStatesInfo.isBelowScale && isCNCSignatory) {
    errorMsg = BELOW_SCALE_ERROR_MSG;
  } else if (
    scaleStatesInfo.isBelowScale &&
    isCNCSignatory &&
    scaleStatesInfo.isAboveSixteenHours
  ) {
    errorMsg = STUDIO_DISTANT_BELOW_SCALE_ERROR_MSG;
  }
  return errorMsg;
};

const scaleStates = (
  studioHourly,
  distantHourly,
  guaranteedHoursScaleStudio,
  guaranteedHoursScaleDistant,
  isCNCSignatory,
) => {
  const otherScaleStates = {
    isAboveSixteenHours: studioHourly > 16 || distantHourly > 16,
    isStudioHourlyBelowScale: studioHourly < guaranteedHoursScaleStudio,
    isDistantHourlyBelowScale: distantHourly < guaranteedHoursScaleDistant,
    isStudioHoursEqualScale: studioHourly === guaranteedHoursScaleStudio,
    isDistantHoursEqualScale: distantHourly === guaranteedHoursScaleDistant,
    isStudioAboveScale: studioHourly > guaranteedHoursScaleStudio,
    isDistantAboveScale: distantHourly > guaranteedHoursScaleDistant,
    isZero:
      guaranteedHoursScaleStudio === 0 || guaranteedHoursScaleDistant === 0,
  };
  const belowScale =
    otherScaleStates.isStudioHourlyBelowScale ||
    otherScaleStates.isDistantHourlyBelowScale;
  return {
    isBelowScale: belowScale,
    isEqualScale:
      otherScaleStates.isStudioHoursEqualScale ||
      otherScaleStates.isDistantHoursEqualScale,
    isEmpty:
      !guaranteedHoursScaleStudio ||
      !guaranteedHoursScaleDistant ||
      otherScaleStates.isZero,
    isAboveScale:
      otherScaleStates.isStudioAboveScale ||
      otherScaleStates.isDistantAboveScale,
    ...otherScaleStates,
  };
};

const InputField = props => {
  const classes = useStyles();
  const {
    guaranteedHours,
    guaranteedHoursDistant,
    guaranteedHoursScaleStudio,
    guaranteedHoursScaleDistant,
    onChange: upstreamOnChange,
    handleSixthSeventhDay,
    isCNCSignatory,
    formErrors,
    isReadOnly,
  } = props;
  const [studioHourly, setStudioHourly] = useState(guaranteedHours);
  const [distantHourly, setDistantHourly] = useState(guaranteedHoursDistant);

  useEffect(() => {
    setStudioHourly(guaranteedHours);
    setDistantHourly(guaranteedHoursDistant);
  }, [guaranteedHours, guaranteedHoursDistant]);

  const scaleStatesInfo = scaleStates(
    studioHourly,
    distantHourly,
    guaranteedHoursScaleStudio,
    guaranteedHoursScaleDistant,
    isCNCSignatory,
  );
  const warningMessage = getHoursWarningMessage(
    scaleStatesInfo,
    isCNCSignatory,
    isReadOnly,
  );

  const isOpenSixthSeventhDay =
    (!scaleStatesInfo.isBelowScale && !scaleStatesInfo.isEqualScale) ||
    scaleStatesInfo.isAboveScale ||
    scaleStatesInfo.isEmpty;
  handleSixthSeventhDay(isOpenSixthSeventhDay);
  const errorMessage = getErrorMessage(
    formErrors,
    scaleStatesInfo,
    isCNCSignatory,
  );

  const getTypedValue = (ev = {}, formKey) => {
    const { target: { value: rawValue } = {} } = ev;
    let value = rawValue;
    if (rawValue === '' || rawValue === '0') value = '';
    return parseFloat(value);
  };

  const onChangeStudio = ev => {
    const typedValue = getTypedValue(ev);
    let value = typedValue > 0 ? typedValue : null;
    setStudioHourly(value);
  };

  const onChangeDistant = ev => {
    const typedValue = getTypedValue(ev);
    let value = typedValue > 0 ? typedValue : null;
    setDistantHourly(value);
  };

  const onBlurStudio = ev => {
    let blurValue;
    const typedValue = getTypedValue(ev);
    if (studioHourly) {
      blurValue = studioHourly;
      upstreamOnChange({
        guaranteedHours: blurValue,
      });
    }

    if (studioHourly && !distantHourly) {
      blurValue = studioHourly;
      upstreamOnChange({
        guaranteedHoursDistant: blurValue,
      });
    }

    if ((!typedValue && distantHourly) || (!studioHourly && distantHourly)) {
      blurValue = distantHourly;
      upstreamOnChange({
        guaranteedHours: blurValue,
      });
    }
    setStudioHourly(blurValue);
    setTableError();
  };

  const onBlurDistant = ev => {
    let blurValue;
    const typedValue = getTypedValue(ev);
    if (distantHourly) {
      blurValue = distantHourly;
      upstreamOnChange({
        guaranteedHoursDistant: blurValue,
      });
    }

    if (distantHourly && !studioHourly) {
      blurValue = distantHourly;
      upstreamOnChange({
        guaranteedHours: blurValue,
      });
    }

    if ((!typedValue && studioHourly) || (!distantHourly && studioHourly)) {
      blurValue = studioHourly;
      upstreamOnChange({
        guaranteedHoursDistant: blurValue,
      });
    }
    setDistantHourly(blurValue);
    setTableError();
  };

  const setTableError = () => {
    const errorMsg = getErrorMessage(
      formErrors,
      scaleStatesInfo,
      isCNCSignatory,
    );

    upstreamOnChange({
      tableErrors: {
        isGuaranteedHoursBelowScale: errorMsg === BELOW_SCALE_ERROR_MSG,
      },
    });
  };

  const isWarning = warningMessage && !errorMessage;

  return (
    <>
      <TableRow>
        <TableCell className={classes.cellTitle}>
          <div className={classes.errorContainer}>
            <Typography
              className={classes.hoursPerDay}
              data-test-id="hoursperday-row-title"
            >
              Hours Per Day
            </Typography>
            {isWarning && (
              <Typography
                color="warning"
                variant="caption"
                className={classes.warningLabel}
                data-test-id="hoursperday-InputField-warning-message"
              >
                <WarningIcon className={classes.warningIcon} />
                {warningMessage}
              </Typography>
            )}
            {errorMessage && (
              <Typography
                color="warning"
                variant="caption"
                className={classes.errorLabel}
                data-test-id="hoursperday-InputField-error-message"
              >
                <WarningIcon className={classes.errorIcon} />
                {errorMessage}
              </Typography>
            )}
          </div>
        </TableCell>
        <TableCell className={classes.rightCellArea}>
          <div className={classes.itemContainer}>
            <Typography className={classes.dollar} />
            <MaskedInput
              mask={guaranteedHoursMask}
              value={studioHourly || ''}
              onChange={onChangeStudio}
              onBlur={onBlurStudio}
              className={classes.inputSpacing}
              render={(ref, props) => (
                <TextField
                  inputRef={ref}
                  {...props}
                  disabled={isReadOnly}
                  InputProps={{
                    inputProps: {
                      'data-test-id': `union-hours-per-day-Studio-input`,
                    },
                  }}
                />
              )}
            />
            <Typography className={classes.hoursCopy}>
              <span>hours</span>
            </Typography>
          </div>
        </TableCell>
        <TableCell className={classes.rightCellArea}>
          <div className={classes.itemContainer}>
            <Typography className={classes.dollar} />
            <MaskedInput
              mask={guaranteedHoursMask}
              value={distantHourly || ''}
              onChange={onChangeDistant}
              onBlur={onBlurDistant}
              className={classes.inputSpacing}
              render={(ref, props) => (
                <TextField
                  inputRef={ref}
                  {...props}
                  disabled={isReadOnly}
                  InputProps={{
                    inputProps: {
                      'data-test-id': `union-hours-per-day-Distant-input`,
                    },
                  }}
                />
              )}
            />
            <Typography className={classes.hoursCopy}>
              <span>hours</span>
            </Typography>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default InputField;
