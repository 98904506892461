import React, { Component } from 'react';

// HoC
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

// Components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Loader from 'common/components/Loader';

// Utilities
import history from 'common/constants/config/history';
import validateName from 'common/utilities/validateName';
import validateEmail from 'common/utilities/validateEmail';
import classNames from 'classnames';

// Colors
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const styles = {
  root: {
    display: 'grid',
    width: '100%',
    height: 'calc(100% - 120px)',
    gridTemplateColumns: '100%',
    gridTemplateRows: '25px calc(100% - 25px)',
    // placeItems: 'center',
    justifyItems: 'center',
    gridTemplateAreas: `
    "....."
    "paper"`,
  },
  paper: {
    gridArea: 'paper',
    display: 'grid',
    height: '550px',
    width: '460px',
    gridTemplateColumns: '15% 35% 35% 15%',
    gridTemplateRows: '5% 15% 50% 20% 10%',
    gridTemplateAreas: `
    ". ...... ...... ."
    ". header header ."
    ". form   form   ."
    ". cancel submit ."
    ". ...... ...... ."`,
    minHeight: 510,
  },
  header: {
    gridArea: 'header',
    placeSelf: 'center',
  },
  form: {
    gridArea: 'form',
    display: 'grid',
  },
  cancel: {
    gridArea: 'cancel',
    height: '48px',
    width: '141px',
    placeSelf: 'center',
  },
  submitWrapper: {
    gridArea: 'submit',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    gridTemplateAreas: `
    "submitButton"`,
    height: '48px',
    width: '141px',
    placeSelf: 'center',
  },
  underline: {
    '&:before': {
      borderBottomColor: '#3c9dd7',
    },
    '&:after': {
      borderBottomColor: '#30aba3',
    },
  },
  errorUnderline: {
    '&:before': {
      borderBottomColor: '#f44336',
    },
    '&:after': {
      borderBottomColor: '#f44336',
    },
  },
  errorLabel: {
    color: '#f44336',
  },
  submitButton: {
    gridArea: 'submitButton',
  },
  submitSuccess: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  submitFailure: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  submitNormalState: {
    color: 'white',
    backgroundColor: '#3c9dd7',
    '&:hover': {
      backgroundColor: '#59acdd',
    },
  },
  submitDisabled: {
    backgroundColor: '#59acdd52 !important',
    color: 'white !important',
  },
  submitProgress: {
    color: green[500],
    gridArea: 'submitButton',
  },
};

class AddCrewMember extends Component {
  static mutators = {
    crew: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: '/projects/' + params.projectId + '/crew',
          create: true,
        };
      },

      stale: function(_, related) {
        var params = related['/router/params'];

        return ['/projects/' + params.projectId + '/crew'];
      },
    },
  };

  state = {
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    emailValid: null,
    submitInProgress: false,
    submitSuccess: false,
    submitFailure: false,
    error: '',
  };

  handleEmailKeyPress = e => {
    this.setState({
      submitInProgress: false,
      submitSuccess: false,
      submitFailure: false,
    });
    const { key } = e || {};
    if (key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      this.handleSubmit();
    }
  };

  handleChange = key => value => this.setState({ [key]: value });

  emailOnChange = e => {
    const { emailValid } = this.state;
    const { target: { value: email } = {} } = e;
    this.setState({ email });
    // Only check to correct the validation while typing
    // dont try to create a validation error while typing
    if (!emailValid && validateEmail(email))
      this.setState({ emailValid: validateEmail(email) });
  };

  checkEmailValidation = () => {
    const { email } = this.state;
    this.setState({ emailValid: validateEmail(email) });
  };

  handleSubmit = () => {
    const {
      firstName,
      middleName,
      lastName,
      email,
      submitSuccess,
    } = this.state;
    const { crew: crewMutator = {} } = this.props;
    // Dont send off the request if the email is not valid
    if (!validateEmail(email) || submitSuccess) return;
    this.setState({
      submitInProgress: true,
      submitSuccess: false,
      submitFailure: false,
    });
    crewMutator.updateAndSave(
      {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        email,
      },
      'new',
      {},
      () => {
        this.setState({ submitSuccess: true, submitInProgress: false });
        setTimeout(this.goBack, 400);
      },
      () => {
        this.setState({ submitInProgress: false, submitFailure: true });
      },
    );
  };

  goBack = () => {
    this.setState({
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      emailValid: null,
      submitInProgress: false,
      submitSuccess: false,
      submitFailure: false,
    });
    const { routerParams = {} } = this.props;
    const { projectId } = routerParams || {};
    history.push(`/projects/${projectId}/crew`);
  };

  render() {
    const { crew: crewMutator = {}, classes = {} } = this.props;
    const { errors = {} } = crewMutator || {};
    const {
      first_name: firstNameError,
      middle_name: middleNameError,
      last_name: lastNameError,
      email: emailError,
    } = errors || {};
    const {
      firstName,
      lastName,
      middleName,
      email,
      emailValid,
      submitInProgress,
      submitSuccess,
      submitFailure,
    } = this.state;

    const submitEnabled = () =>
      !!firstName && !!lastName && emailValid === true;

    const submitButtonClasses = classNames(
      {
        [classes.submitInProgress]: submitInProgress,
        [classes.submitSuccess]: submitSuccess,
        [classes.submitFailure]: submitFailure,
        [classes.submitNormalState]:
          !submitInProgress && !submitSuccess && !submitFailure,
      },
      classes.submitButton,
    );
    const isError =
      submitFailure &&
      (firstNameError || lastNameError || middleNameError || emailError);

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography
            className={classes.header}
            variant="h4"
            data-test-id="AddCrewMember-title"
          >
            Add Crew Member
          </Typography>
          <form className={classes.form} noValidate autoComplete="off">
            <TextField
              id="first-name"
              label="First Name"
              error={submitFailure && firstNameError}
              className={classes.textField}
              value={firstName}
              onChange={({ target: { value } = {} }) =>
                this.handleChange('firstName')(validateName(value))
              }
              margin="normal"
              required
              InputProps={{
                className: firstNameError
                  ? classes.errorUnderline
                  : classes.underline,
              }}
              InputLabelProps={{
                className: firstNameError ? classes.errorLabel : '',
              }}
              data-test-id="AddCrewMember-firstName"
            />
            <TextField
              id="middle-name"
              label="Middle Name"
              error={submitFailure && middleNameError}
              className={classes.textField}
              value={middleName}
              onChange={({ target: { value } = {} }) =>
                this.handleChange('middleName')(validateName(value))
              }
              margin="normal"
              InputProps={{
                className: middleNameError
                  ? classes.errorUnderline
                  : classes.underline,
              }}
              InputLabelProps={{
                className: middleNameError ? classes.errorLabel : '',
              }}
              data-test-id="AddCrewMember-middleName"
            />
            <TextField
              id="last-name"
              label="Last Name"
              error={submitFailure && lastNameError}
              className={classes.textField}
              value={lastName}
              onChange={({ target: { value } = {} }) =>
                this.handleChange('lastName')(validateName(value))
              }
              margin="normal"
              required
              InputProps={{
                className: lastNameError
                  ? classes.errorUnderline
                  : classes.underline,
              }}
              InputLabelProps={{
                className: lastNameError ? classes.errorLabel : '',
              }}
              data-test-id="AddCrewMember-lastName"
            />
            <TextField
              value={email}
              error={submitFailure && (emailValid === false || emailError)}
              onChange={this.emailOnChange}
              onBlur={this.checkEmailValidation}
              onKeyPress={this.handleEmailKeyPress}
              className={classNames(classes.textField, classes.email)}
              data-test-id="AddCrewMember-email"
              label="Personal Email"
              InputProps={{
                className:
                  emailValid === false
                    ? classes.errorUnderline
                    : classes.underline,
              }}
              InputLabelProps={{
                className: emailValid === false ? classes.errorLabel : '',
              }}
              margin="normal"
              required
            />
            {isError && (
              <Typography
                color="error"
                variant="caption"
                className={classes.errorLabel}
              >
                {firstNameError ||
                  lastNameError ||
                  middleNameError ||
                  emailError}
              </Typography>
            )}
          </form>
          <div className={classes.submitWrapper}>
            <Button
              className={submitButtonClasses}
              disabled={!submitEnabled() || submitInProgress}
              onClick={this.handleSubmit}
              variant="contained"
              classes={{
                disabled: classes.submitDisabled,
              }}
              data-test-id="AddCrewMember-submit"
            >
              Submit
            </Button>
            {submitInProgress && (
              <Loader size={24} className={classes.submitProgress} />
            )}
          </div>
          <Button
            variant="contained"
            className={classes.cancel}
            onClick={this.goBack}
          >
            Cancel
          </Button>
        </Paper>
      </div>
    );
  }
}

export default compose(withApi, withStyles(styles))(AddCrewMember);
