import { parse } from 'date-fns';
import updateFieldLayout from 'mobile/components/DocumentSigner/DocumentViewer/updateFieldLayout';
import * as fieldTypes from 'mobile/components/DocumentSigner/utilities/constants/fieldTypes';
import isFilledIn from 'mobile/components/DocumentSigner/utilities/isFilledIn';

const KINDS = {
  [fieldTypes.CHK]: 'checkbox',
  [fieldTypes.CMB]: 'select',
  [fieldTypes.DATE]: 'date',
  [fieldTypes.DATE_SIGNED]: 'timestamp',
  [fieldTypes.INITIAL_SIGNATURE]: 'signature',
  [fieldTypes.NUMBER]: 'number',
  [fieldTypes.RDO]: 'radio',
  [fieldTypes.SIGNATURE]: 'signature',
  [fieldTypes.TXT]: 'text',
  [fieldTypes.UNVALIDATED_INITIAL_SIGNATURE]: 'signature',
  [fieldTypes.UNVALIDATED_SIGNATURE]: 'signature',
};

const convertDateFormat = startPlusDateFormat => {
  if (!startPlusDateFormat) return 'MM/dd/yyyy';

  // The date format used by the API is not consistent with the unicode standard.
  // Currently supported API formats are mm/dd/yyyy and mm/dd/yy. However the
  // unicode standard uses mm for minute - MM is the correct string for month.
  // https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  return startPlusDateFormat.replace('mm', 'MM');
};

// Convert the Start+ representation of a field to the document-signer-react
// representation of an input.
const mapStartPlusFieldToDsrInput = (field, currentDocument) => {
  // Transform the nonsensical PDF coords returned by the API to coordinates
  // representing the field rectangle on the actual page image.
  field = updateFieldLayout(
    {
      originalDimensions: {
        height: currentDocument.height,
        width: currentDocument.width,
      },
      updatedDimensions: {
        height: currentDocument.images[0].height,
        width: currentDocument.images[0].width,
      },
    },
    field,
  );

  const {
    id,
    value: startPlusValue,
    _derived: { error, isDisabled, isRequired, fieldType },
  } = field;

  // Figure out the kind of DSR input
  const kind = KINDS[fieldType];
  if (!kind) {
    throw new Error('Unsupported fieldType encountered.');
  }

  // Figure out the DSR input status
  const status = (() => {
    if (error) return 'error';
    if (isDisabled) return 'disabled';
    if (isRequired) {
      if (isFilledIn(startPlusValue)) return 'success';
      return 'required';
    }
    return 'optional';
  })();

  // Handle radio inputs because they're different
  if (kind === 'radio') {
    return field.radioGroupItems.map(radio => {
      const { height, name: radioName, page, width, x: left, y: top } = radio;
      return {
        height,
        id: `${id}/${radioName}`,
        kind,
        left,
        page,
        status,
        top,
        value: radioName === startPlusValue,
        width,
      };
    });
  }

  // Handle all the other types of input
  const {
    format: startPlusDateFormat,
    height,
    max: maxLength,
    options: optionsObject,
    page,
    width,
    x: left,
    y: top,
  } = field;
  const options =
    optionsObject &&
    Object.entries(optionsObject).map(([value, description]) => ({
      value,
      description,
    }));
  const isInitials =
    fieldType === fieldTypes.INITIAL_SIGNATURE ||
    fieldType === fieldTypes.UNVALIDATED_INITIAL_SIGNATURE;
  const isValidated =
    fieldType === fieldTypes.SIGNATURE ||
    fieldType === fieldTypes.INITIAL_SIGNATURE;
  const isFieldADate = kind === 'timestamp' || kind === 'date';
  const dateFormat = isFieldADate
    ? convertDateFormat(startPlusDateFormat)
    : undefined;
  const value =
    isFieldADate && startPlusValue
      ? parse(startPlusValue, dateFormat, new Date())
      : startPlusValue;

  return {
    dateFormat,
    height,
    id,
    isInitials,
    isValidated,
    kind,
    left,
    maxLength,
    options,
    page,
    status,
    top,
    value,
    width,
  };
};

export default mapStartPlusFieldToDsrInput;
