import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Utilities
import classNames from 'classnames';

// MuiComponents
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  fullScreenRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    flexDirection: 'column',
    background: '#f2f5f7',
  },
  progress: {
    padding: 15,
    boxSizing: 'content-box',
  },
  message: {
    padding: 15,
  },
});

const Loader = props => {
  const {
    color = '#30aba3',
    variant,
    value,
    thickness,
    message,
    messageProps = {},
    className,
    classes,
    fullScreen = false,
    size = 40,
  } = props;

  const circularProgressProps = { variant, value, thickness, size };
  // Pull the className out to combine with our className
  const { className: messageClassName } = messageProps;
  let updatedMessageProps = { ...messageProps };
  delete updatedMessageProps.className;

  const rootClass = classNames(
    {
      [classes.root]: !fullScreen,
      [classes.fullScreenRoot]: fullScreen,
    },
    className,
  );

  return (
    <div className={rootClass} data-test-id={`Loader-root`}>
      <CircularProgress
        className={classes.progress}
        style={{ color }}
        {...circularProgressProps}
      />
      {message && (
        <Typography
          className={classNames(classes.message, messageClassName)}
          variant={'caption'}
          data-test-id={`Loader-message`}
          {...updatedMessageProps}
        >
          {message}
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(Loader);
