export default {
  ruleType: {
    key: 'ruleType',
    label: 'Rule Type',
    section: ['type'],
    reset: [
      'department',
      'union',
      'occupation',
      'schedule',
      'hireState',
      'workState',
      'workCity',
    ],
  },
  department: {
    key: 'department',
    label: 'Department',
    section: ['department', 'combo'],
    reset: [],
  },
  union: {
    key: 'union',
    label: 'Union',
    section: ['union', 'combo'],
    reset: ['occupation', 'schedule'],
  },
  occupation: {
    key: 'occupation',
    label: 'Occupation',
    section: ['union', 'combo'],
    requirePrevious: true,
    reset: ['schedule'],
  },
  schedule: {
    key: 'schedule',
    label: 'Schedule',
    section: ['union', 'combo'],
    requirePrevious: true,
    reset: [],
  },
  hireState: {
    key: 'hireState',
    label: 'Hire State',
    section: ['location', 'combo'],
    reset: [],
  },
  workState: {
    key: 'workState',
    label: 'Work State',
    section: ['location', 'combo'],
    reset: ['workCity'],
  },
  workCity: {
    key: 'workCity',
    label: 'Work City',
    section: ['location', 'combo'],
    requirePrevious: true,
    reset: [],
  },
};
