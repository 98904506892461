import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  MoreVert as DropdownIcon,
} from '@material-ui/icons';
import Select from 'react-select';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import ProjectTemplateWorkflowApproversSelector from './ProjectTemplateWorkflowApproversSelector';

const styles = theme => ({
  level: {
    width: 250,
    height: '100%',
    flexShrink: 0,
    overflowY: 'scroll',
  },
});

const ProjectTemplateWorkflowLevel = ({
  classes,
  index,
  isTemplateI9,
  level,
  onChange,
  onDelete,
  templateRoles,
}) => {
  const { name, role, users } = level;
  const roleOptions = templateRoles.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
  const selectedRoleOption = role && {
    value: role.id,
    label: role.name,
  };
  const popupState = usePopupState({ variant: 'popover' });
  const [isApproversDialogOpen, setIsApproversDialogOpen] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);

  const openApproversDialog = () => {
    popupState.close();
    setIsApproversDialogOpen(true);
  };

  const updateApprovers = users => onChange({ ...level, users });

  const updateSelectRole = selectOption => {
    const { value: id, label: name } = selectOption;
    const role = { id, name };
    onChange({ ...level, role });
  };

  const updateName = name => onChange({ ...level, name });

  const unselectApprover = userId =>
    updateApprovers(users.filter(({ id }) => id !== userId));

  const deleteLevel = () => {
    popupState.close();
    onDelete();
  };

  return (
    <Card
      className={classes.level}
      data-test-id={`ProjectTemplateWorkflowLevel-root-${index}`}
    >
      <CardHeader
        title={
          isEditingName ? (
            <TextField
              autoFocus
              value={name}
              onBlur={() => setIsEditingName(false)}
              onChange={({ target: { value } }) => updateName(value)}
              data-test-id="ProjectTemplateWorkflowLevel-name"
            />
          ) : (
            <span
              onClick={() => setIsEditingName(true)}
              data-test-id="ProjectTemplateWorkflowLevel-name"
            >
              {name}
            </span>
          )
        }
        action={
          <React.Fragment>
            <IconButton
              aria-label="settings"
              data-test-id="ProjectTemplateWorkflowLevel-menu"
              {...bindTrigger(popupState)}
            >
              <DropdownIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={openApproversDialog}>Edit Approvers</MenuItem>
              <MenuItem onClick={deleteLevel}>Remove Level</MenuItem>
            </Menu>
            {isApproversDialogOpen && (
              <ProjectTemplateWorkflowApproversSelector
                isTemplateI9={isTemplateI9}
                open={isApproversDialogOpen}
                onClose={() => setIsApproversDialogOpen(false)}
                onSubmit={updateApprovers}
                defaultSelectedApproverIds={users.map(({ id }) => id)}
              />
            )}
          </React.Fragment>
        }
      />
      <CardContent>
        <span data-test-id="ProjectTemplateWorkflowLevel-role">
          <Select
            options={roleOptions}
            isClearable={false}
            value={selectedRoleOption}
            onChange={updateSelectRole}
            fullWidth
          />
        </span>
        <List>
          {users
            .filter(user => !!user)
            .map(user => (
              <React.Fragment key={user.id}>
                <ListItem
                  data-test-id={`ProjectTemplateWorkflowLevel-user-${user.id}`}
                >
                  <ListItemText
                    primary={`${user.profile.firstName} ${user.profile.lastName}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={users.length < 2}
                      onClick={() => unselectApprover(user.id)}
                      data-test-id={`ProjectTemplateWorkflowLevel-deleteUser-${user.id}`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ProjectTemplateWorkflowLevel);
