import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ExpansionPanelActions, Typography, Button } from '@material-ui/core';
import palette, {
  success as paletteSuccess,
  successHover as paletteSuccessHover,
} from 'common/shared/oldDocumentSignerUI/palette';
import ProgressSpinner from 'common/components/ProgressSpinner';

const styles = theme => ({
  error: {
    color: theme.palette.error.main,
  },
  saveButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    position: 'relative',
  },
  button: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    textTransform: 'capitalize',
    color: 'white',
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
  buttonSuccess: {
    backgroundColor: paletteSuccess,
    '&:hover': {
      backgroundColor: paletteSuccessHover,
    },
  },
});

const DocumentFooter = props => {
  const {
    classes,
    error,
    id,
    isSaving,
    saveSuccess,
    supportDocument,
    updateDoc,
    handleSubmit,
  } = props;

  const submit = () => {
    // If supporting document is invalid, delete it before saving
    if (supportDocument) {
      if (!supportDocument.name) {
        // Delete support document
        updateDoc({ id, key: 'supportDocument', value: null });
      }
    }
    handleSubmit(id);
  };

  return (
    <ExpansionPanelActions>
      <Typography className={classes.error}>{error}</Typography>
      <div className={classes.saveButtonContainer}>
        <Button
          onClick={submit}
          color="primary"
          variant="contained"
          className={classNames(
            { [classes.buttonSuccess]: saveSuccess },
            classes.button,
          )}
          disabled={isSaving}
          data-test-id={`DocumentFooter-save-${id}`}
        >
          Save
        </Button>
        {isSaving && <ProgressSpinner size={24} top={6} left={20} />}
      </div>
    </ExpansionPanelActions>
  );
};

export default withStyles(styles)(DocumentFooter);
