import React, { Component } from 'react';
import Link from 'common/oldJavascripts/components/Base/Link';
import Icon from 'common/oldJavascripts/components/Base/Icon';
import List from 'common/oldJavascripts/components/Base/List';
import Preloader from 'common/oldJavascripts/components/Shared/Preloader';
import ToolTipBox from 'common/oldJavascripts/components/Shared/ToolTipBox';
import Button from '@material-ui/core/Button';
import {
  ListAlt as FileIcon,
  CloudDownload as DownloadIcon,
} from '@material-ui/icons';
// HoC
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import ajax from 'common/utilities/ajax';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import * as Sentry from '@sentry/browser';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { connect } from 'react-redux';

const styles = {
  fileButton: {
    color: '#979ca5',
    fontWeight: 'bold',
    fontSize: '1rem',
    textTransform: 'none',
    '&:hover': {
      color: '#646464',
      backgroundColor: 'white',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
};

class FileItem extends Component {
  static queries = {
    file: {
      info: function(params, related) {
        var routerParams = related['/router/params'];

        return {
          id: `/v2/offers/${routerParams.offerId}/files/${params.id}`,
        };
      },
    },
  };

  _download = () => {
    const {
      routerParams: params,
      file: fileQuery,
      pushNotification,
      popNotification,
    } = this.props;
    const { data: file } = fileQuery;

    if (file.can_download === false) {
      return false;
    }
    if (fileQuery.status === 'loading') {
      return <Preloader />;
    }

    const { projectId, offerId } = params;
    const url = `/v2/projects/${projectId}/offers/${offerId}/files/${file.id}/download`;
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(url)
      .then(response => {
        Sentry.addBreadcrumb({ response });
        const { path = '' } = response;
        if (!path)
          throw new Error(
            'No path received when requesting document for download.',
          );
        popNotification();
        pushNotification({
          message: 'Your download has started.',
          variant: SnackbarVariants.SUCCESS,
        });
        window.location.href = path;
      })
      .catch(err => {
        popNotification();
        pushNotification({
          message:
            'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.',
          variant: SnackbarVariants.ERROR,
        });
        Sentry.captureException(err);
      });
  };

  _renderDisableIcon = () => {
    if (this.props.file.data.can_download === false) {
      return <Icon name="fa-ban" className="fa-stack-2x" />;
    }
  };

  render() {
    const { classes, file: fileQuery } = this.props;
    const file = fileQuery.data;

    if (fileQuery.status === 'loading') {
      return <Preloader />;
    }
    var download = null;
    const label = file.can_download
      ? 'Download'
      : 'Preparing final approved document and it will be available shortly for download.';
    if (file.can_download) {
      download = (
        <Link icon onClick={this._download}>
          <span className="fa-stack">
            {this._renderDisableIcon()}
            <DownloadIcon />
          </span>
        </Link>
      );
    } else {
      download = (
        <ToolTipBox align="top" tooltipNode={label} bold={true}>
          <span>Processing...</span>
        </ToolTipBox>
      );
    }

    return (
      <List.Item>
        <List.ItemContent className={classes.content}>
          <List.ItemContentBody title={file.filename}>
            <Button
              className={classes.fileButton}
              variant="text"
              disableRipple
              onClick={this._download}
              disabled={file.can_download === false}
            >
              <FileIcon /> {file.name}
            </Button>
          </List.ItemContentBody>
          <List.ItemNav>{download}</List.ItemNav>
        </List.ItemContent>
      </List.Item>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

export default compose(
  withApi,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(FileItem);
