import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useIsMobile from 'common/hooks/useIsMobile';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '782px',
      minheight: '407px',
      padding: 9,
    },
  },
  grid: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '55% 43%',
      gridTemplateRows: '55% 43%',
      columnGap: 20,
      rowGap: '15px',
    },
  },
  header: {
    color: '#5b5b5b',
    fontWeight: 400,
    marginBottom: 30,
    marginTop: 5,
    fontSize: 30,
    [theme.breakpoints.up('md')]: {
      fontSize: 35,
    },
  },
  instruction: {
    color: '#606060',
    fontSize: 16,
    fontWeight: 400,
    marginTop: 10,
    [theme.breakpoints.up('md')]: {
      marginBottom: 40,
    },
  },
  continueBtn: {
    fontSize: 16,
    fontWeight: 500,
    background: 'transparent !important',
    color: '#2eaba4',
    border: '1px solid #2eaba4',
    textTransform: 'none !important',
    borderRadius: 2,
    padding: '21.6px 25px',
    marginTop: 15,
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      height: 45,
      padding: 20,
      fontSize: 16,
    },
  },
  startBtn: {
    background: '#2eaba4',
    height: '45px',
    color: 'white',
    borderRadius: 2,
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'none !important',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      padding: 20,
      minWidth: 300,
    },
  },
  btnContainer: {
    justifyContent: 'space-evenly!important',
    marginBottom: 10,
  },
  recommended: {
    color: '#2eaba4',
    fontWeight: 700,
  },
  notRecommendedLine: {
    fontSize: 16,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },
  },
  notRecommendedText: {
    color: '#e02020',
    fontWeight: 700,
  },
  cancel: {
    width: 50,
    height: '19px',
    color: '#0091ff',
    fontSize: 16,
    fontWeight: 700,
    cursor: 'pointer',
  },
}));
const DownloadStartPacket = ({ paperOption, onClose }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  return (
    <Dialog
      open={true}
      classes={{ paper: classes.mainContainer }}
      fullScreen={isMobile}
    >
      <DialogContent>
        <Typography className={classes.header}>
          Manually Complete Offer
        </Typography>
        <div className={classes.grid}>
          <Typography className={classes.instruction}>
            <p className={classes.recommended}>
              Use Digital Start Packet (Recommended)
            </p>
            <p>
              {' '}
              Your digital files are secure and accessible in the <br />
              My Files section of MyCast&Crew.
            </p>
          </Typography>
          <Button className={classes.startBtn} onClick={() => onClose(false)}>
            Use Digital Start Packet
          </Button>
        </div>
        <div className={classes.grid}>
          <Typography className={classes.instruction}>
            <p className={classes.notRecommendedLine}>
              Manually Fill Out Start Packet{' '}
              <span className={classes.notRecommendedText}>
                {' '}
                (Not Recommended)
              </span>
            </p>
            <p>
              This option requires you to complete and sign your offer on paper
              and submit your documents in person. Clicking “Download and
              Continue on Paper” downloads your start packet for you to fill
              out.
            </p>
          </Typography>
          <Button
            data-test-id="Dialog-action-download-continue-on-paper"
            className={classes.continueBtn}
            onClick={() => paperOption()}
          >
            Download and Continue on Paper
          </Button>
        </div>
      </DialogContent>
      <DialogActions className={classes.btnContainer}>
        <Typography className={classes.cancel} onClick={() => onClose(false)}>
          Cancel
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadStartPacket;
