import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import useDepartments from 'common/hooks/useDepartments';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import Loader from 'common/components/Loader';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

const styles = theme => ({
  root: {
    alignContent: 'end',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'max-content max-content 15px',
  },
  label: {
    gridRow: 1,
    gridColumn: 1,
    fontSize: '15px',
  },
  modalLinkContainer: {
    gridRow: 3,
    gridColumn: 2,
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gridTemplateRows: '1fr 1fr',
    gap: '5px',
  },
  modalLinkDescription: {
    gridColumn: '1 / -1',
  },
  modalLink: {
    alignSelf: 'end',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  warningIcon: {
    color: '#ffeb3b',
    placeSelf: 'center center',
  },
  button: {
    height: '38px',
  },
  fieldSet: {
    gridRow: 2,
    gridColumn: '1 / -1',
  },
  radioGroup: {
    display: 'grid',
    gridAutoFlow: 'column',
  },
  formControl: {
    marginLeft: '0',
  },
  select: {},
  error: {},
});

const EmploymentClassification = props => {
  const {
    formData: { employmentClassification, department = null, occupation } = {},
    onChange: upstreamOnChange,
    toggleModal,
    enforceLoanOut,
    error = '',
    classes = {},
    disabled,
    projectId,
  } = props;
  const { value: occupationCode } = occupation || {};

  const { loading, data: departments = [] } = useDepartments({ projectId });

  if (loading) return <Loader />;

  const onChange = ({ target: { value } = {} } = {}) =>
    upstreamOnChange({ employmentClassification: value });

  const isLoanOutDisabled =
    disabled ||
    (enforceLoanOut &&
      !departments.some(
        ({ id: departmentId, loanOutOccupations = [] }) =>
          departmentId === department &&
          loanOutOccupations.find(
            ({ code: occCode }) => occCode === occupationCode,
          ),
      ));

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Employment Classification
      </Typography>
      {enforceLoanOut && (
        <Tooltip title="This project has been configured to restrict the Loan Out employment classification to specific occupations.">
          <div className={classes.modalLinkContainer}>
            <WarningIcon className={classes.warningIcon} />
            <Link
              color="inherit"
              onClick={toggleModal}
              className={classes.modalLink}
            >
              View allowed loan out occupations{' '}
            </Link>
            <Typography className={classes.modalLinkDescription}>
              Your show restricts which occupations are allowed to be loan-out
              hires.
            </Typography>
          </div>
        </Tooltip>
      )}
      <FormControl component="fieldset" classes={{ root: classes.fieldSet }}>
        <RadioGroup
          aria-label="Employment Classification"
          name="employmentClassification"
          className={classes.radioGroup}
          value={employmentClassification}
          onChange={onChange}
        >
          <FormControlLabel
            value="w2"
            control={
              <Radio
                disabled={disabled}
                inputProps={{
                  'data-test-id': 'EmploymentClassifcation-radio-w2',
                }}
              />
            }
            label={
              <React.Fragment>
                <UsaOnly>W-2 Employee</UsaOnly>
                <CanadaOnly>Individual</CanadaOnly>
              </React.Fragment>
            }
            classes={{ root: classes.formControl }}
            data-test-id="EmploymentClassifcation-label-w2"
          />
          <FormControlLabel
            value="loanOut"
            control={
              <Radio
                disabled={isLoanOutDisabled}
                inputProps={{
                  'data-test-id': 'EmploymentClassifcation-radio-loanOut',
                }}
              />
            }
            label="Loan Out"
            classes={{ root: classes.formControl }}
          />
        </RadioGroup>
      </FormControl>
      {error && (
        <Typography color="error" variant="caption" className={classes.error}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default withRouteHelpers(withStyles(styles)(EmploymentClassification));
