import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DesktopOnboardingRoutes from 'onboarding/components/Routes';
import MobileOnboardingRoutes from 'onboarding/components/MobileRoutes';
import Home from 'common/containers/Home';
import Library from 'admin/containers/Library';
import Profile from 'common/containers/Profile';
import Projects from 'common/containers/Projects';
import NonCrewProfile from 'common/containers/NonCrewProfile';
import NotificationsContainer from 'common/containers/NotificationsContainer';
import GlobalPermissions from 'admin/containers/GlobalPermissions';
import Tools from 'admin/containers/Tools';
import MobileRoutes from 'mobile/components/MobileRoutes';
import useIsMobile from 'common/hooks/useIsMobile';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const Routes = () => {
  const isMobile = useIsMobile();
  const flags = useFeatureFlags();
  // Feature component is incompatible with Route so do this the hard way
  const isMobileOnboardingFlagActive = flags.includes('mobileOnboarding');

  return (
    <Switch>
      <Redirect from="/offers" to="/onboarding" />
      {isMobile && isMobileOnboardingFlagActive ? (
        <Route path="/onboarding" component={MobileOnboardingRoutes} />
      ) : (
        <Route path="/onboarding" component={DesktopOnboardingRoutes} />
      )}
      {isMobile && <MobileRoutes />}
      <Route path="/projects" component={Projects} />
      <Route path="/library" component={Library} />
      <Route path="/global-permissions" component={GlobalPermissions} />
      <Route path="/account" component={Profile} />
      <Route path="/profile" component={NonCrewProfile} />
      <Route path="/notifications" component={NotificationsContainer} />
      <Route path="/tools" component={Tools} />
      <Route component={Home} />
    </Switch>
  );
};

export default Routes;
