import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import { compose } from 'redux';
import moment from 'moment';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

const styles = theme => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkButton: {
    height: '45px',
    width: '45px',
  },
  label: {}, // Keep these here to make material-ui happy
  field: {}, // Keep these here to make material-ui happy
});

const SendDateLink = props => {
  const {
    classes,
    onChange: upstreamOnChange,
    formData: { isLinkActive, startDate, startDateObject } = {},
    sendDateConfiguration,
  } = props;
  const isSendOnStartAlways = sendDateConfiguration === 'always_start_date';

  const onChange = () => {
    const { pushSnackbarNotification } = props;
    const isStartDateInPast = startDateObject.isBefore(moment(), 'day');

    // Send Date can not be backdated
    // If the user has backdated their startDate and is trying to link their send date block it
    if (!isLinkActive && isStartDateInPast) {
      pushSnackbarNotification({
        message: `You can not link the send date to the start date because you have backdated your start date.  Please select a start date with a minimum date of today.`,
        variant: SnackbarVariants.WARNING,
        duration: 5000,
      });
      return;
    }

    // Toggle the send date link
    upstreamOnChange({ isLinkActive: !isLinkActive });

    // If they are linking the start date (inverse of the current setting) then
    // change the send Date to the start date
    if (!isLinkActive) {
      upstreamOnChange({
        sendDate: startDate,
        sendDateObject: startDateObject,
      });
    }
  };

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.linkButton}
        onClick={onChange}
        data-test-id="SendDateLink-button"
      >
        {isLinkActive && !isSendOnStartAlways ? (
          <LinkIcon data-test-id="SendDateLink-linkIcon" />
        ) : (
          <LinkOffIcon data-test-id="SendDateLink-linkOffIcon" />
        )}
      </IconButton>
    </div>
  );
};

export default compose(
  withSnackbarNotification,
  withStyles(styles),
)(SendDateLink);
