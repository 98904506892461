import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Layouts
import ProjectLayout from 'common/oldJavascripts/components/Pages/Layouts/Project';

// Components
import Archives from 'studio/oldJavascripts/components/Pages/Project/Archives';
import AddManualArchivedOffer from 'studio/oldJavascripts/components/Pages/Project/Archives/New';
import ArchivedOfferDetails from 'studio/oldJavascripts/components/Pages/Project/Archives/Details';

export default props => {
  return (
    <ProjectLayout>
      <Switch>
        <Route
          exact
          path="/projects/:projectId/archives/new"
          component={AddManualArchivedOffer}
        />
        <Route
          path="/projects/:projectId/archives/:archiveId"
          component={ArchivedOfferDetails}
        />
        <Route component={Archives} />
      </Switch>
    </ProjectLayout>
  );
};
