import React, { useState } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, Link } from '@material-ui/core';
import TreeView from 'react-treeview';
import Dialog from 'common/components/Dialog';

const styles = theme => ({
  paper: {
    width: 600,
    height: 600,
  },
  closeBtn: {
    background: '#f4f6f8',
    color: 'black',
  },
  okBtn: {
    background: '#45a7dc',
    color: 'white',
  },
  treeContainer: {
    border: '1px solid #e4e6e8',
    background: '#f4f6f8',
    padding: 10,
  },
  leaf: {
    marginLeft: 16,
    display: 'block',
  },
  selected: {
    background: '#45a7dc',
    color: 'white',
  },
});

const StudioPlusFolderSelectorModal = props => {
  const { classes, directories, onClose, onSelect, open } = props;

  const [selectedId, setSelectedId] = useState();
  const [expandedNodes, setExpandedNodes] = useState({});

  const setNodeExpansion = (id, isExpanded) =>
    setExpandedNodes({ ...expandedNodes, [id]: isExpanded });

  const renderDirectoryLink = (id, name, isLeaf) => (
    <Link
      component="button"
      color="initial"
      className={classNames({
        [classes.leaf]: isLeaf,
        [classes.selected]: id === selectedId,
      })}
      onClick={() => setSelectedId(id)}
      key={id}
      data-test-id={`StudioPlusFolderSelectorModal-directory-${id}`}
    >
      {name}
    </Link>
  );

  const renderDirectory = ({ id, name, children }) => {
    const isExpanded = expandedNodes[id];
    return children && children.length ? (
      <TreeView
        nodeLabel={renderDirectoryLink(id, name)}
        collapsed={!isExpanded}
        onClick={() => setNodeExpansion(id, !isExpanded)}
        key={id}
      >
        {children.map(renderDirectory)}
      </TreeView>
    ) : (
      renderDirectoryLink(id, name, true)
    );
  };

  const submit = () => {
    onClose();
    onSelect(selectedId);
  };

  const expandAll = () => {
    const obj = {};
    const addDirsToObj = dirs =>
      dirs.forEach(dir => {
        obj[dir.id] = true;
        addDirsToObj(dir.children || []);
      });
    addDirsToObj(directories);
    setExpandedNodes(obj);
  };

  const collapseAll = () => setExpandedNodes({});

  const body = (
    <div>
      <Button
        onClick={expandAll}
        data-test-id="DocumentDestinationDialog-expandAll"
      >
        Expand All
      </Button>
      <Button
        onClick={collapseAll}
        data-test-id="DocumentDestinationDialog-collapseAll"
      >
        Collapse All
      </Button>
      <div
        className={classes.treeContainer}
        data-test-id="DocumentDestinationDialog-tree"
      >
        {directories.map(renderDirectory)}
      </div>
    </div>
  );

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      title="Select Studio+ Destination Directory"
      message={body}
      actions={[
        { text: 'Cancel', fn: onClose, className: classes.closeBtn },
        {
          text: 'OK',
          fn: submit,
          className: classes.okBtn,
          disabled: !selectedId,
        },
      ]}
      handleClose={onClose}
    />
  );
};

export default withStyles(styles)(StudioPlusFolderSelectorModal);
