import React from 'react';
import Radio from '@material-ui/core/Radio';
import withRouteHelpers from '../../../../../common/hoc/withRouteHelpers';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  label: {
    gridRow: 1,
    gridColumn: 1,
    fontSize: '15px',
  },
  fieldSet: {
    gridRow: 2,
    gridColumn: '1 / -1',
    marginTop: '-7px',
  },
  radioGroup: {
    display: 'grid',
    gridAutoFlow: 'column',
  },
  formControl: {
    marginLeft: '0',
  },
  error: {
    display: 'block',
    marginBottom: '10px',
    fontSize: '12px',
  },
});

const NegotiatedContractTerms = props => {
  const {
    onChange: upstreamOnChange,
    negotiatedContractId,
    contracts = [],
    formErrors = {},
    classes = {},
  } = props;
  const error = formErrors.negotiatedContractId;
  let contractId = null;
  if (Array.isArray(contracts)) {
    const htgContract = contracts.find(contract => contract.type === 'htg');
    if (htgContract) {
      const contract =
        htgContract.contracts.find(contract => !!contract.negotiableContract) ||
        {};
      contractId = contract.code;
    }
  }

  if (!contractId) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        Do you wish to use the negotiated contract terms for this offer?
        <FormControl component="fieldset" classes={{ root: classes.fieldSet }}>
          <RadioGroup
            aria-label="Negotiate Rate"
            name="negotiableContract"
            className={classes.radioGroup}
            value={negotiatedContractId}
            onChange={e =>
              upstreamOnChange({ negotiatedContractId: e.target.value })
            }
          >
            <FormControlLabel
              value={contractId}
              control={
                <Radio
                  inputProps={{
                    'data-test-id': 'negotiableContract-radio-Yes',
                  }}
                />
              }
              label="Yes"
              classes={{ root: classes.formControl }}
            />
            <FormControlLabel
              value="N"
              control={
                <Radio
                  inputProps={{
                    'data-test-id': 'negotiableContract-radio-No',
                  }}
                />
              }
              label="No"
              classes={{ root: classes.formControl }}
            />
          </RadioGroup>
        </FormControl>
        {error && (
          <Typography color="error" variant="caption" className={classes.error}>
            {error}
          </Typography>
        )}
      </Typography>
    </div>
  );
};

export default withRouteHelpers(withStyles(styles)(NegotiatedContractTerms));
