import React, { Fragment } from 'react';
import TOOLTIP_ICON from 'common/images/tooltip.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  terms: {
    marginTop: '40px',
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: 20,
  },
  amount: {
    color: '#2eaba4',
    fontSize: 30,
    fontWeight: 400,
    padding: 18,
    textAlign: 'center',
    border: '0.1px solid #d3f0ee',
    [theme.breakpoints.up('md')]: {
      padding: 15,
    },
  },
  grey: {
    color: '#939393',
  },
  scaleTip: {
    fontSize: 10,
    color: '#939393',
  },
  tooltip: {
    marginLeft: '5px',
  },
  mobileTermsRateHeader: {
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    gridTemplateRows: '30% 70%',
    gridAutoFlow: 'row',
  },
  greenBox: {
    backgroundColor: '#f5fcfb',
    fontSize: 18,
    fontWeight: 400,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  regularBox: {
    backgroundColor: '#f5fcfb',
    color: '#5b5b5b',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    padding: 15,
    border: 'none',
  },
  distant: {
    color: '#5b5b5b',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  distantContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const formatAmount = amount => {
  return !Number.isInteger(amount) ? currency(amount) : `$${amount}`;
};

const UnionWeeklyEmployeeTermsMobile = ({ terms = {} }) => {
  const classes = useStyles();
  const {
    rate_per_hour_studio: rate,
    rate_per_hour_location: rateDistant,
    guaranteed_hours_studio: guaranteedHours,
    guaranteed_hours_location: guaranteedHoursDistant,
    pay_idle_days_distant: payIdleDaysDistant,
    pay_gold_at: payGoldAt,
    pay_gold_at_distant: payGoldAtDistant,
    pay_at_scale: payAtScale,
    pay_at_scale_distant: payAtScaleDistant,
    idle_at_scale_distant: idleAtScaleDistant,
    gold_at_scale: goldAtScale,
    gold_at_scale_distant: goldAtScaleDistant,
    rate_per_week_studio: weeklyRate,
    rate_per_week_location: weeklyRateDistant,
  } = terms;

  const isStudio = rate || weeklyRate || guaranteedHours;
  const isDistant = rateDistant || weeklyRateDistant || guaranteedHoursDistant;

  return (
    <div className={classes.terms}>
      <Typography
        className={classes.title}
        data-test-id="TermsOfEmploymentMobile-title"
      >
        Terms of Employment
      </Typography>
      {isStudio && (
        <Fragment>
          <div>
            <div className={classes.greenBox}>
              <Typography
                className={classes.distant}
                data-test-id="TermsOfEmploymentMobile-studioSection-title"
              >
                STUDIO{' '}
                <Tooltip
                  title="Studio refers to a local distance within the studio zone"
                  placement="top-start"
                  className={classes.tooltip}
                >
                  <img src={TOOLTIP_ICON} alt="schedule tooltip" />
                </Tooltip>
              </Typography>
            </div>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.regularBox}
                  data-test-id="TermsOfEmploymentMobile-hourlyStudioRate-title"
                >
                  Hourly Rate
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {rate && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="TermsOfEmploymentMobile-hourlyStudioRate-amount"
                  >
                    {formatAmount(rate)}
                    {payAtScale && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
          <Table className={classes.distantContainer}>
            <TableHead>
              <TableRow>
                {weeklyRate && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="TermsOfEmploymentMobile-weeklyStudioRate-title"
                  >
                    Weekly Rate
                  </TableCell>
                )}
                {guaranteedHours && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="TermsOfEmploymentMobile-hourDays-title"
                  >
                    Hours Per Day
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {weeklyRate && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="TermsOfEmploymentMobile-weeklyRate-amount"
                  >
                    {formatAmount(weeklyRate)}
                    {payAtScale && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
                {guaranteedHours && (
                  <TableCell
                    className={[classes.amount, classes.grey].join(' ')}
                    data-test-id="TermsOfEmploymentMobile-guaranteedHours-amount"
                  >
                    {guaranteedHours}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
          <Table className={classes.distantContainer}>
            <TableHead>
              <TableRow>
                {payGoldAt && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="TermsOfEmploymentMobile-weeklyStudioRate-title"
                  >
                    Pay Gold at
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {payGoldAt && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="TermsOfEmploymentMobile-weeklyRate-amount"
                  >
                    {formatAmount(payGoldAt)}
                    {goldAtScale && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Fragment>
      )}
      {isDistant && (
        <div className={classes.distantContainer}>
          <div>
            <div className={classes.greenBox}>
              <Typography
                className={classes.distant}
                data-test-id="TermsOfEmploymentMobile-distantSection-title"
              >
                DISTANT{' '}
                <Tooltip
                  title="Distant refers to the location outside the studio zone."
                  placement="top-start"
                  className={classes.tooltip}
                >
                  <img src={TOOLTIP_ICON} alt="schedule tooltip" />
                </Tooltip>
              </Typography>
            </div>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                {rateDistant && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="TermsOfEmploymentMobile-hourlyRate-title"
                  >
                    Hourly Rate
                  </TableCell>
                )}
                {weeklyRateDistant && (
                  <TableCell
                    className={classes.regularBox}
                    data-test-id="TermsOfEmploymentMobile-weeklyRateDistant-title"
                  >
                    Weekly Rate
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {rateDistant && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="TermsOfEmploymentMobile-rateDistant-amount"
                  >
                    {formatAmount(rateDistant)}
                  </TableCell>
                )}
                {weeklyRateDistant && (
                  <TableCell
                    className={classes.amount}
                    data-test-id="TermsOfEmploymentMobile-weeklyRateDistant-amount"
                  >
                    {formatAmount(weeklyRateDistant)}
                    {payAtScaleDistant && (
                      <Typography className={classes.scaleTip}>
                        *Scale Rate
                      </Typography>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>

          {(payGoldAtDistant || guaranteedHoursDistant) && (
            <Table className={classes.distantContainer}>
              <TableHead>
                <TableRow>
                  {guaranteedHoursDistant && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-guaranteedHoursDistant-title"
                    >
                      Hours Per Day
                    </TableCell>
                  )}
                  {payGoldAtDistant && (
                    <TableCell
                      className={classes.regularBox}
                      data-test-id="TermsOfEmploymentMobile-goldAtScaleDistant-title"
                    >
                      Pay Gold at
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {guaranteedHoursDistant && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-guaranteedHoursDistant-amount"
                    >
                      {guaranteedHoursDistant}
                    </TableCell>
                  )}
                  {payGoldAtDistant && (
                    <TableCell
                      className={classes.amount}
                      data-test-id="TermsOfEmploymentMobile-goldAtScaleDistant-amount"
                    >
                      {formatAmount(payGoldAtDistant)}
                      {goldAtScaleDistant && (
                        <Typography className={classes.scaleTip}>
                          *Scale Rate
                        </Typography>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>
      )}
      {payIdleDaysDistant && (
        <Table className={classes.distantContainer}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.regularBox}
                data-test-id="TermsOfEmploymentMobile-idleRate-title"
              >
                Idle Days Pay
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className={classes.amount}
                data-test-id="TermsOfEmploymentMobile-idleRateDistant-amount"
              >
                {formatAmount(payIdleDaysDistant)}
                {idleAtScaleDistant && (
                  <Typography className={classes.scaleTip}>
                    *Scale Rate
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      <Typography className={classes.scaleTip}>
        *Scale rate shown is as of offer creation date. Scale rate is subject to
        change upon union negotiated increases.
      </Typography>
    </div>
  );
};

export default UnionWeeklyEmployeeTermsMobile;
