import domClasses from '../../utils/dom/classes.js';

import React from 'react';

class Icon extends React.Component {
  constructor() {
    super();
    this._click = this._click.bind(this);
  }

  render() {
    if (this.props.label) {
      return this._renderWithLabel();
    }
    return this._renderWithoutLabel();
  }

  _renderWithLabel() {
    return (
      <i
        className={this._getClasses()}
        onClick={this._click}
        title={this.props.label || ''}
        style={this.props.style}
      >
        <span className="icon-label">{this.props.label}</span>
      </i>
    );
  }
  _renderWithoutLabel() {
    return (
      <i
        className={this._getClasses()}
        onClick={this._click}
        style={this.props.style}
      />
    );
  }

  _getClasses() {
    return domClasses.set({
      [this.props.className]: this.props.className,
      'icon--clickable': this.props.onClick,
      fa: this.props.name.indexOf('fa-') > -1,
      [this.props.name]: this.props.name.indexOf('fa-') > -1,
      //:TODO: Remove the following classes after we replace all icons to FontAwesome
      icon: this.props.name.indexOf('fa-') === -1,
      [`icon--${this.props.name}`]: this.props.name.indexOf('fa-') === -1,
    });
  }

  _click(event) {
    if (this.props.onClick) {
      event.preventDefault();
      event.stopPropagation();
      this.props.onClick(event);
    }
  }
}

export default Icon;
