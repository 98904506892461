import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  Button,
  Typography,
  List,
  Checkbox,
  Input,
  Paper,
  MuiThemeProvider,
} from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { ListItem, ListItemText } from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import SingleDocIcon from 'common/icons/SingleDocIcon';
import ProgressSpinner from 'common/components/ProgressSpinner';
import palette, {
  lightGrey as paletteLightGrey,
  success as paletteSuccess,
  successHover as paletteSuccessHover,
} from 'common/shared/oldDocumentSignerUI/palette';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';

const styles = theme => ({
  container: {
    width: 600,
    position: 'relative',
  },
  addButton: {
    width: 85,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    color: theme.palette.primary.contrastText,
    height: 15,
    width: 15,
  },
  header: {
    paddingLeft: theme.spacing.unit * 2.5,
  },
  subHeader: {
    color: theme.palette.primary.contrastText,
  },
  dialogContent: {
    paddingBottom: theme.spacing.unit * 2.5,
    boxSizing: 'border-box',
  },
  documentText: {
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& h3': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    maxWidth: '90%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  closeButton: {
    width: 40,
    position: 'absolute',
    top: theme.spacing.unit * 1.2,
    right: theme.spacing.unit * 1.2,
  },
  listContainer: {
    background: paletteLightGrey,
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 1.2,
    height: '40vh',
    overflowY: 'auto',
  },
  listItem: {
    cursor: 'pointer',
    height: theme.spacing.unit * 8,
    marginBottom: theme.spacing.unit,
  },
  select: {
    width: 150,
  },
  filterToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing.unit * 2.5,
    paddingRight: theme.spacing.unit * 2.5,
  },
  confirmAddButtonContainer: {
    position: 'relative',
    float: 'right',
  },
  buttonSuccess: {
    backgroundColor: paletteSuccess,
    '&:hover': {
      backgroundColor: paletteSuccessHover,
    },
  },
  checked: {
    color: `${palette.primary.main} !important`,
  },
});

class AddProjectDocument extends Component {
  state = {
    selectedDocuments: [],
    filter: '',
    documents: this.props.documents,
  };

  handleCloseModal = () => {
    const { closeHandler } = this.props;
    closeHandler();
  };

  handleSubmitDocuments = () => {
    const { selectedDocuments } = this.state;
    const { submitDocuments } = this.props;
    submitDocuments(selectedDocuments);
  };

  inputFilter = value => {
    const { documents } = this.props;
    return documents.filter(doc =>
      doc.name
        .concat(doc.tags.join(', '))
        .toLowerCase()
        .includes(value.toLowerCase()),
    );
  };

  handleFilterDocuments = (field, event) => {
    const { value } = event.target;
    const filteredDocuments = this.inputFilter(value);

    this.setState({
      [field]: value,
      documents: filteredDocuments,
    });
  };

  handleSelectDocument = id => {
    const { selectedDocuments } = this.state;
    const docIndex = selectedDocuments.indexOf(id);
    if (docIndex === -1) {
      selectedDocuments.push(id);
      this.setState({
        selectedDocuments,
      });
      return;
    }
    selectedDocuments.splice(docIndex, 1);
    this.setState({
      selectedDocuments,
    });
  };

  render() {
    const {
      classes,
      project,
      isSaving = false,
      saveSuccess = false,
    } = this.props;
    const { filter, selectedDocuments, documents = [] } = this.state;
    const filteredDocuments = documents.filter(({ expired }) => !expired);
    const noneSelected = selectedDocuments.length === 0;
    const subHeaderClass = classnames([classes.header], [classes.subHeader]);
    const addButtonClass = classnames([classes.addButton], {
      [classes.buttonSuccess]: saveSuccess,
    });
    const documentListItems = filteredDocuments.map(doc => (
      <Paper key={doc.id}>
        <ListItem
          button
          onClick={() => this.handleSelectDocument(doc.id)}
          className={classes.listItem}
          data-test-id={`document-list-item-${doc.id}`}
        >
          <SingleDocIcon height={35} />
          <ListItemText
            primary={doc.name}
            secondary={doc.tags.join(', ')}
            className={classes.documentText}
          />
          <Checkbox
            checked={Boolean(selectedDocuments.includes(doc.id))}
            data-test-id={`document-list-checkbox-${doc.id}`}
            classes={{ checked: classes.checked }}
          />
        </ListItem>
      </Paper>
    ));

    return (
      <MuiThemeProvider theme={documentSignerTheme}>
        <div className={classes.container}>
          <div className={classes.dialogContent}>
            <Button
              onClick={this.handleCloseModal}
              className={classes.closeButton}
              data-test-id="close-document-modal-button"
            >
              <CloseIcon />
            </Button>
            <Typography variant="h5" className={classes.header}>
              Add Documents
            </Typography>
            <Typography
              variant="subtitle1"
              className={subHeaderClass}
              gutterBottom
            >
              Project: {project.title}
            </Typography>
            <div className={classes.filterToolbar}>
              <Input
                id="filter-documents"
                value={filter}
                onChange={e => this.handleFilterDocuments('filter', e)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                placeholder="Filter Documents"
                data-test-id="add-document-filter-input"
              />
            </div>
            <List className={classes.listContainer}>{documentListItems}</List>
          </div>
          <div>
            <div className={classes.confirmAddButtonContainer}>
              <Button
                onClick={this.handleSubmitDocuments}
                disabled={isSaving || noneSelected}
                className={addButtonClass}
                color="primary"
                variant="contained"
                data-test-id="add-document-confirmation-button"
              >
                Add
              </Button>
              {isSaving && <ProgressSpinner size={24} top={6} left={32} />}
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(AddProjectDocument);
