import React from 'react';

import { intersection, union, pull } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import Grey from '@material-ui/core/colors/grey';
import {
  Paper,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

const styles = {
  grid: {
    display: 'grid',
  },
  parentLabel: {
    color: Grey[800],
    fontWeight: 700,
    fontSize: 14,
  },
  child: {
    width: '32%',
  },
};

class PermissionExpansionPanel extends React.Component {
  constructor(props) {
    super(props);
    const allChildren = this.allChildrenPermissions();
    this.state = {
      map: allChildren,
    };
  }

  handleChange = permission => event => {
    const { id } = permission;
    const isPermissionGroup = 'children' in permission;

    const { map = {} } = this.state;
    const { selections = [], onChange: parentHandler } = this.props;
    const {
      target: { checked },
    } = event;

    if (isPermissionGroup) {
      // For PermissionGroup: toggle all of its children
      // if checked: add all children to [selections]
      // if unchecked: remove all children from [selections]
      const { [id]: allChildren = [] } = map;

      const newSelections = checked
        ? union(selections, allChildren)
        : pull(selections, ...allChildren);

      parentHandler(newSelections);
    } else {
      // For Permission: toggle itself
      // Add or remove its value from [selections]
      const index = selections.indexOf(id);
      index >= 0 ? selections.splice(index, 1) : selections.push(id);

      parentHandler(selections);
    }
  };

  allChildrenPermissions = () => {
    const { permissionGroup } = this.props;
    // atm, [PermissionGroup] at grandparent level
    // and has two levels of children.

    // If in the future [item] has more levels, plz upgrade
    // a recursive bfs etc to find all children

    // permissionGroup = { id, code, children: [
    //     { id, code, children: [], permissions: [ permision, permision, ...]}
    //     { id, code, children: [], permissions: [ permision, permision, ...]}
    // ]}

    const result = [];
    permissionGroup.children.forEach(c => {
      if (Array.isArray(c.permissions)) {
        c.permissions.forEach(p => {
          result.push(p.id);
        });
      }
    });

    return { [permissionGroup.id]: result };
  };

  shouldParentBeChecked = permission => {
    // [selections] is passed from parents, which contains selections
    // made from ALL ExpansionalPanels.

    const { selections } = this.props;
    const { map } = this.state;

    const allChildren = map[permission.id];
    const selectedChildren = intersection(selections, allChildren);

    if (selectedChildren.length === 0) return false;

    if (selectedChildren.length === allChildren.length) return true;

    if (selectedChildren.length < allChildren.length) return 'indeterminate';
  };

  renderHelper = item => {
    // [item] is parent level PermisionGroup
    // its attr "permissions" contains all Permissions

    // If in the future [item] has more levels, plz upgrade
    // a recursive bfs etc to render all the children

    const { classes, selections = [] } = this.props;

    return (
      <Paper
        elevation={0}
        className={classes.parentLevel}
        key={`PermissionExpansionPanel-permissionGroup-${item.id}`}
      >
        <Typography className={classes.parentLabel}>
          {item.description}
        </Typography>
        {item.permissions.map(permission => {
          const isChecked = selections.includes(permission.id);
          return (
            <FormControlLabel
              label={permission.description}
              control={
                <Checkbox
                  inputProps={{
                    'data-test-id': `PermissionExpansionPanel-checkbox-${permission.id}`,
                    'data-checked': isChecked,
                  }}
                />
              }
              checked={isChecked}
              onChange={this.handleChange(permission)}
              className={classes.child}
              data-test-id={`PermissionExpansionPanel-formControlLabel-${permission.id}`}
              key={`PermissionExpansionPanel-permission-${permission.id}`}
            />
          );
        })}
      </Paper>
    );
  };

  render() {
    const { permissionGroup = {}, classes } = this.props;
    const { id, description, children = [] } = permissionGroup;

    const checkboxStatus = this.shouldParentBeChecked(permissionGroup);

    return (
      <ExpansionPanel
        onChange={this.expansionPanelChange}
        data-test-id={`PermissionExpansionPanel-root-${id}`}
      >
        <ExpansionPanelSummary
          data-test-id={`PermissionExpansionPanel-expansionPanelSummary-${id}`}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            'data-test-id': `PermissionExpansionPanel-expandIcon-${id}`,
          }}
        >
          <FormControlLabel
            label={description}
            control={
              <Checkbox
                checked={checkboxStatus !== false}
                indeterminate={checkboxStatus === 'indeterminate'}
                inputProps={{
                  'data-test-id': `PermissionExpansionPanel-checkbox-${id}`,
                  'data-checked': checkboxStatus,
                }}
              />
            }
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={this.handleChange(permissionGroup)}
            classes={{ label: classes.parentLabel }}
            data-test-id={`PermissionExpansionPanel-formControlLabel-${id}`}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          className={classes.grid}
          data-test-id={`PermissionExpansionPanel-expansionPanelDetails-${id}`}
        >
          {children.map(c => this.renderHelper(c))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(PermissionExpansionPanel);
