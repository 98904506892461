import React from 'react';
import Field from '../../Base/Field';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import SpecifiedCountryOnly from 'common/components/SpecifiedCountryOnly';

const styles = theme => ({
  nested: {
    marginLeft: '20px',
  },
});

const PRIVILEGES = {
  can_create_offers: {
    label: 'Can create offers (Hirer)',
    subPrivileges: { can_hirer_view_all: 'View all employees and offers' },
  },
  can_be_reviewer:
    'Can review offers before they are sent to employees (Reviewer)',
  can_be_i9_verifier: 'Can verify I-9 documents (I-9 Verifier)',
  can_approve_offers:
    'Can approve completed offers submitted by employees (Approver)',
  can_view_offer_account_codes: {
    label: 'Can view offer account code fields',
    subPrivileges: {
      can_edit_offer_account_codes: 'Can edit offer account code fields',
    },
  },
  can_access_archives: 'Access to archives',
  can_view_documents: 'Can view documents',
};

class PrivilegesPicker extends React.Component {
  render() {
    const mutator = this.props.mutator;
    return <div>{this._renderPrivilegesMap(PRIVILEGES, mutator)}</div>;
  }

  _renderPrivilegesMap(mapItems, mutator, nested = false, options = []) {
    Object.keys(mapItems).forEach(key => {
      const label =
        typeof mapItems[key] === 'string' ? mapItems[key] : mapItems[key].label;
      options.push(this._renderPrivilege(label, key, mutator, nested));
      if (mutator.data[key] && typeof mapItems[key] !== 'string') {
        this._renderPrivilegesMap(
          mapItems[key].subPrivileges,
          mutator,
          true,
          options,
        );
      }
    });
    return options;
  }

  _renderPrivilege(label, privilegeKey, mutator, nested = false) {
    const { classes } = this.props;
    const fieldClassName = classNames({
      [classes.nested]: nested,
    });
    const countryCode = privilegeKey === 'can_be_i9_verifier' ? 'US' : null;
    return (
      <SpecifiedCountryOnly countryCode={countryCode} key={privilegeKey}>
        <span data-test-id={`PrivilegesPicker-checkbox-${privilegeKey}`}>
          <Field
            label={label || '<< No Label >>'}
            type="checkbox"
            name={privilegeKey}
            id={privilegeKey}
            value={mutator.data[privilegeKey]}
            className={fieldClassName}
            onChange={this._updatePrivilege(privilegeKey, mutator)}
          />
        </span>
      </SpecifiedCountryOnly>
    );
  }

  _findChildPrivileges(mapItems, privilegeKey, keys = []) {
    Object.keys(mapItems).forEach(key => {
      if (key === privilegeKey || !privilegeKey) {
        if (!privilegeKey) {
          keys.push(key);
        }
        if (typeof mapItems[key] !== 'string') {
          this._findChildPrivileges(mapItems[key].subPrivileges, null, keys);
        }
      } else if (mapItems[key].subPrivileges) {
        this._findChildPrivileges(
          mapItems[key].subPrivileges,
          privilegeKey,
          keys,
        );
      }
    });
    return keys;
  }

  _updatePrivilege(privilegeKey, mutator) {
    return value => {
      mutator.update(privilegeKey)(value);
      if (!value) {
        this._findChildPrivileges(PRIVILEGES, privilegeKey).forEach(key => {
          mutator.update(key)(false);
        });
      }
    };
  }
}

export default withStyles(styles)(PrivilegesPicker);
