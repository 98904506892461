import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import OfferForm from './OfferForm';
import Loader from 'common/components/Loader';
import CrewMemberReviewerList from './CrewMemberReviewerList';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import withApi from 'common/hoc/withApi';
import withPermissionProtection from 'common/hoc/withPermissionProtection';
import moment from 'moment';
import qs from 'qs';
import formatCurrency from './helpers/formatCurrency';
import getNormalizedMutationPayload from './helpers/getNormalizedMutationPayload';
import classNames from 'classnames';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import { Mutations } from 'common/apollo';
import useOffers from './hooks/useOffers';
import useOfferDefaults from './hooks/useOfferDefaults';
import useStates from 'common/hooks/useStates';
import useOfferCountry from 'common/components/SpecifiedCountryOnly/useOfferCountry';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import isUnionWeeklyScheduleCode from 'common/utilities/isUnionWeeklySchedule';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(300px, 400px) 1fr',
    gridTemplateRows: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  crewMemberName: {
    marginTop: 4,
  },
  crewMemberContactInfo: {
    lineHeight: '1.2',
  },
  withCrewMemberSidebar: {
    gridTemplateAreas: `
        "crewMembers content"`,
  },
  withoutCrewMemberSidebar: {
    gridTemplateAreas: `
        "content content"`,
  },
  crewMembers: {
    gridArea: 'crewMembers',
    margin: '20px',
    padding: 10,
    marginRight: 0,
    boxSizing: 'content-box',
    position: 'sticky',
    top: 20,
  },
});

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';

const EditOffer = props => {
  const {
    classes,
    invalidateByPatterns,
    mutate,
    popSnackbarNotification,
    pushSnackbarNotification,
    projectId,
    routerQuery: { action: routerQueryAction, ids: offerIdsString },
    routerParams: { offerId },
    history,
  } = props;
  const viewingVersionedOffer = routerQueryAction === 'version';
  const offerIds = `${offerIdsString}`.split(',');
  const [
    isReimbursementChangeAlertDisplayed,
    setIsReimbursementChangeAlertDisplayed,
  ] = useState(false);
  const flags = useFeatureFlags();
  const isUnionScheduleAFlagActive = flags.includes('UnionScheduleA');
  const isUnionWeeklyScheduleFlagActive = flags.includes('UnionWeeklySchedule');
  const [configurationLoaded, setConfigurationLoaded] = useState(false);
  const [formData, setFormData] = useState({});
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { data: offerDefaults } = useOfferDefaults(projectId);
  const { data: offerCountry } = useOfferCountry({ offerId });
  const isCanada = offerCountry?.code === 'CA';
  const { countryId } = offerDefaults || {};
  const { data: states } = useStates(countryId);
  const { data: offersData = [], loading } = useOffers(projectId, offerIds);
  const { nodes: offers = [] } = offersData;

  if (loading || !offers || !offerDefaults || !states) return <Loader />;

  const reviewerQueue = offers.filter(({ status }) => status === 'draft');
  const reviewedOffers = offers.filter(({ status }) => status !== 'draft');
  const offerInReview = offers.find(({ id }) => id === offerId) || {};
  const { projectUser: { id: userId = '', profile = {} } = {} } = offerInReview;
  const { firstName = '', lastName = '', phone = '', email = '' } =
    profile || {};
  const { backdateLimit, sendDateConfiguration } = offerDefaults;
  const { offerDate: offerDateFormData = {} } = formData || {};
  const isStartDateBeforeBackDateLimit = (() => {
    if (backdateLimit == null) return false;
    const { startDateObject } = offerDateFormData;
    if (!startDateObject) return false;
    const daysSinceStartDate = moment().diff(startDateObject, 'days');
    return daysSinceStartDate > backdateLimit;
  })();

  const checkUpdatedDefaultReimbursements = () => {
    const {
      status: offerStatus,
      reimbursementsUpdated = false,
    } = offerInReview;
    if (
      offerStatus === 'success' &&
      reimbursementsUpdated &&
      !isReimbursementChangeAlertDisplayed
    ) {
      setIsReimbursementChangeAlertDisplayed(true);
      pushSnackbarNotification({
        message: "This project's default reimbursement settings have changed.",
        variant: SnackbarVariants.WARNING,
        duration: 6000,
      });
    }
  };

  const initializeOfferForm = () => {
    const {
      isTermsOfEmploymentV2,
      termsOfHire = {},
      allowances = {},
      termsOfEmployment = {},
      termsOfEmploymentV2 = {},
      documents = [],
      documentFields = [],
      accountCodes = [],
      startDate,
      endDate,
      sendDate,
      notes,
      additionalNotes = [],
    } = offerInReview;
    const {
      union,
      occupation,
      workSchedule,
      workState,
      hireState,
      workCity,
      hireCity,
      department,
      hiringStatus,
      ...restTermsOfHire
    } = termsOfHire || {};
    const { code: occupationCode, description: occupationDescription } =
      occupation || {};
    const { code: workStateCode } = workState || {};
    const { code: hireStateCode } = hireState || {};
    const { code: workCityCode } = workCity || {};
    const { code: hireCityCode } = hireCity || {};
    const { id: departmentId } = department || {};
    const {
      code: unionCode,
      description: unionDescription,
      isNonUnion: unionIsNonUnion,
    } = union || {};
    const { code: workScheduleCode, description: workScheduleDescription } =
      workSchedule || {};
    delete restTermsOfHire.__typename;

    let sendDateObject = (sendDate && moment(sendDate, DATE_FORMAT)) || null;
    const sendDateIsInPast =
      sendDateObject && sendDateObject.isBefore(moment(), 'day');
    if (sendDate && sendDateIsInPast) sendDateObject = moment();
    const isLinkActive =
      sendDateConfiguration === 'start_date' &&
      sendDateObject.isSame(moment(startDate, DATE_FORMAT));

    const formattedOfferDate = {
      startDate: moment(startDate, DATE_FORMAT).format('LL'),
      startDateObject: moment(startDate, DATE_FORMAT),
      sendDateObject: sendDateObject || sendDate,
      sendDate:
        (sendDate && moment(sendDate, DATE_FORMAT).format('LL')) || null,
      endDateObject: (endDate && moment(endDate, DATE_FORMAT)) || endDate,
      endDate: (endDate && moment(endDate, DATE_FORMAT).format('LL')) || null,
      isLinkActive,
    };

    const updatedAllowances = Object.entries(allowances).reduce(
      (allowancesObject, [allowanceKey, allowance]) => {
        if (allowanceKey === '__typename') return allowancesObject;
        allowancesObject[allowanceKey] = {
          ...allowance,
          amount: allowance.amount ? formatCurrency(allowance.amount) : null,
        };
        return allowancesObject;
      },
      {},
    );

    const hireStateObject =
      states.find(({ code }) => code === hireStateCode) || {};
    const workStateObject =
      states.find(({ code }) => code === workStateCode) || {};
    const formattedTermsOfHire = {
      ...restTermsOfHire,
      hireStateId: hireStateObject.id,
      workStateId: workStateObject.id,
      hireCity: hireCityCode,
      workCity: workCityCode,
      hireState: hireStateCode,
      workState: workStateCode,
      department: departmentId,
      hiringStatus: hiringStatus,
      occupation: {
        value: occupationCode,
        label: occupationDescription,
      },
      union: {
        value: unionCode,
        label: unionDescription,
        isNonUnion: unionIsNonUnion,
      },
      workSchedule: {
        value: workScheduleCode,
        label: workScheduleDescription,
      },
    };
    const isUnionScheduleA =
      workScheduleCode === 'A' &&
      !unionIsNonUnion &&
      isUnionScheduleAFlagActive;

    const isUnionWeeklySchedule =
      isUnionWeeklyScheduleCode(workScheduleCode) &&
      isUnionWeeklyScheduleFlagActive &&
      !unionIsNonUnion;

    let mergedTermsOfEmployment;
    if (
      isTermsOfEmploymentV2 &&
      (isUnionScheduleA || isUnionWeeklySchedule) &&
      !isCanada
    ) {
      mergedTermsOfEmployment = {
        ...termsOfEmploymentV2,
        isTermsOfEmploymentV2: true,
      };
    } else if (
      (isTermsOfEmploymentV2 && !isUnionScheduleA) ||
      (isTermsOfEmploymentV2 && !isUnionWeeklySchedule) ||
      isCanada
    ) {
      mergedTermsOfEmployment = {
        ...termsOfEmployment,
        ...termsOfEmploymentV2,
      };
    } else {
      mergedTermsOfEmployment = termsOfEmployment;
    }

    const formattedTermsOfEmployment = Object.fromEntries(
      Object.entries(mergedTermsOfEmployment).map(([key, value]) => [
        key,
        key.toLowerCase().includes('rate') && value
          ? formatCurrency(value)
          : value,
      ]),
    );

    const dealNotes = [...additionalNotes];
    if (notes) dealNotes.unshift({ notes, current: true });

    setFormData({
      offerIds,
      offerDate: formattedOfferDate,
      termsOfHire: formattedTermsOfHire,
      termsOfEmployment: formattedTermsOfEmployment,
      allowances: updatedAllowances,
      documents,
      documentFields,
      accountCodes,
      dealNotes,
    });

    if (sendDate && sendDateIsInPast) {
      pushSnackbarNotification({
        message:
          "The saved send date was in the past so it has been changed to today's date. Send date must be today or future date.",
        variant: SnackbarVariants.WARNING,
        duration: 6000,
      });
    }
  };

  const confirmOffer = () => {
    const loadingMessage = 'Submitting Offer Now...';
    const { newScaleRates: scaleRates = {} } = formData;

    const { union, workSchedule } = offerInReview?.termsOfHire || {};
    const { code: workScheduleCode } = workSchedule || {};
    const { isNonUnion: unionIsNonUnion } = union || {};

    const isUnionWeeklyScheduleV2 =
      isUnionWeeklyScheduleCode(workScheduleCode) &&
      isUnionWeeklyScheduleFlagActive &&
      !unionIsNonUnion;

    let modifiedFormData = {
      ...formData,
      termsOfEmployment: {
        ...formData?.termsOfEmployment,
        isTermsOfEmploymentV2:
          isUnionWeeklyScheduleV2 ||
          formData?.termsOfEmployment?.isTermsOfEmploymentV2,
      },
    };
    const variables = getNormalizedMutationPayload(modifiedFormData);

    // Scale Rates
    const toe = variables?.termsOfEmployment || {};
    const isCanada = offerDefaults?.countryCode === 'CA';
    if (Object.keys(scaleRates).length > 0) {
      toe.scaleRates = isCanada ? {} : scaleRates;
      delete toe.scaleRates.__typename;
      delete toe.scaleRates.scaleRateHash;
    } else {
      toe.scaleRates = {};
    }
    // Additional processing of mutation payload
    variables.id = parseInt(offerId, 10);
    variables.projectId = projectId;
    variables.crew = [parseInt(userId)];

    popSnackbarNotification();
    pushSnackbarNotification({
      message: loadingMessage,
      variant: SnackbarVariants.INFO,
    });
    mutate({
      variables,
    })
      .then(({ data, message = '' }) => {
        setSubmitInProgress(true);
        popSnackbarNotification({
          message: loadingMessage,
          popAllMatching: true,
        });
        if (message.length) {
          pushSnackbarNotification({
            message: `There was an error submitting the offer${
              !message ? '.' : `: ${message}.`
            }`,
            variant: SnackbarVariants.ERROR,
          });
          return;
        }
        pushSnackbarNotification({
          message: 'Offer successfully submitted',
          variant: SnackbarVariants.SUCCESS,
        });
        invalidateByPatterns([
          `projects/${projectId}/offers`,
          `projects/${projectId}/reviews`,
        ]);
        const currentOfferIndexInQueue = offerIds.indexOf(offerId);
        const nextOfferId = offerIds[currentOfferIndexInQueue + 1];
        // If there are remaining offers in the reviewer
        // queue, load in the next offer
        if (nextOfferId) {
          history.push({
            pathname: `/projects/${projectId}/offers/${nextOfferId}/edit`,
            search: qs.stringify({ ids: offerIds }),
          });
        } else {
          history.push(`/projects/${projectId}/offers`);
        }
      })
      .catch(err => {
        setSubmitInProgress(false);
        const { graphQLErrors, message } = err;
        graphQLErrors.forEach(console.warn);
        popSnackbarNotification({
          message: loadingMessage,
          popAllMatching: true,
        });
        pushSnackbarNotification({
          message: `There was an error submitting the offer${
            !message ? '.' : `: ${message}.`
          }`,
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  if (!configurationLoaded) {
    setConfigurationLoaded(true);
    checkUpdatedDefaultReimbursements();
    initializeOfferForm();
  }

  const workScheduleCode = offerInReview?.termsOfHire?.workSchedule?.code;

  return (
    <div
      className={classNames(classes.root, {
        [classes.withCrewMemberSidebar]: !viewingVersionedOffer,
        [classes.withoutCrewMemberSidebar]: viewingVersionedOffer,
      })}
    >
      {!viewingVersionedOffer && (
        <CrewMemberReviewerList
          className={classes.crewMembers}
          reviewerQueue={reviewerQueue}
          reviewedOffers={reviewedOffers}
          offerIdInReview={offerId}
        />
      )}
      <OfferForm
        headerTitle="Review Offer"
        headerContent={
          <React.Fragment>
            <Typography
              variant="h6"
              data-test-id="EditOffer-crewMember"
              className={classNames(
                classes.crewMemberName,
                classes.crewMemberContactInfo,
              )}
            >{`Crew Member: ${firstName} ${lastName}`}</Typography>
            {!!phone && (
              <Typography
                variant="h6"
                data-test-id="EditOffer-phoneNumber"
                className={classes.crewMemberContactInfo}
              >{`Phone: ${phone}`}</Typography>
            )}
            <Typography
              variant="h6"
              data-test-id="EditOffer-email"
              className={classes.crewMemberContactInfo}
            >
              {`Email: ${email}`}
            </Typography>
          </React.Fragment>
        }
        onSubmit={confirmOffer}
        submitInProgress={submitInProgress}
        formData={formData}
        isReviewOffer
        isTermsOfHireDisabled={isStartDateBeforeBackDateLimit}
        setFormData={setFormData}
        workScheduleCode={workScheduleCode}
      />
    </div>
  );
};

export default compose(
  withPermissionProtection(['can_review_offers', 'can_create_offers']),
  withSnackbarNotification,
  withApi,
  graphql(Mutations.SubmitOffer),
  withStyles(styles),
)(EditOffer);
