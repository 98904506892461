import React from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Button from 'common/oldJavascripts/components/Base/Button';
import Header from 'common/oldJavascripts/components/Base/Header';
import Modal from 'common/oldJavascripts/components/Base/Modal';
import Text from 'common/oldJavascripts/components/Base/Text';

const styles = {
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    padding: '20px',
    width: '100%',
    justifyContent: 'space-around',
  },
};

const VerificationModal = props => {
  const { data: profile, classes, onReject, onConfirm } = props;
  const birthDate = moment(profile.birth_date, 'YYYY-MM-DD').format(
    'MM-DD-YYYY',
  );

  return (
    <Modal>
      <Header>
        <Header.Title quaternary={true}>
          Please double check your information, this will be the last place{' '}
          <br />
          you'll be able to update some of your information.
        </Header.Title>
      </Header>
      <BoxItem>
        <div>
          <Text bold={true}>Full Legal Name:</Text>{' '}
          <Text>{`${profile.first_name} ${profile.middle_name} ${profile.last_name}`}</Text>
        </div>
        <div>
          <Text bold={true}>Social Security #:</Text>{' '}
          <Text>{profile.ssn_mask}</Text>
        </div>
        <div>
          <Text bold={true}>Date of Birth:</Text> <Text>{birthDate}</Text>
        </div>
      </BoxItem>
      <BoxItem nopadding={true}>
        <div className={classes.buttonContainer}>
          <Button cancel={true} onClick={onReject}>
            Go Back
          </Button>
          <Button onClick={onConfirm}>Continue</Button>
        </div>
      </BoxItem>
    </Modal>
  );
};

export default withStyles(styles)(VerificationModal);
