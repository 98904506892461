import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Done as DoneIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import ProjectTemplateAutoAssignmentRuleDisplay from './ProjectTemplateAutoAssignmentRuleDisplay';
import ProjectTemplateAutoAssignmentRuleEditor from './ProjectTemplateAutoAssignmentRuleEditor';

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing.unit * 0.5,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const ProjectTemplateAutoAssignmentRule = props => {
  const {
    classes,
    onToggleEdit,
    isBeingEdited,
    rule,
    rule: { id },
    onDelete,
    onChange,
    projectCountryId,
  } = props;

  return (
    <div
      className={classes.root}
      data-test-id={`ProjectTemplateAutoAssignmentRule-root-${id}`}
    >
      {isBeingEdited ? (
        <ProjectTemplateAutoAssignmentRuleEditor
          rule={rule}
          onChange={onChange}
          projectCountryId={projectCountryId}
        />
      ) : (
        <ProjectTemplateAutoAssignmentRuleDisplay rule={rule} />
      )}
      <div className={classes.buttonRow}>
        <IconButton
          onClick={onToggleEdit}
          data-test-id={`ProjectTemplateAutoAssignmentRule-edit-${id}`}
        >
          {isBeingEdited ? <DoneIcon /> : <EditIcon />}
        </IconButton>
        <IconButton
          onClick={onDelete}
          data-test-id={`ProjectTemplateAutoAssignmentRule-delete-${id}`}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRule);
