import React from 'react';
import ajax from 'common/utilities/ajax.js';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Button from 'common/oldJavascripts/components/Base/Button';
import Modal from 'common/oldJavascripts/components/Base/Modal';
import Loader from 'common/components/Loader';
import AddProjectDocument from 'admin/components/AddProjectDocument';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  closeButton: {
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    textDecoration: 'underline',
    fontSize: '1rem',
  },
};

const AttachTemplateWrapper = Relay.createClass({
  statics: {
    queries: {
      project: {
        info(_, related) {
          const { projectId } = related['/router/params'];
          return {
            id: `/projects/${projectId}`,

            cursor: {
              with_privileges: true,
            },
          };
        },
      },
    },
  },

  getInitialState() {
    return {
      isModalOpen: false,
      isDataReady: false,
      templateProp: '',
      isFailed: false,
      isSaving: false,
      saveSuccess: false,
    };
  },

  _errorHandler() {
    this.setState({ isFailed: true });
  },

  _openModal() {
    this.setState({
      isModalOpen: true,
      isDataReady: false,
      isFailed: false,
    });

    this._getTemplates();
  },

  _closeModal() {
    this.setState({
      isModalOpen: false,
      isSaving: false,
      saveSuccess: false,
    });
    return false;
  },

  _getTemplates() {
    const { projectId } = this.props;
    const url = `/workflow/projects/${projectId}/templates`;
    ajax.get(url).then(data => {
      const documentsProp = data
        .filter(t => t.fieldsMapped)
        .map(t => {
          return {
            id: t.id,
            name: t.name,
            tags: t.categories.map(cat => cat.name),
            mapped: t.fieldsMapped,
            expired: t.expired,
          };
        });

      this.setState({ isDataReady: true, documentsProp: documentsProp });
    }, this._errorHandler);
  },

  _submit(templateIds) {
    const { projectId, attachSuccessHandler } = this.props;
    const url = `/workflow/projects/${projectId}/templates`;
    this.setState({ isSaving: true, saveSuccess: false });
    ajax
      .post(url, { template_id: templateIds }, { json: true })
      .then(() => {
        this.setState({ saveSuccess: true });
        this._closeModal();
        attachSuccessHandler(templateIds);
      })
      .catch(this._errorHandler)
      .finally(() => this.setState({ isSaving: false }));
  },

  _renderModalContent() {
    const { project, classes } = this.props;
    const { name: title } = project.data;
    const {
      isDataReady,
      documentsProp,
      isFailed,
      isSaving,
      saveSuccess,
    } = this.state;

    if (isFailed) {
      return (
        <div>
          <FailureAlert />
          <button className={classes.closeButton} onClick={this._closeModal}>
            Close
          </button>
        </div>
      );
    }

    if (!isDataReady) {
      return (
        <div>
          <Loader />
          <button className={classes.closeButton} onClick={this._closeModal}>
            Close
          </button>
        </div>
      );
    }

    if (isDataReady) {
      return (
        <AddProjectDocument
          submitDocuments={this._submit}
          project={{ title }}
          documents={documentsProp}
          closeHandler={this._closeModal}
          isSaving={isSaving}
          saveSuccess={saveSuccess}
        />
      );
    }

    return <FailureAlert />;
  },

  render() {
    const { isModalOpen } = this.state;
    const toRender = this._renderModalContent();

    return (
      <div>
        <Button onClick={this._openModal}>Add Documents</Button>
        {isModalOpen && <Modal>{toRender}</Modal>}
      </div>
    );
  },
});

export default withStyles(styles)(AttachTemplateWrapper);
