import React, { useEffect } from 'react';

// HoC
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';

// Graphql
import { Queries } from 'common/apollo';

// Components
import List from '@material-ui/core/List';
import CrewMember from 'studio/components/OfferPage/CrewMembers/CrewMember';

const styles = theme => ({
  root: {
    overflowY: 'auto',
  },
});

/**
 * If `setVariables` was called and a query was fired because of that then the network status
 * will be `setVariables` until the result of that query comes back.
 */
const SET_QUERY_VARIABLES = 2;
/**
 * Indicates that `fetchMore` was called on this query and that the query created is currently in
 * flight.
 */
const FETCH_MORE_QUERY = 3;
/**
 * Similar to the `setVariables` network status. It means that `refetch` was called on a query
 * and the refetch request is currently in flight.
 */
const REFETCH_QUERY = 4;
/**
 * No request is in flight for this query, and no errors happened. Everything is OK.
 */
const QUERY_IS_READY = 7;

const CrewList = props => {
  const {
    data: {
      project = {},
      networkStatus,
      loading,
      variables: graphqlVariables,
      fetchMore,
    } = {},
    isSelected,
    search,
    setIsLoading,
    onChange,
    classes = {},
  } = props;
  const { search: graphqlSearch } = graphqlVariables || {};
  const { crew: { edges = [] } = {} } = project || {};
  const displayCrewList =
    networkStatus === SET_QUERY_VARIABLES ||
    networkStatus === FETCH_MORE_QUERY ||
    networkStatus === QUERY_IS_READY;

  const crew = displayCrewList ? edges : [];

  useEffect(() => {
    if (displayCrewList) setIsLoading(false);
  }, [search, networkStatus, graphqlSearch, setIsLoading, displayCrewList]);

  const onScroll = ({ currentTarget }) => {
    if (loading || networkStatus === REFETCH_QUERY) return;
    const { scrollTop, scrollHeight, clientHeight } = currentTarget || {};
    const numberOfUserInList = edges.length;
    const sizeOfListItem = scrollHeight / numberOfUserInList;
    if (clientHeight + scrollTop > scrollHeight - sizeOfListItem * 10) {
      const { cursor: after } = edges[edges.length - 1] || {};

      fetchMore({
        variables: {
          after,
          search,
        },
        // Update query runs twice during fetchMore so we
        // check the existing crew list for possible
        // duplicates when updating the query cache
        updateQuery: (prev, { fetchMoreResult }) => {
          const { project: fetchMoreProject = {} } = fetchMoreResult || {};
          const { crew: { edges: fetchMoreEdges = [] } = {} } =
            fetchMoreProject || {};
          if (!fetchMoreEdges.length) return prev;
          return {
            ...prev,
            project: {
              ...prev.project,
              crew: {
                ...prev.project.crew,
                edges: [
                  ...prev.project.crew.edges,
                  ...fetchMoreEdges.filter(
                    ({ node: { id: crewId } }) =>
                      !prev.project.crew.edges.some(
                        ({ node: { id: prevId } }) => prevId === crewId,
                      ),
                  ),
                ],
              },
            },
          };
        },
      });
    }
  };
  return (
    <List
      className={classes.root}
      onScroll={onScroll}
      data-test-id="CrewList-root"
    >
      {crew.map(({ node = {} } = {}, index) => (
        <CrewMember
          key={node.id}
          {...node.profile}
          onClick={() => onChange(node)}
          selected={isSelected(node.id)}
        />
      ))}
    </List>
  );
};

const getCrewMembersConfig = {
  options: ({
    match: { params: { projectId } = {} } = {},
    search = '',
    filters: { withOffers, withoutOffers, sort, sortField } = {},
  }) => ({
    variables: {
      id: parseInt(projectId, 10),
      first: 20,
      after: '',
      search,
      withOffers,
      withoutOffers,
      sort,
      sortField,
    },
    fetchPolicy: 'network-only',
  }),
};

export default compose(
  withRouter,
  graphql(Queries.GetCrewMembers, getCrewMembersConfig),
  withStyles(styles),
)(CrewList);
