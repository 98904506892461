const isRequiredSelection = label => `${label} is a required selection`;

const messages = {
  startDate: isRequiredSelection('Start Date'),
  sendDate: isRequiredSelection('Send Date'),
  backdateLimit: 'Start Date is beyond the configured backdate limit',
  season: isRequiredSelection('Season'),
  hireState: isRequiredSelection('Hire State'),
  hireCity: isRequiredSelection('Hire City'),
  workState: isRequiredSelection('Work State'),
  workCity: isRequiredSelection('Primary Work City'),
  union: isRequiredSelection('Union'),
  occupation: isRequiredSelection('Occupation'),
  workSchedule: isRequiredSelection('Work Schedule'),
  department: isRequiredSelection('department'),
  currency: isRequiredSelection('Currency'),
  employmentClassification:
    'This occupation is not available for the Loan Out employment classification',
  termsOfEmployment: 'Pay rate and overtime rate are required',
  accountCodes:
    'Account codes are required, please open the dialog to view which fields are required',
  documents: 'At least one document must be selected for this offer',
  documentFields_CMB: 'Please make a selection',
  documentFields_RDO: 'Please make a selection',
  documentFields_TXT: 'Please enter a value',
  allowance_missing_amount:
    'Please enter an amount or remove cap and duration.',
  allowance_per: "Please make a selection for 'Per'",
  allowance_cap_exceeded: 'Maximum cap amount allowed is $999,999.99',
  rate: 'Please fill in at least one rate value',
  rateDistant: 'Rate is a required field',
  locationRatePerHour: true,
  locationRatePerWeek: true,
  studioRatePerHour: true,
  studioRatePerWeek: true,
  overtimeRate: 'Overtime Rate is required with any rate per hour.',
  overtimeRatePrCan: 'Overtime Rate is required',
  overtimeRatePerHour: true,
  ratePerHour: 'Rate is a required field',
  rateStudioDistantRequired: 'Studio & Distant Rate Required',
  dailyRate: 'Please fill in at least one rate value',
  weeklyRate: 'Please fill in at least one rate value',
  accountCode: true,
  nonUnionHourlyRate: 'Rate is below minimum wage',
  nonUnionOvertimeRatePerHour: true,
  nonUnionHourlyRateRequired: 'Please fill in at least one rate value',
  negotiatedContractId: isRequiredSelection('Use Negotiated Contract Terms'),
  hoursPerDay: 'Hours Per Day is required',
  studioDistantHoursPerDay: 'Studio & Distant Hours Required',
  hoursPerWeek: 'Hours Per Week is required',
  rateBelowScale: 'Rate is below scale. Unable to create offer',
  guaranteedHoursBelowScale:
    'Lower than contractual guarantee. Can’t create offer.',
  guaranteedHours: 'Guaranteed hours is required',
  weeklyRateDistant: 'Weekly rate is required',
};

export default (key, value) => {
  if (!value) return messages[key];
  return `${messages[key]} (${value})`;
};
