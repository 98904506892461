import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ViewIcon from '@material-ui/icons/RemoveRedEye';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateRows: '36px 364px',
    gap: '10px',
    height: '100%',
  },
  paper: {
    height: '100%',
    overflowY: 'auto',
    padding: '20px',
  },
  documentName: {
    fontSize: '1.23em',
  },
});

const DocumentList = props => {
  const {
    classes = {},
    onChange,
    documents = [],
    onViewClick,
    header,
    selectedDocuments = [],
    'data-test-id': dataTestId,
  } = props;

  const getIsSelected = id => selectedDocuments.includes(id);

  return (
    <div className={classes.root} data-test-id={dataTestId}>
      <Typography variant="h6">{header}</Typography>
      <Paper className={classes.paper}>
        <List>
          {documents.map(({ id, name, required }) => (
            <ListItem
              key={id}
              role={undefined}
              dense
              button
              onClick={() => {
                if (!required) onChange(id);
              }}
              data-test-id={`DocumentList-listItem-${id}`}
            >
              <Checkbox
                checked={getIsSelected(id) || required}
                tabIndex={-1}
                disableRipple
                disabled={required}
                data-test-id={`DocumentList-checkbox-${id}`}
              />
              <ListItemText primary={name} className={classes.documentName} />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Comments"
                  onClick={() => onViewClick(id)}
                  data-test-id={`DocumentList-iconButton-${id}`}
                >
                  <ViewIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(DocumentList);
