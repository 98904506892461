import React, { Component } from 'react';
import Preloader from '../../../Shared/Preloader';
import raven from 'raven-js';
import { withStyles, Typography } from '@material-ui/core';
import AddressSearch from './AddressSearch';
import SelectedAddress from './SelectedAddress';
import AddressSuggestions from './AddressSuggestions';
import CouldNotDetermineAddress from './CouldNotDetermineAddress';
import ajax from 'common/utilities/ajax';
import classNames from 'classnames';

const styles = {
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 45px',
    gridTemplateColumns: '1fr 50% 1fr',
    gridTemplateAreas: `
    ". content ."
    ". errors ."
    `,
    height: '100%',
    minHeight: '250px',
    maxHeight: '600px',
  },
  content: {
    paddingTop: '15px',
    gridArea: 'content',
  },
  errors: {
    gridArea: 'errors',
    placeSelf: 'center',
  },
};

class AddressInput extends Component {
  constructor(props) {
    super(props);
    const { address = {} } = props;
    this.state = {
      selectedAddress: '', // Placed in state so that the retry mechanism can pull the current selected address
      addressSuggestions: [],
      fullAddressRetryCount: 0,
      fullAddressReceived: false,
      couldNotDetermineAddress: false,
      tryingToDetermineAddress: true,
      fullAddress: {},
      prepopulatedAddress: address,
      error: '',
      suiteOverrideOpen: false,
      suiteOverride: '',
    };
    this.maxRetries = 3;
  }

  componentDidMount() {
    const { address = {} } = this.props;
    this.hydrateProfileAddress(address);
  }

  hydrateProfileAddress = (prepopulatedAddress = {}) => {
    // We take the address that the api gives us
    // and try to resolve it into a address from loqate
    const { loqate_id: loqateId, city, zip_code: zipCode } =
      prepopulatedAddress || {};
    if (loqateId) {
      this.updateUpstream(prepopulatedAddress);
    } else if (city && zipCode) {
      this.findUnkownAddress(prepopulatedAddress);
    } else {
      this.setState({ tryingToDetermineAddress: false });
    }
  };

  findUnkownAddress = prepopulatedAddress => {
    // We couldnt find the address or there is no loqate id from API
    const { street, city, zip_code: zipCode } = prepopulatedAddress;
    const searchText = `${street} ${city} ${zipCode}`;
    this.getAddressSuggestions({ Text: searchText })
      .then(addressSuggestions => {
        this.setState({
          addressSuggestions,
          couldNotDetermineAddress: true,
          tryingToDetermineAddress: false,
        });
      })
      .catch(e => {
        console.warn(e);
        this.setState({
          error: e,
          couldNotDetermineAddress: true,
          tryingToDetermineAddress: false,
        });
        raven.captureException(e);
      });
  };

  clearAddress = () => {
    const { onChange: upstreamOnChange } = this.props;
    this.setState({ fullAddress: {} });
    try {
      upstreamOnChange({ use_primary: false });
    } catch (e) {
      raven.captureException(e);
    }
  };

  onSearchInputChange = Text => {
    if (Text.length === 0) return;
    this.getAndUpateStateWithSuggestions({ Text });
  };

  getAddressSuggestions = ({ Text = '', Container }) => {
    /* set default empty string for Text in the event that the user makes a selection
       with multiple addresses, eg. apartment complex
    */
    const zipStripped = Text.replace(/([\d]{5})-[\d]+/, '$1');
    const params = {
      Key: 'MW69-WM98-YW83-PD49',
      Language: 'en',
      Text: zipStripped,
      Limit: Text ? 10 : undefined,
      Container,
      Countries: 'US',
    };
    return ajax
      .get(
        `https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json3.ws`,
        params,
      )
      .then(({ Items }) => Items);
  };

  getStateId = loqateCode => {
    const { states = [] } = this.props;
    const selectedState = states.find(({ code }) => code === loqateCode) || {};
    try {
      const { id = '' } = selectedState;
      return id;
    } catch (e) {
      raven.captureException(e, {
        extra: {
          message: `Could not find state specified by loqate ProvinceCode. Passed ${loqateCode}`,
        },
      });
    }
  };

  getStateCode = requestedId => {
    const { states = [] } = this.props;
    const selectedState = states.find(({ id }) => id === requestedId) || {};
    try {
      const { code = '' } = selectedState;
      return code;
    } catch (e) {
      raven.captureException(e, {
        extra: {
          message: `Could not find state specified by Id. Passed ${requestedId}.`,
        },
      });
    }
  };

  getFullAddress = Id => {
    return new Promise((resolve, reject) => {
      const params = {
        Key: 'MW69-WM98-YW83-PD49',
        Language: 'en',
        Id,
      };
      ajax
        .get(
          `https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws`,
          params,
        )
        .then(({ Items }) => {
          const [address] = Items;
          resolve(address);
        })
        .catch(e => {
          // Error passed back from Ajax is undefined for some reason
          reject('Could not load Address from Loqate.');
        });
    });
  };

  getFullAddressFallback = error => {
    console.warn(error);
    const { maxRetries, state } = this;
    const { fullAddressRetryCount } = state;
    if (fullAddressRetryCount >= maxRetries) {
      return this.setState({ error });
    }

    this.setState(
      ({ fullAddressRetryCount }) => ({
        fullAddressRetryCount: fullAddressRetryCount + 1,
      }),
      this.saveAddress,
    );
  };

  formatLoqateAddress = address => {
    const {
      Id,
      SubBuilding,
      City,
      ProvinceCode,
      Street,
      BuildingNumber,
      PostalCode,
      Line1,
    } = address;
    const getStreet = () => {
      if (
        Street.length === 0 &&
        BuildingNumber.length === 0 &&
        SubBuilding.length === 0
      )
        return Line1;
      return `${BuildingNumber} ${Street}`;
    };
    return {
      loqate_id: Id,
      street: getStreet(),
      suite: SubBuilding,
      city: City,
      zip_code: PostalCode,
      state_id: this.getStateId(ProvinceCode),
    };
  };

  updateUpstream = address => {
    const { onChange: upstreamOnChange } = this.props;
    const { loqate_id: loqateId } = address;
    this.setState({
      error: '',
      fullAddressRetryCount: 0,
      fullAddress: address,
      selectedAddress: loqateId,
      tryingToDetermineAddress: false,
      couldNotDetermineAddress: false,
    });
    try {
      upstreamOnChange(address);
    } catch (e) {
      raven.captureException(e);
    }
  };

  saveAddress = (Id = this.state.selectedAddress) => {
    this.getFullAddress(Id)
      .then(address => this.updateUpstream(this.formatLoqateAddress(address)))
      .catch(this.getFullAddressFallback);
  };

  saveAddressSuite = () => {
    const { onChange: upstreamOnChange } = this.props;
    // Close Suite Override
    this.setState(({ suiteOverride, fullAddress }) => {
      const updatedAddress = Object.assign({}, fullAddress, {
        suite: suiteOverride,
      });
      try {
        upstreamOnChange(updatedAddress);
      } catch (e) {
        raven.captureException(e);
      }
      return {
        fullAddress: updatedAddress,
        suiteOverride: '',
        suiteOverrideOpen: false,
      };
    });
  };

  getAndUpateStateWithSuggestions = ({ Container, Text }) => {
    this.getAddressSuggestions({ Container, Text })
      .then(addressSuggestions => {
        return this.setState({ addressSuggestions }, this.scrollUpSuggestions);
      })
      .catch(raven.captureException);
  };

  scrollUpSuggestions = () => {
    return;
    // TODO: Upgrade react so that we can use refs
    // Our version only works with string refs
    // No documentation available on how to make this work with a ref in a child component
    //
    // const { addressSuggestionsMenu } = this.refs;
    // const { addressSuggestionsMenu } = this;
    // const { scrollTop, scrollTo } = addressSuggestionsMenu;
    // if (scrollTop === 0) return; // Already at the top
    // scrollTo(0, 0);
  };

  selectSuggestion = ({ Id, Type }) => {
    if (Type !== 'Address') {
      return this.getAndUpateStateWithSuggestions({ Container: Id });
    }
    this.saveAddress(Id);
    this.setState({ selectedAddress: Id });
  };

  endAddressMatch = () => {
    this.setState({
      addressSuggestions: [],
      tryingToDetermineAddress: false,
      couldNotDetermineAddress: false,
    });
  };

  renderAddressSuggestions = closeAction => {
    const { addressSuggestions } = this.state;
    return (
      <AddressSuggestions
        addresses={addressSuggestions}
        selectSuggestion={this.selectSuggestion}
        closeAction={closeAction}
      />
    );
  };

  clearSuggestions = () => {
    this.setState({
      addressSuggestions: [],
    });
  };

  handleSuiteOverrrideKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.saveAddressSuite();
    }
    if (event.keyCode === 27) {
      // Button clicked was escape
      event.preventDefault();
      this.openSuiteOverride(false);
    }
  };

  updateSuiteOverride = value => {
    this.setState({ suiteOverride: value });
  };
  openSuiteOverride = value => {
    this.setState({ suiteOverrideOpen: value, suiteOverride: '' });
  };

  renderContent = () => {
    const { type, classes } = this.props;
    const {
      fullAddress = {},
      tryingToDetermineAddress,
      couldNotDetermineAddress,
      suiteOverrideOpen,
      suiteOverride,
    } = this.state;

    const { city } = fullAddress;

    // We are trying to get the inita data we need from loqate and the api
    if (tryingToDetermineAddress) return <Preloader />;

    // We got an address from the api, but its not a loqate address
    if (couldNotDetermineAddress) {
      return (
        <CouldNotDetermineAddress
          className={classes.content}
          renderAddressSuggestions={() =>
            this.renderAddressSuggestions(this.endAddressMatch)
          }
        />
      );
    }
    // We determine whether the full address has been selected using
    // the city key from the full address
    if (!city) {
      return (
        <AddressSearch
          className={classes.content}
          onSearchInputChange={this.onSearchInputChange}
          renderAddressSuggestions={() =>
            this.renderAddressSuggestions(this.clearSuggestions)
          }
        />
      );
    }
    // Either the user seleced an address in this session
    // Or we got an address from the api thats a loqate address
    return (
      <SelectedAddress
        className={classes.content}
        getStateCode={this.getStateCode}
        clearAddress={this.clearAddress}
        fullAddress={fullAddress}
        suiteOverrideOpen={suiteOverrideOpen}
        updateSuiteOverride={this.updateSuiteOverride}
        handleSuiteOverrrideKeyPress={this.handleSuiteOverrrideKeyPress}
        saveAddressSuite={this.saveAddressSuite}
        openSuiteOverride={this.openSuiteOverride}
        suiteOverrideValue={suiteOverride}
        type={type}
      />
    );
  };

  render() {
    // Render the base container
    // Let Render content figure out what content to render
    // We always show errors
    const { classes, className } = this.props;
    const { error } = this.state;
    return (
      <div className={classNames(classes.root, className)}>
        {this.renderContent()}
        <Typography color="error" classes={{ root: classes.errors }}>
          {error}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(AddressInput);
