import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// MaterialUI Components
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HighlightIcon from '@material-ui/icons/Highlight';
import ProgressSpinner from 'common/components/ProgressSpinner';

// Utilities
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import classNames from 'classnames';

const styles = {
  actionButtons: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'max-content max-content max-content 80px',
    gridColumnGap: '8px',
    alignItems: 'center',
    justifySelf: 'end',
  },
  totalFieldsMapped: {
    justifySelf: 'end',
    color: '#7b7b7b',
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
  saveButton: {
    width: '150px',
    justifySelf: 'center',
  },
  saveButtonSuccess: {
    backgroundColor: palette.success,
    '&:hover': {
      backgroundColor: palette.successHover,
    },
  },
  highlightButtonContainer: {
    borderLeft: '1px solid #7b7b7b',
    marginLeft: '8px',
  },
  highlightButton: {
    color: palette.orange,
  },
  cancelButton: {
    width: 150,
    color: palette.green,
    border: `1px solid ${palette.green}`,
    justifySelf: 'center',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
};
const ToolbarActionButtons = props => {
  const {
    classes,
    totalFieldsMappedTooltipTitle,
    saveSuccess,
    mappedFields,
    totalFields,
    saveMappings,
    isSaving,
    mapModeActive,
    toggleHighlightUnmappedFields,
    highlightUnmappedFields,
    openDiscardChangesDialog,
  } = props;

  const saveButtonClass = classNames(classes.saveButton, {
    [classes.saveButtonSuccess]: saveSuccess,
  });
  const highlightButtonClass = classNames({
    [classes.highlightButton]: highlightUnmappedFields,
  });
  return (
    <div className={classes.actionButtons}>
      <Tooltip
        title={totalFieldsMappedTooltipTitle}
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <Typography
          variant="body1"
          align="center"
          className={classes.totalFieldsMapped}
          data-test-id="RoleMapper-mappedFields"
        >
          {`${mappedFields}/${totalFields} Fields Mapped`}
        </Typography>
      </Tooltip>
      <Button
        onClick={openDiscardChangesDialog}
        variant="contained"
        data-test-id="ToolbarActionButtons-cancelButton"
        classes={{ root: classes.cancelButton }}
      >
        Cancel
      </Button>
      <Button
        className={saveButtonClass}
        onClick={saveMappings}
        disabled={isSaving || mapModeActive}
        color="primary"
        variant="contained"
        data-test-id="RoleMapperToolbar-saveButton"
      >
        Save Document
        {isSaving && <ProgressSpinner size={24} top={5} right={50} />}
      </Button>
      <Tooltip
        title="Highlight unmapped fields"
        classes={{ tooltip: classes.tooltip }}
      >
        <div className={classes.highlightButtonContainer}>
          <IconButton
            className={highlightButtonClass}
            onClick={toggleHighlightUnmappedFields}
            disabled={mapModeActive}
            data-test-id="RoleMapperToolbar-highlightFieldsButton"
          >
            <HighlightIcon />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(ToolbarActionButtons);
