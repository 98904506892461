import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import OfferDocumentsStatus from 'studio/components/OfferDocumentsStatus';
import DocumentApprovalStatus from 'studio/components/DocumentApprovalStatus';
import { MuiThemeProvider, Typography } from '@material-ui/core';

import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';

const styles = () => ({
  root: {
    width: '100%',
  },
  groupedAckowledgement: {
    marginLeft: '70px',
    marginTop: '10px',
    fontWeight: 700,
  },
});

class OfferDocuments extends Component {
  static defaultProps = {
    documentStatus: [],
    offerId: '',
  };

  state = {
    approvalDrawerOpen: false,
  };

  handleCloseApprovalDrawer = () => {
    this.setState({ approvalDrawerOpen: false });
  };

  handleOpenApprovalDrawer = documentId => {
    const { requestDocumentStatus, offerId } = this.props;
    requestDocumentStatus(offerId, documentId);
    this.setState({ approvalDrawerOpen: true });
  };

  employeeAcknowledgementText = acknowledgeType => {
    switch (acknowledgeType) {
      case 'C':
        return 'Employee acknowledgement of receipt';
      case 'G':
        return 'Employee grouped acknowledgement of receipt';
      default:
        return '';
    }
  };

  render() {
    const {
      documents = [],
      selectedDocument: {
        acknowledge_type: acknowledgeType = null,
        crew_acknowledged: crewAcknowledged = false,
      } = {},
      documentStatus = [],
      classes = {},
      previewDocument,
    } = this.props;
    const { approvalDrawerOpen } = this.state;
    const showAcknowledgement = crewAcknowledged || acknowledgeType === 'G';
    let approvalDrawer;
    if (approvalDrawerOpen && documentStatus.length > 0) {
      approvalDrawer = (
        <DocumentApprovalStatus
          open
          closeDrawer={this.handleCloseApprovalDrawer}
          data={documentStatus}
        />
      );
    }
    return (
      <MuiThemeProvider theme={documentSignerTheme}>
        <div className={classes.root}>
          <OfferDocumentsStatus
            documents={documents}
            handleDocumentSelect={this.handleOpenApprovalDrawer}
            previewDocument={previewDocument}
          />
          {approvalDrawer}
          {approvalDrawerOpen && showAcknowledgement && (
            <Typography
              className={classes.groupedAckowledgement}
              data-test-id={'OfferDocuments-groupedAckowledgement'}
              variant="h6"
            >
              {this.employeeAcknowledgementText(acknowledgeType)}
            </Typography>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(OfferDocuments);
