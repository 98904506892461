import * as Sentry from '@sentry/browser';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { REQUEST, SUCCESS, FAILURE } from 'common/store/actions/actionTypes';

// Actions
import {
  requestAccessToken,
  accessTokenSuccess,
  accessTokenFailure,
  ACCESS_TOKEN,
} from 'common/store/actions/tokens';

import {
  logout as requestLogout,
  redirectToSSO as requestRedirectToSSO,
} from 'common/store/actions/authenticate';

// Utilities
import moment from 'moment';

export const getAccessTokenExpiresAt = ({ tokens: { expiresAt } = {} }) =>
  expiresAt;

export const getAccessTokenFailureCount = ({
  tokens: { accessTokenFailureCount: failureCount } = {},
}) => failureCount;

function* sleep(time) {
  yield new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

function* getAccessToken(tokenProvider) {
  try {
    const token = yield call(tokenProvider.getAccessToken);
    Sentry.addBreadcrumb({ oktaAccessTokenResponse: token });
    const { accessToken, expiresAt: expiresAtUnix } = token || {};
    const expiresAt = moment.unix(expiresAtUnix);

    yield put(accessTokenSuccess(accessToken, expiresAt));
  } catch (e) {
    /*
    / If okta throws with access denied it means the user isnt
    / in the application_access group for this app and should be redirected
    / to mycnc.  There is a specific group per environment.
    */
    const { errorCode } = e || {};
    if (errorCode === 'access_denied') {
      yield put(requestRedirectToSSO());
      return;
    }
    if (errorCode === 'login_required') {
      yield put(requestLogout({ fromURI: 'href' }));
      return;
    }
    Sentry.captureException(e);
    yield put(accessTokenFailure());
    yield put(requestAccessToken());
  }
  try {
    yield call(tokenProvider.getAccessTokenMyCnC);
  } catch (e) {
    Sentry.captureException(e);
  }
}

function* refreshAccessToken() {
  const expiresAt = yield select(getAccessTokenExpiresAt);
  const msTillExpiration = expiresAt.diff(moment());

  yield call(sleep, msTillExpiration - 10000);
  yield put(requestAccessToken());
}

function* accessTokenFailureCheck() {
  const maxRetry = 4;
  const failureCount = yield select(getAccessTokenFailureCount);
  if (failureCount >= maxRetry)
    yield put(requestLogout({ withFromURI: false }));
}

export default function* accessTokenFlow({ token }) {
  yield all([
    takeEvery(REQUEST(ACCESS_TOKEN), getAccessToken, token),
    takeEvery(SUCCESS(ACCESS_TOKEN), refreshAccessToken, token),
    takeEvery(FAILURE(ACCESS_TOKEN), accessTokenFailureCheck),
  ]);
}
