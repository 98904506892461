import React, { useState } from 'react';
import DownloadStartPacket from './DownloadStartPacket';
import {
  Print as PrintIcon,
  Description as FileIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  navIcon: {
    marginRight: '7.5px',
    color: 'white',
    cursor: 'pointer',
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const PrintNavOptions = ({ getPrintableForm, continueOnPaper }) => {
  const [isDownloadStartPacket, setIsDownloadStartPacket] = useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <Tooltip
        title="By selecting print you have not opted out of digital on-boarding. These documents are only for review."
        data-test-id={'HeaderOfferOnboarding-printDocumentsTooltip'}
      >
        <div
          className={classNames(classes.navItem, classes.firstNavItem)}
          onClick={getPrintableForm}
          data-test-id="HeaderOfferOnboarding-printDocuments"
        >
          <PrintIcon className={classes.navIcon} />
        </div>
      </Tooltip>

      <div
        className={classes.navItem}
        onClick={() => setIsDownloadStartPacket(true)}
        data-test-id="HeaderOfferOnboarding-optOut"
      >
        <Tooltip
          title="Continue on Paper – by selecting this option you will opt out of the digital onboarding and will be required to complete and sign your offer on paper and submit your documents in person."
          data-test-id={'HeaderOfferOnboarding-completeOnPaperTooltip'}
        >
          <FileIcon className={classes.navIcon} />
        </Tooltip>
      </div>
      {isDownloadStartPacket && (
        <DownloadStartPacket
          paperOption={continueOnPaper}
          onClose={setIsDownloadStartPacket}
        />
      )}
    </React.Fragment>
  );
};

export default PrintNavOptions;
