import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress } from '@material-ui/core';
import { Input, InputLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Done as ConfirmIcon } from '@material-ui/icons';
import isEqual from 'lodash.isequal';

const styles = theme => ({
  root: {
    width: 150,
    marginRight: 50,
  },
  text: {
    paddingTop: 12,
  },
  input: { width: 100 },
  submit: { verticalAlign: 'bottom' },
  circularProgress: {
    marginLeft: theme.spacing.unit,
  },
});

class DocumentRank extends Component {
  state = {
    rank: this.props.rank,
  };

  componentWillReceiveProps(newProps) {
    if (this.props.editMode || isEqual(this.props, newProps)) return;
    this.setState({ rank: newProps.rank });
  }

  handleChange = (name, value) => {
    let updatedValue = value;
    if (name === 'rank') {
      if (value === '0') {
        updatedValue = 1;
      }
      if (value === '') {
        updatedValue = '';
      }
    }
    this.setState({
      [name]: updatedValue,
    });
  };

  handleKeyDown = event => {
    const { value } = event.target;
    if (
      value === '' &&
      (event.key === 'e' || event.key === '-' || event.key === '0')
    ) {
      event.stopPropagation();
      event.preventDefault();
      this.handleChange('rank', value);
    }
  };

  handleKeyPress = ({ key }) => {
    const { rank } = this.state;
    if (key === 'Enter' && rank !== '') {
      this.handleSubmit();
    }
  };

  handleSubmit = (event = {}) => {
    if (event.stopPropagation) event.stopPropagation();
    if (event.preventDefault) event.preventDefault();
    const { rank } = this.state;
    const { handleSubmit: upstreamHandleSubmit } = this.props;
    upstreamHandleSubmit(rank);
  };

  render() {
    const { editMode, onRankClick, classes, id, isUpdatingRank } = this.props;
    const { rank } = this.state;
    return (
      <div
        className={classes.root}
        onClick={onRankClick}
        data-test-id={`DocumentRank-root-${id}`}
      >
        {!editMode && (
          <Typography className={classes.text}>Rank {rank}</Typography>
        )}
        {editMode && (
          <div>
            <FormControl>
              <InputLabel htmlFor="name-simple">Rank</InputLabel>
              <Input
                className={classes.input}
                value={rank}
                type="number"
                onChange={e => this.handleChange('rank', e.target.value)}
                onKeyDown={e => this.handleKeyDown(e)}
                onKeyPress={e => this.handleKeyPress(e)}
              />
            </FormControl>
            <IconButton
              disabled={Boolean(rank === '') || `${isUpdatingRank}` === `${id}`}
              onClick={this.handleSubmit}
              size="small"
              className={classes.submit}
            >
              <ConfirmIcon />
            </IconButton>
          </div>
        )}
        {`${isUpdatingRank}` === `${id}` && (
          <CircularProgress size={24} className={classes.circularProgress} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(DocumentRank);
