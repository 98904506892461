import React from 'react';
import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import format from 'common/oldJavascripts/utils/format.js';
import Link from 'common/oldJavascripts/components/Base/Link';
import OfferStatus from 'common/oldJavascripts/components/Shared/OfferStatus';
import Loader from 'common/components/Loader';
import UsaOnly from 'common/components/UsaOnly';
import RateCell from './RateCell';
import Badges from 'common/components/Badges';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Send as SendIcon,
  NotInterested as RescindOfferIcon,
  Archive as ArchiveOfferIcon,
  Edit as EditOfferIcon,
} from '@material-ui/icons';
import withApi from 'common/hoc/withApi';
import history from 'common/constants/config/history';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import OfferI9Status from 'studio/components/OfferI9Status';

const styles = {
  actionContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: '100%',
    gap: '6px',
  },
  icon: {
    color: '#3c9dd7',
  },
  overflowText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  badgesContainer: {
    marginTop: 6,
  },
  noHorizontalPaddin: {
    paddingLeft: 0,
    paddingRight: 0,
  },
};

class OldTableRow extends React.Component {
  static mutators = {
    project_offer: {
      info: function(params, related) {
        const routerParams = related['/router/params'];

        return {
          id: `/projects/${routerParams.projectId}/offers/${params.offer_id}`,
          create: true,
        };
      },
    },
  };

  static queries = {
    me: {
      info() {
        return {
          id: '/me',
        };
      },
    },
  };

  render() {
    const { offer = {}, classes = {} } = this.props;
    const { badges = [] } = offer;

    return (
      <ExpandableTableRow
        onClick={this._toggleDetails}
        expanded={this.props.detailsExpanded}
        data-test-id={this.props['data-test-id']}
      >
        <ExpandableTableCell>
          <ExpandableTableCellTitle
            data-test-id={`TableRow-employeeName-${offer.id}`}
          >
            {format.fullName(
              offer.first_name,
              offer.middle_name,
              offer.last_name,
            )}
          </ExpandableTableCellTitle>
          <Tooltip title={offer.email} enterDelay={100}>
            <div
              className={classes.overflowText}
              data-test-id={`TableRow-email-${offer.id}`}
            >
              {offer.email}
            </div>
          </Tooltip>
          <div
            className={classes.badgesContainer}
            data-test-id={`TableRow-badges-${offer.id}`}
          >
            <Badges badges={badges} id={offer.id} />
          </div>
        </ExpandableTableCell>
        <ExpandableTableCell>
          <ExpandableTableCellTitle
            data-test-id={`TableRow-department-${offer.id}`}
          >
            {offer.department_name}
          </ExpandableTableCellTitle>
        </ExpandableTableCell>
        <ExpandableTableCell data-test-id={`TableRow-occupation-${offer.id}`}>
          {offer.occupation_description}
          <br />
          {offer.account_code}
        </ExpandableTableCell>
        <ExpandableTableCell data-test-id={`TableRow-union-${offer.id}`}>
          {offer.union_description}
        </ExpandableTableCell>
        <ExpandableTableCell
          date={true}
          data-test-id={`TableRow-startDate-${offer.id}`}
        >
          {format.date(offer.start_date)}
        </ExpandableTableCell>
        {this._renderEndDateCell()}
        <RateCell offer={offer} data-test-id={`TableRow-rate-${offer.id}`} />
        <ExpandableTableCell
          align="center"
          data-test-id={`TableRow-allowances-${offer.id}`}
        >
          <AllowancesRow.Icon offer={offer} />
        </ExpandableTableCell>
        <UsaOnly>
          <ExpandableTableCell
            align="center"
            data-test-id={`TableRow-i9Status-${offer.id}`}
            className={classes.noHorizontalPaddin}
          >
            <OfferI9Status props={{ ...offer }} />
          </ExpandableTableCell>
        </UsaOnly>
        <ExpandableTableCell
          onClick={this._toggleStatus}
          data-test-id={`TableRow-status-${offer.id}`}
        >
          <OfferStatus
            status={offer.status}
            startDate={offer.start_date}
            sendDate={offer.send_date}
          />
        </ExpandableTableCell>
        <ExpandableTableCell data-test-id={`TableRow-actions-${offer.id}`}>
          {this._renderAction()}
        </ExpandableTableCell>
      </ExpandableTableRow>
    );
  }

  _renderEndDateCell = () => {
    const offer = this.props.offer;
    if (this.props.endDateAllowed) {
      return (
        <ExpandableTableCell
          date={true}
          data-test-id={`TableRow-endDate-${offer.id}`}
        >
          {format.date(offer.end_date)}
        </ExpandableTableCell>
      );
    }
  };

  _renderAction = () => {
    const { me, offer, project_offer, classes, params } = this.props;
    const rejected = new RegExp('reject');
    const optedOut = new RegExp('complete_on_paper');
    const resendOffer = new RegExp(/(pending_email_delivery|sent|onboarding)/);
    const onboarding = new RegExp(/onboarding/);
    if (project_offer.status === 'loading' || me.status === 'loading') {
      return <Loader />;
    }
    // Do not display any offer action buttons if the crew member has opted out
    // of digital onboarding
    if (optedOut.test(offer.status)) return null;
    // Resend and rescind offer buttons
    if (resendOffer.test(offer.status)) {
      return (
        <div className={classes.actionContainer}>
          {offer.status !== 'pending_email_delivery' && !params.offerExpired && (
            <Link onClick={this._resendOffer} plain>
              <Tooltip title="Resend Email Invitation" placement="top">
                <SendIcon
                  className={classes.icon}
                  data-test-id={`TableRow-resendButton-${offer.id}`}
                />
              </Tooltip>
            </Link>
          )}
          {onboarding.test(offer.status) && this._renderArchiveAction()}
          {(offer.status === 'sent' ||
            offer.status === 'pending_email_delivery') && (
            <Link onClick={this._rescindOffer} plain>
              <Tooltip title="Rescind Offer" placement="top">
                <RescindOfferIcon
                  className={classes.icon}
                  data-test-id={`TableRow-rescindButton-${offer.id}`}
                />
              </Tooltip>
            </Link>
          )}
        </div>
      );
    }

    // Archive offer button
    if (offer.status === 'submitted') {
      return this._renderArchiveAction();
    }

    // Edit offer button
    if (rejected.test(offer.status) && !me.data.is_support_admin) {
      return (
        <div className={classes.actionContainer}>
          {this._renderArchiveAction()}
          <Link onClick={this._updateOffer} plain={true}>
            <Tooltip title="Update Offer" placement="top">
              <EditOfferIcon
                className={classes.icon}
                data-test-id={`TableRow-editButton-${offer.id}`}
              />
            </Tooltip>
          </Link>
        </div>
      );
    }

    return null;
  };

  _renderArchiveAction = () => {
    const { offer = {}, classes = {} } = this.props;
    return (
      <Link onClick={e => this._archiveOffer(e, offer.status)} plain>
        <Tooltip title="Archive Offer" placement="top">
          <ArchiveOfferIcon
            className={classes.icon}
            data-test-id={`TableRow-archiveButton-${offer.id}`}
          />
        </Tooltip>
      </Link>
    );
  };

  _resendOffer = e => {
    e.stopPropagation();
    this.props.project_offer.save('resend');
  };

  _updateOffer = () => {
    const {
      routerParams: { projectId },
      offer: { id },
    } = this.props;
    history.push(
      `/projects/${projectId}/offers/${id}/edit?ids=${id}&action=version`,
    );
  };

  _archiveOffer = (e, status) => {
    e.stopPropagation();
    this.props.onShowModal('archive', this.props.offer.id, status);
  };

  _rescindOffer = e => {
    e.stopPropagation();
    this.props.onShowModal('rescind', this.props.offer.id, null);
  };

  _toggleDetails = () => {
    const {
      onToggleDetails: handler,
      offer: { id },
    } = this.props;
    handler(id);
  };

  _toggleStatus = () => {
    const {
      onToggleStatus: handler,
      offer: { id },
    } = this.props;
    handler(id);
  };
}

export default compose(withApi, withStyles(styles))(OldTableRow);
