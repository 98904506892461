import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CncUiProvider from '@castandcrew/cnc-ui/CncUiProvider';

// Layouts
import ProjectSetupLayout from 'admin/oldJavascripts/components/Pages/Layouts/ProjectSetup';

// Components
import ImportedSettings from 'admin/oldJavascripts/components/Pages/Project/Setup/ImportedSettings';
import ProjectSettings from 'admin/components/ProjectSettings';
import RelatedProjects from 'admin/components/RelatedProjects';
import AuditLogs from 'admin/oldJavascripts/components/Pages/Project/Setup/AuditLogs';
import ProjectTemplates from 'admin/oldJavascripts/components/Pages/Project/Setup/ProjectTemplates';
import ProjectTemplateWorkflows from 'admin/components/ProjectTemplates/ProjectTemplate/ProjectTemplateWorkflows';

export default props => {
  return (
    <CncUiProvider>
      <ProjectSetupLayout>
        <Switch>
          <Route
            exact
            path="/projects/:projectId/imported-settings"
            component={ImportedSettings}
          />
          <Route
            exact
            path="/projects/:projectId/related-projects"
            component={RelatedProjects}
          />
          <Route
            exact
            path="/projects/:projectId/project-settings"
            component={ProjectSettings}
          />
          <Route
            exact
            path="/projects/:projectId/project-templates/:projectTemplateId/workflows"
            component={ProjectTemplateWorkflows}
          />
          <Route
            exact
            path="/projects/:projectId/project-templates"
            component={ProjectTemplates}
          />
          <Route
            exact
            path="/projects/:projectId/audit-logs"
            component={AuditLogs}
          />

          <Redirect
            from="/projects/:projectId/settings"
            to="/projects/:projectId/imported-settings"
          />
          <Redirect
            from="/projects/:projectId/documents"
            to="/projects/:projectId/project-settings"
          />
          <Redirect
            from="/projects/:projectId/workflow"
            to="/projects/:projectId/project-templates"
          />
        </Switch>
      </ProjectSetupLayout>
    </CncUiProvider>
  );
};
