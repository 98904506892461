import React from 'react';

// Components
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaskedInput from 'react-text-mask';

// Constants
import { ratePerHourMask } from '../../helpers/textFieldNumberMasks';
import OVERTIME_MULTIPLIER from '../OVERTIME_MULTIPLIER';

// Utilities
import formatCurrency from '../../helpers/formatCurrency';
import enforceMaxDigits from '../../helpers/enforceMaxDigits';

const StudioRatePerHour = ({
  onChange: upstreamOnChange,
  formData = {},
  classes = {},
}) => {
  const { studioRatePerHour, locationRatePerHour } = formData || {};

  const onChange = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0' || parseInt(value, 10) === 0) {
      const overtimeRatePerHour =
        locationRatePerHour === ''
          ? null
          : locationRatePerHour * OVERTIME_MULTIPLIER;

      upstreamOnChange({
        studioRatePerHour: null,
        overtimeRatePerHour,
      });
      return;
    }
    upstreamOnChange({
      studioRatePerHour: value,
      overtimeRatePerHour: formatCurrency(
        enforceMaxDigits(value * OVERTIME_MULTIPLIER),
      ),
      studioRatePerWeek: null,
      locationRatePerWeek: null,
    });
    return;
  };

  const onBlur = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0' || parseInt(value, 10) === 0) return;

    const formattedValue = formatCurrency(value);
    const overtimeValue = formattedValue * OVERTIME_MULTIPLIER;
    const formattedOvertimeValue = formatCurrency(overtimeValue);

    upstreamOnChange({
      studioRatePerHour: formattedValue,
      overtimeRatePerHour: formattedOvertimeValue,
      studioRatePerWeek: null,
      locationRatePerWeek: null,
    });
    return;
  };

  return (
    <TableCell align="center" className={classes.root}>
      <MaskedInput
        mask={ratePerHourMask}
        value={studioRatePerHour || ''}
        onChange={onChange}
        onBlur={onBlur}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': 'StudioRatePerHour-input',
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default StudioRatePerHour;
