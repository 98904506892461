import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import palette from 'common/shared/oldDocumentSignerUI/palette';
import { Button, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import ProjectTemplateAutoAssignmentRule from './ProjectTemplateAutoAssignmentRule';
import useAutoAssignmentRules from './useAutoAssignmentRules';
import Loader from 'common/components/Loader';
import useSyncedState from 'common/hooks/useSyncedState';
import uniqueId from 'common/utilities/uniqueId';
import { RULE_TYPES } from './constants/ruleTypes';

const styles = theme => ({
  rulesList: {
    marginTop: theme.spacing.unit,
    flexGrow: 1,
  },
  headingText: {
    padding: `${theme.spacing.unit}px 0px`,
  },
  rulesRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  button: {
    marginLeft: theme.spacing.unit,
    color: 'white',
    backgroundColor: palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
});

const convertRulesToLegacyRestApiRepresentation = rules =>
  rules
    .map(rule => {
      if (!rule.__typename) return null;
      const ruleType = rule.__typename.slice(0, -'AutoAssignmentRule'.length);
      const definedRuleType = RULE_TYPES.find(
        ({ typeName }) => typeName === ruleType,
      );
      const ruleTypeId = definedRuleType?.id;
      const ruleName = definedRuleType?.typeName;
      const legacyRule = {
        ruleType: { id: ruleTypeId, description: ruleType },
      };
      const isCombo = ruleName === 'Combo';
      if (isCombo || ruleName === 'Department') {
        legacyRule.department = { id: rule.department?.id };
      }
      if (isCombo || ruleName === 'Union') {
        legacyRule.union = {
          code: rule.union?.code,
          description: rule.union?.description,
          is_non_union: rule.union?.isNonUnion,
        };
      }
      if (isCombo || ruleName === 'Location') {
        legacyRule.hireState = { ...rule.hireState };
        legacyRule.workState = { ...rule.workState };
        legacyRule.workCity = { ...rule.workCity };
      }
      if (isCombo || ruleName === 'EmploymentType') {
        legacyRule.employmentType = { id: rule.employmentType?.id };
      }
      return legacyRule;
    })
    .filter(x => x);

const ProjectTemplateAutoAssignmentRules = props => {
  const {
    classes,
    isTemplateRequired,
    onChange: onChangeUpstream,
    projectTemplateId,
    projectCountryId,
  } = props;

  // TODO: Once project templates has been fully converted to gql, all the state
  // should be lifted & centralized into the project template root component.
  const { data: upstreamRules } = useAutoAssignmentRules(
    { projectTemplateId },
    {
      // Always get the data from the server. This is necessary because we do
      // not yet have a graphql mutation for updating auto assignment rules, so
      // we can't intelligently refetch the queries.
      fetchPolicy: 'network-only',
    },
  );
  const [rules, setRulesLocal] = useSyncedState(upstreamRules, !!upstreamRules);
  const [ruleIdBeingEdited, setRuleIdBeingEdited] = useState(null);

  if (!rules) return <Loader />;

  const isAll = rules.some(rule => rule.__typename === 'AllAutoAssignmentRule');
  const showRules = !isTemplateRequired;

  const setRules = newRules => {
    setRulesLocal(newRules);
    // Translate new GQL representation to old REST representation & push upstream.
    // TODO: Remove this once whole page is GQL
    const rulesForRestApi = convertRulesToLegacyRestApiRepresentation(newRules);
    onChangeUpstream(rulesForRestApi);
  };

  const deleteRule = id => setRules(rules.filter(rule => rule.id !== id));

  const updateRule = updatedRule => {
    const newRules = rules.map(rule =>
      rule.id === updatedRule.id ? updatedRule : rule,
    );
    setRules(newRules);
  };

  const addRule = () => {
    const id = `new-rule-${uniqueId()}`;
    setRules(rules.concat({ id }));
    setRuleIdBeingEdited(id);
  };

  const toggleRule = id =>
    setRuleIdBeingEdited(id === ruleIdBeingEdited ? null : id);

  return (
    <div className={classes.rulesList}>
      <div className={classes.headingText}>
        <Typography variant="h6" gutterBottom>
          Auto Assignments
        </Typography>
        <Typography variant="body1">
          Offers that match the rules below will have this document enabled by
          default.
        </Typography>
      </div>
      <div className={classes.rulesRow}>
        {rules.map(rule => (
          <ProjectTemplateAutoAssignmentRule
            key={`rule-${rule.id}`}
            rule={rule}
            onChange={updateRule}
            onDelete={() => deleteRule(rule.id)}
            onToggleEdit={() => toggleRule(rule.id)}
            isBeingEdited={ruleIdBeingEdited === rule.id}
            projectCountryId={projectCountryId}
          />
        ))}
      </div>
      <div className={classes.buttonRow}>
        <Button
          className={classes.button}
          onClick={addRule}
          disabled={!showRules || isAll}
          variant="contained"
          color="primary"
          size="small"
          data-test-id={`RuleList-add-${projectTemplateId}`}
        >
          <AddIcon />
          Add New Rule
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRules);
