import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import moment from 'moment';
import TOOLTIP_ICON from 'common/images/tooltip.svg';
import Tooltip from '@material-ui/core/Tooltip';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0 27px',
    },
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: 30,
    '& > div': {
      flex: '1 1 70px',
    },
    '& > div:first-child': {
      marginRight: 20,
    },
    [theme.breakpoints.up('md')]: {
      '& > div': {
        flex: '1 1 100px',
      },
      '& > div:first-child': {
        marginRight: 30,
      },
    },
  },
  itemHeading: {
    color: '#939393',
    fontSize: 14,
    fontWeight: 400,
  },
  itemTitle: {
    color: '#5b5b5b',
    fontSize: 18,
    fontWeight: 400,
  },
  showMore: {
    color: '#3c9dd7',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.5,
    textAlign: 'center',
    marginTop: 15,
  },
  arrowIcon: {
    color: '#30aba3',
    position: 'relative',
    top: 7,
    fontSize: '2rem',
  },
  jobHeading: {
    marginTop: 20,
    marginBottom: 8,
    color: '#939393',
    fontSize: 16,
    fontWeight: 400,
  },
  description: {
    color: '#5b5b5b',
    fontSize: 18,
    fontWeight: 400,
  },
  hrContainer: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: 20,
      margin: '0 100px',
    },
  },
  divider: {
    display: 'block',
    marginTop: '0.5em',
    marginBottom: '0.5em',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderStyle: 'inset',
    borderWidth: '0.5px',
    borderColor: '#979797',
  },
}));

const hiringStatusOptions = {
  full_time: 'Full Time',
  variable: 'Variable',
  part_time: 'Part Time',
  seasonal: 'Seasonal',
  variable_ec: 'Variable (exclude Corps)',
  opt_out_var: 'Opt-Out Variable',
};

const TermsOfHire = props => {
  const {
    union = {},
    department_name: departmentName,
    work_city: workCity,
    work_state: workState,
    hire_state: hireState,
    hire_city: hireCity,
    hiring_status: acaHiringStatus = null,
    sent_at: offerSentDate,
    schedule_description: scheduleDescription,
    custom_occupation_description: jobDescription,
    type,
  } = props?.offerData;
  const [isShowMore, setIsShowMore] = useState(false);
  const acaHiringStatusValue = hiringStatusOptions[acaHiringStatus];
  const classes = useStyles();
  const showMoreText = isShowMore ? 'Show Less' : 'Show More';

  const getWorkState = countryCode => (
    <div>
      <Typography
        className={classes.itemHeading}
        data-test-id="TermsOfHire-workStateTitle"
      >
        Work {countryCode === 'US' ? 'State' : 'Province'}
      </Typography>
      <Typography
        className={classes.itemTitle}
        data-test-id="TermsOfHire-workStateName"
      >
        {workState.name}
      </Typography>
    </div>
  );

  const getWorkCity = () => (
    <div data-test-id="TermsOfHire-workCity">
      <Typography className={classes.itemHeading}>Primary Work City</Typography>
      <Typography className={classes.itemTitle}>{workCity.name}</Typography>
    </div>
  );

  const getHireState = countryCode => (
    <div>
      <Typography
        className={classes.itemHeading}
        data-test-id="TermsOfHire-hireStateTitle"
      >
        Hire {countryCode === 'US' ? 'State' : 'Province'}
      </Typography>
      <Typography className={classes.itemTitle}>{hireState.name}</Typography>
    </div>
  );

  const getHireCity = () => (
    <div data-test-id="TermsOfHire-hireCity">
      <Typography className={classes.itemHeading}>Hire City</Typography>
      <Typography className={classes.itemTitle}>{hireCity.name}</Typography>
    </div>
  );

  const getUnion = () => (
    <div>
      <Typography className={classes.itemHeading}>Union</Typography>
      <Typography
        className={classes.itemTitle}
        data-test-id="TermsOfHire-unionDescription"
      >
        {union.description}
      </Typography>
    </div>
  );

  const getDepartment = () => (
    <div>
      <Typography className={classes.itemHeading}>Department</Typography>
      <Typography className={classes.itemTitle}>{departmentName}</Typography>
    </div>
  );

  const getJobDescription = () => (
    <Fragment>
      <Typography className={classes.jobHeading}>Job Description</Typography>
      <Typography className={classes.description}>{jobDescription}</Typography>
    </Fragment>
  );

  const getHiringStatus = () => (
    <div>
      <Typography className={classes.itemHeading}>ACA Hiring Status</Typography>
      <Typography
        className={classes.itemTitle}
        data-test-id="TermsOfHire-acaHiringStatus"
      >
        {acaHiringStatusValue}
      </Typography>
    </div>
  );

  const getOfferSent = () => (
    <div>
      <Typography className={classes.itemHeading}>Offer sent</Typography>
      <Typography
        className={classes.itemTitle}
        data-test-id="TermsOfHire-offerSentDate"
      >
        {moment(offerSentDate).format('MMMM. Do, YYYY')}
      </Typography>
    </div>
  );

  const getEmploymentClassification = countryCode => (
    <div>
      <Typography className={classes.itemHeading}>
        Employment Classification
      </Typography>
      <Typography
        className={classes.itemTitle}
        data-test-id="TermsOfHire-employmentClassification"
      >
        {type === 'w2' &&
          (countryCode === 'US' ? 'W-2 Employee' : 'Individual')}
        {type === 'loan_out' && 'Loan Out'}
      </Typography>
    </div>
  );

  const getSchedule = () => (
    <div>
      <Typography className={classes.itemHeading}>
        Work Schedule{' '}
        <Tooltip
          title="Schedule is determined by your union type"
          placement="top-start"
          className={classes.tooltip}
        >
          <img src={TOOLTIP_ICON} alt="schedule tooltip" />
        </Tooltip>
      </Typography>
      <Typography
        className={classes.itemTitle}
        data-test-id="TermsOfHire-workScheduleDescription"
      >
        {scheduleDescription}
      </Typography>
    </div>
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.title} data-test-id="TermsOfHire-title">
        Terms of Hire
      </Typography>

      <UsaOnly>
        <div className={classes.itemContainer}>
          {getWorkState('US')}
          {getWorkCity()}
        </div>
        <div className={classes.itemContainer}>
          {getUnion()}
          {getDepartment()}
        </div>
        {isShowMore && (
          <Fragment>
            {jobDescription && getJobDescription()}
            <div className={classes.hrContainer}>
              <hr className={classes.divider} />
            </div>
            <div className={classes.itemContainer}>
              {getHireState('US')}
              {hireCity?.name && getHireCity()}
            </div>
            <div className={classes.itemContainer}>
              {acaHiringStatusValue && getHiringStatus()}
              {getOfferSent()}
            </div>
            <div className={classes.itemContainer}>
              {getEmploymentClassification('US')}
              {getSchedule()}
            </div>
          </Fragment>
        )}
      </UsaOnly>

      <CanadaOnly>
        <div className={classes.itemContainer}>
          {getWorkState('CA')}
          {getHireState('CA')}
        </div>
        <div className={classes.itemContainer}>
          {getUnion()}
          {getDepartment()}
        </div>
        {isShowMore && (
          <Fragment>
            {jobDescription && getJobDescription()}
            <div className={classes.hrContainer}>
              <hr className={classes.divider} />
            </div>
            <div className={classes.itemContainer}>
              {getEmploymentClassification('CA')}
              {getOfferSent()}
            </div>
            <div className={classes.itemContainer}>{getSchedule()}</div>
          </Fragment>
        )}
      </CanadaOnly>

      <Typography
        className={classes.showMore}
        onClick={() => setIsShowMore(!isShowMore)}
        data-test-id="TermsOfHire-showMoreText"
      >
        {showMoreText} <KeyboardArrowDownIcon className={classes.arrowIcon} />
      </Typography>
    </div>
  );
};

export default TermsOfHire;
