import React from 'react';

import T from 'common/oldJavascripts/utils/i18n';
import Form from 'common/oldJavascripts/components/Shared/Form';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import UsaOnly from 'common/components/UsaOnly';
import CanadaOnly from 'common/components/CanadaOnly';

// Mui components
import { Grid, Typography } from '@material-ui/core';

const OldI9 = props => {
  const { project, states } = props;

  return (
    <BoxItem divider>
      <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
        <span data-test-id="I9-title">Employer Information</span>
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Form.Field
            mutator={project}
            label={T('Business or Organization Name')}
            type="text"
            name="employer_org_name"
            grouped="bottom"
            required={true}
            data-test-id="I9-employerOrgName"
          />
        </Grid>

        <Grid item xs={4}>
          <Form.Field
            mutator={project}
            label={T(
              'Business or Organization Address(Street Number and Name)',
            )}
            type="text"
            name="employer_org_address"
            required={true}
            grouped="bottom"
            data-test-id="I9-employerOrgAddress"
          />
        </Grid>

        <Grid item xs={2}>
          <Form.Field
            mutator={project}
            label={T('City or Town')}
            type="text"
            name="employer_org_city"
            grouped="bottom"
            required={true}
            data-test-id="I9-employerOrgCity"
          />
        </Grid>

        <Grid item xs={2}>
          <UsaOnly>
            <Form.Field
              type="select"
              mutator={project}
              label={T('State')}
              name="employer_org_state_id"
              options={states}
              grouped="bottom"
              required={true}
              data-test-id="I9-employerOrgStateId"
            />
          </UsaOnly>
          <CanadaOnly>
            <Form.Field
              type="select"
              mutator={project}
              label={T('Province')}
              name="employer_org_state_id"
              options={states}
              grouped="bottom"
              required={true}
              data-test-id="I9-employerOrgStateId"
            />
          </CanadaOnly>
        </Grid>

        <Grid item xs={1}>
          <UsaOnly>
            <Form.Field
              mutator={project}
              label={T('Zip Code')}
              type="text"
              name="employer_org_zip_code"
              grouped="bottom"
              required={true}
              data-test-id="I9-employerOrgZipCode"
            />
          </UsaOnly>
          <CanadaOnly>
            <Form.Field
              mutator={project}
              label={T('Postal Code')}
              type="text"
              name="employer_org_zip_code"
              grouped="bottom"
              required={true}
              data-test-id="I9-employerOrgZipCode"
            />
          </CanadaOnly>
        </Grid>
      </Grid>
    </BoxItem>
  );
};

export default OldI9;
