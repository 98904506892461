import React, { useState } from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Components
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCodeTable from './AccountCodeTable';

const styles = theme => ({
  paper: {
    padding: '25px',
    width: '100%',
    maxWidth: '80vw',
  },
  actions: {
    paddingTop: 20,
  },
  saveButton: {
    width: 80,
    marginLeft: 8,
  },
  emptyMessage: {
    alignSelf: 'center',
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
});

const AccountCodeModal = props => {
  const {
    formData,
    formErrors,
    open,
    onClose,
    classes,
    accountCodeConfigurations = [],
    onSave,
    offerDefaults: { enforceMask },
    privileges = [],
  } = props;
  const { accountCodes: upstreamAccountCodes = [] } = formData || {};
  const canEditAccountCodes = privileges.includes(
    'can_edit_offer_account_codes',
  );
  const canViewAccountCodes = privileges.includes(
    'can_view_offer_account_codes',
  );

  const [editedAccountCodes, setEditedAccountCodes] = useState(
    upstreamAccountCodes,
  );

  const handleCellOnChange = (accountCodeId, lineItemKey, value) => {
    const newAccountCodes = editedAccountCodes
      .filter(
        ac =>
          !(
            ac.accountCodeId === accountCodeId && ac.lineItemKey === lineItemKey
          ),
      )
      .concat({ accountCodeId, lineItemKey, value });
    setEditedAccountCodes(newAccountCodes);
  };

  const save = () => {
    onSave(editedAccountCodes);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ ...classes }}>
      {canViewAccountCodes && !!accountCodeConfigurations.length ? (
        <React.Fragment>
          <AccountCodeTable
            formData={formData}
            formErrors={formErrors}
            onChange={handleCellOnChange}
            accountCodeConfigurations={accountCodeConfigurations}
            accountCodes={editedAccountCodes}
            onClose={onClose}
            enforceMask={enforceMask}
            canEditAccountCodes={canEditAccountCodes}
          />
          <DialogActions className={classes.actions}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClose}
              data-test-id="AccountCodeModal-cancelButton"
            >
              Cancel
            </Button>
            <Tooltip
              title={
                canEditAccountCodes
                  ? ''
                  : 'You do not have permission to edit the account codes.'
              }
              enterDelay={500}
              classes={{ tooltip: classes.tooltip }}
            >
              <div data-test-id="AccountCodeModal-tooltip">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={save}
                  className={classes.saveButton}
                  data-test-id="AccountCodeModal-saveButton"
                  disabled={!canEditAccountCodes}
                >
                  Save
                </Button>
              </div>
            </Tooltip>
          </DialogActions>
        </React.Fragment>
      ) : (
        <Typography
          variant="h4"
          data-test-id="AccountCodeModal-emptyMessage"
          className={classes.emptyMessage}
        >
          Either your project is not configured to use account codes or you have
          not been granted permissions to view account codes.
        </Typography>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(AccountCodeModal);
