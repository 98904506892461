import getErrorMessage from './errorMessages';
import moment from 'moment';
import isUnionWeeklyScheduleCode from 'common/utilities/isUnionWeeklySchedule';

const termsOfHireCheck = (key, label) => (
  { termsOfHire = {} },
  _,
  { seasons = [] },
) => {
  const value = termsOfHire[key];
  if (value != null) return true;
  // Season is only required if a project has seasons
  if (!seasons.length && key === 'season') return true;
  return { termsOfHire: { [key]: getErrorMessage(key) } };
};

const workCityCheck = (
  { termsOfHire: { workCity } = {} },
  { countryCode },
  { workStateCities },
) => {
  const isWorkCityRequired = countryCode === 'US' && workStateCities.length > 0;
  const checkPassed = !isWorkCityRequired || workCity != null;

  if (checkPassed) return true;
  return { termsOfHire: { workCity: getErrorMessage('workCity') } };
};

const hireCityCheck = (
  { termsOfHire: { hireCity } = {} },
  { countryCode },
  { hireStateCities },
) => {
  const isHireCityRequired = countryCode === 'US' && hireStateCities.length > 0;
  const checkPassed = !isHireCityRequired || hireCity != null;

  if (checkPassed) return true;
  return { termsOfHire: { hireCity: getErrorMessage('hireCity') } };
};

const employmentClassificationCheck = (
  formData,
  { enforceLoanOut },
  { departments } = {},
) => {
  const {
    dealNotes = [],
    termsOfHire: {
      occupation,
      department,
      employmentClassification,
      union,
    } = {},
    termsOfEmployment: { isPrcanRateTable } = {},
  } = formData || {};
  const { isNonUnion = false } = union || {};

  const { value: occupationCode } = occupation || {};

  if (
    dealNotes.length === 0 &&
    employmentClassification === 'w2' &&
    isPrcanRateTable &&
    isNonUnion
  ) {
    return {
      dealNotes:
        'Be sure to enter any applicable vacation/fringe benefits for the non-union crew member here and indicate if this is in addition to rate or included in rate.',
    };
  }

  // If the project does not enforce loan out then therse no reason to test
  if (!enforceLoanOut) return true;

  // There are no restrictions on w2
  if (employmentClassification === 'w2') return true;

  // Find the selected department
  const selectedDepartment = departments.find(({ id }) => id === department);
  const { loanOutOccupations = [] } = selectedDepartment || {};
  // Check if the selected department has that as an available loan out occupation
  if (loanOutOccupations.some(({ code }) => code === occupationCode))
    return true;

  return {
    termsOfHire: {
      employmentClassification:
        'This occupation is not available for the Loan Out employment classification',
    },
  };
};

const offerDateCheck = ({ offerDate = {} } = {}) => {
  const { startDate } = offerDate || {};
  if (startDate !== null && startDate !== undefined) return true;
  return { offerDate: { startDate: getErrorMessage('startDate') } };
};

const sendDateCheck = ({ offerDate = {} } = {}) => {
  const { sendDate } = offerDate || {};
  if (sendDate != null) return true;
  return { offerDate: { sendDate: getErrorMessage('sendDate') } };
};

const backDateLimitCheck = ({ offerDate = {} } = {}, { backdateLimit }) => {
  const { startDateObject } = offerDate || {};
  const today = moment();
  const daysBetween = moment.duration(today.diff(startDateObject)).asDays();
  if (daysBetween <= backdateLimit || !daysBetween) return true;
  return {
    offerDate: {
      startDate: getErrorMessage('backdateLimit', `${backdateLimit} days`),
    },
  };
};

const documentsCheck = ({ documents = [] }) => {
  if (documents?.length !== 0) return true;
  return { documents: getErrorMessage('documents') };
};

const documentFieldChecks = (formData, _, { templates }) => {
  const { documents: selectedDocumentIds = [], documentFields = [] } =
    formData || {};

  if (!templates) return true;

  let failures = [];
  // Check every document that was selected
  // To determine if they have required field groups
  // And if so check if they have a value
  selectedDocumentIds.forEach(selectedDocumentId => {
    const { inputFieldGroups } = templates.find(
      ({ id }) => selectedDocumentId === id,
    );
    inputFieldGroups.forEach(
      ({ id: inputFieldGroupId, required, fields = [] }) => {
        if (!required) return;
        fields.forEach(({ id: inputFieldId, type }) => {
          if (type === 'CHK') return;
          const { value } =
            documentFields.find(({ id }) => id === inputFieldId) || {};
          if (!value) {
            failures = [
              ...failures,
              {
                error: getErrorMessage(`documentFields_${type}`),
                inputFieldId,
                inputFieldGroupId,
                documentId: selectedDocumentId,
              },
            ];
          }
        });
      },
    );
  });
  if (failures.length === 0) return true;
  return { documentFields: failures };
};

const allowancesCheck = ({ allowances = {} }) => {
  const allowanceKeys = Object.keys(allowances);
  let checkFailures = {};
  const checkPassed = allowanceKeys
    .map(key => {
      const { [key]: allowance } = allowances || {};
      const { amount, cap, duration, per } = allowance || {};

      if (Number(cap) > 999999.999999999) {
        checkFailures = {
          ...checkFailures,
          [key]: getErrorMessage('allowance_cap_exceeded'),
        };
        return false;
      }

      // No fields have been filled out
      if (!amount && !cap && !duration) return true;
      // The right fields were filled out
      if (amount && per) return true;

      // Theres a cap or duration but no  amount
      // We assume the user forgot to fill out the amount

      if (duration && !amount) {
        checkFailures = {
          ...checkFailures,
          [key]: getErrorMessage('allowance_missing_amount'),
        };
        return false;
      }

      // All fields except per have ben filled out
      if (amount && !per) {
        checkFailures = {
          ...checkFailures,
          [key]: getErrorMessage('allowance_per'),
        };
        return false;
      }
      return false;
    })
    .every(result => result);

  if (checkPassed || !Object.keys(checkFailures).length) return true;
  return { allowances: checkFailures };
};

const termsOfEmploymentCheck = ({
  termsOfEmployment = {},
  termsOfHire = {},
} = {}) => {
  const { union, workSchedule } = termsOfHire || {};
  const { isNonUnion = false } = union || {};
  const { value: workScheduleCode = null } = workSchedule || {};
  const {
    locationRatePerHour,
    locationRatePerWeek,
    overtimeRatePerHour,
    studioRatePerHour,
    studioRatePerWeek,
    ratePerHour,
    dailyRate,
    weeklyRate,
    weeklyRateDistant,
    rate,
    guaranteedHours,
    guaranteedHoursDistant,
    guaranteedHoursFrequency,
    isTermsOfEmploymentV2,
    isPrcanRateTable,
    rateDistant,
    tableErrors = {},
  } = termsOfEmployment || {};
  const { isRateBelowScale, isGuaranteedHoursBelowScale } = tableErrors;

  const isUnion = !isNonUnion;
  const isExempt = workScheduleCode === 'C' || workScheduleCode === 'D';
  let errors = {};
  const isScheduleA = ['A', 'OT', 'CR', 'FS', 'PO', 'XT', 'M'].includes(
    workScheduleCode,
  );
  const isScheduleC = ['C', 'E', 'CN'].includes(workScheduleCode);
  const isUnionScheduleA = workScheduleCode === 'A' && isUnion;
  const isUnionWeeklySchedule =
    isUnionWeeklyScheduleCode(workScheduleCode) && isUnion;
  if (isNonUnion && isScheduleA && isTermsOfEmploymentV2) {
    if (!ratePerHour) {
      errors = {
        ...errors,
        ratePerHour: getErrorMessage('nonUnionHourlyRateRequired'),
      };
    }
    if (parseFloat(ratePerHour) < 5.5) {
      errors = {
        ...errors,
        ratePerHour: getErrorMessage('nonUnionHourlyRate'),
      };
    }
  }

  if (isNonUnion && isScheduleC && !isPrcanRateTable && isTermsOfEmploymentV2) {
    if (!rate) {
      errors = {
        ...errors,
        rate: getErrorMessage('rate'),
      };
    }
  }

  if (isPrcanRateTable) {
    if (!ratePerHour && !dailyRate && !weeklyRate) {
      errors = {
        ...errors,
        ratePerHour: getErrorMessage('rate'),
      };
    }

    if (ratePerHour && !overtimeRatePerHour) {
      errors = {
        ...errors,
        overtimeRatePerHour: getErrorMessage('overtimeRatePrCan'),
      };
    }

    if (
      (dailyRate && guaranteedHours && guaranteedHoursFrequency !== 'daily') ||
      (dailyRate && !guaranteedHours)
    ) {
      errors = {
        ...errors,
        hoursPerDay: getErrorMessage('hoursPerDay'),
      };
    }

    if (
      (weeklyRate &&
        guaranteedHours &&
        guaranteedHoursFrequency !== 'weekly') ||
      (weeklyRate && !guaranteedHours)
    ) {
      errors = {
        ...errors,
        hoursPerWeek: getErrorMessage('hoursPerWeek'),
      };
    }
  }

  if (
    isUnion &&
    !isPrcanRateTable &&
    !isUnionScheduleA &&
    !isUnionWeeklySchedule
  ) {
    if (
      !studioRatePerHour &&
      !studioRatePerWeek &&
      !locationRatePerHour &&
      !locationRatePerWeek
    ) {
      errors = {
        ...errors,
        studioRatePerHour: getErrorMessage('studioRatePerHour'),
        studioRatePerWeek: getErrorMessage('studioRatePerWeek'),
        locationRatePerHour: getErrorMessage('locationRatePerHour'),
        locationRatePerWeek: getErrorMessage('locationRatePerWeek'),
        rate: getErrorMessage('rate'),
      };
    }
    if ((studioRatePerHour || locationRatePerHour) && !overtimeRatePerHour) {
      errors = {
        ...errors,
        overtimeRatePerHour: getErrorMessage('overtimeRatePerHour'),
        overtimeRate: getErrorMessage('overtimeRate'),
      };
    }
  }

  if (isUnionScheduleA && !isPrcanRateTable && !isUnionWeeklySchedule) {
    if (!rate || !rateDistant) {
      errors = {
        ...errors,
        rate: getErrorMessage('rateStudioDistantRequired'),
      };
    }

    if (isRateBelowScale) {
      errors = {
        ...errors,
        rate: getErrorMessage('rateBelowScale'),
      };
    }
    if (isGuaranteedHoursBelowScale) {
      errors = {
        ...errors,
        guaranteedHours: getErrorMessage('guaranteedHoursBelowScale'),
      };
    }

    if (!guaranteedHours || !guaranteedHoursDistant) {
      errors = {
        ...errors,
        guaranteedHours: getErrorMessage('studioDistantHoursPerDay'),
      };
    }
  }

  if (isUnionWeeklySchedule && isTermsOfEmploymentV2 && !isPrcanRateTable) {
    if (!weeklyRate || !weeklyRateDistant) {
      errors = {
        ...errors,
        weeklyRate: getErrorMessage('weeklyRate'),
        weeklyRateDistant: getErrorMessage('weeklyRateDistant'),
      };
    }
    if (!rate || !rateDistant) {
      errors = {
        ...errors,
        rate: getErrorMessage('rate'),
        rateDistant: getErrorMessage('rateDistant'),
      };
    }

    if (!guaranteedHours || !guaranteedHoursDistant) {
      errors = {
        ...errors,
        guaranteedHours: getErrorMessage('guaranteedHoursDistant'),
        guaranteedHoursDistant: getErrorMessage('guaranteedHoursDistant'),
      };
    }
  }

  if (
    !isUnion &&
    !isExempt &&
    !isTermsOfEmploymentV2 &&
    !isUnionWeeklySchedule
  ) {
    if (!ratePerHour) {
      errors = {
        ...errors,
        ratePerHour: getErrorMessage('ratePerHour'),
        rate: getErrorMessage('rate'),
      };
    }
    if (!overtimeRatePerHour) {
      errors = {
        ...errors,
        overtimeRatePerHour: getErrorMessage('overtimeRatePerHour'),
        overtimeRate: getErrorMessage('overtimeRate'),
      };
    }
  }

  if (!isUnion && isExempt && !isTermsOfEmploymentV2 && !isPrcanRateTable) {
    if (!dailyRate && !weeklyRate) {
      errors = {
        ...errors,
        dailyRate: getErrorMessage('dailyRate'),
        weeklyRate: getErrorMessage('weeklyRate'),
      };
    }
  }
  if (Object.keys(errors).length === 0) return true;
  return { termsOfEmployment: errors };
};

const accountCodeCheck = (
  formData = {},
  _,
  { accountCodeConfigurations = [], privileges = [] } = {},
) => {
  const canEditAccountCodes = privileges.includes(
    'can_edit_offer_account_codes',
  );
  const canViewAccountCodes = privileges.includes(
    'can_view_offer_account_codes',
  );
  // Only validate account code if the user can view AND edit
  if (!(canEditAccountCodes && canViewAccountCodes)) return true;
  const { accountCodes = [] } = formData || {};
  const formDataToAccountCodeKeyMapping = [
    {
      formDataKey: 'termsOfEmployment',
      values: [
        {
          formDataSubKey: 'idleLocationSeventhDayRatePerHour',
          accountCodeKey: 'location',
        },
        {
          formDataSubKey: 'idleLocationSixthDayRatePerHour',
          accountCodeKey: 'location',
        },
        {
          formDataSubKey: 'locationRatePerHour',
          accountCodeKey: 'location',
        },
        {
          formDataSubKey: 'locationRatePerWeek',
          accountCodeKey: 'location',
        },
        {
          formDataSubKey: 'studioGuaranteedHours',
          accountCodeKey: 'studio',
        },
        {
          formDataSubKey: 'studioRatePerHour',
          accountCodeKey: 'studio',
        },
        {
          formDataSubKey: 'studioRatePerWeek',
          accountCodeKey: 'studio',
        },
        {
          formDataSubKey: 'dailyRate',
          accountCodeKey: 'daily',
        },
        {
          formDataSubKey: 'weeklyRate',
          accountCodeKey: 'weekly',
        },
        {
          formDataSubKey: 'ratePerHour',
          accountCodeKey: 'hourly',
        },
      ],
    },
    {
      formDataKey: 'allowances',
      values: [
        {
          formDataSubKey: 'boxRentalAllowance',
          accountCodeKey: 'box',
        },
        {
          formDataSubKey: 'carAllowance',
          accountCodeKey: 'car',
        },
        {
          formDataSubKey: 'computerRentalAllowance',
          accountCodeKey: 'computer',
        },
        {
          formDataSubKey: 'housingAllowance',
          accountCodeKey: 'housing',
        },
        {
          formDataSubKey: 'mobilePhoneAllowance',
          accountCodeKey: 'mobile',
        },
        {
          formDataSubKey: 'perDiemAllowance',
          accountCodeKey: 'perDiem',
        },
      ],
    },
  ];
  let failures = [];

  accountCodeConfigurations
    .filter(({ required }) => required)
    .forEach(({ id: accountCodeId }) => {
      formDataToAccountCodeKeyMapping.forEach(
        ({ formDataKey, values = [] }) => {
          values.forEach(({ formDataSubKey, accountCodeKey }) => {
            const { [formDataKey]: { [formDataSubKey]: value } = {} } =
              formData || {};
            if (formDataKey === 'allowances') {
              const { amount } = value || {};
              if (!amount) return;
            } else {
              // Return if the value is falsey
              // We still need parseInt here because the values
              // are stored as strings
              if (!value || parseInt(value, 10) === 0) return;
            }
            const { value: accountCodeValue } =
              accountCodes.find(
                ({ accountCodeId: codeId, lineItemKey }) =>
                  accountCodeId === codeId && accountCodeKey === lineItemKey,
              ) || {};
            if (accountCodeValue) return;
            const failure = {
              accountCodeId,
              lineItemKey: accountCodeKey,
            };
            failures = [...failures, failure];
          });
        },
      );
    });
  const checkPassed = failures.length === 0;
  if (checkPassed) return true;
  return { accountCodes: failures };
};

const negotiatedContractTermsCheck = (formData, _, { contracts }) => {
  const { termsOfEmployment = {} } = formData;
  const { negotiatedContractId = null } = termsOfEmployment;
  if (Array.isArray(contracts)) {
    const htgContract = contracts.find(contract => contract.type === 'htg');
    if (htgContract) {
      const contract = htgContract.contracts.find(
        contract => !!contract.negotiableContract,
      );
      if (contract && !negotiatedContractId) {
        return {
          termsOfEmployment: {
            negotiatedContractId: getErrorMessage('negotiatedContractId'),
          },
        };
      }
    }
  }
  return true;
};

const offerValidationChecks = [
  // OfferDate
  offerDateCheck,
  backDateLimitCheck,
  sendDateCheck,
  // Terms Of Hire
  termsOfHireCheck('season', 'Season'),
  termsOfHireCheck('hireState', 'Hire State'),
  termsOfHireCheck('workState', 'Work State'),
  hireCityCheck,
  workCityCheck,
  termsOfHireCheck('currency', 'Currency'),
  termsOfHireCheck('union', 'Union'),
  termsOfHireCheck('occupation', 'Occupation'),
  termsOfHireCheck('workSchedule', 'Work Schedule'),
  termsOfHireCheck('department', 'Department'),
  employmentClassificationCheck,
  negotiatedContractTermsCheck,
  // TermsOfEmployment
  termsOfEmploymentCheck,
  // Allowances
  allowancesCheck,
  // Account Codes
  accountCodeCheck,
  // Documents
  documentsCheck,
  // DocumentFields
  documentFieldChecks,
];

export default offerValidationChecks;
