import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, MuiThemeProvider } from '@material-ui/core';
import { LibraryAdd as LibraryAddIcon } from '@material-ui/icons';
import DocumentUploaderModal from './DocumentUploaderModal';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';

const styles = theme => ({
  button: {
    marginLeft: theme.spacing.unit,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    color: theme.palette.primary.contrastText,
    height: 15,
    width: 15,
  },
});

const DocumentUploader = props => {
  const [open, setOpen] = useState(false);
  const { uploadHandler, editHandler, deleteHandler, classes } = props;

  return (
    <MuiThemeProvider theme={documentSignerTheme}>
      <div>
        <Button
          onClick={() => setOpen(true)}
          data-test-id="upload-document-button"
          className={classes.button}
          color="primary"
          variant="contained"
        >
          <LibraryAddIcon className={classes.buttonIcon} />
          Upload Documents
        </Button>
        {open && (
          <DocumentUploaderModal
            open={open}
            uploadHandler={uploadHandler}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            editableFiles
            fileTypes="application/pdf"
            closeModalHandler={() => setOpen(false)}
          />
        )}
      </div>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DocumentUploader);
