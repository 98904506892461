import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// Commponents
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
// Icons
import DoneIcon from '@material-ui/icons/Done';

const styles = theme => ({
  root: {
    width: '200px',
  },
});

const CrewFilterMenu = props => {
  const {
    classes,
    onChange: upstreamOnChange,
    filters = {},
    open,
    anchor,
    onClose,
  } = props;
  const menuItems = [
    {
      label: 'Without Offers',
      selected: filters.withoutOffers,
      onChange: () =>
        upstreamOnChange({ withoutOffers: !filters.withoutOffers }),
    },
    {
      label: 'With Offers',
      selected: filters.withOffers,
      onChange: () => upstreamOnChange({ withOffers: !filters.withOffers }),
    },
  ];
  return (
    <Menu
      id="role-menu-add-approver"
      anchorEl={anchor ? anchor : undefined}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={open}
      onClose={onClose}
      data-test-id="CrewFilterMenu-root"
      classes={{ paper: classes.root }}
    >
      <ListSubheader>Filters</ListSubheader>
      <Divider />
      {menuItems.map(({ label, selected, onChange }) => (
        <MenuItem
          value={label}
          selected={selected}
          onClick={onChange}
          data-test-id={`CrewFilterMenu-menuItem-${label}`}
          key={label}
        >
          {selected && (
            <ListItemIcon data-test-id="CrewFilterMenu-doneIcon">
              <DoneIcon />
            </ListItemIcon>
          )}
          {!selected && <span style={{ width: '40px' }} />}
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default withStyles(styles)(CrewFilterMenu);
