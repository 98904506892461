import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';

import RateCell from '../Offers/RateCell';
import React from 'react';
import format from 'common/oldJavascripts/utils/format.js';
import Button from 'common/oldJavascripts/components/Base/Button';
import { Tooltip } from '@material-ui/core';

import Badges from 'common/components/Badges';
// HoC
import { withStyles } from '@material-ui/core/styles';

import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const styles = () => ({
  actionButtons: {
    padding: '16px 8px',
    overflow: 'unset',
  },
  overflowText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  badgesContainer: {
    marginTop: 6,
  },
});

class Row extends React.Component {
  constructor(props) {
    super(props);
    this._toggleDetails = this._toggleDetails.bind(this);
    this._onSelect = this._onSelect.bind(this);
    this._renderEndDateCell = this._renderEndDateCell.bind(this);
  }

  render() {
    const {
      offer = {},
      rowExpanded,
      openSigner,
      handleReject,
      expanded = false,
      classes,
    } = this.props;
    const { id, ready_to_sign: readyToSign, badges = [] } = offer;
    const fullName = this._getFullName(offer);

    const approveButton = () => {
      if (readyToSign) {
        return (
          <Button
            action={true}
            onClick={() => openSigner(id)}
            data-test-id={`ApprovalsRow-approveButton-${id}`}
          >
            Approve
          </Button>
        );
      }
      return (
        <Tooltip
          classes={{
            tooltip: 'offer-processing-tooltip',
          }}
          title="This offer is being prepared. It will be ready to approve shortly."
        >
          <div>
            <Button
              disabled
              action={true}
              data-test-id={`ApprovalsRow-processingButton-${id}`}
            >
              Processing...
            </Button>
          </div>
        </Tooltip>
      );
    };
    const rejectButton = () => {
      if (readyToSign) {
        return (
          <Button
            onClick={() => handleReject(id)}
            secondary={true}
            data-test-id={`ApprovalsRow-rejectButton-${id}`}
          >
            Reject
          </Button>
        );
      }
      return (
        <Tooltip
          classes={{
            tooltip: 'offer-processing-tooltip',
          }}
          title="This offer is being prepared. It will be ready to approve shortly."
        >
          <div>
            <Button
              disabled
              secondary={true}
              data-test-id={`ApprovalsRow-processingButton-${id}`}
            >
              Processing...
            </Button>
          </div>
        </Tooltip>
      );
    };
    return (
      <ExpandableTableRow
        key={id}
        onClick={this._toggleDetails}
        rowExpanded={rowExpanded}
        data-test-id={this.props['data-test-id']}
      >
        <ExpandableTableCell className="small-left-padding">
          <ExpandableTableCellTitle>{fullName}</ExpandableTableCellTitle>
          <Tooltip title={offer.email} enterDelay={100}>
            <div className={classes.overflowText}>{offer.email}</div>
          </Tooltip>
          <div
            className={classes.badgesContainer}
            data-test-id={`ApprovalsRow-badges-${offer.id}`}
          >
            <Badges badges={badges} id={offer.id} />
          </div>
        </ExpandableTableCell>
        <ExpandableTableCell>
          <ExpandableTableCellTitle>
            {offer.department_name}
          </ExpandableTableCellTitle>
        </ExpandableTableCell>
        <ExpandableTableCell>
          {offer.occupation_description}
          <br />
          {offer.account_code}
        </ExpandableTableCell>
        <ExpandableTableCell>{offer.union_description}</ExpandableTableCell>
        <ExpandableTableCell>
          {format.date(offer.start_date)}
        </ExpandableTableCell>
        {this._renderEndDateCell(offer)}
        <RateCell offer={offer} />
        <ExpandableTableCell align="center" expanded={expanded}>
          <AllowancesRow.Icon offer={offer} />
        </ExpandableTableCell>
        <ExpandableTableCell align="center" className={classes.actionButtons}>
          {approveButton()}
        </ExpandableTableCell>
        <ExpandableTableCell align="center" className={classes.actionButtons}>
          {rejectButton()}
        </ExpandableTableCell>
      </ExpandableTableRow>
    );
  }

  _getFullName(offer) {
    return format.fullName(
      offer.first_name,
      offer.middle_name,
      offer.last_name,
    );
  }

  _onSelect() {
    const { checked, offer = {}, onSelect } = this.props;
    const { id } = offer;
    onSelect(id, !checked);
  }

  _renderEndDateCell(offer) {
    const { endDateAllowed } = this.props;
    if (endDateAllowed) {
      return (
        <ExpandableTableCell>{format.date(offer.end_date)}</ExpandableTableCell>
      );
    }
  }

  _toggleDetails() {
    const { onToggle, offer } = this.props;
    const { id } = offer;
    onToggle(id);
  }
}

export default withStyles(styles)(Row);
