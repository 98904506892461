import React from 'react';
import qs from 'qs';
import history from 'common/constants/config/history';
import config from 'common/oldJavascripts/config.js';
import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import ApprovalsHeader from './Approvals/Header';
import ApprovalRow from './Approvals/Row';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Box from 'common/oldJavascripts/components/Base/Box';
import CustomFieldsRow from 'common/oldJavascripts/components/Shared/CustomFieldsRow';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Field from 'common/oldJavascripts/components/Base/Field';
import Flexbox from 'flexbox-react';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import OfferPacketsTable from 'studio/oldJavascripts/components/Shared/OfferPacketsTable';
import OfferRejectModal from 'common/oldJavascripts/components/Shared/OfferRejectModal';
import Pagination from 'common/oldJavascripts/components/Base/Pagination';
import Loader from 'common/components/Loader';
import ProcessedRow from './Approvals/ProcessedRow';
import RateScaleRow from 'common/oldJavascripts/components/Shared/RateScaleRow';
import Tabs from 'common/oldJavascripts/components/Base/Tabs';
import DocumentInteractionController from 'studio/oldJavascripts/components/Shared/DocumentInteractionController';
import ajax from 'common/utilities/ajax.js';
import AdditionalDealTerms from 'common/oldJavascripts/components/Pages/Project/Offers/AdditionalDealTerms.js';
import OfferFreeFormFields from './Offers/OfferFreeFormFields';
import TypographyLink from './TypographyLink.js';
import processDocumentRequests from 'common/utilities/processDocumentRequests';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// HoC
import withApi from 'common/hoc/withApi';
import { withStyles } from '@material-ui/core/styles';
import withPermissionProtection from 'common/hoc/withPermissionProtection';
import { compose } from 'redux';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const EMPTY_CONTENT = <Blankslate>No offers</Blankslate>;

const REJECT_MODAL_CONTENT = {
  closeLink: 'Close',
  note_label: 'Note',
  note_placeholder:
    'Please add a short explanation for declining the approval of the offer.' +
    ' For example, "the allowance amount is over budget."',
  paragraph:
    'You have chosen to decline the selected offer.' +
    ' It is required that you give us a short explanation for this action.',
  submitBtn: 'Submit',
  title: 'Add a Note',
};

const styles = () => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  employeeNameHeader: {
    width: '15%',
  },
  departmentHeader: {
    width: '10%',
  },
  jobTitleHeader: {
    width: '10%',
  },
  unionHeader: {
    width: '9%',
  },
  startDateHeader: {
    width: '9%',
  },
  rateHeader: {
    width: '10%',
  },
  allowancesHeader: {
    width: '11%',
  },
  actionButtonHeaders: {
    width: '12%',
  },
  statusHeader: {
    width: '12%',
  },
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
});

class OldApprovals extends React.Component {
  static mutators = {
    offerReject: {
      info(params, related) {
        const routerParams = related['/router/params'];
        return {
          create: true,
          id: `/v2/projects/${routerParams.projectId}/approvals`,
        };
      },
    },
  };

  static queries = {
    approvals: {
      info(params, related) {
        const routerParams = related['/router/params'];
        const query = related['/router/query'];
        return {
          cursor: {
            direction: query.direction,
            page: query.page,
            per_page: query.per_page || config.DEFAULT_PER_PAGE,
            q: query.q,
            sort: query.sort,
            status: query.status,
            t: query.t || '',
          },
          id: `/v2/projects/${routerParams.projectId}/approvals`,
        };
      },
    },

    project: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/projects/${params.projectId}`,
        };
      },
    },

    me: {
      info() {
        return { id: '/me' };
      },
    },
  };

  state = {
    expandedApprovals: [],
    hasAllExpanded: false,
    hasAllSelected: false,
    selectedApprovals: [],
    showRejectModal: false,
    expandedWorkflow: {},
    displaySigner: false,
    signingOfferId: null,
    errors: [],
    documentsSuccessfullySubmitted: [],
    isFreeFormFieldsShown: null,
    numberOfColumns: null,
  };

  componentWillUnmount() {
    this.destroyTracker();
  }

  componentDidUpdate() {
    this.checkForProcessingOffers();
  }

  checkForProcessingOffers = () => {
    const {
      approvals: {
        status: approvalsStatus,
        data: { items: approvals = [] } = {},
      } = {},
    } = this.props;

    if (approvalsStatus === 'loading' || this.reloadOfferTimer) return;
    const approvalsLoading = approvals
      .filter(({ ready_to_sign }) => ready_to_sign === false)
      .map(({ _id }) => _id);

    this.reloadOfferTimer = setTimeout(() => {
      this.reloadProcessingOffers(approvalsLoading);
    }, 2000);
  };

  reloadProcessingOffers = approvals => {
    const { approvals: { reloadSubResource } = {} } = this.props;
    approvals.forEach(id => reloadSubResource(id));
    // Clean up Timer
    clearInterval(this.reloadOfferTimer);
    this.reloadOfferTimer = null;
  };

  destroyTracker = () => clearInterval(this.reloadOfferTimer);

  render() {
    const isProcessed = this._isProcessed();
    const {
      displaySigner,
      signingOfferId: offerId,
      errors,
      isFreeFormFieldsShown,
    } = this.state;
    const { approvals = {} } = this.props;
    const { data = {} } = approvals;
    const { items = [] } = data;
    const selectedOffer = items.find(({ id }) => id === offerId);

    const { data: offers } = this.props.approvals;
    const { data: project } = this.props.project;

    return (
      <LayoutContent>
        <div>
          <OfferRejectModal
            content={REJECT_MODAL_CONTENT}
            onClose={this._onRejectModalClose}
            onSubmit={this._onRejectConfirm}
            visible={this.state.showRejectModal}
            data-test-id="Approvals-offerRejectModal"
          />

          {displaySigner && (
            <DocumentInteractionController
              buttonText={{
                accept: 'Approve Offer',
                reject: 'Reject Offer',
              }}
              errors={errors}
              offer={selectedOffer}
              offerId={offerId}
              type="approver"
              handleOfferAccept={this.handleOfferAccept}
              handleOfferReject={() => this.handleOfferReject(offerId)}
              handleEndDocumentInteraction={this.handleEndDocumentInteraction}
              handleSave={this.handleSave}
            />
          )}
          {!displaySigner && (
            <LayoutContent>
              <div>
                <Tabs block={true}>
                  <Tabs.Item
                    index="pending"
                    to="pending"
                    isActive={!isProcessed}
                    onClick={this._selectTab}
                    isDefault={true}
                  >
                    My Approvals
                  </Tabs.Item>
                  <Tabs.Item
                    index="processed"
                    to="processed"
                    isActive={isProcessed}
                    onClick={this._selectTab}
                  >
                    All Approvals
                  </Tabs.Item>
                </Tabs>
                {this._renderContent()}
                {this._renderPagination()}
              </div>
            </LayoutContent>
          )}
        </div>

        <Box>
          {isFreeFormFieldsShown && (
            <OfferFreeFormFields
              offer={offers.items.find(
                offer => offer.id === isFreeFormFieldsShown,
              )}
              offerId={isFreeFormFieldsShown}
              projectId={project.id}
              project={project}
              onSave={this._saveFreeFormFields}
              onClose={this._hideFreeFormFieldsByOffer}
            />
          )}
        </Box>
      </LayoutContent>
    );
  }

  handleEndDocumentInteraction = () =>
    this.setState({
      displaySigner: false,
      errors: [],
    });

  handleOfferAccept = (docs, offerId, documents) => {
    const { documentsSuccessfullySubmitted } = this.state;

    const documentRequests = Object.keys(docs)
      .filter(d => !documentsSuccessfullySubmitted.includes(d))
      .map(documentId => {
        const fields = Object.keys(docs[documentId].fields).map(fieldId => ({
          id: docs[documentId].fields[fieldId].id,
          value: docs[documentId].fields[fieldId].value,
        }));
        const postUrl = `/v2/offers/${offerId}/documents/${documentId}`;
        const promise = ajax
          .post(postUrl, { fields }, { json: true })
          .then(() =>
            this.setState(({ documentsSuccessfullySubmitted }) => ({
              documentsSuccessfullySubmitted: documentsSuccessfullySubmitted.concat(
                documentId,
              ),
            })),
          );
        return { promise, documentId };
      });

    return processDocumentRequests(documentRequests).then(() => {
      const {
        approvals,
        invalidateByPatterns,
        routerParams: { projectId },
      } = this.props;
      approvals.reload();
      invalidateByPatterns([`/projects/${projectId}/offers`]);
      this.setState({
        expandedApprovals: [],
        selectedApprovals: [],
        displaySigner: false,
        errors: [],
      });
    });
  };

  handleSave = (docs, isAutosave) => {
    const {
      documentsSuccessfullySubmitted,
      signingOfferId: offerId,
    } = this.state;

    // Sometimes autosave is triggered after the offer is closed in which case
    // we should just ignore it.
    if (isAutosave && !offerId) return;

    const documentRequests = Object.keys(docs)
      .filter(d => docs[d].ready && !documentsSuccessfullySubmitted.includes(d))
      .map(d => {
        const fields = Object.keys(docs[d].fields).map(fieldId => ({
          id: docs[d].fields[fieldId].id,
          value: docs[d].fields[fieldId].value,
        }));
        const { crew_acknowledged, id } = docs[d];
        const postUrl = `/v2/offers/${offerId}/documents/${id}/save-draft`;
        const promise = ajax.post(
          postUrl,
          { fields, crew_acknowledged },
          { json: true },
        );
        return { promise, documentId: id };
      });

    // If this is an autosave don't reject errors - we don't want to pop up
    // mysterious error messages while the user is typing.
    return processDocumentRequests(documentRequests, !isAutosave);
  };

  handleOfferReject = offerId => {
    this.setState({ showRejectModal: true, offerSelectedToReject: offerId });
    this.handleEndDocumentInteraction();
  };

  openSigner = offerId => {
    this.setState({
      displaySigner: true,
      signingOfferId: offerId,
    });
  };

  _hasSelection = () => {
    return this.state.selectedApprovals.length > 0;
  };

  _isExpanded = id => {
    return this.state.expandedApprovals.indexOf(id) >= 0;
  };

  _isSelected = id => {
    return this.state.selectedApprovals.indexOf(id) >= 0;
  };

  _isProcessed = (props = this.props) => {
    const { routerQuery: query = {} } = this.props;
    const { status } = query || {};
    return status === 'processed';
  };

  _onReject = () => {
    if (this._hasSelection()) {
      this.setState({ showRejectModal: true });
    }
  };

  _onRejectConfirm = value => {
    const {
      offerReject = {},
      approvals = {},
      routerParams: { projectId } = {},
      invalidateByPatterns,
    } = this.props;
    const { offerSelectedToReject } = this.state;
    if (offerSelectedToReject === null) return;

    offerReject.updateAndSave(
      { ids: [offerSelectedToReject], comment: value },
      'reject',
      { json: true },
      () => {
        invalidateByPatterns([`/projects/${projectId}/offers`]);
        approvals.reload();
        this._onRejectModalClose();
        this.setState({ offerSelectedToReject: null });
      },
    );
  };

  _onRejectModalClose = () => {
    this.setState({ showRejectModal: false });
  };

  _renderApproval = approval => {
    return [this._renderApprovalRow(approval)].concat(
      this._renderExpandedRows(approval),
    );
  };

  _renderApprovalRow = (approval = {}) => {
    const { project: projectQuery = {} } = this.props;
    const { data: projectData = {} } = projectQuery;
    const { allow_end_date } = projectData;
    const { id } = approval;
    return (
      <ApprovalRow
        offer={approval}
        checked={this._isSelected(id)}
        endDateAllowed={allow_end_date}
        key={id}
        onSelect={this._toggleApprovalSelect}
        onToggle={this._toggleApprovalExpand}
        rowExpanded={this._isExpanded(id)}
        openSigner={this.openSigner}
        handleReject={this.handleOfferReject}
        data-test-id={`Approvals-row-${approval.id}`}
      />
    );
  };

  _renderCommonHeaders = (isProcessed = false) => {
    const { classes, project: projectQuery = {} } = this.props;
    const { data: projectData = {} } = projectQuery || {};
    const { numberOfColumns } = this.state;
    let headers = [
      <ExpandableTableCell
        className={classes.employeeNameHeader}
        key="employee"
        header
        sort="last_name,first_name,middle_name"
      >
        Employee
      </ExpandableTableCell>,
      <ExpandableTableCell
        className={classes.departmentHeader}
        header
        key="department"
        sort="department"
      >
        Department
      </ExpandableTableCell>,
      <ExpandableTableCell
        className={classes.jobTitleHeader}
        header
        key="occupation"
        sort="occupation"
      >
        Job Title/
        <br />
        Acct Code
      </ExpandableTableCell>,
      <ExpandableTableCell
        className={classes.unionHeader}
        header
        key="union"
        sort="union"
      >
        Union
      </ExpandableTableCell>,
      <ExpandableTableCell
        className={classes.startDateHeader}
        key="startDate"
        header
        sort="start_date"
      >
        Start Date
      </ExpandableTableCell>,
      projectData.allow_end_date ? (
        <ExpandableTableCell
          className={classes.startDateHeader}
          header={true}
          key="endDate"
          sort="end_date"
        >
          End Date
        </ExpandableTableCell>
      ) : null,
      <ExpandableTableCell
        className={classes.rateHeader}
        header
        key="rate"
        sort="rate_per_hour_studio,rate_per_hour_location,rate_per_day_studio,rate_per_week_studio,rate_per_week_location"
      >
        Rate/ Scale
      </ExpandableTableCell>,
      <ExpandableTableCell
        className={classes.allowancesHeader}
        align="center"
        key="allowances"
        header
      >
        Allowances/ Reimbursements
      </ExpandableTableCell>,
      isProcessed ? (
        <ExpandableTableCell
          className={classes.statusHeader}
          header
          key="status"
          sort="status_description"
          align="center"
        >
          Status
        </ExpandableTableCell>
      ) : null,
      isProcessed ? null : (
        <ExpandableTableCell
          className={classes.actionButtonHeaders}
          align="center"
          key="approvals"
          header
        >
          Approval
        </ExpandableTableCell>
      ),
      isProcessed ? null : (
        <ExpandableTableCell
          className={classes.actionButtonHeaders}
          align="center"
          key="reject"
          header
        >
          Reject
        </ExpandableTableCell>
      ),
    ];
    const presentHeaders = headers.filter(header => !!header);
    if (
      !numberOfColumns ||
      (numberOfColumns && numberOfColumns !== presentHeaders.length)
    ) {
      this.setState({ numberOfColumns: presentHeaders.length });
    }
    return headers;
  };

  _renderContent = () => {
    const {
      approvals: { status },
    } = this.props;
    if (status === 'failed') {
      return this._renderContentFailed();
    }
    return this._isProcessed()
      ? this._renderListProcessed()
      : this._renderListPending();
  };

  _renderContentFailed = () => {
    return (
      <Box full={true}>
        <FailureAlert />
      </Box>
    );
  };

  _renderContentSuccess = () => {
    return this._isProcessed()
      ? this._renderListProcessed()
      : this._renderListPending();
  };

  _renderExpand = () => {
    const { hasAllExpanded } = this.state;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={hasAllExpanded}
            onChange={this._toggleExpandAll}
            color="primary"
          />
        }
        label="Expand All"
      />
    );
  };

  _renderListPending = () => {
    const {
      approvals: {
        data: { items: approvals = [] } = {},
        reload: reloadQuery,
      } = {},
      me: { data: { is_support_admin } = {} } = {},
    } = this.props;
    const { selectedApprovals = [] } = this.state;
    return (
      <Box full={true}>
        <BoxItem>
          <ApprovalsHeader
            isProcessed={is_support_admin || false}
            numOfApprovals={approvals.length}
            numOfSelectedApprovals={selectedApprovals.length}
            reloadQuery={reloadQuery}
          />
        </BoxItem>
        <BoxItem minimal={true}>
          <Flexbox marginBottom="5px" width="122px">
            {this._renderExpand()}
          </Flexbox>
        </BoxItem>
        {this._renderListPendingContent()}
      </Box>
    );
  };

  _renderListPendingContent = () => {
    const {
      approvals: {
        data: { items: approvals = [] } = {},
        status: approvalsStatus,
      } = {},
      classes = {},
    } = this.props;
    if (approvalsStatus === 'loading')
      return <Loader className={classes.loader} />;
    if (!approvals.length) {
      return EMPTY_CONTENT;
    }
    return (
      <ExpandableTable className={classes.table}>
        <ExpandableTableRow>{this._renderCommonHeaders()}</ExpandableTableRow>
        {approvals.map(this._renderApproval)}
      </ExpandableTable>
    );
  };

  _renderListProcessed = () => {
    const { approvals: { reload: reloadQuery } = {} } = this.props;
    return (
      <Box full={true}>
        <BoxItem>
          <ApprovalsHeader isProcessed={true} reloadQuery={reloadQuery} />
        </BoxItem>
        {this._renderListProcessedContent()}
      </Box>
    );
  };

  _renderListProcessedContent = () => {
    const {
      approvals: {
        data: { items: approvals = [] } = {},
        status: approvalsStatus,
      } = {},
      classes = {},
    } = this.props;
    if (approvalsStatus === 'loading')
      return <Loader className={classes.loader} />;
    if (approvals.length === 0) {
      return EMPTY_CONTENT;
    }
    return (
      <ExpandableTable className={classes.table}>
        <ExpandableTableRow data-test-id="Approved-row-header">
          {this._renderCommonHeaders(true)}
        </ExpandableTableRow>
        {approvals.map(this._renderProcessedApproval)}
      </ExpandableTable>
    );
  };

  _renderPagination = () => {
    const {
      approvals: { data: { page, total_pages: totalPages } = {} } = {},
    } = this.props;
    if (totalPages === 0) return null;
    return <Pagination page={page} totalPages={totalPages} />;
  };

  _renderSelect = () => {
    const hasAllSelected = this.state.hasAllSelected;
    return (
      <Field
        hideLabel={true}
        inline={true}
        label={`${hasAllSelected ? 'Uns' : 'S'}elect All`}
        onChange={this._toggleSelectAll}
        type="checkbox"
        value={hasAllSelected}
      />
    );
  };

  _renderCustomFieldsRow = approval => {
    const { numberOfColumns } = this.state;
    const scope = `/v2/offers/${approval.id}`;
    return <CustomFieldsRow colSpan={numberOfColumns} params={{ scope }} />;
  };

  _handleExpandWorkflow = (offerId, documentId) => {
    this.setState({
      expandedWorkflow: {
        [offerId]: documentId,
      },
    });
  };

  _renderOfferPacketsRow = approval => {
    const { routerParams: params = {} } = this.props;
    const { projectId } = params;
    const scope = `/v2/projects/${projectId}/approvals/${approval.id}`;
    const { expandedWorkflow, numberOfColumns } = this.state;
    return (
      <ExpandableTableRow
        key={`packet-documents-${approval.id}`}
        rowExpanded={true}
      >
        <ExpandableTableCell colSpan={numberOfColumns} expanded={true}>
          <ExpandableTableRowHeader>
            {'Offer Documents'}
          </ExpandableTableRowHeader>
          <ExpandableTableRowInfo>
            <OfferPacketsTable
              hidePermanent={true}
              params={{
                scope,
                offerId: approval.id,
                hasPackets: approval.has_packets,
              }}
              expandedWorkflow={expandedWorkflow}
              actionClickHandler={this._handleExpandWorkflow}
              showHeader={false}
              width="100%"
            />
          </ExpandableTableRowInfo>
        </ExpandableTableCell>
      </ExpandableTableRow>
    );
  };

  _renderProcessedApprovalRow = approval => {
    const project = this.props.project.data;
    return (
      <ProcessedRow
        approval={approval}
        key={approval.id}
        params={{ id: approval.id, type: 'approver' }}
        endDateAllowed={project.allow_end_date}
        onToggle={this._toggleApprovalExpand}
        data-test-id={`Approved-row-${approval.id}`}
      />
    );
  };

  _renderProcessedApproval = approval => {
    return [this._renderProcessedApprovalRow(approval)].concat(
      this._renderExpandedRows(approval),
    );
  };

  _renderExpandedRows = approval => {
    if (!this._isExpanded(approval.id)) {
      return [];
    }

    return [
      this._renderJobDescription(approval),
      this._renderFreeFormFieldsLink(approval),
      this._renderRateScaleRow(approval),
      this._renderCustomFieldsRow(approval),
      this._renderAllowancesRow(approval),
      this._renderOfferPacketsRow(approval),
      this._renderNotes(approval),
    ];
  };

  _renderAllowancesRow = approval => {
    const { numberOfColumns } = this.state;
    return (
      <AllowancesRow
        colSpan={numberOfColumns}
        key={`allowance-${approval.id}`}
        offer={approval}
      />
    );
  };

  _renderEndDateHeader = () => {
    const project = this.props.project.data;
    if (project.allow_end_date) {
      return (
        <ExpandableTableCell header={true} key="endDate" sort="end_date">
          End Date
        </ExpandableTableCell>
      );
    }
  };

  _renderNotes = approval => {
    const { numberOfColumns } = this.state;
    if (approval.notes) {
      return (
        <ExpandableTableRow
          key={`approval-notes-${approval.id}`}
          rowExpanded={true}
        >
          <ExpandableTableCell colSpan={numberOfColumns} expanded={true}>
            <AdditionalDealTerms
              title="Additional Deal Terms"
              notes={approval.notes || ''}
              oldNotes={[...(approval.old_notes || [])].reverse()}
              headerStyles={{
                color: '#3c9dd7',
                marginLeft: '8px',
                textTransform: 'uppercase',
                fontSize: '0.9rem',
              }}
              currentDate={approval.sent_at}
            />
          </ExpandableTableCell>
        </ExpandableTableRow>
      );
    }
    return null;
  };

  _renderJobDescription = approval => {
    const { numberOfColumns } = this.state;
    if (approval.custom_occupation_description) {
      return (
        <ExpandableTableRow
          key={`custom-occupation-description-${approval.id}`}
          rowExpanded={true}
        >
          <ExpandableTableCell colSpan={numberOfColumns} expanded={true}>
            <ExpandableTableRowHeader>Job Description</ExpandableTableRowHeader>
            <p style={{ marginLeft: 8 }}>
              {approval.custom_occupation_description}
            </p>
          </ExpandableTableCell>
        </ExpandableTableRow>
      );
    }
    return null;
  };

  _renderFreeFormFieldsLink = approval => {
    const { numberOfColumns } = this.state;
    return (
      <ExpandableTableRow
        key={`custom-free-form-fields-${approval.id}`}
        rowExpanded
      >
        <ExpandableTableCell colSpan={numberOfColumns} expanded>
          <TypographyLink
            onClick={() =>
              this._showFreeFormFieldsByOffer({ offerId: approval.id })
            }
            title={'Set Account Codes'}
          />
        </ExpandableTableCell>
      </ExpandableTableRow>
    );
  };

  _selectTab = index => {
    const { location } = this.props;
    const { pathname } = location || {};
    history.push({
      pathname,
      search: qs.stringify({
        status: index,
        page: 1,
      }),
    });
  };

  _toggleApprovalExpand = id => {
    const {
      approvals: { data: { items: approvals = [] } = {} } = {},
    } = this.props;
    const { expandedApprovals } = this.state;
    const updatedExpandedApprovals = [...expandedApprovals];
    const index = updatedExpandedApprovals.indexOf(id);
    if (index >= 0) {
      updatedExpandedApprovals.splice(index, 1);
    } else {
      updatedExpandedApprovals.push(id);
    }
    const hasAllExpanded =
      updatedExpandedApprovals.length &&
      updatedExpandedApprovals.length === approvals.length;
    this.setState({
      hasAllExpanded,
      expandedApprovals: updatedExpandedApprovals,
    });
  };

  _toggleApprovalSelect = (id, selected) => {
    const {
      approvals: { data: { items: approvals = [] } = {} } = {},
    } = this.props;
    const selectedApprovals = this.state.selectedApprovals.concat();
    const index = selectedApprovals.indexOf(id);
    if (selected) {
      if (index < 0) {
        selectedApprovals.push(id);
      }
    } else if (index >= 0) {
      selectedApprovals.splice(index, 1);
    }
    const hasAllSelected = selectedApprovals.length === approvals.length;
    this.setState({
      hasAllSelected,
      selectedApprovals,
    });
  };

  _toggleExpandAll = () => {
    const hasAllExpanded = !this.state.hasAllExpanded;
    const {
      approvals: { data: { items: approvals = [] } = {} } = {},
    } = this.props;
    const expandedApprovals = hasAllExpanded
      ? approvals.map(approval => approval.id)
      : [];
    this.setState({
      expandedApprovals,
      hasAllExpanded,
    });
  };

  _toggleSelectAll = () => {
    const {
      approvals: { data: { items: approvals = [] } = {} } = {},
    } = this.props;
    const hasAllSelected = !this.state.hasAllSelected;
    const selectedApprovals = hasAllSelected
      ? approvals.map(approval => approval.id)
      : [];
    this.setState({
      hasAllSelected,
      selectedApprovals,
    });
  };

  _renderRateScaleRow = approval => {
    const { numberOfColumns } = this.state;
    return (
      <RateScaleRow
        colSpan={numberOfColumns}
        key={`rate-${approval.id}`}
        offer={approval}
      />
    );
  };

  _showFreeFormFieldsByOffer = offer => {
    this.setState({ isFreeFormFieldsShown: offer.offerId });
  };

  _hideFreeFormFieldsByOffer = offerId => {
    this.setState({ isFreeFormFieldsShown: null });
  };
}

export default compose(
  withPermissionProtection('can_approve_offers'),
  withStyles(styles),
  withApi,
)(OldApprovals);
