import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  TextField,
  FormHelperText,
} from '@material-ui/core';

import PermissionExpansionPanel from 'admin/components/PermissionExpansionPanel';

const styles = {
  input: {
    width: 300,
  },
  topMargin: {
    marginTop: 16,
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#30aba3',
    },
  },
  cssFocused: {},
  cssShrinked: {
    color: '#30aba3',
  },
};

class PermissionPresetForm extends React.Component {
  render() {
    const {
      classes,
      preset = {},
      errors,
      permissionGroups = [],
      selections = [],
      onPresetChange,
      onPermissionChange,
    } = this.props;

    const { name = '', id } = preset;

    const { name: nameError = '', permissions: permissionsError = '' } =
      errors || {};

    return (
      <Paper elevation={0}>
        <Typography variant="h5" data-test-id="PermissionPresetForm-header">
          {id ? 'Edit Permission Preset' : 'Create Permission Preset'}
        </Typography>

        <TextField
          required
          error={!!nameError}
          helperText={nameError}
          id="permission-preset-name"
          label="Permission Preset Name"
          value={name}
          onChange={event => onPresetChange('name')(event.target.value)}
          margin="normal"
          className={classes.input}
          inputProps={{
            'data-test-id': 'PermissionPresetForm-name',
          }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
              shrink: classes.cssShrinked,
            },
          }}
          FormHelperTextProps={{
            'data-test-id': 'PermissionPresetForm-error-name',
          }}
        />

        <Typography
          gutterBottom
          variant="subtitle1"
          className={classes.topMargin}
        >
          Permissions
        </Typography>

        {permissionsError && (
          <FormHelperText
            error
            data-test-id="PermissionPresetForm-error-permissions"
          >
            {permissionsError}
          </FormHelperText>
        )}

        {permissionGroups.map(item => {
          return (
            <PermissionExpansionPanel
              key={item.id}
              permissionGroup={item}
              selections={selections}
              onChange={onPermissionChange(item.id)}
            />
          );
        })}
      </Paper>
    );
  }
}

export default withStyles(styles)(PermissionPresetForm);
