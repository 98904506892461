import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'class-names';

const styles = {
  root: {
    boxFlex: '1',
    flex: '1',
    boxPack: 'justify',
    justifyContent: 'space-between',
    display: 'flex',
    boxOrient: 'horizontal',
    flexDirection: 'row',
    margin: '0 auto',
    width: '100%',
    minHeight: '0px',
  },
  full: {
    alignSelf: 'stretch',
    maxWidth: 'none',
  },
};

const LayoutMain = props => {
  const { classes, full, children } = props;
  const rootClass = classNames(classes.root, {
    [classes.full]: full,
  });
  return <div className={rootClass}>{children}</div>;
};

export default withStyles(styles)(LayoutMain);
