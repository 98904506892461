import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import history from 'common/constants/config/history';
import { Link } from 'react-router-dom';

const noteSentStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#f7fcfc',
    padding: '20px 50px',
  },
  title: {
    color: '#2eaba4',
    fontSize: '18px',
    fontWeight: 400,
  },
  noteSentBtnArea: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  viewAllBtn: {
    width: 231,
    height: 40,
    borderRadius: 2,
    backgroundColor: '#2eaba4',
    fontSize: 14,
    color: '#FFFFFF',
    marginTop: '10px',
    marginBottom: '10px',
  },
  noUnderline: {
    textDecoration: 'none !important',
  },
  logoutBtn: {
    width: 232,
    height: 40,
    borderRadius: 2,
    background: 'transparent',
    border: '1px solid #2eaba4',
    fontSize: 14,
    color: '#2eaba4',
    marginTop: '10px',
    marginBottom: '10px',
    '&:hover': {
      background: 'transparent !important',
    },
    '&:focus': {
      background: 'transparent !important',
    },
  },
}));
const NoteSent = ({ closeHandler, projectId, logoutHandler }) => {
  const classes = noteSentStyles();
  const offersUrl = `/projects/${projectId}/offers`;
  const returnToOffers = () => {
    closeHandler();
    history.push({ pathname: offersUrl });
  };
  return (
    <div className={classes.root} data-test-id="RejectOffer-NoteSent">
      <Typography className={classes.title}>Note Sent</Typography>
      <Typography className={classes.description}>
        An email was sent to this crew member. Do you want to go to your
        projects or view more offers?
      </Typography>
      <div className={classes.noteSentBtnArea}>
        <Button className={classes.viewAllBtn} onClick={() => returnToOffers()}>
          View Offers
        </Button>
        <Link to="/projects" className={classes.noUnderline}>
          <Button className={classes.viewAllBtn}>View All Projects</Button>
        </Link>
        <Button className={classes.logoutBtn} onClick={() => logoutHandler()}>
          Logout
        </Button>
      </div>
    </div>
  );
};

export default NoteSent;
