import config from 'common/oldJavascripts/config.js';
import React, { Component } from 'react';
import Box from 'common/oldJavascripts/components/Base/Box';
import Header from 'common/oldJavascripts/components/Base/Header';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Pagination from 'common/oldJavascripts/components/Base/Pagination';
import T from 'common/oldJavascripts/utils/i18n';

import OffersTableHeader from './OffersTableHeader';
import OffersTableRow from './OffersTableRow';
import OfferPacketsTable from 'studio/oldJavascripts/components/Shared/OfferPacketsTable';
import RateScaleRow from 'common/oldJavascripts/components/Shared/RateScaleRow';
import AllowancesRow from 'common/oldJavascripts/components/Shared/AllowancesRow';
import OfferFullHistory from '../Offers/OfferFullHistory';

// Components

import Loader from 'common/components/Loader';
import QueryRefresher from 'common/components/QueryRefresher';

import history from 'common/constants/config/history';
import qs from 'qs';

// HoC
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';

import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

const styles = () => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
});

class ArchivedOffersTable extends Component {
  static queries = {
    project: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: '/projects/' + params.projectId,
        };
      },
    },
    offers: {
      info(params = {}, related) {
        const query = related['/router/query'];
        const routerParams = related['/router/params'];
        return {
          id: `/v2/projects/${routerParams.projectId}/rescinded-archived-offers`,
          cursor: {
            page: query.ao_page,
            per_page: query.per_page || config.DEFAULT_PER_PAGE,
            sort: query.sort,
            direction: query.direction,
            q: query.q,
          },
          expires: true,
        };
      },
    },
  };

  state = {
    expandedOffersDetails: [],
    expandedOffersStatus: [],
    expandedWorkflow: {},
  };

  handleDocumentSelect = (offerId, documentId) => {
    if (documentId) {
      this.setState({
        expandedWorkflow: {
          [offerId]: documentId,
        },
      });
      return;
    }
    this.setState({ expandedWorkflow: {} });
  };

  _toggleOfferDetails = id =>
    this.setState(({ expandedOffersDetails }) => {
      const index = expandedOffersDetails.indexOf(id);
      if (expandedOffersDetails.includes(id)) {
        const expandedOffersDetailsCopy = [...expandedOffersDetails];
        expandedOffersDetailsCopy.splice(index, 1);
        return {
          expandedOffersDetails: expandedOffersDetailsCopy,
        };
      }
      return {
        expandedOffersDetails: [...expandedOffersDetails, id],
      };
    });

  _toggleOfferStatus = id => {
    var expandedOffers = this.state.expandedOffersStatus.slice(0);
    const index = expandedOffers.indexOf(id);

    if (index !== -1) {
      expandedOffers.splice(index, 1);
    } else {
      expandedOffers.push(id);
    }

    var newState = this._resetState(id, 'expandedOffersDetails');
    newState['expandedOffersStatus'] = expandedOffers;
    this.setState(newState);
  };

  _resetState = (id, type) => {
    const list = this.state[type].slice(0);
    const index = list.indexOf(id);
    var ret = { [type]: [] };

    if (index !== 1) {
      list.splice(index, 1);
      ret[type] = list;
    }

    return ret;
  };

  _changeOffersPage = page => {
    const { routerPath: pathname, routerQuery = {} } = this.props;
    history.push({
      pathname,
      search: qs.stringify({
        ...routerQuery,
        ao_page: page,
      }),
    });
  };

  render() {
    const {
      expandedOffersDetails,
      expandedOffersStatus,
      expandedWorkflow,
    } = this.state;
    const {
      offers: offersQuery = {},
      project: projectQuery = {},
      classes = {},
    } = this.props;
    const {
      data: offersData,
      status: offersStatus,
      reload: reloadOffersQuery,
    } = offersQuery || {};
    const { items: offers = [] } = offersData || {};
    const { data: { allow_end_date: endDateAllowed } = {} } =
      projectQuery || {};
    const loading = offersStatus === 'loading';
    const isDetailsExpanded = id => expandedOffersDetails.includes(id);
    const isStatusExpanded = id => expandedOffersStatus.includes(id);
    const hasOffers = offers.length > 0;

    const renderOffersRows = offer => (
      <React.Fragment key={offer.id}>
        <OffersTableRow
          key={offer.id}
          endDateAllowed={endDateAllowed}
          offer={offer}
          params={{ offer_id: offer.id }}
          detailsExpanded={isDetailsExpanded(offer.id)}
          statusExpanded={isStatusExpanded(offer.id)}
          onToggleDetails={this._toggleOfferDetails}
          onToggleStatus={this._toggleOfferStatus}
        />
        {isDetailsExpanded(offer.id) && (
          <RateScaleRow
            key={`rate-${offer.id}`}
            rowExpanded={true}
            offer={offer}
            colSpan={9}
          />
        )}
        {isDetailsExpanded(offer.id) && (
          <AllowancesRow
            key={`allowance-${offer.id}`}
            rowExpanded={true}
            colSpan={9}
            offer={offer}
          />
        )}

        {isDetailsExpanded(offer.id) && (
          <ExpandableTableRow
            key={`packet-documents-${offer.id}`}
            rowExpanded={true}
          >
            <ExpandableTableCell colSpan={9} expanded={true}>
              <ExpandableTableRowHeader>
                Offer Documents
              </ExpandableTableRowHeader>
              <ExpandableTableRowInfo>
                <OfferPacketsTable
                  hidePermanent={true}
                  showHeader={false}
                  rowExpanded={true}
                  colSpan={9}
                  width="100%"
                  params={{
                    scope: `/v2/offers/${offer.id}`,
                    offerId: offer.id,
                    hasPackets: offer.has_packets,
                  }}
                  expandedWorkflow={expandedWorkflow}
                  actionClickHandler={this.handleDocumentSelect}
                />
              </ExpandableTableRowInfo>
            </ExpandableTableCell>
          </ExpandableTableRow>
        )}
        {isStatusExpanded(offer.id) && (
          <ExpandableTableCell colSpan={9} expanded>
            <Header>
              <Header.Title secondary>Offer Full History</Header.Title>
            </Header>
            <OfferFullHistory offer={offer} />
          </ExpandableTableCell>
        )}
      </React.Fragment>
    );

    const renderContent = () => (
      <React.Fragment>
        {!hasOffers && <Blankslate>No Offers</Blankslate>}
        {hasOffers && (
          <ExpandableTable className={classes.table}>
            <OffersTableHeader endDateAllowed={endDateAllowed} />
            {offers.map(renderOffersRows)}
          </ExpandableTable>
        )}
      </React.Fragment>
    );

    return (
      <div data-test-id="OffersTable-root">
        <Box>
          <BoxItem>
            <Header>
              <Header.Title>{T('archives.title')}</Header.Title>
              <QueryRefresher reloadQuery={reloadOffersQuery} />
            </Header>
          </BoxItem>
          {loading && <Loader className={classes.loader} />}
          {!loading && renderContent()}
        </Box>
        {hasOffers && (
          <Pagination
            totalPages={offersData.total_pages}
            page={offersData.page}
            onChange={this._changeOffersPage}
          />
        )}
      </div>
    );
  }
}

export default compose(withApi, withStyles(styles))(ArchivedOffersTable);
