import React from 'react';
import withApi from 'common/hoc/withApi';
import Loader from 'common/components/Loader';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import ProjectTemplates from 'admin/components/ProjectTemplates';
import Ajax from 'common/utilities/ajax';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser';
import { DEFAULT_PER_PAGE } from 'common/oldJavascripts/config.js';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';
import queryParam from 'common/utilities/queryParam';
import transformProjectTemplates from './transformProjectTemplates_NEW';

const styles = theme => ({
  header: {
    height: 20,
  },
  sortName: {
    position: 'absolute',
    left: 61,
    cursor: 'pointer',
  },
  sortRank: {
    position: 'absolute',
    width: 70,
    right: 355,
    cursor: 'pointer',
  },
  sortIcon: {
    position: 'relative',
    top: 2,
    height: 15,
  },
});

class DocumentContainerWrapper extends React.Component {
  static queries = {
    projectTemplates: {
      info: (params, related) => {
        const { projectId } = params;
        const { '/router/query': query } = related;
        const cursor = {
          direction: queryParam(query.order, 'asc'),
          page: queryParam(query.page, 1),
          per_page: queryParam(query.perPage, DEFAULT_PER_PAGE),
          sort: queryParam(query.orderBy, 'rank'),
        };
        return {
          id: `/workflow/projects/${projectId}/project_templates_v2`,
          cursor,
        };
      },
    },
  };

  state = {
    approvers: [],
    isDeleting: null,
    isUpdatingRank: null,
    errors: {},
    verifiers: [],
  };

  componentDidMount() {
    this.requestUsers();
  }

  componentWillReceiveProps(nextProps) {
    const { attachedTemplates: newlyAttachedIds = [] } = nextProps;
    const { onProcessedAttachments, projectTemplates } = this.props;
    if (newlyAttachedIds.length > 0) {
      projectTemplates.reload();
      onProcessedAttachments();
    }
  }

  requestUsers = () => {
    const { projectId } = this.props.params;
    Ajax.get(`/v2/projects/${projectId}/users?role=approver`, {}).then(
      response => {
        const { items: approvers = [] } = response;
        this.setState({ approvers });
      },
    );
    Ajax.get(`/v2/projects/${projectId}/users?role=verifier`, {}).then(
      response => {
        const { items: verifiers = [] } = response;
        this.setState({ verifiers });
      },
    );
  };

  handleDelete = item => {
    const {
      params: { projectId },
      projectTemplates: { reload },
    } = this.props;
    this.setState({ isDeleting: item.id }, () => {
      Ajax.delete(`/workflow/projects/${projectId}/templates/${item.id}`).then(
        () => {
          this.setState({ isDeleting: null });
          reload();
        },
      );
    });
  };

  getOriginalTemplateData = templateId => {
    return this.getTransformedProjectTemplates().find(
      ({ id }) => id === templateId,
    );
  };

  handleUpdate = (updates, updateType = 'default') => {
    const originalData = this.getOriginalTemplateData(updates.id);
    this.setState({ errors: {} });
    const { projectId } = this.props.params;
    let data = {
      ...updates,
    };
    const { id: templateId } = updates;

    // Transform from component-side model of support docs to server-side model.
    const sd = data.supportDocument;
    if (sd) {
      data.supportingDocumentTitles = [
        {
          documentTitle: sd.name,
          required: sd.isRequired,
          attachToMainDocument: sd.isIncluded,
          rank: 1,
        },
      ];
    } else {
      data.supportingDocumentTitles = [];
    }
    delete data.supportDocument;

    const url =
      updateType === 'default'
        ? `/workflow/projects/${projectId}/templates/${templateId}/v2`
        : `/workflow/projects/${projectId}/templates/${templateId}/status`;
    Ajax.patch(url, { ...data }, { json: true })
      .then(response => {
        if (updateType === 'default') {
          this.saveSuccess(templateId, response);
          return;
        }
        if (updateType === 'rank') {
          this.setState({ isUpdatingRank: templateId }, () => {
            this.saveStatusSuccess();
          });
          return;
        }
        if (updateType === 'active') {
          const {
            projectTemplates: {
              data: { items: { item: documents = [] } = {} } = {},
            },
          } = this.props;
          const { active, id: docId } = updates;
          return documents.map(doc =>
            doc.id === docId ? { ...doc, active } : doc,
          );
        }
        this.saveStatusSuccess();
      })
      .catch(this.saveError(templateId, originalData));
  };

  saveStatusSuccess = () => {
    this.setState({ isUpdatingRank: null });
    this.props.projectTemplates.reload();
  };

  saveSuccess = () => {
    this.props.projectTemplates.reload();
  };

  saveError = (templateId, originalData) => {
    return (err = {}) => {
      const message = parseErrorMessages(err);
      const errors = { message, id: templateId };
      this.setState({ errors, isUpdatingRank: null, isDeleting: null });
    };
  };

  request = items => {
    const { key, param, successCallback, rule } = items;
    const { requestHandler } = this.props;
    requestHandler({ key, param, successCallback, rule });
  };

  getTransformedProjectTemplates = () => {
    const {
      projectTemplates: {
        data: { items: { item: rawTemplates = [] } = {} } = {},
      },
      departments,
      users,
    } = this.props;
    return transformProjectTemplates(rawTemplates, users, departments);
  };

  renderHeader = () => {
    const { classes, amendRouterQuery, getRouterQueryParam } = this.props;
    const sortDir = getRouterQueryParam('order', 'asc');
    const sortField = getRouterQueryParam('orderBy', 'rank');
    const isName = sortField === 'name';
    const isRank = sortField === 'rank';
    const isAsc = sortDir === 'asc';
    const isDesc = sortDir === 'desc';
    const sort = newSortField => {
      const newSortDir =
        newSortField === sortField && sortDir === 'asc' ? 'desc' : 'asc';
      amendRouterQuery({ orderBy: newSortField, order: newSortDir });
    };
    return (
      <div className={classes.header}>
        <div className={classes.sortName} onClick={() => sort('name')}>
          Name
          {isName && isAsc && <ArrowUpward className={classes.sortIcon} />}
          {isName && isDesc && <ArrowDownward className={classes.sortIcon} />}
        </div>
        <div className={classes.sortRank} onClick={() => sort('rank')}>
          Rank
          {isRank && isAsc && <ArrowUpward className={classes.sortIcon} />}
          {isRank && isDesc && <ArrowDownward className={classes.sortIcon} />}
        </div>
      </div>
    );
  };

  render() {
    const {
      projectTemplates: { data, status },
      routerQuery = {},
      projectCountryId,
    } = this.props;
    const { page: routerQueryPageParam = '' } = routerQuery || {};

    if (status === 'failed') return <FailureAlert queryName="Templates" />;
    if (!data.items && status === 'loading') return <Loader />;

    const {
      items: { total_items: totalItems },
    } = data;
    const templateList = this.getTransformedProjectTemplates();

    const {
      approvers,
      verifiers,
      isDeleting,
      isUpdatingRank,
      errors,
    } = this.state;

    // There are cross overs between approvers and verifiers
    // This makes sure we only pass down one of each user
    // Also it allows us to throw the role in there to filter out users based on tags
    const userObject = {};
    [...approvers].forEach(user => {
      const existingUser = userObject[user.id] || {};
      const { roles = [] } = existingUser;
      userObject[user.id] = {
        ...userObject[user.id],
        id: `${user.id}`,
        fullName: `${user.first_name} ${user.last_name}`,
        roles: [...roles, 'approver'],
      };
    });
    [...verifiers].forEach(user => {
      const existingUser = userObject[user.id] || {};
      const { roles = [] } = existingUser;
      userObject[user.id] = {
        ...userObject[user.id],
        id: `${user.id}`,
        fullName: `${user.first_name} ${user.last_name}`,
        roles: [...roles, 'verifier'],
      };
    });
    const userArray = Object.keys(userObject).map(user => userObject[user]);

    return (
      <div>
        {this.renderHeader()}
        <ProjectTemplates
          page={routerQueryPageParam}
          error={errors}
          users={userArray}
          documents={templateList}
          templateLoadingStatus={status}
          requestHandler={this.request}
          handleUpdate={this.handleUpdate}
          handleDelete={this.handleDelete}
          totalItems={totalItems}
          isDeleting={isDeleting}
          isUpdatingRank={isUpdatingRank}
          projectCountryId={projectCountryId}
        />
      </div>
    );
  }
}

export default withApi(withStyles(styles)(DocumentContainerWrapper));
