import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

// MuiComponents
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import PersonIcon from '@material-ui/icons/Person';

// Utilities
import classNames from 'classnames';

const styles = {
  root: {
    padding: '0 !important',
  },
  icon: {
    marginRight: 8,
  },
  header: {
    paddingTop: 10,
    paddingLeft: 10,
    alignSelf: 'center',
  },
  listHeader: {
    borderTop: '1px solid #979ca5',
    borderBottom: '1px solid #979ca5',
    fontSize: '1.2rem',
  },
  listItem: {
    borderBottom: '1px solid #979ca5',
  },
  queuedListItem: {
    backgroundColor: '#fff9f1',
  },
  reviewedListItem: {
    backgroundColor: '#f4f9e5',
  },
  bold: {
    fontWeight: 700,
  },
};

const CrewMemberReviewerList = props => {
  const {
    className,
    classes,
    reviewerQueue = [],
    reviewedOffers = [],
    offerIdInReview,
  } = props;

  return (
    <Paper className={classNames(className, classes.root)}>
      <Typography variant="h4" className={classes.header}>
        Reviewer Queue
      </Typography>
      <List>
        <ListSubheader className={classes.listHeader}>
          Waiting for Review
        </ListSubheader>
        {reviewerQueue.map(
          ({
            projectUser: {
              profile: { firstName = '', lastName = '' } = {},
            } = {},
            id,
          }) => (
            <ListItem
              className={classNames(classes.listItem, classes.queuedListItem, {
                [classes.bold]: id === offerIdInReview,
              })}
              data-test-id={`CrewMemberReviewerList-reviewQueue-${firstName}${lastName}`}
            >
              <PersonIcon className={classes.icon} />
              {`${firstName} ${lastName}`}
            </ListItem>
          ),
        )}
      </List>
      <List>
        <ListSubheader className={classes.listHeader}>Reviewed</ListSubheader>
        {reviewedOffers.map(
          ({
            projectUser: {
              profile: { firstName = '', lastName = '' } = {},
            } = {},
          }) => (
            <ListItem
              className={classNames(classes.listItem, classes.reviewedListItem)}
              data-test-id={`CrewMemberReviewerList-reviewedOffer-${firstName}${lastName}`}
            >
              <PersonIcon className={classes.icon} />
              {`${firstName} ${lastName}`}
            </ListItem>
          ),
        )}
      </List>
    </Paper>
  );
};

export default withStyles(styles)(CrewMemberReviewerList);
