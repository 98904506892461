import React from 'react';

// Components
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaskedInput from 'react-text-mask';

// Constants
import { ratePerHourMask } from '../../helpers/textFieldNumberMasks';

// Utilities
import formatCurrency from '../../helpers/formatCurrency';

const DailyRate = ({
  onChange: upstreamOnChange,
  formData: { dailyRate } = {},
  classes = {},
}) => {
  const onChange = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') {
      upstreamOnChange({
        dailyRate: null,
      });
      return;
    }
    upstreamOnChange({
      dailyRate: value,
      weeklyRate: null,
      numberOfDaysPerWorkWeek: null,
    });
    return;
  };

  const onBlur = ({ target: { value } = {} } = {}) => {
    if (value === '' || value === '0') return;

    const formattedValue = formatCurrency(value);

    upstreamOnChange({
      dailyRate: formattedValue,
      weeklyRate: null,
      numberOfDaysPerWorkWeek: null,
    });
    return;
  };

  return (
    <TableCell align="center" className={classes.root}>
      <MaskedInput
        mask={ratePerHourMask}
        value={dailyRate || ''}
        onChange={onChange}
        onBlur={onBlur}
        render={(ref, props) => (
          <TextField
            inputRef={ref}
            {...props}
            className={classes.textField}
            InputProps={{
              className: classes.textFieldInputContainer,
              inputProps: {
                className: classes.textFieldInput,
                'data-test-id': 'DailyRate-input',
              },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      />
    </TableCell>
  );
};

export default DailyRate;
