import React from 'react';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Button from 'common/oldJavascripts/components/Base/Button';
import ContactDetails from 'common/oldJavascripts/components/Base/ContactDetails';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import getSSOUrl from 'common/constants/config/ssoUrl';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '48% 4% 48%',
    gridTemplateRows: '100%',
    gridTemplateAreas: `
        "crew ... hirer"`,
  },
  crew: {
    gridArea: 'crew',
  },
  hirer: {
    gridArea: 'hirer',
  },
  paper: {
    minHeight: 172,
  },
  contactGroupContainer: {
    padding: '8px 15px',
    display: 'grid',
    gap: '5px',
    gridGap: '5px',
  },
  contactDetailContainer: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '30px auto',
    alignItems: 'center',
    fontSize: '0.92307692em',
    lineHeight: '1.07692308',
    fontWeight: '400',
  },
  icons: {
    height: '18px',
    width: 'auto',
  },
});

const HirerContactDetails = withStyles(styles)(
  ({ hirer = {}, classes } = {}) => (
    <BoxItem>
      <ContactDetails>
        <ContactDetails.Title>
          {hirer.first_name + ' ' + hirer.last_name}
        </ContactDetails.Title>
        <ContactDetails.Data secondary={true}>
          <ContactDetails.DataValue>{hirer.title}</ContactDetails.DataValue>
        </ContactDetails.Data>
      </ContactDetails>
      <div className={classes.contactGroupContainer}>
        <div className={classes.contactDetailContainer}>
          <EmailIcon className={classes.icons} />
          <div data-test-id="ContactDetails-hirerEmail">{hirer.email}</div>
        </div>
        {hirer.phone && (
          <div className={classes.contactDetailContainer}>
            <PhoneIcon className={classes.icons} />
            <div data-test-id="ContactDetails-hirerPhone">{hirer.phone}</div>
          </div>
        )}
      </div>
    </BoxItem>
  ),
);

const EmployeeContactDetails = withStyles(styles)(
  ({
    offer: { email, first_name: firstName, last_name: lastName, phone } = {},
    union = {},
    classes,
  } = {}) => (
    <BoxItem>
      <ContactDetails>
        <ContactDetails.Title>
          {firstName + ' ' + lastName}
        </ContactDetails.Title>
        <ContactDetails.Data secondary={true}>
          <ContactDetails.DataValue />
        </ContactDetails.Data>
      </ContactDetails>
      <div className={classes.contactGroupContainer}>
        <div className={classes.contactDetailContainer}>
          <EmailIcon className={classes.icons} />
          <div data-test-id="ContactDetails-employeeEmail">{email}</div>
        </div>
        <div className={classes.contactDetailContainer}>
          <PhoneIcon className={classes.icons} />
          <div data-test-id="ContactDetails-employeePhone">{phone}</div>
        </div>
        <ContactDetails.Data>
          <ContactDetails.DataLabel />
          <ContactDetails.DataValue>{union}</ContactDetails.DataValue>
        </ContactDetails.Data>
      </div>
      <ContactDetails topRight={true}>
        <ContactDetails.Data>
          <ContactDetails.DataValue>
            <a href={`${getSSOUrl()}/profile`}>
              <Button className="box-paragraph-item--align-center">
                Update Contact Info
              </Button>
            </a>
          </ContactDetails.DataValue>
        </ContactDetails.Data>
      </ContactDetails>
    </BoxItem>
  ),
);

const OfferContactDetails = ({ hirer, offer, union, classes }) => (
  <div className={classes.root}>
    <Paper className={classNames(classes.crew, classes.paper)}>
      <EmployeeContactDetails offer={offer} union={union} />
    </Paper>
    <Paper className={classNames(classes.hirer, classes.paper)}>
      <HirerContactDetails hirer={hirer} />
    </Paper>
  </div>
);

OfferContactDetails.Hirer = HirerContactDetails;
OfferContactDetails.Employee = EmployeeContactDetails;

export default withStyles(styles)(OfferContactDetails);
