import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query offers($projectId: Int!, $offerIds: [Int!]!) {
    offers(projectId: $projectId, offerIds: $offerIds) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        totalCount
      }
      nodes {
        id
        status
        isTermsOfEmploymentV2
        reimbursementsUpdated
        projectUser {
          id
          profile {
            id
            firstName
            lastName
            email
            phone
          }
        }
        startDate
        sendDate
        endDate
        termsOfHire {
          subDepartmentId
          season
          hireState {
            code
            id
            name
          }
          hireCity {
            code
            id
            name
          }
          workState {
            code
            id
            name
          }
          workCity {
            code
            id
            name
          }
          union {
            code
            description
            isNonUnion
          }
          occupation {
            code
            description
          }
          workSchedule {
            code
            description
          }
          department {
            code
            id
            loanOutEnabled
            masterDepartmentId
            name
            preSelected
          }
          jobDescription
          employmentClassification
          hiringStatus
          currency
        }
        termsOfEmployment {
          studioRatePerHour
          locationRatePerHour
          studioRatePerWeek
          locationRatePerWeek
          studioGuaranteedHours
          idleLocationSixthDayRatePerHour
          idleLocationSeventhDayRatePerHour
          overtimeRatePerHour
          locationGuaranteedHours
          guaranteedHours
          guaranteedHoursFrequency
          ratePerHour
          dailyRate
          weeklyRate
          numberOfDaysPerWorkWeek
          negotiatedContractId
        }
        termsOfEmploymentV2 {
          rate
          ... on TermsOfEmploymentNonUnionHourly {
            guaranteedHours
            guaranteedHours6thDay
            guaranteedHours7thDay
            guaranteedHoursFrequency
            guaranteedHoursLimit
            rate
            rateMinimumWage
            rateOvertime
          }
          ... on TermsOfEmploymentUnionHourly {
            rateDistant
            weeklyRate
            weeklyRateDistant
            guaranteedHours
            guaranteedHoursDistant
            guaranteedHours6thDay
            guaranteedHours7thDay
            guaranteedHours6thDayDistant
            guaranteedHours7thDayDistant
            payIdleDaysDistant
            payGoldAt
            payGoldAtDistant
            payAtScale
            payAtScaleDistant
            idleAtScaleDistant
            goldAtScale
            goldAtScaleDistant
          }
          ... on TermsOfEmploymentNonUnionWeekly {
            rate
            sixthDayPayMultiplication
            seventhDayPayMultiplication
          }
          ... on TermsOfEmploymentCA {
            ratePerHour
            guaranteedHours
            dailyRate
            weeklyRate
            overtimeRatePerHour
            guaranteedHoursFrequency
          }
        }
        allowances {
          boxRentalAllowance {
            amount
            cap
            duration
            per
          }
          computerRentalAllowance {
            amount
            cap
            duration
            per
          }
          carAllowance {
            amount
            cap
            duration
            per
          }
          mobilePhoneAllowance {
            amount
            cap
            duration
            per
          }
          perDiemAllowance {
            amount
            cap
            duration
            per
          }
          housingAllowance {
            amount
            cap
            duration
            per
          }
        }
        accountCodes {
          id
          accountCodeId
          lineItemKey
          value
        }
        documents
        documentFields {
          id
          value
        }
        notes
        additionalNotes {
          notes
          notesUpdatedAt
          notesUpdatedBy
        }
      }
    }
  }
`;

const useOffers = (projectId, offerIds) => {
  const { error, loading, data } = useQuery(QUERY, {
    // NOTE Project.id is listed in the schema as an ID! type, but the project
    // query takes a projectId of type Int! Obviously, this is wrong.
    // NOTE Same goes for Offer.id
    variables: {
      projectId: parseInt(projectId),
      offerIds: offerIds.map(id => parseInt(id)),
    },
    fetchPolicy: 'no-cache',
  });
  return { error, loading, data: data?.offers };
};

export default useOffers;
