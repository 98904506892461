import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// MaterialUI Components
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const styles = {
  root: {
    marginTop: 15,
  },
  label: {
    fontWeight: 600,
    display: 'flex',
  },
  input: {
    width: '80%',
  },
  tooltip: {
    fontSize: '1rem !important',
  },
  assignmentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '5px',
  },
  infoIcon: {
    marginLeft: '5px',
  },
  doneButtonContainer: {
    display: 'inline-block',
  },
  saveButton: {
    color: '#2dcc70',
  },
};

const PanelConfiguration = props => {
  const {
    actionTitle,
    actionSelect,
    assignmentSelect,
    assignmentTitle,
    saveButton,
    cancelButton,
    children,
    classes,
  } = props;
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.label}>
        {actionTitle}
      </Typography>
      <div data-test-id={actionSelect.dataTestId}>
        <Select
          displayEmpty
          className={classes.input}
          onChange={actionSelect.onChange}
          value={actionSelect.value}
          inputProps={actionSelect.inputProps}
        >
          {actionSelect.menuItems}
        </Select>
      </div>
      <Divider className={classes.divider} />
      {children}
      <Typography variant="subtitle1" className={classes.label}>
        {assignmentTitle}
      </Typography>
      <div
        className={classes.assignmentContainer}
        data-test-id={assignmentSelect.dataTestId}
      >
        <Select
          displayEmpty
          className={classes.input}
          onChange={assignmentSelect.onChange}
          value={assignmentSelect.value}
          inputProps={assignmentSelect.inputProps}
        >
          {assignmentSelect.menuItems}
        </Select>
        {!!assignmentSelect.infoTooltip && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={assignmentSelect.infoTooltip}
          >
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        )}
      </div>
      <Divider className={classes.divider} />
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={saveButton.disabled ? saveButton.tooltipMessage || '' : ''}
      >
        <div
          data-test-id={saveButton.tooltipDataTestId}
          className={classes.doneButtonContainer}
        >
          <Button
            className={classes.saveButton}
            disabled={saveButton.disabled}
            onClick={saveButton.onClick}
            data-test-id={saveButton.dataTestId}
          >
            {saveButton.title}
          </Button>
        </div>
      </Tooltip>
      <Button
        onClick={cancelButton.onClick}
        data-test-id={cancelButton.dataTestId}
      >
        {cancelButton.title}
      </Button>
    </div>
  );
};

export default withStyles(styles)(PanelConfiguration);
