import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

// MaterialUI Components
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HighlightIcon from '@material-ui/icons/Highlight';

// Custom Components
import PanelContent from './PanelContent';
import RuleAssignmentConfiguration from './RuleAssignmentConfiguration';
import RuleAssignmentPanel from './RuleAssignmentPanel';

// Utilities
import * as FieldTypes from 'common/utilities/constants/fieldTypes';
import * as RuleConstants from './ruleConstants';
import classNames from 'classnames';
import { getRulePanelProps } from 'admin/components/RoleMapper/documentSetupHelpers/rules';

const styles = {
  attachRule: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 10,
  },
  icon: {
    marginRight: '5px',
  },
  removeButton: {
    color: 'red',
  },
  tooltip: {
    fontSize: '1rem !important',
  },
  button: {
    backgroundColor: '#8b8b8b',
  },
  highlight: {
    color: '#fae047',
  },
};

const filterRulesBasedOnAssignmentType = ({
  assignedRules,
  isTrigger,
  isWorkflow,
  editingId,
}) =>
  assignedRules
    .filter(({ id: ruleId }) => ruleId !== editingId)
    .filter(({ ruleType }) => {
      if (isTrigger) return ruleType === RuleConstants.TRIGGER;
      if (isWorkflow) return ruleType === RuleConstants.WORKFLOW;
      return ruleType !== RuleConstants.TRIGGER;
    });

const buttonText = ({ isTrigger, isWorkflow }) => {
  if (isTrigger) return 'Trigger';
  if (isWorkflow) return 'Workflow';
  return 'Share';
};

const RuleAssignment = props => {
  const {
    rules,
    triggerActions,
    field,
    fields,
    toggleTriggerRuleHighlight,
    triggerRuleIdToHighlight,
    removeRuleFromField,
    responseBehaviorOptions,
    classes,
    assignmentType,
    roles = [],
    badgeTypes = [],
    disabled: upstreamDisabled,
    assignRule: upstreamAssignRule,
    assignBadge: upstreamAssignBadge,
  } = props;
  const { fieldType, textType } = field;

  // State

  const [editingId, setEditId] = useState(null);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [isAdding, setIsAdding] = useState(false);

  const openConfiguration = () => {
    setIsAdding(true);
    setEditId(null);
  };
  const closeConfiguration = () => {
    setEditId(null);
    setIsAdding(false);
  };
  const assignRule = ruleAssignment => {
    closeConfiguration();
    upstreamAssignRule(ruleAssignment);
  };
  const assignBadge = badgeAssignment => {
    closeConfiguration();
    upstreamAssignBadge(badgeAssignment);
  };
  const cancelAssignment = () => {
    setEditId(null);
    setIsAdding(false);
  };

  const isTrigger = assignmentType === RuleConstants.TRIGGER;
  const isWorkflow = assignmentType === RuleConstants.WORKFLOW;
  const isTextField = fieldType === FieldTypes.TXT;
  const isRadioField = fieldType === FieldTypes.RDO;
  const isCheckbox = fieldType === FieldTypes.CHK;
  const isConfiguringRule = !!editingId || isAdding;

  const selectedRule = rules.find(({ id }) => id === editingId) || {};
  const assignedRules = rules.filter(
    ({ triggerFieldId }) => triggerFieldId === field.id,
  );
  const filteredTriggerActions = (() => {
    const excludeRules = [];

    if (isRadioField) {
      return triggerActions.filter(
        action =>
          action.name === RuleConstants.VALUE ||
          action.name === RuleConstants.SYSTEM,
      );
    }
    if (isCheckbox) {
      return triggerActions
        .filter(
          action =>
            action.name === RuleConstants.PRESENT ||
            action.name === RuleConstants.SYSTEM,
        )
        .map(action =>
          action.name === RuleConstants.PRESENT
            ? { ...action, description: 'Is Checked' }
            : action,
        );
    }
    if (isTextField && textType !== 'number') {
      excludeRules.push(RuleConstants.GT_VALUE);
      excludeRules.push(RuleConstants.LT_VALUE);
    }

    excludeRules.push(RuleConstants.CHECKED);

    return triggerActions.filter(action => !excludeRules.includes(action.name));
  })();

  const getPanelProps = rule => {
    const { fieldGroups = [], ruleType } = rule;
    const {
      associatedConditionalRules = [],
      ruleDescription,
      title,
    } = getRulePanelProps({
      rule,
      field,
      assignmentType,
      triggerActions,
      responseBehaviorOptions,
      conditionalRules: rules.filter(
        ({ ruleType }) => ruleType === RuleConstants.GROUP,
      ),
    });
    const isOpen = expandedPanels.includes(rule.id);
    const isRuleHighlighted = triggerRuleIdToHighlight === rule.id;
    const onClick = isOpen
      ? () => setExpandedPanels(expandedPanels.filter(id => id !== rule.id))
      : () => setExpandedPanels([...expandedPanels, rule.id]);

    const buttons = [
      {
        key: 'edit',
        title: 'Edit',
        onClick: () => {
          setIsAdding(true);
          setEditId(rule.id);
        },
        dataTestId: `RuleAssignmentPanel-editButton-${rule.id}`,
      },
      {
        key: 'remove',
        title: 'Remove',
        onClick: () => removeRuleFromField(rule.id),
        dataTestId: `RuleAssignmentPanel-removeButton-${rule.id}`,
        className: classes.removeButton,
      },
    ];
    const collapseContent = (
      <PanelContent
        fieldGroups={fieldGroups}
        fields={fields}
        associatedConditionalRules={associatedConditionalRules}
      />
    );
    const secondaryActionIcon = (
      <Tooltip
        title={`Click to ${
          isRuleHighlighted ? 'unhighlight' : 'highlight'
        } fields that are mapped to the email.`}
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          onClick={e => {
            e.stopPropagation();
            toggleTriggerRuleHighlight(rule.id);
          }}
          className={classNames({ [classes.button]: isRuleHighlighted })}
          data-test-id={`RuleAssignmentPanel-highlightButton-${rule.id}`}
        >
          <HighlightIcon
            className={classNames({
              [classes.highlight]: isRuleHighlighted,
            })}
          />
        </IconButton>
      </Tooltip>
    );

    const dataTestIds = {
      root: `RuleAssignmentPanel-root-${rule.id}`,
      title: `RuleAssignmentPanel-title-${rule.id}`,
      description: `RuleAssignmentPanel-description-${rule.id}`,
      listIcon: `RuleAssignmentPanel-listIcon-${rule.id}`,
    };

    return {
      dataTestIds,
      title,
      isOpen,
      onClick,
      buttons,
      collapseContent,
      description: ruleDescription,
      key: rule.id,
      secondaryAction:
        ruleType !== RuleConstants.BADGE &&
        ruleType !== RuleConstants.WORKFLOW &&
        secondaryActionIcon,
    };
  };

  return (
    <div>
      {filterRulesBasedOnAssignmentType({
        assignedRules,
        isTrigger,
        isWorkflow,
        editingId,
      }).map(rule => (
        <RuleAssignmentPanel {...getPanelProps(rule)} />
      ))}
      {isConfiguringRule && !upstreamDisabled ? (
        <RuleAssignmentConfiguration
          triggerActions={filteredTriggerActions}
          rules={rules.map(rule =>
            rule.id === selectedRule.id ? selectedRule : rule,
          )}
          isTrigger={isTrigger}
          badgeTypes={badgeTypes}
          editingId={editingId}
          cancelAssignment={cancelAssignment}
          assignRule={assignRule}
          assignBadge={assignBadge}
          rule={selectedRule}
          field={field}
          fields={fields}
          assignmentType={assignmentType}
          roles={roles}
        />
      ) : (
        <Button
          disabled={upstreamDisabled}
          className={classes.attachRule}
          onClick={() => openConfiguration()}
          data-test-id={`RuleAssignment-attach${buttonText({
            isWorkflow,
            isTrigger,
          })}Rule`}
        >
          <AddIcon className={classes.icon} />{' '}
          {`Attach ${isTrigger || isWorkflow ? 'Rule' : 'Notification'}`}
        </Button>
      )}
    </div>
  );
};

export default withStyles(styles)(RuleAssignment);
