import gql from 'graphql-tag';

export default gql`
  query departments($projectId: Int!, $union: String, $occupation: String) {
    departments(
      projectId: $projectId
      unionCode: $union
      occupationCode: $occupation
    ) {
      id
      code
      loanOutEnabled
      masterDepartmentId
      preSelected
      name
      loanOutOccupations {
        code
        description
      }
    }
  }
`;
