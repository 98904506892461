import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'class-names';

const styles = {
  root: {
    padding: '20px',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  wrapper: { margin: '0 auto', maxWidth: '1325px' },
  fullWidth: { maxWidth: 'none' },
  extraPadding: {
    paddingBottom: 100,
  },
  compact: {
    maxWidth: 400,
    padding: '100px 0',
  },
};

class LayoutContent extends Component {
  render() {
    const {
      classes,
      full,
      children,
      className,
      extraPadding,
      compact,
      wrapperProps = {},
      'data-test-id': dataTestId,
    } = this.props;

    // Allow classname for wrapper to be passed in
    // But remove it from the props that should be passed down
    const { className: wrapperClassName } = wrapperProps;
    const wrapperPropsCopy = { ...wrapperProps };
    delete wrapperPropsCopy.className;

    const childrenWrapperClass = classNames(
      classes.wrapper,
      {
        [classes.fullWidth]: full,
        [classes.extraPadding]: extraPadding,
        [classes.compact]: compact,
      },
      wrapperClassName,
    );
    return (
      <div
        className={classNames(className, classes.root)}
        ref={node => (this.node = node)}
        data-test-id={dataTestId}
      >
        <div className={childrenWrapperClass} {...wrapperPropsCopy}>
          {children}
        </div>
      </div>
    );
  }

  scrollTop() {
    this.node.scrollTop = 0;
  }
}

export default withStyles(styles)(LayoutContent);
