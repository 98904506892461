import React from 'react';
import { withStyles, TextField } from '@material-ui/core';

const styles = {
  textField: {
    width: 500,
  },
};
const AddressSearch = props => {
  const {
    onSearchInputChange,
    renderAddressSuggestions,
    classes,
    className,
  } = props;
  // Text Field id and name are intended to be useless information to help
  // disable chrome auto fill which currently can not be turned off by any prop
  // I have tried all of them and every suggestion on stack overflow
  return (
    <div className={className}>
      <span className="text">
        Please find your address using our address search below. If you need
        help, contact support: plus.support@castandcrew.com or 818.860.7770.
      </span>
      <TextField
        id="red"
        name="red"
        label="Search ..."
        autoComplete={'false'}
        className={classes.textField}
        onChange={e => onSearchInputChange(e.target.value)}
      />
      <div className="field">{renderAddressSuggestions()}</div>
    </div>
  );
};

export default withStyles(styles)(AddressSearch);
