import { useEffect } from 'react';
import useMe from './useMe';

let isInitialized = false;

const usePendo = () => {
  const { data: { authProviderId } = {} } = useMe();
  useEffect(() => {
    if (isInitialized || !authProviderId) return;
    isInitialized = true;
    window.pendo.initialize({ visitor: { id: authProviderId }, account: {} });
  }, [authProviderId]);
};

export default usePendo;
