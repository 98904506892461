import React from 'react';
import DialogController from 'common/containers/DialogController';
import SessionManagementController from 'common/containers/SessionManagementController';
import CncUiProvider from '@castandcrew/cnc-ui/CncUiProvider';
import ProjectHamburgerMenu from 'mobile/components/Menu/ProjectHamburgerMenu';
import { Route, Switch } from 'react-router-dom';
import ProjectsList from 'mobile/components/Projects/ProjectsList';
import OfferList from 'mobile/components/Projects/OfferList';
import Approval from 'mobile/components/Projects/Approval';
import Home from 'common/containers/Home';
import { MOBILE_ROUTES } from 'mobile/constants';

const { PROJECTS, OFFERS, APPROVAL } = MOBILE_ROUTES;

const MobileRoutes = () => {
  return (
    <CncUiProvider>
      <Switch>
        <Route exact path={PROJECTS}>
          <ProjectHamburgerMenu />
          <ProjectsList />
        </Route>
        <Route exact path={OFFERS}>
          <ProjectHamburgerMenu />
          <OfferList />
        </Route>
        <Route exact path={APPROVAL}>
          <ProjectHamburgerMenu />
          <Approval />
        </Route>
        <Route component={Home} />
      </Switch>
      <DialogController />
      <SessionManagementController />
    </CncUiProvider>
  );
};

export default MobileRoutes;
