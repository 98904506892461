export const MAX_MOBILE_WIDTH = 768;

export const ALLOWANCES = [
  { key: 'box_rental', title: 'Box Rental' },
  { key: 'computer_rental', title: 'Computer Rental' },
  { key: 'car_allowance', title: 'Car Allowance' },
  { key: 'mobile_phone', title: 'Mobile Phone' },
  { key: 'per_diem_allowance', title: 'Per Diem Allowance' },
  { key: 'housing_allowance', title: 'Housing Allowance' },
];

export const MYCASTANDCREW_URL = 'https://my.castandcrew.com/';
export const PLUSTRAINING_URL = ' https://support.castandcrew.com/';
export const CUSTOMER_SUPPORT_URL =
  'https://www.castandcrew.com/support/customer-product-support/';
export const UNIVERSITYLINK =
  'https://live.castandcrew.com/cast-and-crew-university';

export const MOBILE_ROUTES = {
  PROJECTS: '/projects',
  OFFERS: '/projects/:projectId/offers',
  APPROVAL: '/projects/:projectId/approvals',
};

export const REMEMBER_SIGNATURE_COOKIE = 'remember_signature';
export const VIEWED_TUTORIAL_OVERLAY = 'viewed_overlay_tutorial';
