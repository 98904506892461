import classNames from 'classnames';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import isEmpty from 'common/utilities/isEmpty';
import uniqueId from 'common/utilities/uniqueId';
import ruleConfig from './ruleConfig';
import ruleTypes from './ruleTypes';
import ProjectTemplateAutoAssignmentRuleSelect from './ProjectTemplateAutoAssignmentRuleSelect';

const ruleKeys = Object.keys(ruleConfig);

const styles = theme => ({
  selectRow: {
    width: '100%',
    display: 'grid',
    gridTemplateAreas: `
      "ruleType department  .......... ........"
      "ruleType union       occupation schedule"
      "ruleType hireState   workState  workCity"
    `,
    gridTemplateColumns: '24% 24% 24% 24%',
    gridTemplateRows: 'auto auto auto',
    gridColumnGap: '8px',
    columnGap: '8px',
  },
  multiSelectRow: {
    gridRowGap: '7px',
    rowGap: '7px',
  },
  ruleType: {
    gridArea: 'ruleType',
  },
  department: {
    gridArea: 'department',
  },
  union: {
    gridArea: 'union',
  },
  occupation: {
    gridArea: 'occupation',
  },
  schedule: {
    gridArea: 'schedule',
  },
  hireState: {
    gridArea: 'hireState',
  },
  workState: {
    gridArea: 'workState',
  },
  workCity: {
    gridArea: 'workCity',
  },
});

const ProjectTemplateAutoAssignmentRuleEditor = props => {
  const { classes, index, loadingStatus, options, rule, updateRule } = props;

  const getPreviousKey = key => ruleKeys[ruleKeys.indexOf(key) - 1] || false;

  const getNextKey = key => ruleKeys[ruleKeys.indexOf(key) + 1] || false;

  // Generates an object where they keys are all the rule types
  // And the values are selectors that are unrelated to that rule type
  const getObjectOfUnrelatedSelectors = () => {
    const selectors = {};
    ruleTypes.forEach(({ code }) => {
      selectors[code] = ruleKeys.filter(
        k =>
          !ruleConfig[k].section.includes(code) &&
          ruleConfig[k].key !== 'ruleType',
      );
    });
    return selectors;
  };

  const checkFieldClear = ({ rule, key }) => {
    const { code: ruleType } = rule.ruleType;
    const updatedRule = { ...rule };
    let fieldsToClear = [];
    const selectors = getObjectOfUnrelatedSelectors();
    // Clear any selectors that are not relevant to the rule type
    fieldsToClear = [...fieldsToClear, ...selectors[ruleType]];

    // Clear any selectors that are dependent on the selector that got changed
    const nextField = getNextKey(key);
    const nextFieldIndex = ruleKeys.indexOf(key) + 1;
    // Check to make sure that the next field exists, and is within the bounds of the array
    if (nextField) {
      // Iterate through and find all the dependent fields
      for (let i = nextFieldIndex; i < ruleKeys.length; i += 1) {
        const { requirePrevious } = ruleConfig[ruleKeys[i]];
        if (requirePrevious) {
          fieldsToClear.push(ruleKeys[i]);
        } else {
          break;
        }
      }
    }
    // Clear all dependent fields
    fieldsToClear.forEach(field => {
      updatedRule[field] = {};
    });
    return updatedRule;
  };

  const handleUpdate = (key, value) => {
    let updatedRule = { ...rule };
    updatedRule[key] = value;
    updatedRule = checkFieldClear({ rule: updatedRule, key, value });
    const nextKey = getNextKey(key);
    const nextSelectorConfig = {
      ...ruleConfig[nextKey],
      key: nextKey,
    };
    updateRule({ rule: updatedRule, value, index, nextSelectorConfig });
  };

  const optionsToRender = (() => {
    const { code: ruleType } = rule.ruleType;
    const allKeys = Object.keys(ruleConfig);
    // Base rule config with ruleType
    let keys = [allKeys[0]];
    if (ruleType === 'all' || ruleType === undefined) {
      return keys;
    }
    if (ruleType === 'location') {
      keys = [
        ...keys,
        ...allKeys.filter(key => ruleConfig[key].section.includes('location')),
      ];
      return keys;
    }
    if (ruleType === 'department') {
      keys = [
        ...keys,
        ...allKeys.filter(key =>
          ruleConfig[key].section.includes('department'),
        ),
      ];
      return keys;
    }
    if (ruleType === 'union') {
      keys = [
        ...keys,
        ...allKeys.filter(key => ruleConfig[key].section.includes('union')),
      ];
      return keys;
    }
    // Fall through is combo rule
    keys = [
      ...keys,
      ...allKeys.filter(key => ruleConfig[key].section.includes('combo')),
    ];
    return keys;
  })();

  /**
   * getFilteredOptions takes the key that is sent from the Rule Select Map
   * Then looks at the total options and returns the appropriate options for this select
   * @param  {string} key Options: [union, occupation, schedule, hireState, workState, workCity]
   *                      The key from the rule select of the current drop down
   * @return {[array]}    Array of objects that represent the available options for that select
   */
  const getFilteredOptions = (key, value) => {
    const { union, occupation, schedule, workCity, workState } = ruleConfig;
    const { key: unionKey } = union;
    const { key: occupationKey } = occupation;
    const { key: scheduleKey } = schedule;
    const { key: workStateKey } = workState;
    const { key: workCityKey } = workCity;

    // This is a list of options that are filtered based on previous selections
    // And so they get stored as objects, with the key being the previous selection
    const objKeys = [occupationKey, scheduleKey, workCityKey];

    // Figure out if the key is one of the groups that are filtered based on previous option
    // If not then just return the whole option array
    if (objKeys.indexOf(key) === -1) {
      return options[key];
    }

    // If they are filtered based on previous selection
    // Then return the array which is stored in options with a key of the value of the previous selection
    // according to the following array
    const selectionOrder = [
      unionKey,
      occupationKey,
      scheduleKey,
      workStateKey,
      workCityKey,
    ];
    const previousSelectionIndex = selectionOrder.indexOf(key) - 1; // Index of previous selector
    const previousSelectionKey = selectionOrder[previousSelectionIndex]; // Key of previous selector
    const previousSelectionValue = rule[previousSelectionKey] || {}; // Value of Previous Selector in the rule
    const filteredOptions = options[key][previousSelectionValue.code] || []; // Get the current options scoped to the previous selection
    if (filteredOptions.length === 0) {
      return [value];
    }
    return filteredOptions;
  };

  const inputsList = optionsToRender.map(key => {
    const isDepartment = key === 'department';
    const { requirePrevious, label } = ruleConfig[key];
    const previousKey = getPreviousKey(key);
    const disabled = requirePrevious && isEmpty(rule[previousKey] || {});
    const filteredOptions = getFilteredOptions(key, rule[key]);
    const options =
      filteredOptions.length === 0 ? [rule[key]] : filteredOptions;
    return (
      <ProjectTemplateAutoAssignmentRuleSelect
        key={`select-rule-${key}-${uniqueId()}`}
        label={label}
        disabled={disabled}
        value={
          (rule[key] && isDepartment ? rule[key].id : rule[key].code) || ''
        }
        index={index}
        type={key}
        options={options}
        handleUpdate={handleUpdate}
        isLoading={loadingStatus[key] === 'loading'}
        className={classes[key]}
      />
    );
  });

  const selectRowClass = classNames(classes.selectRow, {
    [classes.multiSelectRow]: rule.ruleType.code === 'combo',
  });

  return <div className={selectRowClass}>{inputsList}</div>;
};

export default withStyles(styles)(ProjectTemplateAutoAssignmentRuleEditor);
