import React from 'react';
import withApi from 'common/hoc/withApi';
import { Grid, Typography } from '@material-ui/core';
import {
  Check as CheckMarkIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from '@material-ui/icons';
import PreLoader from 'mobile/components/PreLoader';
import { makeStyles } from '@material-ui/core/styles';

const documentName = {
  color: '#0091ff',
  fontSize: 14,
  fontWeight: 400,
  marginLeft: '20px',
};
const iconSize = {
  width: 24,
  height: 24,
};
const offerDocumentsStyles = makeStyles(theme => ({
  documentName: {
    ...documentName,
  },
  grayedDocumentName: {
    ...documentName,
    color: '#999999',
  },
  checkMarkIcon: {
    color: '#2ecc71',
    ...iconSize,
  },
  grayedCheckMark: {
    color: '#999999',
    ...iconSize,
  },
  eyeIcon: {
    color: '#aaaaaa',
    ...iconSize,
  },
  noDocs: {
    fontSize: '18px',
    color: '#999999',
    textAlign: 'center',
    paddingTop: '90px',
  },
}));

const OfferDocuments = props => {
  const classes = offerDocumentsStyles();
  const { offerDocuments, handleDocumentPreview } = props;
  const { data = {} } = offerDocuments;
  const { items: documents = [] } = data;

  const isLoading = offerDocuments.status === 'loading';

  if (isLoading) {
    return <PreLoader />;
  }

  const viewableSupportingDocuments = documents
    .map(doc => doc.supportingDocuments)
    .flat()
    .filter(doc => doc.isViewable);

  const getMainDocumentId = supportingDocumentId => {
    const results = documents.find(obj =>
      obj.supportingDocuments.find(doc => doc.id === supportingDocumentId),
    );
    const { id: mainDocumentId } = results || {};
    return mainDocumentId;
  };

  if (viewableSupportingDocuments.length === 0) {
    return <Typography className={classes.noDocs}>No Documents</Typography>;
  }

  return viewableSupportingDocuments.map(
    ({ id: supportingDocumentId, name }) => (
      <Grid container>
        <Grid item xs={1}>
          <Typography>
            <CheckMarkIcon
              fontSize="large"
              classes={{
                root: classes.checkMarkIcon,
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="h6"
            className={classes.documentName}
            onClick={() =>
              handleDocumentPreview(
                getMainDocumentId(supportingDocumentId),
                supportingDocumentId,
              )
            }
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <RemoveRedEyeIcon
            fontSize="large"
            classes={{ root: classes.eyeIcon }}
            data-test-id={`"DocumentList-Offerdocuments-eyeIcon-"${supportingDocumentId}`}
            onClick={() =>
              handleDocumentPreview(
                getMainDocumentId(supportingDocumentId),
                supportingDocumentId,
              )
            }
          />
        </Grid>
      </Grid>
    ),
  );
};
OfferDocuments.queries = {
  offerDocuments: {
    info(params) {
      const { hasPackets, offerId } = params;
      if (!hasPackets) {
        return {
          id: `/v2/offers/${offerId}/documents`,
        };
      }
      return {
        id: `empty`,
      };
    },
  },
};

export default withApi(OfferDocuments);
