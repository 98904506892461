import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { IconButton, TextField as MuiTextField } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { Clear as ClearIcon } from '@material-ui/icons';
import SignatureIcon from '../../icons/SignatureIcon';
import classNames from 'classnames';
import * as palette from '../../shared/oldDocumentSignerUI/palette';
import sharedCss from '../../shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';

const styles = theme => ({
  ...sharedCss.fields,
  root: {
    position: 'absolute',
  },
  '@font-face': {
    fontFamily: 'Caflischscriptpro',
    src: [
      "url('https://payrollplus-assets.castandcrew.com/caflischscriptpro-regular.otf')",
    ],
  },
  textField: {
    width: '100%',
    height: '100%',
  },
  signatureButton: {
    position: 'absolute',
    right: 5,
    bottom: 10,
    minWidth: 70,
    width: 70,
    padding: 5,
    background: '#ff0000',
    borderRadius: 3,
    opacity: 0.7,
    '&:hover': {
      background: '#dd0000',
    },
  },
  editButton: {
    position: 'absolute',
    padding: 0,
    right: 5,
    bottom: 5,
  },
  input: {
    fontFamily: 'Caflischscriptpro',
    padding: 0,
    color: palette.darkGrey,
    paddingLeft: '5px',
    paddingRight: '5px',
  },
});

const SignatureFieldBase = ({ isValidated }) => {
  const Component = props => {
    const {
      name,
      id,
      x,
      y,
      height,
      width,
      handleChange,
      classes,
      value,
      handleSignatureButtonClick,
      clearSignatureButtonClick,
      setFieldRef,
      _derived: { isRequired, isDisabled },
    } = props;

    const fieldStyle = {
      top: y,
      left: x,
      height,
      width,
      fontSize: height * 0.8,
    };

    const showButton = (!isValidated || !value) && !isDisabled;
    const inputClass = classNames(classes.input, fieldStatusClass(props));

    return (
      <div
        className={classes.root}
        style={fieldStyle}
        data-test-id={`SignatureFieldBase-root-${id}`}
      >
        <MuiTextField
          id={name}
          inputRef={e => setFieldRef(e, y)}
          placeholder={isRequired ? 'Required' : ''}
          className={classes.textField}
          value={value === null || value === undefined ? '' : value}
          multiline={false}
          type="text"
          onChange={e => handleChange(e.target.value)}
          margin="none"
          disabled
          InputProps={{
            disableUnderline: false,
            classes: { input: inputClass },
            style: { fontSize: height * 0.8 },
          }}
        />
        {showButton && (
          <IconButton
            className={value ? classes.editButton : classes.signatureButton}
            onClick={() => handleSignatureButtonClick(id)}
            data-test-id={`SignatureFieldBase-button-${id}`}
          >
            {value ? <EditIcon /> : <SignatureIcon />}
          </IconButton>
        )}
        {!showButton && (
          <IconButton
            className={classes.editButton}
            onClick={() => clearSignatureButtonClick(id)}
            data-test-id={`SignatureClearFieldBase-button-${id}`}
          >
            <ClearIcon />
          </IconButton>
        )}
      </div>
    );
  };

  return withStyles(styles)(Component);
};

export default SignatureFieldBase;
