import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import Table from 'common/oldJavascripts/components/Base/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {
  Folder as FolderClosedIcon,
  FolderOpen as FolderOpenIcon,
} from '@material-ui/icons';
import { compose } from 'redux';
import { Queries } from 'common/apollo';
import { graphql } from 'react-apollo';
import moment from 'moment';
import Transition from 'common/components/Transition';
import { CATEGORIES } from 'common/utilities/constants/myFileCategories';
import ProgressSpinner from 'common/components/ProgressSpinner';
import { MYFILES_NEXT_BTN, MYFILES_CANCEL_BTN } from './Styles';

const styles = theme => ({
  secondaryButton: {
    ...MYFILES_NEXT_BTN,
  },
  dialogContainer: {
    minHeight: '45vh !important',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    backgroundColor: 'white',
    border: 'none',
    padding: 0,
    marginBottom: theme.spacing.unit * 6,
    boxShadow: 'inset -1px 1px -1px rgba(0, 0, 0, 0.8)',
    '& > *': {
      width: '100%',
    },
  },
  closeIcon: {
    top: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    position: 'absolute',
    cursor: 'pointer',
  },
  cancel: {
    ...MYFILES_CANCEL_BTN,
  },
  disabledBtn: {
    ...MYFILES_NEXT_BTN,
    opacity: '0.4',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '30px',
    paddingBottom: '40px',
  },
  mainSummary: {
    border: '1px solid lightgrey',
    borderRadius: 2,
    margin: '1px 20px',
  },
  panelDetails: {
    borderLeft: '2px solid #3ba',
    backgroundColor: '#fbfbfb',
    margin: '0 20px',
  },
  categoryContent: {
    width: '100%',
    paddingRight: '0!important',
    display: 'flex',
    justifyContent: 'space-between',
  },
  categoryTitle: {
    marginLeft: '20px',
    marginBottom: '10px',
    bottom: '6px',
    position: 'relative',
  },
  dot: {
    height: '10px',
    width: '10px',
    backgroundColor: '#3ba',
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: 'right',
    marginBottom: '4px',
  },
  checkboxContainer: {
    padding: 0,
    width: '80px',
  },
  checkbox: {},
  mainPanel: {
    boxShadow: 'none',
    position: 'static',
  },
  selectedItem: {
    textAlign: 'right',
    paddingRight: '20px',
    position: 'relative',
    bottom: '30px',
    color: 'grey',
  },
  instructions: {
    fontSize: '14px',
    color: '#616161',
    fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
  },
  title: {
    fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
    color: '#616161',
    fontSize: 18,
    marginBottom: '14px',
  },
});
const MyFilesModal = props => {
  const initialState = {
    expandedPanels: [],
    displayDot: [],
    selectedItems: [],
    selectedCount: 0,
  };
  const [state, setState] = useState(initialState);
  const { expandedPanels, displayDot, selectedItems, selectedCount } = state;
  const {
    isOpen,
    handleClose,
    classes,
    uploadHandler,
    data: { myFiles = [], loading = false },
  } = props;

  const handleFileSelected = (ev, checked, item) => {
    setState(({ selectedCount, selectedItems, ...rest }) => {
      const list = checked
        ? [...selectedItems, item]
        : selectedItems.filter(obj => obj.category !== item.category);
      const hasFiles = list.filter(obj => obj.category === item.category);
      return {
        ...rest,
        selectedCount: checked ? selectedCount + 1 : selectedCount - 1,
        selectedItems: list,
        displayDot: { [item.category]: hasFiles.length > 0 },
      };
    });
  };

  const uploadFilesFromMyFiles = () => {
    selectedItems.forEach(item => {
      if (item.id) uploadHandler({ my_file_id: item.id });
    });
    handleClose(selectedItems);
  };

  const displayFiles = key => {
    const items = myFiles.filter(file => file.category === key);

    if (items.length === 0) {
      return <div> You have not uploaded any files. </div>;
    }

    return (
      <Table>
        <Table.Header>
          <TableCell className={classes.checkboxContainer} />
          <TableCell>FileName</TableCell>
          <TableCell>Date uploaded</TableCell>
        </Table.Header>
        {items.map(item => (
          <TableRow>
            <TableCell>
              <Checkbox
                className={classes.checkbox}
                onChange={(ev, checked) =>
                  handleFileSelected(ev, checked, item)
                }
              />
            </TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>
              <Typography>
                {moment(item.createdAt).format('MM-DD-YYYY')}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    );
  };

  const handlePanelChange = (ev, expanded, key) => {
    setState(state => ({ ...state, expandedPanels: { [key]: expanded } }));
  };

  const isNextBtnDisabled = selectedCount === 0;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
      classes={{ paper: classes.dialogContainer }}
    >
      <DialogTitle id="responsive-dialog-title" disableTypography>
        <Typography variant="h6" className={classes.title}>
          My Files
        </Typography>
        <Typography variant="caption" className={classes.instructions}>
          Here are the available files in your My Files section in MyCast&Crew
        </Typography>
        <div className={classes.closeIcon}>
          <CloseIcon onClick={handleClose} />
        </div>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {loading && <ProgressSpinner size={24} top={100} left={200} />}
        {!loading && (
          <div>
            {CATEGORIES.map(category => (
              <ExpansionPanel
                onChange={(ev, expanded) =>
                  handlePanelChange(ev, expanded, category.key)
                }
                classes={{ rounded: classes.mainPanel }}
              >
                <ExpansionPanelSummary classes={{ root: classes.mainSummary }}>
                  <div className={classes.categoryContent}>
                    <span>
                      {expandedPanels[category.key] ? (
                        <FolderOpenIcon />
                      ) : (
                        <FolderClosedIcon />
                      )}
                      <span className={classes.categoryTitle}>
                        {category.displayName}
                      </span>
                    </span>
                    {displayDot[category.key] && (
                      <span className={classes.dot} />
                    )}
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.panelDetails }}>
                  {displayFiles(category.key)}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </div>
        )}
      </DialogContent>
      <div>
        <div className={classes.modalFooter}>
          <Button
            size="medium"
            variant="text"
            className={classes.cancel}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            size="medium"
            variant="contained"
            className={
              isNextBtnDisabled ? classes.disabledBtn : classes.secondaryButton
            }
            data-test-id="MyFiles-select-button"
            disabled={isNextBtnDisabled}
            onClick={uploadFilesFromMyFiles}
          >
            Next
          </Button>
        </div>
        {selectedCount > 0 && (
          <p className={classes.selectedItem}>
            {selectedCount} selected document
          </p>
        )}
      </div>
    </Dialog>
  );
};

const withMyFilesQuery = graphql(Queries.myFiles);

export default compose(withStyles(styles), withMyFilesQuery)(MyFilesModal);
