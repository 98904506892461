import React from 'react';
import STARTPLUS_LOGO from 'common/images/startplus-logo-white.png';
import Layout from 'common/oldJavascripts/components/Base/Layout';
import LayoutBanner from 'common/oldJavascripts/components/Base/Layout/LayoutBanner';
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import MainHeader from '../../Base/MainHeader';
import MainNav from './Nav/MainNav';
import DropdownSession from './Nav/DropdownSession';

const Base = ({ hasSidebar, children }) => (
  <Layout hasSidebar={hasSidebar}>
    <LayoutBanner />
    <LayoutHeader>
      <MainHeader>
        <MainHeader.Logo alt="Cast & Crew logo" src={STARTPLUS_LOGO} />
        <MainNav />
        <MainHeader.Nav secondary={true}>
          <DropdownSession />
        </MainHeader.Nav>
      </MainHeader>
    </LayoutHeader>
    {children}
  </Layout>
);

export default Base;
