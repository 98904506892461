import React from 'react';

// Components
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import AccountCodeTableCell from './AccountCodeTableCell';

// Utilities
import classNames from 'class-names';
import formatCurrency from 'studio/components/OfferPage/helpers/formatCurrency';
import isUnionWeeklyScheduleCode from 'common/utilities/isUnionWeeklySchedule';

const AccountCodeTableBody = props => {
  const {
    formData = {},
    formErrors = {},
    onChange,
    classes = {},
    accountCodeConfigurations = [],
    accountCodes = [],
    enforceMask,
    canEditAccountCodes,
  } = props || {};

  const {
    rate,
    rateDistant,
    // Terms Of Employment
    // Union Table
    studioRatePerWeek,
    studioRatePerHour,
    locationRatePerWeek,
    locationRatePerHour,
    idleLocationSixthDayRatePerHour,
    idleLocationSeventhDayRatePerHour,
    // Exempt Table
    dailyRate,
    weeklyRate,
    // Default Table
    ratePerHour,
    // Allowances
    boxRentalAllowance = {},
    carAllowance = {},
    computerRentalAllowance = {},
    housingAllowance = {},
    mobilePhoneAllowance = {},
    perDiemAllowance = {},
    workScheduleCode,
  } = formData || {};
  const isWeeklySchedule =
    formData?.__typename === 'TermsOfEmploymentUnionHourly' &&
    isUnionWeeklyScheduleCode(workScheduleCode);

  const rowKeys = [
    {
      lineItemKey: 'studio',
      label: 'Studio',
      display: () =>
        studioRatePerWeek > 0 ||
        studioRatePerHour > 0 ||
        (rate > 0 && !weeklyRate && !dailyRate),
      amount: studioRatePerWeek || studioRatePerHour || rate,
    },
    {
      lineItemKey: 'studio',
      label: 'Studio Rate per Hour',
      display: () => rate > 0 && isWeeklySchedule && rateDistant,
      amount: rate,
    },
    {
      lineItemKey: 'location',
      label: isWeeklySchedule ? 'Distant Rate per Hour' : 'Location',
      display: () =>
        locationRatePerWeek > 0 ||
        locationRatePerHour > 0 ||
        idleLocationSixthDayRatePerHour > 0 ||
        idleLocationSeventhDayRatePerHour > 0 ||
        rateDistant > 0,
      amount:
        locationRatePerWeek ||
        locationRatePerHour ||
        idleLocationSixthDayRatePerHour ||
        idleLocationSeventhDayRatePerHour ||
        rateDistant,
    },
    {
      lineItemKey: 'hourly',
      label: 'Hourly Rate',
      display: () => ratePerHour > 0,
      amount: ratePerHour,
    },
    {
      lineItemKey: 'daily',
      label: 'Daily Rate',
      display: () => dailyRate > 0,
      amount: dailyRate,
    },
    {
      lineItemKey: 'weekly',
      label: 'Weekly Rate',
      display: () => weeklyRate > 0,
      amount: weeklyRate,
    },
    {
      lineItemKey: 'perDiem',
      label: 'Per Diem',
      display: () => perDiemAllowance.amount,
      amount: perDiemAllowance.amount,
    },
    {
      lineItemKey: 'box',
      label: 'Box Rental',
      display: () => boxRentalAllowance.amount,
      amount: boxRentalAllowance.amount,
    },
    {
      lineItemKey: 'car',
      label: 'Car Allowance',
      display: () => carAllowance.amount,
      amount: carAllowance.amount,
    },
    {
      lineItemKey: 'computer',
      label: 'Computer Rental',
      display: () => computerRentalAllowance.amount,
      amount: computerRentalAllowance.amount,
    },
    {
      lineItemKey: 'mobile',
      label: 'Mobile Phone Allowance',
      display: () => mobilePhoneAllowance.amount,
      amount: mobilePhoneAllowance.amount,
    },
    {
      lineItemKey: 'housing',
      label: 'Housing Allowance',
      display: () => housingAllowance.amount,
      amount: housingAllowance.amount,
    },
  ];

  const rowKeysToDisplay = rowKeys.filter(({ display }) => display());

  const cellWithTextFieldClass = (key, id) => {
    const hasError = formErrors.find(
      ({ id: accountCodeId, lineItemKey }) =>
        id === accountCodeId && key === lineItemKey,
    );
    return classNames(classes.cellWithTextField, {
      [classes.border]: !hasError,
      [classes.errorCell]: hasError,
    });
  };

  const labelCellClass = key => {
    const hasError = formErrors.find(({ lineItemKey }) => lineItemKey === key);
    return classNames(classes.labelCell, {
      [classes.border]: !hasError,
      [classes.errorCell]: hasError,
    });
  };
  const getValue = (selectedLineItemKey, selectedAccountCodeId) => {
    const { value = '' } =
      accountCodes.find(
        ({ lineItemKey, accountCodeId }) =>
          lineItemKey === selectedLineItemKey &&
          accountCodeId === selectedAccountCodeId,
      ) || {};
    return value;
  };

  const cellClasses = (lineItemKey, cellId) => ({
    ...classes,
    root: cellWithTextFieldClass(lineItemKey, cellId),
  });

  return (
    <TableBody>
      {rowKeysToDisplay.map(({ lineItemKey, label, amount }) => (
        <TableRow key={lineItemKey}>
          <TableCell className={labelCellClass(lineItemKey)}>
            {`${label}: ${!!amount && `$${formatCurrency(amount)}`}`}
            {!!formErrors.find(
              ({ lineItemKey: key }) => lineItemKey === key,
            ) && (
              <Typography
                color="error"
                variant="caption"
                className={classes.errorLabel}
              >
                Please fill out the required fields.
              </Typography>
            )}
          </TableCell>
          {accountCodeConfigurations.map(cell => (
            <AccountCodeTableCell
              {...cell}
              lineItemKey={lineItemKey}
              value={getValue(lineItemKey, cell.id)}
              onChange={({ target: { value } = {} } = {}) =>
                onChange(cell.id, lineItemKey, `${value}`.toUpperCase())
              }
              classes={cellClasses(lineItemKey, cell.id)}
              enforceMask={enforceMask}
              key={`${lineItemKey}-${cell.id}`}
              canEditAccountCodes={canEditAccountCodes}
            />
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default AccountCodeTableBody;
