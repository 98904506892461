import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query me {
    me {
      id
      authProviderId
    }
  }
`;

const useMe = (projectId, offerIds) => {
  const { error, loading, data } = useQuery(QUERY);
  return { error, loading, data: data?.me };
};

export default useMe;
