import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

// HoC
import { compose } from 'redux';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';

// Material UI
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

// Custom Components
import SystemFieldSelect from 'admin/components/RoleMapper/SystemFieldSelect';

// Utilities
import * as FieldTypes from 'common/utilities/constants/fieldTypes';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import {
  filterSystemFields,
  searchSystemFields,
} from 'common/utilities/filterSystemFields';
import { getRuleRoleId } from 'admin/components/RoleMapper/documentSetupHelpers/rules';

const styles = {
  root: {
    gridArea: 'inputs',
    display: 'grid',
    gridTemplateColumns: '49% 49%',
    gridTemplateRows: 'auto',
    gridColumnGap: '2%',
    gap: '5px',
    gridGap: '5px',
    gridTemplateAreas: `
      "responseLabel                setLabel"
      "inputsContainer              inputsContainer"
      "addResponseBehavior          ........"
      "addConditionalRuleContainer  addConditionalRuleContainer"
    `,
  },
  addResponseBehavior: {
    gridArea: 'addResponseBehavior',
  },
  inputsContainer: {
    gridArea: 'inputsContainer',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'auto',
    gridColumnGap: '2%',
    gap: '15px',
    gridGap: '15px',
  },
  inputs: {
    display: 'grid',
    gridTemplateColumns: '49% 43% 4%',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `
      "responseBehavior setValue removeBehavior"
    `,
    gridColumnGap: '2%',
  },
  responseLabel: {
    gridArea: 'responseLabel',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
  },
  setLabel: {
    gridArea: 'setLabel',
    fontWeight: 700,
  },
  responseBehavior: {
    gridArea: 'responseBehavior',
    width: '100%',
  },
  setValue: {
    gridArea: 'setValue',
    width: '100%',
    marginTop: '1px',
  },
  removeBehavior: {
    gridArea: 'removeBehavior',
    padding: 0,
    height: '24px',
    width: '24px',
    alignSelf: 'end',
  },
  icon: {
    marginRight: '5px',
  },
  infoIcon: {
    height: 20,
    width: 20,
    marginLeft: 5,
  },
  addRule: {
    gridArea: 'addRuleLabel',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    fontWeight: 700,
  },
  attachResponseBehavior: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '10px',
    width: '75%',
  },
  tooltip: {
    fontSize: '1rem',
  },
  addConditionalRule: {
    width: '31.5%',
    marginTop: '10px',
  },
  removeRuleIcon: {
    padding: 0,
    marginLeft: '10px',
  },
  addConditionalRuleContainer: {
    gridArea: 'addConditionalRuleContainer',
    display: 'flex',
    flexDirection: 'column',
  },
  conditionalRuleSelect: {
    width: '49%',
    marginTop: '10px',
  },
};

// Return true if rule contains response behavior type
// Return false otherwise
const checkExistingBehavior = (responseBehavior, ...behaviorTypes) =>
  responseBehavior.some(({ responseBehaviorType }) =>
    behaviorTypes.includes(responseBehaviorType),
  );

// Return an array with specific response behavior types filtered out
const filterResponseBehavior = (responseBehavior, ...types) =>
  responseBehavior.filter(({ name }) => !types.includes(name));

const useSystemFieldSearch = (
  initialFieldType,
  initialTextType,
  systemFieldOptions,
  resetSystemField,
  systemFieldResponse,
) => {
  const [systemFields, setSystemFields] = useState(
    filterSystemFields(initialFieldType, initialTextType, systemFieldOptions),
  );
  // Search system field function that first filters according
  // to field and text type
  const searchFields = ({ target: { value } }) => {
    const filteredSystemFields = filterSystemFields(
      initialFieldType,
      initialTextType,
      systemFieldOptions,
    );
    const searchResults = searchSystemFields(value, filteredSystemFields);
    setSystemFields(searchResults);
  };
  useEffect(() => {
    const filteredSystemFields = filterSystemFields(
      initialFieldType,
      initialTextType,
      systemFieldOptions,
    );
    const { value = null } = systemFieldResponse || {};
    // Check if the selected system field is still a valid option with the
    // selected field type and text type and reset the selected system field
    // if it's not valid in the filtered system field array
    if (value) {
      const isExistingSystemField = filteredSystemFields.filter(
        option => option.items.filter(item => item.name === value).length,
      ).length;
      if (!isExistingSystemField) {
        resetSystemField();
      }
    }
    setSystemFields(filteredSystemFields);

    // TODO: Disentangle dependencies
    // eslint-disable-next-line
  }, [initialFieldType, initialTextType]);
  return { systemFields, searchFields };
};

const TriggerRuleConfiguration = props => {
  const {
    rule = {},
    rules = [],
    classes = {},
    fields = [],
    roles = [],
    responseBehaviorOptions = [],
    onResponseBehaviorChange,
    onSetValueChange,
    addResponseBehavior,
    removeResponseBehavior,
    addConditionalRule,
    handleConditionalRuleChange,
    removeConditionalRule,
    systemFieldOptions = [],
    selectSystemField,
    menuAnchor,
    openSystemFieldMenu,
    closeSystemFieldMenu,
    removeSystemFieldResponse,
    pushSnackbarNotification,
  } = props;
  const {
    responseBehavior = [],
    conditionalRuleIds = [],
    id,
    fieldGroups = [],
  } = rule || {};
  const hasSetValueBehaviorType = checkExistingBehavior(
    responseBehavior,
    'set',
  );
  const hasEmptyBehavior = checkExistingBehavior(responseBehavior, '', null);
  const hasEnableBehavior = checkExistingBehavior(responseBehavior, 'enable');
  const hasDisableBehavior = checkExistingBehavior(responseBehavior, 'disable');
  const hasRequireBehavior = checkExistingBehavior(responseBehavior, 'require');
  const hasShowBehavior = checkExistingBehavior(responseBehavior, 'show');
  const hasHideBehavior = checkExistingBehavior(responseBehavior, 'hide');
  const hasSystemBehavior = checkExistingBehavior(responseBehavior, 'system');
  const hasEmptyConditionalRule = (conditionalRuleIds || []).includes('');

  const assignedRoleId = getRuleRoleId(
    rules.map(r => (r.id === id ? rule : r)),
    fields,
    id,
  );
  const assignedRole = roles.find(role => role.id === assignedRoleId) || {};
  // Checks the trigger rule for exactly one field assigned to it
  const fieldCount = fieldGroups.reduce(
    (total, { fieldIds = [] }) => total + fieldIds.length,
    0,
  );
  const hasExactlyOneField = fieldCount === 1;

  // Return the fieldType and textType of a single field in a trigger rule
  // returns null when the trigger rule is mapped to more than one rule
  const fieldTypeData = (() => {
    if (!hasExactlyOneField) return null;
    const fieldId = fieldGroups.map(({ fieldIds }) => fieldIds).flat()[0];
    if (!fieldId) return null;
    const { fieldType = FieldTypes.TXT, textType = 'string' } =
      fields.find(field => field.id === fieldId) || {};
    return { fieldType, textType };
  })();

  // Find the response behavior index of the populate with system
  // field if any
  const systemFieldResponseIndex = responseBehavior.findIndex(
    ({ responseBehaviorType }) => responseBehaviorType === 'system',
  );
  // System field response behavior object
  const systemFieldResponse =
    systemFieldResponseIndex >= 0 && responseBehavior[systemFieldResponseIndex];
  const resetSystemField = () =>
    selectSystemField(systemFieldResponseIndex)({ target: { value: '' } });

  // Use the system field search hook to handle system field state
  const { systemFields, searchFields } = useSystemFieldSearch(
    (fieldTypeData && fieldTypeData.fieldType) || FieldTypes.TXT,
    (fieldTypeData && fieldTypeData.textType) || 'string',
    systemFieldOptions,
    resetSystemField,
    systemFieldResponse,
  );
  const isTextField =
    fieldTypeData && fieldTypeData.fieldType === FieldTypes.TXT;
  const isDropdown =
    fieldTypeData && fieldTypeData.fieldType === FieldTypes.CMB;
  // If there are no fields or more than one field assigned to the rule
  // and the response behavior was previously set to system field
  // then remove the system field response from the response behavior array
  if (
    (!hasExactlyOneField || (!isDropdown && !isTextField)) &&
    systemFieldResponseIndex >= 0
  ) {
    pushSnackbarNotification({
      message: `This rule ${
        !hasExactlyOneField
          ? 'does not have exactly one'
          : 'has a non-text or non-dropdown'
      } field mapped to it, so the system field response behavior has been removed.`,
      variant: SnackbarVariants.WARNING,
      duration: 7000,
    });
    removeSystemFieldResponse();
  }
  // Returns an array of MenuItems with existing response behaviors filtered out to prevent duplicate response behavior selection
  const getResponseBehaviorOptions = (options, behaviorType) =>
    options
      .filter(
        option =>
          responseBehavior.every(
            ({ responseBehaviorType }) => option.name !== responseBehaviorType,
          ) || behaviorType === option.name,
      )
      .map(option => (
        <MenuItem
          value={option.name}
          data-test-id={`TriggerRuleConfiguration-menuItem-${option.name}`}
          key={option.name}
        >
          {option.description}
        </MenuItem>
      ));

  // Update the trigger rules array with the trigger rule that is currently being
  // configured to keep the conditionalRuleIds array current
  const conditionalRules = rules.filter(r => r.ruleType === 'group');
  const triggerRules = rules
    .filter(r => r.ruleType === 'trigger')
    .map(r => (r.id === id ? rule : r));

  // Returns conditional rules assigned to the same role as the trigger rule
  // or all conditional rules if the trigger role does not have a role assigned
  const rulesAssignedToSameRole = assignedRoleId
    ? conditionalRules.filter(rule => {
        const conditionalRoleId = getRuleRoleId(rules, fields, rule.id);
        return conditionalRoleId && conditionalRoleId === assignedRoleId;
      })
    : conditionalRules;
  // Remove the rules that are already linked to the trigger rule
  const rulesNotAlreadyAssigned = rulesAssignedToSameRole.filter(
    rule => !conditionalRuleIds.includes(rule.id),
  );
  const hasRulesToAssign = !!rulesNotAlreadyAssigned.length;
  const addRequiredRuleTooltip = (() => {
    if (!hasRulesToAssign) {
      return `There are no available Required Rules ${
        assignedRole.name
          ? `that are assigned to the ${assignedRole.name} role `
          : ''
      }to link to this Trigger Rule.`;
    }
    if (hasEmptyConditionalRule) {
      return 'Please choose a required rule to assign before adding a new conditional rule.';
    }
    return '';
  })();

  const renderSystemFieldSelect = (externalName, index) => {
    let systemField = {};
    // Find and assign the system field
    // if one already exists
    systemFields.forEach(field => {
      const { items = [] } = field;
      items.forEach(item => {
        if (item.name === externalName) {
          systemField = item;
        }
      });
    });
    const onSelect = selectSystemField(index);
    const multiLevelMenuProps = {
      anchor: menuAnchor,
      closeMenu: () => {
        closeSystemFieldMenu();
        searchFields({ target: { value: '' } });
      },
      filteredOptions: systemFields,
      filterOptions: searchFields,
      selectSystemField: onSelect,
    };
    const menuIcon = systemField.name ? <CloseIcon /> : <SearchIcon />;
    const systemFieldIconAction = !!systemField.name
      ? onSelect
      : openSystemFieldMenu;
    return (
      <SystemFieldSelect
        onClick={systemFieldIconAction}
        menuIcon={menuIcon}
        multiLevelMenuProps={multiLevelMenuProps}
        systemField={systemField}
      />
    );
  };
  const systemFieldResponseTooltip = (() => {
    if (fieldCount === 0)
      return 'This trigger rule does not contain any fields so it cannot be pre-populated with a system field.';
    if (fieldCount > 1)
      return 'This trigger rule contains more than one field so it cannot be pre-populated with a system field.';
    if (!isTextField)
      return 'Only trigger rules containing a text field can be pre-populated with a system field.';
    return '';
  })();

  return (
    <div className={classes.root}>
      <Typography className={classes.responseLabel}>
        Response Behavior
        {!!systemFieldResponseTooltip.length && (
          <Tooltip
            title={systemFieldResponseTooltip}
            classes={{ tooltip: classes.tooltip }}
          >
            <InfoIcon
              className={classes.infoIcon}
              data-test-id={'TriggerRuleConfiguration-responseBehaviorInfoIcon'}
            />
          </Tooltip>
        )}
        :
      </Typography>
      {hasSetValueBehaviorType && (
        <Typography className={classes.setLabel}>Set Value</Typography>
      )}
      <div className={classes.inputsContainer}>
        {responseBehavior.map(
          ({ responseBehaviorType, value: responseValue = null }, index) => {
            /*
              Filter inverse related response behavior types based on the types that are configured on the rule, ie. if the rule has a response behavior of enable, then remove the disable option
              But retain all options if the response behavior value is the one that filters other response behavior menus
            */
            let filteredResponseBehavior = responseBehaviorOptions;
            if (hasEnableBehavior && responseBehaviorType !== 'enable')
              filteredResponseBehavior = filterResponseBehavior(
                filteredResponseBehavior,
                'disable',
                'hide',
              );
            if (hasDisableBehavior && responseBehaviorType !== 'disable')
              filteredResponseBehavior = filterResponseBehavior(
                filteredResponseBehavior,
                'enable',
                'require',
              );
            if (hasRequireBehavior && responseBehaviorType !== 'require')
              filteredResponseBehavior = filterResponseBehavior(
                filteredResponseBehavior,
                'disable',
                'hide',
              );
            if (hasShowBehavior && responseBehaviorType !== 'show')
              filteredResponseBehavior = filterResponseBehavior(
                filteredResponseBehavior,
                'disable',
                'hide',
              );
            if (hasHideBehavior && responseBehaviorType !== 'hide')
              filteredResponseBehavior = filterResponseBehavior(
                filteredResponseBehavior,
                'require',
                'show',
                'set',
                'system',
              );
            if (hasSystemBehavior && responseBehaviorType !== 'system')
              filteredResponseBehavior = filterResponseBehavior(
                filteredResponseBehavior,
                'require',
                'hide',
                'set',
                'enable',
              );
            // If the trigger rule is mapped to more than one field
            // or is mapped to a field that is not a text field
            // remove the system field response behavior option
            if (!hasExactlyOneField || (!isTextField && !isDropdown))
              filteredResponseBehavior = filterResponseBehavior(
                filteredResponseBehavior,
                'system',
              );
            return (
              <div className={classes.inputs} key={`${responseBehaviorType}`}>
                <Select
                  value={responseBehaviorType || ''}
                  onChange={e =>
                    onResponseBehaviorChange(
                      {
                        responseBehaviorType: e.target.value,
                        value: '',
                      },
                      index,
                    )
                  }
                  className={classes.responseBehavior}
                  data-test-id={`TriggerRuleConfiguration-responseSelect-${index}`}
                  inputProps={{
                    'data-test-id': `TriggerRuleConfiguration-responseInput-${index}`,
                  }}
                >
                  {getResponseBehaviorOptions(
                    filteredResponseBehavior,
                    responseBehaviorType,
                  )}
                </Select>
                {responseBehaviorType === 'set' && (
                  <TextField
                    className={classes.setValue}
                    value={responseValue || ''}
                    onChange={e => onSetValueChange(e.target.value, index)}
                    inputProps={{
                      'data-test-id': `TriggerRuleConfiguration-setValueInput-${index}`,
                    }}
                  />
                )}
                {responseBehaviorType === 'system' &&
                  renderSystemFieldSelect(responseValue, index)}
                <IconButton
                  className={classes.removeBehavior}
                  onClick={() => removeResponseBehavior(index)}
                  data-test-id={`TriggerRuleConfiguration-removeBehaviorButton-${index}`}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            );
          },
        )}
      </div>
      <Tooltip
        title={
          hasEmptyBehavior
            ? 'Please configure the existing response behavior before adding a new response behavior.'
            : ''
        }
        classes={{ tooltip: classes.tooltip }}
      >
        <div
          className={classes.addResponseBehavior}
          data-test-id="TriggerRuleConfiguration-addBehaviorContainer"
        >
          <Button
            className={classes.attachResponseBehavior}
            onClick={() => addResponseBehavior()}
            data-test-id="TriggerRuleConfiguration-attachResponseBehavior"
            disabled={hasEmptyBehavior}
          >
            <AddIcon className={classes.icon} /> Add Response Behavior
          </Button>
        </div>
      </Tooltip>
      <div className={classes.addConditionalRuleContainer}>
        <Typography className={classes.addRule}>
          Link Required Rule
          <Tooltip
            title="Required Rules that are added to a trigger rule will have its fields enabled when the trigger rule is triggered."
            classes={{ tooltip: classes.tooltip }}
          >
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
          :
        </Typography>
        {conditionalRuleIds.map((ruleId, index) => {
          // Filter all unassigned conditional rules by checking the rule id against
          // the conditional rule ids attached to each trigger rule and the conditional rule
          // ids of the trigger rule that is currently being configured
          // Returns an array of all eligible conditional rules that can be assigned to the trigger rule
          // Eligible conditional rules are not yet assigned to a trigger rule and are assigned to a role
          let unassignedConditionalRules = conditionalRules
            .filter(
              r =>
                triggerRules.every(
                  ({ conditionalRuleIds: ruleIds = [] }) =>
                    !ruleIds.includes(r.id) &&
                    !conditionalRuleIds.includes(r.id),
                ) || r.id === ruleId,
            )
            .filter(r => !!getRuleRoleId(rules, fields, r.id));

          // Get all unassigned conditional rules that belong to the same role
          // if the trigger rule is already assigned to a role
          // Also filter conditional rules that are not assigned to a role
          if (assignedRoleId) {
            unassignedConditionalRules = unassignedConditionalRules.filter(
              r => {
                const conditionalRoleId = getRuleRoleId(rules, fields, r.id);
                return conditionalRoleId === assignedRoleId;
              },
            );
          }
          const ruleMenuItems = unassignedConditionalRules.map((r, idx) => (
            <MenuItem
              value={r.id}
              key={r.id}
              data-test-id={`TriggerRuleConfiguration-ruleMenuItem-${idx}`}
            >
              {r.name}
            </MenuItem>
          ));
          return (
            <div key={ruleId}>
              <Select
                displayEmpty
                onChange={e =>
                  handleConditionalRuleChange(e.target.value, index)
                }
                className={classes.conditionalRuleSelect}
                value={ruleId}
                key={ruleId}
                data-test-id={`TriggerRuleConfiguration-ruleSelect-${index}`}
                inputProps={{
                  'data-test-id': `TriggerRuleConfiguration-ruleInput-${index}`,
                }}
              >
                {ruleMenuItems}
              </Select>
              <IconButton
                className={classes.removeRuleIcon}
                onClick={() => removeConditionalRule(index)}
                data-test-id={`TriggerRuleConfiguration-removeConditionalRule-${index}`}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          );
        })}
        <Tooltip
          title={addRequiredRuleTooltip}
          classes={{ tooltip: classes.tooltip }}
        >
          <div data-test-id="TriggerRuleConfiguration-addButtonContainer">
            <Button
              onClick={() => addConditionalRule('', conditionalRuleIds.length)}
              data-test-id="TriggerRuleConfiguration-addConditionalRule"
              className={classes.addConditionalRule}
              disabled={hasEmptyConditionalRule || !hasRulesToAssign}
            >
              <AddIcon className={classes.icon} /> Add Required Rule
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default compose(
  withSnackbarNotification,
  withStyles(styles),
)(TriggerRuleConfiguration);
