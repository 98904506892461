import { createMuiTheme } from '@material-ui/core';
import palette from './palette';

export const documentSignerTheme = createMuiTheme({
  palette,
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: '1rem',
      },
    },
    MuiButton: {
      root: {
        fontSize: '1rem',
        textTransform: 'capitalize',
      },
    },
  },
});
