import React from 'react';
import { Button, Tooltip, withStyles } from '@material-ui/core';

const styles = {
  button: {
    backgroundColor: '#3c9dd7',
    color: '#ffffff',
    fontWeight: '700',
  },
  disabledButton: {
    backgroundColor: '#3c9dd7 !important',
    color: '#ffffff !important',
    opacity: '0.25',
    cursor: 'default',
  },
};

const NextButton = props => {
  const { disabled, onClick, classes, title } = props;

  const button = (
    <Button
      classes={{ root: classes.button, disabled: classes.disabledButton }}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
    >
      Next
    </Button>
  );

  if (!disabled) return button;

  return (
    <Tooltip id="tooltip" title={<ol>{title}</ol>}>
      <div>{button}</div>
    </Tooltip>
  );
};

export default withStyles(styles)(NextButton);
