import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
// Component
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  root: {},
});

const CheckboxInput = props => {
  const { onChange, value, classes = {}, fieldName } = props || {};

  return (
    <FormGroup row classes={{ root: classes.root }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={({ target: { checked } = {} } = {}) => onChange(checked)}
            data-test-id={`CheckboxInput-${fieldName}`}
          />
        }
        label={fieldName}
      />
    </FormGroup>
  );
};

export default withStyles(styles)(CheckboxInput);
