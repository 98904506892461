import React from 'react';
import ajax from 'common/utilities/ajax.js';
import AccountCodeModal from '../AccountCodeModal.js';

class OfferFreeFormFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalAccountCodes: [],
      accountCodeTypes: [],
      offerCodes: [],
      userData: {},
      errors: {},
      projectDetails: {},
      isLoading: true,
    };
    this.saveFields = this.saveFields.bind(this);
  }

  componentDidMount() {
    const { projectId, offer = {} } = this.props;

    Promise.all([
      ajax.get('/v3/account-code-types'),
      ajax.get(`/v2/offers/${offer.id}/fields/free-form-fields`),
      ajax.get(`/v2/projects/${projectId}/user`),
      ajax.get(`/v3/projects/${projectId}/fields/free-form-fields`),
      ajax.get(`/projects/${projectId}?with_privileges=true`),
    ])
      .then(values => {
        this.setState({
          accountCodeTypes: values[0],
          offerCodes: values[1].items || [],
          userData: values[2],
          additionalAccountCodes: values[3].items || [],
          projectDetails: values[4],
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ additionalAccountCodes: [], isLoading: false });
      });
  }

  saveFields(fields) {
    const { offerId } = this.props;
    const url = `/v2/offers/${offerId}/additional-account-codes`;

    ajax.put(url, { payload: fields }, { json: true }).then(
      () => {
        this.setState({ isComplete: true });
      },
      errors => {
        this.setState({ errors });
      },
    );
  }

  render() {
    const {
      additionalAccountCodes,
      accountCodeTypes,
      offerCodes,
      userData,
      errors,
      isComplete,
      isLoading,
      projectDetails,
    } = this.state;
    const { offer, project = {}, onClose } = this.props;
    const { data_type: detailSubDataType } = projectDetails;
    const {
      account_code: accountCode,
      department_code: departmentCode,
    } = offer;

    return (
      <AccountCodeModal
        accountCodes={additionalAccountCodes}
        codeTypes={accountCodeTypes}
        isOpen={isComplete !== true}
        onClose={() => onClose(offer.id)}
        handleAccountCodeSubmit={this.saveFields}
        termsOfEmployment={offer.terms_of_employment}
        privileges={userData.privileges}
        isExistingOffer={!!offer.version}
        offerErrors={!!Object.keys(errors).length}
        offerId={offer.id}
        isNonUnion={offer.union_is_non_union}
        offerStatus={offer.status}
        isLoading={isLoading}
        detailSubCode={accountCode || departmentCode || ''}
        tableRow={true}
        offerCodes={offerCodes}
        detailSubDataType={detailSubDataType}
        enforceMask={project.enforce_mask}
        accountMask={project.account_mask}
        country={project.country}
      />
    );
  }
}

export default OfferFreeFormFields;
