const remapSupportingDocs = supportingDocumentTitles => {
  const sd = supportingDocumentTitles[0];
  return {
    name: sd.documentTitle,
    isRequired: sd.required,
    isIncluded: sd.attachToMainDocument,
  };
};

const generateRule = () => {
  return {
    ruleType: {},
    department: {},
    union: {},
    occupation: {},
    schedule: {},
    hireState: {},
    workState: {},
    workCity: {},
    employmentType: {},
  };
};

const removeForeignKeys = rule => {
  const updatedRule = { ...rule };
  const allowedKeys = Object.keys(generateRule());
  const recievedKeys = Object.keys(updatedRule);
  recievedKeys.forEach(k => {
    if (!allowedKeys.includes(k)) {
      delete updatedRule[k];
    }
  });
  return updatedRule;
};

const remapAssignRule = (incomingRule = {}, departments = []) => {
  const { ruleType = {} } = incomingRule;
  const { description = '' } = ruleType;
  let rule = {
    ...generateRule(),
    ...incomingRule,
    ruleType: {
      ...ruleType,
      code: description.toLowerCase().replace(' ', '_'),
    },
  };
  const { department: ruleDepartment = {} } = rule;
  if (
    description.toLowerCase() === 'department' ||
    description.toLowerCase() === 'combo'
  ) {
    const departmentWithDetails =
      departments.find(department => department.id === ruleDepartment.id) || {};
    rule.department = departmentWithDetails;
  }
  const rulesWithForeignKeysRemoved = removeForeignKeys(rule);
  return rulesWithForeignKeysRemoved;
};

const remapTemplate = (template, users, departments) => {
  let { auto_assign_rules = [], supportingDocumentTitles = [] } = template;
  let supportDocument = {};

  // Transform from server-side model of support docs to component-side model.
  if (supportingDocumentTitles.length > 0) {
    supportDocument = remapSupportingDocs(supportingDocumentTitles);
  }

  if (auto_assign_rules.length > 0) {
    auto_assign_rules = auto_assign_rules.map(rule =>
      remapAssignRule(rule, departments),
    );
  }

  const updatedTemplate = {
    ...template,
    supportDocument,
    auto_assign_rules,
    isSaving: false,
    saveSuccess: false,
  };
  if (Object.keys(updatedTemplate.supportDocument).length === 0) {
    delete updatedTemplate.supportDocument;
  }
  return updatedTemplate;
};

const transformProjectTemplates = (items, users, departments) => {
  const templates = items.map(doc => ({ ...doc, id: `${doc.id}` }));
  return templates.map(template => remapTemplate(template, users, departments));
};

export default transformProjectTemplates;
