import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Input,
  Chip,
  Card,
  CardContent,
  Tooltip,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import * as FieldTypes from 'common/utilities/constants/fieldTypes';

import cloneDeep from 'lodash.clonedeep';

const menuOptions = [
  FieldTypes.TXT,
  FieldTypes.RDO,
  FieldTypes.CHK,
  FieldTypes.CMB,
];

const optionTranslation = option => {
  switch (option) {
    case FieldTypes.TXT:
      return 'Text';
    case FieldTypes.RDO:
      return 'Radio Group';
    case FieldTypes.CHK:
      return 'Checkbox';
    case FieldTypes.DATE:
      return 'Date';
    case FieldTypes.NUMBER:
      return 'Number';
    case FieldTypes.CMB:
      return 'Dropdown';
    default:
      return 'Text Field';
  }
};

const styles = () => ({
  inputDisabled: {
    color: 'black',
  },
  bold: {
    fontWeight: 'bold',
  },
  inputOptionsContainer: {
    gridArea: 'inputOptions',
    marginLeft: 10,
    paddingLeft: 10,
    width: '92%',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
  chipContainer: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
    whiteSpace: 'nowrap',
    marginTop: 3,
  },
  cardContainer: {
    marginTop: 10,
  },
  cardContent: {
    padding: '12px 5px 8px 8px !important',
    display: 'grid',
    gridTemplateRows: 'auto 8px auto',
    gridTemplateColumns: '47% 34% 15%',
    gridTemplateAreas: `
      "inputLabel   inputType    buttons"
      "..........   .........    buttons"
      "inputOptions inputOptions buttons"
      `,
    gap: '2%',
    gridGap: '2%',
  },
  buttonContainer: {
    gridArea: 'buttons',
    display: 'grid',
    gridTemplateColumns: '100%',
  },
  inputLabelContainer: {
    paddingLeft: 10,
    borderLeft: '1px solid  rgba(54, 164, 188, 0.80)',
  },
  inputTypeSelect: {
    gridArea: 'inputType',
    marginLeft: '20px !important',
    alignSelf: 'start',
  },
  multiOptionInput: {
    width: '100%',
  },
  inputLabelTextField: {
    width: '100%',
    gridArea: 'inputLabel',
  },
  inputLabelFieldType: {
    width: '100%',
    gridArea: 'inputType',
  },
  actionButton: {
    minWidth: 40,
    height: '100%',
    width: '100%',
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
});

class CustomInputField extends Component {
  state = {
    inputField: this.props.inputField,
    errors: {},
    isEditing: false,
    optionInputValue: '',
  };

  // Add an input option to a radio group or dropdown input
  handleAddInputOption = ({ key }) => {
    const { optionInputValue, inputField } = this.state;
    const { properties: { options = [] } = {} } = inputField;
    const trimmedOptionValue = optionInputValue.trim();
    // Ignore all non 'Enter' key presses or if the option value consists of empty spaces
    if (!(key === 'Enter') || !trimmedOptionValue) return;
    // If the option already exists, clear the input
    if (
      Object.keys(options).find(
        option => option.toLowerCase() === trimmedOptionValue.toLowerCase(),
      )
    ) {
      return this.setState({ optionInputValue: '' });
    }
    const updatedInputField = {
      ...inputField,
      properties: {
        ...inputField.properties,
        options: {
          ...options,
          [trimmedOptionValue]: trimmedOptionValue,
        },
      },
    };
    this.setState(({ errors }) => ({
      inputField: updatedInputField,
      optionInputValue: '',
      errors: {
        ...errors,
        options: false,
      },
    }));
  };

  handleUpdateInputOption = ({ target: { value } }) => {
    this.setState({ optionInputValue: value });
  };

  handleLabelChange = ({ target: { value } }) => {
    this.setState(({ inputField }) => ({
      inputField: { ...inputField, displayName: value },
    }));
  };

  handleDeleteOption = option => {
    this.setState(({ inputField }) => {
      const updatedInputField = cloneDeep(inputField);
      delete updatedInputField.properties.options[option];
      return { inputField: updatedInputField };
    });
  };

  toggleEditInputFieldLabel = () => this.setState({ isEditing: true });

  handleKeyPress = (event, input, inputField) => {
    if (event.key === 'Enter') {
      this.confirmInputFieldLabel(input, inputField);
    }
  };

  confirmInputFieldLabel = (input, inputField) => {
    const { updateInput: upstreamUpdateInput } = this.props;
    // Check for an empty input label
    if (inputField.displayName === '') {
      return this.setState(({ errors }) => ({
        errors: {
          ...errors,
          inputLabel: 'Blank input labels are not allowed.',
        },
      }));
    }
    // Check for empty options when creating a radio or dropdown input
    if (
      (inputField.fieldType === FieldTypes.RDO ||
        inputField.fieldType === FieldTypes.CMB) &&
      !Object.keys(inputField.properties.options).length
    ) {
      return this.setState(({ errors }) => ({
        errors: {
          ...errors,
          options: 'At least one option is required.',
        },
      }));
    }

    this.setState(({ isEditing, errors }) => {
      const inputFieldIndex = input.inputFields.findIndex(
        i => i.name === inputField.name,
      );
      // Check for duplicate input field labels
      if (
        input.inputFields.find(
          (field, idx) =>
            field.displayName.toLowerCase() ===
              inputField.displayName.toLowerCase() &&
            idx !== inputFieldIndex &&
            field.active,
        )
      ) {
        return {
          errors: {
            ...errors,
            inputLabel: 'Duplicate input labels are not allowed.',
          },
        };
      }
      const updatedInputFields = input.inputFields.map(field =>
        field.name === inputField.name ? inputField : field,
      );
      const updatedInput = {
        ...input,
        inputFields: updatedInputFields,
      };
      upstreamUpdateInput(updatedInput);
      return {
        isEditing: false,
        errors: {},
        optionInputValue: '',
      };
    });
  };

  handleLabelFocus = currentLabel => {
    if (currentLabel === 'Default Label') {
      this.handleLabelChange({ target: { value: '' } });
    }
  };

  handleSelectChange = ({ target: { value } }) => {
    this.setState(({ inputField }) => ({
      inputField: {
        ...inputField,
        fieldType: value,
        properties: { ...inputField.properties, options: {} },
      },
    }));
  };

  renderMultiOptionInput = () => {
    const { classes } = this.props;
    const { inputField, isEditing, optionInputValue, errors } = this.state;
    const {
      properties: { options = {} },
    } = inputField;
    const userInputOptions = Object.keys(options).map(option => (
      <Chip
        key={`CustomInputField-inputOption-${option}`}
        data-test-id={`CustomInputField-inputOption-${option}`}
        label={option}
        onDelete={isEditing ? () => this.handleDeleteOption(option) : null}
        clickable={isEditing}
      />
    ));
    return (
      <div className={classes.inputOptionsContainer}>
        <Tooltip
          enterDelay={500}
          title={isEditing ? '' : 'Click edit to change'}
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <TextField
            placeholder="Press 'Enter' to add a new option."
            label="Options"
            onChange={e => this.handleUpdateInputOption(e)}
            value={optionInputValue}
            onKeyPress={e => this.handleAddInputOption(e)}
            className={classes.multiOptionInput}
            disabled={!isEditing}
            error={!!errors.options}
            helperText={errors.options}
            FormHelperTextProps={{
              'data-test-id': `CustomInputField-multiOptionError-${inputField.name}`,
            }}
            inputProps={{
              'data-test-id': `CustomInputField-multiOptionInput-${inputField.name}`,
            }}
          />
        </Tooltip>
        <div className={classes.chipContainer}>{userInputOptions}</div>
      </div>
    );
  };

  render() {
    const { deleteInputField, openDeleteDialog, input, classes } = this.props;
    const { isEditing, errors, inputField } = this.state;
    const inputFieldDeleteMessage = (
      <span>
        Are you sure you want to delete{' '}
        <b className={classes.bold}>{inputField.displayName}</b>?
      </span>
    );
    const inputDeleteCallback = () =>
      deleteInputField(input.id, inputField.name);
    const iconAction = isEditing
      ? () => this.confirmInputFieldLabel(input, inputField)
      : () => this.toggleEditInputFieldLabel();
    const isMultiOptionInput =
      inputField.fieldType === FieldTypes.RDO ||
      inputField.fieldType === FieldTypes.CMB;
    const inputProps = {
      classes: {
        disabled: classes.inputDisabled,
      },
    };
    const inputDisplay = isEditing ? (
      <React.Fragment>
        <InputLabel htmlFor={`CustomInputField-option-${inputField.name}`}>
          Input Type
        </InputLabel>
        <Select
          value={inputField.fieldType}
          input={
            <Input
              onChange={e => this.handleSelectChange(e)}
              inputProps={{
                'data-test-id': `CustomInputField-selectInput-${inputField.name}`,
              }}
            />
          }
          disabled={!isEditing}
          classes={{
            disabled: classes.inputDisabled,
          }}
          data-test-id={`CustomInputField-inputTypeSelect-${inputField.name}`}
        >
          {menuOptions.map(inputOption => (
            <MenuItem
              value={inputOption}
              key={`${inputField.name}-${inputOption}`}
              data-test-id={`CustomInputField-menuItem-${inputOption}`}
            >
              {optionTranslation(inputOption)}
            </MenuItem>
          ))}
        </Select>
      </React.Fragment>
    ) : (
      <Tooltip
        enterDelay={500}
        title="Click edit to change"
        placement="left"
        classes={{ tooltip: classes.tooltip }}
      >
        <TextField
          label="Input Type"
          value={optionTranslation(inputField.fieldType)}
          className={classes.inputLabelFieldType}
          InputProps={{
            ...inputProps,
            inputProps: {
              'data-test-id': `CustomInputField-inputTypeInput-${inputField.name}`,
            },
          }}
          disabled
        />
      </Tooltip>
    );

    return (
      <Card
        key={inputField.name}
        classes={{ root: classes.cardContainer }}
        id={`InputFieldListItem-${inputField.name}`}
        data-test-id={`CustomInputField-root-${inputField.name}`}
      >
        <CardContent classes={{ root: classes.cardContent }}>
          <div className={classes.inputLabelContainer}>
            <Tooltip
              enterDelay={500}
              title={isEditing ? '' : 'Click edit to change'}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <TextField
                label="Input Label"
                onChange={e => this.handleLabelChange(e)}
                value={inputField.displayName}
                onFocus={() => this.handleLabelFocus(inputField.displayName)}
                error={!!errors.inputLabel}
                helperText={errors.inputLabel}
                onKeyPress={e => this.handleKeyPress(e, input, inputField)}
                disabled={!isEditing}
                className={classes.inputLabelTextField}
                InputProps={{
                  ...inputProps,
                  inputProps: {
                    'data-test-id': `CustomInputField-input-${inputField.name}`,
                  },
                }}
                FormHelperTextProps={{
                  'data-test-id': `CustomInputField-inputError-${inputField.name}`,
                }}
              />
            </Tooltip>
          </div>
          <FormControl className={classes.inputTypeSelect}>
            {inputDisplay}
          </FormControl>
          <div className={classes.buttonContainer}>
            <Tooltip
              enterDelay={500}
              title={isEditing ? 'Done' : 'Edit'}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color={isEditing ? 'primary' : 'default'}
                onClick={iconAction}
                data-test-id={`CustomInputField-editFieldGroupButton-${inputField.name}`}
                className={classes.actionButton}
              >
                {isEditing ? <CheckIcon /> : <EditIcon />}
              </Button>
            </Tooltip>
            <Tooltip
              enterDelay={500}
              title="Delete"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                data-test-id={`CustomInputField-deleteFieldGroupButton-${inputField.name}`}
                onClick={() =>
                  openDeleteDialog(inputFieldDeleteMessage, inputDeleteCallback)
                }
                className={classes.actionButton}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </div>
          {isMultiOptionInput && this.renderMultiOptionInput()}
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(CustomInputField);
