import React, { Component } from 'react';
import ajax from 'common/utilities/ajax';
import collection from 'common/oldJavascripts/utils/collection.js';
import T from 'common/oldJavascripts/utils/i18n';
import classNames from 'classnames';
import * as Sentry from '@sentry/browser';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import ContactDetails from 'common/oldJavascripts/components/Base/ContactDetails';
import Dropdown from 'common/oldJavascripts/components/Base/Dropdown';
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import MainHeader from 'common/oldJavascripts/components/Base/MainHeader';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LensIcon from '@material-ui/icons/Lens';
import {
  Print as PrintIcon,
  InsertDriveFile as FileIcon,
} from '@material-ui/icons';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import withApi from 'common/hoc/withApi';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import withDialogNotification from 'common/hoc/withDialogNotification';
import * as Queries from 'common/apollo/queries';
import Feature from 'common/components/Feature';

// Constants
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import history from 'common/constants/config/history';
import PrintNavOptions from 'onboarding/components/PrintNavOptions';

const STEPS = [
  {
    title: T('employee.onboarding.intro.details.title'),
    desc: T('employee.onboarding.intro.details.description'),
    path: /onboarding\/details/,
    introPath: /onboarding\/intro\/details/,
  },
  {
    title: 'Supporting Documents',
    desc: 'Supporting Document Request',
    path: /onboarding\/supporting-documents/,
    introPath: /onboarding\/intro\/supporting-documents/,
  },
  {
    title: T('Start Packet Forms'),
    desc: T('Start Packet Forms'),
    paths: ['documents', 'i9'],
  },
  {
    title: T('employee.onboarding.intro.submit_packet.title'),
    desc: T('employee.onboarding.intro.submit_packet.description'),
    path: /onboarding\/complete/,
    introPath: /onboarding\/intro\/complete/,
  },
];

const styles = {
  navItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  navIcon: {
    marginRight: '7.5px',
    color: 'white',
  },
  firstNavItem: {
    marginRight: '10px',
  },
  dialogConfirmationText: {
    textAlign: 'center',
    fontWeight: 700,
  },
  dialogHeader: {
    textAlign: 'center',
  },
  titleContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(2, max-content)',
    gap: '10px',
    height: '100%',
    overflow: 'hidden',
    width: 'max-content',
  },
  projectTitle: {
    marginLeft: '0',
    paddingLeft: '20px',
    fontSize: '1.3em',
    lineHeight: '3',
    color: '#ffffff',
    fontWeight: '700',
    marginTop: '6px',
  },
  projectStatus: {
    alignSelf: 'center',
    fontSize: '1.3em',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  projectStatusLive: {
    color: '#ffffff',
  },
  projectStatusTest: {
    color: '#ffffff57',
  },
  statusContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gridTemplateColumns: '20px 1fr',
  },
  statusIcon: {
    height: '12px',
    width: '12px',
  },
  liveIcon: {
    color: palette.green,
  },
  testIcon: {
    color: '#3434348f',
  },
};
class HeaderOfferOnboarding extends Component {
  static mutators = {
    offer: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: '/v2/offers/' + params.offerId,
        };
      },
    },
  };

  static queries = {
    hirer: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId + '/hirer',
        };
      },
    },
    documents: {
      info: function(_, related) {
        const { offerId } = related['/router/params'];
        return {
          id: `/v2/offers/${offerId}/documents-details`,
        };
      },
    },
  };

  getPrintableForm = () => {
    const {
      offer: offerQuery = {},
      pushNotification,
      popNotification,
    } = this.props;
    const { data: { id: offerId } = {} } = offerQuery || {};
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(`/v2/offers/${offerId}/paper-document`)
      .then(res => {
        Sentry.addBreadcrumb(res);
        const { path } = res || {};
        if (path) {
          popNotification();
          pushNotification({
            message: 'Your download has started.',
            variant: SnackbarVariants.SUCCESS,
          });
          window.location.href = path;
        } else {
          throw new Error(
            'No path received from api when downloading offer documents',
          );
        }
      })
      .catch(err => {
        const { resource: { errors = {} } = {} } = err;
        const { url: [apiErrorMessage] = [] } = errors || {};
        const message = apiErrorMessage
          ? apiErrorMessage
          : 'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.';
        if (!apiErrorMessage) Sentry.captureException(err);
        pushNotification({
          message,
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  dismissContinueOnPaperDialog = () => {
    const { popDialogNotification } = this.props;
    popDialogNotification();
  };

  continueOnboardingOnPaper = () => {
    const {
      routerParams: { offerId } = {},
      pushNotification,
      popNotification,
    } = this.props;
    pushNotification({
      message:
        'Hold on a moment while we opt you out of digital onboarding and prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .post(`/v2/offers/${offerId}/opt-out`)
      .then(({ path = '' }) => {
        if (path) {
          popNotification();
          pushNotification({
            message: 'Your download has started.',
            variant: SnackbarVariants.SUCCESS,
          });
          window.location.href = path;
          history.push('/onboarding/offers');
        } else {
          throw new Error(
            'No path received from api when downloading offer documents',
          );
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        pushNotification({
          message:
            'Please contact your production office or support (plus.support@castandcrew.com or (818) 848-0999) if you need assistance.',
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  oldContinueOnPaper = () => {
    this.dismissContinueOnPaperDialog();
    this.continueOnboardingOnPaper();
  };

  render() {
    const {
      routerPath: path,
      hirer: hirerQuery = {},
      offer: offerQuery = {},
      data: { project = {} } = {},
      documents: documentsQuery = {},
      pushDialogNotification,
      classes = {},
    } = this.props;
    const { data: hirer = {} } = hirerQuery || {};
    const { data: offer = {} } = offerQuery || {};
    const { status: offerStatus = '' } = offer;
    const { items: documentsData = [] } = documentsQuery.data;
    const supportingDocumentTitlesList = [];

    documentsData.forEach(doc => {
      const { supportingDocumentTitles = [] } = doc;
      if (supportingDocumentTitles.length > 0) {
        supportingDocumentTitlesList.push(supportingDocumentTitles);
      }
    });

    const hasSupportingDocuments = supportingDocumentTitlesList.length > 0;
    const onboardingSteps = hasSupportingDocuments
      ? STEPS
      : STEPS.filter(key => key.title !== 'Supporting Documents');

    let contactHiringManager = null;

    const { status, name } = project || {};

    const statusClass = classNames(classes.projectStatus, {
      [classes.projectStatusLive]: status === 'live',
      [classes.projectStatusTest]: status === 'test',
    });

    const iconClass = classNames(classes.statusIcon, {
      [classes.liveIcon]: status === 'live',
      [classes.testIcon]: status === 'test',
    });
    let currentStep = 0;
    // Find active step
    collection.each(onboardingSteps, function(index, item) {
      const { path: itemPath = null, paths = [], introPath } = item;
      if (paths.length > 0) {
        const str = path.split('/');
        const query = str[str.length - 1];
        if (paths.indexOf(query) !== -1) {
          currentStep = index + 1;
          return false;
        }
      }
      if (
        (itemPath && path.match(itemPath)) ||
        (introPath && path.match(introPath))
      ) {
        currentStep = index + 1;
        return false;
      }
    });

    if (hirerQuery.status !== 'loading') {
      contactHiringManager = (
        <Dropdown
          title={T('dashboard.header.contact_hiring_manager')}
          header
          hideAvatar
          secondary
        >
          <Dropdown.Item>
            <ContactDetails.Prefab
              detailName={hirer.first_name + ' ' + hirer.last_name}
              detailTitle={hirer.title}
              detailEmail={hirer.email}
              detailPhone={hirer.phone}
            />
          </Dropdown.Item>
        </Dropdown>
      );
    }

    const dialogProps = {
      title: (
        <Typography variant="h4" className={classes.dialogHeader}>
          Heads up!
        </Typography>
      ),
      message: [
        <React.Fragment>
          <Typography variant="subtitle1">
            By clicking "Continue on paper" you are opting not to complete your
            offer using digital on-board and you will now be required to
            complete and sign your offer on paper and submit your documents in
            person.
          </Typography>
          <Typography
            className={classes.dialogConfirmationText}
            variant="subtitle1"
          >
            Are you sure you want to continue?
            <br />
            (You can print the documents to complete and sign on paper)
          </Typography>
        </React.Fragment>,
      ],
      actions: [
        {
          text: 'No, take me back',
          fn: this.dismissContinueOnPaperDialog,
          color: 'secondary',
        },
        {
          text: 'PRINT DOCUMENTS and CONTINUE ON PAPER',
          fn: this.oldContinueOnPaper,
          color: 'primary',
        },
      ],
    };

    const OldPrintOptionsNavItems = () => {
      if (offerStatus === 'sent' || offerStatus.includes('onboarding')) {
        return (
          <React.Fragment>
            <Tooltip
              title="By selecting print you have not opted out of digital on-boarding. These documents are only for review."
              data-test-id={'HeaderOfferOnboarding-printDocumentsTooltip'}
            >
              <div
                className={classNames(classes.navItem, classes.firstNavItem)}
                onClick={this.getPrintableForm}
                data-test-id="HeaderOfferOnboarding-printDocuments"
              >
                <PrintIcon className={classes.navIcon} />
              </div>
            </Tooltip>
            <Tooltip
              title="Continue on Paper – by selecting this option you will opt out of the digital onboarding and will be required to complete and sign your offer on paper and submit your documents in person."
              data-test-id={'HeaderOfferOnboarding-optOutTooltip'}
            >
              <div
                className={classes.navItem}
                onClick={() => pushDialogNotification(dialogProps)}
                data-test-id="HeaderOfferOnboarding-optOut"
              >
                <FileIcon className={classes.navIcon} />
              </div>
            </Tooltip>
          </React.Fragment>
        );
      } else {
        return null;
      }
    };
    const statusLoaded = name && status;

    return (
      <LayoutHeader>
        <MainHeader secondary={true}>
          {statusLoaded && (
            <div className={classes.titleContainer}>
              <Typography className={classes.projectTitle}>{name}</Typography>{' '}
              <div className={classes.statusContainer}>
                <LensIcon className={iconClass} />
                <Typography className={statusClass}>{status}</Typography>
              </div>
            </div>
          )}
          <MainHeader.Progress
            currentStep={currentStep}
            steps={onboardingSteps}
          />
          <MainHeader.Nav secondary={true} flex={true}>
            <Feature
              name="mobileOnboarding"
              fallback={<OldPrintOptionsNavItems />}
            >
              <PrintNavOptions
                continueOnPaper={this.continueOnboardingOnPaper}
                getPrintableForm={this.getPrintableForm}
              />
            </Feature>
            {contactHiringManager}
          </MainHeader.Nav>
        </MainHeader>
      </LayoutHeader>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

const projectStatusConfig = {
  skip: ({ offer: { data: { project_id: projectId } = {} } = {} } = {}) =>
    !projectId,
  options: ({ offer: { data: { project_id: projectId } = {} } = {} } = {}) => ({
    variables: { id: parseInt(projectId, 10) },
  }),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withApi,
  graphql(Queries.GetProjectNameStatus, projectStatusConfig),
  withDialogNotification,
)(HeaderOfferOnboarding);
