import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import { ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Layers as LayersIcon,
  Edit as EditIcon,
  FindReplace as FindReplaceIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const MAP_FIELDS_TEXT = {
  processed: ['Map Fields', ''],
  processing: ['Processing...', ''],
  unsupported: [
    'Error: Unsupported File',
    'This file is an unsupported pdf type.',
  ],
  secure_file: [
    'Error: Secure File',
    "Start+ can't upload PDFs that are password protected.  Please unprotect the file and reload.",
  ],
  signature_field_file: [
    'Error: Digital Signature Fields',
    "Start+ can't upload PDFs that have digital signature fields.  Please check the file and reload.",
  ],
  failed: ['Error: PDF Failure', 'Start+ failed to process this document.'],
};

const styles = theme => ({
  menuItemError: {
    '& *': {
      color: theme.palette.error.main,
    },
  },
  textError: {
    color: theme.palette.error.main,
  },
});

class RowMenu extends Component {
  state = {
    isHandlingActiveFlag: false,
    activeFlagError: false,
  };

  componentWillReceiveProps(nextProps) {
    // Clear error flag when opening menu
    const { anchorEl: nextAnchor } = nextProps;
    const { anchorEl: currAnchor } = this.props;
    const isOpening = !currAnchor && nextAnchor;
    if (isOpening) this.setState({ activeFlagError: false });
  }

  _handleActiveFlag = id => {
    const { handleActiveFlag } = this.props;
    this.setState({ isHandlingActiveFlag: true, activeFlagError: false });
    Promise.resolve(handleActiveFlag(id))
      .catch(() => this.setState({ activeFlagError: true }))
      .finally(() => this.setState({ isHandlingActiveFlag: false }));
  };

  render() {
    const {
      classes,
      anchorEl,
      handleOpenDocViewer,
      handleEdit,
      handleCloseMenu,
      handleReplace,
      contextualMenuDoc,
    } = this.props;
    const { isHandlingActiveFlag, activeFlagError } = this.state;
    const { id, status = 'failed', visible } = contextualMenuDoc || {};
    const [mapFieldsText, mapFieldsTooltipText] =
      MAP_FIELDS_TEXT[status.toLowerCase()] || MAP_FIELDS_TEXT.processed;
    let activeFlagLabel;
    if (activeFlagError) {
      activeFlagLabel = 'Error';
    } else if (isHandlingActiveFlag) {
      activeFlagLabel = 'Processing...';
    } else {
      activeFlagLabel = visible ? 'Hide' : 'Make Visible';
    }

    return (
      <Menu
        id={`table-row-menu-${id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        data-test-id={`table-row-menu-${id}`}
      >
        <MenuItem
          onClick={() => handleOpenDocViewer(id)}
          data-test-id={`RowMenu-View-${id}`}
        >
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText primary="View" />
        </MenuItem>

        <MenuItem
          onClick={() => handleEdit(id)}
          data-test-id={`RowMenu-Edit-${id}`}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>

        <MenuItem
          onClick={() => handleReplace(id)}
          data-test-id={`RowMenu-Replace-${id}`}
        >
          <ListItemIcon>
            <FindReplaceIcon />
          </ListItemIcon>
          <ListItemText primary="Replace" />
        </MenuItem>

        <Tooltip id="tooltip-fab" title={mapFieldsTooltipText}>
          <div>
            <MenuItem
              component={props => (
                <Link {...props} to={`/library/documents/${id}/mapping`} />
              )}
              data-test-id={`menu-item-role-mapping-${id}`}
              disabled={status.toLowerCase() !== 'processed'}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classnames({
                    [classes.textError]: !!mapFieldsTooltipText,
                  }),
                }}
                primary={mapFieldsText}
              />
            </MenuItem>
          </div>
        </Tooltip>

        <MenuItem
          onClick={() => this._handleActiveFlag(id)}
          data-test-id={`RowMenu-toggleVisibility-${id}`}
          disabled={isHandlingActiveFlag}
          className={classnames({ [classes.menuItemError]: activeFlagError })}
        >
          {visible && (
            <ListItemIcon>
              <VisibilityOffIcon />
            </ListItemIcon>
          )}
          {!visible && (
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={activeFlagLabel} />
        </MenuItem>
      </Menu>
    );
  }
}

export default withStyles(styles)(RowMenu);
