import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    position: 'relative',
    '&:before': {
      right: '100%',
      marginRight: '15px',
      position: 'absolute',
      content: '""',
      top: '50%',
      width: '9999px',
      height: '1px',
      background: '#e6e7ea',
    },
    '&:after': {
      left: '100%',
      marginLeft: '15px',
      position: 'absolute',
      content: '""',
      top: '50%',
      width: '9999px',
      height: '1px',
      background: '#e6e7ea',
    },
  },
};

const Label = props => {
  const { classes, children } = props;
  return <span className={classes.root}>{children}</span>;
};

export default withStyles(styles)(Label);
