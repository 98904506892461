import React from 'react';
import { Link } from 'react-router-dom';
import domClasses from '../../utils/dom/classes.js';
var DocumentTable = null;

DocumentTable = class extends React.Component {
  render() {
    return (
      <div className="documentTable">
        <div className="documentTableBody">{this.props.children}</div>
      </div>
    );
  }
};

DocumentTable.Row = class extends React.Component {
  render() {
    var classes = null;

    classes = domClasses.set({
      documentTableRow: true,
      'documentTableRow--header': this.props.header,
      'documentTableRow--clickable': !!this.props.to,
      'documentTableRow--border': !this.props.header,
    });

    if (this.props.to) {
      return (
        <Link to={this.props.to} className={classes} params={this.props.params}>
          {this.props.children}
        </Link>
      );
    } else {
      return <div className={classes}>{this.props.children}</div>;
    }
  }
};

DocumentTable.Cell = class extends React.Component {
  render() {
    var classes = null;

    classes = domClasses.set({
      documentTableCell: true,
      'documentTableCell--header': this.props.header,
      'documentTableCell--rowHeader': this.props.rowHeader,
    });

    return (
      <div className={classes} data-test-id={this.props['data-test-id']}>
        {this.props.children}
      </div>
    );
  }
};

export default DocumentTable;
