import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Button from '../../Base/Button';
import Field from '../../Base/Field';
import Link from '../../Base/Link';
import Preloader from '../../Shared/Preloader';

import React from 'react';
import Relay from '../../../utils/react/Relay.js';
import Table from '../../Base/Table';
import Text from '../../Base/Text';
import ToolTipBox from '../../Shared/ToolTipBox';
import {
  Create as EditIcon,
  CheckCircle as VerifiedIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

// Utilities
import { AsYouType } from 'libphonenumber-js';
import phoneNumberInputOnChange from 'common/oldJavascripts/utils/phoneNumberInputOnChange';

const PHONE_MAX_LENGTH = 15;

const styles = {
  phoneCell: {
    overflow: 'unset',
  },
  phoneNumber: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtonCell: {
    width: '220px !important',
  },
};

const PhoneTooltip = ({ phone = '' }) => {
  const phoneNumber = phone || '';
  return (
    <div className="tooltip--small">
      Phone number must be less than
      <Text
        alert={phoneNumber.replace(/\D/g, '').length > PHONE_MAX_LENGTH}
        bold={true}
      >
        {' '}
        15
      </Text>{' '}
      characters long
    </div>
  );
};

export default withStyles(styles)(
  Relay.createClass({
    getInitialState() {
      return {
        editMode: false,
        numberChanged: false,
      };
    },

    render() {
      const { classes = {} } = this.props;
      const phone = this.props.mutator;

      if (phone.status === 'loading') {
        return (
          <Table framed={true}>
            <Preloader />
          </Table>
        );
      }

      return (
        <BoxItem>
          <Table framed={true}>
            <Table.Row>
              <Table.Cell>
                <Text bold={true}>Phone number</Text>
              </Table.Cell>
              <Table.Cell align="center" className={classes.phoneCell}>
                {this._renderNumberCell()}
              </Table.Cell>
              <Table.Cell align="center" className={classes.actionButtonCell}>
                {this._renderActionButton()}
              </Table.Cell>
            </Table.Row>
          </Table>
          {this._renderDisclaimer()}
        </BoxItem>
      );
    },

    _renderActionButton() {
      const phoneData = this.props.mutator.data;

      if (phoneData.is_phone_verified) {
        return (
          <Text data-test-id="PhoneVerificationRow-verified">
            <VerifiedIcon />
            Verified
          </Text>
        );
      }

      if (this.state.numberChanged) {
        return (
          <React.Fragment>
            <Button tertiary onClick={this._leaveEditMode()}>
              Cancel update
            </Button>
            <Button
              accept={true}
              disabled={!this._isVerifiable()}
              tertiary={true}
              onClick={this._saveNumber()}
              data-test-id="PhoneVerificationRow-updateAndVerify"
            >
              Update and verify
            </Button>
          </React.Fragment>
        );
      }

      return (
        <Button
          accept={true}
          disabled={!this._isVerifiable()}
          tertiary={true}
          onClick={this.props.verifyNumber}
          data-test-id="PhoneVerificationRow-verify"
        >
          Send verification code
        </Button>
      );
    },

    _renderDisclaimer() {
      return (
        <BoxItem nopadding={true}>
          <Text small={true} transform="uppercase">
            PLEASE NOTE:
          </Text>{' '}
          <Text small={true}>
            If you update this number for notifications it will also be changed
            on your profile. Standard rates and charges may apply.
          </Text>
          <Text comment={true} paragraph={true} small={true}>
            * All times are in PST timezone
          </Text>
        </BoxItem>
      );
    },

    _renderNumberCell() {
      const { editMode } = this.state;
      const { mutator, classes = {} } = this.props;
      const {
        dataBeforeEdits: { phone: preExistingPhone },
        data: { phone },
      } = mutator;

      if (!editMode && preExistingPhone) {
        return (
          <Link
            plain={true}
            onClick={this._enterEditMode()}
            className={classes.phoneNumber}
            data-test-id="PhoneVerificationRow-edit"
          >
            <EditIcon />
            <Text>{this.formatPhoneNumber(preExistingPhone)}</Text>
          </Link>
        );
      }

      return (
        <ToolTipBox align="left" tooltipNode={<PhoneTooltip phone={phone} />}>
          <span data-test-id="PhoneVerificationRow-input">
            <Field
              autoFocus={true}
              onChange={(number, target) => this._updateNumber(number, target)}
              type="phone"
              value={this.formatPhoneNumber(phone)}
            />
          </span>
        </ToolTipBox>
      );
    },

    _isVerifiable() {
      const phone = this.props.mutator.data.phone;
      return phone && phone.replace(/\D/g, '').length <= PHONE_MAX_LENGTH;
    },

    _updateNumber(number, target) {
      const { mutator: phoneMutator, updateVerifiedStatus } = this.props;
      this.setState({ numberChanged: true });
      updateVerifiedStatus(false);
      return phoneNumberInputOnChange(number, target, 'phone', phoneMutator);
    },

    formatPhoneNumber(number) {
      // we do not start formatting the number until it's greater than 4 digits
      // this prevents a bug that prevents the user from deleting their area code
      if (!/(.?\d){4,}/.test(number)) return number;
      const asYouType = new AsYouType('US');
      // return the formatted phone number
      return asYouType.input(number);
    },

    _saveNumber() {
      const phone = this.props.mutator;
      return () => {
        phone.save('edit', {}, () => {
          this.props.verifyNumber();
        });
      };
    },

    _enterEditMode() {
      return () => {
        this.setState({ editMode: true });
      };
    },

    _leaveEditMode() {
      return () => {
        this.setState({ editMode: false });
      };
    },
  }),
);
