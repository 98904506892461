import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query contracts(
    $projectId: Int!
    $startDate: String!
    $workState: String!
    $hireState: String!
    $workCity: String!
    $hireCity: String!
    $currency: String
    $union: String!
    $occupation: String!
    $workSchedule: String!
    $season: Int
  ) {
    contracts(
      projectId: $projectId
      startDate: $startDate
      workState: $workState
      hireState: $hireState
      workCity: $workCity
      hireCity: $hireCity
      currency: $currency
      union: $union
      occupation: $occupation
      workSchedule: $workSchedule
      season: $season
    ) {
      type
      contracts {
        code
        description
        negotiableContract
        signatory {
          id
          description
        }
      }
    }
  }
`;

const useContracts = variables => {
  const {
    projectId,
    startDate,
    workState,
    hireState,
    workCity,
    hireCity,
    currency,
    union,
    occupation,
    workSchedule,
  } = variables;
  const skip =
    !projectId ||
    !startDate ||
    !workState ||
    !hireState ||
    !workCity ||
    !hireCity ||
    !currency ||
    !union ||
    !occupation ||
    !workSchedule;
  const { loading, data } = useQuery(QUERY, {
    skip,
    variables,
    notifyOnNetworkStatusChange: true,
  });
  return { loading, data: !skip && data && data.contracts };
};

export default useContracts;
