import React, { useState } from 'react';
import { green, red } from '@material-ui/core/colors';
import Loader from 'common/components/Loader';
import Box from 'common/oldJavascripts/components/Base/Box';
import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Table from 'common/oldJavascripts/components/Base/Table';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Link from 'common/oldJavascripts/components/Base/Link';

// HoC
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

// MuiComponents
import { Paper, Typography, TextField, FormControl } from '@material-ui/core';
import { Button } from '@material-ui/core';

// GQL
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

// Components
import validateEmail from 'common/utilities/validateEmail';

const PROJECTS_QUERY = gql`
  query projectsByEmployee($employeeEmail: String!) {
    projectsByEmployee(employeeEmail: $employeeEmail) {
      nodes {
        id
        name
        productionCompany {
          name
        }
        users {
          profile {
            email
          }
          active
        }
      }
    }
  }
`;

const styles = {
  formControl: {
    minWidth: 240,
    marginLeft: 22,
  },
  search: {
    paddingRight: 16,
    width: 200,
  },
  title: {
    padding: 18,
  },
  userAccessHeader: {
    display: 'flex',
    width: '35%',
    justifyContent: 'space-around',
    paddingBottom: 16,
  },
  submitButton: {
    gridArea: 'submitButton',
    height: '30px',
  },
  submitSuccess: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  submitFailure: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  submitNormalState: {
    color: 'white',
    backgroundColor: '#3c9dd7',
    '&:hover': {
      backgroundColor: '#59acdd',
    },
  },
  submitDisabled: {
    backgroundColor: '#59acdd52 !important',
    color: 'white !important',
  },
  submitProgress: {
    color: green[500],
    gridArea: 'submitButton',
  },
};

const UserProjectList = props => {
  const { classes } = props;
  const [email, setEmail] = useState('');
  const [loadProjects, { error, loading, data }] = useLazyQuery(
    PROJECTS_QUERY,
    { fetchPolicy: 'no-cache' },
  );
  const despace = s => s.replace(/ /g, '');
  const canSubmit = validateEmail(email);
  const hasProjects = data?.projectsByEmployee?.nodes?.length > 0;

  const projectTableRow = project => {
    return (
      <Table.Row>
        <Table.Cell>{project.productionCompany.name}</Table.Cell>
        <Table.Cell>
          <Link to={`projects/${project.id}/users`}>{project.name}</Link>
        </Table.Cell>
        <Table.Cell>{isActive(project.users, email)}</Table.Cell>
      </Table.Row>
    );
  };

  function isActive(users, email) {
    const selectedUser = users.find(user => user?.profile?.email === email);
    const selectedUserActiveStatus = selectedUser?.active ? 'True' : 'False';
    return selectedUserActiveStatus;
  }

  return (
    <Paper elevation={0}>
      <Typography variant="h4" className={classes.title}>
        Projects By User
      </Typography>
      <div className={classes.userAccessHeader}>
        <div>
          <FormControl className={classes.formControl}>
            <TextField
              label="User Email"
              margin="normal"
              required
              value={email}
              onChange={ev => setEmail(despace(ev.target.value))}
            />
          </FormControl>
        </div>
        <Button
          className={classes.submitButton}
          onClick={() => loadProjects({ variables: { employeeEmail: email } })}
          variant="contained"
          disabled={!canSubmit}
          classes={{
            disabled: classes.submitDisabled,
          }}
        >
          View Projects
        </Button>
      </div>
      <Box>
        <BoxBody>
          {loading && (
            <BoxItem>
              <Loader />
            </BoxItem>
          )}
          {!loading && error && (
            <BoxItem>
              <Blankslate>Failed loading projects: {error.message}</Blankslate>
            </BoxItem>
          )}
          {!loading && !error && !hasProjects && (
            <BoxItem>
              <Blankslate>No Projects Found</Blankslate>
            </BoxItem>
          )}
          {!loading && !error && hasProjects && (
            <Table>
              <Table.Header>
                <Table.Cell header>Production Company</Table.Cell>
                <Table.Cell header>Project</Table.Cell>
                <Table.Cell header>Active</Table.Cell>
              </Table.Header>
              {data.projectsByEmployee.nodes.map(projectTableRow)}
            </Table>
          )}
        </BoxBody>
      </Box>
    </Paper>
  );
};

export default compose(withStyles(styles))(UserProjectList);
