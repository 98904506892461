import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';

const styles = {
  root: {
    flexDirection: 'row',
  },
  header: {
    fontWeight: 600,
  },
  label: {
    fontSize: '1rem',
  },
};

const sendOptions = [
  { label: 'Send Immediately', value: 'immediately' },
  { label: 'Send on Start Date', value: 'start_date' },
  { label: 'Always Send on Start Date', value: 'always_start_date' },
  // The offer form POST/PUT's formData to the API and null values are
  // excluded from the form, so we cast null as a string to keep the mutator
  // consistent with what is being sent to the API
  { label: 'No Default Send Date', value: 'null' },
];

const SendOfferDefault = props => {
  const { classes = {}, value = null, onChange } = props;
  const dataCheckedValue = value === null ? 'null' : value;
  return (
    <div data-test-id="SendOfferDefault-root">
      <Typography variant="h6" className={classes.header}>
        Send Offer Default
      </Typography>
      <RadioGroup
        className={classes.root}
        value={`${value}`}
        onChange={e => onChange(e.target.value)}
      >
        {sendOptions.map(option => (
          <FormControlLabel
            control={
              <Radio
                inputProps={{
                  'data-checked': String(option.value === dataCheckedValue),
                  'data-test-id': `SendOfferDefault-input-${option.value}`,
                }}
              />
            }
            label={option.label}
            value={option.value}
            classes={{ label: classes.label }}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default withStyles(styles)(SendOfferDefault);
