import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel,
  TextField,
  FormControlLabel,
} from '@material-ui/core';

const styles = () => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `
    "title"
    "description"
    "required"
    `,
    gridColumnGap: 0,
    gap: '20px',
    gridGap: '20px',
  },
  title: {
    gridArea: 'title',
  },
  description: {
    gridArea: 'description',
  },
  checkbox: {
    gridArea: 'required',
    display: 'grid',
  },
});

class CustomInputDialog extends Component {
  state = { input: this.props.input || {}, errors: {} };

  componentWillReceiveProps(nextProps) {
    this.setState({ input: nextProps.input || {}, errors: {} });
  }

  handleInputChange = ({ target: { value } }, key) => {
    this.setState(({ input }) => ({
      input: {
        ...input,
        [key]: value,
      },
    }));
  };

  handleIsRequiredChange = value => {
    this.setState(({ input }) => ({
      input: {
        ...input,
        isRequired: value,
      },
    }));
  };

  // Clear the text field if it's focused and contains the default value
  handleTitleFocus = displayName => {
    if (displayName.includes('New Custom Input')) {
      this.handleInputChange({ target: { value: '' } }, 'displayName');
    }
  };

  handleSaveInput = () => {
    const { inputs, saveInput } = this.props;
    const { input } = this.state;
    const inputIndex = inputs.findIndex(i => i.id === input.id);
    // Check for input name uniqueness and display error if it's a duplicate name
    const isDuplicateName = inputs.find(
      (i, index) =>
        i.displayName.toLowerCase() === input.displayName.toLowerCase() &&
        index !== inputIndex,
    );
    if (isDuplicateName) {
      this.setState(({ errors }) => ({
        errors: {
          ...errors,
          displayName: true,
        },
      }));
      return;
    }
    saveInput(input);
  };

  render() {
    const { classes, onClose, isOpen } = this.props;
    const { input, errors } = this.state;
    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <DialogTitle>Configure Input</DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            label="Title"
            onChange={e => this.handleInputChange(e, 'displayName')}
            onFocus={() => this.handleTitleFocus(input.displayName)}
            className={classes.title}
            value={input.displayName}
            error={errors.displayName}
            helperText={
              errors.displayName && 'Duplicate titles are not allowed.'
            }
            inputProps={{
              'data-test-id': 'CustomInputDialog-titleInput',
            }}
          />
          <TextField
            label="Description"
            multiline
            onChange={e => this.handleInputChange(e, 'description')}
            className={classes.description}
            value={input.description}
            inputProps={{
              'data-test-id': 'CustomInputDialog-descriptionInput',
            }}
          />
          <div className={classes.checkbox}>
            <FormLabel>Is this a required input?</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() =>
                    this.handleIsRequiredChange(!input.isRequired)
                  }
                  checked={input.isRequired}
                  data-test-id="CustomInputDialog-isRequiredCheckbox"
                  inputProps={{
                    'data-test-id': 'CustomInputDialog-checkboxInput',
                  }}
                />
              }
              label="Yes"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={onClose}
            data-test-id="InputDialog-cancelButton"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleSaveInput(input)}
            data-test-id="InputDialog-saveButton"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CustomInputDialog);
