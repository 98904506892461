import React from 'react';
import { createOfferCurrency as currency } from '../../utils/format';

import NonUnionTable from './RateScaleRow/NonUnionTable';
import UnionTable from './RateScaleRow/UnionTable';
import USUnionTable from 'studio/components/OfferPage/TermsOfEmployment/USTermsOfEmployment/RateTable';
import USUnionWeeklyTable from 'studio/components/OfferPage/TermsOfEmployment/USUnionWeeklyTermsOfEmployment/WeeklyRateTable';

import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableRowHeader from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowHeader';
import ExpandableTableRowInfo from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRowInfo';
import NonUnionRateTable from 'studio/components/OfferPage/TermsOfEmployment/NonUnionRateTable';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import isNonUnionV2Active from 'common/utilities/isNonUnionActive';
import CanadaRateTable from 'studio/components/OfferPage/TermsOfEmployment/CanadaTermsOfEmployment/RateTable';
import useOfferCountry from 'common/components/SpecifiedCountryOnly/useOfferCountry';
import useOffers from 'studio/components/OfferPage/hooks/useOffers';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import isUnionWeeklyScheduleCode from 'common/utilities/isUnionWeeklySchedule';

const NON_UNION_EXEMPT_TABLE_HEADER = [
  'Daily Rate',
  'Weekly Rate',
  'Number of Days',
];
const NON_UNION_HOURLY_TABLE_HEADER = [
  'Hourly Rate',
  'Overtime Rate',
  'Guaranteed Hours',
];

const RateScaleRow = props => {
  const { offer, colSpan, getRouterParam } = props;
  const {
    terms_of_employment: terms = {},
    work_schedule_code: workScheduleCode,
  } = offer;
  const projectId = getRouterParam('projectId');
  const isUnion = !offer?.union_is_non_union;
  const flags = useFeatureFlags();
  const isUnionScheduleAFlagActive = flags.includes('UnionScheduleA');
  const isUnionWeeklyScheduleFlagActive = flags.includes('UnionWeeklySchedule');
  const { data: offerData = [] } = useOffers(projectId, [offer?.id]);

  const { nodes: offers = [] } = offerData;

  const { data: offerCountry, loading } = useOfferCountry({
    offerId: offer?.id,
  });

  const isCanada = offerCountry?.code === 'CA';

  const rateMultiplication = payRate => {
    if (!payRate) return 0;
    const days = terms?.guaranteed_days_studio || 0;
    const dailyRate = terms?.rate_per_week_studio / days;
    return Number((payRate / dailyRate).toFixed(1));
  };
  const renderRateTable = () => {
    if (!loading) {
      if (isCanada) {
        const { termsOfEmploymentV2 = {} } = offers.length > 0 ? offers[0] : {};
        return (
          <CanadaRateTable
            isReadOnly
            formData={termsOfEmploymentV2}
            onChange={() => {}}
            workScheduleCode={workScheduleCode}
          />
        );
      }

      const isUnionScheduleV2 =
        workScheduleCode === 'A' && isUnionScheduleAFlagActive && isUnion;
      const isUnionWeeklyScheduleV2 =
        isUnionWeeklyScheduleCode(workScheduleCode) &&
        isUnionWeeklyScheduleFlagActive &&
        isUnion;
      const { termsOfEmploymentV2 = {} } = offers.length > 0 ? offers[0] : {};

      if (isUnionScheduleV2) {
        return (
          <USUnionTable
            isReadOnly
            formData={termsOfEmploymentV2}
            onChange={() => {}}
          />
        );
      }
      if (isUnionWeeklyScheduleV2) {
        return (
          <USUnionWeeklyTable
            isReadOnly
            formData={termsOfEmploymentV2}
            onChange={() => {}}
          />
        );
      }
      if (isUnion) {
        return <UnionTable offer={terms} />;
      }
      const { data, header } = getNonUnionTableInfo();
      const employmentTermsData = {
        rate: terms?.rate_per_week_studio || terms?.rate_per_day_studio,
        sixthDayPayMultiplication: rateMultiplication(terms?.pay_6th_day_rate),
        seventhDayPayMultiplication: rateMultiplication(
          terms?.pay_7th_day_rate,
        ),
        overtimeRatePerHour: terms?.overtime_rate,
        ratePerHour: terms?.rate_per_hour_studio,
        guaranteedHours: terms?.guaranteed_hours_studio,
        guaranteedHours6thDay: terms?.pay_6th_day_rate,
        guaranteedHours7thDay: terms?.pay_7th_day_rate,
      };

      return isNonUnionV2Active(flags, workScheduleCode) ? (
        <NonUnionRateTable
          isReadOnly
          onChange={() => {}}
          formData={employmentTermsData}
          workScheduleCode={workScheduleCode}
        />
      ) : (
        <NonUnionTable tableHeader={header} tableData={data} offer={offer} />
      );
    }
  };
  const getNonUnionTableInfo = () => {
    const { terms_of_employment } = offer;
    const {
      rate_per_day_studio: ratePerDayStudio,
      rate_per_week_studio: ratePerWeekStudio,
      guaranteed_days_studio: guaranteedDaysStudio,
      rate_per_hour_studio: ratePerHourStudio,
      overtime_rate: overtimeRate,
      guaranteed_hours_studio: guaranteedHoursStudio,
    } = terms_of_employment;
    if (ratePerDayStudio || ratePerWeekStudio || guaranteedDaysStudio) {
      return {
        data: [
          'Non-Union',
          currency(ratePerDayStudio),
          currency(ratePerWeekStudio),
          guaranteedDaysStudio,
        ],
        header: NON_UNION_EXEMPT_TABLE_HEADER,
      };
    }
    return {
      data: [
        'Non-Union Hourly',
        currency(ratePerHourStudio),
        currency(overtimeRate),
        guaranteedHoursStudio,
      ],
      header: NON_UNION_HOURLY_TABLE_HEADER,
    };
  };

  return (
    <ExpandableTableRow
      rowExpanded
      data-test-id={`RateScaleRow-root-${offer.id}`}
    >
      <ExpandableTableCell colSpan={colSpan} expanded>
        <ExpandableTableRowHeader>Rate/Scale</ExpandableTableRowHeader>
        <ExpandableTableRowInfo>{renderRateTable()}</ExpandableTableRowInfo>
      </ExpandableTableCell>
    </ExpandableTableRow>
  );
};

export default withRouteHelpers(RateScaleRow);
