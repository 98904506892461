import React, { Component } from 'react';
import Box from 'common/oldJavascripts/components/Base/Box';
import Button from 'common/oldJavascripts/components/Base/Button';
import Field from 'common/oldJavascripts/components/Base/Field';
import Header from 'common/oldJavascripts/components/Base/Header';
import T from 'common/oldJavascripts/utils/i18n';
import Link from 'common/oldJavascripts/components/Base/Link';
import Modal from 'common/oldJavascripts/components/Base/Modal';
import Text from 'common/oldJavascripts/components/Base/Text';
import history from 'common/constants/config/history';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import BoxParagraphItem from 'common/oldJavascripts/components/Base/Box/BoxParagraphItem';

// HoC
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';

//MUI
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '20px 1fr',
  },
  charCountLabel: {
    justifySelf: 'end',
    gridColumn: '2',
  },
});

class RejectModal extends Component {
  static mutators = {
    offer: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: '/v2/offers/' + params.offerId,
          create: true,
        };
      },
    },
  };

  state = {
    disableSubmit: true,
    rejectNote: '',
  };

  modalSave = () => {
    const { offer, onError } = this.props;
    const { disableSubmit } = this.state;

    if (disableSubmit) return false;

    this.setState({ disableSubmit: true });

    offer.save(
      'reject',
      {},
      () => {
        history.push('/onboarding/offers');
      },
      err => {
        if (onError) onError(err);
      },
    );
  };

  onNoteChange = value => {
    const { offer } = this.props;

    offer.update('rejection_comments')(value);

    this.setState({
      disableSubmit: value.length === 0,
      rejectNote: value,
    });
  };

  render() {
    const { disableSubmit, rejectNote } = this.state;
    const { onClose, classes } = this.props;
    const maxLength = '500';

    return (
      <Modal minimal>
        <Box minimal noShadow>
          <BoxItem>
            <Header>
              <Header.Title compact center>
                <span data-test-id="RejectModal-title">
                  {T('employee.offers.modal.title')}
                </span>
              </Header.Title>
            </Header>
          </BoxItem>
          <BoxItem minimal>
            <Text paragraph>{T('employee.offers.modal.note')}</Text>
          </BoxItem>
          <BoxItem>
            <span data-test-id="RejectModal-note">
              <div className={classes.root}>
                <InputLabel>{T('employee.offers.modal.note_label')}</InputLabel>
                <Typography className={classes.charCountLabel}>
                  {rejectNote.length} / {maxLength}
                </Typography>
              </div>
              <Field
                type="textarea"
                numRows={6}
                maxLength={maxLength}
                onChange={this.onNoteChange}
              />
            </span>
          </BoxItem>
          <BoxParagraphItem align="center" minimal>
            <Link onClick={onClose}>{T('employee.offers.modal.close')}</Link>
            <Button
              align="right"
              marginLeft
              disabled={disableSubmit}
              onClick={this.modalSave}
              data-test-id="RejectModal-submit"
            >
              {T('employee.offers.modal.submit')}
            </Button>
          </BoxParagraphItem>
        </Box>
      </Modal>
    );
  }
}

export default compose(withStyles(styles), withApi)(RejectModal);
