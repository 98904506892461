import React from 'react';
import T from 'common/oldJavascripts/utils/i18n';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import FreeFormFields from '../FreeFormFields/Index';

// Mui Components
import { Paper, Grid, Typography } from '@material-ui/core';

const Fields = ({
  updateDetailSubDataType,
  enforceMask,
  accountMask,
  updateEnforceMask,
}) => (
  <BoxItem divider={true}>
    <Typography
      variant="h5"
      gutterBottom
      style={{ fontWeight: 'bold', paddingBottom: 20 }}
    >
      <span data-test-id="Fields-title">
        {T('project.documents.manage_fields.title')}
      </span>
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Paper elevation={0}>
          <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
            Account Code Fields
          </Typography>
          <FreeFormFields
            updateDetailSubDataType={updateDetailSubDataType}
            enforceMask={enforceMask}
            accountMask={accountMask}
            updateEnforceMask={updateEnforceMask}
          />
        </Paper>
      </Grid>
    </Grid>
  </BoxItem>
);

export default Fields;
