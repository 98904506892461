import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { logout } from 'common/store/actions/authenticate';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withApi from 'common/hoc/withApi';
import getSSOUrl from 'common/constants/config/ssoUrl';
import BEACH from 'mobile/images/beach.svg';
import PALMTREE from 'mobile/images/palmtree.svg';
import UsaOnly from 'common/components/UsaOnly';

const mobileCompleteStyles = makeStyles(theme => ({
  container: {
    paddingTop: '70px',
    marginBottom: '100px',
    backgroundColor: '#f7fcfc',
    height: '100vh',
  },
  card: {
    minWidth: '295px',
    width: '60vw',
    borderRadius: 2,
    backgroundColor: '#ffffff',
    margin: '0 auto',
    display: 'block',
    padding: '30px',
    boxShadow: '-2px -1px 11px 6px rgba(209,209,209,1)',
  },
  title: {
    color: '#2eaba4',
    fontSize: '25px',
    fontWeight: 400,
    textAlign: 'center',
  },
  subtitle: {
    color: '#646464',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'center',
    marginBottom: 30,
  },
  note: {
    color: '#646464',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  myOffersBtn: {
    width: 231,
    height: 40,
    borderRadius: 2,
    backgroundColor: '#2eaba4',
    fontSize: 14,
    color: '#FFFFFF',
    marginTop: '10px',
    marginBottom: '10px',
  },
  noUnderline: {
    textDecoration: 'none !important',
  },
  myW4sBtn: {
    width: '232px',
    height: '40px',
    borderRadius: 2,
    background: 'transparent',
    border: '1px solid #2eaba4',
    fontSize: 14,
    color: '#2eaba4',
    marginTop: '10px',
    marginBottom: '10px',
    '&:hover': {
      background: 'transparent !important',
    },
    '&:focus': {
      background: 'transparent !important',
    },
  },
  palmtree: {
    marginRight: '15px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

const MobileComplete = ({ logout }) => {
  const classes = mobileCompleteStyles();

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <Typography
          className={classes.title}
          data-test-id="MobileComplete-title"
        >
          You Rock!
        </Typography>
        <Typography
          className={classes.subtitle}
          data-test-id="MobileComplete-subtitle"
        >
          Your offer and completed forms have been submitted for approval
        </Typography>
        <div className={classes.imgContainer}>
          <img
            src={PALMTREE}
            alt="palm tree icon"
            className={classes.palmtree}
          />
          <img src={BEACH} alt="beach icon" />
        </div>
        <UsaOnly>
          <Typography
            className={classes.note}
            data-test-id="MobileComplete-note"
          >
            Don't forget your ID when reporting for work. You'll need it for I-9
            verification.
          </Typography>
        </UsaOnly>
        <div className={classes.btnContainer}>
          <Link
            to="/onboarding"
            className={classes.noUnderline}
            data-test-id="MobileComplete-myOffersLink"
          >
            <Button className={classes.myOffersBtn}>Go to My Offers</Button>
          </Link>
          <UsaOnly>
            <a
              href={`${getSSOUrl()}/w4s`}
              className={classes.noUnderline}
              data-test-id="MobileComplete-myW4sLink"
            >
              <Button className={classes.myW4sBtn}>Go to My W-4s</Button>
            </a>
          </UsaOnly>
          <Button variant="text" onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  logout,
};
export default compose(
  withApi,
  connect(null, mapDispatchToProps),
)(MobileComplete);
