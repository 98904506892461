import React from 'react';

// HOC
import { withStyles } from '@material-ui/core/styles';

// Components

// Utilities
import classNames from 'classnames';

const styles = {
  badge: {
    display: 'inline-block',
    color: 'white',
    paddingLeft: 7,
    paddingRight: 7,
    height: 21,
    lineHeight: '21px',
    borderRadius: 5,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: 'grey',
    '& + span': {
      marginLeft: 6,
    },
  },
  COI: {
    backgroundColor: '#D0031B',
  },
};

const Badges = props => {
  const { badges = [], id, classes } = props;

  return (
    <React.Fragment>
      {badges.map(badge => (
        <span
          key={`Badges-${badge}-${id}`}
          className={classNames(classes.badge, classes[badge])}
        >
          <span data-test-id={`Badges-${badge}-${id}`}>{badge}</span>
        </span>
      ))}
    </React.Fragment>
  );
};

export default withStyles(styles)(Badges);
