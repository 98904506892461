import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import { compose } from 'redux';

// Material ui
import ListItem from 'admin/components/RoleMapper/ListItem';
import HighlightTriggerSourceButton from 'admin/components/RoleMapper/HighlightTriggerSourceButton';

// Utilities
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import ajax from 'common/utilities/ajax';
import * as Sentry from '@sentry/browser';

const styles = theme => ({
  highlightedIcon: {
    color: '#fae047',
  },
  highlightedButton: {
    backgroundColor: '#8b8b8b',
  },
  highlightButton: {
    padding: 0,
    marginRight: '8px',
    height: '20px',
    width: '20px',
  },
  icon: {
    height: '20px',
  },
  bold: {
    fontWeight: 'bold',
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
  ruleTitle: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
    width: '100%',
  },
});

const TriggerRuleListItem = props => {
  const {
    classes = {},
    rule = {},
    toggleRuleMapping,
    openConfigureDialog,
    openDeleteDialog,
    deleteRule,
    activeRuleGroupId,
    highlightTriggerSource,
    triggerSourceIdToHighlight,
    activeRuleId,
    deleteConfirmationRequired = false,
    docId,
    pushSnackbarNotification,
    index,
  } = props;
  const {
    fieldGroups = [],
    triggerFieldId,
    ruleType,
    id,
    code: ruleCode = null,
  } = rule;
  const isEmail = ruleType === 'email';
  const fieldGroup = fieldGroups[0] || {};
  const fieldCount = fieldGroups.reduce(
    (total, { fieldIds = [] }) => (total += fieldIds.length),
    0,
  );
  const ruleDeleteMessage = (
    <span>
      There {fieldCount > 1 ? 'are' : 'is'}{' '}
      <b className={classes.bold}>
        {fieldCount} field
        {fieldCount > 1 && 's'}
      </b>{' '}
      assigned to this {isEmail ? 'email notification' : 'rule'}, are you sure
      you want to delete <b className={classes.bold}>{rule.name}</b>?
    </span>
  );
  const ruleDeleteCallback = () => deleteRule(rule.id);
  // Disable the map and delete buttons if the user is currently mapping a different field group to fields
  const isDisabled = (() => {
    if (ruleType === 'trigger')
      return (
        activeRuleGroupId &&
        !fieldGroups.some(fg => fg.id === activeRuleGroupId)
      );
    return activeRuleId && activeRuleId !== id;
  })();

  const highlightSourceButton = (
    <HighlightTriggerSourceButton
      triggerFieldId={triggerFieldId}
      triggerSourceIdToHighlight={triggerSourceIdToHighlight}
      listItemComponentName="TriggerRuleListItem"
      enabledTooltipText={`This ${
        isEmail ? 'email notification' : 'trigger rule'
      } has not been assigned a trigger source.`}
      disabledTooltipText={`Highlight the ${
        isEmail ? 'notification' : 'trigger'
      } source.`}
      highlightTriggerSource={highlightTriggerSource}
      index={index}
    />
  );
  const ruleTitle = (
    <div className={classes.ruleTitle}>
      {highlightSourceButton}
      {rule.name}
    </div>
  );
  const mapText = isEmail ? 'Map Data' : 'Map Rule';
  const deleteHandler = () => {
    if (!deleteConfirmationRequired || !ruleCode) {
      openDeleteDialog(ruleDeleteMessage, ruleDeleteCallback);
      return;
    }
    ajax
      .get(`/workflow/templates/${docId}/rule-delete/${ruleCode}`)
      .then(({ canDelete, errors: { projectNames = [] } = {} }) => {
        if (canDelete) {
          openDeleteDialog(ruleDeleteMessage, ruleDeleteCallback);
          return;
        } else {
          pushSnackbarNotification({
            message: `This rule is currently being used by the following projects and cannot be deleted: ${projectNames.join(
              ', ',
            )}`,
            variant: SnackbarVariants.ERROR,
          });
        }
      })
      .catch(e => {
        Sentry.captureException(e);
        pushSnackbarNotification({
          message: `There was an error while trying to delete this rule`,
          variant: SnackbarVariants.ERROR,
        });
      });
  };
  const listProps = {
    key: `TriggerRuleListItem-${rule.id}`,
    dataTestIds: {
      root: `TriggerRuleListItem-root-${rule.id}`,
      title: `TriggerRuleListItem-ruleName-${rule.id}`,
      subHeader: `TriggerRuleListItem-mappedFields-${rule.id}`,
    },
    title: ruleTitle,
    subHeader: `${fieldCount} fields`,
    buttons: [
      {
        disabled: isDisabled,
        title: activeRuleGroupId === fieldGroup.id ? 'Done' : mapText,
        color: 'primary',
        onClick: () => toggleRuleMapping(rule.id, fieldGroup.id),
        dataTestId: `TriggerRuleListItem-mapButton-${rule.id}`,
      },
      {
        disabled: !!activeRuleGroupId,
        title: 'Configure',
        color: 'secondary',
        onClick: () => openConfigureDialog(rule.id),
        dataTestId: `TriggerRuleListItem-configureButton-${rule.id}`,
      },
      {
        disabled: !!activeRuleGroupId,
        title: 'Delete',
        onClick: () => deleteHandler(),
        dataTestId: `TriggerRuleListItem-deleteButton-${rule.id}`,
      },
    ],
  };
  return <ListItem {...listProps} />;
};

export default compose(
  withStyles(styles),
  withSnackbarNotification,
)(TriggerRuleListItem);
