// material-ui/colors should really export a default object
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
} from '@material-ui/core/colors';

const COLORS = [
  red,
  deepPurple,
  lightBlue,
  green,
  yellow,
  deepOrange,
  pink,
  indigo,
  cyan,
  lightGreen,
  amber,
  purple,
  blue,
  teal,
  lime,
  orange,
];

const VARIANTS = ['A100', 'A200', 'A400', 'A700'];

const colorFromInt = i => {
  const colorIndex = i % COLORS.length;
  const variantIndex = Math.floor(i / 5) % VARIANTS.length;
  return COLORS[colorIndex][VARIANTS[variantIndex]];
};

export default colorFromInt;
