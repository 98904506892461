import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    outline: 'none',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    width: 60,
    height: 60,
    color: '#848a8f',
    borderRadius: '0px',
  },
  active: {
    background: theme.palette.bottomDialogBackground,
    color: 'white',
    '&:hover': {
      background: theme.palette.bottomDialogBackground,
      color: 'white',
    },
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const ToolbarButton = props => {
  const {
    title,
    disabled,
    classes,
    onClick,
    index,
    dataTestId,
    activeTab,
    icon,
    isEditingRoleName,
  } = props;
  const isActive = index === activeTab;
  const tooltipTitle = (() => {
    if (disabled)
      return 'Please complete field mapping before navigating to another tab.';
    if (isEditingRoleName)
      return 'Please complete editing the Role Name before navigating to another tab';
    return '';
  })();
  return (
    <Tooltip
      title={tooltipTitle}
      classes={{ tooltip: classes.tooltip }}
      data-test-id={`ToolbarButton-tooltip-${title}`}
    >
      <div>
        <Button
          variant="text"
          disabled={disabled || isEditingRoleName}
          className={classNames(classes.root, {
            [classes.active]: isActive,
          })}
          data-test-id={dataTestId}
          onClick={() => onClick(index)}
          classes={{ label: classes.buttonContent }}
          disableRipple
        >
          {icon}
          {title}
        </Button>
      </div>
    </Tooltip>
  );
};

export default withStyles(styles)(ToolbarButton);
