import React from 'react';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import ScrollDestination from 'common/components/ScrollDestination';
import DocumentFields from 'studio/components/OfferPage/DocumentFields';
import Documents from 'studio/components/OfferPage/Documents';

const OfferDocuments = props => {
  const {
    classes,
    formData,
    formErrors,
    onChangeDocument,
    onChangeDocumentFields,
    scrollToDocumentFields,
    templates,
  } = props;
  const { documentsFormErrors, documentFieldsFormErrors } = formErrors;

  if (!templates) return null;

  return (
    <React.Fragment>
      <Documents
        onChange={onChangeDocument}
        classes={{ root: classes.documentsClasses }}
        formData={{
          ...formData,
        }}
        formErrors={documentsFormErrors}
        templates={templates}
      />
      <ScrollDestination
        isActive={scrollToDocumentFields}
        behavior="smooth"
        block="start"
      >
        <DocumentFields
          onChange={onChangeDocumentFields}
          classes={{ root: classes.documentFieldsClasses }}
          formData={{
            ...formData,
          }}
          formErrors={documentFieldsFormErrors}
          templates={templates}
        />
      </ScrollDestination>
    </React.Fragment>
  );
};

export default withRouteHelpers(OfferDocuments);
