import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query hasHiringStatus($projectId: Int!) {
    project(id: $projectId) {
      id
      hasHiringStatus
    }
  }
`;

const useHasHiringStatus = projectId => {
  const { loading, data } = useQuery(QUERY, {
    skip: !projectId,
    variables: { projectId: parseInt(projectId) },
  });
  return { loading, data: data?.project.hasHiringStatus };
};

export default useHasHiringStatus;
