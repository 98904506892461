import React from 'react';
import Icon from './Icon';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const ALERT_CLOSE_TIME = 5000;

const styles = {
  root: {
    borderRadius: '5px',
    padding: '20px 25px',
    marginBottom: '15px',
    maxWidth: '400px',
    borderWidth: '1px',
    borderStyle: 'solid',
    '&span:first-child': {
      marginRight: '0.5em',
    },
  },
  info: {
    background: 'rgb(255, 255, 255)',
    color: 'rgb(242, 94, 91)',
  },
  error: {
    background: 'rgb(251, 222, 223)',
    color: 'rgb(234, 90, 90)',
    borderColor: 'rgb(248, 193, 193)',
  },
  success: {
    background: 'rgb(236, 249, 220)',
    color: 'rgb(153, 204, 72)',
    borderColor: 'rgb(211, 239, 172)',
  },
  warning: {
    background: 'rgb(255, 245, 222)',
    color: 'rgb(255, 180, 0)',
    borderColor: 'rgb(255, 229, 170)',
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    right: '15px',
    top: '20px',
  },
  inline: {
    textAlign: 'center',
    width: '100%',
    minWidth: '100%',
    margin: '15px 0',
    position: 'relative',
    fontSize: '12px',
    textTransform: 'none',
  },
};

class Alert extends React.Component {
  componentDidMount() {
    if (this.props.onClose) {
      setTimeout(this.props.onClose, ALERT_CLOSE_TIME);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div className={this._getClasses()} data-test-id="Alert-root">
        {children}
        {this._renderClose()}
      </div>
    );
  }

  _getClasses = () => {
    const { classes, className, inline, type } = this.props;
    return classNames(
      {
        [classes.inline]: inline,
      },
      classes.root,
      classes[type],
      className,
    );
  };

  _renderClose = () => {
    const { classes, onClose } = this.props;
    if (onClose) {
      return (
        <span className={classes.close} onClick={onClose}>
          <Icon name="remove-pill" />
        </span>
      );
    }
  };
}

export default withStyles(styles)(Alert);
