import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, Tooltip, Typography } from '@material-ui/core';
import DocumentViewer from '../DocumentViewer';
import SignatureTOSDialog from './SignatureTOSDialog';
import ClickThroughAcknowledgment from './ClickThroughAcknowledgment';
import GrowlNotification from './GrowlNotification';
import DocumentSelector from './DocumentSelector';
import ActionButtons from './ActionButtons';
import SaveSignature from './SaveSignature';
import * as FieldStore from './Fields';
import { documentSignerTheme } from '../shared/oldDocumentSignerUI/theme';
import * as FieldTypes from '../utilities/constants/fieldTypes';
import isFilledIn from '../utilities/isFilledIn';
import { CircularProgress } from '@material-ui/core';
import debounce from 'lodash.debounce';
import decorateDocuments from './decorateDocuments';
import inViewport from 'in-viewport';
import MobileAnySignature from './MobileAnySignature';

const AUTOSAVE_INTERVAL = 10000;

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateRows: '100%',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#f2f5f7',
    gridTemplateColumns: '20% 1fr',
    gridTemplateAreas: `"documentSelector documentViewer"`,
  },
  documentSelector: {
    gridArea: 'documentSelector',
  },
  documentViewer: {
    gridArea: 'documentViewer',
  },
  busyOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(195, 195, 195, 0.8)',
    zIndex: 100000,
    display: 'grid',
    placeContent: 'center',
  },
  busyContainer: {
    textAlign: 'center',
  },
});

class DocumentSigner extends Component {
  constructor(props) {
    super(props);

    // Get decorated documents
    const {
      documents,
      isMobile,
      selectedDocumentId,
      throwFatalError,
    } = this.props;
    if (!(documents && documents.length)) {
      throwFatalError('No Documents passed down');
      return;
    }
    const decoratedDocs = decorateDocuments(documents);

    // Get first viewable document
    const firstViewableDoc =
      decoratedDocs.find(doc => doc._derived.isViewable) || {};
    const { id: firstViewableDocId } = firstViewableDoc;
    if (!firstViewableDocId) {
      throwFatalError('No Documents found with images');
      return;
    }

    this.state = {
      documents: decoratedDocs,
      currentDocumentId: isMobile ? selectedDocumentId : firstViewableDocId,
      modals: {
        signature: {
          open: false,
          field: null,
          isMobileAnySignatureDialogOpen: false,
        },
        acknowledgment: {
          closed: false,
        },
      },
      growlType: null,
      growlOpen: false,
      signedFieldTypes: {},
      isPerformingAction: false,
      isSignatureSaved: false,
      errors: [],
    };
  }

  cleanUpNotification = () => {
    this.setState({ growlType: null });
  };

  hideNotification = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({ growlOpen: false });
  };

  handleNotificationContinue = nextDocId => () => {
    this.setState({ growlOpen: false });
    this.setCurrentDocument(nextDocId);
  };

  notifyIfDocumentComplete = doc => {
    const {
      fields,
      _derived: { isComplete, error: docError },
    } = doc;
    const { nextDocId } = this.getDocumentPosition({
      filterOutCompleted: true,
    });
    const docFieldError = fields.some(f => f._derived.error);
    const canContinue = isComplete && nextDocId && !docError && !docFieldError;
    if (canContinue) {
      this.setState({ growlType: 'documentComplete', growlOpen: true });
    }
  };

  autosave = debounce(doc => {
    const { isMobile, mobileDocsSaveHandler } = this.props;
    if (isMobile && mobileDocsSaveHandler) {
      return mobileDocsSaveHandler([doc], true); // autosave boolean flag
    }

    const { actions = {} } = this.props;
    const { save = {} } = actions || {};
    const { action: saveAction } = save || {};

    if (!saveAction) {
      console.warn(
        'Attempted to perform save action but no function was passed down.',
      );
      return;
    }
    saveAction([doc], true); // Flag indicates this is an autosave
  }, AUTOSAVE_INTERVAL);

  onDocumentDidChange = (doc = this.getCurrentDocument()) => {
    const { onChangeDocuments } = this.props;
    this.autosave(doc);
    this.notifyIfDocumentComplete(doc);
    if (onChangeDocuments) onChangeDocuments(this.state.documents);
  };

  onChangeFieldValue = (documentId, fieldId, value) => {
    const { errors } = this.state;
    let fieldToFocus;
    this.setState(
      ({ documents }) => {
        const doc = documents.find(({ id }) => id === documentId);
        const { fields = [] } = doc || {};
        const field = fields.find(({ id }) => id === fieldId);
        const newField = { ...field, value };
        const newDocument = {
          ...doc,
          fields: fields.map(field =>
            field.id === fieldId ? newField : field,
          ),
        };
        const newDocuments = decorateDocuments(
          documents.map(doc => (doc.id === documentId ? newDocument : doc)),
          errors,
          fieldId,
        );
        const { max: maxLength } = field;
        if (maxLength && `${value}`.length === maxLength) {
          const fieldIndex = fields.findIndex(({ id }) => id === fieldId);
          fieldToFocus = fields[fieldIndex + 1];
        }
        return { documents: newDocuments };
      },
      () => {
        if (fieldToFocus) {
          const {
            _derived: { fieldType },
          } = fieldToFocus;
          if (fieldType === FieldTypes.TXT || fieldType === FieldTypes.NUMBER) {
            this.goToField(fieldToFocus.id);
          }
        }
        const { documents } = this.state;
        const doc = documents.find(({ id }) => id === documentId);
        this.onDocumentDidChange(doc);
      },
    );
  };

  setAcknowledgmentClosed = closed => {
    this.setState(({ modals }) => ({
      modals: {
        ...modals,
        acknowledgment: { closed },
      },
    }));
  };

  closeSignatureTOSDialog = () => {
    // This gets called when someone presses the close icon
    // in the signature tos dialog
    // We close the modal and clear the field type and id
    this.setState(({ modals }) => ({
      modals: {
        ...modals,
        signature: {
          open: false,
          field: null,
        },
      },
    }));
  };

  acceptSignatureTOS = fieldValue => {
    const {
      currentDocumentId,
      modals: {
        signature: { field },
      },
    } = this.state;
    const {
      id: fieldId,
      _derived: { isSignature, fieldType },
    } = field;

    if (!isSignature) {
      console.warn(
        'Attempted to accept Signature ToS on a non signature field.',
      );
      return;
    }

    // Update the state and accept the signature tos
    // Close the modal, and then hand it off to onChangeFieldValue
    this.setState(
      ({ modals, signedFieldTypes }) => ({
        signedFieldTypes: { ...signedFieldTypes, [fieldType]: true },
        modals: {
          ...modals,
          signature: {
            open: false,
            field: null,
            isMobileAnySignatureDialogOpen: false,
          },
        },
      }),
      () => this.onChangeFieldValue(currentDocumentId, fieldId, fieldValue),
    );
  };

  // If they have already accepted the TOS for this field type OR they have
  // saved their signature, we can skip showing the dialog and just fill in
  // the signature for them.
  // We always show the dialog for unvalidated signature so they can enter any
  // name they want.
  shouldAutosign = field => {
    const { isMobileSignatureSaved } = this.props;
    const {
      _derived: { isSignature, isValidated, fieldType },
    } = field;
    const { isSignatureSaved, signedFieldTypes } = this.state;
    const isTosAccepted = !!signedFieldTypes[fieldType];
    return (
      isSignature &&
      isValidated &&
      (isTosAccepted || isSignatureSaved || isMobileSignatureSaved === 'true')
    );
  };

  clearSignatureButtonClick = fieldId => {
    const { id: documentId } = this.getCurrentDocument();
    this.onChangeFieldValue(documentId, fieldId, null);
  };
  // Called when a user clicks the icon on a signature field.
  handleSignatureButtonClick = fieldId => {
    const { actor = {}, isMobile } = this.props;
    const { name, initials } = actor || {};
    const { fields = [], id: documentId } = this.getCurrentDocument();
    const field = fields.find(({ id }) => id === fieldId);
    const {
      _derived: { isInitials, isSignature, fieldType },
    } = field;

    // Make sure we are only executing on a signature field
    if (!isSignature) {
      console.warn(
        `Attempted to click signature button ToS on a non signature field, with fieldType: ${fieldType}`,
      );
      return;
    }

    // Autofill signature and return if conditions are met.
    if (this.shouldAutosign(field)) {
      const fieldValue = isInitials ? initials : name;
      this.onChangeFieldValue(documentId, fieldId, fieldValue);
      return;
    }

    if (isMobile) {
      this.handleAnySignatureDialog(field, true);
    } else {
      this.setState(({ modals }) => ({
        modals: {
          ...modals,
          signature: {
            open: true,
            field,
          },
        },
      }));
    }
  };

  getCurrentDocument = () => {
    // Return whatever document is currently chosen
    const { currentDocumentId, documents } = this.state;
    const currentDocument = documents.find(
      ({ id }) => currentDocumentId === id,
    );
    return currentDocument;
  };

  canViewDocument = documentId => {
    // Some documents will come from the API
    // with no images.  This means they do not get to view the document
    // But Product wants them to be able to see that the doc is part of the
    // offer package
    const { documents } = this.state;
    const doc = documents.find(({ id }) => id === documentId);
    return doc._derived.isViewable;
  };

  getDocumentPosition = ({ filterOutCompleted = false } = {}) => {
    const { currentDocumentId, documents } = this.state;
    let docs = documents.filter(({ _derived: { isViewable } }) => isViewable);
    if (filterOutCompleted) {
      docs = docs.filter(
        ({ id, _derived: { isComplete } }) =>
          id === currentDocumentId || !isComplete,
      );
    }
    const docIds = docs.map(({ id }) => id);
    const currDocIndex = docIds.indexOf(currentDocumentId);
    const prevDocId = docIds[currDocIndex - 1];
    const nextDocId = docIds[currDocIndex + 1];
    return {
      prevDocId,
      nextDocId,
    };
  };

  setCurrentDocument = newDocumentId => {
    if (!this.canViewDocument(newDocumentId)) return;
    const { currentDocumentId } = this.state;
    if (newDocumentId === currentDocumentId) return;

    // If there is an autosave pending, flush it.
    this.autosave.flush();
    const { selectedDocumentId, isMobile } = this.props;

    this.setState(
      ({ currentDocumentId }) => ({
        currentDocumentId: isMobile ? selectedDocumentId : newDocumentId,
        signedFieldTypes: {},
      }),
      () => this.setAcknowledgmentClosed(false),
    );
  };

  handleActionButtonClick = type => {
    // This function handles submit, cancel, and save buttons
    // We access the props based on the type of action button clicked
    // and then call that function and pass up the documents
    this.setState({ isPerformingAction: true });
    const { actions = {} } = this.props;
    const { documents } = this.state;
    const { [type]: actionObject = {} } = actions || {};
    const { action } = actionObject || {};
    if (typeof action !== 'function') {
      console.warn(
        `Attempted to call action of type "${type}" from DocumentViewerContainer, but it was not passed in.`,
      );
      return;
    }
    return Promise.resolve(action(documents))
      .then(() => this.setState({ errors: [] }))
      .catch((errors = []) => {
        let isDocError = false;
        if (Array.isArray(errors)) {
          isDocError = errors.some(({ documentId }) => documentId);
        }
        const growlType = isDocError ? 'documentError' : 'unknownError';
        this.setState(({ documents }) => ({
          documents: decorateDocuments(documents, errors),
          errors,
          growlType,
          growlOpen: true,
        }));
        // Re-throw errors so subsequent catch blocks attached to this promise
        // will trigger.
        return Promise.reject(errors);
      })
      .finally(() => this.setState({ isPerformingAction: false }));
  };

  handleAcknowledgment = () => {
    const { errors } = this.state;
    this.setState(({ documents, currentDocumentId }) => {
      const newDocs = documents.map(doc =>
        doc.id === currentDocumentId
          ? { ...doc, crew_acknowledged: true }
          : doc,
      );
      return { documents: decorateDocuments(newDocs, errors) };
    }, this.onDocumentDidChange);
  };

  handleAnySignatureDialog = (field = null, open = false) => {
    this.setState(state => {
      const { modals } = state;
      return {
        modals: {
          ...modals,
          signature: {
            isMobileAnySignatureDialogOpen: open,
            field,
          },
        },
      };
    });
  };

  setFieldRef = (ref, y, fieldId) => {
    // Store the field ref in state
    const { currentDocumentId } = this.state;
    this[`__${currentDocumentId}-${fieldId}`] = { ref, y };
  };

  renderFormField = field => {
    const {
      id: fieldId,
      _derived: { fieldType, readOnlyTooltip },
    } = field;

    if (readOnlyTooltip) {
      const { x: left, y: top, height, width } = field;
      const style = { left, top, height, width, position: 'absolute' };
      return (
        <Tooltip
          title={readOnlyTooltip}
          enterDelay={500}
          key={fieldId}
          placement="bottom"
        >
          <div style={style} />
        </Tooltip>
      );
    }

    const currentDocument = this.getCurrentDocument();
    const { id: documentId } = currentDocument;
    const {
      handleSignatureButtonClick,
      clearSignatureButtonClick,
      onChangeFieldValue,
    } = this;
    const { isMobile } = this.props;
    const props = {
      ...field,
      handleSignatureButtonClick,
      clearSignatureButtonClick,
      isMobile,
      handleChange: newValue =>
        onChangeFieldValue(documentId, fieldId, newValue),
      setFieldRef: (ref, y) => this.setFieldRef(ref, y, fieldId),
    };
    const FormField = FieldStore[fieldType];
    if (!FormField) {
      const { throwFatalError } = this.props;
      throwFatalError('Unable to render Document Field');
      return;
    }
    return <FormField {...props} key={fieldId} />;
  };

  goToField = fieldId => {
    const { fields = [], id: documentId } = this.getCurrentDocument();
    const field = fields.find(({ id }) => id === fieldId);
    const { page } = field;
    this.__documentViewer.setPage(null, page).then(() => {
      const { ref, y = 0 } = this[`__${documentId}-${fieldId}`] || {};
      if (!ref) return;

      // If the field is outside the viewport, scroll to bring it in
      const isInViewport = inViewport(ref, { container: this.__root });
      if (!isInViewport) this.__documentViewer.scrollTo(y * 0.8);

      // Focus the field
      const {
        _derived: { fieldType, isSignature },
      } = field;

      // If field is a signature, simulate a click on the signature button
      // UNLESS clicking the button would autofill the signature.
      if (isSignature) {
        if (!this.shouldAutosign(field)) {
          this.handleSignatureButtonClick(fieldId);
          return;
        }
      }

      // If field is a date, open the datepicker.
      if (fieldType === FieldTypes.DATE) {
        ref.setOpen(true);
        return;
      }

      // Otherwise just try and focus the field
      ref.focus && ref.focus();
      ref.node && ref.node.focus && ref.node.focus();
    });
  };

  // Moves the focus to the first field in the current document which needs to
  // be filled in (or has an error which must be addressed).
  goToFirstIncompleteRequiredField = () => {
    const {
      _derived: { isComplete },
      fields,
    } = this.getCurrentDocument();
    if (isComplete) return;
    const firstIncompleteRequiredField = fields.find(
      ({ _derived: { error, isRequired }, value }) =>
        error || (isRequired && !isFilledIn(value)),
    );
    if (!firstIncompleteRequiredField) return;
    this.goToField(firstIncompleteRequiredField.id);
  };

  setIsSignatureSaved = isSignatureSaved => this.setState({ isSignatureSaved });
  setRootRef = root => (this.__root = root);
  setDocumentViewerRef = documentViewer =>
    (this.__documentViewer = documentViewer);

  render() {
    const {
      actions,
      actor,
      isMobile = false,
      classes: desktopClasses,
      mobileDocumentSignerStyles,
      mobileDocumentViewerStyles,
      getDocumentImageUrls,
      me,
      throwFatalError,
      templatesAcknowledgeNotes,
      templatesAcknowledgeTitle,
      className,
      isSaveSignatureEnabled,
      isDocumentCompleteSnackbarEnabled = true,
    } = this.props;
    const {
      documents,
      growlType,
      growlOpen,
      isPerformingAction,
      modals,
    } = this.state;
    const { signature: signatureModalConfig = {} } = modals || {};
    const { isMobileAnySignatureDialogOpen } = signatureModalConfig;
    const { acknowledgment: { closed: acknowledgmentClosed } = {} } =
      modals || {};
    const currentDocument = this.getCurrentDocument();
    const {
      acknowledge_title: acknowledgeTitle,
      acknowledge_notes: acknowledgeNotes,
      acknowledge_type: acknowledgeType,
      crew_acknowledged: crewAcknowledged,
    } = currentDocument;
    const showClickThroughAcknowledgment =
      acknowledgeType === 'C' && !crewAcknowledged && !acknowledgmentClosed;
    const { prevDocId, nextDocId } = this.getDocumentPosition();
    const { nextDocId: nextIncompleteDocId } = this.getDocumentPosition({
      filterOutCompleted: true,
    });
    const isOfferComplete = documents.every(
      ({ _derived: { isComplete } }) => isComplete,
    );
    const groupedAcknowledgmentDocumentNames = documents
      .filter(doc => doc.acknowledge_type === 'G')
      .map(doc => doc.templateName);

    const classes = mobileDocumentSignerStyles || desktopClasses;

    const isGrowlEnabled =
      isDocumentCompleteSnackbarEnabled || growlType !== 'documentComplete';

    return (
      <MuiThemeProvider theme={documentSignerTheme}>
        <div
          className={classNames(classes.root, className)}
          ref={this.setRootRef}
        >
          {isPerformingAction && (
            <div
              className={classes.busyOverlay}
              data-test-id="DocumentSigner-busyOverlay"
            >
              <div className={classes.busyContainer}>
                <CircularProgress color="black" />
                <Typography>Processing...</Typography>
              </div>
            </div>
          )}
          {!isMobile && (
            <DocumentSelector
              className={classes.documentSelector}
              documents={documents}
              setCurrentDocument={this.setCurrentDocument}
              goToField={this.goToField}
              currentDocumentId={currentDocument.id}
              onSelectAcknowledgment={() => this.setAcknowledgmentClosed(false)}
            />
          )}
          <DocumentViewer
            className={classes.documentViewer}
            currentDocument={currentDocument}
            getDocumentImageUrls={getDocumentImageUrls}
            throwFatalError={throwFatalError}
            setCurrentDocument={this.setCurrentDocument}
            prevDocId={prevDocId}
            nextDocId={nextDocId}
            renderField={this.renderFormField}
            innerRef={this.setDocumentViewerRef}
            isMobile={isMobile}
            mobileDocumentViewerStyles={mobileDocumentViewerStyles}
            actionButtons={
              <ActionButtons
                actions={actions}
                actionButtonClick={this.handleActionButtonClick}
                isOfferComplete={isOfferComplete}
                groupedAcknowledgmentDocumentNames={
                  groupedAcknowledgmentDocumentNames
                }
                templatesAcknowledgeNotes={templatesAcknowledgeNotes}
                templatesAcknowledgeTitle={templatesAcknowledgeTitle}
              />
            }
          />
          {!isMobile && (
            <SignatureTOSDialog
              config={signatureModalConfig}
              actor={actor}
              handleSubmit={this.acceptSignatureTOS}
              handleClose={this.closeSignatureTOSDialog}
            />
          )}
          {isMobileAnySignatureDialogOpen && (
            <MobileAnySignature
              closeDialogHandler={this.handleAnySignatureDialog}
              config={signatureModalConfig}
              actor={actor}
              handleSubmit={this.acceptSignatureTOS}
            />
          )}
          <ClickThroughAcknowledgment
            isOpen={showClickThroughAcknowledgment}
            title={acknowledgeTitle}
            description={acknowledgeNotes}
            onAcknowledge={this.handleAcknowledgment}
            onClose={() => this.setAcknowledgmentClosed(true)}
          />
          {isGrowlEnabled && (
            <GrowlNotification
              isOpen={growlOpen}
              onClose={this.hideNotification}
              onExited={this.cleanUpNotification}
              onContinue={this.handleNotificationContinue(nextIncompleteDocId)}
              type={growlType}
            />
          )}
          {isSaveSignatureEnabled && (
            <SaveSignature
              actor={actor}
              onSaveSignature={this.setIsSignatureSaved}
              documents={documents}
              me={me}
            />
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(DocumentSigner);
