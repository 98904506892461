import React from 'react';
import domClasses from '../../utils/dom/classes.js';

export default class extends React.Component {
  static defaultProps = {
    bold: false,
  };

  render() {
    var classes = null;

    classes = domClasses.set(this.props.className, {
      text: true,
      'text--blue': this.props.blue,
      'text--small': this.props.small,
      'text--faded': this.props.faded,
      'text--inline': this.props.inline,
      'text--alert': this.props.alert,
      'text--nowrap': this.props.nowrap,
      'text--amount': this.props.amount,
      'text--paragraph': this.props.paragraph,
      'text--comment': this.props.comment,
      'text--help': this.props.help,
      'text--bold': this.props.bold,
      'text--italic': this.props.italic,
      'text--big': this.props.big,
      'text--flex-vertical': this.props.flexVertical,
      'text--transform-uppercase': this.props.transform === 'uppercase',
      'text--ultra': this.props.ultra,
    });

    if (this.props.paragraph) {
      return (
        <p
          className={classes}
          title={this.props.title}
          style={this.props.style}
          data-test-id={this.props['data-test-id']}
        >
          {this.props.children}
        </p>
      );
    } else {
      return (
        <span
          className={classes}
          title={this.props.title}
          style={this.props.style}
          data-test-id={this.props['data-test-id']}
        >
          {this.props.children}
        </span>
      );
    }
  }
}
