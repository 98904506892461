import memoize from 'lodash.memoize';
import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import status from 'common/oldJavascripts/utils/react/status';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Loader from 'common/components/Loader';
import BoxBody from 'common/oldJavascripts/components/Base/Box/BoxBody';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Header from 'common/oldJavascripts/components/Base/Header';
import DocumentContainerWrapper from './DocumentContainerWrapper';
import AttachTemplateWrapper from './AttachTemplateWrapper';
import Ajax from 'common/utilities/ajax';

const withCache = fn => memoize(fn, (...args) => args.join(' '));

const fetchOccupations = withCache((projectId, union) =>
  Ajax.get(`/payroll/projects/${projectId}/unions/${union}/occupations`),
);

const fetchSchedules = withCache((projectId, union, occupation) =>
  Ajax.get(
    `/payroll/projects/${projectId}/unions/${union}/occupations/${occupation}/work_schedules`,
  ),
);

const fetchCities = withCache((projectId, stateId) =>
  Ajax.get(`/projects/${projectId}/cities`, {
    state_id: stateId,
    per_page: 101,
  }),
);

const ProjectTemplates = Relay.createClass({
  statics: {
    queries: {
      project: {
        info(_, related) {
          const params = related['/router/params'];
          return {
            id: `/projects/${params.projectId}`,
          };
        },
      },

      states: {
        info(_, related) {
          const params = related['/router/params'];

          return {
            id: `/projects/${params.projectId}/states`,
          };
        },
      },

      departments: {
        info(_, related) {
          const { projectId } = related['/router/params'];
          return {
            id: `/v2/projects/${projectId}/departments`,
          };
        },
      },

      unions: {
        info(_, related) {
          const params = related['/router/params'];
          return {
            id: `/payroll/projects/${params.projectId}/unions`,
          };
        },
      },
    },

    mutators: {
      editTemplate: {
        info(_, related) {
          const params = related['/router/params'];
          const { id } = params;
          return {
            id: `/workflow/templates/${id}`,
            create: true,
          };
        },
      },
    },
  },

  getInitialState() {
    return {
      workCities: {},
      shouldFetch: null,
    };
  },

  _onAttachedTemplates(templateIds) {
    this.setState({ attachedIds: templateIds });
  },

  _onProcessedAttachments() {
    this.setState({ attachedIds: [] });
  },

  _getStatus() {
    const { unions, states, users } = this.props;
    return status([unions, states, users]);
  },

  _lookupState: function(code) {
    const states = this.props.states.data.items;
    const selectedState = states.find(state => state.code === code);
    this.setState({ selectedState });
    return selectedState;
  },

  requestData({ key: requestedKey, param, successCallback, rule }) {
    const { routerParams: params, unions, states, departments } = this.props;
    const { projectId } = params;
    const { workCities } = this.state;
    const { items: departmentsArray = [] } = departments.data;
    const updatedDepartments = departmentsArray.map(item => ({
      ...item,
      description: item.name,
    }));
    let selectedState;
    let response;
    let responses = {
      union: unions.data.items,
      workState: states.data.items,
      hireState: states.data.items,
      department: updatedDepartments,
      workCity: workCities,
      occupation: [],
      schedule: [],
    };
    if (requestedKey === 'occupation' && param) {
      return fetchOccupations(projectId, param).then(data =>
        successCallback(data.items),
      );
    }
    if (requestedKey === 'schedule' && param) {
      if (rule) {
        const { code: unionCode } = rule.union;
        return fetchSchedules(projectId, unionCode, param).then(data =>
          successCallback(data.items),
        );
      }
    }
    if (requestedKey === 'workCity' && param && !workCities[param]) {
      // the `param` value is actually the same as state.code
      selectedState = this._lookupState(param);
      return fetchCities(projectId, selectedState.id).then(data => {
        this.setState({ workCities: data.items }, () => {
          successCallback(this.state.workCities);
        });
      });
    } else {
      response = responses[requestedKey];
      return successCallback(response);
    }
  },

  render: function() {
    const { routerParams: params, project } = this.props;
    const { projectId } = params;
    const { items: departments = [] } = this.props.departments.data;
    const { selectedState, shouldFetch, attachedIds } = this.state;

    if (this._getStatus() === 'loading') {
      return <Loader />;
    }

    if (this.props.unions.status === 'failed') {
      return <FailureAlert queryName="Unions" />;
    }

    return (
      <BoxBody>
        <BoxItem>
          <Header>
            <Header.Nav>
              <AttachTemplateWrapper
                {...params}
                attachSuccessHandler={this._onAttachedTemplates}
              />
            </Header.Nav>
          </Header>
        </BoxItem>
        <BoxItem>
          <DocumentContainerWrapper
            requestHandler={this.requestData}
            params={{ projectId, selectedState }}
            onChange={this._change}
            departments={departments}
            shouldFetch={shouldFetch}
            attachedTemplates={attachedIds}
            onProcessedAttachments={this._onProcessedAttachments}
            projectCountryId={project.data.country_id}
          />
        </BoxItem>
      </BoxBody>
    );
  },
});

export default ProjectTemplates;
