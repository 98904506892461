import get from 'lodash.get';
import React from 'react';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import * as Queries from 'common/apollo/queries';
import * as Mutations from 'common/apollo/mutations';
import DocumentNotification from './DocumentNotification';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import Loader from 'common/components/Loader';

const styles = theme => ({
  container: {
    width: '100%',
    paddingTop: theme.spacing.unit * 3,
  },
  cards: {
    marginTop: 10,
    display: 'flex',
    flexWrap: 'wrap',
  },
});

const DocumentNotifications = ({
  classes,
  onSaveNotification,
  projectTemplateId,
  pushSnackbarNotification,
}) => {
  const { loading, data, error } = useQuery(
    Queries.projectTemplateNotifications,
    {
      variables: { id: parseInt(projectTemplateId) },
    },
  );

  if (error) {
    pushSnackbarNotification({
      message: 'There was an error fetching notifications',
      variant: SnackbarVariants.ERROR,
    });
    return null;
  }

  const notifications = data?.projectTemplate?.notifications;
  if (loading || !notifications) return <Loader />;

  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom>
        Notifications
      </Typography>
      <div className={classes.cards}>
        {notifications.map(notification => (
          <DocumentNotification
            notification={notification}
            key={notification.id}
            onSaveNotification={(...args) =>
              onSaveNotification(...args).catch(err => {
                const errCode = get(err, 'graphQLErrors[0].extensions.code');
                if (errCode === 'FORBIDDEN')
                  pushSnackbarNotification({
                    message:
                      'You do not have permission to perform this operation',
                    variant: SnackbarVariants.ERROR,
                  });
              })
            }
          />
        ))}
      </div>
    </div>
  );
};

const withUpsertProjectTemplateNotification = graphql(
  Mutations.projectTemplateNotificationCreate,
  {
    props: ({ mutate, ownProps: { projectTemplateId: id } }) => ({
      onSaveNotification: (ruleId, distributionLists) => {
        const distributionListsInput = distributionLists.map(
          ({ departments, isEnabled, recipients }) => ({
            departmentIds: departments.map(({ id }) => parseInt(id)),
            isEnabled: !!isEnabled,
            recipientIds: recipients.map(({ id }) => parseInt(id)),
          }),
        );
        return mutate({
          variables: { ruleId, distributionListsInput, id: parseInt(id) },
        });
      },
    }),
    options: ({ projectTemplateId }) => ({
      refetchQueries: [
        {
          query: Queries.projectTemplateNotifications,
          variables: { id: parseInt(projectTemplateId) },
        },
      ],
    }),
  },
);

export default compose(
  withStyles(styles),
  withUpsertProjectTemplateNotification,
  withSnackbarNotification,
)(DocumentNotifications);
