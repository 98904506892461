import React from 'react';
import Feature from 'common/components/Feature';
import OldMobileDocumentSigner from './MobileDocumentSigner_OLD';
import NewMobileDocumentSigner from './MobileDocumentSigner_NEW';

const MobileDocumentSigner = props => (
  <Feature
    name="newMobileDocumentSigner"
    fallback={<OldMobileDocumentSigner {...props} />}
  >
    <NewMobileDocumentSigner {...props} />
  </Feature>
);

export default MobileDocumentSigner;
