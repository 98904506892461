import React from 'react';

// Components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

// Custom Components
import DailyRate from './DailyRate';
import WeeklyRate from './WeeklyRate';
import NumberOfDaysPerWorkWeek from './NumberOfDaysPerWorkWeek';

// Utilities
import classNames from 'class-names';

const ExemptTermsOfEmploymentTable = ({
  classes = {},
  onChange: upstreamOnChange,
  formData = {},
  formErrors = {},
} = {}) => {
  const textFieldProps = key => ({
    onChange: upstreamOnChange,
    formData: formData,
    classes: {
      root: classNames(classes.cellWithTextFieldClass, {
        [classes.errorCell]: formErrors[key],
      }),
      textField: classes.textField,
      textFieldInputContainer: classes.textFieldInputContainer,
      textFieldInput: classes.textFieldInput,
    },
  });

  const weeklyRateLabelClass = classNames(classes.labelCellClass, {
    [classes.errorCell]: formErrors.weeklyRate,
  });

  const dailyRateLabelClass = classNames(classes.labelCellClass, {
    [classes.errorCell]: formErrors.dailyRate,
  });

  return (
    <Table className={classes.root}>
      <TableBody>
        <TableRow>
          <TableCell className={dailyRateLabelClass}>
            Daily Rate
            {formErrors.dailyRate && (
              <Typography
                color="error"
                variant="caption"
                className={classes.errorLabel}
              >
                {formErrors.dailyRate}
              </Typography>
            )}
          </TableCell>
          <DailyRate {...textFieldProps('dailyRate')} />
        </TableRow>
        <TableRow>
          <TableCell className={weeklyRateLabelClass}>
            <div className={classes.idlePayLabel}>Weekly Rate</div>
            {formErrors.weeklyRate && (
              <Typography
                color="error"
                variant="caption"
                className={classes.errorLabel}
              >
                {formErrors.weeklyRate}
              </Typography>
            )}
          </TableCell>
          <WeeklyRate {...textFieldProps('weeklyRate')} />
        </TableRow>
        <TableRow>
          <TableCell className={classes.labelCellClass}>
            <div className={classes.idlePayLabel}>
              Number of Days per Work Week
            </div>
          </TableCell>
          <NumberOfDaysPerWorkWeek
            {...textFieldProps('numberOfDaysPerWorkWeek')}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ExemptTermsOfEmploymentTable;
