import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import formatPhoneNumber from 'common/utilities/formatPhoneNumber';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0 27px',
    },
  },
  title: {
    color: '#646464',
    fontSize: '24px',
    fontWeight: 400,
    marginBottom: 8,
  },
  topCopy: {
    color: '#939393',
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 30,
  },
  jobTitle: {
    color: '#5b5b5b',
    fontSize: 16,
    marginBottom: 20,
    fontStyle: 'italic',
  },
  itemName: {
    color: '#5b5b5b',
    fontSize: 16,
    fontWeight: 400,
  },
  managerName: {
    color: '#656565',
    fontSize: 18,
    fontWeight: 700,
  },
  email: {
    color: '#3c9dd7',
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 8,
  },
  phoneIcon: {
    color: '#2eaba4',
  },
  managerEmail: {
    textDecoration: 'none',
  },
}));

const NeedAssistance = props => {
  const classes = useStyles();
  const {
    first_name: hirerFirstName,
    last_name: hirerLastName,
    email,
    phone,
  } = props?.hirer;

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Need Assistance?</Typography>
      <Typography className={classes.topCopy}>
        Contact your hiring manager:
      </Typography>
      <Typography className={classes.managerName}>
        {hirerFirstName} {hirerLastName}
      </Typography>
      <Typography className={classes.jobTitle}>Hiring Manager</Typography>
      <Typography>
        <IconButton size="small">
          <EmailIcon className={classes.phoneIcon} />
        </IconButton>{' '}
        <span className={classes.email}>
          <a
            href={`mailto:${email}`}
            target="_top"
            className={classes.managerEmail}
          >
            {email}
          </a>
        </span>
      </Typography>
      {phone && (
        <Typography>
          <IconButton size="small">
            <PhoneIcon className={classes.phoneIcon} />
          </IconButton>
          <span className={classes.email}>
            <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
          </span>
        </Typography>
      )}
    </div>
  );
};

export default NeedAssistance;
