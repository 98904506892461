import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, TextField, Dialog } from '@material-ui/core';
import BackButton from 'mobile/components/BackButton';

const signatureStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f7fcfc',
  },
  title: {
    color: '#2eaba4',
    fontSize: '20px',
    fontWeight: 400,
    marginBottom: '10px',
    paddingTop: '40px',
  },
  signatureSection: {
    boxShadow: '0 -3 7 rgba(0, 0, 0, 0.05), 0 8 7 rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ffffff',
    padding: '20px',
  },
  yesBtn: {
    width: 247,
    height: '40px',
    borderRadius: 2,
    backgroundColor: '#2eaba4',
    marginTop: '15px',
    marginBottom: '15px',
  },
  noBtn: {
    color: '#2eaba4',
  },
  pleaseNote: {
    color: '#646464',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '20px',
    marginBottom: '10px',
    padding: '0 15px',
  },
  bold: {
    fontWeight: 500,
  },
  topSection: {
    margin: '0 20px 20px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formFields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '90%',
    background: '#F7FCFC',
  },
  legalCopy: {
    fontSize: '12px',
    backgroundColor: 'white',
    fontWeight: 400,
    padding: '20px 10px',
    height: '112px',
    overflowY: 'auto',
    marginTop: '25px',
    position: 'relative',
  },
  errorMessage: {
    fontSize: 12,
    color: 'red',
    marginLeft: '15px',
  },
  backBtn: {
    marginTop: '17px',
  },
  subTitle: {
    color: '#646464',
    fontSize: 14,
    fontWeight: 400,
    marginTop: '10px',
  },
  input: {
    background: '#f7fcfc',
  },
  label: {
    color: '#2eaba4',
  },
}));

const NAME_ERROR_MESSAGE = 'Full Legal Name is required';
const INITIALS_ERROR_MESSAGE = 'Initials are required';

const Signature = ({
  isSecondAttempt = false,
  handleRememberSignatureInSession,
  actor,
  projectId,
}) => {
  const classes = signatureStyles();
  const [state, setState] = useState({
    fullName: '',
    initials: '',
    fullNameError: false,
    initialsError: false,
    isMatchingNameError: false,
    isMatchingInitialsError: false,
  });
  const {
    fullNameError,
    initialsError,
    fullName,
    initials,
    isMatchingNameError,
    isMatchingInitialsError,
  } = state;
  const { name: profileName, initials: profileInitials } = actor;

  const closeSignatureDialog = () => {
    handleRememberSignatureInSession({
      rememberSignature: false,
    });
  };

  const handleSubmit = ({ rememberSignature }) => {
    const hasMatchingNameErrors = verifyMatchingLegalNames();
    setState(state => ({
      ...state,
      fullNameError: fullName === '',
      initialsError: initials === '',
    }));
    if (fullName === '' || initials === '') return;
    if (hasMatchingNameErrors) return;
    handleRememberSignatureInSession({
      rememberSignature,
      approverInfo: { fullName, initials },
    });
  };

  const verifyMatchingLegalNames = () => {
    const isNameError =
      fullName.toLowerCase().trim() !== profileName.toLowerCase().trim();
    const isInitialsError =
      initials.toLowerCase().trim() !== profileInitials.toLowerCase().trim();
    setState(state => ({
      ...state,
      isMatchingNameError: isNameError,
      isMatchingInitialsError: isInitialsError,
    }));
    return isNameError || isInitialsError;
  };

  const handleLegalName = fullName => {
    setState(state => ({
      ...state,
      fullName,
    }));
  };

  const handleInitials = initials => {
    setState(state => ({
      ...state,
      initials,
    }));
  };

  const firstAttemptTitle =
    'Saving your signature and initials for this session speeds up the approval process.';
  const secondAttemptTitle =
    'To speed things up, let’s save your signature and initials.';
  const title = isSecondAttempt ? secondAttemptTitle : firstAttemptTitle;
  return (
    <Dialog fullScreen open data-test-id="Signature-Dialog">
      <div className={classes.root}>
        <BackButton
          title="Back to Offers"
          onClick={() => closeSignatureDialog()}
          data-test-id="Signature-Dialog-backBtn"
          className={classes.backBtn}
          url={`/projects/${projectId}/offers`}
        />
        <div className={classes.topSection}>
          <Typography className={classes.title}>{title}</Typography>
          {isSecondAttempt && (
            <Typography className={classes.subTitle}>
              Applies to this online session only.
            </Typography>
          )}
        </div>
        <div className={classes.signatureSection}>
          <Typography className={classes.pleaseNote}>
            <span className={classes.bold}>PLEASE NOTE</span> To comply with
            federal I-9 regulations, employees and I-9 verifiers must continue
            to type their signatures directly on the form.{' '}
          </Typography>
          <div className={classes.formFields}>
            <TextField
              id="legal-name"
              className={classes.textField}
              label="Full Legal Name"
              margin="normal"
              variant="filled"
              InputLabelProps={{
                className: classes.label,
              }}
              InputProps={{
                'data-test-id': 'Signature-Dialog-legalName',
                className: classes.input,
              }}
              onChange={e => handleLegalName(e.target.value)}
            />
            {fullNameError && (
              <Typography
                className={classes.errorMessage}
                data-test-id="Signature-Dialog-fullNameError"
              >
                {NAME_ERROR_MESSAGE}
              </Typography>
            )}
            {isMatchingNameError && (
              <Typography className={classes.errorMessage}>
                Please enter your full legal name as recorded in Start+:{' '}
                {profileName}
              </Typography>
            )}
            <TextField
              id="initials"
              className={classes.textField}
              label="Initials"
              margin="normal"
              variant="filled"
              InputLabelProps={{
                className: classes.label,
              }}
              InputProps={{
                'data-test-id': 'Signature-Dialog-initials',
                className: classes.input,
              }}
              onChange={e => handleInitials(e.target.value)}
            />
            {initialsError && (
              <Typography
                className={classes.errorMessage}
                data-test-id="Signature-Dialog-initialsError"
              >
                {INITIALS_ERROR_MESSAGE}
              </Typography>
            )}
            {isMatchingInitialsError && (
              <Typography className={classes.errorMessage}>
                Please enter your initials as recorded in Start+:
                {profileInitials}
              </Typography>
            )}
          </div>
          <div className={classes.btnContainer}>
            <Button
              className={classes.yesBtn}
              onClick={() => handleSubmit({ rememberSignature: true })}
              data-test-id="Signature-Dialog-yesBtn"
            >
              Yes, Save My Signature
            </Button>
            <Typography
              onClick={() => closeSignatureDialog()}
              data-test-id="Signature-Dialog-noBtn"
              className={classes.noBtn}
            >
              No, I'll manually sign my signature{' '}
            </Typography>
          </div>
          <Typography
            className={classes.legalCopy}
            data-test-id="Signature-Dialog-legalCopy"
          >
            Only the person associated with these records is permitted to use
            this system through this account. Completion and submission of the
            online forms available through this system will create legally
            binding obligations associated with each form. Please read each form
            carefully. Execution of each form will be accomplished through
            clicking on the “Sign Here” button, where required on the form. By
            clicking “Sign Here”, you are acknowledging that you have read the
            applicable form, understand the requirements for submission of the
            information, agree that the information you are submitting is
            complete and accurate information, and that the form can be
            submitted and signed by you through this system.
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};
export default Signature;
