import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = {
  root: { background: '#ffffff' },
  bordered: { borderTop: '1px solid #e6e7ea' },
};

const BoxFooter = props => {
  const { classes, children, bordered } = props;
  const rootClass = classNames(classes.root, {
    [classes.bordered]: bordered,
  });
  return <div className={rootClass}>{children}</div>;
};

BoxFooter.defaultProps = {
  bordered: true,
};

export default withStyles(styles)(BoxFooter);
