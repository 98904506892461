import DocumentViewer from './DocumentViewer';
import Modal from '../Base/Modal';

import React from 'react';

class DocumentModal extends React.Component {
  render() {
    const { pages } = this.props;
    if (!pages) {
      return null;
    }
    return (
      <Modal size={pages.length ? 'maximal' : null}>
        <DocumentViewer
          key="viewer"
          onClose={this.props.onClose}
          pages={pages}
        />
      </Modal>
    );
  }
}

export default DocumentModal;
