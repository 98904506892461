import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import OfferDocuments from './OfferDocuments';
import { makeStyles } from '@material-ui/core/styles';
import DocumentModalWrapper from 'studio/oldJavascripts/components/Shared/DocumentModalWrapper';

const documentListStyles = makeStyles(theme => ({
  documentTitle: {
    color: '#646464',
    fontSize: 24,
    fontWeight: 400,
    marginBottom: '30px',
    marginTop: '10px',
  },
}));

const DocumentList = ({ offer }) => {
  const [isDocumentPreviewerOpen, setDocumentPreviewer] = useState(false);
  const [supportingDocumentInfo, setSupportingDocumentInfo] = useState({
    mainDocumentId: '',
    supportingDocumentId: '',
  });
  const { mainDocumentId, supportingDocumentId } = supportingDocumentInfo;
  const classes = documentListStyles();
  const optedOut = offer.status === 'complete_on_paper';
  const { id: offerId, has_packets: offerHasPackets } = offer;

  const handleDocumentPreviewer = (
    mainDocumentId = '',
    supportingDocumentId = '',
  ) => {
    setDocumentPreviewer(!isDocumentPreviewerOpen);
    setSupportingDocumentInfo({
      mainDocumentId,
      supportingDocumentId,
    });
  };
  return (
    <React.Fragment>
      <div
        className={classes.documentsContainer}
        data-test-id="DocumentList-container"
      >
        <Typography variant="h2" className={classes.documentTitle}>
          Supporting Documents
        </Typography>
        {optedOut && (
          <Typography data-test-id="OfferDocuments-optOutText">
            Employee has chosen to complete the offer on paper and cannot
            complete this offer in Start+.
          </Typography>
        )}
        {!optedOut && (
          <OfferDocuments
            params={{
              scope: `/v2/offers/${offerId}`,
              offerId: offerId,
              hasPackets: offerHasPackets,
            }}
            handleDocumentPreview={handleDocumentPreviewer}
          />
        )}
      </div>
      {isDocumentPreviewerOpen && (
        <DocumentModalWrapper
          onClose={handleDocumentPreviewer}
          params={{
            documentId: mainDocumentId,
            isMainDocument: false,
            offerId: offerId,
            supportingDocumentId,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default DocumentList;
