import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import DocumentHeader from './DocumentHeader';
import ProjectTemplateBody from './ProjectTemplateBody';
import DocumentFooter from './DocumentFooter';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

const styles = theme => ({
  root: {
    margin: theme.spacing.unit,
  },
  summaryContent: {
    margin: 0,
  },
});

class ProjectTemplate extends PureComponent {
  state = {
    gqlCallback: null,
  };

  render() {
    const {
      auto_assign_rules: autoAssignRules,
      classes,
      id,
      acknowledge_notes: acknowledgeNotes,
      acknowledge_options: acknowledgeOptions,
      acknowledge_title: acknowledgeTitle,
      acknowledge_type: acknowledgeType,
      deleteDoc,
      handleSubmit,
      isDeleting,
      isUpdatingRank,
      name,
      error = {},
      rank,
      requestHandler,
      supportDocument,
      updateDoc,
      users,
      updateRank,
      workflow = {},
      isSaving = false,
      saveSuccess = false,
      active = false,
      routerHash,
      amendRouterHash,
      workflowsCount,
      templateRequired,
      projectCountryId,
    } = this.props;
    const { gqlCallback } = this.state;
    const isExpanded = routerHash === id;

    // Until this whole page is converted to GraphQL we have to submit two requests
    const submit = (...args) => {
      handleSubmit(...args);
      if (gqlCallback) gqlCallback();
    };

    return (
      <ExpansionPanel
        expanded={isExpanded}
        classes={{ root: classes.root }}
        onChange={(__, newIsExpanded) => {
          if (newIsExpanded !== isExpanded) {
            const newHash = newIsExpanded ? id : null;
            amendRouterHash(newHash);
          }
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon data-test-id={`Document-expand-${id}`} />}
          classes={{
            content: classes.summaryContent,
          }}
        >
          <DocumentHeader
            deleteDoc={deleteDoc}
            name={name}
            id={id}
            isActive={active}
            isDeleting={isDeleting}
            isUpdatingRank={isUpdatingRank}
            rank={rank}
            updateDoc={updateDoc}
            updateRank={updateRank}
          />
        </ExpansionPanelSummary>
        {isExpanded && (
          <React.Fragment>
            <ProjectTemplateBody
              autoAssignRules={autoAssignRules}
              acknowledgeNotes={acknowledgeNotes}
              acknowledgeOptions={acknowledgeOptions}
              acknowledgeTitle={acknowledgeTitle}
              acknowledgeType={acknowledgeType}
              id={id}
              isExpanded={isExpanded}
              requestHandler={requestHandler}
              supportDocument={supportDocument}
              workflow={workflow}
              updateDoc={updateDoc}
              users={users}
              onSetGqlCallback={gqlCallback => this.setState({ gqlCallback })}
              workflowsCount={workflowsCount}
              templateRequired={templateRequired}
              projectCountryId={projectCountryId}
            />
            <DocumentFooter
              error={error.message}
              handleSubmit={submit}
              id={id}
              isSaving={isSaving}
              saveSuccess={saveSuccess}
              supportDocument={supportDocument}
              updateDoc={updateDoc}
            />
          </React.Fragment>
        )}
      </ExpansionPanel>
    );
  }
}

export default withRouteHelpers(withStyles(styles)(ProjectTemplate));
