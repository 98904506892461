import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import sharedCss from '../../shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';

const styles = theme => ({
  ...sharedCss.fields,
  input: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
});

const CustomDateInput = props => {
  const {
    fieldId,
    onClick,
    onChange,
    value,
    classes,
    height,
    width,
    _derived: { isDisabled, isRequired },
  } = props;
  const fieldDynamicStyle = { height, width };
  const inputClass = classNames(classes.input, fieldStatusClass(props));
  return (
    <div
      className={classes.container}
      onClick={onClick}
      style={fieldDynamicStyle}
      data-test-id={`CustomDateInput-root-${fieldId}`}
    >
      <MuiTextField
        value={value}
        style={fieldDynamicStyle}
        placeholder={isRequired ? 'Required' : 'Click to select a date'}
        type="text"
        onChange={onChange}
        margin="none"
        disabled={isDisabled}
        InputProps={{
          disableUnderline: isDisabled,
          classes: {
            input: inputClass,
          },
          inputProps: {
            'data-test-id': `CustomDateInput-input-${fieldId}`,
          },
        }}
      />
    </div>
  );
};

export default withStyles(styles)(CustomDateInput);
