import React from 'react';
import Flexbox from 'flexbox-react';
import { withStyles } from '@material-ui/core/styles';
import Packet from './Packet';
import Preloader from 'common/oldJavascripts/components/Shared/Preloader';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import status from 'common/oldJavascripts/utils/react/status.js';
import UserRole from 'common/oldJavascripts/utils/UserRole.js';
import DocumentModalWrapper from './DocumentModalWrapper';
import OfferDocuments from 'studio/components/OfferDocuments';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';

const styles = {
  headerContainer: {
    flex: '1 1 auto',
    margin: '0 5px 10px',
    padding: '5px',
    background: '#ffffff',
  },
  packetIcon: {
    margin: '0 0 0 20px',
  },
  packetContainer: {
    flex: '0 0 auto',
    margin: '20px 10px',
  },
  packetsContainer: {
    flex: '0 0 auto',
    margin: '20px 10px',
    maxWidth: 400,
  },
  approved: {
    color: '#99cc48',
  },
  notApproved: {
    color: '#99cc48',
  },
  icon: {
    marginTop: '20px',
    cursor: 'pointer',
  },
};

export default withStyles(styles)(
  Relay.createClass({
    statics: {
      queries: {
        offerDocuments: {
          info(params) {
            const { hasPackets, offerId } = params;
            if (!hasPackets) {
              return {
                id: `/v2/offers/${offerId}/documents`,
              };
            }
            return {
              id: `empty`,
            };
          },
        },
        packets: {
          info(params) {
            const { hasPackets, scope } = params;
            if (hasPackets) {
              return {
                id: `${scope}/packets`,
              };
            }
            return {
              id: `empty`,
            };
          },
        },
        user: {
          info(params, related) {
            const routerParams = related['/router/params'];
            const projectId = routerParams.projectId;
            const scope = projectId ? 'projects' : 'offers';

            return {
              id: `/v2/${scope}/${projectId ? projectId : params.offerId}/user`,
            };
          },
        },
      },
    },

    getDefaultProps() {
      return {
        allowPreview: true,
        colorStyle: 'blue',
        hidePermanent: false,
        showHeader: true,
      };
    },

    getInitialState() {
      return {
        isMainDocument: true,
        modalIsOpen: false,
        mainDocumentId: '',
        supportingDocumentId: '',
      };
    },

    handlePreviewDocument(
      isMainDocument,
      mainDocumentId,
      supportingDocumentId = '',
    ) {
      this.setState({
        isMainDocument,
        mainDocumentId,
        modalIsOpen: true,
        supportingDocumentId,
      });
    },

    handleCloseModal() {
      this.setState({
        modalIsOpen: false,
      });
    },

    renderOfferDocuments() {
      const {
        actionClickHandler,
        expandedWorkflow,
        params,
        offerDocuments,
      } = this.props;
      if (offerDocuments.status === 'loading') {
        return <Preloader />;
      }
      const { offerId } = params;
      const { data = {} } = offerDocuments;
      const { items: documents = [] } = data;
      if (documents.length === 0) {
        return <Blankslate>No Documents Defined</Blankslate>;
      }
      const selectedDocument =
        documents.find(doc => expandedWorkflow[offerId] === doc.id) || {};
      const documentWorkflow = selectedDocument.workflow || [];
      const offerDocumentsProps = {
        documents,
        selectedDocument,
        documentStatus: documentWorkflow,
        offerId,
        requestDocumentStatus: actionClickHandler,
        previewDocument: this.handlePreviewDocument,
      };
      return <OfferDocuments {...offerDocumentsProps} />;
    },

    render() {
      const {
        isMainDocument,
        modalIsOpen,
        mainDocumentId,
        supportingDocumentId,
      } = this.state;
      const { params } = this.props;
      const { hasPackets, offerId } = params;
      return (
        <Flexbox flexDirection="column" width={this.props.width}>
          {modalIsOpen && (
            <DocumentModalWrapper
              onClose={this.handleCloseModal}
              params={{
                documentId: mainDocumentId,
                isMainDocument,
                offerId,
                supportingDocumentId,
              }}
            />
          )}
          {this._renderHeader()}
          <Flexbox flexDirection="row" flexWrap="wrap">
            {hasPackets ? this._renderPackets() : this.renderOfferDocuments()}
          </Flexbox>
        </Flexbox>
      );
    },

    _getPackets() {
      const packets = this.props.packets.data.items;
      // apply hide permanent to offers documents
      if (this.props.hidePermanent) {
        return packets.filter(packet => !packet.is_permanent);
      }
      return packets;
    },

    _renderHeader: function() {
      const { classes, showHeader } = this.props;
      if (showHeader) {
        var signatureNote = null;

        return (
          <div className={classes.headerContainer}>
            {'Offer Documents'}
            {signatureNote}
          </div>
        );
      }
    },

    _renderPackets() {
      const { classes } = this.props;
      const status = this._getStatus();
      const scope = this.props.params.scope;
      const approvals = scope.split('/').indexOf('approvals') !== -1;

      if (status === 'loading') {
        return <Preloader />;
      }
      if (status === 'failed') {
        return <FailureAlert />;
      }

      const user = this.props.user.data;
      const packets = this._getPackets();
      const isEmployee = new UserRole(user.role).isEmployee();

      if (packets.length === 0) {
        return <Blankslate>No Packets Defined</Blankslate>;
      }

      if (this.props.showAction) {
        return packets
          .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
          .map(packet => (
            <Flexbox>
              <div
                key={`packet-${packet.id}-icon`}
                className={classes.packetIcon}
              >
                {this._renderIcon(packet.documents, packet.offer_id, packet.id)}
              </div>
              <div
                className={classes.packetContainer}
                key={`packet-${packet.id}-desc`}
              >
                <Packet
                  allowPreview={
                    (this.props.allowPreview && approvals) || isEmployee
                  }
                  colorStyle={this.props.colorStyle}
                  isApproval={approvals}
                  packet={packet}
                  params={this.props.params}
                  user={user}
                />
              </div>
            </Flexbox>
          ));
      }

      //sort the documents and pass the sorted list down to the OfferDocuments component
      return packets
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map(packet => (
          <div key={`packet-${packet.id}`} className={classes.packetsContainer}>
            <Packet
              allowPreview={
                (this.props.allowPreview && approvals) || isEmployee
              }
              colorStyle={this.props.colorStyle}
              isApproval={approvals}
              packet={packet}
              params={this.props.params}
              user={user}
            />
          </div>
        ));
    },

    _renderIcon(documents, offerId, packetId) {
      //need all possible approval_statuses from BE team
      const { actionClickHandler, classes = {} } = this.props;
      const getStatus = doc => doc.approval_status;
      const countApprovals = (sum, status) =>
        status === 'approved' ? sum + 1 : sum;
      const approved =
        documents.length === documents.map(getStatus).reduce(countApprovals, 0);

      if (approved) {
        return (
          <CheckCircleIcon
            className={classNames(classes.icon, {
              [classes.approved]: approved,
            })}
          />
        );
      }
      return (
        <CheckCircleIcon
          className={classNames(classes.icon, {
            [classes.notApproved]: !approved,
          })}
          onClick={() => actionClickHandler(offerId, packetId)}
        />
      );
    },

    _getStatus() {
      return status([this.props.packets, this.props.user]);
    },
  }),
);
