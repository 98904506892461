import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { Divider, Typography, MuiThemeProvider } from '@material-ui/core';

import SupportDocumentRow from './SupportDocumentRow';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    minWidth: '480px',
    margin: 'auto',
    height: 'inherit',
  },
  instructions: {
    width: '50%',
  },
  documentRow: {
    display: 'flex',
    flexDirection: 'row',
    '&:nth-child(even)': {
      background: '#F4F4F4',
    },
    '&:nth-child(odd)': {
      background: '#E5E5E5',
    },
    '&:first-child': {
      background: 'inherit',
    },
  },
  textMargin: {
    marginLeft: theme.spacing.unit / 2,
  },
  headers: {
    fontWeight: 'bold',
  },
  documentActions: {
    display: 'flex',
    flex: 1,
    marginLeft: theme.spacing.unit * 2,
    minWidth: 100,
  },
  supportDoc: {
    margin: theme.spacing.unit / 4,
    background: '#CCC',
  },
  documentList: {
    height: 'calc(100% - 80px)',
    overflow: 'auto',
  },
  red: {
    color: 'red',
  },
});

class RequestSupportingDocuments extends PureComponent {
  state = {};

  editHandler = () => {};

  render() {
    const {
      documents,
      offerId,
      uploadHandler,
      editHandler,
      deleteHandler,
      showDocumentHandler,
      classes,
      isApproval,
    } = this.props;

    const emptyRequest = [
      <Divider key="divider-for-empty" />,
      <ListItem key="empty-container" className={classes.mainDocument}>
        <ListItemText primary="No support document requested." />
      </ListItem>,
    ];

    const documentList = () => {
      let rows = [];
      documents.forEach(doc => {
        const { supportingDocumentTitles, id: documentId } = doc;
        const nestedSupportRow = supportingDocumentTitles.map(
          (supportDoc, index) => {
            const { id: supportingDocumentId } = supportDoc || {};
            const apiData = {
              offerId,
              documentId,
              supportingDocumentId,
            };
            return (
              <SupportDocumentRow
                key={`support-document-row-${supportingDocumentId}`}
                doc={doc}
                editHandler={data => editHandler(data, apiData)}
                uploadHandler={formData => uploadHandler(formData, apiData)}
                deleteHandler={({ fileId }) =>
                  deleteHandler({ fileId, supportingDocumentId, documentId })
                }
                showDocumentHandler={showDocumentHandler}
                index={index}
                isApproval={isApproval}
              />
            );
          },
        );
        rows = [...rows, ...nestedSupportRow];
      });
      return rows;
    };

    return (
      <MuiThemeProvider theme={documentSignerTheme}>
        <div className={classes.root}>
          <Typography
            variant="h5"
            component="h3"
            data-test-id="RequestSupportingDocuments-title"
          >
            Supporting Document Request
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.instructions}
            gutterBottom
            data-test-id="RequestSupportingDocuments-description"
          >
            You have been requested to provide supporting documentation for your
            allowance(s) (e.g. Box Rental Inventory, Proof of Insurance, etc).
            {!isApproval && (
              <React.Fragment>
                <br />
                <br />- If required (red asterisk{' '}
                <span className={classes.red}>*</span>) you must upload in order
                to proceed.
                <br />- If optional (no asterisk) you may upload, but are not
                required to upload.
              </React.Fragment>
            )}
            <br />
            <br />
            For box rental, if you do not load box rental inventory, you may be
            required to bring a physical copy to Production Accounting.
            <br />
            <br />
            Please ensure you do not upload health-related documents and be sure
            to redact any unnecessary information (e.g., financial information)
            from any documents you upload.
          </Typography>
          <List className={classes.documentList}>
            <ListItem key="title-row-container" className={classes.documentRow}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.headers}>
                    Requested Document
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.headers}>
                    Associated Document
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.headers}>
                    Attachments
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            {documents.length === 0 ? emptyRequest : documentList()}
          </List>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(RequestSupportingDocuments);
