const UNION_WEEKLY_SCHEDULE = [
  'B',
  'B1',
  'B2',
  'B3',
  'B4',
  'C1',
  'C2',
  'C3',
  'C4',
  'AW',
  'W',
  'E1',
  'E2',
  'F1',
  'D1',
];

export default workScheduleCode =>
  UNION_WEEKLY_SCHEDULE.includes(workScheduleCode);
