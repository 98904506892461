import React from 'react';
import Select from 'react-select';
import Typography from '@material-ui/core/Typography';
import formatOptionLabel from './formatOptionLabel';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

const HiringStatusSelect = props => {
  const {
    onChange: upstreamOnChange,
    formData: { hiringStatus = '' } = {},
    classes = {},
    error = '',
    selectStyles,
  } = props;

  const hiringStatusOptions = [
    { value: 'full_time', label: 'Full Time' },
    { value: 'variable', label: 'Variable' },
  ];
  const value = hiringStatusOptions.find(({ value }) => value === hiringStatus);

  const onChange = ({ value }) =>
    upstreamOnChange({
      hiringStatus: value,
    });

  return (
    <div className={classes.root}>
      <Typography className={classes.label} variant="body1">
        ACA Hiring Status
      </Typography>
      <div data-test-id="HiringStatusSelect-select">
        <Select
          styles={selectStyles}
          className={classes.select}
          onChange={onChange}
          value={value}
          options={hiringStatusOptions}
          formatOptionLabel={formatOptionLabel('HiringStatusSelect')}
        />
      </div>
      {error && (
        <Typography color="error" variant="caption" className={classes.error}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default withRouteHelpers(HiringStatusSelect);
