import React from 'react';
import Relay from '../../utils/react/Relay.js';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Table from '../Base/Table';
import Select from '../Base/Select';
import Header from '../Base/Header';
import T from '../../utils/i18n';
import reimbursementTypes from '../../utils/reimbursementTypes';
import collection from '../../utils/collection.js';
import { Errors } from '../Base/Field';

import { formatCurrency } from 'common/utilities/formatCurrency';
var EditableCell = Table.EditableCell;
var ReimbursementRow = null;

ReimbursementRow = Relay.createClass({
  render: function() {
    var data = this.props.data;
    var errors = this.props.errors;
    var editMode = this.props.editMode;
    var type = this.props.type;
    var perOptions = null;
    var perValue = data[type + '_per'];

    perOptions = [
      { value: 'day', label: T('Day') },
      { value: 'week', label: T('Week') },
    ];

    // Handle migrated offers and project configurations that have custom values
    if (perValue && perValue !== 'day' && perValue !== 'week') {
      perOptions.push({
        value: perValue,
        label: perValue,
        rightLabel: '(' + T('migrated value') + ')',
      });
    }

    return (
      <Table.Row>
        <Table.Cell data-test-id={`ReimbursementsRow-title-${type}`}>
          {T('shared.reimbursements.' + type)}
        </Table.Cell>
        <EditableCell
          value={data[type + '_amount']}
          onChange={this._cellChange(type + '_amount')}
          ignoreZero={true}
          format="currency"
          align="right"
          errors={this._errorsForKey(errors, type + '_amount')}
          minValueWarning={0}
          maxValueWarning={9999999}
          label={''}
          editMode={editMode}
          data-test-id={`ReimbursementsRow-amount-${type}`}
        />
        <Table.Cell
          allowOverflow
          data-test-id={`ReimbursementsRow-per-${type}`}
        >
          <Select
            options={perOptions}
            placeholder={T('Choose')}
            value={data[type + '_per']}
            onChange={this._cellChange(type + '_per')}
            searchable={false}
            isInvalid={this._errorsForKey(errors, type + '_per') ? true : false}
          />
        </Table.Cell>
        <EditableCell
          value={data[type + '_cap']}
          onChange={this._cellChange(type + '_cap')}
          ignoreZero={true}
          format="currency"
          align="right"
          errors={this._errorsForKey(errors, type + '_cap')}
          minValueWarning={Number(data[`${type}_amount`])}
          maxValueWarning={9999999}
          label={''}
          editMode={editMode}
          maxLength={9}
          isCurrency={true}
          data-test-id={`ReimbursementsRow-cap-${type}`}
        />
        <EditableCell
          value={data[type + '_duration']}
          onChange={this._cellChange(type + '_duration')}
          ignoreZero={true}
          format="numeric"
          fractionalDigits={0}
          errors={this._errorsForKey(errors, type + '_duration')}
          maxValueWarning={999}
          align="center"
          label={''}
          editMode={editMode}
          maxLength={3}
          data-test-id={`ReimbursementsRow-duration-${type}`}
        />
      </Table.Row>
    );
  },

  _cellChange: function(key) {
    var self = this;
    return function(value) {
      let updatedValue = value;
      const columnKey = key.split('_').pop();
      if (columnKey === 'cap') {
        // format the value as US currency, eg. 600200.22
        updatedValue = formatCurrency(value);
      }
      self.props.onChange(key, updatedValue);
    };
  },

  _errorsForKey: function(errors, key) {
    return errors[key] || null;
  },
});

export default class extends React.Component {
  render() {
    var self = this;

    const errorsDisplay = [].concat.apply([], Object.values(this.props.errors));

    var rows = collection.map(reimbursementTypes, function(type) {
      return (
        <ReimbursementRow
          data={self.props.data}
          onChange={self.props.onChange}
          errors={self.props.errors || []}
          editMode={self.props.editMode}
          key={type}
          type={type}
        />
      );
    });

    return (
      <BoxItem>
        <Header>
          <Header.Title>
            <span data-test-id="ReimbursementsTable-title">
              Allowances / Reimbursements
            </span>
          </Header.Title>
        </Header>
        <Errors errors={errorsDisplay} />
        <Table
          grid={true}
          noWrap={true}
          overflowed={true}
          data-test-id="ReimbursementsTable-table"
        >
          <Table.Row header={true}>
            <Table.Cell />
            <Table.Cell align="center">
              {T('terms_of_hire.employment.amount')}
            </Table.Cell>
            <Table.Cell align="center">
              {T('terms_of_hire.employment.per')}
            </Table.Cell>
            <Table.Cell align="center">
              {T('terms_of_hire.employment.cap')}
            </Table.Cell>
            <Table.Cell align="center">
              {T('terms_of_hire.employment.duration')}
            </Table.Cell>
          </Table.Row>
          {rows}
        </Table>
      </BoxItem>
    );
  }
}
