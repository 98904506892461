import React from 'react';
import ProjectTemplateAutoAssignmentRuleSelect from './ProjectTemplateAutoAssignmentRuleSelect';
import { RULE_TYPES } from '../constants/ruleTypes';

const ProjectTemplateAutoAssignmentRuleTypeSelect = props => {
  const { className, 'data-test-id': dataTestId, onChange, ruleType } = props;
  const options = RULE_TYPES.map(({ description, typeName }) => ({
    label: description,
    value: typeName,
  }));
  return (
    <ProjectTemplateAutoAssignmentRuleSelect
      label="Rule Type"
      options={options}
      value={ruleType}
      onChange={onChange}
      data-test-id={dataTestId}
      className={className}
    />
  );
};

export default ProjectTemplateAutoAssignmentRuleTypeSelect;
