import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Done as CheckIcon, Clear as ErrorIcon } from '@material-ui/icons';
import {
  COMPLETE,
  INPROGRESS,
  REJECTED,
  WAITING,
} from 'common/utilities/constants/documentStatus';

const styles = theme => ({
  denseItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing.unit / 4,
  },
  itemTextRoot: {
    padding: 0,
  },
  itemTextInset: {
    '&:first-child': {
      paddingLeft: theme.spacing.unit * 3 + 2,
    },
  },
  itemIconRoot: {
    marginRight: theme.spacing.unit / 4,
  },
  success: {
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
});

const ApprovalStepDetail = props => {
  const { data, classes } = props;
  const { approvers } = data;

  const generateApproverIcon = approver => {
    let icon = null;

    if (approver.status === COMPLETE) {
      icon = <CheckIcon className={classes.success} />;
    }
    if (approver.status === REJECTED) {
      icon = <ErrorIcon className={classes.error} />;
    }
    return icon ? (
      <ListItemIcon classes={{ root: classes.itemIconRoot }}>
        {icon}
      </ListItemIcon>
    ) : null;
  };

  const generateApproverText = approver => {
    const color =
      approver.status === INPROGRESS || approver.status === WAITING
        ? { secondary: approver.fullName }
        : { primary: approver.fullName };
    return (
      <ListItemText
        inset
        {...color}
        classes={{ root: classes.itemTextRoot, inset: classes.itemTextInset }}
      />
    );
  };

  const approverList = approvers.map(ap => (
    <ListItem key={ap.id} classes={{ dense: classes.denseItem }}>
      {generateApproverIcon(ap)}
      {generateApproverText(ap)}
    </ListItem>
  ));

  return (
    <div>
      <List dense>{approverList}</List>
    </div>
  );
};

export default withStyles(styles)(ApprovalStepDetail);
