import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Button, Typography } from '@material-ui/core';
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import humanizeFileTypes from 'common/utilities/humanizeFileTypes';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  dropzone: {
    position: 'relative',
    border: 'none',
    color: 'rgba(0, 0, 0, 0)',
  },
  dropfiles: {
    backgroundColor: palette.white,
    position: 'relative',
    padding: `${theme.spacing.unit * 1.25}px ${theme.spacing.unit * 2.5}px`,
    height: '100%',
  },
  instruction: {
    border: '2px dashed',
    borderRadius: '3px',
    borderColor: 'lightgray',
    backgroundColor: theme.palette.common.white,
    textAlign: 'center',
  },
  secondaryButton: {
    marginLeft: '4rem',
    fontFamily:
      'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif !important',
    fontSize: 14,
    fontWeight: 500,
    height: '40px',
    background: 'transparent !important',
    color: '#2eaba4',
    border: '1px solid #2eaba4',
    textTransform: 'none !important',
    top: '-1px',
  },
  itemRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    height: '100%',
  },
  cloudIcon: {
    fontSize: 50,
    marginRight: '5px',
    color: '#32c5ff',
  },
  overlayStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '8em 0',
    background: theme.palette.divider,
    textAlign: 'center',
    color: 'inherit',
  },
  hidden: {
    display: 'none',
  },
  dropzoneTitle: {
    marginTop: '15px',
    marginLeft: '1rem',
    color: 'lightgray',
  },
  filesLink: {
    color: '#3C9DE3',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '4px solid #3C9DE3',
      paddingBottom: '5px',
    },
  },
  myFiles: {
    textAlign: 'center',
    marginTop: 20,
    zIndex: 5,
    color: '#333',
  },
});

class Uploader extends Component {
  static defaultProps = {
    accept: 'application/pdf',
  };

  state = {
    dropzoneActive: false,
  };

  onDragEnter = () => {
    this.setState({
      dropzoneActive: true,
    });
  };

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false,
    });
  };

  onDrop = files => {
    const { onUpload } = this.props;
    this.setState({
      dropzoneActive: false,
    });
    onUpload(files);
  };

  onClick = reference => {
    reference.open();
  };

  render() {
    const {
      classes,
      accept = '',
      children,
      maxFiles,
      handleMyFiles,
      isMyFiles,
    } = this.props;
    const { dropzoneActive } = this.state;
    const rejectStyle = { color: 'red' };
    const multiFile = maxFiles !== 1;
    const disableUpload = multiFile && children && children.length >= maxFiles;
    const message = multiFile
      ? 'Drag & Drop files here or'
      : 'Drag & Drop a file here or';
    let dropzoneRef;
    return (
      <Dropzone
        className={classes.dropzone}
        accept={accept}
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        disableClick
        ref={node => {
          dropzoneRef = node;
        }}
        rejectStyle={rejectStyle}
        multiple={multiFile}
        disabled={disableUpload}
      >
        <div className={classes.dropfiles}>
          {children}
          {dropzoneActive && (
            <Typography className={classes.overlayStyle}>
              Please drop {humanizeFileTypes(accept)} files only
            </Typography>
          )}

          <div
            className={classNames(classes.instruction, {
              [classes.hidden]: disableUpload,
            })}
          >
            <Paper square elevation={0} className={classes.paper}>
              <div className={classes.itemRow}>
                <CloudUploadIcon className={classes.cloudIcon} />
                <Typography className={classes.dropzoneTitle}>
                  {message}
                </Typography>
                <Button
                  size="medium"
                  variant="contained"
                  className={classes.secondaryButton}
                  data-test-id="uploader-open-file-selector-button"
                  onClick={() => this.onClick(dropzoneRef)}
                >
                  Browse for file
                </Button>
              </div>
            </Paper>
          </div>
          {isMyFiles && (
            <Typography className={classes.myFiles}>
              Select from{' '}
              <span onClick={handleMyFiles} className={classes.filesLink}>
                My Files
              </span>
              ?
            </Typography>
          )}
        </div>
      </Dropzone>
    );
  }
}

export default withStyles(styles)(Uploader);
