import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Stepper, Step } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import { Clear as CloseIcon } from '@material-ui/icons';
import ApprovalStepLabel from './ApprovalStepLabel';
import ApprovalStepDetail from './ApprovalStepDetail';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  stepperRoot: {
    paddingTop: `${theme.spacing.unit * 6}px`,
    alignItems: 'flex-start',
  },
  stepHorizontal: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  denseLabel: {
    width: theme.spacing.unit * 20,
  },
  closeIcon: {
    float: 'right',
    marginTop: -theme.spacing.unit * 3,
    cursor: 'pointer',
  },
});

const DocumentApprovalStatus = props => {
  const { classes, data, open, closeDrawer } = props;

  const approvalSteps = data.map(datum => (
    <Step key={datum.step} classes={{ horizontal: classes.stepHorizontal }}>
      <ApprovalStepLabel stepDetail={datum}>
        <ApprovalStepDetail data={datum} />
      </ApprovalStepLabel>
    </Step>
  ));

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Typography variant="h6">Approvals</Typography>
        <div className={classes.closeIcon}>
          <CloseIcon
            onClick={closeDrawer}
            data-test-id="close-button-document-approval-status"
          />
        </div>
        <Stepper classes={{ root: classes.stepperRoot }}>
          {approvalSteps}
        </Stepper>
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(DocumentApprovalStatus);
