import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Components

import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';
import DocumentLibrary from 'admin/oldJavascripts/components/Shared/DocumentLibrary';
import RoleMapper from 'admin/oldJavascripts/components/Pages/Library/Documents/RoleMapperWrapper';

const Libary = props => (
  <BaseLayout data-test-id="Library-LibraryLayout">
    <Switch>
      <Route
        exact
        path="/library/documents/:documentId/mapping"
        component={RoleMapper}
      />
      <Route
        exact
        path="/library/documents/:documentId/replacement"
        component={RoleMapper}
      />
      <Route component={DocumentLibrary} />
    </Switch>
  </BaseLayout>
);

export default Libary;
