import { useCookies } from 'react-cookie';

// Convenience wrapper around useCookies for working with a single cookie.
const useCookie = name => {
  const [cookies, setCookie, removeCookie] = useCookies([name]);
  return [
    cookies[name],
    (value, options) => setCookie(name, value, options),
    options => removeCookie(name, options),
  ];
};

export default useCookie;
