export const TXT = 'TXT';
export const CHK = 'CHK';
// export const BTN = 'BTN';  // We dont support buttons
export const RDO = 'RDO';
export const CMB = 'CMB';
// Custom Fields
export const SIGNATURE = 'SIGNATURE';
export const INITIAL_SIGNATURE = 'INITIAL_SIGNATURE';
export const UNVALIDATED_SIGNATURE = 'UNVALIDATED_SIGNATURE';
export const UNVALIDATED_INITIAL_SIGNATURE = 'UNVALIDATED_INITIAL_SIGNATURE';
export const DATE = 'DATE';
export const DATE_SIGNED = 'DATE_SIGNED';
export const NUMBER = 'NUMBER';
