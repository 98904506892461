import './shims';

// React
import React from 'react';
import ReactDOM from 'react-dom';

// NPM Providers
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Router } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { Provider as ReduxProvider } from 'react-redux';

// Custom Providers
import AuthenticationProvider from 'common/contextProviders/Authentication';
import FeatureFlagsProvider from 'common/components/FeatureFlagsProvider';
import { CookiesProvider } from 'react-cookie';

// Constants
import theme from 'common/shared/theme';
import apolloClient from 'common/apollo';

// Utilities
import store from 'common/store';
import history from 'common/constants/config/history';
import * as serviceWorker from './serviceWorker';
import {
  init as SentryInit,
  Integrations as SentryIntegrations,
} from '@sentry/browser';

// Css
import './index.css';

// Component
import App from './App';

const render = Component => {
  ReactDOM.render(
    <ReduxProvider store={store}>
      <CookiesProvider>
        <Router history={history}>
          <ApolloProvider client={apolloClient}>
            <AuthenticationProvider>
              <FeatureFlagsProvider>
                <MuiThemeProvider theme={theme}>
                  <Component />
                </MuiThemeProvider>
              </FeatureFlagsProvider>
            </AuthenticationProvider>
          </ApolloProvider>
        </Router>
      </CookiesProvider>
    </ReduxProvider>,
    document.getElementById('root'),
  );
};

SentryInit({
  dsn: 'https://c0197df51eea4a2a801306009015dc99@sentry.io/258319',
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: integrations =>
    integrations.map(integration => {
      if (integration.name !== 'Breadcrumbs') return integration;
      return new SentryIntegrations.Breadcrumbs({
        console: false,
        dom: true,
        xhr: true,
        fetch: true,
        beacon: true,
        history: true,
      });
    }),
});

// Turn off service worker
serviceWorker.unregister();

render(App);
