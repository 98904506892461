import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, Drawer, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing.unit * 2.2,
    background: theme.palette.bottomDialogBackground,
  },
  primaryButton: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  description: {
    marginRight: theme.spacing.unit * 1.5,
    color: theme.palette.primary,
    maxHeight: 75,
    overflow: 'auto',
  },
  header: {
    color: theme.palette.primary,
    textTransform: 'capitalize',
  },
}));

const DEFAULT_ACKNOWLEDGMENT_TITLE = 'Acknowledgement of Receipt';
const DEFAULT_ACKNOWLEDGMENT_DESCRIPTION =
  'I acknowledge receipt of, and agree to read and adhere to, the policies and procedures outlined in the document.';

const MobileClickThroughAcknowledgment = props => {
  const { isOpen, title, description, onAcknowledge } = props;

  const [isChecked, setIsChecked] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    setIsChecked(false);
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Drawer open={isOpen} anchor="bottom">
      <div className={classes.root}>
        <Typography
          variant="h5"
          gutterBottom
          className={classes.header}
          data-test-id="ClickThroughAcknowledgment-header"
        >
          {title || DEFAULT_ACKNOWLEDGMENT_TITLE}
        </Typography>
        <Typography component="p" className={classes.description}>
          <Checkbox
            onChange={e => setIsChecked(e.target.checked)}
            data-test-id="ClickThroughAcknowledgment-checkbox"
            checked={isChecked}
          />
          {description || DEFAULT_ACKNOWLEDGMENT_DESCRIPTION}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.primaryButton}
          onClick={onAcknowledge}
          disabled={!isChecked}
          data-test-id="ClickThroughAcknowledgment-submitButton"
        >
          Submit Acknowledgment
        </Button>
      </div>
    </Drawer>
  );
};

export default MobileClickThroughAcknowledgment;
