import React from 'react';
import Relay from 'common/oldJavascripts/utils/react/Relay.js';
import Link from 'common/oldJavascripts/components/Base/Link';
import List from 'common/oldJavascripts/components/Base/List';
import NavBar from 'common/oldJavascripts/components/Base/NavBar';
import Form from 'common/oldJavascripts/components/Shared/Form';
import Loader from 'common/components/Loader';
import T from 'common/oldJavascripts/utils/i18n';
import collection from 'common/oldJavascripts/utils/collection.js';

// Mui Components
import { Edit as EditIcon } from '@material-ui/icons';
import { Grid, Paper, ListItemSecondaryAction } from '@material-ui/core';

const Field = Form.Field;
const Button = Form.Button;

const FreeFieldItem = Relay.createClass({
  statics: {
    mutators: {
      freeFormField: {
        info: function(params, related) {
          const routerParams = related['/router/params'];

          return {
            id:
              '/projects/' +
              routerParams.projectId +
              '/free-form-fields/' +
              params.id,
          };
        },
      },
    },
    queries: {
      project: {
        info: function(_, related) {
          const routerParams = related['/router/params'];

          return {
            id: '/projects/' + routerParams.projectId,
          };
        },
      },
    },
  },

  getInitialState: function() {
    return {
      expanded: false,
      defaultValue: this.props.freeFormField.data.default_value || '',
      error: [],
    };
  },

  handleDefaultValueChange(value) {
    const { freeFormField } = this.props;
    const isValid = new RegExp('^[a-zA-Z0-9]*$').test(value);
    if (isValid) {
      freeFormField.update('default_value')(value);
      this.setState({
        error: [],
      });
    } else {
      this.setState({
        error: [
          'This account code field is now restricted to alpha numeric characters',
        ],
      });
    }
  },

  render: function() {
    const { freeFormField } = this.props;

    let label = null;
    let content = null;
    const { error } = this.state;

    const {
      data: { name: theName },
    } = freeFormField;
    const isDetailSubField = theName === 'detail/sub';

    // Check if code is for the insurance or ins
    // since we have to limit this fields character count to 2
    const isInsurance = theName === 'insurance' || theName === 'ins';

    // if its the insurance field, limit the max char length to 2; otherwise its 4
    const maxLength = isInsurance ? 2 : 4;

    if (this.state.expanded) {
      content = (
        <List.ItemContent>
          <Form
            action="edit"
            mutator={freeFormField}
            onSuccess={this._handleSuccess}
          >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <Field
                    mutator={freeFormField}
                    label={T('project.documents.free_form_fields.form.name')}
                    grouped={true}
                    type="text"
                    name="name"
                    readonly={true}
                    disabled={true}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <Field
                    mutator={freeFormField}
                    label={T(
                      'project.documents.free_form_fields.form.description',
                    )}
                    grouped={true}
                    type="text"
                    name="description"
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <Field
                    mutator={freeFormField}
                    type="checkbox"
                    label={T(
                      'project.documents.free_form_fields.form.allow_complete',
                    )}
                    name="allow_complete"
                    grouped={true}
                    inlineLabel={true}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  <Field
                    mutator={freeFormField}
                    type="checkbox"
                    label={T(
                      'project.documents.free_form_fields.form.required',
                    )}
                    name="required"
                    grouped={true}
                    inlineLabel={true}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0}>
                  {!isDetailSubField && (
                    <span
                      data-test-id={`FreeFormFields-defaultValue-${theName}`}
                    >
                      <Field
                        mutator={freeFormField}
                        label="Default Value"
                        id="default_value"
                        name="default_value"
                        type="text"
                        grouped
                        maxLength={maxLength}
                        inlineLabel
                        onChange={value => this.handleDefaultValueChange(value)}
                        errors={error}
                      />
                    </span>
                  )}
                </Paper>
              </Grid>
            </Grid>
            <NavBar>
              <NavBar.Item>
                <Button
                  mutator={freeFormField}
                  data-test-id={`FreeFormFields-update-${theName}`}
                >
                  {T('form.actions.update')}
                </Button>
              </NavBar.Item>
              <NavBar.Item>
                <Link onClick={this._collapseForm}>
                  {T('form.actions.cancel')}
                </Link>{' '}
                {T('form.actions.or')}
              </NavBar.Item>
            </NavBar>
          </Form>
        </List.ItemContent>
      );
    } else {
      label = freeFormField.data.name;
      if (freeFormField.data.description) {
        label += ' (' + freeFormField.data.description + ')';
      }
      content = (
        <List.ItemContent>
          <List.ItemContentBody title={freeFormField.data.name}>
            {label}
          </List.ItemContentBody>
          <ListItemSecondaryAction>
            <Link
              onClick={this._expandForm}
              icon={true}
              data-test-id={`FreeFormFields-editButton-${theName}`}
            >
              <EditIcon data-test-id={`AccountCodeFields-edit-${label}`} />
            </Link>
          </ListItemSecondaryAction>
        </List.ItemContent>
      );
    }

    return <List.Item>{content}</List.Item>;
  },

  _expandForm: function() {
    this.setState({ expanded: true });
  },

  _collapseForm: function() {
    const { freeFormField } = this.props;
    const { defaultValue } = this.state;
    freeFormField.update('default_value')(`${defaultValue}`.toUpperCase());
    this.setState({ expanded: false });
  },

  _handleSuccess: function() {
    const { freeFormField, updateDetailSubDataType } = this.props;
    const {
      data: { default_value, name, data_type: dataType },
    } = freeFormField;
    if (name === 'detail/sub') {
      updateDetailSubDataType(dataType);
    }
    this.setState({ expanded: false, defaultValue: default_value });
  },
});

export default Relay.createClass({
  statics: {
    queries: {
      freeFormFields: {
        info: function(_, related) {
          var params = related['/router/params'];

          return {
            id: '/projects/' + params.projectId + '/free-form-fields',
          };
        },
      },
    },
  },

  render: function() {
    const {
      freeFormFields,
      updateDetailSubDataType,
      updateEnforceMask,
      enforceMask,
      accountMask,
    } = this.props;

    if (freeFormFields.status === 'loading') {
      return <Loader />;
    }

    return (
      <List>
        {collection.map(freeFormFields.data.items, function(freeFormField) {
          return (
            <FreeFieldItem
              key={freeFormField.id}
              params={{ id: freeFormField.id }}
              updateDetailSubDataType={updateDetailSubDataType}
              enforceMask={enforceMask}
              accountMask={accountMask}
              updateEnforceMask={updateEnforceMask}
            />
          );
        })}
      </List>
    );
  },
});
