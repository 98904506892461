import React, { useState, useEffect } from 'react';
import withApi from 'common/hoc/withApi';
import withPermissionProtection from 'common/hoc/withPermissionProtection';
import { compose } from 'redux';
import BackButton from 'mobile/components/BackButton';
import {
  Button,
  Typography,
  Paper,
  InputBase,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import PreLoader from 'mobile/components/PreLoader';
import RejectOffer from './RejectOffer';
import { MOBILE_ROUTES } from 'mobile/constants';
import history from 'common/constants/config/history';
import { makeStyles } from '@material-ui/core/styles';
import OfferSlides from 'mobile/components/Projects/OfferSlides';
import {
  ChevronLeft as ChevronDown,
  Search as SearchIcon,
} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import qs from 'qs';
import debounce from 'lodash.debounce';
import YouRock from 'mobile/components/Projects/YouRock';
import { logout } from 'common/store/actions/authenticate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const { PROJECTS } = MOBILE_ROUTES;
const offerListStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#f7fcfc',
    paddingTop: '20px',
    paddingBottom: '120px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '40px',
  },
  pageTitle: {
    marginLeft: '20px',
    fontSize: 18,
    color: '#646464',
    fontWeight: 500,
  },
  noOffers: {
    marginTop: '20px',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 500,
  },
  offerBox: {
    minWidth: '300px',
    minHeight: '350px',
    boxShadow: '0 -1 1 rgba(0, 0, 0, 0.05), 0 2 4 rgba(0, 0, 0, 0.16)',
    borderRadius: 2,
    backgroundColor: '#ffffff',
    margin: '15px',
    padding: '15px',
  },
  rejectBtn: {
    fontSize: 14,
    fontWeight: 500,
    height: '40px',
    background: 'transparent !important',
    color: '#2eaba4',
    border: '1px solid #2eaba4',
    textTransform: 'none !important',
    minWidth: '91px',
    borderRadius: 2,
  },
  approveBtn: {
    background: '#2eaba4',
    height: '40px',
    color: 'white',
    marginLeft: '20px',
    minWidth: '91px',
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 500,
  },
  description: {
    color: '#646464',
    fontSize: 14,
    fontWeight: 400,
  },
  checkMarkIcon: {
    color: '#2ecc71',
    height: 24,
    width: 24,
  },
  eyeIcon: {
    color: '#aaaaaa',
    width: 25,
    height: 25,
  },
  nicework: {
    color: '#2eaba4',
    fontSize: '24px',
    fontWeight: 400,
    marginLeft: '30px',
  },
  niceworkMessage: {
    color: '#646464',
    fontSize: '15px',
    marginLeft: '30px',
  },
  viewAll: {
    color: '#0091ff',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
    marginTop: '20px',
  },
  bottomSection: {
    width: '100vw',
    height: '60px',
    boxShadow: '0 -4 10 rgba(0, 0, 0, 0.06)',
    backgroundColor: '#ffffff',
    paddingBottom: '30px',
    paddingLeft: '10px',
    paddingRight: '10px',
    position: 'fixed',
    left: 0,
    bottom: 0,
  },
  viewAllProjects: {
    color: '#646464',
    fontSize: '16px',
    fontWeight: 400,
    textDecoration: 'none',
  },
  logout: {
    color: '#2eaba4',
    fontSize: '16px',
    fontWeight: 400,
  },
  bottomBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: 3,
    marginTop: '20px',
    justifyContent: 'center',
  },
  fixedSearchBar: {
    position: 'fixed',
    backgroundColor: '#FFFFFF',
    marginTop: '0',
    top: '55px',
    width: '100%',
    margin: '0 auto',
    padding: '15px 0px 15px',
    boxShadow: '0 6px 4px -2px lightgrey',
  },
  normalSearchBar: {
    position: 'relative',
  },
  floatingSearchBox: {
    height: '60px',
    backgroundColor: '#ffffff',
  },
  searchForm: {
    backgroundColor: '#ffffff',
    borderRadius: 2,
    border: '1px solid #d4d4d4',
    boxShadow: 'none',
    width: '62%',
    paddingTop: '10px',
    paddingLeft: '5px',
  },
  searchIcon: {
    width: '30px',
    height: '30px',
    color: '#b0bec5',
  },
  sortIcon: {
    transform: 'rotate(-90deg)',
    color: '#2eaba4',
    position: 'absolute',
    left: '5.5rem',
    top: '0.7rem',
    width: '30px',
    height: '30px',
    backgroundColor: '#fff',
    pointerEvents: 'none',
  },
  sortFormControl: {
    marginLeft: '15px',
    backgroundColor: '#ffffff',
    width: '100px',
    height: '40px',
  },
  sortLabel: {
    paddingLeft: '12px',
    fontSize: '16px',
    marginTop: '-8px',
  },
  searchIconBtn: {
    top: '-5px',
    padding: 0,
  },
  searchinput: {
    marginLeft: '5px',
    '&::placeholder': {
      bottom: '20px',
      fontSize: '18px',
    },
  },
  pagination: {
    marginTop: '20px',
    marginBottom: '20px',
    color: '#0091ff',
    fontSize: '14px',
    textAlign: 'center',
  },
  niceWorkContainer: {
    marginTop: '60px',
  },
}));
const DEFAULT_PER_PAGE = 20;

const OfferList = props => {
  const classes = offerListStyles();
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState(false);
  const [sortText, setSortText] = useState();
  const [scrolling, setScrolling] = useState(false);
  const [offersToLoad, setOffersToLoad] = useState(DEFAULT_PER_PAGE);
  const [
    remainingPaginatedOfferCount,
    setRemainingPaginatedOfferCount,
  ] = useState(DEFAULT_PER_PAGE);

  const {
    approvals: {
      data: { items: approvals = [], total_items: totalItems = 0 } = {},
      status: approvalsStatus,
    } = {},
    routerParams: { projectId } = {},
    invalidateByPatterns,
    logout,
  } = props;

  const handleClose = () => {
    setIsRejectDialogOpen(false);
  };
  const isLoading = approvalsStatus === 'loading';
  const readyToSignOffers = approvals.filter(
    item => item.ready_to_sign !== false,
  );

  const totalOffers = readyToSignOffers.length;

  useEffect(() => {
    const handleScroll = debounce(() => {
      const verticalScrollPosition = window.pageYOffset;
      if (verticalScrollPosition <= 15) {
        setScrolling(false);
      } else if (verticalScrollPosition > 140) {
        setScrolling(true);
      }
    }, 100);
    const {
      approvals: {
        data: {
          total_pages: totalPages = 0,
          page = 0,
          total_items: totalItems = 0,
        } = {},
      } = {},
    } = props;

    if (page === totalPages - 1) {
      let nextOffersCount = totalItems - totalOffers;
      setOffersToLoad(offersToLoad => offersToLoad + nextOffersCount);
      setRemainingPaginatedOfferCount(nextOffersCount);
    } else if (page < totalPages) {
      setRemainingPaginatedOfferCount(DEFAULT_PER_PAGE);
      setOffersToLoad(offersToLoad => offersToLoad + DEFAULT_PER_PAGE);
    } else {
      setRemainingPaginatedOfferCount(0);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [props, totalOffers]);

  const { location: { state: { showNextOffer = false } = {} } = {} } = history;
  const isNextOffer = !isLoading && showNextOffer;
  const isNiceWork = !isLoading && isNextOffer && totalOffers > 0;
  const isYouRock = !isLoading && isNextOffer && totalOffers === 0;

  const nextOffer = () => {
    let arr = [];
    if (totalOffers > 0) {
      let [nextOffer] = readyToSignOffers;
      arr.push(nextOffer);
    }
    return arr;
  };
  const offers = isNiceWork ? nextOffer() : readyToSignOffers;
  const getNextOfferProjectName = () => {
    if (offers) {
      let [firstOffer] = offers;
      return firstOffer ? firstOffer.project_name : '';
    }
  };
  const handleRejectClick = offer => {
    history.push({ state: { projectId, offer } });
    setSelectedOfferId(offer.id);
    setIsRejectDialogOpen(true);
  };

  const handleApprovalClick = offer => {
    history.push({
      pathname: `/projects/${projectId}/approvals`,
      state: { projectId, offer },
    });
  };

  const handleSearch = event => {
    const { value } = event.target;
    const { location: { pathname } = {} } = history;
    history.push({
      pathname,
      search: qs.stringify({
        q: value,
        page: 1,
      }),
    });
  };

  const handleOfferSort = event => {
    const { value } = event.target;
    setSortText(value);
    let direction;
    let sort;
    if (value === 'oldest') {
      direction = 'asc';
      sort = 'start_date';
    } else if (value === 'newest') {
      direction = 'desc';
      sort = 'start_date';
    } else if (value === 'A-Z') {
      direction = 'asc';
      sort = 'last_name,first_name,middle_name';
    } else if (value === 'Z-A') {
      direction = 'desc';
      sort = 'last_name,first_name,middle_name';
    }
    const { location: { pathname } = {} } = history;
    history.push({
      pathname,
      search: qs.stringify({
        sort,
        direction,
      }),
    });
  };

  const noOffers = !isLoading && totalOffers === 0 && !isYouRock;

  const searchBar = scrolling
    ? classes.fixedSearchBar
    : classes.normalSearchBar;

  const paginateOffers = () => {
    const { location: { pathname } = {} } = history;
    history.push({
      pathname,
      search: qs.stringify({
        sort: 'start_date',
        direction: 'desc',
        per_page: offersToLoad,
      }),
    });
  };

  if (isYouRock) {
    return <YouRock />;
  }

  return (
    <div className={classes.root}>
      {!isNiceWork && (
        <BackButton
          title="Back to Projects"
          url="/projects"
          isExternal={false}
        />
      )}
      {!isNiceWork && !isLoading && totalItems > 0 && (
        <Typography variant="h4" className={classes.pageTitle}>
          Total Offers: {totalItems}
        </Typography>
      )}
      {!isNiceWork && (
        <div className={[classes.searchContainer, searchBar].join(' ')}>
          <Paper className={classes.searchForm}>
            <IconButton
              type="submit"
              aria-label="search"
              className={classes.searchIconBtn}
            >
              <SearchIcon className={classes.searchIcon} />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="Search"
              inputProps={{
                'aria-label': 'Search',
                className: classes.searchinput,
              }}
              onBlur={e => handleSearch(e)}
            />
          </Paper>
          <FormControl className={classes.sortFormControl}>
            <InputLabel
              className={classes.sortLabel}
              disableAnimation={false}
              htmlFor="searchCriteria"
            >
              Sort
            </InputLabel>
            <Select
              id="offerlist-select"
              value={sortText}
              onChange={e => handleOfferSort(e)}
            >
              <MenuItem value="newest">Newest</MenuItem>
              <MenuItem value="oldest">Oldest</MenuItem>
              <MenuItem value="A-Z">A-Z</MenuItem>
              <MenuItem value="Z-A">Z-A</MenuItem>
            </Select>
            <ChevronDown className={classes.sortIcon} />
          </FormControl>
        </div>
      )}
      {isLoading && <PreLoader />}
      {!isLoading && noOffers && (
        <Typography className={classes.noOffers}>No Offers</Typography>
      )}
      {totalOffers > 0 && isNiceWork && (
        <React.Fragment>
          <Typography className={classes.nicework}>Nice Work!</Typography>
          <Typography className={classes.niceworkMessage}>
            {' '}
            Here is your next offer for {getNextOfferProjectName()}.
          </Typography>
        </React.Fragment>
      )}
      {offers.length > 0 &&
        offers.map(offer => (
          <Paper
            className={classes.offerBox}
            data-test-id={`OffersListCard-${offer.id}`}
            key={offer.id}
          >
            <OfferSlides offer={offer} />
            <div className={classes.btnContainer}>
              <Button
                className={classes.rejectBtn}
                onClick={() => handleRejectClick(offer)}
                data-test-id={`OffersListCard-rejectButton-${offer.id}`}
              >
                Reject
              </Button>
              <Button
                className={classes.approveBtn}
                data-test-id={`OffersListCard-approveButton-${offer.id}`}
                onClick={() => handleApprovalClick(offer)}
              >
                Approve
              </Button>
            </div>
          </Paper>
        ))}
      {!isNiceWork && !isLoading && remainingPaginatedOfferCount > 0 && (
        <Typography
          onClick={() => paginateOffers()}
          className={classes.pagination}
        >
          View next {remainingPaginatedOfferCount} offer(s)
        </Typography>
      )}
      {isRejectDialogOpen && (
        <RejectOffer
          open={isRejectDialogOpen}
          handleClose={handleClose}
          projectId={projectId}
          selectedOfferId={selectedOfferId}
          invalidateByPatterns={invalidateByPatterns}
        />
      )}
      {totalItems > 0 && isNiceWork && (
        <div className={classes.niceWorkContainer}>
          {totalItems > 1 && (
            <Typography
              className={classes.viewAll}
              onClick={() => {
                const offersRoute = `/projects/${projectId}/offers`;
                history.replace(offersRoute, {
                  state: { showNextOffer: false },
                });
              }}
            >
              View all {totalItems} offer(s) for this project
            </Typography>
          )}
          <div className={classes.bottomSection}>
            <div className={classes.bottomBtnContainer}>
              <Typography className={classes.logout} onClick={logout}>
                Logout
              </Typography>
              <Link to={PROJECTS} className={classes.viewAllProjects}>
                View All Projects
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

OfferList.queries = {
  approvals: {
    info(params, related) {
      const routerParams = related['/router/params'];
      const query = related['/router/query'];
      return {
        cursor: {
          direction: query.direction || 'desc',
          page: query.page,
          per_page: query.per_page || DEFAULT_PER_PAGE,
          q: query.q,
          sort: query.sort || 'start_date',
          status: query.status,
          t: query.t || '',
        },
        id: `/v2/projects/${routerParams.projectId}/approvals`,
      };
    },
  },
  project: {
    info: (_, related) => {
      const params = related['/router/params'];

      return {
        id: '/projects/' + params.projectId,
      };
    },
  },
};

const mapDispatchToProps = {
  logout,
};

export default compose(
  withPermissionProtection('can_approve_offers'),
  withApi,
  connect(null, mapDispatchToProps),
)(OfferList);
