import React from 'react';
import { Route } from 'react-router-dom';

// Components
import BaseLayout from 'common/oldJavascripts/components/Pages/Layouts/Base';
import ToolsTab from 'admin/containers/Tools/ToolsTab';

const GlobalPermissions = props => (
  <BaseLayout>
    <Route exact path="/tools" component={ToolsTab} />
  </BaseLayout>
);

export default GlobalPermissions;
