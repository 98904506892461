export default {
  login: {
    not_registered: 'Not a user yet?',
    sign_up: 'Join Now',
    form: {
      email: 'Email',
      password: 'Password',
      remember_me: 'Keep me logged in',
      forgot_password: 'Forgot Password?',
      submit: 'Log In',
    },
  },
  forgot_password: {
    form: {
      email: 'Email',
      submit: 'Reset Password',
    },
  },
  sign_up: {
    notifications: {
      email_taken: {
        message: 'The email has already been registered by another user,',
        sign_in: 'Sign In',
      },
    },
    form: {
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email Address',
      phone: 'Phone',
      password: 'New Password',
      confirm_password: 'Confirm Password',
      submit: 'Register',
    },
  },
  invitation: {
    form: {
      accept: 'Accept Invitation',
    },
    notifications: {
      token_error: 'The token is invalid or expired.',
    },
  },
  reset: {
    form: {
      new_password: 'New Password',
      confirm_password: 'Confirm Password',
      submit: 'Reset',
    },
  },
  dashboard: {
    header: {
      projects: 'Projects',
      users: 'Users',
      logout: 'Log Out',
      progress_step: 'Step',
      contact_hiring_manager: 'Contact Hiring Manager',
    },
  },
  projects: {
    title: 'Projects',
    not_found: 'No projects found',
    columns: {
      name: 'Name',
      region: 'Region',
      production_company: 'Production Company',
      producer: 'Producer',
    },
    item: {
      start_date: 'Start Date',
      employees: {
        zero: 'No employees',
        one: '1 Employee',
        other: '%{count} Employees',
      },
    },
  },
  users: {
    title: 'Users',
    new: 'Invite/Add Non-production employee',
    not_found: 'No users found.',
    edit: 'Edit',
    delete: 'Edit',
    delete_confirm: 'Are you sure you want to delete %{label}?',
    delete_failure: 'Deletion failed, this user has pending offers.',
    delete_head_of: 'the head of %{label}',
    search: 'Search',
    search_placeholder: 'Search...',
    columns: {
      employee: 'Employee',
      email: 'Email',
      hirer: 'Hiring Manager',
      reviewer: 'Reviewer',
      approver: 'Approver',
      verifier: 'I-9 verifier',
      archiver: 'Archives',
      documents_viewer: 'Document viewer',
      role: 'Role',
      actions: 'Actions',
    },
    roles: {
      employee: 'Employee',
      non_employee: 'Non-employee',
    },
  },
  project_crew: {
    create_offer: 'Create offer',
    title: 'Crew list',
    without_offers: 'Crew',
    not_found: 'No crew members',
    offers: {
      zero: 'None',
      one: '%{count} offer',
      other: '%{count} offers',
    },
    columns: {
      allowances: 'Allowances / Reimbursements',
      employee: 'Employee',
      email: 'Email',
      department: 'Department',
      occupation: 'Position',
      union: 'Union',
      start_date: 'Start Date',
      rate: 'Rate/Scale',
      scale: 'Scale',
      box: 'Box',
      cell: 'Cell',
      car: 'Car',
      computer: 'Computer',
      date_sent: 'Date Sent',
      status: 'Status',
      actions: 'Actions',
      no_offer: 'Offer not sent yet',
      name: 'Name',
      account_code: 'Account Code',
      per: 'Per',
      offers: 'Offers',
    },
    form: {
      first_name: 'First Name',
      middle_name: 'Middle Name',
      last_name: 'Last Name',
      ssn: 'Social Security #',
      email: 'Email',
      bulk_file: 'File',
    },
    new: {
      title: 'Add crew member',
      submit: 'Add crew member',
    },
    status: {
      no_offer: 'No offer',
      incomplete: 'Incomplete',
    },
    rates_table: {
      rate_per_hour: 'Rate per hour',
      rate_per_week: 'Rate per week',
      guaranteed_hours: 'Guaranteed hours',
      studio: 'Studio',
      distant: 'Distant',
      pay_7th_day_at: 'Pay 7th day at',
      pay_6th_day_at: 'Pay 6th day at',
    },
    offer_historical: {
      title: 'Offer historical information',
      sent: 'Sent to employee',
      approved: 'Approved',
      empty: 'No history available',
    },
  },
  documents: {
    edit: {
      groups: {
        employee: 'Filled in by the employee',
        automatic: 'Filled in automatically',
        union_terms: 'Union Terms of Employment',
        non_union_terms: 'Non Union Terms of Employment',
        free_form_fields: 'Account Code Fields',
        custom: 'Custom Fields',
        manual: 'Filled manually when signing document',
        i9_verifier: 'I-9 Verifier',
        w4: 'W-4 Fields',
        hiring_manager: 'Hiring Manager',
        loan_out: 'Loan-Out Corporations',
        reimbursements: 'Allowances / Reimbursements',
        emergency_contact: 'Employee Emergency Contact',
      },
      approvers: {
        title: "Approvers' Signatures",
        empty: 'Without approvers',
        level: 'Approver Level %{level}',
        level_date: 'Approver Level %{level} Date',
        approval_signature: 'Signature Level %{level}',
        approval_date: 'Sign Date Level %{level}',
        approval_first_name: 'First Name Level %{level}',
        approval_last_name: 'Last Name Level %{level}',
        approval_full_name: 'Full Name Level %{level}',
      },
      page_label: 'Page %{page_number}',
    },
    fields: {
      signature: 'Signature',
      initial: 'Initial',
      date_sign: 'Date sign',
      text_field: 'Text field',
      checkbox: 'Checkbox',
      radio_button: 'Radio button',
    },
    field_types: {
      first_name: 'First name',
      middle_name: 'Middle name',
      middle_initial: 'Middle initial',
      last_name: 'Last name',
      full_name: 'Full name',
      gender: 'Gender',
      email: 'Mail',
      phone: 'Phone',
      birth_date: 'Birth date',
      w2_address_street: 'W-2 address street',
      w2_address_suite: 'W-2 address suite',
      w2_address_state: 'W-2 address state',
      w2_address_city: 'W-2 address city',
      w2_address_zip_code: 'W-2 address zip code',
      w4_single_checkbox: 'W-4 Single',
      w4_married_checkbox: 'W-4 Married',
      w4_married_but_higher_rate_checkbox: 'W-4 Married but higher withhold',
      w4_total_allowances: 'W-4 Total Allowances / Reimbursements',
      w4_additional_withhold: 'W-4 Additional amt withheld',
      w4_exempt: 'Exempt',
      primary_address_street: 'Primary address street',
      primary_address_suite: 'Primary address suite',
      primary_address_state: 'Primary address state',
      primary_address_city: 'Primary address city',
      primary_address_zip_code: 'Primary address zip code',
      mailing_address_street: 'Mailing address street',
      mailing_address_suite: 'Mailing address suite',
      mailing_address_state: 'Mailing address state',
      mailing_address_city: 'Mailing address city',
      mailing_address_zip_code: 'Mailing address zip code',
      loan_out_address_street: 'Loan out address street',
      loan_out_address_suite: 'Loan out address suite',
      loan_out_address_state: 'Loan out address state',
      loan_out_address_city: 'Loan out address city',
      loan_out_address_zip_code: 'Loan out address zip code',
      agent_address_street: 'Agent address street',
      agent_address_suite: 'Agent address suite',
      agent_address_state: 'Agent address state',
      agent_address_city: 'Agent address city',
      agent_address_zip_code: 'Agent address zip code',
      ethnic_code: 'Ethnic code',
      emergency_name: 'Emergency name',
      emergency_phone: 'Emergency phone',
      signature: 'Signature',
      signature_date: 'Signature date',
      alternate_id: 'Alternate ID',
      alternate_id_last_four: 'Alternate ID last four',
      ssn: 'SSN',
      ssn_last_four: 'SSN last four',
      hiring_manager_first_name: 'Hiring Manager First Name',
      hiring_manager_last_name: 'Hiring Manager Last Name',
      hiring_manager_full_name: 'Hiring Manager Full Name',
      union: 'Union',
      contract: 'Contract',
      occupational_code: 'Occupational code',
      schedule: 'Schedule',
      department: 'Department',
      start_date: 'Start date',
      anticipated_end_date: 'Anticipated end date',
      hire_state: 'Hire state',
      work_state: 'Work state',
      hiring_status: 'Hiring status',
      account_code: 'Account code',
      rate_per_hour_studio: 'Rate per hour studio',
      rate_per_hour_location: 'Rate per hour location',
      rate_per_week_studio: 'Rate per week studio',
      rate_per_week_location: 'Rate per week location',
      guaranteed_hours_studio: 'Guaranteed hours studio',
      guaranteed_hours_location: 'Guaranteed hours location',
      pay_rate_6th_location: 'Pay rate 6th location',
      pay_rate_7th_location: 'Pay rate 7th location',
      overtime_rate: 'Overtime Rate',
      box_rental: 'Box Rental Amount',
      box_rental_amount: 'Box Rental Amount',
      box_rental_cap: 'Box Rental Cap',
      box_rental_per: 'Box Rental Per',
      box_rental_duration: 'Box Rental Duration',
      computer_rental: 'Computer Rental Amount',
      computer_rental_amount: 'Computer Rental Amount',
      computer_rental_cap: 'Computer Rental Cap',
      computer_rental_per: 'Computer Rental Per',
      computer_rental_duration: 'Computer Rental Duration',
      car_allowance: 'Car Allowance Amount',
      car_allowance_amount: 'Car Allowance Amount',
      car_allowance_cap: 'Car Allowance Cap',
      car_allowance_per: 'Car Allownce Per Per',
      car_allowance_duration: 'Car Allowance Duration',
      mobile_phone: 'Mobile Phone Amount',
      mobile_phone_amount: 'Mobile Phone Amount',
      mobile_phone_cap: 'Mobile Phone Cap',
      mobile_phone_per: 'Mobile Phone Per',
      mobile_phone_duration: 'Mobile Phone Duration',
      per_diem_allowance: 'Per Diem Amount',
      per_diem_allowance_amount: 'Per Diem Allowance Amount',
      per_diem_allowance_cap: 'Per Diem Cap',
      per_diem_allowance_per: 'Per Diem Per',
      per_diem_allowance_duration: 'Per Diem Duration',
      housing_allowance: 'Housing Allowance Amount',
      housing_allowance_amount: 'Housing Allowance Amount',
      housing_allowance_cap: 'Housing Allowance Cap',
      housing_allowance_per: 'Housing Allowance Per',
      housing_allowance_duration: 'Housing Allowance Duration',
      deal_notes: 'Deal notes',
      project: 'Project name',
      production_company: 'Production company name',
      free_field_1: 'Free field 1',
      free_field_2: 'Free field 2',
      free_field_3: 'Free field 3',
      free_field_4: 'Free field 4',
      free_field_5: 'Free field 5',
      emergency_contact_name: 'Emergency Name',
      emergency_contact_relationship: 'Emergency Relationship',
      emergency_contact_phone_number: 'Emergency Phone Number',
      emergency_contact_phone_type: 'Emergency Phone Type',
      manual_textbox: 'Manual textbox %{index}',
      manual_optional_textbox: 'Manual optional textbox %{index}',
      manual_checkbox: 'Manual checkbox %{index}',
      verifier_document_title_1: 'Document Title 1',
      verifier_document_title_2: 'Document Title 2',
      verifier_document_title_3: 'Document Title 3',
      verifier_document_title_4: 'Document Title 4',
      verifier_document_title_5: 'Document Title 5',
      verifier_issuing_authority_1: 'Issuing Authority 1',
      verifier_issuing_authority_2: 'Issuing Authority 2',
      verifier_issuing_authority_3: 'Issuing Authority 3',
      verifier_issuing_authority_4: 'Issuing Authority 4',
      verifier_issuing_authority_5: 'Issuing Authority 5',
      verifier_document_number_1: 'Document Number 1',
      verifier_document_number_2: 'Document Number 2',
      verifier_document_number_3: 'Document Number 3',
      verifier_document_number_4: 'Document Number 4',
      verifier_document_number_5: 'Document Number 5',
      verifier_expiration_date_1: 'Expiration Date 1',
      verifier_expiration_date_2: 'Expiration Date 2',
      verifier_expiration_date_3: 'Expiration Date 3',
      verifier_expiration_date_4: 'Expiration Date 4',
      verifier_expiration_date_5: 'Expiration Date 5',
      verifier_date: 'Date',
      verifier_title: 'Title',
      verifier_last_name: 'Last Name',
      verifier_first_name: 'First Name',
      verifier_organization: 'Organization',
      verifier_organization_address: 'Organization Address',
      verifier_city: 'City',
      verifier_state: 'State',
      verifier_zip_code: 'Zip Code',
      verifier_signature: 'Verifier Signature',
      corporation_name: 'Corporation Name',
      federal_tax_id: 'Federal TAX ID',
      corporation_address_1: 'Corporation Address 1',
      corporation_address_2: 'Corporation Address 2',
      corporation_city: 'Corporation City',
      corporation_state: 'Corporation State',
      corporation_zipcode: 'Corporation Zipcode',
      date_of_incorporation: 'Date of Incorporation',
      state_of_incorporation: 'State of Incorporation',
      state_corporate_number: 'State Corporate Number',
      type_individual_sole_checkbox: 'Type - Invividual/Sole',
      type_c_corp_checkbox: 'Type - C Corporation',
      type_s_corp_checkbox: 'Type - S Corporation',
      type_partnership_checkbox: 'Type - Partnership',
      type_trust_estate_checkbox: 'Type - Trust/estate',
      type_llc_checkbox: 'Type - Limited liability company',
      type_llc_classification: 'Type - LLC Classification',
      type_other_checkbox: 'Type - Other',
      hourly_rate: 'Hourly Rate',
      daily_rate: 'Daily Rate',
      weekly_rate: 'Weekly Rate',
      weekly_days: 'Weekly Days',
      daily_hours: 'Daily Hours',
    },
  },
  archives: {
    manual: {
      title: 'Manual Archived Offers',
      header: {
        name: 'Name',
        position: 'Position',
        sentDate: 'Sent Date',
        approvedDate: 'Approved Date',
        actions: 'Actions',
      },
    },
    title: 'Archived Offers',
    not_found: 'No archives found.',
    columns: {
      name: 'Name',
      position: 'Position',
      sent_date: 'Sent Date',
      approved_date: 'Approved Date',
      actions: 'Actions',
    },
    actions: {
      view_packets: 'View Packets',
    },
    new: {
      action: 'Add New',
      title: 'Add New Archived Offer',
      first_name: 'First name',
      last_name: 'Last name',
      position: 'Position',
      sent_date: 'Sent date',
      approved_date: 'Approved date',
      file: 'Select file to upload',
      submit_button: 'Add',
    },
    details: {
      back: 'Back',
      title: 'Archived Offer Information',
      fields: {
        position: 'Position',
        sent_date: 'Sent Date',
        approved_date: 'Approved date',
      },
      files: {
        title: 'Documents uploaded by Hiring Manager',
      },
      actions: {
        download: 'download',
      },
    },
  },
  audit_logs: {
    title: 'Audit Log',
    not_found: 'No activities found.',
    columns: {
      activity: 'Activity',
    },
    messages: {
      create_custom_field: 'created custom field %{custom_field}',
      edit_custom_field: 'edited custom field %{custom_field}',
      remove_custom_field: 'removed custom field %{custom_field}',
      create_department: 'added department %{department}',
      rename_department: 'renamed department %{previous_name} to %{new_name}',
      remove_department: 'removed department %{department}',
      remove_department_head:
        'removed department head %{head_first_name} %{head_last_name} from department %{department}',
      add_department_head:
        'added department head %{head_first_name} %{head_last_name} to department %{department}',
      add_hirer: 'added hiring manager %{first_name} %{last_name}',
      add_project_document:
        'added new document %{document_name} by %{first_name} %{last_name}',
      update_project_document:
        'updated document %{document_name} by %{first_name} %{last_name}',
      update_project_status: 'updated project status from %{from} to %{to}',
      update_document_assignment:
        'updated document auto assignments %{document_name} by %{first_name} %{last_name}',
      delete_project_document:
        'deleted document %{document_name} by %{first_name} %{last_name}',
      remove_hirer: 'removed hiring manager %{first_name} %{last_name}',
      add_verifier: 'added verifier %{first_name} %{last_name} to I-9',
      remove_verifier: 'removed verifier %{first_name} %{last_name} from I-9',
      add_approver:
        'added %{first_name} %{last_name} approver to packet %{packet}',
      add_head_approver: 'added department head approver to packet %{packet}',
      add_approve_notification:
        'added notification to Cast & Crew to packet %{packet}',
      remove_approver:
        'removed %{first_name} %{last_name} approver from packet %{packet}',
      remove_head_approver:
        'removed department head approver from packet %{packet}',
      remove_approve_notification:
        'removed notification to Cast & Crew from packet %{packet}',
      create_packet: 'created document packet %{packet}',
      remove_packet: 'removed document packet %{packet}',
      create_document: 'created document %{document}',
      modify_document: 'modified document %{document}',
      add_packet_document: 'added %{document} document to packet %{packet}',
      remove_packet_document:
        'removed %{document} document from packet %{packet}',
      send_invite: 'sent invitation email for %{first_name} %{last_name}',
      add_non_employee: 'added Non Employee %{first_name} %{last_name}',
      updated_non_employee: 'updated Non Employee %{first_name} %{last_name}',
      remove_non_employee: 'removed Non Employee %{first_name} %{last_name}',
      project_go_live:
        'migrated project status to Live, removing %{total_users} Users, and %{total_offers} Offers.',
      project_go_live_fail:
        'attempted to migrate project status to Live but an error occurred: %{error} %{error_trace}',
      merge_crew_accounts: 'merged accounts: %{message}',
      updated_supporting_document_box_checked:
        'updated document %{document_name} "include in final offer PDF" checkbox was checked by %{first_name} %{last_name}',
      updated_supporting_document_box_unchecked:
        'updated document %{document_name} "include in final offer PDF" checkbox was un-checked by %{first_name} %{last_name}',
      workflow_save_success:
        'updated %{workflows_count} workflows in %{document_name} by %{first_name} %{last_name}',
      project_clone: 'cloned project %{project_name}',
    },
  },
  errors: {
    cannot_be_admin: 'Cannot add Project admin as crew member.',
    cannot_be_crew: 'Cannot add Crew member as Project admin.',
    none_selected: 'Please select at least one.',
    too_many_selected: 'Please select max 50 Crew members.',
    already_exists: 'The account already exists.',
    blank: "Can't be blank.",
    invalid: 'Is invalid.',
    too_short: 'Is too short.',
    taken: 'Is taken',
    not_included: 'Is invalid',
    invalid_import_email_column: "File imported doesn't have an email column",
    invalid_import_email: 'File imported has invalid emails addresses',
    invalid_import_file: 'File imported is invalid',
    file_too_big: 'The file size is too large',
    invalid_credentials: 'Email/password combination not found',
    new_passwords_not_matched: 'New Password and Confirm Password do not match',
    different_password:
      'New password must be different than the last 5 passwords',
    account_locked: 'Account is locked.',
    not_unique: 'Must be unique',
    invalid_alternate_id:
      'Invalid format. Only digits and dashes up to 11 characters',
    invalid_file_format:
      'The file type you are trying to upload can’t be imported, please try uploading a file that matches the type required above.',
    unsafe_file: 'Uploaded file contains Malware',
    set_account_codes_required:
      'Required Account Code Fields must be completed',
    custom_fields_required: 'Required Custom Fields must be completed.',
    additional_field_required: 'Additional Field Required',
    primary_w2_required: 'Primary and W-2 addresses are required.',
    primary_required: 'Primary address is required.',
    w2_required: 'W-2 address is required.',
    greater_than: 'Value is not within the valid range',
    less_than: 'Value is not within the valid range',
    corporation_not_found: 'Tax ID not found',
    alternate_addresses_exceeds_max:
      'You have exceeded the allowed amount of mailing addresses',
    invalid_federal_tax_id: 'Insert a Tax ID with the format XX-XXXXXXX',
    invalid_account: "The account doesn't exist.",
    duplicated: 'This user already exists.',
    'box_rental_amount must be less than or equal to 999999': {
      '9999': 'Box Rental Amount must be less than $1,000,000.',
    },
    'box_rental_cap must be less than or equal to 999999': {
      '9999': 'Box Rental Cap must be less than $1,000,000.',
    },
    'computer_rental_amount must be less than or equal to 999999': {
      '9999': 'Computer Rental Amount must be less than $1,000,000.',
    },
    'computer_rental_cap must be less than or equal to 999999': {
      '9999': 'Computer Rental Cap must be less than $1,000,000.',
    },
    'car_allowance_amount must be less than or equal to 999999': {
      '9999': 'Car Allowance Amount must be less than $1,000,000.',
    },
    'car_allowance_cap must be less than or equal to 999999': {
      '9999': 'Car Allowance Cap must be less than $1,000,000.',
    },
    'mobile_phone_amount must be less than or equal to 999999': {
      '9999': 'Mobile Phone Amount must be less than $1,000,000.',
    },
    'mobile_phone_cap must be less than or equal to 999999': {
      '9999': 'Mobile Phone Cap must be less than $1,000,000.',
    },
    'per_diem_allowance_amount must be less than or equal to 999999': {
      '9999': 'Per Diem Allowance Amount must be less than $1,000,000.',
    },
    'per_diem_allowance_cap must be less than or equal to 999999': {
      '9999': 'Per Diem Allowance Cap must be less than $1,000,000.',
    },
    'housing_allowance_amount must be less than or equal to 999999': {
      '9999': 'Housing Allowance Amount must be less than $1,000,000.',
    },
    'housing_allowance_cap must be less than or equal to 999999': {
      '9999': 'Housing Allowance Cap must be less than $1,000,000.',
    },
  },
  'box_rental_duration must be less than or equal to 999':
    'Box Rental Duration must be less than 1000.',
  'computer_rental_duration must be less than or equal to 999':
    'Computer Rental Duration must be less than 1000.',
  'car_allowance_duration must be less than or equal to 999':
    'Car Allowance Duration must be less than 1000.',
  'mobile_phone_duration must be less than or equal to 999':
    'Mobile Phone Duration must be less than 1000.',
  'per_diem_allowance_duration must be less than or equal to 999':
    'Per Diem Allowance Duration must be less than 1000.',
  'housing_allowance_duration must be less than or equal to 999':
    'Housing Allowance Duration must be less than 1000.',
  images: {
    logo: 'Cast and Crew Logo',
  },
  emails: {
    title: 'Cast & Crew',
    forgot_password: {
      subject: 'Reset your password',
    },
    invitation: {
      subject: 'Review invitation to project',
    },
    offer_invitation: {
      subject: '%{hirer_name} sent a job offer for %{project_name}',
    },
    email_digest: {
      subject: 'Daily Update',
    },
    offer_file_download: {
      subject: 'Generated file is ready',
    },
    offer_file_download_no_results: {
      subject: 'Offer documents search returned no results',
    },
    hirer_notify_rejection: {
      subject: 'An offer has been declined for %{project_name}',
    },
    sidekiq_job_failed: {
      subject: '[%{environment}] Background job failed.',
    },
  },
  docusign: {
    subject_line: 'Sign %{document_name}',
    body_line: 'Sign document %{document_name}',
    step: {
      preload: "We're retrieving your document",
      loading: "We're preparing your document",
      saving: "We're saving your document",
      done:
        "Your document has been submitted, we're preparing your next document now",
    },
  },
  components: {
    uploader: {
      browse_file: 'Browse a file from your computer',
      drag_file: 'Drag & drop a file here or',
      take_snapshot: 'Take a snapshot',
      or: 'or',
    },
  },
  work_schedules: {
    a: 'Hourly/Daily',
    b: 'Weekly',
    c: 'Exempt',
  },
  offer: {
    form: {
      project_name: 'Project Name',
      hirer_contact_information: 'Hirer Contact Information',
      hirer: 'Hirer',
      job_description: 'Job Description',
      account_major_minor: 'Account Major / Minor',
      aca_hiring_status: 'ACA Hiring Status',
      start_date: 'Start Date',
      expected_end_date: 'Expected End Date',
      continue: 'Continue',
      terms: 'Terms of Employment',
      studio: 'Studio',
      location: 'Distant',
      rate_hour: 'Rate Per Hour',
      rate_week: 'Rate Per Week',
      guaranteed_hours: 'Guaranteed Hours',
      pay_6th: 'PAY 6th DAY AT',
      pay_7th: 'PAY 7th DAY AT',
      amount: 'Amount',
      per: 'Per',
      cap: 'Cap',
      duration: 'Duration',
    },
    invitation: {
      welcome: 'Welcome to Start+',
      title: "Let's get going!",
      description:
        'Please set up your Start+ Password. Once your password is set you will be able to complete your Start Packet.',
      username: 'Your user name is %{email}.',
      form: {
        password: 'New Password',
        password_confirm: 'Confirm Password',
        button_label: 'Set Password',
      },
    },
  },
  invitations: {
    title: 'Invitations',
    not_found: 'No invitations found.',
    new: {
      title: 'Profile information',
      submit: 'Send invite',
      success: 'Invitation sent.',
    },
    form: {
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email',
      phone: 'Phone',
      title: 'Title',
    },
    columns: {
      name: 'Name',
      phone: 'Phone',
      email: 'Email',
      hirer: 'Hirer',
      reviewer: 'Reviewer',
      approver: 'Approver',
      verifier: 'I-9 verifier',
      archiver: 'Archiver',
      documents_viewer: 'Document viewer',
      role: 'Role',
    },
    roles: {
      non_employee: 'Non-employee',
    },
  },
  ethnic: {
    white: 'White / Caucasian',
    spanish: 'Spanish / Hispanic / Latino',
    american_indian: 'American Indian',
    african_american: 'African American',
    asian: 'Asian',
    other: 'Other',
  },
  edit_non_employee: {
    general_information: 'General Information',
    manage_user: 'Manage User',
    general: {
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email',
      title: 'Title',
      phone: 'Phone',
    },
    submit: 'Save Changes',
  },
  employees: {
    manage_user: 'Manage User',
  },
  profile: {
    general_information: {
      title: 'General Information',
      first_name: 'First Name',
      middle_name: 'Middle name',
      last_name: 'Last Name',
      gender: 'Gender',
      gender_options: {
        male: 'Male',
        female: 'Female',
        other: 'Other',
      },
      email: 'Email',
      phone: 'Phone',
      phone_type: 'Phone Type',
      phone_type_options: {
        mobile: 'Mobile',
        work: 'Work',
        home: 'Home',
      },
      birth_date: 'Date of Birth',
      ssn: 'Social Security #',
      ssn_note:
        'Note: Your Social Security Number will still appear on all government forms such as I-9 and W-4.',
      alternate_id_mask: 'Alternate Employee ID',
      alternate_id_description:
        'If you enter an alternate employee ID (which was assigned to you by your employer), this identification number will appear on all paperwork in place of your social security number.',
      dob: {
        title: 'Date of Birth',
        day: 'Day',
        month: 'Month',
        year: 'Year',
      },
    },
    addresses: {
      title: 'Addresses',
      default_description_first: 'New Address',
      default_description_plural: 'New Address %{index}',
      delete_confirm: 'Are you sure you want to delete address %{label}?',
      delete_failure: 'The address could not be deleted',
      address_required:
        'The W-2 and primary address are required to be selected',
    },
    payroll: {
      title: 'Payroll Information',
      ach: {
        title: 'Banking Information',
        description: 'Filling in Banking Information is optional.',
        routing_number: 'Routing Number',
      },
      employee_data: {
        title: 'Employee Data',
        ethnic_code: 'Ethnic Code (Optional)',
      },
    },
    emergency_contact: {
      title: 'Emergency Contact',
      name: 'Name',
      relationship: 'Relationship',
      phone: 'Phone',
      phone_type: 'Phone Type',
    },
    w4: {
      title: 'W-4 Tax withholding information',
      marital_status: {
        title: 'Marital Status',
        single: 'Single',
        married: 'Married',
        married_special: 'Married, but withhold at higher Single rate',
        note:
          "Note: if married, but legally separated, or spouse is a nonresident alien, check 'Single'",
      },
      allowances:
        'Total number of allowances / reimbursements you are claiming',
      additional_amount:
        'Additional amount, if any, you want withheld from each paycheck',
      exempt: {
        title: 'Exempt',
        exemptions: {
          title:
            'I claim exemption from withholding for 2016, and I certify that I meet both of the following conditions for exemption.',
          first:
            'Last year I had a right to a refund of all federal income tax withheld because I had no tax liability, and',
          second:
            'This year I expect a refund of all federal income tax withheld because I expect to have no tax liability.',
        },
        accept:
          "If you meet both conditions, write 'Exempt' in the field below.",
      },
      full_w4_link: 'View full version of W-4 Form',
    },
    address: {
      type: 'Type',
      title: 'Address',
      types: {
        primary: 'Primary',
        mailing: 'Mailing',
        loan_out: 'Loan-out',
        agent: 'Agent',
        w2: 'W-2',
      },
      add: 'Add New Address',
      save: 'Save Address',
      delete: 'Delete Address',
      suite: 'Apartment or Suite No.',
      city: 'City',
      state: 'State',
      zip: 'Zip Code',
      description: 'Description',
      new_address: 'New Address',
    },
    submit: 'Save Changes',
  },
  account: {
    profile: {
      title: 'Profile Information',
    },
    navbar: {
      profile: 'Profile Details',
    },
    notifications: {
      title: 'Notifications',
      options: {
        new_job_offer: 'When I get a new Job Offer',
        hirer_sends_message: 'When a Hirer sends me a message',
        document_update_required: 'When I have to update a document',
        request_action_required: 'When I have to take action on a request',
        job_offer_expiration: 'When Job Offers are about to expire',
        job_offer_approved: 'When Job Offer is approved',
      },
      subheading: 'Send me email notifications...',
      submit: 'Save Changes',
      success: 'Notifications updated successfully.',
    },
    header: {
      profile: 'Profile',
      password: 'Password',
      notifications: 'Notifications',
    },
    password: {
      title: 'Reset Password',
      current: 'Current Password',
      new: 'New Password',
      repeat_new: 'Confirm Password',
      four_digits: 'Last Four Digits of SSN',
    },
  },
  project: {
    header: {
      users: 'Users',
      invitations: 'Invitations',
      setup: {
        title: 'Project setup',
        settings: 'Imported settings',
        documents: 'Project settings',
        related_projects: 'Related projects',
        packets: 'Packets & approvers',
        audit_log: 'Audit log',
      },
      crew: 'Manage Crew',
      offers: 'Manage Offers',
      create_offer: 'Create offer',
      approvals: 'Approvals',
      reviews: 'Reviews',
      i9_reviews: 'I-9 reviews',
      archives: 'Archives',
      offers_documents: 'Offers documents',
    },
    information: {
      title: 'Project Information',
      project_name: 'Project Name',
      production_company_name: 'Production Company Name',
      production_company_number: 'Production Company Number',
      project_number: 'Project Number',
      producer: 'Producer',
    },
    unions: {
      title: 'Unions',
    },
    payroll: {
      title: 'Payroll Database',
      production_type: 'Production Type',
      studio_type: 'Studio Type',
      media_type: 'Media Type',
    },
    comments: {
      title: 'Comments',
    },
    documents: {
      manage_fields: {
        title: 'Manage Account Code Fields',
      },
      free_form_fields: {
        title: 'Account Code Fields',
        form: {
          name: 'Name',
          description: 'Description',
          allow_complete: 'Include on Offers pages',
          required: 'Required Field',
        },
        edit: {
          title: 'Edit',
        },
      },
      supporting_documents: {
        form: {
          name: 'Name',
          description: 'Description',
          required: 'Required',
        },
      },
      custom_fields: {
        title: 'Custom fields',
        add: 'Add employee custom field',
        delete: 'Delete',
        item: {
          edit: 'Edit',
          delete: 'Delete',
        },
        form: {
          type: 'Type',
          name: 'Name',
          required_for_hirer:
            'Display on Terms of Hire for Hiring Managers, and Reviewers',
          required_for_employee: 'Display on Employee Profile',
          type_opts: {
            string: 'String',
            number: 'Number',
            textarea: 'Textarea',
          },
        },
        new: {
          action: 'New Custom Field',
          title: 'Create Custom Field',
        },
        edit: {
          title: 'Edit Custom Field',
        },
      },
      departments: {
        title: 'Departments',
        add: 'Add department',
        item: {
          edit: 'Edit',
          delete: 'Delete',
          cancel: 'Cancel',
        },
        form: {
          name: 'Department Name',
          head: 'Department Head',
          code: 'Account Code',
        },
      },
      manage_users: {
        title: 'Manage users and departments',
        non_employees: {
          title: 'Add user (non-production employee)',
          add: 'Add non-employee user',
          edit: 'Edit',
          remove: 'Remove',
          cancel: 'Cancel',
          delete_referenced:
            'This user has already acted upon existing offers.',
        },
        departments: {
          title: 'Departments',
        },
      },
      reviewer_pool: {
        title: 'Reviewer pool',
        label: 'Reviewer',
        add_level: 'Add reviewer level',
        description:
          'Reviewers are individuals who will have the opportunity to review offers before they are sent. Each reviewer will have the ability to edit and review offers before they are sent to an employee. All reviewers will have the ability to send the offer to the employee.',
        level_label: 'Reviewers level %{level}',
      },
      manage_documents: {
        title: 'Manage documents',
        edit: 'Edit',
        active: {
          title: 'Active documents',
        },
        inactive: {
          title: 'Inactive documents',
        },
        archive: {
          label: 'Archive',
          confirm: 'Are you sure you want to archive this document?',
        },
        unarchive: {
          label: 'Unarchive',
          confirm: 'Are you sure you want to unarchive this document?',
        },
        add: 'Add Document to this Project',
        new: {
          submit: 'Upload',
          requirements:
            'In order to upload a Project Document, source file must be PDF.\n',
        },
        form: {
          file: 'File',
        },
      },
      additional_details: {
        title: 'Manage additional project details',
        hiring_status: 'ACA Hiring Status',
        allow_end_date: 'Allow Anticipated End Date',
        allow_direct_deposit: 'Allow Direct Deposit',
        offers: 'Offers',
        allow_hirers_to_send_offers: 'Allow Hiring Managers To Send Offers',
        custom_email_text: 'Custom Email Text',
        full_time: 'Full Time',
        variable: 'Variable',
        require_emergency_contact: 'Require emergency contact',
        start_backdate_limit: 'Start backdate limit (days)',
      },
    },
    packets: {
      new: {
        button: 'Add New Packet',
        label: 'Create New Packet',
        submit: 'Create Packet',
      },
      delete_confirm: 'Are you sure you want to delete packet %{label}?',
      delete_referenced: 'This packet was already filled by an employee.',
      delete_permanent: 'This packet cannot be deleted.',
      add_document_referenced: 'This packet was already filled by an employee',
      documents: {
        title: 'Documents',
        add: 'Assign a Document to this Packet',
        delete: 'Delete',
        is_referenced: 'Document is already filled by an employee',
      },
      flow: {
        title: 'Approval Flow',
        add: 'Add approver level',
        delete: 'Delete approver level',
        employee: 'Employee',
        notification: 'Data Sent to Cast & Crew',
        department_head: 'Department Head',
        delete_confirm: 'Are you sure you want to delete this approval level?',
        delete_referenced:
          'The approver has a pending offer and cannot be removed.',
      },
      i9: {
        flow: {
          title: 'I-9 Verifiers',
        },
      },
    },
    approvals: {
      not_found: 'No offers',
      title: 'Approval Queue',
      approved_title: 'Approved Offers',
      approved: 'Approved',
      pending_approval: 'Pending Approval',
      review: 'Review & Approve',
      select_offer: 'Select #{user} offer',
      columns: {
        employee: 'Employee',
        department_account: 'Department',
        account_code: 'Account Code',
        occupation: 'Position',
        union: 'Union',
        start_date: 'Start Date',
        end_date: 'End Date',
        rate: 'Rate/Scale',
        allowances: 'Allowances / Reimbursements',
        sent_date: 'Sent Date',
        status: 'Status',
        per: 'Per',
        box: 'Box',
        cell: 'Cell',
        car: 'Car',
        computer: 'Computer',
        approval_date: 'Date Approved',
      },
    },
    reviews: {
      reviewed: 'Reviewed',
      not_reviewed: 'Not Reviewed',
      pending_employee: 'Pending employee',
      pending_approval: 'Pending approval',
      rejected: 'Rejected',
      rejected_employee: 'Rejected by Employee',
      approved: 'Approved',
      review_bulk: 'Review selected',
      not_found: 'No Reviews',
      title: 'For Review',
      sent_title: 'Manage Offers',
      select_offer: 'Select %{user} offer',
      delete: 'Delete offer',
      delete_confirmation:
        "You are about to delete an offer, this action can't be undone.",
      cap: 'Cap',
      duration: 'Duration',
      columns: {
        employee: 'Employee',
        department_account: 'Department',
        occupation: 'Job Title/ Acct Code',
        union: 'Union',
        start_date: 'Start Date',
        rate: 'Rate/Scale',
        per: 'Per',
        status: 'Status',
        allowances: 'Allowances / Reimbursements',
        sent_date: 'Sent date',
      },
    },
    i9_verifications: {
      title: 'I-9 Documents Review',
      not_found: 'No I-9 documents found',
      actions: {
        validate: 'Validate I-9',
        verify: 'Verify',
        view: 'View',
      },
      columns: {
        name: 'Employee',
        phone: 'Phone',
        department: 'Department',
        i9_document: 'I-9 Status',
        position: 'Position',
        union: 'Union',
        start_date: 'Start Date',
        actions: 'Actions',
      },
      statuses: {
        not_signed: 'not signed',
        signed_not_submitted: 'not submitted',
        submitted: 'submitted',
        verified: 'verified',
        past_due: '%{days} days past due',
      },
      supporting_document: {
        title: 'Supporting Document',
        documents: 'Documents',
        empty: 'Without documents',
        or: 'or',
        actions: {
          delete: 'Delete',
          cancel: 'Cancel',
          upload: 'Upload',
          add_document: 'Add Document',
        },
        fields: {
          name: 'name',
        },
      },
    },
  },
  terms_of_hire: {
    crew_members: {
      title: '1. Crew members',
      description: 'Select crew members that will share this offer',
      selected: 'selected',
      with_offer: 'With offer',
      without_offer: 'Without offer',
    },
    create_offer: {
      title: '2. Create offer',
      description: 'This offer will be sent to %{number} crew members',
      terms_of_hire: 'Terms of hire',
      documents_and_settings: 'Documents and settings',
      union: 'Union',
      contract: 'Collective Bargaining Agreement (CBA)',
      occupation_code: 'Job Title / Position',
      schedule: 'Schedule',
      department: 'Department',
      start_date: 'Start date',
      end_date: 'Anticipated end date',
      hire_state: 'Hire state',
      work_city: 'Primary Work City',
      work_state: 'Work state',
      hiring_status: 'ACA hiring status',
      full_time: 'Full time',
      variable: 'Variable',
      opt_out_var: 'Opt-Out Variable',
      part_time: 'Part Time',
      seasonal: 'Seasonal',
      variable_ec: 'Variable (Exclude Corps)',
      account_code: 'Account code',
      notes: 'Additional Deal Notes',
      custom_fields: 'Custom Form Fields',
      free_form_fields: 'Account Code Fields',
      empty_custom_fields: 'This project has no custom fields',
      empty_free_form_fields: 'This project has no free fields',
      empty_packets: 'This project has no packets',
      packets:
        'Select all document(s) and/or packet(s) that apply to Crew hire',
      privileges: 'Privileges',
    },
    employment: {
      terms_of_employment: 'Terms of Employment',
      studio: 'Studio',
      location: 'Distant',
      amount: 'Amount',
      per: 'Per',
      cap: 'Cap',
      duration: 'Duration',
      rate_per_hour: 'Rate Per Hour',
      rate_per_week: 'Rate Per Week',
      guaranteed_hours: 'Guaranteed Hours',
      rate_per_hour_studio: 'Rate per hour studio',
      rate_per_hour_location: 'Rate per hour location',
      rate_per_week_studio: 'Rate per week studio',
      rate_per_week_location: 'Rate per week location',
      guaranteed_hours_studio: 'Garanteed hours studio',
      guaranteed_hours_location: 'Garanteed hours location',
      idle_hours_per_day: 'Distant Idle Hours Per Day',
      pay_6th_day_at: 'Pay 6th Day At',
      pay_7th_day_at: 'Pay 7th Day At',
      '6th_day_rate': '6th Day Rate',
      '7th_day_rate': '7th Day Rate',
      edit_terms: 'Edit Terms',
      message: {
        one_of_these_fields:
          'Please complete one of the following fields:\nRate per Hour - Studio\nRate per Hour - Distant\nRate per Week - Studio\nRate per Week - Distant',
      },
    },
    next: 'Next',
    save: 'Save and next',
    save_with_invitation: 'Send and next',
  },
  non_employee_sign_up: {
    title:
      'Hi %{first_name}, please complete the following form to finish your Non-Employee Registration for %{project_name}!',
    general_information: 'General Information',
    general: {
      first_name: 'First Name',
      middle_name: 'Middle Name',
      last_name: 'Last Name',
      title: 'Title',
      email: 'Email',
      phone: 'Phone Number',
      password: 'New Password',
      confirm_password: 'Confirm Password',
    },
    submit: 'Continue',
  },
  offers: {
    previous_reviewers: 'Previous approvers',
  },
  reviews: {
    title: 'Reviews',
    reviewed: 'Reviewed',
    waiting: 'Waiting for review',
  },
  employee: {
    onboarding: {
      next_label: 'Next',
      hiring_manager: 'Hiring Manager',
      intro: {
        profile: {
          title: 'Profile Information',
          description: 'Profile Information',
        },
        details: {
          title: 'Job Offer',
          description: 'Offer Details',
          paragraph_one: "Click 'Next' to view your job offer details",
        },
        additional_info: {
          title: 'Additional Project Info',
          description: 'Additional Project Info',
        },
        packets: {
          title: 'Review and Sign',
          description: 'Review and Sign',
          paragraph_one: 'Congratulations!',
          paragraph_two:
            'We have all the information we need to fill out your documents. On the next screen you will review, sign and submit them.',
        },
        submit_packet: {
          title: 'Submit Packet',
          description: 'Submit for Approval',
        },
      },
      profile: {
        project_specific_information: 'Project Specific Information',
      },
      additional_info: {
        next: 'Save & Next',
      },
      packets: {
        sign_documents: 'Sign the Following Documents',
        finish_and_submit: 'Finish and Submit for Review',
        no_documents: 'No documents to sign',
      },
      complete: {
        description: 'Your Start Packet has been Submitted for Approval.',
        go_to_offer_status: 'Go to Offer Status',
        logout: 'Logout',
      },
      details: {
        project_information_title: 'Project Information',
        union_title: 'Union',
        contract_title: 'Collective Bargaining Agreement',
        occupation_code_title: 'Position / Title (Occupation Code)',
        schedule_title: 'Schedule',
        department_title: 'Department',
        start_date_title: 'Start Date',
        end_date_title: 'Anticipated End Date',
        hire_state_title: 'Hire State',
        work_state_title: 'Work State',
        work_city_title: 'Primary Work City',
        hiring_status_title: 'ACA Hiring Status',
        hiring_status_full_time: 'Full Time',
        hiring_status_variable: 'Variable',
        hiring_status_seasonal: 'Seasonal',
        hiring_status_opt_out_var: 'Opt-Out Variable',
        hiring_status_part_time: 'Part Time',
        hiring_status_variable_ec: 'Variable (Exclude Corps)',
        comments_title: 'Additional Deal Terms / Comments (Optional)',
        vin_number_title: 'VIN Number',
        accept_offer_label: 'Accept Offer',
        reject_offer_label: 'Reject',
        terms_of_employment: 'Terms of Employment',
        studio: 'Studio',
        location: 'Distant',
        rate_per_hour: 'Rate Per Hour',
        rate_per_week: 'Rate Per Week',
        guaranteed_hours: 'Guaranteed Hours',
        pay_6th_day_at: 'Pay 6th day at',
        pay_7th_day_at: 'Pay 7th day at',
        amount: 'Amount',
        per: 'Per',
        cap: 'Cap',
        duration: 'Duration',
      },
    },
    offers: {
      title: 'My Offers',
      project_name_label: 'Project Name',
      position_label: 'Position',
      sent_date_label: 'Sent Date',
      approved_date_label: 'Approved Date',
      optedOut: {
        label: 'Complete on Paper',
        empty_list:
          "You haven't opted out of digital on-boarding for any offers",
      },
      sent: {
        label: 'New',
        empty_list: 'You have no new offers',
      },
      submitted: {
        documents: {
          title: 'Documents',
          document_name_label: 'Document Name',
          status_label: 'Status',
          not_signed: 'Not Signed',
          signed_not_submitted: 'Signed',
          submitted: 'Submitted for Approval',
          approved: 'Approved',
          rejected: 'Rejected',
        },
        documents_label: 'Documents',
        update_contact_label: 'Update Contact Info',
        label: 'Submitted for Approval',
        empty_list: "You haven't submitted offers for approval",
        review: {
          hour_label: 'hour',
          week_label: 'week',
          department_label: 'Department/Account',
          occupation_label: 'Occupation',
          union_label: 'Union',
          rate_label: 'Rate',
          scale_label: 'Scale',
          box_label: 'Box',
          cell_label: 'Cell',
          car_label: 'Car',
          computer_label: 'Computer',
        },
      },
      approved: {
        label: 'Approved',
        empty_list: 'You have no approved offers',
      },
      employeeRejected: {
        label: 'Employee Rejected',
        empty_list: 'You have no rejected offers',
      },
      supporting_documents: {
        title: 'Supporting Documents',
        required: 'Supporting documentation is required below',
        delete: {
          label: 'Delete',
        },
        cancel: {
          label: 'Cancel',
        },
      },
      modal: {
        title: 'Add a Note',
        note:
          'You have chosen to reject this offer for employment. We are required to ask that you add a short note as to why.',
        note_label: 'Note',
        close: 'Close',
        submit: 'Submit',
      },
      archive: {
        modal: {
          title: 'Archive Notes',
          note:
            'You have chosen to archive this offer. We are required to ask that you add a short note as to why.',
          submit: 'Archive',
        },
      },
      rescind: {
        modal: {
          title: 'Rescind Notes',
          note:
            'You have chosen to rescind this offer. We are required to ask that you add a short note as to why.',
          submit: 'Rescind',
        },
      },
    },
  },
  shared: {
    loading: 'Loading',
    help: 'Help',
    empty_text: 'N/A',
    cancel: 'cancel',
    day: {
      one: 'day',
      other: 'days',
    },
    week: {
      one: 'week',
      other: 'weeks',
    },
    month: {
      one: 'month',
      other: 'months',
    },
    privileges: {
      title: 'Privileges',
      can_create_offers: 'Can create offers (Hirer)',
      can_be_reviewer:
        'Can review offers before they are sent to employees (Reviewer)',
      can_approve_offers:
        'Can approve completed offers submitted by employees (Approver)',
      can_be_i9_verifier: 'Can verify I-9 documents (I-9 Verifier)',
      can_access_archives: 'Access to archives',
      can_view_documents: 'Can view documents',
    },
    actions: {
      download: 'Download',
      view: 'View',
      back: 'Back',
    },
    reimbursements: {
      box_rental: 'Box rental',
      computer_rental: 'Computer rental',
      car_allowance: 'Car allowance',
      mobile_phone: 'Mobile phone',
      per_diem_allowance: 'Per Diem',
      housing_allowance: 'Housing allowance',
    },
  },
  form: {
    search_label: 'Search',
    search_placeholder: 'Search...',
    actions: {
      create: 'Create',
      update: 'Update',
      save: 'Save',
      delete: 'Delete',
      submit: 'Submit',
      or: 'or',
      cancel: 'cancel',
    },
  },
  help: {
    project_hiring_managers_title: 'Hiring Managers for this project',
    contact_information_title: 'Contact Information for Cast & Crew',
    customer_service: 'Customer Service',
    cast_and_crew: 'Cast&Crew',
    support_email: 'plus.support@castandcrew.com',
    support_phone: '+1 818 860-7770',
  },
  approvals: {
    title: 'Approve offer for',
    pending: 'Offers for your approval',
    approved: 'Approved offers',
    current_document: 'This document',
    reject_offer: 'Reject Offer',
    other_documents: 'Other documents in this offer',
    no_offers: 'No offers',
    approve: 'Approve',
    sign_and_approve: 'Sign & Approve',
    approve_all_docs: 'Approve all documents',
    approve_all: 'Approve All',
    rejection_comments: 'Offer Rejection Comments',
    no_documents: 'No documents to approve',
    no_documents_alert:
      'No documents to approve, click below to approve all documents of the offer.',
    previous_offer: 'Previous Offer',
    next_offer: 'Next Offer',
    status: {
      approved: 'Approved',
      submitted: 'Submitted',
    },
  },
  free_form_fields: {
    description: 'Description %{n}',
    location: 'Location',
    set: 'Set',
    series: 'Series',
    insurance: 'Insurance',
  },
  i9_verifications: {
    title: 'I-9 Verifications',
    document_name: 'Document name',
    validate_label: 'Validate I-9',
    download: 'Download',
    status: {
      approved: 'Approved',
      submitted: 'Submitted',
    },
  },
  docusign_error: {
    title: 'Document Signing Error',
    description:
      "There's an error in the document and cannot be signed at this moment. You can keep signing the following documents while this problem is fixed.",
  },
  status: {
    draft: {
      packet: 'This packet contains documents still in draft state',
      document: 'This document is in a draft state',
    },
    warning: 'Warning',
    approval: {
      success: 'Approved',
      pending: 'Pending',
    },
  },
  offers_documents: {
    title: 'Approved offers documents',
    not_found: 'No offers found',
    columns: {
      name: 'Name',
      department: 'Department',
      account_code: 'Account code',
      occupation: 'Occupation',
      start_date: 'Start Date',
      approved_on: 'Approved On',
      actions: 'Actions',
    },
    detail: {
      title: 'Offer documents',
      back: 'Back',
    },
  },
  verifier: {
    i9_review: {
      disclaimer: {
        title:
          'Employer or Authorized Representative Review and Verification DISCLAIMER',
        text:
          'The employer understands that Form I-9 compliance is the responsibility of the employer, and failure to\ncomply with the Form I-9 requirements may lead to fines and/or other penalties.  The Form I-9 provided\nhere is for the employer’s convenience only; no warranty is made as to its compliance with the Form I-9\nregulations, government guidance, or any law(s).  Any penalties arising from I-9 forms completed in this\nsystem are the responsibility of the employer, and in no instance will Cast & Crew Entertainment\nServices, LLC be responsible for any such fines or penalties. In using the Form I-9, the employer states\nthat (1) it has read and understands the Form I-9 instructions; (2) it will not discriminate against any\nemployee on the basis of national origin or citizenship status; (3) it must review original documentation\npresented by the employee as evidence of identity and employment authorization in the presence of the\nemployee; and (4) it must not ask to see a specific document or documents for I-9 purposes.  The employer\nrepresentative agrees to electronically sign the Form I-9, attests that s/he has read and understands the\nattestation(s) to be signed, confirms that s/he is the individual listed in the signature block, and agrees\nnot to provide his/her password or login credentials to any other individual.\n',
      },
    },
  },
};
