import React from 'react';

import Moment from 'moment';
import DatePicker from '../../Base/DatePickerField';
import Field from '../../Base/Field';
import FormField from '../../Base/FormField';
import EthnicGroups from '../../Shared/EthnicGroups';
import T from '../../../utils/i18n';
import { cleanName } from '../../../utils/validateName';
import { withStyles } from '@material-ui/core/styles';

import Loader from 'common/components/Loader';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';

// Utilities
import phoneNumberInputOnChange from 'common/oldJavascripts/utils/phoneNumberInputOnChange';
import { AsYouType } from 'libphonenumber-js';

const DOB_FORMAT = 'YYYY-MM-DD';

const styles = {
  root: {
    width: '100%',
    height: '650px',
    display: 'grid',
    gap: '2%',
    gridGap: '2%',
    gridTemplateColumns: '1% repeat(4, 22%) 1%',
    gridTemplateRows: '5% repeat(6, 14.166%) 10%',
    gridTemplateAreas: `
    ". header          ............... ............. ............. ."
    ". firstName       firstName       middleName    middleName    ."
    ". lastName        lastName        gender        gender        ."
    ". email           email           phoneType     phone         ."
    ". dob             .............   ethnic        ethnic        ."
    ". ssn             alternateId     ethnic        ethnic        ."
    ". altIdDisclaimer altIdDisclaimer ethnic        ethnic        ."
    ". ............... ............... ............. ............. ."`,
  },
  header: {
    gridArea: 'header',
    fontSize: '1.23076923em',
    lineHeight: '1.25',
    margin: '15px 0',
    fontWeight: 700,
  },
  firstName: {
    gridArea: 'firstName',
  },
  middleName: {
    gridArea: 'middleName',
  },
  lastName: {
    gridArea: 'lastName',
  },
  gender: {
    gridArea: 'gender',
  },
  email: {
    gridArea: 'email',
  },
  phoneType: {
    gridArea: 'phoneType',
  },
  phone: {
    gridArea: 'phone',
  },
  dob: {
    gridArea: 'dob',
  },
  dobPicker: {
    backgroundColor: '#e6e7ea',
  },
  ssn: {
    gridArea: 'ssn',
  },
  alternateId: {
    gridArea: 'alternateId',
  },
  ethnic: {
    gridArea: 'ethnic',
  },
  altIdDisclaimer: {
    gridArea: 'altIdDisclaimer',
  },
  genderInfoIcon: {
    width: 20,
    height: 20,
    marginLeft: 4,
  },
  genderInputLabel: {
    display: 'flex',
  },
};

class ProfileGeneral extends React.Component {
  updatePhoneNumber = (number, target) => {
    const { profileMutator } = this.props;
    return phoneNumberInputOnChange(number, target, 'phone', profileMutator);
  };

  maskBirthYear = (dob, isProfileLocked = false) => {
    if (!isProfileLocked) {
      return Moment(dob, DOB_FORMAT);
    }
    const date = Moment(dob).format('MM-DD-YYYY');
    return date.replace(date.substring(6, 10), '****');
  };

  render() {
    const { classes, profileMutator } = this.props;
    const { data: profile, errors = {} } = profileMutator;
    var defaultDate = profile.birth_date
      ? Moment(profile.birth_date, DOB_FORMAT)
      : null;
    var genderOptions = null;
    var phoneTypeOptions = null;

    genderOptions = [
      {
        label: T('profile.general_information.gender_options.male'),
        value: 'M',
      },
      {
        label: T('profile.general_information.gender_options.female'),
        value: 'F',
      },
      {
        label: 'Choose not to disclose',
        value: 'N',
      },
    ];

    phoneTypeOptions = [
      {
        label: T('profile.general_information.phone_type_options.mobile'),
        value: 'Mobile',
      },
      {
        label: T('profile.general_information.phone_type_options.work'),
        value: 'Work',
      },
      {
        label: T('profile.general_information.phone_type_options.home'),
        value: 'Home',
      },
    ];

    if (profileMutator.status === 'loading') {
      return <Loader size={24} />;
    }
    const hasSsnError = !!(errors.ssn_mask || []).length;

    return (
      <div className={classes.root}>
        <Typography className={classes.header}>General Information</Typography>
        <div className={classes.firstName} data-test-id="General-firstName">
          <Field
            label={T('profile.general_information.first_name')}
            type="text"
            grouped={true}
            name="first_name"
            id="first_name"
            value={cleanName(profile.first_name)}
            errors={errors.first_name}
            required={true}
            onChange={value =>
              this._handleValidateNameInput('first_name', value)
            }
          />
        </div>

        <div className={classes.middleName} data-test-id="General-middleName">
          <Field
            label={T('profile.general_information.middle_name')}
            type="text"
            grouped={true}
            name="middle_name"
            id="middle_name"
            value={cleanName(profile.middle_name)}
            errors={errors.middle_name}
            onChange={value =>
              this._handleValidateNameInput('middle_name', value)
            }
          />
        </div>

        <div className={classes.lastName} data-test-id="General-lastName">
          <Field
            label={T('profile.general_information.last_name')}
            type="text"
            grouped={true}
            name="last_name"
            id="last_name"
            value={cleanName(profile.last_name)}
            errors={errors.last_name}
            required={true}
            onChange={value =>
              this._handleValidateNameInput('last_name', value)
            }
          />
        </div>
        <div className={classes.gender} data-test-id="General-gender">
          <Field
            label={
              <div className={classes.genderInputLabel}>
                Gender <span className="field-required">*</span>
                <Tooltip title='Please choose from the following options. You may opt out by selecting "Choose not to disclose".'>
                  <InfoIcon
                    className={classes.genderInfoIcon}
                    data-test-id="General-genderInfoIcon"
                  />
                </Tooltip>
              </div>
            }
            type="select"
            grouped={true}
            name="gender"
            id="gender"
            options={genderOptions}
            value={profile.gender}
            errors={errors.gender}
            onChange={profileMutator.update('gender')}
          />
        </div>
        <div className={classes.email} data-test-id="General-email">
          <Field
            label={T('profile.general_information.email')}
            type="text"
            grouped={true}
            name="email"
            id="email"
            value={profile.email}
            errors={errors.email}
            required={true}
            onChange={profileMutator.update('email')}
            disabled={true}
          />
        </div>
        <div className={classes.phoneType} data-test-id="General-phoneType">
          <Field
            label={T('profile.general_information.phone_type')}
            type="select"
            grouped={true}
            name="phone_type"
            value={profile.phone_type}
            errors={errors.phone_type}
            required={true}
            onChange={profileMutator.update('phone_type')}
            options={phoneTypeOptions}
            id="phone_type"
          />
        </div>
        <div className={classes.phone} data-test-id="General-phone">
          <Field
            label={T('profile.general_information.phone')}
            type="phone"
            grouped={true}
            name="phone"
            id="phone"
            value={this.formatPhoneNumber(profile.phone)}
            errors={errors.phone}
            required={true}
            onChange={(value, target) => this.updatePhoneNumber(value, target)}
          />
        </div>
        <div className={classes.dob} data-test-id="General-dob">
          <FormField
            label={T('Date of Birth')}
            required={true}
            grouped={false}
            disabled={profile.is_locked}
            errors={errors.birth_date}
          >
            <DatePicker
              name="birth_date"
              id="birth_date"
              selected={defaultDate}
              maxDate={Moment()}
              showYearDropdown={true}
              isClearable={!profile.is_locked}
              onChange={this._changeDOB}
              className={classNames({ [classes.dobPicker]: profile.is_locked })}
              value={this.maskBirthYear(profile.birth_date, profile.is_locked)}
            />
          </FormField>
        </div>
        <div className={classes.ssn} data-test-id="General-ssn">
          <Field
            label={T('profile.general_information.ssn')}
            type="ssn"
            grouped={true}
            unmaskLength={4}
            name="ssn_mask"
            id="ssn_mask"
            value={profile.ssn_mask}
            disabled={profile.is_locked}
            errors={
              hasSsnError && [
                'Please contact product support 818.848.0999 or 818.860.7770 or plus.support@castandcrew.com',
              ]
            }
            required={true}
            onChange={profileMutator.update('ssn_mask')}
          />
        </div>
        <div className={classes.alternateId} data-test-id="General-alternateId">
          <Field
            label={T('profile.general_information.alternate_id_mask')}
            type="text"
            grouped={true}
            name="alternate_id"
            id="alternate_id"
            value={profile.alternate_id}
            errors={errors.alternate_id}
            onChange={profileMutator.update('alternate_id')}
          />
        </div>
        <Typography
          variant="body1"
          className={classes.altIdDisclaimer}
          data-test-id={'General-alternateIdDisclaimer'}
        >
          If you enter an alternate employee ID (which was assigned to you by
          your employer), this identification number will appear on all
          paperwork in place of your social security number.
        </Typography>
        <div className={classes.ethnic}>
          <EthnicGroups
            profile={profileMutator}
            columns={2}
            label={'Ethnic Code'}
            data-test-id="General-ethnic"
          />
        </div>
      </div>
    );
  }

  formatPhoneNumber = number => {
    // we do not start formatting the number until it's greater than 4 digits
    // this prevents a bug that prevents the user from deleting their area code
    if (!/(.?\d){4,}/.test(number)) return number;
    const asYouType = new AsYouType('US');
    // return the formatted phone number
    return asYouType.input(number);
  };

  _changeDOB = date => {
    const { profileMutator = {} } = this.props;
    let formattedDate = date;
    if (date) {
      formattedDate = date.format(DOB_FORMAT);
    }
    profileMutator.update('birth_date')(formattedDate);
  };

  _handleValidateNameInput = (key, name) => {
    this._handleMutatorUpdate(key, name.replace(/[^a-z\s.\-']/i, ''));
  };

  _handleMutatorUpdate = (key, name) => {
    const profileMutator = this.props.profileMutator;
    profileMutator.update(key)(name);
  };
}

export default withStyles(styles)(ProfileGeneral);
