import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import useCookie from 'common/hooks/useCookie';

const BetaWarning = () => {
  const [seen, setSeen] = useCookie('hasUserSeenMobileOnboardingBetaWarning');
  const isOpen = !seen;

  const close = () => setSeen('1');

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>Mobile Offer Acceptance is in Beta</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We see you are using a mobile device to accept your offer.
          <br />
          <br />
          The Start+ mobile experience is currently in BETA. This means you may
          encounter bugs and performance issues while you accept your offer on
          your mobile device.
          <br />
          <br />
          If you want to accept your offer without issues, please go to Start+
          using a desktop/laptop or a large tablet.
          <br />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color="primary"
          autoFocus
          data-test-id="BetaWarning-dismiss"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BetaWarning;
