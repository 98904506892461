import React from 'react';

// HoC
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    display: 'inline-block',
    width: 'auto',
  },
});

const SubmitOffer = ({ onClick, classes = {} }) => {
  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        size="large"
        color="secondary"
        className={classes.button}
        onClick={onClick}
        data-test-id={`SubmitOffer-button`}
      >
        Verify
      </Button>
    </div>
  );
};

export default withStyles(styles)(SubmitOffer);
